/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react'
import { IHub, ISpace, IActiveSpaceDetails, IUser } from '../../../interfaces'
import { fabric } from 'fabric'
import { images } from '../../../assets/image'
import $ from 'jquery'
import { getHubColorPath } from '../../../helper'

enum EItem {
	hub = 'hub',
	space = 'space',
}

interface IFabricObject
	extends fabric.Image,
		fabric.Circle,
		fabric.Object,
		fabric.Group {
	lines: (fabric.Line | null)[]
	data: { type: EItem } & (IHub | ISpace) & any
}

interface IPan {
	mouseDown: WheelEvent | undefined | { x: number; y: number }
	drag: WheelEvent | undefined
	mouseUp: WheelEvent | undefined
}

interface IDragDropObject {
	space: { type: EItem; parent: IHub } & ISpace
	hub: { type: EItem } & IHub
}

let canvas: fabric.Canvas

let panActions: IPan = {
	mouseDown: undefined,
	drag: undefined,
	mouseUp: undefined,
}

const initialDragDrop: IDragDropObject = {
	space: { type: EItem.space, name: '', parent: { name: '' } },
	hub: { type: EItem.hub, name: '' },
}

let dragDropData = initialDragDrop
let dropMessageShown = false
let dropMessageTrigger = false

let pressTimer: any
// main blue globe related variables
let mainGlobeCoords: { x: number; y: number }
// main blue globe related variables

let canvasRateLimiter: any

class HubsAndSpaces extends Component<TItem> {
	static mainGlobe: IFabricObject | undefined = undefined
	static alreadyLoaded = false

	shouldComponentUpdate(nextProps: TItem, nextState: any) {
		if (
			nextProps.selectItem === this.props.selectItem &&
			nextProps.activeSpace === this.props.activeSpace &&
			nextProps.hubs === this.props.hubs
		)
			return false
		return true
	}

	componentWillUnmount() {
		clearInterval(pressTimer)
	}

	resetCanvas() {
		const { left: x, top: y } = canvas.getCenter()

		canvas.setZoom(0.8)
		canvas.absolutePan({ x: x - 200, y: y - 400 } as fabric.Point)
	}

	componentDidMount() {
		canvas = new fabric.Canvas('hubs-and-spaces-canvas', {
			selection: false,
			hoverCursor: 'pointer',
		})

		const elem = $('.at-sectionspace')

		canvas.setHeight((elem.height() || 620) + 500)
		canvas.setWidth(elem.width() || 330)

		const { left: x, top: y } = canvas.getCenter()

		canvas.setZoom(0.5)
		canvas.absolutePan({ x: x - 230, y: y - 450 } as fabric.Point)

		canvas.on({
			'object:moving': (e) => {
				const p = e.target as IFabricObject

				const circle =
					p.type === 'group'
						? ((p.getObjects()[0] as unknown) as IFabricObject)
						: p

				const radius = circle.width
					? (circle.width / 2) * (circle.scaleX || 1)
					: 1

				if (p.lines?.length) {
					p.lines.forEach((line, idx) => {
						if (line) {
							if (!(line instanceof fabric.Line)) {
								line = Object.assign(new fabric.Line(), line)
								p.lines[idx] = line
							}
							if (idx) {
								p?.left &&
									line.set({
										x1: p.left + radius,
										y1: p.top && p.top + radius,
									})
							} else {
								p?.left &&
									line.set({
										x2: p.left + radius,
										y2: p.top && p.top + radius,
									})
							}
						}
					})
				}
				canvas.renderAll()
			},
			'mouse:dblclick': () => {
				// canvas.selectionBorderColor = '#fff'
				// const event = evtDown.e as WheelEvent
				// panActions.mouseUp = undefined
				// panActions.mouseDown = { x: event.screenX, y: event.screenY }
			},
			'mouse:down': (evtDown) => {
				if (evtDown.target) {
					pressTimer = setTimeout(() => {
						const active = canvas.getActiveObject()
						if (active.type !== 'image') {
							let circle = active
							this.props.selectItem(circle.data)
						} else {
							this.props.selectItem()
						}
					}, 1000)
					scaleObject(true)
					animate(evtDown.target, true)
				}
				const [x, y] = this.getClickCoords(evtDown.e as TouchEvent | MouseEvent)
				panActions.mouseUp = undefined
				panActions.mouseDown = { x, y }
			},
			'mouse:up': (evtUp) => {
				if (dragDropData !== initialDragDrop) {
					if (
						dragDropData.hub?.type === EItem.hub &&
						dragDropData.space?.type === EItem.space
					) {
						if (
							dragDropData.hub?._id !== dragDropData.space?.parent?._id &&
							!dropMessageTrigger
						) {
							this.props.dragDropHandler(dragDropData.space, dragDropData.hub)
							dropMessageTrigger = true
						}
					}
				}
				clearTimeout(pressTimer)
				scaleObject(false)
				if (evtUp.target) animate(evtUp.target, false)
				const event = evtUp.e as WheelEvent
				panActions.mouseUp = event
				panActions.mouseDown = undefined
			},
			'mouse:move': (evtMove) => {
				if (panActions.mouseDown) {
					clearTimeout(pressTimer)
					handleSpaceDrag(evtMove)

					if (dragDropData !== initialDragDrop && !dropMessageShown) {
						if (
							dragDropData.hub?.type === EItem.hub &&
							dragDropData.space?.type === EItem.space
						) {
							if (
								dragDropData.hub?._id !== dragDropData.space?.parent?._id &&
								!dropMessageShown
							) {
								dropMessageShown = true
							}
						}
					}
				}
				if (!evtMove.target && panActions.mouseDown) {
					let [x0, y0] = [panActions.mouseDown.x, panActions.mouseDown.y]
					;(() => {
						const [x1, y1] = this.getClickCoords(
							evtMove.e as TouchEvent | MouseEvent
						)
						const point = {
							x: x1 - x0,
							y: y1 - y0,
						} as fabric.Point
						canvas.relativePan(point)
						panActions.mouseDown = {
							x: x1,
							y: y1,
						}
					})()
				}
			},
			'mouse:wheel': (evt) => {
				const event = evt.e as WheelEvent
				const delta = event.deltaY
				const maxZoom = 20
				const minZoom = 0.19
				let zoom = canvas.getZoom()
				zoom = zoom + delta / 200
				if (zoom > maxZoom) zoom = maxZoom
				if (zoom < minZoom) zoom = minZoom
				if (zoom < maxZoom && zoom > minZoom) {
					const point = { x: event.offsetX, y: event.offsetY } as fabric.Point
					canvas.zoomToPoint(point, zoom)
				}
				event.preventDefault()
				event.stopPropagation()
			},
		})
	}

	getClickCoords(evt: TouchEvent | MouseEvent): [number, number] {
		return evt instanceof TouchEvent
			? [evt.touches[0].screenX, evt.touches[0].screenY]
			: [evt.screenX, evt.screenY]
	}

	render() {
		const { hubs, activeSpace } = this.props

		clearInterval(canvasRateLimiter)
		canvasRateLimiter =
			hubs && setTimeout(() => renderCanvas(hubs, activeSpace), 50)

		/* -------------------reset drag drop related variables---------------------- */
		dragDropData = initialDragDrop
		dropMessageShown = false
		dropMessageTrigger = false
		/* -------------------reset drag drop related variables---------------------- */

		return (
			<Fragment>
				{/* <a className='at-btnadduser' onClick={this.resetCanvas}>
					<i className='icon-cancel text-white'></i>
				</a> */}
				<canvas id='hubs-and-spaces-canvas' />
			</Fragment>
		)
	}
}

const loadMainGlobe = async () => {
	if (HubsAndSpaces.alreadyLoaded) return

	mainGlobeCoords = {
		x: Math.floor(canvas?.getWidth() / 2.5),
		y: Math.floor(canvas?.getHeight() / 2.5),
	}

	HubsAndSpaces.alreadyLoaded = true
	HubsAndSpaces.mainGlobe = await new Promise((resolve, reject) => {
		fabric.Image.fromURL(
			images.hubsAndSpaces.globeImg,
			(img) => {
				const scale = 100 / (img.width || 100)
				img.set({
					left: mainGlobeCoords.x,
					top: mainGlobeCoords.y,
					scaleX: scale,
					scaleY: scale,
				})
				resolve(img as IFabricObject)
				// mainGlobe = img as IFabricObject
			},
			{
				hasControls: false,
				hasBorders: false,
			}
		)
	})
}

const handleSpaceDrag = (evt: fabric.IEvent) => {
	const { target } = evt
	target?.setCoords()
	if (target) {
		canvas.forEachObject(function (obj) {
			if (obj === target || obj.type === 'line') return
			if (target.intersectsWithObject(obj)) {
				if (
					dragDropData.space !== target.data ||
					dragDropData.hub !== obj.data
				) {
					dragDropData = {
						space: target.data,
						hub: obj.data,
					}
				}
				if (
					dragDropData.space?.type === EItem.space &&
					dragDropData.hub?.type === EItem.hub &&
					dragDropData.hub?._id !== dragDropData.space?.parent?._id
				) {
					obj.set('opacity', 0.5)
				}
			} else if (obj.opacity !== 1) {
				dragDropData = initialDragDrop
				dropMessageShown = false
				dropMessageTrigger = false
				obj.set('opacity', 1)
			}
		})
	}
}

const renderCanvas = async (hubs: IHub[], activeSpace?: ISpace) => {
	if (!canvas) return
	if (!HubsAndSpaces.mainGlobe) await loadMainGlobe()

	canvas?.clear()
	const hubGlobe = {
		x: 100,
		y: 50,
		radius: 30,
	}

	if (HubsAndSpaces.mainGlobe) HubsAndSpaces.mainGlobe.lines = [null]

	let incValue = 360 / hubs.length

	let newValue = 0
	for (const hub of hubs) {
		hubGlobe.x = 250 * Math.cos(newValue) + mainGlobeCoords.x
		hubGlobe.y = 250 * Math.sin(newValue) + mainGlobeCoords.y

		newValue = newValue + incValue + 2

		const newHub = (await new Promise((resolve, reject) => {
			return fabric.Image.fromURL(images.hubsAndSpaces.hubImg, (img) => {
				const scale = 50 / (img.width || 500)
				img.set({
					left: hubGlobe.x,
					top: hubGlobe.y,
					scaleX: scale,
					scaleY: scale,
				})

				resolve(img)
			})
		})) as fabric.Object

		newHub.set('data', { type: EItem.hub, ...hub })

		const text = makeText(hubGlobe.x + 20, hubGlobe.y + 50, undefined, hub.name)

		const hubGroup = (new fabric.Group([newHub, text], {
			hasControls: false,
			hasBorders: false,
			data: newHub.data,
		}) as unknown) as IFabricObject
		hubGroup.lines = [null]
		const spaceGlobe = {
			x: hubGlobe.x - 100,
			y: hubGlobe.y - 100,
			radius: 13,
		}
		let spaceIndex = 0
		for (const space of hub.spaces || []) {
			spaceGlobe.x = 100 * Math.cos(spaceIndex) + hubGlobe.x
			spaceGlobe.y = 100 * Math.sin(spaceIndex) + hubGlobe.y
			spaceIndex++

			const newSpace = (await new Promise((resolve, reject) => {
				return fabric.Image.fromURL(getHubColorPath(space.color), (img) => {
					const scale = 35 / (img.width || 500)
					img.set({
						left: spaceGlobe.x,
						top: spaceGlobe.y,
						scaleX: scale,
						scaleY: scale,
					})

					resolve(img)
				})
			})) as fabric.Object
			newSpace.set('data', {
				...space,
				type: EItem.space,
				parent: { ...newHub.get('data') },
			})
			let outerCircle
			if (space._id === activeSpace?._id) {
				newSpace.set('data', { ...newSpace.data, active: true })
				outerCircle = makeCircle(
					spaceGlobe.x - 5,
					spaceGlobe.y - 5,
					spaceGlobe.radius + 9,
					'',
					'grey',
					1
				)
			}
			const text = makeText(
				spaceGlobe.x - spaceGlobe.radius,
				spaceGlobe.y + spaceGlobe.radius * 2 + 5,
				undefined,
				`${space.name} (${space.contacts?.length})`
			)
			const line = makeLine([
				hubGlobe.x + hubGlobe.radius,
				hubGlobe.y + hubGlobe.radius,
				spaceGlobe.x + spaceGlobe.radius,
				spaceGlobe.y + spaceGlobe.radius,
			])
			hubGroup.lines.push(line)

			const objects = outerCircle
				? [newSpace, text, outerCircle]
				: [newSpace, text]
			const spaceGroup = (new fabric.Group(objects, {
				hasControls: false,
				hasBorders: false,
				data: newSpace.data,
			}) as unknown) as IFabricObject
			spaceGroup.lines = [line]
			canvas?.add(line, spaceGroup)
		}
		const lineToMainGlobe = makeLine([
			mainGlobeCoords.x + 50,
			mainGlobeCoords.y + 50,
			hubGlobe.x + hubGlobe.radius,
			hubGlobe.y + hubGlobe.radius,
		])
		HubsAndSpaces.mainGlobe?.lines.push(lineToMainGlobe)
		hubGroup.lines.unshift(lineToMainGlobe)
		canvas?.add(lineToMainGlobe, hubGroup)
	}

	if (HubsAndSpaces.mainGlobe)
		canvas?.add(HubsAndSpaces.mainGlobe as IFabricObject)
	canvas?.renderAll()
}

const scaleObject = (scaleUp: boolean) => {
	const activeObj = canvas.getActiveObject()

	if (activeObj && activeObj.type !== 'image') {
		const { scaleX } = activeObj
		if (scaleX) {
			if (scaleUp) {
				scaleX > 1 && activeObj.scale(1)
			} else {
				scaleX < 1.5 && activeObj.scale(1.5)
			}
		}
	}
}

const animate = (obj: fabric.Object, scaleUp: boolean) => {
	if (obj && obj.type !== 'image') {
		canvas.renderAndReset()
		const scaleX = obj.scaleX || 0
		canvas.renderAll()
		fabric.util.animate({
			startValue: scaleX,
			endValue: scaleX + (scaleUp ? 0.5 : -0.5),
			duration: 400,
			onChange: (value: number) => {
				obj.scale(value)
				canvas.renderAll()
			},

			// onComplete: function () {
			// 	e.target?.setCoords()
			// },
			byValue: 0,
		})
	}
}

const makeLine = (coords: number[]) => {
	return new fabric.Line(coords, {
		stroke: 'grey',
		strokeWidth: 0.5,
		selectable: false,
		evented: false,
	})
}

const makeCircle = (
	left: number = 0,
	top: number = 0,
	radius: number = 0,
	color: string = '#f55',
	stroke: string = '',
	strokeWidth: number = 3,
	data: any = {}
) => {
	const circle = new fabric.Circle({
		top,
		left,
		radius,
		fill: color,
		stroke,
		strokeWidth,
		hasControls: false,
		hasBorders: false,
		data,
	})
	return (circle as unknown) as IFabricObject
}

const makeText = (
	left: number,
	top: number,
	color: string = 'black',
	text: string = ''
) => {
	return new fabric.Textbox(text, {
		fontFamily: 'sf_ui_displayregular, sans-serif;',
		// fontWeight: 900,
		width: text.length + 40,
		fontSize: 20,
		top,
		left,
		fill: color,
		hasControls: false,
		hasBorders: false,
		evented: false,
	})
}

type TItem = {
	hubs?: IHub[]
	selectItem: Function
	dragDropHandler: (space: ISpace, hub: IHub) => void
	user: IUser
	// editItem: Function
	// deleteItem: Function
	activeSpace?: IActiveSpaceDetails
}

// const mapStateToProps = (state: IStoreReducers) => ({
// 	activeSpace: state.user.activeSpace,
// })

// export default connect(mapStateToProps, null)(HubsAndSpaces)
export default HubsAndSpaces
