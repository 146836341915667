import { AxiosResponse } from 'axios'
import { IUser } from '../../interfaces'
import { userService } from '../../services'
import { CURRENT_STUDIO_USER } from '../types'


export const setStudioUser = (payload: IUser) => ({
    type: CURRENT_STUDIO_USER,
    payload
})

export const fetchUserStudio = (id: string) => async (dispatch: any) => {
    userService.getUsersByID(id).then((res: AxiosResponse<Array<IUser>>) => {
        dispatch(setStudioUser(res.data[0]))
    }).catch((err: any) => {
        console.log(err)
    })
}