import { AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ERole } from '../../../../enums'
import { ICollectionItem, IEventDocumentResponse, IEventResponse, IUser } from '../../../../interfaces'
import { IResponse } from '../../../../interfaces/db.interface'
import { routes } from '../../../../router'
import { eventService, toastService } from '../../../../services'
import { collectionImage } from '../../../../shared'
import { userSelector } from '../../../../store/selectors'
import LoadingComponent from '../../../common/Loading.component'
import { UserNameComponent, UserPicComponent } from '../../../User/index.component'

interface IProps {
    user: IUser
    onBack: Function
    onClickOnEvent: Function
}
const AssignedEventList = ({ user, onBack, onClickOnEvent }: IProps) => {

    const history = useHistory()
    const [studioUser, setStudioUser] = useState<IUser>()
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingEvent, setLoadingEvent] = useState<boolean>(false)
    const [upcomingEvents, setUpcomingEvents] = useState<Array<IEventDocumentResponse>>([])
    const [happeningEvents, setHappeningEvents] = useState<Array<IEventDocumentResponse>>([])
    const [pastEvents, setPastEvents] = useState<Array<IEventDocumentResponse>>([])
    const [events, setEvents] = useState<Array<IEventDocumentResponse>>([])
    const paramId = (useParams() as any).id
    const [selectedEvent, SetSelectedEvents] = useState<Array<IEventDocumentResponse>>([])


    const sortingEvent = (e: Array<IEventDocumentResponse>) => {
        let futureEvents = e.filter((e: IEventDocumentResponse) => moment(e.startDate).valueOf() > moment().valueOf() && moment(e.endDate).valueOf() > moment().valueOf())
        // .sort((a: IEventDocumentResponse, b: IEventDocumentResponse) => new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf())
        let currEvents = e.filter((e: IEventDocumentResponse) => moment(e.startDate).valueOf() < moment().valueOf() && moment(e.endDate).valueOf() > moment().valueOf())
        let pastEvents = e.filter((e: IEventDocumentResponse) => moment(e.startDate).valueOf() < moment().valueOf() && moment(e.endDate).valueOf() < moment().valueOf())

        setUpcomingEvents([...futureEvents])
        setHappeningEvents([...currEvents])
        setPastEvents([...pastEvents])
    }


    const fetchUserWithEvents = useCallback(() => {
        setLoading(true)
        eventService.getUserWithEvent(user._id).then((res: AxiosResponse<IResponse<IUser>>) => {
            let resUser = res.data.data
            let resEvent = resUser.events as Array<IEventDocumentResponse>
            setStudioUser({ ...resUser })
            setEvents([...resEvent])
            sortingEvent(resEvent)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [studioUser])

    useEffect(() => {
        console.log(moment().valueOf())
        fetchUserWithEvents()
    }, [])

    const onSelectEvents = (item: IEventDocumentResponse) => {
        if (selectedEvent.map((i: IEventDocumentResponse) => i._id).indexOf(item._id) !== -1) {
            let newItems = selectedEvent.filter((i: IEventDocumentResponse) => i._id !== item._id)
            SetSelectedEvents([...newItems])
            return
        }
        let newArts = selectedEvent
        newArts.push(item)
        SetSelectedEvents([...newArts])
    }

    const isEventSelected = (item: IEventDocumentResponse) => {
        return selectedEvent.map((i: IEventDocumentResponse) => i._id).indexOf(item._id) !== -1
    }

    const onSelectAllEvents = () => {
        if (selectedEvent.length === events.length) {
            SetSelectedEvents([])
            return
        }
        SetSelectedEvents([...events])
    }

    const isAllEventsArtSelected = () => {

        return selectedEvent.length > 0 && (selectedEvent.length === events.length)
    }

    const onDeleteEvents = () => {
        if (!selectedEvent.length) return

        let items = selectedEvent.map((e: IEventDocumentResponse) => e._id)

        setLoading(true)
        eventService.deleteEvents({ events: items }).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetchUserWithEvents()
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onHideEvents = (e: IEventDocumentResponse) => {
        setLoading(true)
        eventService.updateSingleEvent(e._id, { isHide: !e.isHide }).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetchUserWithEvents()
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const renderingEvents = (e: IEventDocumentResponse) => {

        let item = (e.items as Array<ICollectionItem>)
        if (user._id !== (e.user as IUser)._id && e.isHide) {
            return null
        }
        return (
            <div className="col-6" key={e._id}>
                <div className="atv2-eventbox">
                    {/* <div className="atv2-eventbox-top">
                        <Link to={{ pathname: `${routes.artCentral.events.view}/${e._id}` }}>
                            <h5>{e.name}</h5>
                        </Link>
                        <ul>
                            <li>Start: {moment(e.startDate).format('LL')}</li>
                            <li>End: {moment(e.endDate).format('LL')}</li>
                        </ul>
                    </div> */}
                    {e.poster && <figure className="at-figureFull" onClick={() => onClickOnEvent(e)}>
                        <img src={collectionImage(item[0], `${e.poster}`, true)} className="img-fluid" alt="" />
                    </figure>}
                    {user._id === (e.user as IUser)._id && <>
                        <div className="atv2-eventbox-positionabs">
                            <div className="at-artgallerycollectionbox atv2-collectionbox">
                                <div className="at-checkbox">
                                    <i className={`${e.isHide ? 'icon-view' : 'icon-view-disable'} at-iconview`} onClick={() => onHideEvents(e)}></i>
                                </div>
                                <span className="at-checkbox at-checkbox-width40">
                                    <input type="checkbox" name="select" id="artcollectionselectall" checked={isEventSelected(e)} />
                                    <label htmlFor="artcollectionone" onClick={() => onSelectEvents(e)}></label>
                                </span>
                            </div>
                        </div>
                        {/* <div className="atv2-eventbox-arrows">
                                <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowup" title="Up" ></i>
                                <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowdw" title="Down"  ></i>
                            </div> */}
                    </>}
                </div>
                {/* atv2-eventbox ends */}

            </div>
        )
    }

    return (
        <div className='at-postcollections scrollable at-contentarea'>
            <div className='at-collections'>
                <div className='at-orangeeconhead'>
                    <a
                        onClick={() => onBack()}
                        className='at-left-arrowicon'
                    >
                        <i className='icon-left-arrow'></i>
                    </a>
                    {studioUser && <UserPicComponent user={studioUser} />}
                    <div className='at-orangeeconcontent'>
                        {studioUser?.isDeaf && (
                            <span className='at-postcontenticon'>
                                <i className='icon-hand'></i>
                            </span>
                        )}
                        <fieldset className="at-artcollectioncontent w-100">
                            <h3>
                                {studioUser?.roles.includes(ERole.AGENT) ? studioUser?.gallery_name : 'Art Studio'}
                                {studioUser?.roles.includes(ERole.ARTIST) && <UserNameComponent user={studioUser} />}
                            </h3>
                            <div className="at-btniconholder mt-2">
                                {user._id === studioUser?._id && <> <div className="at-checkbox">
                                    <input type="checkbox" name="selectAll" checked={isAllEventsArtSelected()} />
                                    <label htmlFor="selectAll" onClick={() => onSelectAllEvents()}></label>
                                </div>
                                    <button className="at-btndelete text-danger" disabled={selectedEvent.length === 0} onClick={onDeleteEvents} ><i className="icon-delete"></i></button>
                                </>}
                            </div>
                        </fieldset>
                        {/* <div className="at-btniconholder">
											<a  className="at-btnheart at-postbtn"><i className="icon-favorite"></i></a>
											<a  id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a>
											<a  className="at-btnmore at-postbtn"><i className="icon-more"></i></a>
										</div> */}
                    </div>
                </div>
                {/* at-orangeeconhead ends */}
                {events.length ? <div className="atv2-eventbox-wrapper">
                    {happeningEvents.length > 0 && <>
                        <h4 className="at-eventheading">Current Events</h4>
                        <div className="row at-row-mlr-3 mb-1">
                            {happeningEvents.map((e: IEventDocumentResponse) => renderingEvents(e))}
                        </div>
                    </>}

                    {upcomingEvents.length > 0 && <>
                        <h4 className="at-eventheading mt-0">Future Events</h4>
                        <div className="row at-row-mlr-3">
                            {upcomingEvents.map((e: IEventDocumentResponse) => renderingEvents(e))}
                        </div>
                    </>}

                    {pastEvents.length > 0 && <>
                        <h4 className="at-eventheading">Past Events</h4>
                        <div className="row at-row-mlr-3">
                            {pastEvents.map((e: IEventDocumentResponse) => renderingEvents(e))}
                        </div>
                    </>}
                </div>
                    :
                    <p>No data found</p>
                }
            </div>
            {loading && <LoadingComponent className='at-transaction-loader' />}
        </div>
    )
}

export default AssignedEventList
