import React, { useState, useCallback, useEffect, FormEvent } from 'react'
import { AxiosResponse } from 'axios'

import { adminService, toastService } from '../../services'
import { RouteComponentProps } from 'react-router-dom'
import { TmceEditor } from '../common/TMCEditor.comopnent'

interface IPageProps extends RouteComponentProps {
    [key: string]: any
}

interface IPolicy {
    _id: string,
    policy: any,
    terms: any,
    genreAndStyleKeywords: string
}

export const EditText = ({ history }: IPageProps) => {
    const [policyObject, setPolicyObject] = useState<IPolicy>({ _id: '', policy: '', terms: '', genreAndStyleKeywords:'' })

    useEffect(() => {
        fetchPolicy()
    }, [])

    const fetchPolicy = useCallback(() => {
        adminService.getPolicy().then((response: AxiosResponse<any>) => {
            let data = response.data[0]
            setPolicyObject({
                ...policyObject,
                _id: data._id,
                policy: data.policy,
                terms: data.terms
            })
        }).catch(error => {
            console.log("Error in getting terms: ", error)
        })
    }, [])

    const handlePolicy = (e: any) => {
        // const { value } = (e.target as HTMLInputElement)
        setPolicyObject({
            ...policyObject,
            policy: e,
        })
    }


    const handleTerms = (e: any) => {
        // const { value } = (e.target as HTMLInputElement)
        setPolicyObject({
            ...policyObject,
            terms: e,
        })
    }
    const handleFenreAndStyleKeywords = (e: any) => {
        // const { value } = (e.target as HTMLInputElement)
        setPolicyObject({
            ...policyObject,
            genreAndStyleKeywords: e,
        })
    }

    const updatePolicy = (e: any) => {
        e.preventDefault()
        if (policyObject?._id) {
            adminService.updatePolicy(policyObject._id, { policy: policyObject.policy, terms: policyObject.terms, genreAndStyleKeywords: policyObject.genreAndStyleKeywords }).then((response: AxiosResponse<any>) => {
                toastService.success("Successfully updated the terms")
            }).catch(error => {
                console.log("Error in updating the terms:", error)
            })
            return
        }
        adminService.createPolicy({ policy: policyObject.policy, terms: policyObject.terms }).then((response: AxiosResponse<any>) => {
            toastService.success("Successfully created the terms")
        }).catch(error => {
            console.log("Error in updating the terms:", error)
        })
    }

    // const toolbarConfig: any = {
    //     // Optionally specify the groups to display (displayed in the order listed).
    //     display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
    //     INLINE_STYLE_BUTTONS: [
    //         { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    //         { label: 'Italic', style: 'ITALIC' },
    //         { label: 'Underline', style: 'UNDERLINE' }
    //     ],
    //     BLOCK_TYPE_BUTTONS: [
    //         { label: 'UL', style: 'unordered-list-item' },
    //         { label: 'OL', style: 'ordered-list-item' }
    //     ]
    // };

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two at_pad_lr_0">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Edit text</h2>
                </div>
                <div className="at-paddinglr-15">
                    <div className="form-group">
                        <label className="pt-3"><h4>Policy</h4></label>
                        <TmceEditor text={policyObject?.policy} onChange={handlePolicy} />
                    </div>
                    <div className="form-group">
                        <label className="pt-3"><h4>Terms and Conditions</h4></label>
                        <TmceEditor text={policyObject.terms} onChange={handleTerms} />
                    </div>
                    <div className="form-group">
                        <label className="pt-3"><h4>Genre and Style Keywords</h4></label>
                        <TmceEditor text={policyObject.genreAndStyleKeywords} onChange={handleFenreAndStyleKeywords} />
                    </div>
                    <div className="form-group">
                        <button className="at-btn at-btnupdate at-btn-fullwidth" onClick={updatePolicy}>Update</button>
                    </div>
                </div>
            </div>
        </main>
    )
}