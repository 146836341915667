import { AxiosError, AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { images } from '../../../../../assets/image'
import { EPrintingServiceType, EPrintType, EServicesType } from '../../../../../enums'
import { IFormates, IUser } from '../../../../../interfaces'
import { IResponse } from '../../../../../interfaces/db.interface'
import { IPrintingServices, IPrintingServiesPrices, IPrintingServiesSizes } from '../../../../../interfaces/services.interface'
import { consultantService } from '../../../../../services/consultantServices.service'
import { ProposalImageView } from '../../../../common/ProposalImageView'

interface IProps {
    format: IFormates
    selectedPrinter?: IUser
    onClickingSelectPrinter: Function
    onlyPrinter?: boolean
}
export const SeeServicePricing = ({ format, selectedPrinter, onClickingSelectPrinter, onlyPrinter }: IProps) => {

    const [services, setServices] = useState<Array<IPrintingServices>>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchServices = () => {
        if (!selectedPrinter) return
        setLoading(true)
        consultantService.getPrintingServicesByUserId(selectedPrinter._id).then((res: AxiosResponse<IResponse<Array<IPrintingServices>>>) => {
            if (res.data.success) {
                if (onlyPrinter) {
                    let filtered = res.data.data.filter((i: IPrintingServices) => i.serviceType === EPrintingServiceType.PRINTING_OPTION)
                    setServices([...filtered])

                } else {
                    let print = res.data.data.filter((i: IPrintingServices) => i.serviceType === EPrintingServiceType.PRINTING_OPTION)
                    let frame = res.data.data.filter((i: IPrintingServices) => i.serviceType === EPrintingServiceType.FRAMING_OPTION)
                    setServices([...print, ...frame])
                }
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            setLoading(false)
            console.log(err)
        })
    }

    useEffect(() => {
        fetchServices()
    }, [])
    return <div className="at-addactionholder">
        <div className="at-formthemehead pb-2">
            <h2 className="text-center">Select a Printer</h2>
            {/* <div className="from-group at-searchinput">
                <input type="search" className="form-control" placeholder="Search" value="" />
            </div> */}
        </div>
        <div className="at-selectprinter">

            {selectedPrinter && <div className="at-printerbox">
                <h6>{selectedPrinter.businessName ? `${selectedPrinter.businessName}` : `${selectedPrinter.firstName} ${selectedPrinter.lastName}`}</h6>
                <p>{selectedPrinter.address ? selectedPrinter.address : ''} {selectedPrinter.city ? selectedPrinter.city : ''} {selectedPrinter.state ? selectedPrinter.state : ''} {selectedPrinter.zipcode ? selectedPrinter.zipcode : ''}</p>
                <p>{formatPhoneNumberIntl(selectedPrinter.phoneNumber)}</p>
            </div>}
            {services && services.length > 0 && services.map((s: IPrintingServices) => {
                let prices: Array<IPrintingServiesPrices> = s.printingPrices
                return (
                    <div className="at-printerbox-outer">
                        <div className="at-formthemehead pb-2">
                            {s.serviceType === EPrintingServiceType.FRAMING_OPTION && <h2 className="text-left">{`Framing Services & Prices`}</h2>}
                            {s.serviceType === EPrintingServiceType.PRINTING_OPTION && <h2 className="text-left">{`Printing Services & Prices`}</h2>}
                        </div>
                        <div className="at-printerbox">
                            <h6>{s.name}</h6>
                            <p dangerouslySetInnerHTML={{ __html: s.description }} ></p>
                            {/* {selectedPrinter && <p>{formatPhoneNumberIntl(selectedPrinter.phoneNumber)}</p>} */}
                        </div>
                        <figure>
                            <ProposalImageView isServicImage={true} item={s} />
                        </figure>
                        <table className="table table-striped table-borderless at-tableprinter">
                            <thead>
                                <tr>
                                    <th>
                                        <h6>Sizes</h6>
                                        <p>(H x W)</p>
                                    </th>
                                    <th>
                                        <h6>Printing</h6>
                                        <p>(USD)</p>
                                    </th>
                                    <th>
                                        <h6>Shipping</h6>
                                        {s.serviceType === EPrintingServiceType.FRAMING_OPTION && <p>{'Framed'} (USD)</p>}
                                        {s.serviceType === EPrintingServiceType.PRINTING_OPTION && <p>{'Unframed'} (USD)</p>}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {prices && prices.length > 0 && prices.map((p: IPrintingServiesPrices) => {
                                    if (!p.printingSizes || !p.printingSizes._id) return null
                                    return (
                                        <tr>
                                            <td>{p.printingSizes.width} x {p.printingSizes.height} inhces</td>
                                            <td>{p.price}</td>
                                            <td>{p.shippingPrice}</td>
                                        </tr>
                                    )
                                })}

                                {/* <tr>
                                    <td>18 x 24 inhces</td>
                                    <td>$32</td>
                                    <td>$12</td>
                                </tr>
                                <tr>
                                    <td>24 x 36 inhces</td>
                                    <td>$24</td>
                                    <td>$12</td>
                                </tr>
                                <tr>
                                    <td>18 x 24 inhces</td>
                                    <td>$32</td>
                                    <td>$12</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                )
            })}

        </div>
        <button className="at-btn" disabled={!selectedPrinter} onClick={() => onClickingSelectPrinter()}>
            Select this printer
        </button>
    </div>
}