import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ITutorailsCategory } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { toastService, tutorialsService } from '../../services'
import LoadingComponent from '../common/Loading.component'
import { CategoryComponent } from './Tutorials/Category.comonent'

interface IProps {

}
const TutorialsPage = ({ }: IProps) => {

    const history = useHistory()
    const [categories, setCategories] = useState<Array<ITutorailsCategory>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [categoryName, setCategoryName] = useState<string>('')

    const fetchCategories = () => {
        setLoading(true)
        tutorialsService.getCategories().then((res: AxiosResponse<IResponse<Array<ITutorailsCategory>>>) => {
            let { data } = res
            if (data.success) {
                setCategories([...data.data])
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const addCategory = () => {
        if (!categoryName) return
        setLoading(true)
        tutorialsService.addCategories({ title: categoryName }).then((res: AxiosResponse<IResponse<ITutorailsCategory>>) => {
            let { data } = res

            if (data.success) {
                toastService.success(data.message)
                fetchCategories()
            } else {
                toastService.error(data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchCategories()
    }, [])
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createcollection scroll-style-3 scrollable at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Tutorials</h2>
                </div>
                <form className="at-formtheme at-formcreatecollection" >
                    <fieldset>
                        <div className="form-group at-floatlabel form-w-100 mt-3">
                            <input type="text" value={categoryName} name="" className="form-control" autoComplete="off" placeholder=" " onChange={(e: any) => setCategoryName(e.target.value)} />
                            <label>Create Tutorial Category</label>
                            <button type="button" disabled={!categoryName} className="at-btnadd c-sunshadebg" onClick={() => addCategory()}><i className="icon-add-bold"></i></button>
                        </div>
                    </fieldset>

                </form>
                <div className="at-themebody">
                    {categories.length > 0 && categories.map((c: ITutorailsCategory) => <CategoryComponent category={c} fetch={fetchCategories} />)}
                </div>

            </div>
            {loading && <LoadingComponent className='at-transaction-loader' />}
        </main>
    )
}

export default TutorialsPage