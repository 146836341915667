import { AxiosError, AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { EServicesType } from '../../../../enums'
import { IUser } from '../../../../interfaces'
import { IResponse } from '../../../../interfaces/db.interface'
import { IServiceCategoryDocument, IConsultantServicesDocument } from '../../../../interfaces/services.interface'
import { routes } from '../../../../router'
import { toastService } from '../../../../services'
import { consultantService } from '../../../../services/consultantServices.service'
import { ConfirmAlertComponent } from '../../../common/ConfirmAlert.component'
import { UniversalModal } from '../../../common/Modals/Universal.modal'

interface IProps {
    c: IServiceCategoryDocument,
    user: IUser
    fetch: Function
}
export const ServiceCategoryComponent = ({ c, user, fetch }: IProps) => {

    const history = useHistory()
    const [addPopup, setAddPopup] = useState<boolean>(false)
    const [editPopup, setEditPopup] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [serviceName, setServiceName] = useState<string>('')
    const [categoryName, setCategoryName] = useState<string>('')
    const [confirm, setConfirm] = useState<boolean>(false)
    const [selectedService, setSelectedService] = useState<IConsultantServicesDocument>()
    const [checkedServices, setCheckedService] = useState<string>('')

    const openAddPopup = () => {
        setAddPopup(true)
    }
    const closeAddPopup = () => {
        setAddPopup(false)
    }

    const openEditPopup = () => {
        setCategoryName(c.title)
        setEditPopup(true)
    }
    const closeEditPopup = () => {
        setCategoryName('')
        setEditPopup(false)
    }

    const addServiceToCategory = () => {
        setLoading(true)

        let data = {
            user: user._id,
            name: serviceName,
            category: c._id
        }

        consultantService.addServices(data).then((res: AxiosResponse<IResponse<IConsultantServicesDocument>>) => {
            if (res.data.success) {
                fetch()
                closeAddPopup()
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onSelectServices = (s: IConsultantServicesDocument) => {
        if (checkedServices === s._id) {
            setCheckedService('')
            return
        }
        setCheckedService(s._id as string)
    }

    const onClickEdit = (s: IConsultantServicesDocument) => {
        if (c.type === EServicesType.DEFAULT) {
            if (!checkedServices || s._id !== checkedServices) {
                toastService.error('Please check the selected services')
                return
            }
            history.push(`${routes.account.services.professional.form}/${checkedServices}`)
        } else {
            history.push(`${routes.account.services.professional.form}/${s._id}`)
        }

    }

    const onEditCategory = () => {
        if (!categoryName) return
        setLoading(true)
        consultantService.updateCategoryName(c._id, { title: categoryName }).then((res: AxiosResponse<IResponse<IConsultantServicesDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetch()
                closeEditPopup()
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const openConfirm = (s: IConsultantServicesDocument) => {
        setConfirm(true)
        setSelectedService(s)
    }
    const closeConfirm = () => {
        setConfirm(false)
        setSelectedService({} as any)
    }

    const onDelete = () => {
        setLoading(true)
        consultantService.deleteService(selectedService?._id).then((res: AxiosResponse<IResponse<IConsultantServicesDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetch()
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
            closeConfirm()
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }
    return (
        <div key={c._id} className="at-categorybox">
            <div className="at-categoryhead">
                <div className="at-editableheading">
                    <div className="at-editableheading-inner">
                        <h4 className="at-additional-heading">{c.title}</h4>
                        {c.type !== "DEFAULT" && <ul className="at-editheading-icon">
                            <li>
                                <button className="" >
                                    <i className="icon-edit2" onClick={() => openEditPopup()}></i>
                                </button>
                            </li>
                        </ul>}
                    </div>
                </div>
                {c.type !== EServicesType.DEFAULT && <button className="at-btnadd-relative c-sunshadebg" type="button" onClick={() => openAddPopup()}>
                    <i className="icon-add-bold"></i>
                </button>}
            </div>
            {c.services && c.services.length > 0 && c.services.map((s: IConsultantServicesDocument) => {
                return (
                    <div key={s._id} className="at-categoryitem">
                        <div className="at-rightcateg">
                            <h6 className="p-0">
                                {s.name}
                            </h6>
                            <ul className="at-categaction">
                                <li>
                                    {c.type === EServicesType.DEFAULT ? <div className="at-checkbox at-checkbox-round" >
                                        <input type="checkbox" checked={(s._id as string) === checkedServices ? true : false} name="remember" id="abc" />
                                        <label htmlFor="abc" onClick={() => onSelectServices(s)}></label>
                                    </div> :
                                        <button className="at-btndelete" >
                                            <i className="icon-delete2" onClick={() => openConfirm(s)}></i>
                                        </button>
                                    }
                                </li>
                                <li>
                                    <button className="at-btndelete" >
                                        <i className="icon-edit2" onClick={() => onClickEdit(s)}></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            })}
            {
                addPopup && <UniversalModal open={addPopup} onClose={closeAddPopup}>
                    <h5 className="at-popupheading">ADD SERVICE</h5>
                    <div className="form-group form-w-100 at-floatlabel-h40">
                        <input type="text" name="name" disabled={loading} className="form-control " placeholder=" " value={serviceName} onChange={(e: any) => setServiceName(e.target.value)} />
                        <label>Service Name</label>
                    </div>
                    <div className="col-12 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-longbtn-h40" type='button' disabled={loading || !serviceName} onClick={() => addServiceToCategory()}>{loading ? 'Wait...' : 'Add'}</button>
                        </div>
                    </div>
                </UniversalModal>

            }

            {
                editPopup && <UniversalModal open={editPopup} onClose={closeEditPopup}>
                    <h5 className="at-popupheading">Edit Category</h5>
                    <div className="form-group form-w-100 at-floatlabel-h40">
                        <input type="text" name="name" disabled={loading} className="form-control " placeholder=" " value={categoryName} onChange={(e: any) => setCategoryName(e.target.value)} />
                        <label>Category Name</label>
                    </div>
                    <div className="col-12 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-longbtn-h40" type='button' disabled={loading || !categoryName} onClick={() => onEditCategory()}>{loading ? 'Wait...' : 'Update'}</button>
                        </div>
                    </div>
                </UniversalModal>

            }
            {confirm && <ConfirmAlertComponent
                open={confirm}
                message={`Are you sure you want to delete this service?`}
                title="Confirmation!"
                onCancel={closeConfirm}
                onConfirm={onDelete}
                confirmTitle="Delete" />}
        </div>
    )
}