import { AxiosResponse } from 'axios'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { screenTypeArray, userTypeArray } from '../../../constants'
import { bytesToMegaBytes, dataURLtoFile, _logger } from '../../../helper'
import { ITutorailsCategory, ITutorials, ITutorialState, tutorialInitailState } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { toastService, tutorialsService } from '../../../services'
import { eventImages } from '../../../shared'
import { ErrorComponent } from '../../common/Error.component'
import { SliderComponent } from '../../common/Slider.component'

interface IProps {
    category: ITutorailsCategory,
    submit: Function
    t?: ITutorials
    onClose: Function
}
export const AddTutorial = ({ category, submit, t, onClose }: IProps) => {

    const coverRef = useRef<any>(null)
    const tutorialRef = useRef<any>(null)
    const pdfRef = useRef<any>(null)
    const [state, setState] = useState<ITutorialState>({ ...tutorialInitailState })
    const [errors, setErrors] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [uploadMedia, setUploadMedia] = useState<any>({
        cover: null,
        tutorial: null
    })

    const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }

    const onSelectedAudience = (v: string) => {
        let data = state.roles

        if (data.includes(v)) {
            data = data.filter((r: string) => r !== v)
        } else {
            data.push(v)
        }

        setState({
            ...state,
            roles: data
        })
    }

    const checkSelectedRoles = (v: string) => {
        if (state.roles.includes(v)) {
            return true
        }

        return false
    }

    const onChangePoster = (e: any, name: string) => {
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            if (filesArray?.length === 0) return

            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {

                    setState({
                        ...state,
                        [name]: files[0],
                        [`${name}Preview`]: images
                    })
                    setUploadMedia({
                        ...uploadMedia,
                        [name]: files[0],
                    })
                }, error => {
                    console.error(error);
                });
        }
    }


    const onCropImage = (image: any, fileName: string, varName: string) => {
        const file = dataURLtoFile(image.cropped, fileName)
        setState({
            ...state,
            [varName]: file,
            [`${varName}Preview`]: [image.cropped]
        })

        setUploadMedia({
            ...uploadMedia,
            [varName]: file
        })

        // const file = dataURLtoFile(image.cropped, fileName)

    }

    const onDeleteImage = (name: string) => {
        setState({
            ...state,
            [name]: null,
            [`${name}Preview`]: []
        })
        setUploadMedia({
            ...uploadMedia,
            [name]: null
        })
    }

    const selectCoverImage = () => {

        coverRef.current.click();
    }
    const selectTutorial = () => {

        tutorialRef.current.click();
    }

    const selectPdf = () => {

        pdfRef.current.click();
    }

    const validateData = () => {
        let { cover, tutorial, name } = state
        let newErr: any = {}
        if (!cover) {
            newErr['cover'] = ['Cover image is required']
        }

        if (!tutorial) {
            newErr['tutorial'] = ['Video is required']
        }
        if (!name) {
            newErr['name'] = ['name is required']
        }

        if (Object.keys(newErr).length > 0) {
            setErrors({ ...newErr })
            return false
        }
        setErrors({})
        return true
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (!validateData()) return

        let { name, cover, roles, screen } = state
        let data = new FormData()

        data.append('name', name)
        if (uploadMedia.cover) {
            data.append('cover', cover)
        }
        if (uploadMedia.tutorial) {
            data.append('tutorial', state.tutorial)
        }
        data.append('screen', screen)
        data.append('category', category._id)
        data.append('roles', JSON.stringify(roles))
        if(state.pdf)data.append('pdf', state.pdf)

        if (state._id) {
            data.append('id', state._id)
        }

        setLoading(true)
        tutorialsService.addTutorials(data).then((res: AxiosResponse<IResponse<ITutorials>>) => {
            let { data } = res
            if (data.success) {
                toastService.success(data.message)
                setState({ ...tutorialInitailState })
                setUploadMedia({
                    cover: null,
                    tutorial: null
                })
                submit()
            } else {
                toastService.error(data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (t && t._id) {
            setState({
                name: t.name,
                screen: t.screen,
                roles: t.roles,
                cover: t.cover,
                coverPreview: [eventImages({}, t.cover, true)],
                tutorialPreview: [{ isVideo: t.tutorial.isVideo, url: eventImages({}, t.tutorial.url, true) }],
                tutorial: t.tutorial,
                _id: t._id,
                pdf: t.pdf ? t.pdf : ''
            })
        }
    }, [t])

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    return <div>
        <form onSubmit={onSubmit}>
            <h5 className="at-popupheading">Add Tutorial</h5>
            <div className="row row-margin-lr7 align-items-center">
                <div className="col-12 pad-lr-7">
                    <div className="form-group form-w-100 mb-0">
                        <div className="at-themefileupload at-upload-templates">
                            <input type="file" name="poster" ref={coverRef} multiple={false} accept="image/*" id="at-uploadfile-1" onChange={(e: any) => onChangePoster(e, 'cover')} />
                            <label onClick={selectCoverImage}>
                                <span><i className="icon-gallery"></i>Upload Cover</span>
                            </label>
                            {errorElement('cover')}
                        </div>
                    </div>
                </div>

                {state.coverPreview?.length > 0 && <div className={`form-group form-w-100 mb-2 position-relative`}>
                    <SliderComponent items={state.coverPreview} originalItems={state.coverPreview} onCrop={(image: any) => onCropImage(image, (state.cover as any).name, 'cover')} currentIndex={0} onChangeIndex={() => { }} />
                    {
                        state.coverPreview && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeleteImage('cover')}></i>)
                    }
                    {/* <CropImageComponent image={preview[selectedFile]} /> */}
                </div>}

                <div className="col-12 pad-lr-7">
                    <div className="form-group form-w-100 ">
                        <div className="at-themefileupload at-upload-templates">
                            <input type="file" name="poster" ref={tutorialRef} multiple={false} accept="video/*" id="at-uploadfile-2" onChange={(e: any) => onChangePoster(e, 'tutorial')} />
                            <label onClick={selectTutorial}>
                                <span><i className="icon-video-tutorial"></i>Upload Video</span>
                            </label>
                            {errorElement('tutorial')}
                        </div>
                    </div>
                </div>

                {state.tutorialPreview?.length > 0 && <div className={`form-group form-w-100 mb-2 position-relative`}>
                    <SliderComponent items={state.tutorialPreview} originalItems={state.tutorialPreview} onCrop={(image: any) => onCropImage(image, (state.tutorial as any).name, 'tutorial')} currentIndex={0} onChangeIndex={() => { }} />
                    {
                        state.tutorialPreview && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeleteImage('tutorial')}></i>)
                    }
                    {/* <CropImageComponent image={preview[selectedFile]} /> */}
                </div>}

                <div className="form-group form-w-50">
                    <div className="at-themefileupload">
                        <input type="file" name="pdf" accept="application/pdf" ref={pdfRef} onChange={(e: any) => onChangePoster(e, 'pdf')} id="at-uploadfile-2" />
                        <label onClick={selectPdf}>
                            {state.pdf && state.pdf !== "null" ? <span><i className="icon-tick  at-color-greenlight"></i>pdf uploaded</span> : <span><i className="icon-pdf-big"></i>Upload event pdf</span>}
                        </label>
                    </div>
                </div>

                <div className="col-12 pad-lr-7">
                    <div className="form-group at-floatlabel-h40">
                        <input
                            id=""
                            className="form-control "
                            name="name"
                            value={state.name}
                            placeholder=""
                            onChange={onChangeText}
                        />

                        <label>Tutorial Name</label>
                        {errorElement('name')}
                    </div>
                </div>

                <div className="col-12 pad-lr-7">
                    <div className="form-group ">
                        <span className="at-select at-floatlabel-h40">
                            <select className="form-control floating-select" value={state.screen} name="screen" onChange={onChangeText} >
                                <option value=""></option>
                                {screenTypeArray.map((s: { label: string, value: string }) => (
                                    <option value={s.value}>{s.label}</option>
                                ))}
                            </select>
                            <label>Select Screen to Place Video Link</label>
                        </span>
                    </div>
                </div>

            </div>
            <div className="row row-margin-lr7">
                <div className="col-12 pad-lr-7">
                    <h4 className="at-headingbold"> Select Audience <span>(one or multiple)</span></h4>
                </div>
                {userTypeArray.map((i: { label: string, value: string }) => {
                    return (
                        <div className="col-6 pad-lr-7">
                            <div className="at-checkbox at-checkboxtutorail" onClick={() => onSelectedAudience(i.value)}>
                                <input type="checkbox" name="remember" id="remember" checked={checkSelectedRoles(i.value)} />
                                <label>{i.label}</label>
                            </div>
                        </div>
                    )
                })}

            </div>
            <div className="row row-margin-lr7 mt-3">
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button className="at-btn at-btn-cancel at-longbtn-h40" type="button" onClick={() => onClose()} >Cancel</button>
                    </div>
                </div>
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button className="at-btn at-longbtn-h40" type="submit" disabled={loading} >{loading ? 'Wait...' : 'ADD'}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
}