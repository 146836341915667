
export const typesOfAssignment = `<p>In an exclusive assignment, the ARTIST can only assign the ART to one AGENT/GALLERY. The AGENT/GALLERY gets paid regardless of whether the ARTIST or the AGENT/GALLERY sell the ART.</p>
 <p>In a non-exclusive assignment, the ARTIST can assign the ART to one or many AGENT/GALLERY. Only the selling AGENT/GALLERY gets paid when the ART is sold by the AGENT/GALLERY.</p>
 `

export const usStateArray = [
    { isoCode: 'WA', desZip: '04101' },
    { isoCode: 'OR', desZip: '04101' },
    { isoCode: 'CA', desZip: '04101' },
    { isoCode: 'ID', desZip: '04101' },
    { isoCode: 'NV', desZip: '04101' },
    { isoCode: 'MT', desZip: '04101' },
    { isoCode: 'WY', desZip: '04101' },
    { isoCode: 'UT', desZip: '04101' },
    { isoCode: 'AZ', desZip: '04101' },
    { isoCode: 'CO', desZip: '04101' },
    { isoCode: 'NM', desZip: '04101' },
    { isoCode: 'ND', desZip: '04101' },
    { isoCode: 'SD', desZip: '04101' },
    { isoCode: 'NE', desZip: '04101' },
    { isoCode: 'KS', desZip: '04101' },
    { isoCode: 'OK', desZip: '04101' },
    { isoCode: 'TX', desZip: '04101' },
    { isoCode: 'AK', desZip: '04101' },
    { isoCode: 'HI', desZip: '04101' },
    { isoCode: 'MN', desZip: '98101' },
    { isoCode: 'IA', desZip: '98101' },
    { isoCode: 'MO', desZip: '98101' },
    { isoCode: 'AR', desZip: '98101' },
    { isoCode: 'LA', desZip: '98101' },
    { isoCode: 'WI', desZip: '98101' },
    { isoCode: 'IL', desZip: '98101' },
    { isoCode: 'MI', desZip: '98101' },
    { isoCode: 'IN', desZip: '98101' },
    { isoCode: 'KY', desZip: '98101' },
    { isoCode: 'TN', desZip: '98101' },
    { isoCode: 'MS', desZip: '98101' },
    { isoCode: 'AL', desZip: '98101' },
    { isoCode: 'GA', desZip: '98101' },
    { isoCode: 'FL', desZip: '98101' },
    { isoCode: 'SC', desZip: '98101' },
    { isoCode: 'NC', desZip: '98101' },
    { isoCode: 'WV', desZip: '98101' },
    { isoCode: 'VA', desZip: '98101' },
    { isoCode: 'OH', desZip: '98101' },
    { isoCode: 'PA', desZip: '98101' },
    { isoCode: 'MD', desZip: '98101' },
    { isoCode: 'DE', desZip: '98101' },
    { isoCode: 'NJ', desZip: '98101' },
    { isoCode: 'CT', desZip: '98101' },
    { isoCode: 'RI', desZip: '98101' },
    { isoCode: 'NY', desZip: '98101' },
    { isoCode: 'MA', desZip: '98101' },
    { isoCode: 'NE', desZip: '98101' },
    { isoCode: 'VT', desZip: '98101' },
    { isoCode: 'ME', desZip: '98101' },
]
