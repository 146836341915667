import { AxiosError, AxiosResponse } from 'axios';
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import StepWizard from "react-step-wizard";
import { ERole } from '../../enums';
import { bytesToMegaBytes, dataURLtoFile, _logger } from '../../helper';
import { IResponse } from '../../interfaces/db.interface';
import { ISubmissionProcessState, initialSubmissionState, ISubmissionProcess } from '../../interfaces/submission-process.interface';
import { routes } from '../../router';
import { submissionProcessService, toastService } from '../../services';
import { refreshUser } from '../../store/actions';
import { userSelector } from '../../store/selectors';
import { UniversalModal } from '../common/Modals/Universal.modal';
import { StepFive } from './FormSteps/StepFive';
import { StepFour } from './FormSteps/StepFour';
import { StepOne } from './FormSteps/StepOne';
import { StepThree } from './FormSteps/StepThree';
import { StepTwo } from './FormSteps/StepTwo';

interface IProps {

}
const Nav = (props: any) => {
    return (
        <div className="at-nav-steps">
            <div className="at-stepsheading">
                <div className="at-stpesback">
                    {props.currentStep && props.currentStep === 1 && <div className="at-opacity-0">BACK</div>}
                    {props.currentStep && props.currentStep !== 1 && <button disabled={props.loading} className="at-btn-stepcancel" onClick={() => props.previousStep()}>BACK</button>}
                </div>
                {/* <h4>STEP {props.currentStep} OF {props.totalSteps}</h4> */}
                <div className="at-stpesnext">
                    {props.currentStep && props.currentStep !== (props.totalSteps - 1) && props.currentStep !== props.totalSteps && <button disabled={props.loading} className="at-btn-stepnext" onClick={() => props.nextStep()}>NEXT</button>}
                    {props.currentStep && props.currentStep === (props.totalSteps - 1) && <button disabled={props.loading} className="at-btn-stepnext" onClick={() => props.onSubmit()}>SUBMIT</button>}
                </div>
            </div>
        </div>
    )
}
const SubmissionProcessForm = ({ }: IProps) => {

    const [showPopup, setPopup] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [addressVerify, setAddressVerify] = useState<boolean>(false)
    const history = useHistory()
    const location = useLocation()
    const ref = useRef<any>(null)
    const user = useSelector(userSelector)
    const dispatch = useDispatch()

    const [state, setState] = useState<ISubmissionProcessState>({ ...initialSubmissionState })
    const [errors, setErrors] = useState<any>({})

    const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }

    const setRole = (role: string) => {
        if (state.roles.includes(role)) {
            setState({
                ...state,
                roles: []
            })
            return
        }
        setState({
            ...state,
            roles: [role]
        })
    }

    const checkZip = (value: any) => {
        let url = `https://zip.getziptastic.com/v2/US/${value}`
        fetch(url, { method: 'GET' }).then((res) => res.json()).then((res: any) => {
            if (res.city && res.state && res.country) {
                // let newErrors: any = errors
                // if (newErrors && newErrors['zipcode']) {
                //     delete newErrors['zipcode']
                // }
                setState({
                    ...state,
                    city: res.city,
                    state: res.state,

                })
                setAddressVerify(true)
            } else {
                let newErrors: any = {}
                newErrors['zipcode'] = ['Please enter valid zip code']
                // this.setState({ errors: newErrors, zipLoading: false })
            }
        }).catch(err => {
            console.log(err)
        })

    }

    useEffect(() => {

        if (state.zipcode) {
            if (state.zipcode.length === 5) {
                console.log("run")
                checkZip(state.zipcode)
            } else {
                setState({
                    ...state,
                    address: '',
                    city: '',
                    state: ''
                })
                setAddressVerify(false)
            }
        }
    }, [state.zipcode])

    const checkValidUser = () => {
        if (user && user._id) {
            if (user.submissionApplication && user.submissionApplication._id) {
                history.goBack()
            }
        }
    }

    useEffect(() => {
        checkValidUser()
        if (location.pathname === routes.submissionProcess.serviceForm) {
            setState({
                ...state,
                roles: [ERole.CONSULTANT]
            })
        }
    }, [])

    const onChangePoster = (e: any, name: string) => {
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    setState({
                        ...state,
                        [name]: files[0]
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onChangeFiles = (e: any): void => {

        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    setState({
                        ...state,
                        images: [...state.images, ...files],
                        preview: [...state.preview, ...images],
                        previewsCopy: [...state.preview, ...images],
                        selectedFile: state.selectedFile ? state.selectedFile : 0
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onDeleteImage = (index: number) => {
        let newImages = state.images.filter((i: any, ind: number) => ind !== index)
        let newPreview = state.preview.filter((i: any, ind: number) => ind !== index)
        let newCopy = state.previewsCopy.filter((i: any, ind: number) => ind !== index)

        setState({
            ...state,
            images: newImages,
            preview: newPreview,
            previewsCopy: newCopy
        })
    }

    const onCrop = (cropedImage: any) => {
        const fileName = state.images[cropedImage.index].name
        const file = dataURLtoFile(cropedImage.cropped, fileName)
        const updatedFiles = state.images
        updatedFiles[cropedImage.index] = file
        const updatedPreviews = [...state.preview]
        updatedPreviews[cropedImage.index] = cropedImage.cropped
        setState({
            ...state,
            images: updatedFiles,
            preview: updatedPreviews,
            previewsCopy: updatedPreviews
        })
    }

    const onSubmit = () => {
        if (!onValidate()) return
        let data = new FormData()
        state.images.forEach((i: any) => data.append('files', i))
        data.append('bio', state.bio)
        data.append('cv', state.cv)
        data.append('city', state.city)
        data.append('address', state.address)
        data.append('state', state.state)
        data.append('zipcode', state.zipcode)
        data.append('webUrl', state.webUrl)
        data.append('instagramUrl', state.instagramUrl)
        data.append('roles', JSON.stringify(state.roles))
        setLoading(true)
        submissionProcessService.submitApplication(data).then((res: AxiosResponse<IResponse<ISubmissionProcess>>) => {
            console.log(res.data)
            ref?.current?.goToStep(ref.current?.totalSteps)
            dispatch(refreshUser())
            setLoading(false)
        }).catch((err: AxiosError) => {
            setLoading(false)
            console.log(err)
        })
    }

    const onValidate = () => {

        let newErr: any = {}

        if (state.roles && !state.roles.length) {
            newErr['role'] = ['Role is required']
            setErrors({ ...newErr })
            ref?.current?.goToStep(1)
            return false
        }
        if (!state.zipcode) {
            newErr['zipcode'] = ['Zipcode is required']
            setErrors({ ...newErr })
            ref?.current?.goToStep(1)
            return false
        }

        if (state.zipcode && !state.address) {
            newErr['address'] = ['Address is required']
            setErrors({ ...newErr })
            ref?.current?.goToStep(1)
            return false
        }

        if (!state.webUrl) {
            newErr['webUrl'] = ['Website is required']
            setErrors({ ...newErr })
            ref?.current?.goToStep(2)
            return false
        }

        if (!state.instagramUrl) {
            newErr['instagramUrl'] = ['Instagram link is required']
            setErrors({ ...newErr })
            ref?.current?.goToStep(2)
            return false
        }
        if (state.roles.includes(ERole.ARTIST)) {
            if (!state.bio) {
                newErr['bio'] = ['Bio is required']
                setErrors({ ...newErr })
                ref?.current?.goToStep(3)
                return false
            }
            if (!state.cv) {
                newErr['cv'] = ['CV is required']
                setErrors({ ...newErr })
                ref?.current?.goToStep(3)
                return false
            }
            if (state.images && !state.images.length) {
                newErr['images'] = ['Pictures are required']
                setErrors({ ...newErr })
                ref?.current?.goToStep(4)
                return false
            }
        }


        setErrors({})
        return true

    }

    const onSetInstance = (sw: any) => {
        ref.current = sw
    }

    const onFinish = () => {
        history.push(routes.home)
    }

    const getRoleSteps = () => {
        if (state.roles.includes(ERole.ARTIST)) {
            return (
                <StepWizard nav={<Nav onSubmit={onSubmit} loading={loading} />} instance={onSetInstance}>
                    <StepOne setRole={setRole} state={state} onChangeText={onChangeText} addressVerify={addressVerify} errors={errors} />
                    <StepTwo state={state} onChangeText={onChangeText} errors={errors} />
                    <StepThree state={state} errors={errors} onChangePoster={onChangePoster} />
                    <StepFour state={state} errors={errors} onSubmit={onSubmit} loading={loading} onChangeFiles={onChangeFiles} onDeleteImage={onDeleteImage} onCrop={onCrop} />
                    <StepFive onFinish={onFinish} />
                </StepWizard>
            )
        }

        if (state.roles.includes(ERole.AGENT) || state.roles.includes(ERole.CONSULTANT)) {
            return (
                <StepWizard nav={<Nav onSubmit={onSubmit} loading={loading} />} instance={onSetInstance}>
                    <StepOne setRole={setRole} state={state} onChangeText={onChangeText} addressVerify={addressVerify} errors={errors} />
                    <StepTwo state={state} onChangeText={onChangeText} errors={errors} />
                    {/* <StepThree state={state} errors={errors} onChangePoster={onChangePoster} />
                    <StepFour state={state} errors={errors} onSubmit={onSubmit} loading={loading} onChangeFiles={onChangeFiles} onDeleteImage={onDeleteImage} onCrop={onCrop} /> */}
                    <StepFive onFinish={onFinish} />
                </StepWizard>
            )
        }

        return (
            <StepWizard nav={<Nav onSubmit={onSubmit} loading={loading} />} instance={onSetInstance}>
                <StepOne setRole={setRole} state={state} onChangeText={onChangeText} addressVerify={addressVerify} errors={errors} />
            </StepWizard>
        )
    }

    return (
        <div className="at-createcollection at-contentarea">
            <div className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2> {state.roles.includes(ERole.CONSULTANT) ? 'Apply Online as a Service Provider' : 'Apply Online as an Artist or Gallery'}</h2>
                </div>
                {!state.roles.includes(ERole.CONSULTANT) && <div className="at-themebody-plr20">
                    <div className="at-orangetextbox">
                        <p className="mb-1">
                            Please be as complete as possible with your application. Have the following information ready before you start:
                    </p>
                        <ul>
                            <li><h6>ARTIST</h6></li>
                            <li>
                                1. Your website link and Instagram account link
                            </li>
                            <li>2. Your bio/artist statement in PDF format</li>
                            <li>3. Your CV in PDF format</li>
                            <li>4. 6-10 pictures in JPEG format of recent and available work, labeled as follows: LastName_Title_Medium_Size_Price.jpg</li>
                            <li><h6>GALLERY</h6></li>
                            <li>1. Your website link and Instagram account link</li>
                        </ul>
                    </div>
                </div>}
                {showPopup && <UniversalModal className="at-bottom0-h370" open={showPopup} hideCross={true} onClose={() => onFinish()} >
                    <div className="at-themesteps-wizard">
                        {getRoleSteps()}
                    </div>
                </UniversalModal>}
            </div>
        </div>
    )
}

export default SubmissionProcessForm