/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from 'react'
import { images } from '../../../assets/image'
import { IContact } from '../../../interfaces'
import { getInitials } from '../../../helper'
import { userImage } from '../../../shared'

class ContactComponent extends Component<TContact> {
	state: { checked: boolean } = {
		checked: false,
	}
	render() {
		const { contact, toggleSelect, editContact, isEditable, alreadyAdded, disabled, isSelected } = this.props
		const { checked } = this.state

		// this.props.contacts.map()
		return (
			<div className={`at-uservideocontent ${disabled ? 'contact-disabled' : ''}`}>
				<div
					className='at-checkbox'
					onClick={() => {
						toggleSelect(contact._id)
					}}
				>
					<input type='checkbox' checked={isSelected(contact._id)} onChange={() => { }} />
					<label></label>
				</div>
				<figure>
					<img
						src={
							contact.profile
								? userImage(contact.profile)
								: getInitials(contact.firstName, contact.lastName)
						}
						alt='User Image'
					/>
				</figure>
				<h3>
					{contact.firstName} {contact.lastName}
					<span>
						{contact.phoneNumber}
						{/* ({contact.code}) {contact.number} */}
					</span>
				</h3>
				{isEditable && <div className='dropdown at-themedropdown'>
					<div className="d-flex">
						<a
							className='dropdown-item'
							onClick={() => {
								if (isEditable && editContact) editContact(contact)
							}}
						>
							<i className='icon-edit'></i>
						</a>
					
					</div>
					{/* <button
						type='button'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'
						className='at-btndropdown'
					>
						<i className='icon-more'></i>
					</button>
					<div className='dropdown-menu dropdown-menu-right at-droopdownmenu'>
						<a className='dropdown-item'>
							<i className='icon-edit'></i>
						</a>
						<a className='dropdown-item'>
							<i className='icon-delete'></i>
						</a>
					</div> */}
				</div>}
			</div>
		)
	}
}

type TContact = {
	contact: IContact
	toggleSelect: Function
	editContact?: Function
	isEditable?: boolean
	disabled?: boolean
	alreadyAdded?: boolean
	isSelected: Function
}

export default ContactComponent
