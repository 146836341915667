import React, { Fragment, useState, useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import ImageModalComponent from './ImageModal.component'

import { routes } from '../../router'
import { VideoComponent } from './Video.component'

interface IProps {
	currentIndex?: number
	onChangeIndex?: any
	items: Array<string>
	originalItems: Array<string>
	component?: any
	onCrop: Function,
	canEdit?: boolean
}

const initialDetails = { cropped: '', original: '', imageIndex: 0 }

export const SliderComponent = (props: IProps) => {
	const { items, originalItems, currentIndex, onChangeIndex, onCrop, canEdit } = props

	const [imageModal, setImageModal] = useState({
		display: false,
		crop: true,
		details: { ...initialDetails },
	})

	useEffect(() => {
		setImageModal({ ...imageModal, details: { ...initialDetails } })
	}, [props.items])

	const openModal = (image: any, imageIndex: number) => {
		setImageModal({
			...imageModal,
			display: true,
			details: {
				original: image,
				cropped: image,
				imageIndex,
			},
		})
	}

	const onCropComplete = (image: { original: string; cropped: string }) => {
		setImageModal({
			...imageModal,
			display: false,
			details: {
				...imageModal.details,
				cropped: image.cropped,
			},
		})
		onCrop({ ...image, index: imageModal.details.imageIndex })
	}

	let swipeOptions: any = {}
	if (currentIndex) swipeOptions.index = currentIndex
	if (onChangeIndex) swipeOptions.onChangeIndex = (selectedFile: number) => {
		const isObject = typeof items[selectedFile] === 'object';
		const url = isObject ? (imageModal.details && imageModal.details.cropped) || (items[selectedFile] as any).url : items[selectedFile];
		if (!url.includes('base64') && imageModal.display) {
			setImageModal({
				...imageModal,
				display: false
			})
		}
		onChangeIndex(selectedFile)
	}

	const isEditPage = window.location.href.includes(routes.artCentral.post.edit) || window.location.href.includes(routes.artCentral.collection.edit) || window.location.href.includes(routes.artCentral.commissionWork.proposalCommissionwork) || window.location.href.includes(routes.artCentral.events.edit) || canEdit;

	const renderAsset = (asset: any, index: number) => {
		const isObject = typeof asset === 'object';

		const url = isObject ? (imageModal.details && imageModal.details.cropped) || asset.url : asset;
		const isVideo = isObject ? asset.isVideo : url.includes('video');
		return (
			!isVideo ? (
				<div
					key={`image-${index}`}
					className='img-holder position-relative'
				>
					<img
						src={url}
						alt=''
						className='post-image'
					/>
					{url.includes('base64') && <i className="icon-crop at-icon-crop" onClick={() => openModal(originalItems[index], index)} />}
				</div>) : <VideoComponent key={index} url={url} autoControls={true} index={currentIndex} />
		)
	}

	return (
		<Fragment>
			<SwipeableViews enableMouseEvents={!imageModal.display} {...swipeOptions}>
				{items.map((item: any, index: number) => renderAsset(item, index))}
			</SwipeableViews>
			{imageModal.display && (
				<ImageModalComponent onClose={onCropComplete} onCancel={() => setImageModal({ ...imageModal, display: false })} image={imageModal} />
			)}
		</Fragment>
	)
}
