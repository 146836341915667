import React, { useCallback, useEffect, useRef } from 'react'
// import Iframe from 'react-iframe'

interface IProps {
    url: any
}
export const IFrameComponent = ({ url }: IProps) => {


    const ref = useRef<any>(null)
    // let ref = useCallback((node: any) => {
    //     ref = node;
    // }, []);
    useEffect(() => {
        // console.log(ref.current?.contentWindow.location);
        // const node = document.createElement("div");
        // node.innerHTML = "<h1>Hello iFrame</h1>";

        // refiFrame.current.contentDocument.open();
        // refiFrame.current.contentDocument.write(node.innerHTML);
        // refiFrame.current.contentDocument.close();
    }, [ref]);
    return (

        <iframe id="iframe" ref={ref} src={url} className="portal-iframe h-100" title="original-iframe-title" />

    )
}
