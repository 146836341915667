import { IUser, ICurrentStudio } from '../../interfaces'
import { CURRENT_STUDIO_USER, IActionType } from '../types'


const initialState: ICurrentStudio | any = {
    user: {}
}
export const currentStudioReducer = (state = initialState, action: IActionType): ICurrentStudio => {
    switch (action.type) {
        case CURRENT_STUDIO_USER:
            return { user: action.payload }
        default:
            return state
    }
}
