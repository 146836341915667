import { IOffer } from "../interfaces"
import * as type from '../constants/transactionTypes'

export const actionMessage = (action: string, sellerName: string = `[Selle Name]`, buyerName: string = `[Buyer Name ]`, artName: string = `[Art Name]`) => {
    switch (action) {
        case type.REJECT_OFFER:
            return {
                message: `I Truly appreciate your interest in my ART, however, at this point I can't accept your Offer. Please consider increasing the amount of your offer.`,
                header: `${sellerName} is reject your offer`
            };
        case type.ACCEPT_OFFER:
            return {
                message: `Thank you for your offer. Your Offer has been approved, please click NEXT below to complete the purchase. `,
                header: `${sellerName} has approved your offer`
            }
        case type.CONFORMATION_OF_SHIPMENT:
            return {
                message: `Again, thank you for your purchase. I have shipped the ART you purchased. Please find below the shipping information and tracking number. `,
                header: `${sellerName} has approved your offer`
            }
        default:
            return { message: '', header: '' }
    }
}