import React, { Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ADMIN_PHONE } from '../../environment'
import { IStoreActionsStates, ISignupState, IQuestion, IStoreReducers, ISocialSignup, IVerifyPhoneResponse, IUser } from '../../interfaces';
import { authService, questionService, toastService } from '../../services';
import { BAD_REQUEST, CONFLICT } from 'http-status'
import { validationErrors } from '../../shared/functions';
import { ErrorComponent } from '../common/Error.component';
import { routes } from '../../router';
import PhoneInput from 'react-phone-number-input'
import { connect } from 'react-redux';
import { loginAction, saveUser, resetSocialLoginAction } from '../../store/actions';
import { CUser } from '../../classes';
import { getInitialsAsBlob, _logger } from '../../helper';
import { accountService } from '../../services/account.service';
import { ERole } from '../../enums';
import { AxiosResponse, AxiosError } from 'axios';
import LoadingComponent, { BSLoading } from '../common/Loading.component';
import * as bcrypt from 'bcryptjs'
import PinField from 'react-pin-field';
import { trim } from 'lodash';
import RSelect from 'react-select'
import { getUserRoles, userSelling } from '../../constants';

interface IProps extends IStoreActionsStates, RouteComponentProps {
    onClose: any
    openSignin: any
    saveUser?: any
    resetSocialLoginAction?: any
    openSubscriptionPopup: Function
}

const addressPlaceholder: any = {
    ARTIST: 'Art Studio Address',
    AGENT: 'Gallery Address',
    CONSULTANT: 'Business Address',
    SERVICE_PROVIDER: 'Business Address',
    PRIVATE_ART_BUYER: 'Address',
    PROFESSIONAL_ART_BUYER: 'Address'
}

const initialState: ISignupState = {
    errors: null,
    loading: false,
    questions: [],
    questionTab: 1,
    verifying: false,
    codeSent: false,
    phoneVerified: false,
    isValidZip: false,
    user: {
        firstName: '',
        lastName: '',
        phoneNumber: '+1',
        password: '',
        confirmPassword: '',
        isDeaf: false,
        question1: '',
        answer1: '',
        question2: '',
        answer2: '',
        provider: '',
        roles: [],
        gallery_name: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        selling: ''
    },
    zipLoading: false
};

let verifyKey = '';
class SignupComponent extends Component<IProps | any> {

    state: ISignupState = { ...initialState }

    constructor(props: any) {
        super(props);
    }

    get socialAuth(): ISocialSignup | any {
        return this.props.socialAuth || {};
    }
    componentDidMount() {
        this.fetchQuestions()
    }

    fetchQuestions = () => {
        questionService.list().then((response: any) => {
            this.setState({ questions: response.data, user: { ...this.state.user, ...this.socialAuth } })
        })
    }
    get questions1() {
        const { questions, user } = this.state;
        return questions.filter((q: IQuestion) => q._id !== user.question2)
    }

    get questions2() {
        const { questions, user } = this.state;
        return questions.filter((q: IQuestion) => q._id !== user.question1)
    }

    onChangeText = (e: FormEvent<HTMLInputElement> | any): void => {
        const { name, value } = e.target;
        _logger('', {
            name, value
        });

        const { user } = this.state;
        const _user = { ...user, [name]: value };
        this.setState({ user: _user })
    }

    toggleLoading = (): void => {
        this.setState({ loading: !this.state.loading })
    }

    // Submit registeration Form
    submit = (e: FormEvent): void => {
        e.preventDefault();
        let { user, phoneVerified, errors } = this.state;
        let newUserData = user
        // if (!this.validateAddress()) return

        newUserData['approve_status'] = this.setUserApprovalStatus()
        if (!phoneVerified) return toastService.info('Phone number is not verified.');
        if (user.roles.includes(ERole.AGENT) && trim(user.gallery_name).length < 1) return toastService.info('Gallery name is required.');
        if (user && user.provider) {
            const password = `66*&687@jkHKJ${(new Date()).getTime()}`;
            user.password = password;
            user.confirmPassword = password
        }
        // if (user.phoneNumber === ADMIN_PHONE) {
        //     newUserData['approve_status'] = 'approved'
        // }
        this.toggleLoading();
        authService.signup(newUserData).then(this.successHandler, this.errorHandler)
    }

    setUserApprovalStatus = () => {
        let { user } = this.state
        if ((user.roles.length === 0 || user.roles[0].length === 0)) return 'approved'
        if (user.phoneNumber === ADMIN_PHONE) return 'approved'

        return 'pending'
    }

    validateAddress = () => {
        let { errors, user } = this.state
        if (errors && errors['zipcode']) {
            return false
        }
        if (!user.zipcode) {
            let newErrors: any = {}
            newErrors['zipcode'] = ['Please enter zip code']
            this.setState({ errors: newErrors })
            return false
        }
        if (!user.address) {
            let newErrors: any = {}
            newErrors['address'] = ['Please enter address']
            this.setState({ errors: newErrors })
            return false
        }
        if (user.zipcode.length < 5) {
            let newErrors: any = {}
            newErrors['zipcode'] = ['Please enter valid zip code']
            this.setState({ errors: newErrors })
            return false
        }
        this.setState({ errors: null })
        return true
    }

    successHandler = async (response: any) => {
        let newUser: IUser = response.data.user
        await this.setState({ user: response.data.user })
        this.updatePicture(newUser._id)
        this.props.resetSocialLoginAction();
        this.toggleLoading();

        toastService.success(response.data.message);
        const user: CUser = new CUser(response.data.user);
        toastService.info(`Welcome, ${user.fullName}`)
        this.props.loginAction(response.data);
        this.props.saveUser(response.data.user);
        this.props.history.push(routes.home)
        this.props.onClose()
    }

    updatePicture = async (id: any) => {
        const { firstName, lastName, phoneNumber, question1, question2, answer1, answer2, isDeaf, _id, city, address, state, zipcode, } = this.state.user;

        // let data: any = {
        //     firstName, lastName, phoneNumber, question1, question2, answer1, answer2, isDeaf, _id, city, address, state, zipcode
        // }
        const formData = new FormData()
        // for (let key in data) {
        //     formData.append(key, data[key]);
        // }

        const blob = await getInitialsAsBlob(firstName, lastName)

        formData.append('file', blob, `${firstName}_${lastName}_${Date.now()}.jpg`);

        authService.createUserImage(id, formData).then()
    }

    errorHandler = ({ response }: any) => {
        let errors = null;
        this.toggleLoading();
        switch (response.status) {
            case BAD_REQUEST:
                errors = validationErrors(response.data);
                break;
            case CONFLICT:
                errors = response.data
                toastService.error(response.data.message)
                break;
            default:
                break;
        }
        this.setState({ errors })
    }

    errorElement = (key: string) => (
        this.state.errors && this.state.errors[key] && <ErrorComponent errors={this.state.errors[key]} multiple={false} />
    )

    questionElement = (type: number) => (
        <div className={`at-tabpane tab-pane fade show ${this.state.questionTab === type ? 'active' : ''}`} id={`questionTab${type}`} role="tabpanel" aria-labelledby="questionone-tab">
            <div className="form-group">
                <span className="at-select">
                    <select id={`question${type}`} name={`question${type}`} onChange={this.onChangeText}>
                        <option>Select Question {type}</option>
                        {
                            (type === 1 ? this.questions1 : this.questions2).map((q: IQuestion) => <option key={q._id} value={q._id}>{q.title}</option>)
                        }
                    </select>
                </span>
                {this.errorElement(`question${type}`)}
            </div>
            <div className="form-group">
                <input autoComplete="off" type="text" name={`answer${type}`} className="form-control" placeholder="Security Answer" onChange={this.onChangeText} />
                {this.errorElement(`answer${type}`)}
            </div>
        </div>
    )

    setRole = (role: any): void => {
        let { user } = this.state
        if (user.roles.includes(role.value)) {
            user.roles = []
        } else {
            user.roles = [role.value]
        }
        this.setState({ user: { ...user } })
    }

    setSellingType = (role: any): void => {
        let { user } = this.state
        user.selling = role.value
        this.setState({ user: { ...user } })
    }

    verifyPhone = () => {
        this.setState({ verifying: true, codeSent: false })
        authService.verifyPhone({ phoneNumber: this.state.user.phoneNumber }).then((response: AxiosResponse<IVerifyPhoneResponse>) => {
            verifyKey = response.data.id;
            toastService.success(response.data.message)
            this.setState({ verifying: true, codeSent: true })
            this.setState({ verifying: false })
        }, (error: AxiosError) => {
            this.setState({ verifying: false })
            toastService.error(error.response?.data.message)
        })
    }

    verifyPinCode = (value: any) => {
        if (value.length < 4) return;
        const compare = bcrypt.compareSync(`${value}`, verifyKey);
        if (!compare) return toastService.error('Incorrect pin')
        this.setState({ phoneVerified: true })
    }

    onChangeZip = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        _logger('', {
            name, value
        });

        const { user } = this.state;
        const _user = { ...user, [name]: value };
        this.setState({ user: _user }, () => {
            if (value.length === 5) {
                this.checkZip(value);
            }
            else {
                this.setState({ isValidZip: false })
            }
        })
    }

    checkZip = (value: any) => {
        const { user, errors } = this.state;
        this.setState({ zipLoading: true })
        let url = `https://zip.getziptastic.com/v2/US/${value}`
        fetch(url, { method: 'GET' }).then((res) => res.json()).then((res: any) => {
            if (res.city && res.state && res.country) {
                let newErrors: any = errors
                if (newErrors && newErrors['zipcode']) {
                    delete newErrors['zipcode']
                }
                this.setState({
                    user: {
                        ...user,
                        city: res.city,
                        state: res.state,
                    },
                    isValidZip: true,
                    errors: newErrors,
                    zipLoading: false
                })
            } else {
                let newErrors: any = {}
                newErrors['zipcode'] = ['Please enter valid zip code']
                this.setState({ errors: newErrors, zipLoading: false })
            }
        }).catch(err => {
            let newErrors: any = errors
            newErrors['zipcode'] = ['Please enter valid zip code']
            this.setState({ errors: newErrors, zipLoading: false })
        })

    }

    render() {
        const { loading, errors, user, questionTab, verifying, codeSent, phoneVerified, isValidZip, zipLoading } = this.state;

        const tab1 = errors && (errors.question1 || errors.answer1) ? 'text-danger' : questionTab === 1 && 'active';
        const tab2 = errors && (errors.question2 || errors.answer2) ? 'text-danger' : questionTab === 2 && 'active';
        return (
            <div className="at-themepopupbox">
                <a className="at-close at-btnclosesignup" id="at-close" onClick={this.props.onClose}><i className="icon-cancel"></i></a>
                <form className="at-formtheme at-signupform" onSubmit={this.submit}>
                    <fieldset>
                        <h2>New User</h2>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="form-group at-floatlabel-h40">
                                    <input autoComplete="off" type="text" name="firstName" className="form-control" value={user.firstName} placeholder=" " onChange={this.onChangeText} />
                                    <label>First name</label>
                                    {this.errorElement('firstName')}
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="form-group at-floatlabel-h40">
                                    <input autoComplete="off" type="text" name="lastName" className="form-control" value={user.lastName} placeholder=" " onChange={this.onChangeText} />
                                    <label>Last name</label>
                                    {this.errorElement('lastName')}
                                </div>
                            </div>
                            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group mb-3">
                                    <div className="at-deafhardholder" onClick={e => this.setState({ user: { ...user, isDeaf: !user.isDeaf } })}>
                                        <em className="at-deafhardicon"><i className="icon-hand"></i></em>
                                        <span>Deaf or Hard of Hearing</span>
                                        <div className="onoffswitch">
                                            <input autoComplete="off" type="checkbox" name="isDeaf" className="onoffswitch-checkbox" id="myonoffswitch4" checked={user.isDeaf} />
                                            <label className="onoffswitch-label"></label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group mb-3">
                                    <div className="at-deafhardholder" onClick={e => this.setRole({ value: ERole.PRIVATE_ART_BUYER })}>
                                        <em className="at-deafhardicon"><i className="icon-gallery"></i></em>
                                        <span>Private Art Buyer</span>
                                        <div className="onoffswitch">
                                            <input type="checkbox" name="agent" className="onoffswitch-checkbox" value={ERole.PRIVATE_ART_BUYER} id="myonoffswitch5" checked={user.roles.includes(ERole.PRIVATE_ART_BUYER)} />
                                            <label className="onoffswitch-label"></label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <div className="at-deafhardholder" onClick={e => this.setRole({ value: ERole.PROFESSIONAL_ART_BUYER })}>
                                        <em className="at-deafhardicon"><i className="icon-color-palette"></i></em>
                                        <span>Professional/institutional Art Buyer</span>
                                        <div className="onoffswitch">
                                            <input type="checkbox" name="artist" className="onoffswitch-checkbox" value={ERole.PROFESSIONAL_ART_BUYER} id="myonoffswitch6" checked={user.roles.includes(ERole.PROFESSIONAL_ART_BUYER)} />
                                            <label className="onoffswitch-label"></label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* {!isBuyer && <>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group at-FormInputHAuto at-zindex10 mb-3">
                                        <RSelect name='selling' options={userSelling} onChange={this.setSellingType} placeholder='What are you selling?' />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group at-FormInputHAuto at-zindex9">
                                        <RSelect name='selling' options={getUserRoles(user.selling)} onChange={this.setRole} placeholder='What is your role?' />
                                    </div>
                                </div>
                            </>} */}
                            {/* <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 ${!user.roles.includes(ERole.AGENT) ? 'd-none' : ''}`}>
                                <div className="form-group at-floatlabel-h40">
                                    <input autoComplete="off" type="text" name="gallery_name" className="form-control" placeholder=" " onChange={this.onChangeText} />
                                    <label>Gallery Name</label>
                                    {this.errorElement('gallery_name')}
                                </div>
                            </div> */}

                            {isValidZip &&
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group at-floatlabel-h40">
                                        <input type="text" name="address" className="form-control" placeholder=" " value={user.address} onChange={this.onChangeText} />
                                        <label>{addressPlaceholder[(user.roles as any)[0]] ? addressPlaceholder[(user.roles as any)[0]] : 'Address'}</label>
                                    </div>
                                    {this.errorElement('address')}
                                </div>}
                            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                <div className="row">
                                    {isValidZip && <><div className="col-4 col-sm-4 col-md-4 col-lg-4 at-colpadding-right-0">
                                        <div className="form-group">
                                            <input type="text" disabled name="city" className="form-control" placeholder="City" value={user.city} />
                                        </div>
                                    </div>
                                        <div className="col-4 col-sm-4 col-md-4 col-lg-4 at-colpadding-right-0">
                                            <div className="form-group">
                                                <input type="text" disabled name="state" className="form-control" placeholder="State" value={user.state} />
                                            </div>
                                        </div> </>}
                                    <div className={!isValidZip ? 'col-xs-12 col-sm-12 col-md-12 col-lg-12' : 'col-4 col-sm-4 col-md-4 col-lg-4'}>
                                        <div className="form-group at-floatlabel-h40">
                                            <input type="text" name="zipcode" className="form-control" placeholder=" " value={user.zipcode} onChange={this.onChangeZip} />
                                            <label>Zipcode</label>
                                            {this.errorElement('zipcode')}
                                        </div>

                                    </div>
                                </div>
                            </div> */}

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group mb-0 d-flex align-items-center nh-input-verify">
                                    <PhoneInput
                                        placeholder="Phone (e.g., +1 412 877 4312)"
                                        value={user.phoneNumber}
                                        defaultCountry="US"
                                        onChange={(phoneNumber: any) => this.setState({ phoneVerified: false, codeSent: false, user: { ...user, phoneNumber } })} />

                                    {!verifying ? (
                                        !phoneVerified ? <button onClick={this.verifyPhone} className="at-btn at-minwidth-78 ">{codeSent ? 'RESEND' : 'VERIFY'}</button> : <span className="icon-tick at-verify-tick"></span>
                                    ) : <BSLoading />}
                                </div>
                                {this.errorElement('phoneNumber')}
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    {(codeSent && !phoneVerified) && <>
                                        <PinField type="number" length={4} validate={/^[0-9]$/} onChange={this.verifyPinCode} className="form-control mt-3" />
                                        <p className="at-text-verify-danger">Please enter the 4-digit code sent to you via text</p>
                                    </>}
                                </div>
                            </div>
                            <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 ${user.provider && 'display-none'}`}>
                                <div className="form-group at-floatlabel-h40">
                                    <input autoComplete="off" type="password" name="password" className="form-control" placeholder=" " onChange={this.onChangeText} />
                                    <label>Password</label>
                                    {this.errorElement('password')}
                                </div>
                            </div>
                            <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 ${user.provider && 'display-none'}`}>
                                <div className="form-group at-floatlabel-h40">
                                    <input autoComplete="off" type="password" name="confirmPassword" className="form-control" placeholder=" " onChange={this.onChangeText} />
                                    <label>Confirm Password</label>
                                    {this.errorElement('confirmPassword')}
                                </div>
                            </div>
                            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <ul className="nav nav-tabs at-themenavtabs at-questiontab" id="at-questiontab" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab1}`} id="questionone-tab" data-toggle="tab" onClick={() => this.setState({ questionTab: 1 })} role="tab" aria-controls="questionone" aria-selected="true"><span>Question 1</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab2}`} id="questiontwo-tab" data-toggle="tab" onClick={() => this.setState({ questionTab: 2 })} role="tab" aria-controls="questiontwo" aria-selected="false"><span>Question 2</span></a>
                                        </li>
                                    </ul>
                                    <div className="at-tabcontent tab-content" id="myTabContent">
                                        { this.questionElement(1) }
                                        { this.questionElement(2) }
                                    </div>
                                </div> 
                        </div> */}
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <button className="at-btn at-btnrecoveycode at-minwidth-100" disabled={loading} type="submit">{loading ? 'Please wait...' : 'SIGNUP'}</button>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <em>Already have an account?<a className="at-gotologinfromsignup" onClick={this.props.openSignin}>LOGIN</a></em>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapAction = {
    loginAction,
    saveUser,
    resetSocialLoginAction
}
const mapState = (state: IStoreReducers) => ({
    socialAuth: state.socialAuth
})
export default connect(mapState, mapAction)(SignupComponent);
