import { AxiosResponse } from 'axios'
import { data } from 'jquery'
import React, { FormEvent, useState, useEffect } from 'react'
import { ECostFactorUnit, EWeightFactorType, EWeightFactorUnit } from '../../../enums'
import { initWeightFactorState, IWeihgtFactorsDocument, IWeihgtFactorsState } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { adminService, toastService } from '../../../services'
import { ErrorComponent } from '../../common/Error.component'

interface IProps {
    onClose: Function
    onSubmit: Function
    factoryType: string
    factor?: IWeihgtFactorsDocument
}
export const WeightFactorForm = ({ onClose, factoryType, onSubmit, factor }: IProps) => {
    const [state, setState] = useState<IWeihgtFactorsState>({ ...initWeightFactorState })
    const [errors, setErrors] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)


    useEffect(() => {
        if (factor && factor._id) {
            setState({
                ...state,
                id: factor.id,
                _id: factor._id,
                name: factor.name,
                sizingFactor: factor.sizingFactor,
                businessWeightFactor: factor.businessWeightFactor,
                weightFactor: factor.weightFactor,
                rolled: factor.rolled,
                weightFactorUnit: factor.weightFactorUnit,
                factorType: factoryType,
                isActive: factor.isActive,
                isHide: factor.isHide,
                ...(factor.costFactor ? { costFactor: factor.costFactor } : null),
                ...(factor.costFactorUnit ? { costFactorUnit: factor.costFactorUnit } : null)
            })
        }
    }, [data])


    const onChange = (e: FormEvent<HTMLInputElement> | any) => {
        let { name, value } = e.target

        setState({
            ...state,
            [name]: value
        })
    }

    const onChangeRole = () => {
        setState({
            ...state,
            rolled: !state.rolled
        })
    }

    const onValidate = () => {

        let newErr: any = {}
        if (!state.name) {
            newErr['name'] = ['Name is required']
            setErrors({ ...newErr })

            return false
        }

        if (!state.sizingFactor) {
            newErr['sizingFactor'] = ['Sizing Factor is required']
            setErrors({ ...newErr })

            return false
        }

        if (Number(state.sizingFactor) < 1) {
            newErr['sizingFactor'] = ['Value must be greater than or equal to 1']
            setErrors({ ...newErr })

            return false
        }

        if (!state.businessWeightFactor) {
            newErr['businessWeightFactor'] = ['Business Weight Factor is required']
            setErrors({ ...newErr })

            return false
        }

        if (Number(state.businessWeightFactor) < 1) {
            newErr['businessWeightFactor'] = ['Value must be greater than or equal to 1']
            setErrors({ ...newErr })

            return false
        }

        if (!state.weightFactor) {
            newErr['weightFactor'] = ['Weight Factor is required']
            setErrors({ ...newErr })

            return false
        }

        if (state.weightFactor) {
            state.weightFactor.toString().split(".")


            if (Number(state.weightFactor) < 0 || (state.weightFactor.toString().includes('.') && state.weightFactor.toString().split(".")[1].length > 4)) {
                newErr['weightFactor'] = ['Value must be greater than or equal to 0.0001']
                setErrors({ ...newErr })

                return false
            }
        }


        if (!state.weightFactorUnit) {
            newErr['weightFactorUnit'] = ['Weight Factor Unit is required']
            setErrors({ ...newErr })

            return false
        }

        if (factoryType === EWeightFactorType.PACKAGE) {

            if (!state.costFactor) {
                newErr['costFactor'] = ['Cost Factor is required']
                setErrors({ ...newErr })

                return false
            }
            if (state.weightFactor) {
                state.weightFactor.toString().split(".")


                if (state.costFactor && (Number(state.costFactor) < 0 || (state.costFactor.toString().includes('.') && state.costFactor.toString().split(".")[1].length > 3))) {
                    newErr['costFactor'] = ['Value must be greater than or equal to 0.001']
                    setErrors({ ...newErr })

                    return false
                }
            }


            if (!state.costFactorUnit) {
                newErr['costFactorUnit'] = ['Cost Factor Unit is required']
                setErrors({ ...newErr })

                return false
            }

        }


        setErrors({})
        return true

    }
    const onAddFactor = (e: FormEvent) => {
        e.preventDefault()
        if (!onValidate()) return
        setLoading(true)

        let data = {
            name: state.name,
            sizingFactor: state.sizingFactor,
            businessWeightFactor: state.businessWeightFactor,
            weightFactor: state.weightFactor,
            rolled: state.rolled,
            weightFactorUnit: state.weightFactorUnit,
            factorType: factoryType,
            ...(state.costFactor ? { costFactor: state.costFactor } : null),
            ...(state.costFactorUnit ? { costFactorUnit: state.costFactorUnit } : null)
        }

        if (factor && factor._id) {
            adminService.updateWeightFactors(factor._id, data).then((res: AxiosResponse<IResponse<IWeihgtFactorsDocument>>) => {
                if (res.data.success) {
                    toastService.success(res.data.message)
                    onSubmit()
                }
                setLoading(false)
            }).catch((err: any) => {
                setLoading(false)
                console.log(err)
            })
            return
        }
        adminService.createWeightFactors(data).then((res: AxiosResponse<IResponse<IWeihgtFactorsDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                onSubmit()
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }


    return (
        <div>
            <h5 className="at-popupheading pb-3">Details</h5>
            <form onSubmit={onAddFactor} noValidate>
                <div className="row row-margin-lr7 align-items-center">
                    <div className="col-12 pad-lr-7">
                        <div className="form-group at-floatlabel-h40">
                            <input type="text" name="name" className="form-control" value={state.name} placeholder=" " onChange={onChange} />
                            <label>Name</label>
                            {errors && errors['name'] && <ErrorComponent errors={errors['name']} multiple={false} />}
                        </div>
                    </div>
                    <div className="col-6">

                    </div>
                    <div className="col-6 pad-lr-7">
                        <div className="form-group at-form-custmargin">
                            <div className="at-deafhardholder" onClick={() => onChangeRole()}>
                                <span>Rolled Product</span>
                                <div className="onoffswitch">
                                    <input
                                        autoComplete="off"
                                        type="checkbox"
                                        name="isDeaf"
                                        className="onoffswitch-checkbox"
                                        id="myonoffswitch4"
                                        checked={state.rolled}
                                    />
                                    <label className="onoffswitch-label"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pad-lr-7">
                        <div className="form-group at-floatlabel-h40">
                            <input type="number" name="sizingFactor" className="form-control" value={state.sizingFactor} placeholder=" " onChange={onChange} />
                            <label>Sizing Factor</label>
                            {errors && errors['sizingFactor'] && <ErrorComponent errors={errors['sizingFactor']} multiple={false} />}
                        </div>
                    </div>
                    <div className="col-12 pad-lr-7">
                        <div className="form-group at-floatlabel-h40">
                            <input type="number" name="businessWeightFactor" className="form-control" value={state.businessWeightFactor} placeholder=" " onChange={onChange} />
                            <label>Business Weight Factor</label>
                            {errors && errors['businessWeightFactor'] && <ErrorComponent errors={errors['businessWeightFactor']} multiple={false} />}
                        </div>
                    </div>
                    <div className="col-12 pad-lr-7">
                        <div className="form-group at-floatlabel-h40">
                            <input type="number" name="weightFactor" className="form-control" value={state.weightFactor} placeholder=" " onChange={onChange} />
                            <label>Weight Factor (lbs)</label>
                            {errors && errors['weightFactor'] && <ErrorComponent errors={errors['weightFactor']} multiple={false} />}
                        </div>
                    </div>
                    <div className="col-12 pad-lr-7">
                        <div className="form-group at-select text-capitalize at-floatlabel-h40">
                            <select
                                className="form-control floating-select"
                                value={state.weightFactorUnit}
                                name="weightFactorUnit"
                                onChange={onChange}
                                placeholder=" "
                                required

                            >
                                <option value="" disabled></option>
                                <option value={EWeightFactorUnit.POUNDS_PER_SQUARE_INCH} >Pounds per Square Inch</option>
                                <option value={EWeightFactorUnit.POUNDS_PER_LINEAR_INCH} >Pounds per Linear Inch</option>
                                <option value={EWeightFactorUnit.POUNDS_PER_CUBIC_INCH} >Pounds per Cubic Inch</option>
                            </select>
                            <label className="at-lineheight-27">Weight Factor Unit</label>
                            {errors && errors['weightFactorUnit'] && <ErrorComponent errors={errors['weightFactorUnit']} multiple={false} />}
                        </div>
                    </div>
                    {factoryType === EWeightFactorType.PACKAGE &&

                        <>
                            <div className="col-12 pad-lr-7">
                                <div className="form-group at-floatlabel-h40">
                                    <input type="number" name="costFactor" className="form-control" value={state.costFactor} placeholder=" " onChange={onChange} />
                                    <label>Cost Factor ($)</label>
                                    {errors && errors['costFactor'] && <ErrorComponent errors={errors['costFactor']} multiple={false} />}
                                </div>
                            </div>
                            <div className="col-12 pad-lr-7">
                                <div className="form-group at-select text-capitalize at-floatlabel-h40">
                                    <select
                                        className="form-control floating-select"
                                        value={state.costFactorUnit}
                                        name="costFactorUnit"
                                        onChange={onChange}
                                        placeholder=" "
                                        required
                                    >
                                        <option value=""></option>
                                        <option value={ECostFactorUnit.USD_PER_SQUARE_INCH} >USD per Square Inch</option>
                                        <option value={ECostFactorUnit.USD_PER_LINEAR_INCH} >USD per Linear Inch</option>
                                        <option value={ECostFactorUnit.USD_PER_CUBIC_INCH} >USD per Cubic Inch</option>
                                    </select>
                                    <label className="at-lineheight-27">Cost Factor Unit</label>
                                    {errors && errors['costFactorUnit'] && <ErrorComponent errors={errors['costFactorUnit']} multiple={false} />}
                                </div>
                            </div>
                        </>
                    }
                    <div className="col-6 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-btn-cancel at-longbtn-h40" onClick={() => onClose()}>Cancel</button>
                        </div>
                    </div>
                    <div className="col-6 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-longbtn-h40" type="submit" disabled={loading}>{loading ? 'Wait...' : 'Save'}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}