import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import AboutPage from '../components/AboutPage'
import ContactPage from '../components/ContactPage'
import HomePage from './Home'
import { routes } from './routes'
import '../assets/templates.scss'
import { useSelector } from 'react-redux'
import { authSelector, inPlatformSelector, templateSelector } from '../../store/selectors'
import { HttpService } from '../../services/base.service'
import PrivateRoutes from '../components/PrivateRoutes'
import { TemplateContainer } from '../assets/_global-template-style'
import GalleryPage from './GalleryPage'
import ArtDetailPage from './ArtDetailPage'
import CollectionPage from './CollectionPage'
import ExhibitionsPage from './ExhibitionsPage'
import EventPage from './EventPage'
import PortalComponent from '../../components/common/Portal.component'

interface IProps {
    name: string
}
const MainRoute = ({ name }: IProps) => {
    const location = useLocation()
    const auth = useSelector(authSelector).access_token
    HttpService.setToken(auth)
    const template = useSelector(templateSelector)
    const inPlatform = useSelector(inPlatformSelector)

    // let checkRoute = location.pathname.includes(routes(template.url).exhibitions.index) || location.pathname.includes(routes(template.url).gallery.index)
    return (
        <div className={`At-TemplateWrap ${inPlatform.activeSplitScreen ? "At-PortalEnabled" : ''}`}>
            <TemplateContainer templateBackground_Color={template.backgroundColor || '#fff'} textColor={template.textColor} lineColor={template.lineColor || '#eaedf1'} className="At-Theme">

                <Header />
                <Switch>
                    <Route path={routes(name).home} exact component={HomePage} />
                    <Route path={routes(name).about} exact component={AboutPage} />
                    <Route path={routes(name).Contact} exact component={ContactPage} />
                    <PrivateRoutes>
                        <Route path={routes(name).exhibitions.index} exact component={ExhibitionsPage} />
                        <Route path={`${routes(name).exhibitions.viewSingleExhibition}/:id`} exact component={EventPage} />
                        <Route path={routes(name).gallery.index} exact component={GalleryPage} />
                        <Route path={`${routes(name).gallery.collectionView}/:id`} exact component={CollectionPage} />
                        <Route path={`${routes(name).gallery.collectionItemView}/:id`} exact component={ArtDetailPage} />
                    </PrivateRoutes>
                </Switch>
                <Footer />
            {(inPlatform.activeSplitScreen) ? <PortalComponent /> : null}
            </TemplateContainer>
        </div>

    )
}

export default MainRoute