import React from 'react'
import moment from 'moment'
import { INotification, ITransaction } from '../../interfaces'
import { collectionImage } from '../../shared'
import { CurrencyFormat } from '../common/CurrencyFormat'
import { ERole } from '../../enums'
import { ISelectedService } from '../../interfaces/services.interface'

interface IProps {
    notification: INotification
    getItemType: Function
}

export const SoldPrintingServiceLayout = ({ notification, getItemType }: IProps) => {
    let transaction = (notification.transaction as ITransaction)
    let artist = transaction && transaction.artistID && transaction.artistID._id ? transaction.artistID : transaction.sellerID
    let seller = transaction.sellerID && transaction.sellerID._id ? transaction.sellerID : artist
    let buyer = transaction.buyerID && transaction.buyerID._id ? transaction.buyerID : notification.from
    let isAgent = artist && artist.roles.includes(ERole.AGENT)
    let artistName = artist && isAgent ? transaction.itemID?.artistName || `${artist?.firstName} ${artist?.lastName}` : `${artist?.firstName} ${artist?.lastName}`
    const service = transaction.selectedService && transaction.selectedService._id ? transaction.selectedService : undefined

    const getServicedata = () => {
        if (!service) {
            return null
        }
        let buyerServive = service.selectedServices.find((i: ISelectedService) => i._id === transaction.buyerService)


        return (!buyerServive ? null : <>
            {buyerServive.printingService && <div className="at-transact-line pt-2">
                <p>Print Medium: <span>{buyerServive.printingService.name}</span></p>
            </div>}

            {buyerServive.framingService && <div className="at-transact-line pt-2">
                <p>Framing Option: <span>{buyerServive.framingService.name}</span></p>
            </div>}
            {buyerServive.selectedSize && <div className="at-transact-line pt-2">
                <p>Size: <span>{buyerServive.selectedSize.width} x {buyerServive.selectedSize.height}</span></p>
            </div>}
        </>)
    }

    const getTotal = () => {
        if (!service) {
            return null
        }
        let buyerServive = service.selectedServices.find((i: ISelectedService) => i._id === transaction.buyerService)
        return !buyerServive ? null : <>
            {buyerServive.totalCost && <div className="at-transact-line pt-2">
                <p>Total Service Fees (including shipping): <span><CurrencyFormat amount={Number(buyerServive.totalCost)} /></span></p>
            </div>}
        </>
    }
    return (
        <div className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>{moment(notification.createdAt).format("L")}</p>
                        <p className="pl-5">{moment(notification.createdAt).format("hh:mm:ss a")}</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Seller:</p>
                        <p className="pl-2 text-capitalize"><span>{seller.firstName} {seller.lastName}</span></p>
                    </div>
                    <div className="at-transact-line">
                        <p>Artist:</p>
                        <p className="pl-2 text-capitalize"><span>{artistName}</span></p>
                    </div>
                    <div className="at-transact-line" >
                        <p>Buyer:</p>
                        <p className="pl-3 text-capitalize"><span>{`${buyer.firstName} ${buyer.lastName}`}</span></p>
                        {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                    </div>
                    <div className="at-transact-line pt-2" >
                        <p>Shipping Address: <span>{transaction.shippingAddress}, {transaction.city}, {transaction.state}, {transaction.zip}</span></p>
                        {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                    </div>
                    {getServicedata()}
                    {/* <div className="at-transact-line pt-2">
                        <p>Art: <span>{transaction.itemName ? transaction.itemName : getItemType(transaction)}</span></p>
                    </div> */}
                </div>
                {notification.itemId && <div className="at-transact-right">
                    {notification.itemId && notification.itemId.name && <p className=""><span>{notification.itemId.name}</span></p>}
                    <figure>
                        <img src={collectionImage(notification.itemId, `high_${notification.itemId.url}`, true)} alt="" />
                    </figure>
                    <button className={`at-btn at-btn-sm at-btn-orange`}>Next</button>
                </div>}
                {getTotal()}
                {service && service.artImage && service.artImage.url && <div className="at-transact-line at-transactline-fullwidth pt-2">
                    <p>Link to Download Artist Approved File: <a href="#" className="at-orangelink-withicon" onClick={() => window.open(collectionImage({} as any, service.artImage.url), '_blank')}>{service.artImage.url} <i className="icon-download"></i></a></p>
                </div>}
            </div>
        </div>
    )
}