import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    RouteComponentProps
} from 'react-router-dom';
import { connect } from 'react-redux'
import LandingPage from "../pages/Landing.page";
import { ISpaceState, IStoreReducers } from "../interfaces/store.interface";
import PrivateRoutes from "./private.route";
import NotFound404Page from "../pages/NotFound404.page";
import { routes } from '.'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { logoutAction, setLeftPanel } from "../store/actions";
import { LOGGED_IN } from "../constants";
import PrintingFramingShowMain from '../templates/Printing_Framing_Format/MainRoutes'
import ConsultingServicesShowMain from '../templates/Consulting_Services_Format/MainRoutes'
import OmniRoutes from './omni-Routers'
import LoadingRoutes from "../pages/PrivacyPolicy";
import AdminRoute from "./admin.route";
import { ZoomActions, EZoom, IZoom } from "../services/zoom.service";
import StreamingHeaderComponent from "../components/common/StreamingHeader.component";
import ZoomItemComponent from "../components/common/ZoomItem.component";
import { IPlatformWebReducer } from "../interfaces";
import { Subscription } from 'rxjs'
import { AdminRouteAction } from "../services";
import { HttpService } from "../services/base.service";
import { AuthenticationComponent } from "../components/common/AuthModals";


interface IMainRouteProps {
    spaces?: ISpaceState
    remember: boolean
    logoutAction: any,
    spaceEnabled: any,
    inPlatform: IPlatformWebReducer,
    token: string,
    rigthPanelActive: boolean
    setLeftPanel: Function
}


class MainRoute extends Component<IMainRouteProps> {
    adminRouteSubscription = new Subscription();
    zoomSubscription = new Subscription();
    zoomEventValue: IZoom = {
        type: EZoom.NONE,
        value: null
    }
    state: { zoom: IZoom, count: number, isAdminRoute: boolean } = {
        zoom: this.zoomEventValue,
        count: 0,
        isAdminRoute: false
    }

    componentWillMount() {
        this.setToken()

        let isLargeScreen = window.innerWidth > 767
        if (isLargeScreen) {
            ZoomActions.triggerAction({ type: EZoom.ART_COLLECTION, value: null })
        } else {
            this.props.setLeftPanel({
                activeRigthPanel: true
            })
        }
        if (this.props.remember) {
            sessionStorage.setItem(LOGGED_IN, 'true');
        }
        const check = sessionStorage.getItem(LOGGED_IN);
        if (!this.props.remember && !check) {
            this.props.logoutAction();
        }
        const adminRoute = AdminRouteAction.listen.subscribe((status: boolean) => this.setState({ isAdminRoute: status }))
        const sub$ = ZoomActions.listen.subscribe(this.zoomListener);
        this.adminRouteSubscription.add(adminRoute)
        this.zoomSubscription.add(sub$)
    }
    componentDidMount() {
        // if (window.location.pathname !== '/' || window.innerWidth < 767) {
        //     this.props.setLeftPanel({
        //         activeRigthPanel: true
        //     })
        // }
    }

    componentWillReceiveProps(props: IMainRouteProps) {
        if (props.token !== this.props.token) {
            HttpService.setToken(props.token)
        }

    }

    setToken = () => {
        HttpService.setToken(this.props.token)
    }

    zoomListener = (data: IZoom) => {
        this.zoomEventValue = data;
        this.setState({ zoom: data })
    }

    render() {
        const { zoom: { type }, isAdminRoute } = this.state;
        const { spaceEnabled, inPlatform, rigthPanelActive, token } = this.props
        const zoomClass = (type === EZoom.FEED || type === EZoom.COLLECTION_ITEM || type === EZoom.TEMPLATES_IFRAME || type === EZoom.ART_COLLECTION) ? 'zoom-enabled' : ''

        return (
            // <div id="at-wrapper" className={`at-wrapper ${this.props.spaces && this.props.spaces.openActiveMembersSideBar && 'at-openthemeusersidbar'}`}>
            <Router>
                <div
                    id='at-wrapper'
                    className={`${!inPlatform.isActive && 'at-wrapper'} ${(token && spaceEnabled) ? 'at-wrapper-withvideo' : ''} ${this.props.spaces &&
                        this.props.spaces.openActiveMembersSideBar &&
                        'at-openthemeusersidbar'
                        } ${zoomClass}  ${(type === EZoom.TEMPLATES_IFRAME || type === EZoom.ART_COLLECTION) ? 'at-wrapperfullpanel' : ''} ${isAdminRoute && 'at-wrapper-admin'} ${rigthPanelActive ? 'at-rightpanel-open' : 'at-rightpanel-close'}  `}
                >
                    <div id="at-wrapperinner" className="at-wrapperinner">
                        {zoomClass && !isAdminRoute && !inPlatform.isActive && <ZoomItemComponent />}
                        <div className={inPlatform.isActive ? 'at-gallery-inner' : `at-themeholder`}>

                            {!isAdminRoute &&
                                <div>
                                    <StreamingHeaderComponent hideHeader={inPlatform.isActive || (!rigthPanelActive)} {...this.props} />
                                </div>
                            }

                            <Switch>
                                <Route path="/" exact component={(props: any) => (<LandingPage {...props} showHome={true} timestamp={new Date().toString()} />)} />
                                <Route path={routes.adminRoutes.index} component={AdminRoute} />

                                <Route path={routes.loadingRoutes} component={LoadingRoutes} />
                                <Route path={routes.newsFeed} component={(props: any) => (<LandingPage {...props} showFeed={true} timestamp={new Date().toString()} />)} />
                                <Route path={routes.artCollection} component={(props: any) => (<LandingPage {...props} showCollection={true} timestamp={new Date().toString()} />)} />
                                <Route path={routes.template.printingFramingShow} component={PrintingFramingShowMain} />
                                <Route path={routes.template.consultingServicesShow} component={ConsultingServicesShowMain} />
                                <OmniRoutes {...this.props} />
                                <Route path="*" component={NotFound404Page} />

                            </Switch>
                            <ToastContainer className='toast-customization' />
                            <AuthenticationComponent />
                        </div>
                    </div>
                </div>
            </Router>
            // </div>
        );
    }
}
const mapState = (state: IStoreReducers) => ({
    rigthPanelActive: state.leftPanel.activeRigthPanel,
    token: state.auth.access_token,
    spaces: state.spaces,
    remember: state.auth.remember,
    spaceEnabled: state.user.activeSpace && state.user.activeSpace._id,
    inPlatform: state.inPlatform
});

export default connect(mapState, { logoutAction, setLeftPanel })(MainRoute);
