import React from 'react'
import ImageGallery from 'react-image-gallery';
import { ImageLoading } from '../../components/common/ImageLoading';

interface IProps {
    src: string | any,
    className?: string
    thumbnail?: string
}

interface ImageGalleryState {
    original: string,
    thumbnail: string
}

export const CustomImage = ({ src, className, thumbnail }: IProps) => {
    let image = [{
        original: src,
        thumbnail: thumbnail ? thumbnail : src
    }]
    return (
        <figure className={`${className ? className : ''}`}>
            <ImageGallery

                items={image}
                showFullscreenButton={true}
                showPlayButton={false}
                showNav={false}
                showThumbnails={false}
                renderItem={(item: ImageGalleryState, index: number) => <ImageLoading src={item.original} className="image-gallery-image" thumbnail={item.thumbnail} />}
            // originalClass='img-fluid'
            />
        </figure>
    )
}