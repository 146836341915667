import { AxiosResponse } from 'axios'
import React, { FormEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { userService, toastService } from '../../services'
import { templatesImages } from '../../shared'
import { templateSelector } from '../../store/selectors'
import { USER_ALERT_TEMPLATE_CONTACT } from '../../constants/userAlert.type'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { ImageLoading } from '../../components/common/ImageLoading'
import PhoneInput from 'react-phone-number-input'


interface IState {
    message: string
}

const initState = {
    message: ''
}
const ContactPage = () => {
    let template = useSelector(templateSelector)
    let user = template.user
    const [state, setState] = useState<IState>({ ...initState })
    const [phoneNumber, setPhoneNumber] = useState<any>('+1')
    const [loading, setLoading] = useState<boolean>(false)
    const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (!phoneNumber) {
            toastService.error("Please enter Phone Number")
            return
        }
        let data = {
            message: state.message,
            to: user._id,
            type: USER_ALERT_TEMPLATE_CONTACT,
            fromAdmin: true,
            fromPhoneNumber: phoneNumber
        }
        setLoading(true)
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {

            toastService.success("Message sent successfully")
            setLoading(false)


        }).catch((err: any) => {
            console.log(err)
            setLoading(false)

        })
    }
    return <div >
        <section className="At-ContactPage At-Section-Mh800">
            <div className="container">
                <div className="row mx-lg-n6">
                    {user && <div className="col-sm-6 px-lg-6 At-AboutContent">
                        <h3>Contact</h3>
                        {template.contactInformation.information && <h6 dangerouslySetInnerHTML={{ __html: template.contactInformation.information }}>

                        </h6>}
                        {/* <h6>
                            {formatPhoneNumberIntl(user.phoneNumber)}
                        </h6> */}
                        {template && template.locationImage && <figure className="At-FigureMap">
                            <ImageLoading src={templatesImages(`${template.locationImage}`)} thumbnail={templatesImages(`blur_${template.locationImage}`)} className="img-fluid" alt="" />
                        </figure>}
                    </div>}
                    <div className="col-sm-6 px-lg-6">
                        <form className="At-AboutForm" onSubmit={onSubmit}>
                            <div className="form-group at-inputwithicon ">
                                <i className="icon-user"></i>
                                {/* <input type="text" name="username" value={username} className="form-control" placeholder="Phone number" onChange={this.onChangeText}/> */}
                                <PhoneInput
                                    placeholder="Phone (e.g., +1 412 877 4312)"
                                    value={phoneNumber}
                                    className="floating-input-outer"
                                    defaultCountry="US"
                                    onChange={(phoneNumber: any) => setPhoneNumber(phoneNumber)} />
                                {/* <label>Enter phone number</label> */}
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name='message' value={state.message} onChange={onChangeText} placeholder="Message" required />
                                <div className="invalid-feedback">
                                    Please enter a message in the textarea.
                                </div>
                            </div>
                            <button className="At-BtnContact" disabled={loading} type="submit">{loading ? 'Please wait...' : 'Submit'}</button>

                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default ContactPage