import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import RTE from 'react-rte'
import { IUser } from '../../../interfaces'
import { toastService } from '../../../services'
import { collectionService } from '../../../services/collection.service'
import { TmceEditor } from '../TMCEditor.comopnent'

interface IProps {
    title: string,
    description: string,
    onClose: Function,
    open: boolean,
    collection: any,
    event: any,
    onSubmit: Function,
    user: IUser,
    keyword?: string
}

export const DescriptionModal = ({ title, description, onClose, open, collection, event, onSubmit, user, keyword }: IProps) => {
    const [text, setText] = useState<string>('')
    const [keywordText, setKeywordText] = useState<string>('')

    const handleTerms = (e: any) => {
        // const { value } = (e.target as HTMLInputElement)
        setText(e)
    }

    
    // const addHtmlInString = (str: string) => {
    //     let newString = ''
    //     let htmlString = `<h6 className='text-bold'>Keywords: </h6>`

    //     if (str.includes('>')) {
    //         let indexOf = str.indexOf('>')
    //         if (indexOf !== -1) {
    //             newString = str.slice(0, indexOf+1) + htmlString + str.slice((indexOf+1) + 0)
    //         }
    //     } else {
    //         newString = htmlString + str
    //     }
    //     console.log(newString)
    //     return newString
    // }

    useEffect(() => {
        if (description) {
            setText(description)
        }

        if (keyword) {
            setKeywordText(keyword)
        }
    }, [description, keyword])

    const onUpdate = () => {
        event(collection.id, { description: text, keywords: keywordText }).then((res: AxiosResponse<any>) => {
            toastService.success(res.data.message)
            onSubmit()
        }).catch((e: any) => console.log(e))
    }
    return (
        <div className={`at-collection-description at-themepopupholder ${open && ' at-popupfixedbottom at-showthemerecoverycodepopup'}`}>
            <div className="at-themepopupbox text-left">
                <a className="at-close cursor-pointer" onClick={() => onClose()} id="at-close"><i className="icon-cancel"></i></a>
                <div className="at-addactionholder">
                    <div className="at-formthemehead text-center">
                        <h2>{title}</h2>
                    </div>
                    {collection.user && (collection.user._id === user._id || collection.user === user._id) ? <>
                        <div className="row description-modal-editor">
                            <div className="col-12">
                                
                            <TmceEditor text={text} onChange={(e: any) => handleTerms(e)} />
                            </div>
                        </div>
                        <div className="row description-modal-editor">
                          <div className="col-12">
                          <div className="at-formthemehea">
                                <h6 className='mt-3 text-bold'>Keywords: </h6>
                            </div>
                            <TmceEditor text={keywordText} onChange={(e: any) => setKeywordText(e)} />
                          </div>
                        </div>
                        <div className="pt-2">
                            <button className="at-btn at-btnupdate at-btn-fullwidth" onClick={onUpdate}>Update</button>
                        </div>
                    </>
                        :
                        <div>
                                <h6 className='text-bold mb-0'>Description: </h6> 

                            {text && <div className="text-justify" dangerouslySetInnerHTML={{ __html: text }}>

                            </div>}
                            {keywordText && <div className='at-children-zeromargin'>

                                <h6 className='text-bold'>{`Keywords: `} </h6> 

                                <p className="text-justify" dangerouslySetInnerHTML={{ __html: keywordText}}>

                                </p>
                            </div>

                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}