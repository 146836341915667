import React, { useState, useEffect } from 'react'
import { images } from '../../assets/image'
import { RouteComponentProps, Link } from 'react-router-dom'
import { IFormates, ITransaction, IUser } from '../../interfaces'
import moment from 'moment'
import LoadingComponent from '../common/Loading.component'
import { collectionImage } from '../../shared'
import { CurrencyFormat } from '../common/CurrencyFormat'
import { paymentService, toastService, transactionService } from '../../services'
import { AxiosResponse } from 'axios'
import { ConfirmAlertComponent } from '../common/ConfirmAlert.component'
import { ERole, ETransactionStatus, EtransactionType } from '../../enums'
import { TransferLayout } from '../common/TransferLayout'
import { transactionStatus } from '../../helper'

interface IPageProps extends RouteComponentProps {
    [key: string]: any
}
export const AdminTransactionsComponent = ({ history }: IPageProps) => {
    const [transactions, setTransaction] = useState<Array<ITransaction>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showConfirmBox, setConfirmBox] = useState<boolean>(false)
    const [selectedTransaction, setSelectedTransaction] = useState<ITransaction>()

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        transactionService.getAllTransactions().then((response: AxiosResponse<any>) => {
            let newData = [...response.data.response]
            setTransaction(newData.reverse())
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const getItemType = (item: ITransaction) => {
        if (item.formatesId) {
            let format: Array<IFormates> = item?.itemID?.formates.filter((value: IFormates) => value._id === item?.formatesId)
            if (format && format.length <= 0) {
                return ` `
            }
            if (format && format[0]) {
                return format[0].size
            }
            return ` `
        }
        return `Original`
    }

    const onOpenConfirmBox = (item: ITransaction) => {
        setSelectedTransaction(item)
        setConfirmBox(true)
    }

    const onCloseConfirmBox = () => {
        let object: any = {}
        setSelectedTransaction(object)
        setConfirmBox(false)
    }

    const releaseArtFunds = () => {
        setLoading(true)
        paymentService.releasePayment({ transactionID: selectedTransaction?._id, transactionState: ETransactionStatus.PAID }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success("“Payment released successfully")
                fetch()
            } else if (res.data && res.data.length > 0) {
                toastService.error(res.data[0].message)
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
            onCloseConfirmBox()
        }).catch(err => {
            console.log(err)
            onCloseConfirmBox()
            setLoading(false)
        })
    }

    const releaseCommissionWork = () => {
        setLoading(true)
        paymentService.releaseCommissionPayment({ transactionID: selectedTransaction?._id, transactionState: ETransactionStatus.PAID }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success("“Payment released successfully")
                fetch()
            } else if (res.data && res.data.length > 0) {
                toastService.error(res.data[0].message)
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
            onCloseConfirmBox()
        }).catch(err => {
            console.log(err)
            onCloseConfirmBox()
            setLoading(false)
        })
    }
    const onSubmitConfirmBox = () => {
        if (selectedTransaction?.transactionType === 'ART') {
            releaseArtFunds()
        } else {
            releaseCommissionWork()
        }
    }

    const checkStatus = (item: ITransaction) => {
        if (item.transactionState === ETransactionStatus.PAID) {
            return { color: 'grey', text: 'Paid', disabled: true }
        }

        if (item.transactionState === 'refunded') {
            return { color: 'grey', text: 'Paid', disabled: true }
        }
        if (item.transactionState === ETransactionStatus.RETURN_COMPLETED) {
            return { color: 'grey', text: ETransactionStatus.RETURN_COMPLETED.replace('_', ' ').toLowerCase(), disabled: true }
        }
        return { color: 'orange', text: 'Release funds', disabled: false }
    }

    const artsTransaction = (item: ITransaction) => {
        let artist = item.artistID && item.artistID._id ? item.artistID : item.sellerID
        let isAgent = artist && artist.roles.includes(ERole.AGENT)
        let artistName = artist && artist._id && isAgent ? item.itemID?.artistName || `${artist?.firstName} ${artist?.lastName}` : `${artist?.firstName} ${artist?.lastName}`
        let formatShipping = item.itemID?.assignment?.formatResponsibility
        let originalShipping = item.itemID?.assignment?.originalResponsibility
        return (
            <div key={item._id} className="at-transaction-wrap">
                <div className="at-transaction-box">
                    <div className="at-transact-left">
                        <div className="at-transact-line">
                            <p>{moment(item.createdAt).format("L")}</p>
                            <p className="pl-5">{moment(item.createdAt).format("hh:mm:ss a")}</p>
                        </div>
                        {item.sellerID && <div className="at-transact-line">
                            <p>Seller:</p>
                            <p className="pl-2 text-capitalize"><span>{item.sellerID.firstName} {item.sellerID.lastName}</span></p>
                        </div>}
                        {item.sellerID && <div className="at-transact-line">
                            <p>Artist:</p>
                            <p className="pl-2 text-capitalize"><span>{artistName}</span></p>
                        </div>}
                        {item.buyerID && <div className="at-transact-line" >
                            <p>Buyer:</p>
                            <p className="pl-3 text-capitalize"><span>{`${item.buyerID.firstName} ${item.buyerID.lastName}`}</span></p>
                            {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                        </div>}
                        <div className="at-transact-line pt-2">
                            <p>Art:<span> {item.itemName ? item.itemName : getItemType(item)}</span></p>
                        </div>

                        {/* <TransferLayout transaction={item} />
                                                {item.itemID?.assignment && <div className="at-transfer-details pl-3">
                                                    <h5>Assignment Details</h5>
                                                    <ul>
                                                        {item.itemID.assignment.user && <li><span>Gallery Name:</span> {(item.itemID.assignment.user as IUser).gallery_name} </li>}
                                                        <li><span>Assignment Type:</span> {item.itemID.assignment.type}</li>
                                                        <li><span>Payout Percent: </span>{item.formatesId ? item.itemID.assignment.formatCommission : item.itemID.assignment.commission}</li>
                                                        <li><span>AssignmentDate:</span>{moment(item.itemID.assignment.createdAt).format("L")}</li>
                                                        <li><span>AssignmentTerm:</span> {item.formatesId ? item.itemID.assignment.formatPeriod : item.itemID.assignment.period}</li>
                                                        <li><span>AssignmentExpiration:</span>{moment(item.itemID.assignment.createdAt).add(item.formatesId ? item.itemID.assignment.formatPeriod : item.itemID.assignment.period, 'months').format('L')}</li>
                                                    </ul>
                                                </div>}
                                                <div className="pt-2">
                                                    <button disabled={checkStatus(item).disabled} className={`at-btn at-btn-sm at-btn-${checkStatus(item).color}`} onClick={() => onOpenConfirmBox(item)}>{checkStatus(item).text}</button>
                                                </div> */}
                    </div>
                    <div className="at-transact-right">
                        {item.itemID && item.itemID.name && <p className=""><span>{item.itemID.name}</span></p>}
                        <figure>
                            {item.itemID && item.itemID.url && <img src={collectionImage(item.itemID, `high_${item.itemID.url}`, true)} alt="" />}
                        </figure>
                        <button className={`at-btn at-btn-sm at-btn-${transactionStatus(item.transactionState).color} text-capitalize`}>{transactionStatus(item.transactionState).status}</button>
                    </div>

                    <div className="at-transactbox-bottom">
                        <div className="at-transact-line pt-2">
                            <p>Price:<span><CurrencyFormat amount={item.price} /></span></p>
                            <p className="pl-2">Shipping: <span><CurrencyFormat amount={item.shippingPrice} /></span></p>
                            <p className="pl-2">Total: <span><CurrencyFormat amount={item.totalPrice} /></span></p>
                        </div>
                        <TransferLayout showNewStyle={true} transaction={item} />
                        {item.itemID?.assignment && <div className="at-transfer-details pl-3 text-capitalize">
                            <h5>Assignment Details</h5>
                            <ul>
                                {item.itemID.assignment.user && <li><span>Gallery Name:</span> {(item.itemID.assignment.user as IUser).gallery_name} </li>}
                                <li><span>Assignment Type:</span> {item.itemID.assignment.type}</li>
                                <li><span>Payout Percent: </span> {item.formatesId ? item.itemID.assignment.formatCommission : item.itemID.assignment.commission}</li>
                                <li><span>Assignment Date:</span> {moment(item.itemID.assignment.createdAt).format("L")}</li>
                                <li><span>Assignment Term:</span> {item.formatesId ? item.itemID.assignment.formatPeriod : item.itemID.assignment.period}</li>
                                <li><span>Assignment Expiration:</span> {moment(item.itemID.assignment.createdAt).add(item.formatesId ? item.itemID.assignment.formatPeriod : item.itemID.assignment.period, 'months').format('L')}</li>
                                {item.formatesId && item.itemID.assignment.formatResponsibility && <li><span>Shipping Responsibility:</span> {item.itemID.assignment.formatResponsibility}</li>}
                                {!item.formatesId && item.itemID.assignment.originalResponsibility && <li><span>Shipping Responsibility:</span> {item.itemID.assignment.originalResponsibility}</li>}
                            </ul>
                        </div>}
                        <div className="pt-2">
                            <button disabled={checkStatus(item).disabled} className={`at-btn at-btn-sm at-btn-${checkStatus(item).color}`} onClick={() => onOpenConfirmBox(item)}>{checkStatus(item).text}</button>
                        </div>
                    </div>
                </div>


                {/* at-transfer-amount ends  */}
            </div>
        )
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Transactions</h2>
                </div>
                <div className="at-transaction-main">
                    {transactions?.length > 0 ? (
                        <>
                            {transactions?.map((item: ITransaction) => {
                                return (item.transactionType === EtransactionType.CURATORIAL_OFFER ? null : artsTransaction(item))
                            })}
                        </>
                    ) : (<>{loading && <LoadingComponent className='at-transaction-loader' />}</>)}

                </div>
            </div>
            {showConfirmBox && <ConfirmAlertComponent loading={loading} open={showConfirmBox} onClose={onCloseConfirmBox} onCancel={onCloseConfirmBox} onConfirm={onSubmitConfirmBox} message="Are you sure you want to release the fund." />}
        </main>
    )
}
