import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { images } from '../../../../assets/image'
import { AWS_ENDPOINT } from '../../../../environment'
import { ISpace, IUser } from '../../../../interfaces'
import { IResponse } from '../../../../interfaces/db.interface'
import { IGalleryDocument } from '../../../../interfaces/Gallery-walk.interface'
import { routes } from '../../../../router'
import { auctionService, galleryWalkService, spaceService, toastService, userService } from '../../../../services'
import { eventImages } from '../../../../shared'
import { ImageLoading } from '../../../common/ImageLoading'
import { UniversalModal } from '../../../common/Modals/Universal.modal'
import { TmceEditor } from '../../../common/TMCEditor.comopnent'
import HeartComponent from '../../../ArtCentral/feed/HeartIcon.component'
import { IAuctionAttendessDocument, IAuctionDocument } from '../../../../interfaces/auction.interface'
import { AuctionRegistration } from './AuctionRegistration.component'
import { dispatch } from 'rxjs/internal/observable/pairs'
import { ADD_NEW_MEMBER, USER_ALERT_SPACE_ENTER } from '../../../../constants'
import { socketNode } from '../../../../services/socket.service'
import { changeActiveSpace, fetchUserStudio, activateSpaceAction } from '../../../../store/actions'
import { useDispatch } from 'react-redux'

interface IProps {
    auction: IAuctionDocument
    user: IUser
    onClickShowArts?: (g: any) => any
    fetch: Function
    onClickJoinLiveAuctionsArts?: Function
    isRight?: boolean
}

export const ArtAuctionsCardViewComponent = ({ auction, user, onClickShowArts, fetch, onClickJoinLiveAuctionsArts, isRight }: IProps) => {

    const [showPicture, setShowPicture] = useState<boolean>(false)
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [showRegistration, setShowRegistration] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const dispatch = useDispatch()

    const history = useHistory()

    const openShowPicture = () => {
        if (showInfo) closeSshowInfo()
        setShowPicture(true)
    }

    const onPdfClick = () => {
        if (!auction || !auction.pdf || auction.pdf === 'null') return
        let link = `${AWS_ENDPOINT}/pdf/${auction?.pdf}`
        window.open(link, "_blank");
    }

    const closeShowPicture = () => {
        setShowPicture(false)
    }

    const openShowInfo = () => {
        if (showPicture) closeShowPicture()
        setDescription(auction.description)
        setShowInfo(true)
    }

    const closeSshowInfo = () => {
        setShowInfo(false)
    }

    const onClickImage = () => {
        if (onClickShowArts) {
            onClickShowArts(auction)
            return
        }
        history.push(`${routes.account.services.auction.showArts}/${auction._id}`)
    }

    const onJoinLiveAuction = async () => {

        const space: ISpace = (await spaceService.userArtCentralSpace(auction.user._id)).data;
        changeActiveSpace && dispatch(changeActiveSpace(space))
        socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
        dispatch(fetchUserStudio(auction.user._id))
        sendUserAlert(auction, `${user?.firstName} ${user?.lastName} has just entered your space.`, USER_ALERT_SPACE_ENTER, false, space._id)
        dispatch(activateSpaceAction(true))
        if (onClickJoinLiveAuctionsArts) {
            onClickJoinLiveAuctionsArts(auction)
            return
        }
        history.push(`${routes.account.services.auction.joinAuction}/${auction._id}`)
    }

    const sendUserAlert = (c: IAuctionDocument, message: string, type: string, showToast: boolean = false, spaceId: any = null) => {
        let cUser = c.user

        if (user?._id === cUser._id) {
            return
        }
        let data = {
            message: message,
            to: cUser._id,
            from: user?._id,
            type: type,
            spaceID: spaceId
        }
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
            if (showToast && res.data.status === 'Success') {
                toastService.success("Message sent successfully")
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const onUpdateDescription = () => {
        if (!description) return
        setLoading(true)
        auctionService.updateSingleAuction(auction._id, { description }).then((res: AxiosResponse<IResponse<IAuctionDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            }
            fetch()
            setLoading(false)
            closeSshowInfo()
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }


    const OpenAuctionRegistration = () => {
        setShowRegistration(true)
    }

    const CloseAuctionRegistration = () => {
        setShowRegistration(false)
    }

    const checkIfUserRegistered = () => {
        let attend = auction.attendees

        let fil = attend.filter((u: IAuctionAttendessDocument) => u.user._id === user._id && (u.auction as any) === auction._id)

        if (fil && fil.length > 0) {
            return true
        }

        if (user._id === auction.user._id) {
            return true
        }
        return false
    }

    const EventDate = () => {

        if (moment(auction.startDate).format() > moment().endOf('day').format()) {
            return true
        }

        if (moment(auction.endDate).format() < moment().startOf('day').format()) {
            return true
        }

        return false
    }

    const EventTime = () => {
        let currentUserTime = moment(moment().format('hh:mm:ss A'), "HH:mm:ss a")
        let startTime = moment(moment(auction.startTime).format('hh:mm:ss A'), "HH:mm:ss a")
        let endTime = moment(moment(auction.endTime).format('hh:mm:ss A'), "HH:mm:ss a")
        if (currentUserTime.isAfter(startTime) && currentUserTime.isBefore(endTime)) {
            return true
        }

        return false
    }

    return <div className={` ${isRight ? "col-md-12 at-postouter-rightpanel" : "col-lg-6 col-xl-4"} `}>
        <div className="at-post w-100 mx-0">
            <div className="at-orangeeconhead px-lg-0 px-xl-2">
                {/* <figure>
                    <img alt="icon" />
                </figure> */}
                <div className="at-orangeeconcontent">
                    <h3><span> <span className="at-post-title">{auction.name}</span>
                        <span className="fs-14">{moment(auction.startDate).format("DD/MM/YYYY")}-{moment(auction.endDate).format("DD/MM/YYYY")} </span>
                        <span className="fs-14">{moment(auction.startTime).format("hh:mm a")}-{moment(auction.endTime).format("hh:mm a")} {auction.timeZone ? auction.timeZone.includes('/') ? auction.timeZone.split('/')[1] : auction.timeZone : 'EST'}</span></span></h3>

                    <div className="at-btniconholder">
                        <div className="at-btnholder-bottom ">
                            {/* <button type="button" className={`at-doubleextrasmallbtn ${checkIfUserRegistered() ? 'btn btn-secondary' : 'at-btn'}`} onClick={() => OpenAuctionRegistration()} disabled={checkIfUserRegistered()}>{checkIfUserRegistered() ? "Confirmed" : 'REGISTER'}</button> */}
                            <div className="d-flex mx-2">
                                <a className="at-btnomniicon at-postbtn" title="Gallery"><i className="icon-gallery" onClick={() => openShowPicture()}></i></a>
                                <a className="at-btnomniicon at-postbtn" title="Information"><i className="icon-info" onClick={() => openShowInfo()}></i></a>
                                <a className="at-btnomniicon at-postbtn" title="document"><i className="icon-pdf" onClick={() => onPdfClick()}></i></a>
                                {/* <a title="Preferred" className="at-btnheart at-postbtn cursor-pointer"><i className="icon-favorite"></i></a> */}
                                <HeartComponent postId={auction.id} userId={auction.user._id} />
                            </div>
                        </div>
                    </div>
                    <div className="at-auctionbtn-holder">
                        <a href="#" className="at-orangelinkbig w-100" onClick={() => onClickImage()}>See Auction Art</a>
                        <div className="at-auctionbtn-holderinner">
                            {checkIfUserRegistered() && <button type="button" className="at-btn at-smallbtn-h40" disabled={EventDate() || !EventTime()} onClick={() => onJoinLiveAuction()}>JOIN LIVE AUCTION</button>}
                            {!checkIfUserRegistered() && <button type="button" className="at-btn at-smallbtn-h40 " disabled={EventDate()} onClick={() => OpenAuctionRegistration()}>REGISTER NOW</button>}
                        </div>
                    </div>
                </div>

                <div className="at-orangeeconcontent at-gallerywalkpage-content">
                    <h3><span className="at-post-title">{auction.name}</span>
                        <a href="#" className="at-orangelink" onClick={() => onClickImage()}>See Auction Art</a>
                    </h3>
                    <div className="at-btniconholder">
                        <div className="at-btnholder-bottom">

                            <a className="at-btnomniicon at-postbtn" title="Gallery"><i className="icon-gallery" onClick={() => openShowPicture()}></i></a>
                            <a className="at-btnomniicon at-postbtn" title="Information"><i className="icon-info" onClick={() => openShowInfo()}></i></a>
                            <a className="at-btnomniicon at-postbtn" title="document"><i className="icon-pdf" onClick={() => onPdfClick()}></i></a>
                            {/* <a title="Preferred" className="at-btnheart at-postbtn cursor-pointer"><i className="icon-favorite"></i></a> */}
                            <HeartComponent postId={auction.id} userId={auction.user._id} />
                        </div>
                    </div>
                </div>
            </div>
            <figure className="at-postimg" ><img src={eventImages({}, auction.poster)} alt="" className="false " /></figure>
            <div className="at-orangeeconcontent at-gallerywalkpage-contentbottom align-items-center flex-column">
                {/* <button type="button" className={`at-doubleextrasmallbtn ${checkIfUserRegistered() ? 'btn btn-secondary' : 'at-btn'}`} onClick={() => OpenAuctionRegistration()} disabled={checkIfUserRegistered()}>{checkIfUserRegistered() ? "Confirmed" : 'REGISTER'}</button> */}
                <h3 className="mb-3"><span className="text-center at-fs14"> {moment(auction.startDate).format("DD/MM/YYYY")}-{moment(auction.endDate).format("DD/MM/YYYY")} <br />
                    {moment(auction.startTime).format("hh:mm a")}-{moment(auction.endTime).format("hh:mm a")} {auction.timeZone ? auction.timeZone.split('/')[1] : 'EST'}</span></h3>
                {checkIfUserRegistered() && <button type="button" className="at-btn at-smallbtn-h40" disabled={EventDate() || !EventTime()} onClick={() => onJoinLiveAuction()}>JOIN LIVE AUCTION</button>}
                {!checkIfUserRegistered() && <button type="button" className="at-btn at-smallbtn-h40 " disabled={EventDate()} onClick={() => OpenAuctionRegistration()}>REGISTER NOW</button>}
            </div>
        </div>

        {showInfo && description && <UniversalModal open={showInfo} onClose={() => setShowInfo(false)}>
            <div className="at-addactionholder">
                <div className="at-formthemehead text-center pb-2">
                    <h2 className="at-color-nightrider">{auction.name}</h2>
                    <div className="text-left my-3">
                        <p className="mb-0">Start: {moment(auction.startDate).format('LL')}</p>
                        <p className="mb-0">End: {moment(auction.endDate).format('LL')}</p>
                    </div>
                </div>
                {(auction.user as IUser)._id === user._id ? <>
                    <div className="row description-modal-editor">
                        <div className="col-12">
                            <TmceEditor text={description} onChange={(e: any) => setDescription(e)} />
                        </div>
                    </div>
                    <div className="pt-2">
                        <button className="at-btn at-btnupdate at-btn-fullwidth" disabled={loading} onClick={() => onUpdateDescription()}>{loading ? `Please wait...` : `Update`}</button>
                    </div>
                </>
                    :
                    <div className=" ">
                        {description && <div className="text-justify" dangerouslySetInnerHTML={{ __html: description }}>
                        </div>}
                    </div>
                }
            </div>
        </UniversalModal>}
        {
            showPicture && auction && auction.images.length > 0 && <UniversalModal open={showPicture} onClose={() => setShowPicture(false)}>
                <div className="at-modal-images">
                    <h5 className="at-popupheading">Auction Event Pictures</h5>
                    {auction.images.map((i: any) => {
                        return (
                            <figure className="at-roundbox-figure-plain">
                                <ImageLoading src={eventImages({}, `high_${i.url}`)} thumbnail={eventImages({}, `blur_${i.url}`)} />
                                {/* <img src={eventImages({}, `high_${i.url}`)} alt="" /> */}
                            </figure>
                        )
                    })}
                </div>
            </UniversalModal>
        }

        {
            showRegistration && <UniversalModal open={showRegistration} onClose={CloseAuctionRegistration}>
                <AuctionRegistration a={auction} onClose={CloseAuctionRegistration} user={user} fetch={fetch} />
            </UniversalModal>
        }
    </div>
}