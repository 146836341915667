import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { connect } from 'react-redux'
import { offerService, toastService } from '../../../services'
import { IOffer, IStoreReducers, IUser } from '../../../interfaces'
import * as type from '../../../constants/transactionTypes'
import { socketNode } from '../../../services/socket.service'
import { OfferView } from './Offers.View'
import { actionMessage } from '../../../actions'

interface IProps {
    user?: IUser
    routeUser?: IUser
}

interface IState {
    offers: Array<IOffer>
    selectedItem: {
        [key: string]: boolean
    },
    action: string
    message: string,
    errors: any,
    loading: boolean,
    disableInput: boolean
}
class TransactionsPage extends Component<IProps> {
    state: IState = {
        offers: [],
        selectedItem: {},
        action: '',
        message: '',
        errors: [],
        loading: false,
        disableInput: false
    }

    componentDidMount() {
        this.fetchOffers()
    }

    fetchOffers = () => {
        const { user, routeUser } = this.props
        let currUser = routeUser ? routeUser : user
        this.setState({ loading: true })
        offerService.getBuyerOffersOffer(currUser?._id).then((res: AxiosResponse<Array<IOffer>>) => {
            let data = res.data.reverse()
            this.setState({ offers: data, loading: false })
        }).catch(error => {
            console.log(error)
            this.setState({ loading: false })
        })
    }


    sendSocketEvent = (offer: IOffer, action: string, message: string) => {
        // let path = `art-central/${offer.offeredTo.isAgent ? 'assignment' : 'collection/item'}/${offer.itemId.id}?offer=${offer._id}`
        // let getMessage = actionMessage(offer, action, message).message
        // socketNode.emit(action, {
        //     from: offer.offeredBy._id,
        //     to: offer.offeredTo._id,
        //     message: getMessage,
        //     itemId: offer.itemId.id,
        //     phone: offer.offeredTo.phoneNumber,
        //     offerID: offer._id,
        //     path
        // })
    }

    sendAction = () => {
        let { offers, action, selectedItem, message } = this.state

        let selectedOffer = Object.keys(selectedItem)
        let selectedOfferData = offers.filter((offer: IOffer) => {
            return selectedOffer.indexOf(offer._id) !== -1
        })
        selectedOfferData.forEach((item: IOffer) => {
            this.sendSocketEvent(item, action, message)
        })
        toastService.success("Actions are created")
        this.setState({ message: '', selectedItem: [], action: '' })
    }


    getOfferStatus = (item: IOffer) => {
        if (item.approved) {
            return { status: 'approved', color: 'orange' }
        }
        if (item.pending) {
            return { status: 'pending', color: 'grey' }
        }
        if (item.rejected) {
            return { status: 'rejected', color: 'grey' }
        }
        return { status: 'pending', color: 'grey' }
    }


    render() {
        const { offers, selectedItem, message, errors, loading, disableInput, action } = this.state
        // console.log(Object.keys(selectedItem))
        return (
            <OfferView
                offers={offers}
                getOfferStatus={this.getOfferStatus}
                loading={loading}
                showButton={false}
                onAccept={() => { }}
                onReject = {() => {}}
                onConfirmApprove={() => {}}
                onConfirmReject={() => {}}
                onConfirmApproveClose={() => {}}
                onConfirmRejectClose={() => {}}
            />
        )
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(TransactionsPage)
