import React from 'react';

interface IProps {
    message: string
    onClose: Function
    onSubmit: Function
    buttonText?:string
    header?: string
}

export const ThanyouPopup = ({ message, onClose, onSubmit, buttonText, header }: IProps) => {
    return (
        <div className="at-themepopupbox text-left">
            <a className="at-close cursor-pointer" onClick={() => onClose()} id="at-close"><i className="icon-cancel"></i></a>
            <div className="at-addactionholder">
                <div className="at-formthemehead">
                    <h2>{header || `Thank you for your offer`}</h2>
                </div>
                <div className="form-group text-left pt-3">
                    <p>{message}</p>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        <button type="button" className="cursor-pointer at-btn at-btn-orange btn-block" onClick={() => onSubmit()}>{buttonText || 'Ok'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}