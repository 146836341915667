import React, { useState, FormEvent, useEffect } from 'react'
import { ETransactionStatus } from '../../../enums'
import { IDisputed, ITransaction, IArtReturn } from '../../../interfaces'
import { ErrorComponent } from '../Error.component'

interface IProps {
    open: boolean,
    onClose: Function,
    onSubmit: Function
    transaction?: ITransaction
    showForm: boolean
}

export const ArtReturnModal = ({ open, onClose, onSubmit, transaction, showForm }: IProps) => {

    const [state, setState] = useState<IArtReturn>({
        status: false,
        details: '',
        reason: '',
        delivered: false,
        labelStatus: ''
    })

    const [errors, setErrors] = useState<any>({})

    useEffect(() => {

    }, [])

    const onChange = (e: FormEvent<HTMLInputElement> | any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const onSubmitDetails = () => {
        if (!validateData()) return
        let data: IArtReturn = state
        data.status = true
        data.labelStatus = ETransactionStatus.RETURN_PENDING
        onSubmit(data)
    }

    const validateData = () => {
        let { reason } = state
        if (!reason) {
            let newErrors = errors
            newErrors['reason'] = ['Please enter title of dipsute']
            setErrors({ ...newErrors })
            return false
        }
        setErrors({})
        return true
    }
    return (
        <div className={`at-thankyou-popup at-themepopupholder ${open && 'at-showthemesignuppopup'}`}>
            <div className="at-themepopupbox text-left">
                <a className="at-close cursor-pointer" onClick={() => onClose()} id="at-close"><i className="icon-cancel"></i></a>
                <div className="at-addactionholder">
                    <div className="at-formthemehead">
                        <h2>Return notification</h2>
                    </div>
                    {!showForm && <div className="row">
                        <div className="col">
                            <p className="">ART may not be returned at this point as it is outside the return window established in our return policy. If you have any questions, please review the return policy in the Terms and Conditions link in the home page.</p>
                        </div>
                    </div>}

                    {showForm && <> <div className="row">
                        <div className="col">
                            <p>If you plan to return this item, please make sure it is
                            packed properly in the same packing container you
                            received it. It must arrive in good condition to the
                            seller for the refund to be approved. Also, you are
                            responsible for all return fees and shipping costs.</p>
                        </div>
                        <div className="col-xs-12 col-sm-12 pad-lr-7">
                            <div className="form-group">
                                <span className="at-select">
                                    <select className="text-capitalize" onChange={onChange} name="reason" required >
                                        <option value="" disabled selected>Reason for return</option>
                                        <option value="ART is Damaged">ART is Damaged</option>
                                        <option value="The ART received did not match what I expected">The ART received did not match what I expected</option>
                                        <option value="I am not satisfied with this ART">I am not satisfied with this ART</option>
                                    </select>
                                    {errors && errors['reason'] && <ErrorComponent errors={errors['reason']} multiple={false} />}
                                </span>
                            </div>
                        </div>
                    </div>


                        <div className="row at-dispute-text-area">
                            <div className="col-xs-12 col-sm-12 pad-lr-7">
                                <div className="form-group">
                                    <textarea name="details" value={state.details} className="form-control" placeholder="Additional Information" onChange={(e: any) => onChange(e)} />
                                </div>

                            </div>
                        </div>


                        <div className="form-group">
                            <button type="submit" className="at-btn at-minwidth-100" onClick={() => onSubmitDetails()}>{'Next'}</button>
                        </div>
                    </>}

                </div>
            </div>
        </div>
    )
}