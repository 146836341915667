
import { HttpService } from "./base.service";
import { IUser } from "../interfaces";

class ShipEngine extends HttpService {
    private readonly prefix: string = 'ship-engine';

    /**
     * 
     *  Get ShipEngine Provider
     * @param data
     */
    getProviders = (data: any): Promise<any> => this.get(`${this.prefix}/carriers-list`, data)


    getShiipingRates = (data: any): Promise<any> => this.post(`${this.prefix}/calculate-shipping`, data)

}

export const shipengineService = new ShipEngine()