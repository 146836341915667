import React from 'react'
import { ERole } from '../../enums'
import { ISellerPrice, ITransaction } from '../../interfaces'
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CurrencyFormat } from './CurrencyFormat'
import 'react-tabs/style/react-tabs.css';
import { _logger } from '../../helper';

interface IProps {
    transaction: ITransaction
    showNewStyle?: boolean
}

export const TransferLayout = ({ transaction, showNewStyle }: IProps) => {
    let isAgent = transaction.artistID && transaction.artistID._id !== transaction.sellerID._id
    let artist = isAgent ? transaction.artistPrice : transaction.sellerPrice
    let seller: ISellerPrice = transaction.sellerPrice
    let isExternalArt = (transaction?.artistID?._id === transaction?.sellerID?._id) && (transaction?.artistID?.roles.includes(ERole.AGENT) || transaction?.sellerID?.roles.includes(ERole.AGENT)) && transaction?.itemID?.artistName
    const isProvider = transaction.serviceProvider && transaction.serviceProvider._id
    const providerPrice = transaction.serviceProviderPrice

    const oldStyle = () => {
        return (
            <>
                {isAgent && seller && artist ? <div className="at-transfer-amount ">
                    <div className="at-transfer-upper">
                        <h5 className="text-center at-text-underline">Transfer Amount Calculation</h5>
                        <div className="row">
                            <div className="col-2 at-custcol-4">
                            </div>
                            <div className="col-2 at-custcol-4">
                                <h6>To Artist</h6>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <h6>Payout</h6>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <h6>To Seller</h6>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <h6>Payout</h6>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <h6>Total</h6>
                            </div>
                        </div>
                        {/* heading row  */}
                        <div className="row">
                            <div className="col-2 at-custcol-4">
                                <p>
                                    ART Price
                                </p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p><CurrencyFormat amount={artist.price} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>
                                    {artist.payouts}%
                                </p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>
                                    <CurrencyFormat amount={seller.price} />
                                </p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>{seller.payouts}% </p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p><CurrencyFormat amount={transaction.price} /></p>
                            </div>
                        </div>
                        {/* item row */}
                        <div className="row">
                            <div className="col-2 at-custcol-4">
                                <p>Shipping:</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p><CurrencyFormat amount={Number(artist.shipmentPrice) > 0 ? artist.shipmentPrice : '0'} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>{Number(artist.shipmentPrice) > 0 ? '100%' : '0%'}</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p><CurrencyFormat amount={Number(seller.shipmentPrice) > 0 ? seller.shipmentPrice : '0'} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>{Number(seller.shipmentPrice) > 0 ? '100%' : '0%'}</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p><CurrencyFormat amount={transaction.shippingPrice} /></p>
                            </div>
                        </div>
                        {/* item row
                    <div className="row">
                        <div className="col-2 at-custcol-4">
                            <p>Sales Tax:</p>
                        </div>
                        <div className="col-2 at-custcol-4">
                            <p>$0.00</p>
                        </div>
                        <div className="col-2 at-custcol-4">
                            <p> 0%</p>
                        </div>
                        <div className="col-2 at-custcol-4">
                            <p><CurrencyFormat amount={seller.salesTax} /></p>
                        </div>
                        <div className="col-2 at-custcol-4">
                            <p>100%</p>
                        </div>
                        <div className="col-2 at-custcol-4">
                            <p><CurrencyFormat amount={transaction.tax} /></p>
                        </div>
                    </div> */}
                        {/* item row */}

                        <div className="row">
                            <div className="col-2 at-custcol-4">
                                <p>Stripe Fees*:</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>-<CurrencyFormat amount={artist.stripeFee} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> {artist.payouts}%</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>-<CurrencyFormat amount={seller.stripeFee} /> </p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>{seller.payouts}%</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>-<CurrencyFormat amount={transaction.stripeFee} /></p>
                            </div>
                        </div>
                        {/* item row */}

                        <div className="row">
                            <div className="col-2 at-custcol-4">
                                <p>Payments**:</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>$0.00</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> 0%</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>-$0.00 </p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>0%</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>-$0.00</p>
                            </div>
                        </div>
                        {/* item row */}

                        <div className="row">
                            <div className="col-2 at-custcol-4">
                                <p>Total:</p>
                            </div>
                            <div className="col-2 at-custcol-4 at-bordertop">
                                <p> <CurrencyFormat amount={artist.total} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> </p>
                            </div>
                            <div className="col-2 at-custcol-4 at-bordertop">
                                <p><CurrencyFormat amount={seller.total} /> </p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p></p>
                            </div>
                            <div className="col-2 at-custcol-4 at-bordertop">
                                <p><CurrencyFormat amount={Number(seller.total) + Number(artist.total)} /></p>
                            </div>
                        </div>
                        {/* item row */}

                    </div>

                    <div className="at-transfer-help">
                        <p>
                            * Includes credit card and fund transfer fees
                        </p>
                        <p>   ** Includes payments to service providers</p>
                    </div>
                </div>

                    :
                    //  Single artist transaction
                    artist && <div className="at-transfer-amount at-transfer-amount-threecol">
                        <div className="at-transfer-upper">
                            <h5 className="text-center at-text-underline">Transfer Amount Calculation</h5>
                            <div className="row justify-content-md-center">
                                <div className="col-3 at-custcol-4">
                                </div>
                                <div className="col-3 at-custcol-4">
                                    <h6>{artist && isExternalArt ? 'To Seller' : 'To Artist'}</h6>
                                </div>
                                <div className="col-2 at-custcol-4">
                                    <h6>Payout</h6>
                                </div>
                            </div>
                            {/* heading row  */}
                            <div className="row justify-content-md-center">
                                <div className="col-3 at-custcol-4">
                                    <p>
                                        ART Price
                                    </p>
                                </div>
                                <div className="col-3 at-custcol-4">
                                    <p><CurrencyFormat amount={artist.price} /></p>
                                </div>
                                <div className="col-2 at-custcol-4">
                                    <p>
                                        {artist.payouts}%
                                    </p>
                                </div>

                            </div>
                            {/* item row */}
                            <div className="row justify-content-md-center">
                                <div className="col-3 at-custcol-4">
                                    <p>Shipping:</p>
                                </div>
                                <div className="col-3 at-custcol-4">
                                    <p><CurrencyFormat amount={artist.shipmentPrice} /></p>
                                </div>
                                <div className="col-2 at-custcol-4">
                                    <p> 100%</p>
                                </div>
                            </div>
                            {/* item row */}
                            {/* <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                                <p>Sales Tax:</p>
                            </div>
                            <div className="col-3 at-custcol-4">
                                <p><CurrencyFormat amount={artist.salesTax} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> 100%</p>
                            </div>
                        </div> */}
                            {/* item row */}

                            <div className="row justify-content-md-center">
                                <div className="col-3 at-custcol-4">
                                    <p>Stripe Fees*:</p>
                                </div>
                                <div className="col-3 at-custcol-4">
                                    <p>-<CurrencyFormat amount={artist.stripeFee} /></p>
                                </div>
                                <div className="col-2 at-custcol-4">
                                    <p> 100%</p>
                                </div>
                            </div>
                            {/* item row */}

                            <div className="row justify-content-md-center">
                                <div className="col-3 at-custcol-4">
                                    <p>Payments**:</p>
                                </div>
                                <div className="col-3 at-custcol-4">
                                    <p>$0.00</p>
                                </div>
                                <div className="col-2 at-custcol-4">
                                    <p> 0%</p>
                                </div>
                            </div>
                            {/* item row */}

                            <div className="row justify-content-md-center">
                                <div className="col-3 at-custcol-4">
                                    <p>Total:</p>
                                </div>
                                <div className="col-3 at-custcol-4 at-bordertop">
                                    <p> <CurrencyFormat amount={Number(artist.total)} /></p>
                                </div>
                                <div className="col-2 at-custcol-4">
                                    <p> </p>
                                </div>
                            </div>
                            {/* item row */}

                        </div>

                        <div className="at-transfer-help d-flex justify-content-center pb-2">
                            <div>
                                <p>
                                    * Includes credit card and fund transfer fees
                                </p>
                                <p>   ** Includes payments to service providers</p>
                            </div>

                        </div>
                    </div>
                }
            </>
        )
    }

    const newStyle = () => {
        return (
            <div className="at-transfer-amount ">
                <div className="at-transfer-upper">
                    <h5 className="at-text-underline">Transfer Amount Calculation</h5>
                    {(isAgent || seller || artist || isProvider) && <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                        <TabList>
                            {artist && <Tab>Artist/Gallery</Tab>}
                            {isAgent && <Tab>Seller</Tab>}
                            {isProvider && <Tab>Service Provider</Tab>}
                        </TabList>
                        {artist && <TabPanel>
                            <div className="py-4">
                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <h6>{artist && isExternalArt ? 'To Seller' : 'To Artist'}</h6>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <h6>Payout</h6>
                                    </div>
                                </div>
                                {/* heading row  */}
                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>
                                            ART Price
                                        </p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p><CurrencyFormat amount={artist.price} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p>
                                            {artist.payouts}%
                                        </p>
                                    </div>

                                </div>
                                {/* item row */}
                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Shipping:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p><CurrencyFormat amount={artist.shipmentPrice} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> 100%</p>
                                    </div>
                                </div>
                                {/* item row */}
                                {/* <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                                <p>Sales Tax:</p>
                            </div>
                            <div className="col-3 at-custcol-4">
                                <p><CurrencyFormat amount={artist.salesTax} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> 100%</p>
                            </div>
                        </div> */}
                                {/* item row */}

                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Stripe Fees*:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p>-<CurrencyFormat amount={artist.stripeFee} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> 100%</p>
                                    </div>
                                </div>
                                {/* item row */}

                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Payments**:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p>$0.00</p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> 0%</p>
                                    </div>
                                </div>
                                {/* item row */}

                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Total:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4 at-bordertop">
                                        <p> <CurrencyFormat amount={Number(artist.total)} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> </p>
                                    </div>
                                </div>
                                {/* item row */}



                                <div className="at-transfer-help d-flex justify-content-center pb-2">
                                    <div>
                                        <p>
                                            * Includes credit card and fund transfer fees
                                        </p>
                                        <p>   ** Includes payments to service providers</p>
                                    </div>

                                </div>
                            </div>
                        </TabPanel>}

                        {isAgent && seller && artist && <TabPanel>
                            <div className="py-4">
                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <h6>{'To Seller'}</h6>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <h6>Payout</h6>
                                    </div>
                                </div>
                                {/* heading row  */}
                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>
                                            ART Price
                                        </p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p><CurrencyFormat amount={seller.price} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p>
                                            {seller.payouts}%
                                        </p>
                                    </div>

                                </div>
                                {/* item row */}
                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Shipping:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p><CurrencyFormat amount={seller.shipmentPrice} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> 100%</p>
                                    </div>
                                </div>
                                {/* item row */}
                                {/* <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                                <p>Sales Tax:</p>
                            </div>
                            <div className="col-3 at-custcol-4">
                                <p><CurrencyFormat amount={seller.salesTax} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> 100%</p>
                            </div>
                        </div> */}
                                {/* item row */}

                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Stripe Fees*:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p>-<CurrencyFormat amount={seller.stripeFee} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> 100%</p>
                                    </div>
                                </div>
                                {/* item row */}

                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Payments**:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p>$0.00</p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> 0%</p>
                                    </div>
                                </div>
                                {/* item row */}

                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Total:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4 at-bordertop">
                                        <p> <CurrencyFormat amount={Number(seller.total)} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> </p>
                                    </div>
                                </div>
                                {/* item row */}



                                <div className="at-transfer-help d-flex justify-content-center pb-2">
                                    <div>
                                        <p>
                                            * Includes credit card and fund transfer fees
                                        </p>
                                        <p>   ** Includes payments to service providers</p>
                                    </div>

                                </div>
                            </div>
                        </TabPanel>}

                        {isProvider && <TabPanel>
                            <div className="py-4">
                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <h6>{'To Provider'}</h6>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <h6>Payout</h6>
                                    </div>
                                </div>
                                {/* heading row  */}
                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>
                                            Service Price
                                        </p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p><CurrencyFormat amount={providerPrice.price} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p>
                                            {'100'}%
                                        </p>
                                    </div>

                                </div>
                                {/* item row */}

                                {/* item row */}
                                {/* <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                                <p>Sales Tax:</p>
                            </div>
                            <div className="col-3 at-custcol-4">
                                <p><CurrencyFormat amount={seller.salesTax} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> 100%</p>
                            </div>
                        </div> */}
                                {/* item row */}

                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Stripe Fees*:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4">
                                        <p>-<CurrencyFormat amount={providerPrice.stripeFee} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> 100%</p>
                                    </div>
                                </div>
                                {/* item row */}


                                {/* item row */}

                                <div className="row justify-content-md-center">
                                    <div className="col-3 at-custcol-4">
                                        <p>Total:</p>
                                    </div>
                                    <div className="col-3 at-custcol-4 at-bordertop">
                                        <p> <CurrencyFormat amount={Number(providerPrice.total)} /></p>
                                    </div>
                                    <div className="col-2 at-custcol-4">
                                        <p> </p>
                                    </div>
                                </div>
                                {/* item row */}



                                <div className="at-transfer-help d-flex justify-content-center pb-2">
                                    <div>
                                        <p>
                                            * Includes credit card and fund transfer fees
                                        </p>
                                        <p>   ** Includes payments to service providers</p>
                                    </div>

                                </div>
                            </div>
                        </TabPanel>}

                    </Tabs>}
                </div>
            </div>)
    }
    return (showNewStyle ? newStyle() : oldStyle())
}