import React, { Component } from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import {
	ICollection,
	IUser,
	IStoreReducers,
	ICollectionItem,
} from '../../../interfaces'
import { connect } from 'react-redux'
import { collectionService } from '../../../services/collection.service'
import { AxiosResponse } from 'axios'
import { toastService } from '../../../services'
import { locale, lang } from '../../../locale'
import LoadingComponent from '../../../components/common/Loading.component'
import CollectionGalleryComponent from '../../../components/ArtCentral/collection/CollectionGallery.component'
import {
	UserPicComponent,
	UserNameComponent,
} from '../../../components/User/index.component'
import { ERole } from '../../../enums'
import { info } from 'console'
import { routes } from '../../../router'
import { IResponse } from '../../../interfaces/db.interface'

interface IPageProps extends RouteComponentProps<{ id: string }> {
	user: IUser
	setStudioUser: Function
}

interface IPageState {
	loading: boolean
	information: {
		user: IUser
		collections: Array<ICollection>
	} | null
}

class CollectionListPage extends Component<IPageProps> {
	state: IPageState = {
		loading: false,
		information: null,
	}

	componentDidMount() {
		this.fetch(this.params.id)
	}


	componentWillReceiveProps(props: IPageProps) {
		if (props.match.params.id !== this.params.id) {
			this.fetch(props.match.params.id)
		}
	}

	get params() {
		return this.props.match.params
	}

	toggleLoading = (key: 'loading') => {
		this.setState({ [key]: !this.state[key] })
	}

	fetch = (id: string): void => {
		this.toggleLoading('loading')
		collectionService.userCollections(id).then(
			(response: AxiosResponse<IResponse<any>>) => {
				this.toggleLoading('loading')
				const { user, collections } = response.data.data

				this.setState({
					information: {
						...this.state.information,
						user: { ...user },
						collections: !user.isAgent ? collections : (user?.assignment_collections?.length > 0 ? user?.assignment_collections : user?.collections?.length > 0 ? user.collections : [])
					}
				})
			},
			(error) => {
				this.toggleLoading('loading')
				// toastService.error(lang.common.httpError)
			}
		)
	}

	render() {
		const { information, loading } = this.state
		const { location, user } = this.props

		return (
			<div className='at-postcollections scrollable at-contentarea'>
				{information && !loading ? (
					<div className='at-collections'>
						<div className='at-orangeeconhead'>
							<a
								onClick={this.props.history.goBack}
								className='at-left-arrowicon'
							>
								<i className='icon-left-arrow'></i>
							</a>
							<UserPicComponent user={information.user} />
							<div className='at-orangeeconcontent'>
								{information.user?.isDeaf && (
									<span className='at-postcontenticon'>
										<i className='icon-hand'></i>
									</span>
								)}
								<h3>
									{information.user?.isAgent ? information.user?.gallery_name : 'Art Studio'}
									{information.user?.isArtist && <UserNameComponent user={information.user} />}
								</h3>
								{
									<div className="at-btniconholder">
										<a className="at-btnmore at-postbtn">
											<i className='icon-event-view' title="Events" onClick={() => this.props.history.push(`${routes.artCentral.events.list}/${this.params.id}`)}></i>
										</a>
									</div>
								}
								{/* <div className="at-btniconholder">
											<a  className="at-btnheart at-postbtn"><i className="icon-favorite"></i></a>
											<a  id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a>
											<a  className="at-btnmore at-postbtn"><i className="icon-more"></i></a>
										</div> */}
							</div>
						</div>
						{!!information.collections.length ? (
							<div className='at-collectiongallery at-themescrollbar '>
								<CollectionGalleryComponent
									event={() => collectionService.listWithItems(this.params.id)}
								/>
							</div>
						) : (
							<p>No data found</p>
						)}
					</div>
				) : (
					<LoadingComponent />
				)}
			</div>
		)
	}
}

const mapState = (state: IStoreReducers) => ({
	user: state.user,
})



export default connect(mapState, {})(CollectionListPage)
