import React from 'react'
import { ERole } from '../../../enums'
import { IUser } from '../../../interfaces'
import { ISubmissionProcessState } from '../../../interfaces/submission-process.interface'
import { ErrorComponent } from '../../common/Error.component'


interface IProps {
    setRole: Function,
    state: ISubmissionProcessState
    onChangeText: Function
    addressVerify: boolean
    errors: any
}
export const StepOne = ({ state, setRole, onChangeText, addressVerify, errors }: IProps) => {

    const getAddressLabel = () => {
        if (state.roles.includes(ERole.ARTIST)) {
            return 'Art Studio Address'
        }
        if (state.roles.includes(ERole.AGENT)) {
            return 'Gallery Address'
        }
        if (state.roles.includes(ERole.CONSULTANT)) {
            return 'Business Address'
        }

        return 'Address'
    }
    return <div>
        {!state.roles.includes(ERole.CONSULTANT) && <h5 className="at-stepheading">Select One</h5>}
        <div className="row at-stepone">
            {!state.roles.includes(ERole.CONSULTANT) && <>
                <div className="col-12 at-formgroup-outer">
                    <div className="at-checkbox at-checkbox-roundfullwidth" onClick={() => setRole(ERole.ARTIST)}>
                        <input type="checkbox" checked={state.roles.includes(ERole.ARTIST)} name="" />
                        <label><i className="at-icon-checkbox icon-paint"></i> I am an Artist</label>
                    </div>
                </div>
                <div className="col-12 at-formgroup-outer ">
                    <div className="at-checkbox at-checkbox-roundfullwidth" onClick={() => setRole(ERole.AGENT)}>
                        <input type="checkbox" checked={state.roles.includes(ERole.AGENT)} name="" />
                        <label><i className="at-icon-checkbox icon-artist-gallery" ></i>I am a Gallery</label>
                        {errors && errors['role'] && <ErrorComponent errors={errors['role']} multiple={false} />}
                    </div>
                </div>
            </>}



            <div className="col-12 at-formgroup-outer">
                <h6>Enter your address</h6>

                {addressVerify && <>
                    <div className="form-group at-floatlabel-h40">
                        <input type="text" name="address" value={state.address} className="form-control" placeholder=" " onChange={(e: any) => onChangeText(e)} />
                        <label>{getAddressLabel()}</label>
                        {errors && errors['address'] && <ErrorComponent errors={errors['address']} multiple={false} />}
                    </div>
                    <div className="form-group at-floatlabel-h40">
                        <input type="text" disabled name="city" value={state.city} className="form-control" placeholder=" " onChange={(e: any) => onChangeText(e)} />
                        <label>City</label>
                    </div>

                    <div className="form-group at-floatlabel-h40">
                        <input type="text" disabled name="state" value={state.state} className="form-control" placeholder=" " onChange={(e: any) => onChangeText(e)} />
                        <label>State</label>
                    </div>
                </>}

                <div className="form-group at-floatlabel-h40 mb-0">
                    <input
                        id=""
                        className="form-control "
                        name="zipcode"
                        placeholder=" "
                        onChange={(e: any) => onChangeText(e)}
                    />
                    <label>Zipcode</label>
                    {errors && errors['zipcode'] && <ErrorComponent errors={errors['zipcode']} multiple={false} />}
                </div>
            </div>
        </div>
    </div>
}