import React from 'react'
import { useHistory } from 'react-router'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { CuratorialServiceComponent } from '../../components/User/Services/CuratorialServices/CuratorialService.component';
import AccessCuratorialProjectComponent from '../../components/User/Services/CuratorialServices/participants/AccessCuratorialProject';
import { _logger } from '../../helper';
import { useLocation } from 'react-router-dom';
import { ParticapantOffersComponent } from '../../components/User/Services/CuratorialServices/participants/ParticipantsOffers.component';


interface IProps {
    hideBackButton: boolean
}
const ParticipantCuratorialProjectPage = ({ hideBackButton }: IProps) => {
    const history = useHistory()
    const location = useLocation()
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>Curatorial Projects<span className="at-tutorialplaybtn"></span></h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    <Tabs selected={location.state && (location.state as any).page === 'offer' ? 1 : 0}>
                        <Tab label="Invitations">
                            <AccessCuratorialProjectComponent />
                        </Tab>
                        <Tab label="Offers">
                            <ParticapantOffersComponent />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </main>
    )
}

export default ParticipantCuratorialProjectPage