import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom';
import { _logger } from '../../helper';
import { IStoreReducers, IUser } from '../../interfaces';
import UserAccount from '../../components/User/MangeFunds/FundDistibution'
import Transactions from '../../components/User/MangeFunds/SettleMentLog'
import Tranactions from '../../components/User/MangeFunds/SettleMentLog';
import { UniversalModal } from '../../components/common/Modals/Universal.modal';
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial';
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon';
import { EScreens } from '../../enums/screens.enum';

interface IProps extends RouteComponentProps {
}

const ManageFunds = (props: IProps) => {

    const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false)

    const openTutorialBox = () => {
        setShowTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setShowTutorialBox(false)
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Manage Funds <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.MANAGE_FUNDS} onClick={openTutorialBox} /></span></h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                        <Tab label="Account">
                            <UserAccount {...props} />
                        </Tab>
                        <Tab label="Transfers">
                            <Tranactions {...props} />
                        </Tab>
                    </Tabs>
                </div>
                {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={closeTutorialBox} position='bottom-0'>
                    <ScreenTutorial screen={EScreens.MANAGE_FUNDS} />
                    {/* at-categorybox ends */}
                </UniversalModal>
                }
            </div>
        </main>
    )
}



export default ManageFunds