import React, {Component, FormEvent} from 'react';
import { BAD_REQUEST, NOT_FOUND } from 'http-status'
import {validationErrors} from "../../shared";
import { authService, toastService } from '../../services';
import {ErrorComponent} from "../common/Error.component";
import PinField from "react-pin-field"
import debounce from 'lodash/debounce'
import LoadingComponent from './../common/Loading.component';

interface VerificationCodeProps {
    onClose: any
    openSignin: any
    openResetPassword: any
	phoneNumber: string
}
interface IPageState {
	errors: any
	phoneNumber: string
	loading: boolean
	pin: string
}
class VerificationCodeComponent extends Component<VerificationCodeProps> {
	state: IPageState = {
        errors: null,
        phoneNumber: '',
        loading: false,
		pin: ''
    }

    formElement: any = null;
	debounceInput: any = null;

	constructor(props: any) {
        super(props);
    }

    componentWillMount() {
        this.debounceInput = debounce(this.onPinChange, 1000)
    }

    toggleLoading = (): void => {
        this.setState({ loading: !this.state.loading })
    }

    submit = (e: FormEvent): void => {
        e.preventDefault();
    }

    onPinChange = (pin: string): void => {
        if (pin.length < 4) return;
	    this.toggleLoading();
        const { phoneNumber } = this.props;
        this.setState({ errors: null, pin })
        authService.verifyPinCode({ phoneNumber, pin }).then(this.successHandler, this.errorHandler)
    }

    successHandler = ({ data }: any) => {
        this.toggleLoading();
        if (data && !data.success) {
            toastService.error(data.message)
        } else {
            toastService.success(data.message);
            this.props.openResetPassword(this.state.pin)
        }
    }

    errorHandler = ({ response }: any) => {
        this.toggleLoading();
        let errors = null;
        switch (response.status) {
            case BAD_REQUEST:
                errors = validationErrors(response.data);
                break;
            case NOT_FOUND:
                toastService.error('Incorrect PIN code.')
                break;
            default:
                break;
        }
        this.setState({ errors })
    }

    errorElement = (key: string) => (
        this.state.errors && this.state.errors[key] && <ErrorComponent errors={this.state.errors[key]} multiple={false}/>
    )

    render() {
        const { loading } = this.state;
        return (
			<div className="at-themepopupbox">
				<a className="at-close" id="at-close"  onClick={this.props.onClose}><i className="icon-cancel"></i></a>
				<form className="at-formtheme at-formrecoverycode" onSubmit={this.submit} ref={ref => this.formElement = ref}>
					<fieldset>
						<h2>Enter 4-Digit Recovery Code<span>The recovery code was sent your mobile number<em>Please enter the code</em></span></h2>
                        <div className="form-group">
                            <div className="at-recoverycodebox">
                                <PinField length={4} validate={/^[0-9]$/} onChange={this.debounceInput} className="form-control"/>
                                { this.errorElement('pin') }
                            </div>
                        </div>
                        {
                            loading && <LoadingComponent />
                        }
						<div className="form-group">
							<em>Back to<a className="at-gotologinfromforgotpassword" onClick={this.props.openSignin}>LOGIN</a></em>
						</div>
					</fieldset>
				</form>
			</div>
        );
    }
}

export default VerificationCodeComponent;
