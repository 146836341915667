import { HttpService } from './base.service'
import { data } from 'jquery';

class UserTemplateService extends HttpService {
    private readonly prefix: string = 'templates'
    /**
     * 
     * Check url 
     */
    checkUrl = (url: string): Promise<any> => this.get(`${this.prefix}/checkUrl/${url}`)

    /**
     * 
     * Add Template Data
     */

    add = (data: any): Promise<any> => this.post(`${this.prefix}/add`, data)

    /**
    * 
    * get User template
    */
    getUserTemplate = (id: string): Promise<any> => this.get(`${this.prefix}/userTemplateData/${id}`)

    /**
     * 
     * get User template
     */

    getTemplate = (data: any): Promise<any> => this.get(`${this.prefix}/getTemplate`, data)

    updateTemplate = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/update/${id}`, data)
}

export const userTemplateService = new UserTemplateService();