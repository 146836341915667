import { AxiosResponse } from 'axios'
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Subscription } from 'rxjs'
import Countdown from 'react-countdown';
import { images } from '../../../../../assets/image'
import { PLATFORM_FEE } from '../../../../../environment'
import { currencyFormatter } from '../../../../../helper'
import { IUser } from '../../../../../interfaces'
import { IAuctionArtDocument, IAuctionAttendessDocument, IAuctionBiddingDocument, IAuctionDocument } from '../../../../../interfaces/auction.interface'
import { IResponse } from '../../../../../interfaces/db.interface'
import { AuctionArtBiddingUpdateAction, auctionService, paymentService, toastService } from '../../../../../services'
import { AddressPopup } from '../../../../common/AddressPopup'
import { CurrencyFormat } from '../../../../common/CurrencyFormat'
import { CurrencyField } from '../../../../common/CurrencyInput'
import { PayPopup } from '../../../../common/Modals/PayPopup'
import { UserPicComponent } from '../../../index.component'
import moment from 'moment';

interface IProps {
    auctionArt: IAuctionArtDocument
    attendee?: IAuctionAttendessDocument
    user: IUser
    auction: IAuctionDocument
    isRightPanel?: boolean
    onRestartAuctionOnArt: Function
}


const BiddingBox = ({ auctionArt, attendee, user, auction, isRightPanel, onRestartAuctionOnArt }: IProps) => {

    const time = 120000
    const countRef = useRef<any>(null)
    const [amount, setAmount] = useState<string>('')
    const [maxBid, setMaxBid] = useState<IAuctionBiddingDocument>()
    const [minBid, setMinBid] = useState<IAuctionBiddingDocument>()
    const [myCurrentBid, setMyCurrentBid] = useState<IAuctionBiddingDocument>()
    const [loading, setLoading] = useState<boolean>(false)
    const [showPayBox, setShowPayBox] = useState<boolean>(false)
    const [showAddressBox, setShowAddressBox] = useState<boolean>(false)
    const [tax, setTax] = useState<any>()
    const [stripeData, setStripeData] = useState<any>()
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false)
    const [inValidAddress, setInValidAddress] = useState<boolean>(false)
    const [remianingTime, setRemainingTime] = useState<number>(time)

    const subscribe = new Subscription()

    const fecthBid = useCallback((newId: string = '') => {
        console.log("run")
        setLoading(true)
        auctionService.getLatestBid(newId ? newId : auctionArt._id).then((res: AxiosResponse<IResponse<Array<IAuctionBiddingDocument>>>) => {
            if (res.data.data.length > 0) {
                if (res.data.data.length == 1) {
                    setMinBid(res.data.data[0])
                    setMaxBid(res.data.data[0])
                } else {
                    setMinBid(res.data.data[1])
                    setMaxBid(res.data.data[0])
                }

                let filter = res.data.data.filter((b: IAuctionBiddingDocument) => user._id === b.attendee.user._id)
                if (filter?.length > 0) {
                    setMyCurrentBid(filter[0])
                }
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(true)
        })
    }, [])


    useEffect(() => {
        fecthBid()
        const biddingUpdate = AuctionArtBiddingUpdateAction.listen.subscribe((status: string) => {
            console.log("biduudud", status)

            if (status) {
                fecthBid(status)
            }
        })
        subscribe.add(biddingUpdate)
        return () => {
            subscribe.unsubscribe()
        }

    }, [fecthBid])

    useEffect(() => {
        if (auctionArt && auctionArt._id) {
            fecthBid(auctionArt._id)
        }
    }, [auctionArt._id])

    useEffect(() => {
        if (auctionArt.purchasingTime && !auctionArt.isPurchased) {
            let currentTimeDif = moment().diff(moment(auctionArt.purchasingTime), "milliseconds")
            if (time - currentTimeDif <= 0) {
                countRef.current?.stop()
                toastService.success("Time up")
                onComplete()
            }
        }

    }, [auctionArt])

    const onChangePrice = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        let price = value < 0 ? 0 : value;
        if (price.includes("$") || price.includes(","))
            price = price.split("$")[1].replace(/,/gi, "");

        setAmount(price)
    }

    const onClickBid = (e: FormEvent) => {
        e.preventDefault()
        if (maxBid) {
            if (Number(amount) < Number(maxBid.amount)) {
                toastService.warning("Amount must be greater than the current bid amount.")
                return
            }
        } else if (Number(amount) < (Number(auctionArt.art.auctionMinPrice) + Number(PLATFORM_FEE) + Number(auctionArt.art.shipping))) {
            toastService.warning("Amount must be greater than minimum art price.")
            return
        }

        setLoading(true)
        auctionService.createBid({ auctionArtId: auctionArt._id, attendee: attendee?._id, amount: Number(amount) }).then((res: AxiosResponse<IResponse<IAuctionBiddingDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                setAmount('')
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(true)
        })
    }

    const onCloseBid = () => {
        setLoading(true)
        auctionService.stopAuctionOnArt({ auctionArtId: auctionArt._id, finalBid: maxBid?._id }).then((res: AxiosResponse<IResponse<IAuctionArtDocument>>) => {
            if (res.data.success) {
                let { data } = res.data
                toastService.success(res.data.message)
                // if (data.finalBid && data.finalBid.attendee && data.finalBid.attendee.user && data.finalBid.attendee.user._id === user._id) {
                //     toastService.success("The bidding has been stopped. You have 2 minutes to buy this peice of art.")
                // }
                setAmount('')
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(true)
        })
    }

    const onComplete = () => {
        if (auction.user._id === user._id) {
            onRestartAuctionOnArt(auctionArt._id)
        }

        if (auctionArt.finalBid.attendee.user._id === user._id) {
            toastService.success("Time Up")
        }

        onCloseAddressBox()
        onClosePaybox()
    }

    /******************************************* Payment Modal ************************************************* */

    const OpenAddressBox = () => {

        setShowAddressBox(true);
    }

    const onCloseAddressBox = () => {
        setShowAddressBox(false);
    }

    const onSubmitAddressBox = (address: any) => {
        if (!maxBid) return
        let price = (Number(maxBid.amount)).toFixed(2)
        setLoading(true)
        paymentService.taxPayment({
            state: address.state,
            address: address.shippingAddress,
            price: price,
            sellerID: auctionArt.art.art_collection.user._id,
            zip: address.zipcode,
            city: address.city
        }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'success') {
                if (!res.data.total_tax || isNaN(res.data.total_tax)) {
                    setInValidAddress(true)
                    return
                }
                setTax(`${res.data.total_tax}`)
                openPayBox()
            }
            if (res.data.status === 'Fail') {
                setInValidAddress(true)
                return
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
        setShowAddressBox(false);
    }

    const openPayBox = () => {
        setShowPayBox(true)
    }

    const onClosePaybox = () => {
        let obj: any = {}

        setShowPayBox(false)
    }

    const onSubmitPaybox = () => {
        let obj: any = {}
        setShowPayBox(false)
        setShowPaymentForm(false)
    }

    const onConfirmPay = () => {
        handlePayment()
    }

    const handlePayment = () => {
        if (!maxBid || !attendee) return
        let data = {
            auctionArt: auctionArt._id,
            bid: maxBid._id,
            attendee: attendee._id,
            tax: tax,
            price: Number(maxBid.amount) + Number(PLATFORM_FEE),
            itemName: `${auctionArt.art.name}`
        }
        setLoading(true)
        auctionService.auctionCheckout({ ...data }).then((res: AxiosResponse<any>) => {
            if (res.data.success) {
                // toastService.success(res.data.message)
                setStripeData({ clientSecret: res.data.client_secret })
            }
            setLoading(false)
            setShowPaymentForm(true)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }
    let auctionArtUser = auctionArt?.art.art_collection.user
    return (
        <>
            <div className={`${isRightPanel ? "col-lg-12 order-2" : "col-lg-6 col-xl-5"} col-md-12`}>
                <div className="at-auction-box at-greyborderbox at-box-p20 at-mt-32">
                    <div className="row at-row-mlr-6">
                        <div className="col-md-12 col-lg-12 at-titlesbox">
                            <h4>{auctionArt.art.name}</h4>
                            <h5>{auctionArt.art.height}" X {auctionArt.art.width}" X {auctionArt.art.depth}" {auctionArt.art.artTechnique ? auctionArt.art.artTechnique : ''} </h5>
                            <h5 className="mb-1">Minimum Price: <CurrencyFormat amount={Number((Number(auctionArt.art.auctionMinPrice) + Number(PLATFORM_FEE) + Number(auctionArt.art.shipping)).toFixed(2))} /></h5>
                            <h6>Artist: {auctionArtUser ? <span>{auctionArtUser.firstName} {auctionArtUser.lastName}</span> : ''}</h6>
                        </div>
                    </div>
                    <div className="at-bordertop-1-spacing">
                        <div className="row at-row-mlr-6  at-auctionheading">
                            <div className="col-6 col-lg-6">
                                <div className="at-auctionheading at-left-auctionheading">
                                    <h6>Previous Highest Bid: <span className="at-fs18 at-color-nightrider"><CurrencyFormat amount={Number(minBid?.amount || 0)} /></span></h6>
                                    <p className="at-paddlenumber-small">Paddle Number: <span className="at-color-orange at-fontbold">{minBid ? minBid.attendee.paddleNumber : ''}</span></p>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="at-auctionheading at-right-auctionheading">
                                    <h6>Current Highest Bid: <span className="at-fs18 at-color-nightrider"><CurrencyFormat amount={Number(maxBid?.amount || 0)} /></span></h6>
                                    <p className="at-paddlenumber-small">Paddle Number: <span className="at-color-orange at-fontbold">{maxBid ? maxBid.attendee.paddleNumber : ''}</span></p>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="at-bordertop-1-spacing">
                        <div className="row at-row-mlr-6 ">
                            <div className="col-md-7 col-lg-7">
                            <h4><span className="at-color-grey">Current Bid:</span> <span><CurrencyFormat amount={Number(bid ? bid.amount : 0)} /></span></h4>
                            <h4><span className="at-color-grey">Paddle No:</span> <span>{bid && bid.attendee ? bid.attendee.paddleNumber : ''}</span></h4>
                        </div>
                            <div className="col-md-5 col-lg-5">
                                <div className="at-auctionicon-wrap">
                                    <button className="at-btntransparent" disabled={user._id !== auction.user._id} onClick={() => onCloseBid()}><i className={`icon-auction ${user._id !== auction.user._id || !auctionArt.activeForBidding ? '' : 'at-color-greenlight3'}`}></i></button>
                                    {auction.user._id === user._id && auctionArt.activeForBidding && <p className="at-fs12 at-color-grey ">Click here to accept current bid.</p>}
                                    {!auctionArt.activeForBidding && auctionArt.finalBid && <p className="at-fs12 at-color-grey ">Current Bid Accepted</p>}
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="at-bordertop-1-spacing">
                        <div className="row at-row-mlr-6 ">
                            <div className="col-12">
                                {auction.user._id === user._id && auctionArt.activeForBidding && <div className="my-3">
                                    <button className="at-btn at-btn-bid at-btn-xl px-sm-2" onClick={() => onCloseBid()}>Accept Max Bid <i className="icon-auction3 at-iconlg"></i></button>
                                </div>}

                                {auction.user._id === user._id && !auctionArt.activeForBidding && <div className="my-3">
                                    <button className="at-btn at-btn-bid at-btn-disabled at-btn-xl px-sm-2" >Max Bid Accepted <i className="icon-auction2 at-iconlg"></i></button>
                                </div>
                                }

                                {!auctionArt.activeForBidding && auctionArt.art.art_collection.user._id !== user._id && !auctionArt.isPurchased && attendee && (auctionArt.finalBid?.attendee.user._id === user._id) && maxBid && <>
                                    <div className="w-100">
                                        <h4 className="at-fontsemibold mb-1">Congratulations</h4>
                                        <h6 className="at-color-grey at-fs12">Congratulations, Your bid is Accepted</h6>
                                    </div>
                                    <div className="my-3">
                                        <button className="at-btn at-longbtn at-btn-xl" onClick={() => OpenAddressBox()}>Buy Art Now</button>
                                    </div>
                                </>}
                                {!auctionArt.activeForBidding && !auctionArt.isPurchased && auctionArt.purchasingTime && <>
                                    <div className=" w-100">
                                        <h6 className="at-fontsemibold mb-1">Time Left</h6>
                                        <h6 className="at-color-grey at-fs12">Time left to buy art</h6>
                                    </div>

                                    <Countdown
                                        date={Date.now() + (remianingTime - moment().diff(moment(auctionArt.purchasingTime), "milliseconds") > 0 ? remianingTime - moment().diff(moment(auctionArt.purchasingTime), "milliseconds") : 0)}
                                        ref={countRef}
                                        onComplete={() => onComplete()}
                                        renderer={({ hours, minutes, seconds }) => {
                                            return (
                                                <div className="at-digitalclock w-100">
                                                    <div className="at-clocknumber">
                                                        <h2>0</h2>
                                                    </div>
                                                    <div className="at-clocknumber">
                                                        <h2>{minutes}</h2>
                                                    </div>
                                                    <div className="at-clockcolon">
                                                        <h2>:</h2>
                                                    </div>
                                                    <div className="at-clocknumber">
                                                        <h2>{Math.floor(seconds / 10)}</h2>
                                                    </div>
                                                    <div className="at-clocknumber">
                                                        <h2>{(seconds % 10)}</h2>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />

                                </>}
                            </div>
                        </div>
                    </div>

                    {/* {!auctionArt.activeForBidding && auctionArt.art.art_collection.user._id !== user._id && !auctionArt.isPurchased && attendee && (auctionArt.finalBid?.attendee.user._id === user._id) && maxBid && <div className="row at-row-mlr-6 at-bordertop-1-spacing">
                        <div className="col-6 pad-lr-7">
                            <label className="at-pricelabel" htmlFor="">ORIGINAL</label>
                            <span className="at-pricedetail at-pricedetail-two">Price: <em>{currencyFormatter.format(Number(maxBid.amount || 0))}</em></span>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <div className='form-group position-relative'>
                                <label className="at-pricelabel invisible">.</label>

                                <button type="button" className="at-btn at-btn-buyoriginal" disabled={loading} onClick={() => OpenAddressBox()}>BUY</button>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <p className="at-color-orange">Sales taxes and out of country shipping fees will be added to your bid</p>
                        </div>
                    </div>} */}
                    {auctionArt.activeForBidding && auctionArt.art.art_collection.user._id !== user._id && attendee && attendee.user._id !== auction.user._id && <div className="row at-row-mlr-6 at-submmitbid-row">
                        <div className="col-6">
                            <h5 className="at-submmitbid">Submit Your Bid</h5>
                        </div>
                        <div className="col-6">
                            <p className="at-paddlenumber">Paddle Number: <span className="at-color-orange at-fontbold">{attendee.paddleNumber}</span></p>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='form-group position-relative'>
                                <CurrencyField
                                    type="text"
                                    name='offer'
                                    value={amount}
                                    className="form-control text-capitalize at-input-bgwhite"
                                    onChange={onChangePrice}
                                    placeholder={`${currencyFormatter.format(Number(amount))}`}
                                />

                                <button type="button" disabled={loading || !amount} className="at-sendicon"
                                    onClick={onClickBid}
                                ><i className="icon-send"></i></button>
                                {/* {errors && errors['offer'] && <ErrorComponent errors={errors['offer']} multiple={false} />} */}
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <p className="at-color-orange">Sales taxes and out of country shipping fees will be added to your bid</p>
                            <div className="at-auctionheading at-auctionheading-big">
                                <h3 className="at-fontsemibold"><CurrencyFormat amount={Number(myCurrentBid?.amount || 0)} /></h3>
                                <h6>My Current Bid</h6>
                            </div>
                        </div>
                    </div>}
                </div>
                {/* {auctionArtUser && auctionArtUser.firstName && <div className="at-profilebox at-greyborderbox at-box-p20">
                    <div className="at-leftprofile">
                        <UserPicComponent className="at-figurefull-50" user={auctionArtUser} />
                        <figure className="at-figurefull-50">
                            <img src={images.img03} alt="" />
                        </figure>
                        <h5 className="at-profilename">{auctionArtUser.firstName} {auctionArtUser.lastName}</h5>
                    </div>
                    <h6 className="at-profilerole">Artist</h6>
                </div>} */}
            </div>
            {showAddressBox && <div className={`at-thankyou-popup at-themepopupholder ${showAddressBox && 'at-showthemesignuppopup'}`}><AddressPopup onClose={onCloseAddressBox} onSubmit={onSubmitAddressBox} user={auctionArt.finalBid.attendee.user} inValidAddress={inValidAddress} /></div>}
            {showPayBox && <div className={`at-thankyou-popup at-themepopupholder ${showPayBox && 'at-showthemesignuppopup'}`}>
                <PayPopup loading={loading} message='' tax={tax} platformFee={10} offerPrice={Number(Number(maxBid?.amount) + Number(PLATFORM_FEE)).toFixed(2)} item={maxBid} onClose={onClosePaybox} onConfirmPay={onConfirmPay} showPaymentForm={showPaymentForm} onSubmit={onSubmitPaybox} setDropinInstance={() => { }} stripeData={stripeData} />
            </div>}
        </>
    )
}

export default React.memo(BiddingBox)