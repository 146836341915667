import React, { useEffect, useState } from 'react'
import { RouteComponentProps, Switch, useHistory, withRouter } from 'react-router'
import $ from 'jquery'
import Art_Collections from '../ArtCentral/Art_Collections'
import BuyerGalleryWalk from './BuyerGallaryWalks'
import { images } from '../../assets/image'
import { ICollectionItem, ICollection, IUser, ISpace } from '../../interfaces'
import BuyerAccessProfessionServices from './BuyerProfessionalServices'
import BuyersNewsFeeds from './BuyersNewsFeeds'
import { useDispatch, useSelector } from 'react-redux'
import { activateSpaceAction, changeActiveSpace, fetchUserStudio, logoutAction, refreshUser, saveUser, setLeftPanel, setStudioUser } from '../../store/actions'
import BuyerCollectionPage from './BuyerCollectionView'
import BuyerArtDetailPage from './BuyerItemView'
import { routes } from '../../router'
import { authSelector, leftPanelSelector, studioUserSelector, userSelector } from '../../store/selectors'
import { AgoraLeaveActions, NotificationCountAction, ShowTutorialPageAction, spaceService, toastService, UserAlertCountAction, UserLoginAction, userService } from '../../services'
import { ERole } from '../../enums'
import BuyerGalleryPage from './BuyerCollectionsPage'
import { accountService } from '../../services/account.service'
import { ESubmissionStatus } from '../../enums/submission-process.enum'
import { Subscription } from 'rxjs'
import { IGalleryDocument, IGalleryWalkSpacesDocument } from '../../interfaces/Gallery-walk.interface'
import { BuyerGalleryWalkView } from './BuyerGalleryWalkView.component'
import BuyerArtAuctions from './BuyerArtAuctions.Page'
import { ADD_NEW_MEMBER, USER_ALERT_SPACE_ENTER } from '../../constants'
import { socketNode } from '../../services/socket.service'
import { AxiosResponse } from 'axios'
import { MessageModal } from '../../components/common/Modals/MessagePopup'
import BuyerGalleryFeatureArtView from './BuyerGalleryFeatureArtView'
import { IAuctionDocument } from '../../interfaces/auction.interface'
import BuyerAuctionArtsShowComponent from '../../components/User/Services/Auction/BuyerArtAuctionShowArts.component'
import { BuyerLiveAuction } from '../../components/User/Services/Auction/LiveAuction/BuyerLiveAuction'
import { IResponse } from '../../interfaces/db.interface'

interface IProps extends RouteComponentProps {

}
const BuyerArtCentral = (props: IProps) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const leftPanelState = useSelector(leftPanelSelector)
    const user = useSelector(userSelector)
    const studioUser = useSelector(studioUserSelector)
    const token = useSelector(authSelector).access_token
    const [comp, setComp] = useState<string>("")
    const [routeArray, setRouteArray] = useState<Array<string>>([])
    const [selectedCollection, setSelectedCollection] = useState<ICollection>()
    const [selectedItem, setSelectedItem] = useState<ICollectionItem>()
    const [selectedAuction, setSelectedAuction] = useState<IAuctionDocument>()
    const [selectedLiveAuction, setSelectedLiveAuction] = useState<IAuctionDocument>()
    const [selectedGalleryWalk, setSelectedGalleryWalk] = useState<IGalleryDocument>()
    const [selectedGalleryWalkSpace, setSelectedGalleryWalkSpace] = useState<IGalleryWalkSpacesDocument>()
    const [selectedUser, setSelectedUser] = useState<IUser>()
    const [didUpated, setDidUpdate] = useState(false)
    const userAlertSubscription = new Subscription()
    const notificationSubscription = new Subscription()
    const [count, setCount] = useState<number>(0)
    const [userAlertCount, setUserAlertCount] = useState<number>(0)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)

    const setRoute = () => {
        if (comp) {
            let array = routeArray
            array.push(comp)
            setRouteArray([...array])
        } else {
            let array = routeArray
            array.push("art_collection")
            setRouteArray([...array])
        }
    }

    const onArtCollectionView = async () => {
        await setComp('art_collection')
        // setSelectedCollection({ ...c })
        setRoute()
    }
    const onClickOnItem = async (i: ICollectionItem) => {
        setSelectedItem({ ...i })
        await setComp("item-view")
        setRoute()
    }

    const onGoBack = async () => {
        let currPage = routeArray[routeArray.length - 1]
        let array = routeArray
        array.pop()
        setRouteArray([...array])
        await setComp(currPage)
    }

    const onSelectCollection = async (c: ICollection) => {
        setSelectedCollection({ ...c })
        await setComp('collection-view')
        setRoute()
        // dispatch(setLeftPanel({
        //     collection: c,
        // }))
    }
    const onSelectItem = async (i: ICollectionItem) => {
        setSelectedCollection({ ...i.art_collection })
        setSelectedItem({ ...i })
        await setComp('item-view')
        // dispatch(setLeftPanel({
        //     item: i,
        // }))
    }

    const onClickOnGallery = async () => {
        await setComp('gallery-view')
        // setSelectedCollection({ ...c })
        setRoute()
    }
    const onClickOnFeeds = async () => {
        await setComp('feeds-view')
        // setSelectedCollection({ ...c })
        setRoute()
    }
    const onClickOnProfessional = async () => {
        await setComp('services-view')
        // setSelectedCollection({ ...c })
        setRoute()
    }

    const onClickOnGalleryWalk = async (g: IGalleryDocument) => {
        await setSelectedGalleryWalk(g)
        await setComp('gallery-walk-view')
        setRoute()
    }
    const onClickOnGalleryWalkSpace = async (g: IGalleryWalkSpacesDocument) => {
        await setSelectedGalleryWalkSpace(g)
        await setComp('gallery-walk-feature-art-view')
        setRoute()
    }

    const onBackClickOnCollectionPage = async () => {
        await setComp('art_collection')
        setRoute()
    }

    const onClickOnFeed = async (u: IUser) => {
        setSelectedUser({ ...u })
        await setComp("studio-view")
        setRoute()
        // dispatch(setLeftPanel({
        //     user: u
        // }))
    }

    const onClick = (route: string) => {
        dispatch(setLeftPanel({
            activeRigthPanel: true,
        }))
        history.push(route)
    }

    const onClickLoginIcon = () => {
        UserLoginAction.triggerAction(true)
    }

    const onClickOnArtAuction = async () => {
        if (!token) {
            UserLoginAction.triggerAction(true)
            return
        }
        await setComp('live-art-auction')
        // setSelectedCollection({ ...c })
        setRoute()
    }

    const onClickShowAuctionsArts = async (a: IAuctionDocument) => {
        await setSelectedAuction({ ...a })
        await setComp('live-art-auction-show-arts')
        setRoute()
    }
    const onClickJoinLiveAuctionsArts = async (a: IAuctionDocument) => {
        await setSelectedLiveAuction({ ...a })
        await setComp('join-live-art-auction')
        setRoute()
    }


    const onClickHide = () => {
        let check = !leftPanelState.activeRigthPanel
        // if (check) {
        //     $('#at-wrapper').removeClass('at-rightpanel-close')
        // } else {

        //     $('#at-wrapper').addClass('at-rightpanel-close')
        // }
        dispatch(setLeftPanel({
            activeRigthPanel: check,
        }))
    }

    const logout = () => {

        if (user.activeSpace && user.activeSpace._id) {
            const activeSpace = user.activeSpace
            AgoraLeaveActions.triggerAction(true)
            accountService.deactivateSpace().then((response: AxiosResponse<IResponse<any>>) => {
                let spaceName = spaceService.getSpaceName(activeSpace);
                toastService.info(`You have exited ${spaceName}`)
                dispatch(setStudioUser({} as any))
                dispatch(refreshUser())
                dispatch(activateSpaceAction(false))
                dispatch(logoutAction())
                dispatch(saveUser({} as any))
                history.push('/')
                // $('#at-wrapper').removeClass('expanded-video-list')
                // $('#at-wrapper').removeClass('speech-to-text-enabled')
                // $('#at-wrapper').removeClass('show-in-full-screen')
                onGoBack()
            }, (error: any) => {
                // toastService.error('Some error occurred.')

            })
            return
        }

        dispatch(logoutAction())
        dispatch(saveUser({} as any))
        history.push('/')
    }

    const onClickApplyOnline = (r: string) => {
        dispatch(setLeftPanel({
            activeRigthPanel: true
        }))
        history.push(r)
    }

    const onClickOnTutorial = async (isRoute: boolean) => {
        await dispatch(setLeftPanel({
            activeRigthPanel: true
        }))
        if (isRoute) {
            history.push(routes.appTutorials)
            return
        }
        ShowTutorialPageAction.triggerAction(true)
    }


    /*************************************** Admin Art Central *************************************************************** */

    const enterAdminArtCentralSpace = async () => {
        if (!token) {
            UserLoginAction.triggerAction(true)
            return
        }

        const space: ISpace = (await spaceService.adminArtCentralSpace()).data;
        changeActiveSpace && dispatch(changeActiveSpace(space))
        socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
        let data = {
            message: `${user?.firstName} ${user?.lastName} has just entered your space.`,
            to: space.userId,
            from: user?._id,
            type: USER_ALERT_SPACE_ENTER,
            spaceID: space._id
        }
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
        }).catch((err: any) => {
            console.log(err)
        })
        // dispatch(fetchUserStudio(space.userId))
        // sendUserAlert(c, `${user?.firstName} ${user?.lastName} has just entered your space.`, USER_ALERT_SPACE_ENTER, false, space._id)
        dispatch(activateSpaceAction(true))
    }

    const openMessageBox = () => {
        if (!token) {
            UserLoginAction.triggerAction(true)
            return
        }
        setShowMessageBox(true)
    }

    const closeMessageBox = () => {
        setShowMessageBox(false)
    }

    const sendAdminAlert = (message: string) => {
        let data = {
            message: message,
            from: user._id,
        }
        userService.sendAdminUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success("Message sent successfully")
            }
            closeMessageBox()
        }).catch((err: any) => {
            console.log(err)
            closeMessageBox()
        })
    }

    const showReEnterSpace = () => {
        if (comp === "studio-view" || comp === "item-view" || comp === "collection-view") {
            return true
        }

        return false
    }

    const onReEnterSpace = async () => {
        if (!studioUser || !studioUser._id) {
            return
        }

        const space: ISpace = (await spaceService.userArtCentralSpace(studioUser._id)).data;
        changeActiveSpace && dispatch(changeActiveSpace(space))
        socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
        dispatch(fetchUserStudio(studioUser._id))
        dispatch(activateSpaceAction(true))
    }



    // useEffect(() => {
    //     if (selectedCollection) {
    //         setDidUpdate(!didUpated)
    //     }
    // }, [selectedCollection, selectedItem])

    useEffect(() => {
        if (comp && routeArray) {
            if (!showReEnterSpace()) {
                dispatch(setStudioUser({} as any))
            }
            dispatch(setLeftPanel({
                component: comp,
                route_array: routeArray,
                collection: selectedCollection,
                item: selectedItem,
                user: selectedUser,
                galleryWalk: selectedGalleryWalk,
                galleryWalkSpace: selectedGalleryWalkSpace,
                auction: selectedAuction,
                liveAuction: selectedLiveAuction
            }))
        }
    }, [comp])

    useEffect(() => {
        if (leftPanelState.component !== comp) {
            setComp(leftPanelState.component)
            setRouteArray(leftPanelState.route_array)
            if (leftPanelState.collection) setSelectedCollection(leftPanelState.collection)
            if (leftPanelState.item) setSelectedItem(leftPanelState.item)
            if (leftPanelState.user) setSelectedUser(leftPanelState.user)
            if (leftPanelState.galleryWalk) setSelectedGalleryWalk(leftPanelState.galleryWalk)
            if (leftPanelState.galleryWalkSpace) setSelectedGalleryWalkSpace(leftPanelState.galleryWalkSpace)
            if (leftPanelState.auction) setSelectedAuction(leftPanelState.auction)
            if (leftPanelState.liveAuction) setSelectedLiveAuction(leftPanelState.liveAuction)
        }
    }, [leftPanelState])

    useEffect(() => {
        const count = NotificationCountAction.listen.subscribe((status: number) => setCount(status))
        const userCount = UserAlertCountAction.listen.subscribe((status: number) => setUserAlertCount(status))
        notificationSubscription.add(count)
        userAlertSubscription.add(userCount)

        return () => {
            notificationSubscription.unsubscribe()
            userAlertSubscription.unsubscribe()
        }
    }, [])

    const renderComponent = () => {
        if (!token && (comp === 'services-view' || comp === 'gallery-view' || comp === "gallery-walk-view" || comp === "gallery-walk-feature-art-view" || comp === 'collection-view' || comp === 'item-view' || comp === 'studio-view'
            || comp === 'live-art-auction' || comp === "live-art-auction-show-arts" || comp === 'join-live-art-auction')) {
            setComp('art_collection')
        }

        switch (comp) {
            case 'art_collection':
                return <Art_Collections onSelectCollection={onSelectCollection} />
            case 'gallery-view':
                return <BuyerGalleryWalk onClickOnGalleryWalk={onClickOnGalleryWalk} />
            case 'gallery-walk-view':
                return <BuyerGalleryWalkView galleryWalkId={selectedGalleryWalk?._id} onClickOnFeed={onClickOnFeed} onClickOnGalleryWalkSpace={onClickOnGalleryWalkSpace} onGoBack={onGoBack} />
            case 'gallery-walk-feature-art-view':
                return <BuyerGalleryFeatureArtView galleryWalkSpaceId={selectedGalleryWalkSpace?._id} onClickOnItem={onClickOnItem} onGoBack={onGoBack} />
            case 'feeds-view':
                return <BuyersNewsFeeds onClickOnFeed={onClickOnFeed} {...props} />
            case 'services-view':
                return <BuyerAccessProfessionServices />
            case 'collection-view':
                return <BuyerCollectionPage onGoBack={onGoBack} id={selectedCollection?.id} onClickOnItem={onClickOnItem} />
            case 'item-view':
                return <BuyerArtDetailPage onGoBack={onGoBack} id={selectedItem?.id} {...props} />
            case 'studio-view':
                return <BuyerGalleryPage onClickOnItem={onClickOnItem} onSelectCollection={onSelectCollection} userId={(selectedUser as IUser)?._id} onGoBack={onGoBack} />
            case 'live-art-auction':
                return <BuyerArtAuctions onClickShowArts={onClickShowAuctionsArts} onClickJoinLiveAuctionsArts={onClickJoinLiveAuctionsArts} />
            case 'live-art-auction-show-arts':
                return <BuyerAuctionArtsShowComponent id={selectedAuction?.id} onClickJoinLiveAuctionsArts={onClickJoinLiveAuctionsArts} onGoBack={onGoBack} />
            case 'join-live-art-auction':
                return <BuyerLiveAuction id={selectedLiveAuction?.id} onGoBack={onGoBack} />
            // case 3:
            //     return <AssignedCollectionItemView onEditItem={onEditItem} onBack={onGoBack} {...props} user={props.user} id={selectedItem?.id || undefined} />;
            default:
                return <Art_Collections onSelectCollection={onSelectCollection} />;
        }
    }

    const onAddArtToOmni = (route: string) => {
        if (!leftPanelState.activeRigthPanel) {
            dispatch(setLeftPanel({
                activeRigthPanel: true,
            }))
        }
        history.push(route)
    }

    const getUserRoleButtons = () => {

        if (token && user && user._id) {
            if (user?.roles?.length === 0 || user?.roles[0]?.length === 0) {
                if (user.submissionApplication && user.submissionApplication._id) {
                    return null
                }
                if (comp === 'services-view') {
                    return <button className={`at-btn at-btnsubmission`} onClick={() => onClickApplyOnline(routes.submissionProcess.serviceForm)}>
                        APPLY ONLINE AS A SERVICE PROVIDER
                    </button>
                }
                return <button className={`at-btn at-btnsubmission`} onClick={() => onClickApplyOnline(routes.submissionProcess.form)}>
                    APPLY ONLINE AS AN ARTIST OR GALLERY
                </button>
            }
            if (user?.roles?.length !== 0 || user?.roles[0]?.length !== 0) {
                if (!user.subscriptionID && !user?.roles?.includes(ERole.PRIVATE_ART_BUYER) && !user?.roles?.includes(ERole.PROFESSIONAL_ART_BUYER) && (!user?.roles?.includes(ERole.PROMOTER) && !user?.roles?.includes(ERole.TESTER) && !user?.roles?.includes(ERole.AUCTIONEER))) {
                    return <button title="Art Collection" className="at-btn at-btnsubmission" onClick={() => onAddArtToOmni(routes.account.subscription)}>{`PLEASE  COMPLETE  YOUR  ${user.roles[0].includes(ERole.CONSULTANT) ? "PROVIDER" : `${user.roles[0]}`}  ACCOUNT  SETUP`}</button>
                }
                else {
                    if (comp === "art_collection") {
                        if (user.subscriptionID && user?.roles?.includes(ERole.ARTIST)) {
                            return <button title="Art Collection" className="at-btn at-btnsubmission" onClick={() => onAddArtToOmni(routes.artCentral.collection.create)}>ADD ART TO OMNI</button>
                        }

                        if (user.subscriptionID && user?.roles?.includes(ERole.AGENT)) {
                            return <button title="Art Collection" className="at-btn at-btnsubmission" onClick={() => onAddArtToOmni(routes.artCentral.agent.assignments)}>ADD ART TO OMNI</button>
                        }

                        return null
                    }
                    if (comp === "feeds-view" && !user?.roles?.includes(ERole.PRIVATE_ART_BUYER) && !user?.roles?.includes(ERole.PROFESSIONAL_ART_BUYER)) {

                        return <button title="Art Collection" className="at-btn at-btnsubmission" onClick={() => onAddArtToOmni(routes.artCentral.post.create)}>ADD A POST TO NEWS FEED</button>
                    }
                    if (comp === "services-view" && user?.roles?.includes(ERole.CONSULTANT)) {

                        return <button title="Art Collection" className="at-btn at-btnsubmission" onClick={() => onAddArtToOmni(routes.account.services.professional.index)}>ADD PROFESSIONAL SERVICES</button>
                    }

                    if (comp === "gallery-view" && user?.roles?.includes(ERole.PROMOTER)) {

                        return <button title="Create Gallery Walk" className="at-btn at-btnsubmission" onClick={() => onAddArtToOmni(routes.artCentral.gelleryWalk.create)}>CREATE GALLERY WALK</button>
                    }
                    return null
                }
            }
        }
        return null
    }

    return <div>
        <div className="at-top-mainnav">
            <div className="at-top-mainnav-top">
                <div className="at-largescreen-logo">
                    <figure>
                        <img src={images.logoOmniNew} alt="company logo " />
                    </figure>
                </div>

                <ul className="at-home-topheading">
                    <li >
                        <button className={`at-btnicon ${comp === 'art_collection' ? 'at-btn-colororange' : ''}`} onClick={() => onArtCollectionView()}>
                            <h4 className="">Art Collections</h4>
                        </button>
                    </li>
                    <li>
                        {token && <button className={`at-btnicon ${comp === 'gallery-view' ? 'at-btn-colororange' : ''}`} onClick={() => onClickOnGallery()}>
                            <h4>Gallery Walks & Events</h4>
                        </button>}
                    </li>
                    <li>
                        <button className={`at-btnicon ${comp === 'live-art-auction' ? 'at-btn-colororange' : ''}`} onClick={() => onClickOnArtAuction()}>
                            {/* <p>Coming Soon!</p> */}
                            <h4>Live Art Auctions</h4>
                        </button>
                    </li>
                    <li >
                        <button className={`at-btnicon ${comp === 'feeds-view' ? 'at-btn-colororange' : ''}`} onClick={() => onClickOnFeeds()}>
                            <h4>News Feed</h4>
                        </button>
                    </li>
                    {token && <li >
                        <button className={`at-btnicon ${comp === 'services-view' ? 'at-btn-colororange' : ''}`} disabled={!token} onClick={() => onClickOnProfessional()}>
                            <h4>Professional Services</h4>
                        </button>
                    </li>}

                    {/* {token && (user?.roles?.length === 0) && <button className={`at-btn at-btnsubmission`} onClick={() => history.push(routes.submissionProcess.form)}>
                    Apply online as an Artist or Gallery
                </button>} */}
                </ul>
                <ul className="at-home-topheadingtwo">
                    {token && !leftPanelState.activeRigthPanel && <>
                        {showReEnterSpace() && <li>
                            <a className="at-linkheading" onClick={() => onReEnterSpace()}>
                                <i className="icon-enter"></i>
                                <span>Walk In</span>
                            </a>
                        </li>}
                        <li>
                            <a className="at-linkheading" onClick={() => onClick(routes.notifications)}>
                                <i className="icon-notification"></i>
                                {count > 0 && <label className="at-notifybadge  at-orangebadge">{count}</label>}
                                <span>Notifications</span>
                            </a>
                        </li>

                        <li>
                            <a className="at-linkheading " onClick={() => onClick(routes.userAlert)}>
                                <i className="icon-comment"></i>
                                {userAlertCount > 0 && <label className="at-notifybadge  at-orangebadge">{userAlertCount}</label>}
                                <span>Messages</span>
                            </a>
                        </li>
                        <li>
                            <a className="at-linkheading" onClick={() => onClick(routes.account.index)}>
                                <i className="icon-user-2"></i>
                                <span>Account</span>
                            </a>
                        </li>
                    </>}
                    {!token && <li>
                        <a className="at-linkheading" onClick={() => onClickOnTutorial(false)}>
                            <div>
                                <i className="icon-video-tutorial"></i>
                                <span>
                                    Tutorials
                                </span>
                            </div>
                        </a>
                    </li>}

                    {token && <li>
                        <a className="at-linkheading" onClick={() => onClickOnTutorial(true)}>
                            <div>
                                <i className="icon-video-tutorial"></i>
                                <span>
                                    Tutorials
                                </span>
                            </div>
                        </a>
                    </li>}
                    {!token && !leftPanelState.activeRigthPanel && <li>
                        <a className="at-linkheading" onClick={() => onClickLoginIcon()} >
                            <i className="icon-login02"></i>
                            <span>Login</span>
                        </a>
                    </li>}
                    {
                        token && !leftPanelState.activeRigthPanel && <li>
                            <a className="at-linkheading" onClick={logout}>
                                <i className="icon-logout at-color-orange"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    }

                    <li>
                        <button className="at-icon-slide at-btnicon at-linkheading" disabled={!token} onClick={() => onClickHide()}>
                            <i className={` ${leftPanelState.activeRigthPanel ? 'icon-slidein at-color-orange' : 'icon-slideout'}`}></i>
                            <span>{` ${leftPanelState.activeRigthPanel ? 'Close Tools' : 'Open Tools'}`}</span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className="at-top-mainnav-bottom">
                <div className="at-leftnavbtns-bottom">
                    <button className={`at-btn at-btnyellow`} onClick={() => enterAdminArtCentralSpace()} >
                        WALK IN CEO OFFICE
                    </button>
                    <div className="at-messageceo-btn">
                        <i className="icon-send" onClick={() => openMessageBox()}></i>
                        <p>Message CEO</p>
                    </div>
                </div>

                <div className="at-rightnavbtns-bottom">
                    {getUserRoleButtons()}
                </div>
            </div>
        </div>

        {renderComponent()}
        {/* <BuyerGalleryWalk/> */}
        {showMessageBox && <MessageModal open={showMessageBox} onClose={closeMessageBox} onSubmit={sendAdminAlert} message='' title='Send Message' />}
    </div>
}

export default withRouter(BuyerArtCentral)