import { EHubsAndSpaces } from '../constants'
import { images } from '../assets/image'
import { ISpace } from '../interfaces'
import _ from 'lodash'
import { NODE_ENV } from '../environment'
import { ETransactionStatus, IEnvironment } from '../enums'

export const isPlural = (length: number) => {
	return length === 1 ? false : true
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
	const arr = dataurl.split(',')
	const mime = arr[0].match(/:(.*?);/)?.[1]
	const bstr = atob(arr[1])
	let n = bstr.length
	const u8arr = new Uint8Array(n)

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}

	return new File([u8arr], filename, { type: mime })
}

export const getUserStats = _.memoize((spaces: ISpace[] = []) => {
	if (!spaces.length) return { activeUsers: 0, totalUsers: 0 }

	return spaces
		?.map((space) => {
			return {
				activeUsers: space.activeUsers?.length || 0,
				totalUsers: space.contacts?.length || 0,
			}
		})
		.reduce((acc, curr) => {
			return {
				activeUsers: acc.activeUsers + curr.activeUsers,
				totalUsers: acc.totalUsers + acc.totalUsers,
			}
		})
})

export const getInitials = (firstName: string = '', lastName: string = '') => {
	const canvas = document.createElement('canvas')
	canvas.style.display = 'none'
	canvas.width = 200
	canvas.height = 200
	document.body.appendChild(canvas)
	const context = canvas.getContext('2d')
	if (context) {
		context.fillStyle = '#92A8B5'
		context.fillRect(0, 0, canvas.width, canvas.height)
		context.font = '75px Helvetica'
		context.fillStyle = '#FBFAF9'
	}
	let first
	let last
	first = firstName[0]
	last = lastName[0]
	if (last) {
		const initials = first + last
		context?.fillText(
			initials.toUpperCase(),
			canvas.width / 4,
			canvas.height / 1.6
		)
	} else {
		const initials = first
		context?.fillText(
			initials.toUpperCase(),
			canvas.width / 4,
			canvas.height / 1.6
		)
	}
	const data = canvas.toDataURL()
	document.body.removeChild(canvas)
	return data
}

export const getInitialsAsBlob = async (
	firstName: string = '',
	lastName: string = ''
) => {
	const canvas = document.createElement('canvas')
	canvas.style.display = 'none'
	canvas.width = 200
	canvas.height = 200
	document.body.appendChild(canvas)
	const context = canvas.getContext('2d')
	if (context) {
		context.fillStyle = '#92A8B5'
		context.fillRect(0, 0, canvas.width, canvas.height)
		context.font = '75px Helvetica'
		context.fillStyle = '#FBFAF9'
	}
	let first
	let last
	first = firstName[0]
	last = lastName[0]
	if (last) {
		const initials = first + last
		context?.fillText(
			initials.toUpperCase(),
			canvas.width / 4,
			canvas.height / 1.6
		)
	} else {
		const initials = first
		context?.fillText(
			initials.toUpperCase(),
			canvas.width / 4,
			canvas.height / 1.6
		)
	}
	const data = await new Promise((resolve, reject) => {
		canvas.toBlob((blob) => resolve(blob))
	})

	document.body.removeChild(canvas)
	return (data as unknown) as Promise<Blob>
}

export const getHubColorPath = (color = EHubsAndSpaces.spaceBlue) =>
	images.hubsAndSpaces[color]

export const _logger = (title: string, log: any) => {
	if ((NODE_ENV as any) !== IEnvironment.DEVELOPMENT) return;
	// console.log(`==================== Start: ${title} ====================`)
	// console.log(log)
	// console.log(`==================== Ended: ${title} ====================`)
}

export const bytesToMegaBytes = (bytes: number) => bytes / 1000000

export const isEmptyBuffer = (nsgArrayBuffer: any) => {
	let nsgArray = new Uint8Array(nsgArrayBuffer)
	return nsgArray.every((element) => {
		return element === 0
	})
}

export const downsampleBuffer = (
	buffer: any,
	sampleRate: any,
	outSampleRate: any
) => {
	if (outSampleRate == sampleRate) {
		return buffer
	}
	if (outSampleRate > sampleRate) {
		throw 'downsampling rate show be smaller than original sample rate'
	}
	let sampleRateRatio = sampleRate / outSampleRate
	let newLength = Math.round(buffer.length / sampleRateRatio)
	let result = new Int16Array(newLength)
	let offsetResult = 0
	let offsetBuffer = 0
	while (offsetResult < result.length) {
		let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio)
		let accum = 0,
			count = 0
		for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
			accum += buffer[i]
			count++
		}

		result[offsetResult] = Math.min(1, accum / count) * 0x7fff
		offsetResult++
		offsetBuffer = nextOffsetBuffer
	}
	return result.buffer
}

export const isInViewport = (elem: any): boolean => {
	try {
		const distance = elem?.getBoundingClientRect()
		const atMain = document.getElementById('feed-scroll')?.getBoundingClientRect()

		if (!elem || !distance || !atMain) return false
		return (
			distance.top >= atMain.top &&
			distance.left >= atMain.left &&
			distance.bottom <= atMain.bottom &&
			distance.right <= atMain.right
		)
	} catch (error) {
		return true
	}
}

// Create our number formatter.
export const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const transactionStatus = (status: string) => {
	switch (status) {
		case ETransactionStatus.PENDING:
			return { status: ETransactionStatus.PENDING.toLocaleLowerCase(), color: 'grey' }
		case ETransactionStatus.SHIPPED:
			return { status: ETransactionStatus.SHIPPED.toLocaleLowerCase(), color: 'orange' }
		case ETransactionStatus.RETURN_PENDING:
			return { status: ETransactionStatus.RETURN_PENDING.replace('_', ' ').toLocaleLowerCase(), color: 'grey' }
		case ETransactionStatus.COMPLETED:
			return { status: ETransactionStatus.COMPLETED.toLocaleLowerCase(), color: 'orange' }
		case ETransactionStatus.DISPUTED:
			return { status: ETransactionStatus.DISPUTED.toLocaleLowerCase(), color: 'grey' }
		case ETransactionStatus.RETURN_ALLOWED:
			return { status: ETransactionStatus.RETURN_ALLOWED.replace('_', ' ').toLocaleLowerCase(), color: 'orange' }
		case ETransactionStatus.RETURN_NOT_ALLOWED:
			return { status: ETransactionStatus.RETURN_NOT_ALLOWED.replace(/_/g, ' ').toLocaleLowerCase(), color: 'orange' }
		case ETransactionStatus.RETURN_SHIPPED:
			return { status: ETransactionStatus.RETURN_SHIPPED.replace('_', ' ').toLocaleLowerCase(), color: 'grey' }
		case ETransactionStatus.RETURN_COMPLETED:
			return { status: ETransactionStatus.RETURN_COMPLETED.replace(/_/g, ' ').toLocaleLowerCase(), color: 'orange' }
		case ETransactionStatus.PAID:
			return { status: ETransactionStatus.PAID.toLocaleLowerCase(), color: 'orange' }
		case ETransactionStatus.RESOLVED:
			return { status: ETransactionStatus.RESOLVED.toLocaleLowerCase(), color: 'orange' }
		default:
			return { status: ETransactionStatus.PENDING.toLocaleLowerCase(), color: 'grey' }
	}
}

