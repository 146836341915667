import { AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { images } from '../../assets/image'
import LoadingComponent from '../../components/common/Loading.component'
import { ICollectionItem, IEventDocumentResponse } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { eventService } from '../../services'
import { collectionImage, eventImages } from '../../shared'
import { templateSelector } from '../../store/selectors'
import { CustomImage } from '../components/CustomImage'
import { CustomText } from '../components/CustomText'
import ExhibitionsPage from './ExhibitionsPage'
import { routes } from './routes'

const EventPage = () => {
    const history = useHistory()
    const params = (useParams() as any).id
    const template = useSelector(templateSelector)
    const [event, setEvent] = useState<IEventDocumentResponse>()
    const [loading, setLoading] = useState<boolean>(false)
    const [showArts, setShowArts] = useState<boolean>(false)

    const fetch = useCallback(() => {
        setLoading(true)
        eventService.getSingleEvent(params).then((res: AxiosResponse<IResponse<any>>) => {
            setEvent({ ...res.data.data })
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    const renderArts = () => {
        let arts = event?.items
        return (
            arts && arts.length > 0 && (arts as Array<ICollectionItem>).map((a: ICollectionItem) => (
                // <figure className="At-FigureFull At-Figure-h470" key={a.id} onClick={() => { history.push(`${routes(template.url).artists.collectionItemView}/${a.id}`) }}>
                //     <img src={collectionImage(a, `${a.url}`, true)} className="img-fluid" alt="" />
                // </figure>
                <div className="At-BorderFigureHolder">
                    <div className="At-BorderFigure">
                        <CustomImage src={collectionImage(a, `${a.url}`)} thumbnail={collectionImage(a, `blur_${a.url}`)} />

                    </div>
                    <div className="At-ArtDetailBox">
                        {a.name && <h5>
                            {a.name}
                        </h5>}
                        {a.artTechnique && a.height && a.width && <p> {a.height}" X {a.width}" {a.depth ? `X ${a.depth}"` : null}  {a.artTechnique}</p>}
                        <i className="icon-dotted-circle" onClick={() => { history.push(`${routes(template.url).artists.collectionItemView}/${a.id}`) }}></i>
                    </div>
                </div>
            ))
        )
    }
    const renderImages = () => {
        let images = event?.images
        return (
            images && images.length > 0 && images.map((i: any, index: number) => (
                // <figure className="At-FigureFull At-Figure-h470" key={index}>
                //     <img src={eventImages({}, `${a.url}`, true)} className="img-fluid" alt="" />
                // </figure>
                <div className="At-BorderFigureHolder">
                    <div className="At-BorderFigure">
                        <CustomImage src={collectionImage(i, `${i.url}`)} thumbnail={collectionImage(i, `blur_${i.url}`)} />

                    </div>
                </div>

            ))
        )
    }
    useEffect(() => {
        fetch()
    }, [])
    return <div >
        <section className="At-ArtistsPage At-Event At-Section-Mh800">
            <div className="container">
                <div className="row mx-lg-n6">
                    {event && <div className="col-sm-6 px-lg-6 At-ArtistLeftCol">
                        <h5>{moment(event?.startDate).format('MMMM DD')} - {moment(event?.endDate).format('LL')}</h5>
                        <h3>{event?.name}</h3>
                        <CustomText>
                            <p dangerouslySetInnerHTML={{ __html: event.description }}></p>
                        </CustomText>
                    </div>}
                    <div className="col-sm-6 px-lg-6 At-ArtistRightCol ml-auto">
                        <ul className="At-FeatureEventUl d-flex justify-content-between">
                            <li>
                                <a className={`At-HeadingLink ${!showArts ? 'At-HeadingLinkActive' : ''}`} onClick={() => setShowArts(false)} >Event Pictures</a>
                            </li>
                            <li>
                                <a className={`At-HeadingLink ${showArts ? 'At-HeadingLinkActive' : ''}`} onClick={() => setShowArts(true)} >Featured Art</a>
                            </li>
                        </ul>

                        {showArts ? renderArts() : renderImages()}
                        {/* <figure onClick={() => { history.push(routes.artists.collectionItemView) }}>
                            <img src={images.template.gallery02} className="img-fluid" alt="" />
                        </figure>
                        <figure onClick={() => { history.push(routes.artists.collectionItemView) }}>
                            <img src={images.template.gallery03} className="img-fluid" alt="" />
                        </figure>
                        <figure onClick={() => { history.push(routes.artists.collectionItemView) }}>
                            <img src={images.template.gallery04} className="img-fluid" alt="" />
                        </figure>
                        <figure onClick={() => { history.push(routes.artists.collectionItemView) }}>
                            <img src={images.template.gallery02} className="img-fluid" alt="" />
                        </figure>
                        <figure onClick={() => { history.push(routes.artists.collectionItemView) }}>
                            <img src={images.template.gallery04} className="img-fluid" alt="" />
                        </figure>
                        <figure onClick={() => { history.push(routes.artists.collectionItemView) }}>
                            <img src={images.template.gallery03} className="img-fluid" alt="" />
                        </figure> */}
                    </div>
                </div>
                {loading && <LoadingComponent className='at-transaction-loader' />}
            </div>
        </section>
    </div>
}

export default EventPage