import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { images } from '../../assets/image';
import { ERole, ETemplate } from '../../enums';
import { APP_URL } from '../../environment';
import { ITemplateState, IUser } from '../../interfaces';
import { routes } from '../../router';
import { toastService } from '../../services';
import { EZoom, ZoomActions } from '../../services/zoom.service';
import { userSelector } from '../../store/selectors';
interface IProps {
    goBack: Function,
    setData: Function,
    data: string,
    templateState: ITemplateState,
    user: IUser
}
export const SelectTemplateComponent = ({ goBack, setData, data, user, templateState }: IProps) => {
    const history = useHistory()
    const [selectedTemplate, setTemplate] = useState<string>('')
    const item_1 = [
        {
            original: images.template.artShowHome,
            thumbnail: images.template.artShowHome,
            isVideo: false
        },
        {
            original: images.template.artShowCollectionShow,
            thumbnail: images.template.artShowCollectionShow,
            isVideo: false
        },
        {
            original: images.template.artShowCollection,
            thumbnail: images.template.artShowCollection,
            isVideo: false
        },
        {
            original: images.template.artShowItem,
            thumbnail: images.template.artShowItem,
            isVideo: false
        },
    ]
    const item_2 = [
        {
            original: images.template.galleryShowHome,
            thumbnail: images.template.galleryShowHome,
            isVideo: false
        },
        {
            original: images.template.galleryShowCollection,
            thumbnail: images.template.galleryShowCollection,
            isVideo: false
        },
        {
            original: images.template.galleryShowItem,
            thumbnail: images.template.galleryShowItem,
            isVideo: false
        },

    ]

    const item_3 = [
        {
            original: images.template.printingFramingItem1,
            thumbnail: images.template.printingFramingItem1,
            isVideo: false
        },
        {
            original: images.template.printingFramingItem2,
            thumbnail: images.template.printingFramingItem2,
            isVideo: false
        },
        {
            original: images.template.printingFramingItem3,
            thumbnail: images.template.printingFramingItem3,
            isVideo: false
        },

    ]

    useEffect(() => {
        if (data) {
            setTemplate(data)
        }
    }, [])
    const onClickTemplate = (t: string) => {

        setTemplate(t)
        setData({ template: t })
    }

    const onClickView = (route: string, t: string) => {
        if (t === ETemplate.ART_SHOW_FORMAT && !user.template) {
            toastService.info('Please add information required for template')
            return
        }
        window.open(`${APP_URL}/${route}`, '_blank')
    }

    const TemplateJSX = (title: string, img: any, value: string, route: string) => {
        return (
            <div className="at-assign-roundbox">
                <div className="d-flex ">
                    <h5>{title}</h5>
                    {/* <i className='icon-switch at-assign-icon ml-auto' onClick={() => { ZoomActions.triggerAction({ type: EZoom.TEMPLATES, value: images }) }}></i> */}
                </div>
                <ImageGallery
                    items={img}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showNav={false}
                />
                <div className="row">
                    {/* {!user.template ? null : <div className="col-6">
                        <button className="at-btn at-smallbtn-h40-w100 mt-3" onClick={() => onClickView(templateState.url, value)}>View</button>
                    </div>} */}
                    <div className="col-6 ml-auto">
                        <button className="at-btn at-smallbtn-h40-w100 mt-3" disabled={selectedTemplate === value} onClick={() => onClickTemplate(value)}>Select</button>
                    </div>
                </div>
            </div>
        )
    }

    const renderTemplates = () => {
        switch (user.roles[0]) {
            case ERole.ARTIST:

                return TemplateJSX('GALLERY FORMAT', item_1, ETemplate.ART_SHOW_FORMAT, routes.template.artShow)
            case ERole.AGENT:

                return TemplateJSX('GALLERY FORMAT', item_2, ETemplate.GALLERY_FORMAT, routes.template.galeeryShow)

            default:
                return null;
        }
    }
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2><span className="at-color-orange">STEP 2:</span> Select Template</h2>
                    {/* <h2>STEP 1: Select URL</h2> */}
                </div>
                <div className="at_theme_innercontent">
                    {renderTemplates()}

                    {!user.roles.includes(ERole.CONSULTANT) && <>
                        {TemplateJSX('CATALOGUE FORMAT', item_3, ETemplate.CATALOGUE_FORMAT, routes.template.galeeryShow)}
                        {TemplateJSX('STUDIO FORMAT', item_3, ETemplate.STUDIO_FORMAT, routes.template.galeeryShow)}
                    </>}
                    {user.roles.includes(ERole.CONSULTANT) && <>
                        {TemplateJSX('SERVICE FORMAT', item_3, ETemplate.SERVICE_FORMAT, routes.template.galeeryShow)}
                    </>}
                    {/* <div className="at-assign-roundbox">
                        <div className="d-flex ">
                            <h5>CATALOGUE FORMAT</h5>
                            <i className='icon-switch at-assign-icon ml-auto' onClick={() => { ZoomActions.triggerAction({ type: EZoom.TEMPLATES, value: images }) }}></i>
                        </div>
                        <ImageGallery
                            items={item_3}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            showNav={false}
                        />
                        <div className="row">
                            <div className="col-6">

                                <button className="at-btn at-smallbtn-h40-w100 mt-3"><Link className="at-color-white" to={routes.template.consultingServicesShow} target="_blank">View</Link></button>
                            </div>
                            <div className="col-6">

                                <button className="at-btn at-smallbtn-h40-w100 mt-3" disabled={selectedTemplate === ETemplate.PRINTING_FRAMING_SERVICES_FORMAT} onClick={() => onClickTemplate(ETemplate.GALLERY_FORMAT)}>Select</button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </main>
    )
}