import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import LoadingComponent from '../../../common/Loading.component'
import { ICollection, ICollectionItem, ISpace, ITemplateReducerState } from '../../../../interfaces'
import { collectionService } from '../../../../services/collection.service'
import { collectionImage, getUserTemplateRoute } from '../../../../shared'
import { activateSpaceAction, changeActiveSpace, fetchUserStudio, setInPlatformWebState } from '../../../../store/actions'
import { templateSelector, userSelector } from '../../../../store/selectors'
import { CustomImage } from '../../../../templates/components/CustomImage'
import { ERole } from '../../../../enums'
import { routes } from '../../../../router'
import { IAuctionAttendessDocument, IAuctionDocument } from '../../../../interfaces/auction.interface'
import { auctionService, spaceService, toastService, userService } from '../../../../services'
import { IResponse } from '../../../../interfaces/db.interface'
import { ArtBoxComponent } from '../../../ArtCentral/collection/ArtBox.component'
import { UniversalModal } from '../../../common/Modals/Universal.modal'
import moment from 'moment'
import { AuctionRegistration } from './AuctionRegistration.component'
import { ADD_NEW_MEMBER, USER_ALERT_SPACE_ENTER } from '../../../../constants'
import { socketNode } from '../../../../services/socket.service'

interface IProps {
    id?: string
    onGoBack: Function
    onClickJoinLiveAuctionsArts: Function
}
const BuyerAuctionArtsShowComponent = ({ id, onGoBack, onClickJoinLiveAuctionsArts }: IProps) => {

    const dispatch = useDispatch()
    const [auction, setAcution] = useState<IAuctionDocument>()
    const [loading, setLoading] = useState<boolean>(false)
    const user = useSelector(userSelector)
    const [showRegistration, setShowRegistration] = useState<boolean>(false)

    const fetch = useCallback(() => {
        if (!id) return
        setLoading(true)
        auctionService.getAuctionById(id).then((response: AxiosResponse<IResponse<IAuctionDocument>>) => {
            setAcution(response.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    const OpenAuctionRegistration = () => {
        setShowRegistration(true)
    }

    const CloseAuctionRegistration = () => {
        setShowRegistration(false)
    }

    const checkIfUserRegistered = () => {
        if (!auction) return
        let attend = auction.attendees

        let fil = attend.filter((u: IAuctionAttendessDocument) => u.user._id === user._id && (u.auction as any) === auction._id)

        if (fil && fil.length > 0) {
            return true
        }

        if (user._id === auction.user._id) {
            return true
        }
        return false
    }

    const EventDate = () => {
        if (!auction) return
        if (moment(auction.startDate).format() > moment().endOf('day').format()) {
            return true
        }

        if (moment(auction.endDate).format() < moment().startOf('day').format()) {
            return true
        }

        return false
    }

    const EventTime = () => {
        if (!auction) return
        let currentUserTime = moment(moment().format('hh:mm:ss A'), "HH:mm:ss a")
        let startTime = moment(moment(auction.startTime).format('hh:mm:ss A'), "HH:mm:ss a")
        let endTime = moment(moment(auction.endTime).format('hh:mm:ss A'), "HH:mm:ss a")
        if ((currentUserTime.isAfter(startTime) && currentUserTime.isBefore(endTime))) {
            return true
        }

        return false
    }

    const onJoinLiveAuction = async () => {
        if (!auction) return
        const space: ISpace = (await spaceService.userArtCentralSpace(auction.user._id)).data;
        changeActiveSpace && dispatch(changeActiveSpace(space))
        socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
        dispatch(fetchUserStudio(auction.user._id))
        sendUserAlert(auction, `${user?.firstName} ${user?.lastName} has just entered your space.`, USER_ALERT_SPACE_ENTER, false, space._id)
        dispatch(activateSpaceAction(true))
        if (onClickJoinLiveAuctionsArts) {
            onClickJoinLiveAuctionsArts(auction)
            return
        }
    }

    const sendUserAlert = (c: IAuctionDocument, message: string, type: string, showToast: boolean = false, spaceId: any = null) => {
        let cUser = c.user

        if (user?._id === cUser._id) {
            return
        }
        let data = {
            message: message,
            to: cUser._id,
            from: user?._id,
            type: type,
            spaceID: spaceId
        }
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
            if (showToast && res.data.status === 'Success') {
                toastService.success("Message sent successfully")
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }


    useEffect(() => {
        fetch()
    }, [fetch])


    return <div className="at-createcollection at-contentarea-two scrollable at-auctionpage">
        <section>
            <div className="container">
                <div className="row ">
                    <div className="col-12 at-panelcollection-heading">
                        <a onClick={() => onGoBack()} className="at-left-arrowicon"><i className="icon-arrow-bold"></i></a>
                        <div className="at-panelcollection-text">
                            {auction && <h2> {auction?.name} </h2>}
                            {/* {collection && <h3 className="at-color-grey">{collection.title}</h3>} */}
                        </div>
                        {!checkIfUserRegistered() && <button className="at-btn at-smallbtn-w140 ml-auto" disabled={EventDate()} onClick={() => OpenAuctionRegistration()}>REGISTER NOW</button>}
                        {checkIfUserRegistered() && <button className="at-btn at-smallbtn-w140 ml-auto" disabled={EventDate() || !EventTime()} onClick={() => onJoinLiveAuction()}>JOIN LIVE AUCTION</button>}
                    </div>

                    {auction && auction.arts.length > 0 && auction.arts.map((i: ICollectionItem) => {

                        return (<ArtBoxComponent art={i} onClickThreeDots={() => { }} showThreeDots={false} showAuction={true} />)
                    })}
                </div>
                {loading && <LoadingComponent className='at-transaction-loader' />}
            </div>
        </section>
        {
            showRegistration && auction && <UniversalModal open={showRegistration} onClose={CloseAuctionRegistration}>
                <AuctionRegistration a={auction} onClose={CloseAuctionRegistration} user={user} fetch={fetch} />
            </UniversalModal>
        }
    </div>
}

export default BuyerAuctionArtsShowComponent