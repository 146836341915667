import { AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { transactionStatus } from '../../../helper'
import { IFormates, IStoreReducers, ITransaction, IUser } from '../../../interfaces'
import { transactionService } from '../../../services'
import { collectionImage } from '../../../shared'
import { CurrencyFormat } from '../../common/CurrencyFormat'
import LoadingComponent from '../../common/Loading.component'
import { TransferLayout } from '../../common/TransferLayout'

interface IProps extends RouteComponentProps {
    user?: IUser
}

const Tranactions = ({ user }: IProps) => {

    const [transactions, setTransactions] = useState<Array<ITransaction>>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        transactionService.getUserTransactions(user?._id).then((res: AxiosResponse<any>) => {
            let data = [...res.data.response]
            setTransactions(data.reverse())
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const getItemType = (item: ITransaction) => {
        if (item.formatesId) {
            let format: Array<IFormates> = item?.itemID?.formates.filter((value: IFormates) => value._id === item?.formatesId)
            if (format && format.length <= 0) {
                return ` `
            }
            if (format && format[0]) {
                return format[0].size
            }
            return ` `
        }
        return `Original`
    }
    return (
        <div className="at-transaction-main">
            {transactions?.length > 0 ? (
                <>
                    {transactions?.map((item: ITransaction) => (

                        <div key={item._id} className="at-transaction-wrap">
                            <div className="at-transaction-box">
                                <div className="at-transact-left">
                                    <div className="at-transact-line">
                                        <p>{moment(item.createdAt).format("L")}</p>
                                        <p className="pl-5">{moment(item.createdAt).format("hh:mm:ss a")}</p>
                                    </div>
                                    {item.sellerID && <div className="at-transact-line">
                                        <p>Seller:</p>
                                        <p className="pl-2 text-capitalize"><span>{item.sellerID.firstName} {item.sellerID.lastName}</span></p>
                                    </div>}
                                    {item.sellerID && <div className="at-transact-line">
                                        <p>Artist:</p>
                                        <p className="pl-2 text-capitalize"><span>{item.sellerID.firstName} {item.sellerID.lastName}</span></p>
                                    </div>}
                                    {item.buyerID && <div className="at-transact-line" >
                                        <p>Buyer:</p>
                                        <p className="pl-3 text-capitalize"><span>{`${item.buyerID.firstName} ${item.buyerID.lastName}`}</span></p>
                                        {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                                    </div>}
                                    <div className="at-transact-line pt-2">
                                        <p>Art:<span> {getItemType(item)}</span></p>
                                    </div>

                                </div>
                                <div className="at-transact-right">
                                    {item.itemID && item.itemID.name && <p className=""><span>{item.itemID.name}</span></p>}
                                    <figure>
                                        {item.itemID && item.itemID.url && <img src={collectionImage(item.itemID, `high_${item.itemID.url}`, true)} alt="" />}
                                    </figure>
                                    <button className={`at-btn at-btn-sm at-btn-${transactionStatus(item.transactionState).color} text-capitalize`}>{transactionStatus(item.transactionState).status}</button>

                                </div>
                                <div className="at-transactbox-bottom">
                                    <div className="at-transact-line pt-2">
                                        <p>Price:<span><CurrencyFormat amount={item.price} /></span></p>
                                        <p className="pl-2">Shipping: <span><CurrencyFormat amount={item.shippingPrice} /></span></p>
                                        <p className="pl-2">Total: <span><CurrencyFormat amount={item.totalPrice} /></span></p>
                                    </div>
                                </div>
                                <TransferLayout transaction={item} />
                                {/* at-transfer-amount ends  */}
                            </div>
                        </div>
                    ))}
                </>
            ) : (<>{loading && <LoadingComponent className='at-transaction-loader' />}</>)}
        </div>

    )
}
const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})
export default connect(mapStateToProps, null)(Tranactions)