import { IOffer } from "../interfaces"

export const ACCEPT_OFFER = 'ACCEPT_OFFER'
export const REJECT_OFFER = 'REJECT_OFFER'
export const CONFORMATION_OF_SHIPMENT = 'CONFORMATION_OF_SHIPMENT'
export const RELEASE_OF_FUNDS = 'RELEASE_OF_FUNDS'
export const COMMISSION_WORK_PAYMENT = 'COMMISSION_WORK_PAYMENT'
export const SEND_PURCHASE_RECIEVE = 'SEND_PURCHASE_RECIEVE'
export const RELEASE_OF_FUNDS_APPROVAL = 'RELEASE_OF_FUNDS_APPROVAL'
export const RETURN_OF_ART_NOTICE = 'RETURN_OF_ART_NOTICE'
export const RECIEVED_OFFER = 'RECIEVED_OFFER'
export const REFUND_PAYMENT = 'REFUND_PAYMENT'
export const TRANSFER_PAYMENT = 'TRANSFER_PAYMENT'
export const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT'
export const RETURN_ALLOWED = 'RETURN_ALLOWED'
export const RETURN_NOT_ALLOWED = 'RETURN_NOT_ALLOWED'
export const RETURN_EXPIRE = 'RETURN_EXPIRE'
export const RETURN_SHIPPED = 'RETURN_SHIPPED'
export const COMMISSION_WORK_REQUEST = 'COMMISSION_WORK_REQUEST'
export const COMMISSION_WORK_PROPOSAL = 'COMMISSION_WORK_PROPOSAL'
export const COMMISSION_WORK_PROPOSAL_rejected = 'COMMISSION_WORK_PROPOSAL_rejected'
export const COMMISSION_WORK_PROPOSAL_APPROVED = 'COMMISSION_WORK_PROPOSAL_APPROVED' 
export const COMMISSION_WORK_REJECTED = 'COMMISSION_WORK_REJECTED'
export const SUBSCRIPTION_REFUND = 'SUBSCRIPTION_REFUND'
export const END_ACCOUNT_ASSIGNMENT = 'END_ACCOUNT_ASSIGNMENT'
export const ON_BUY_SERVICE = 'ON_BUY_SERVICE'
export const TRANSFER_PAYMENT_PRINTING_SERVICE_PROVIDER = 'TRANSFER_PAYMENT_PRINTING_SERVICE_PROVIDER'
export const CURATORIAL_SEND_INVITATIONS = 'CURATORIAL_SEND_INVITATIONS';
export const CURATORIAL_RESPONSE_INVITATIONS = 'CURATORIAL_RESPONSE_INVITATIONS';
export const CURATORIAL_SEND_PROPOSAL = 'CURATORIAL_SEND_PROPOSAL';
export const CURATORIAL_SEND_OFFER = 'CURATORIAL_SEND_OFFER';
export const CURATORIAL_ACCEPT_OFFER = 'CURATORIAL_ACCEPT_OFFER';
export const CURATORIAL_REJECT_OFFER = 'CURATORIAL_REJECT_OFFER';
export const CURATORIAL_REQUEST_OFFER_PAYMENT = 'CURATORIAL_REQUEST_OFFER_PAYMENT';
export const CURATORIAL_OFFER_PAYMENT_PAID = 'CURATORIAL_OFFER_PAYMENT_PAID';