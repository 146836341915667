import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { EGalleryWalkFiltered } from '../../../../enums'
import { IAuctionDocument } from '../../../../interfaces/auction.interface'
import { IResponse } from '../../../../interfaces/db.interface'
import { routes } from '../../../../router'
import { auctionService, galleryWalkService, spaceService } from '../../../../services'
import { userSelector } from '../../../../store/selectors'
import { ArtAuctionsCardViewComponent } from './ArtAuctionCardView.component'

interface IProps {

}
const ArtAuctionsPage = () => {
    const history = useHistory()
    const user = useSelector(userSelector)

    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [query, setQuery] = useState<string>('')
    const [filtered, setFiltered] = useState<boolean>(false)
    const [auctions, setAuctions] = useState<Array<IAuctionDocument>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [spaceType, setSpaceType] = useState<string>(EGalleryWalkFiltered.HAPPENING_NOW)

    const fetchAuctions = (s: string, search: string = '', filter: boolean = false) => {
        setLoading(true)
        auctionService.getAuctionsList({ timeString: s, filtered: filter, query: search, time: moment().format() }).then((res: AxiosResponse<IResponse<Array<IAuctionDocument>>>) => {
            let { data } = res.data
            let startDay = new Date(moment().startOf('day').format())
            let endDay = new Date(moment().endOf('day').format())
            let newAuctions = []
            let currentUserTime = moment(moment().format('hh:mm:ss A'), "HH:mm:ss a")
            if (s === EGalleryWalkFiltered.HAPPENING_NOW) {
                
                newAuctions = data.filter((a: IAuctionDocument) => {
                    let startTime = moment(moment(a.startTime).format('hh:mm:ss A'), "HH:mm:ss a")
                    let endTime = moment(moment(a.endTime).format('hh:mm:ss A'), "HH:mm:ss a")
                    return new Date(a.startDate) < endDay && new Date(a.endDate) >= startDay 
                    // return new Date(a.startDate) < endDay && new Date(a.endDate) >= startDay && (currentUserTime.isAfter(startTime) && currentUserTime.isBefore(endTime))
                })
                setAuctions([...newAuctions])
            } else if (s === EGalleryWalkFiltered.PAST) {
                newAuctions = data.filter((a: IAuctionDocument) => {
                    let startTime = moment(moment(a.startTime).format('hh:mm:ss A'), "HH:mm:ss a")
                    let endTime = moment(moment(a.endTime).format('hh:mm:ss A'), "HH:mm:ss a")
                    return new Date(a.endDate) < startDay 
                    // return new Date(a.endDate) < startDay || currentUserTime.isBefore(endTime)
                })
                setAuctions([...newAuctions])
            } else if (s === EGalleryWalkFiltered.COMMING_SOON) {
                newAuctions = data.filter((a: IAuctionDocument) => {
                    let startTime = moment(moment(a.startTime).format('hh:mm:ss A'), "HH:mm:ss a")
                    let endTime = moment(moment(a.endTime).format('hh:mm:ss A'), "HH:mm:ss a")

                    return new Date(a.startDate) > endDay
                    // return new Date(a.startDate) > endDay || currentUserTime.isBefore(startTime)
                })
                setAuctions([...newAuctions])
            } else {
                setAuctions([...res.data.data])
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
        })
    }

    const onChangeSpace = (e: any) => {
        setSpaceType(e.target.value)
        fetchAuctions(e.target.value)
    }

    const openSearch = () => {
        setShowSearch(true)
    }

    const closeSearch = () => {
        setQuery('')
        setShowSearch(false)
        fetchAuctions(spaceType, '', filtered)
    }

    const onSearch = (e: any) => {
        setQuery(e.target.value)
        fetchAuctions(spaceType, e.target.value, filtered)
    }

    const onToggleFilter = (value: boolean) => {
        if (value) {
            setFiltered(true)
        } else {
            setFiltered(false)
        }
        fetchAuctions(spaceType, query, value)
    }

    useEffect(() => {
        fetchAuctions(spaceType)
    }, [])
    return (
        <div className="at-createcollection at-gallerywalkpage at-contentarea-two">
            <div className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Art Auctions</h2>
                    <div className="at-gallerywalk-formselect form-group at-artcollectionselect">
                        <span className="at-select at-floatlabel-h40">
                            <select className="form-control floating-select" value={spaceType} name="" onChange={onChangeSpace} >
                                {/* <option value=''>Select Spaces</option> */}
                                <option value={EGalleryWalkFiltered.HAPPENING_NOW}>Happening Now</option>
                                <option value={EGalleryWalkFiltered.COMMING_SOON}>Coming Soon</option>
                                <option value={EGalleryWalkFiltered.PAST}>Past Auctions</option>
                            </select>
                        </span>
                    </div>
                    {/* {showSearch && <a className="at-left-arrowicon" onClick={() => closeSearch()}><i className="icon-left-arrow"></i></a>} */}
                    {!showSearch && <a className="at-filtericon cursor-pointer" onClick={() => openSearch()}><i title="Search" className="icon-search"></i></a>}

                    <a className={`at-filtericon cursor-pointer mr-2 ${filtered && 'text-orange'}`} onClick={() => onToggleFilter(!filtered)}><i title="Filter Preferred" className="icon-filter"></i></a>
                    <h2>
                        <div className={`from-group At-MarginTopSearchMinus dn ${showSearch && 'display'}`}>
                            <div className="input-group">
                                {loading && <div className="input-group-prepend">
                                    <span className="input-group-text search-field" id="basic-addon1"><i className="fa fa-spinner"></i></span>
                                </div>}
                                <input type="search" value={query} className="form-control" placeholder="Search" onChange={(e: any) => onSearch(e)} />
                            </div>
                        </div>
                    </h2>
                </div>
                {
                    auctions && auctions.length > 0 && auctions.map((a: IAuctionDocument) => <ArtAuctionsCardViewComponent isRight={true} key={a._id} fetch={() => fetchAuctions(spaceType, query, filtered)} auction={a} user={user} />)
                }
                {
                    (auctions && auctions.length === 0 && !loading) && <div className="text-center  mt-5 mb-5">
                        <h4>No Active Auction Event Found! </h4>
                    </div>
                }
            </div>
        </div>
    )
}

export default ArtAuctionsPage