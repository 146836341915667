import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { AxiosResponse } from 'axios'
import { IStoreReducers, IUser } from '../../../interfaces'
import { refreshUser } from '../../../store/actions'
import { paymentService, toastService } from '../../../services'
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component'

interface IProps extends RouteComponentProps {
    user?: IUser,
    refreshUser?: any
}

interface IState {
    showUserForm: boolean,
    showMore: boolean
    loading: boolean
    deleteLoading: boolean,
    confirmDelete: boolean
}
class ConnectedWithStripePage extends Component<IProps> {
    state: IState = {
        showUserForm: false,
        loading: false,
        showMore: false,
        deleteLoading: false,
        confirmDelete: false
    }

    get query() {
        return this.props.location.search
    }
    componentDidMount() {
        if (this.query) {
            let query = new URLSearchParams(this.props.location.search).get("status")
            if (query === 'true') {
                toastService.success('You have successfully connected your account with Stripe to process payments and transfers funds into your account.')
            }
        }
    }
    getAccount = () => {
        let { user } = this.props
        this.setState({ loading: true })
        paymentService.getStripeAccount(user?.accountId).then((res: AxiosResponse) => {
            if (res.data.status === 'Success') {
                window.open(res.data.URL, "_blank");
            }

            if (res.data.status === 'Fail') {
                this.setState({ loading: true })
                paymentService.connectWithStripe({ userId: user?._id }).then((res: AxiosResponse<any>) => {
                    window.location.replace(res.data.url);
                    this.setState({ loading: false })
                }).catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
            }
            // console.log("accountL-=-", res.data.account)
            this.setState({ loading: false })
        })
    }

    onConnect = () => {
        let { user } = this.props
        if (user?.accountId) {
            this.getAccount()
            return
        }
        this.setState({ loading: true })
        paymentService.connectWithStripe({ userId: user?._id }).then((res: AxiosResponse<any>) => {
            window.location.replace(res.data.url);
            this.setState({ loading: false })
        }).catch(err => {
            console.log(err)
            this.setState({ loading: false })
        })
    }

    getButtonText = () => {
        let { user } = this.props
        if (user?.accountId) {
            return `Access Account`
        }

        return `Set up Account`
    }

    deleteAccount = () => {
        const { user } = this.props
        this.setState({ deleteLoading: true })
        paymentService.deleteStripeAccount({ userId: user?._id, accountId: user?.accountId }).then((res: AxiosResponse) => {
            if (res.data.deleted) {
                toastService.success('Your account has been deleted successfully')
                this.props.refreshUser()
            }
            this.setState({ deleteLoading: false, confirmDelete: false })
        }).catch(err => {
            console.log(err)
            this.setState({ deleteLoading: false, confirmDelete: false })
        })
    }

    openConfirmDeletePopup = () => {
        this.setState({ confirmDelete: true })
    }

    onCloseConfirmDeletePopup = () => {
        this.setState({ confirmDelete: false })
    }

    render() {
        const { showUserForm, loading, showMore, deleteLoading, confirmDelete } = this.state
        const { user } = this.props
        return (
            <>
                <div className="at_theme_innercontent at_theme_innerconnectstripe pt-4">
                    <p>
                        OMNI has contracted with Stripe, a leading global payment services technology company to process payments and transfers funds into your account. Please click below to setup and manage your Stripe account.
                    </p>
                    <div className="form-group">
                        <button className="at-btn at-minwidth-100" disabled={loading} onClick={this.onConnect}>{loading ? 'Please wait...' : this.getButtonText()}</button>
                    </div>
                    {
                        user?.accountId && <div className="form-group">
                            <button className="at-btn at-btn-bermudagrey at-minwidth-100" disabled={deleteLoading} onClick={this.openConfirmDeletePopup}>{deleteLoading ? 'Please wait...' : 'Delete Account'}</button>
                        </div>
                    }
                </div>

                <ConfirmAlertComponent
                    title='Delete Account'
                    message='Are you sure you want to delete this account.'
                    onClose={this.onCloseConfirmDeletePopup}
                    onConfirm={this.deleteAccount}
                    open={confirmDelete}
                    onCancel={this.onCloseConfirmDeletePopup}
                />
            </>
        )
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, { refreshUser })(ConnectedWithStripePage)
