import { HttpService } from './base.service'
import { IResponse, IHub, IHubDelete } from '../interfaces'
import { AxiosResponse } from 'axios'

class HubService extends HttpService {
	private readonly prefix: string = 'hub'

	/**
	 * Hubs List
	 */
	list = (): Promise<AxiosResponse<IHub[]>> => this.get(this.prefix)

	/**
	 * Create Hub
	 * @param  {IHub} hub
	 */
	add = (hub: IHub): Promise<AxiosResponse<IHub | IResponse>> =>
		this.post(this.prefix, hub)

	/**
	 * Update Hub
	 * @param  {IHub} hub
	 */
	udpate = (hub: IHub): Promise<AxiosResponse<IHub>> =>
		this.put(this.prefix, hub)

	/**
	 * Remove Hub(s) form hub list
	 * @param  {IHub} hub
	 */
	remove = (hubs: IHubDelete): Promise<any> =>
		this.delete(this.prefix, undefined, hubs)
}

export const hubService = new HubService()
