import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { images } from '../../../assets/image'
import { AWS_ENDPOINT } from '../../../environment'
import { IUser } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { IGalleryDocument } from '../../../interfaces/Gallery-walk.interface'
import { routes } from '../../../router'
import { galleryWalkService, toastService } from '../../../services'
import { eventImages } from '../../../shared'
import { ImageLoading } from '../../common/ImageLoading'
import { UniversalModal } from '../../common/Modals/Universal.modal'
import { TmceEditor } from '../../common/TMCEditor.comopnent'
import HeartComponent from '../feed/HeartIcon.component'

interface IProps {
    gallery: IGalleryDocument
    user: IUser
    onClickOnGalleryWalk?: (g: any) => any
    fetch: Function
}

export const BuyerGalleryViewComponent = ({ gallery, user, onClickOnGalleryWalk, fetch, }: IProps) => {

    const [showPicture, setShowPicture] = useState<boolean>(false)
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')

    const history = useHistory()

    const openShowPicture = () => {
        if (showInfo) closeSshowInfo()
        setShowPicture(true)
    }

    const onPdfClick = () => {
        if (!gallery || !gallery.pdf || gallery.pdf === 'null') return
        let link = `${AWS_ENDPOINT}/pdf/${gallery?.pdf}`
        window.open(link, "_blank");
    }

    const closeShowPicture = () => {
        setShowPicture(false)
    }

    const openShowInfo = () => {
        if (showPicture) closeShowPicture()
        setDescription(gallery.description)
        setShowInfo(true)
    }

    const closeSshowInfo = () => {
        setShowInfo(false)
    }

    const onClickImage = () => {
        if (onClickOnGalleryWalk) {
            onClickOnGalleryWalk(gallery)
            return
        }
        history.push(`${routes.artCentral.gelleryWalk.view}/${gallery._id}`)
    }

    const onUpdateDescription = () => {
        if (!description) return
        setLoading(true)
        galleryWalkService.updateSingleGalleryWalkById(gallery._id, { description }).then((res: AxiosResponse<IResponse<IGalleryDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            }
            fetch()
            setLoading(false)
            closeSshowInfo()
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }



    return <div>
        <div className="at-post">
            <div className="at-orangeeconhead">
                {/* <figure>
                    <img alt="icon" />
                </figure> */}
                <div className="at-orangeeconcontent">
                    <h3><span className="text-center">{gallery.name} <br />
                        <span className="fs-14">{moment(gallery.startDate).format("DD/MM/YYYY")}-{moment(gallery.endDate).format("DD/MM/YYYY")} </span>
                        <span className="fs-14">{moment(gallery.startTime).format("hh:mm a")}-{moment(gallery.endTime).format("hh:mm a")} {gallery.timeZone ? gallery.timeZone.includes('/') ? gallery.timeZone.split('/')[1] : gallery.timeZone : 'EST'}</span></span></h3>
                    <div className="at-btniconholder">
                        <div className="at-btnholder-bottom">
                            <a className="at-btnomniicon at-postbtn" title="Gallery"><i className="icon-gallery" onClick={() => openShowPicture()}></i></a>
                            <a className="at-btnomniicon at-postbtn" title="Information"><i className="icon-info" onClick={() => openShowInfo()}></i></a>
                            <a className="at-btnomniicon at-postbtn" title="document"><i className="icon-pdf" onClick={() => onPdfClick()}></i></a>
                            {/* <a title="Preferred" className="at-btnheart at-postbtn cursor-pointer"><i className="icon-favorite"></i></a> */}
                            <HeartComponent postId={gallery.id} userId={gallery.user._id} />
                        </div>
                    </div>
                </div>

                <div className="at-orangeeconcontent at-gallerywalkpage-content">
                    <h3><span>{gallery.name}</span></h3>
                    <div className="at-btniconholder">
                        <div className="at-btnholder-bottom">
                            <a className="at-btnomniicon at-postbtn" title="Gallery"><i className="icon-gallery" onClick={() => openShowPicture()}></i></a>
                            <a className="at-btnomniicon at-postbtn" title="Information"><i className="icon-info" onClick={() => openShowInfo()}></i></a>
                            <a className="at-btnomniicon at-postbtn" title="document"><i className="icon-pdf" onClick={() => onPdfClick()}></i></a>
                            {/* <a title="Preferred" className="at-btnheart at-postbtn cursor-pointer"><i className="icon-favorite"></i></a> */}
                            <HeartComponent postId={gallery.id} userId={gallery.user._id} />
                        </div>
                    </div>
                </div>
            </div>
            <figure className="at-postimg" onClick={() => onClickImage()}><img src={eventImages({}, gallery.poster)} alt="" className="false " /></figure>
            <div className="at-orangeeconcontent at-gallerywalkpage-contentbottom">
                <h3><span className="text-center"> {moment(gallery.startDate).format("DD/MM/YYYY")}-{moment(gallery.endDate).format("DD/MM/YYYY")} <br />
                    {moment(gallery.startTime).format("hh:mm a")}-{moment(gallery.endTime).format("hh:mm a")} {gallery.timeZone ? gallery.timeZone.split('/')[1] : 'EST'}</span></h3>
            </div>
        </div>

        {showInfo && description && <UniversalModal open={showInfo} onClose={() => setShowInfo(false)}>
            <div className="at-addactionholder">
                <div className="at-formthemehead text-center pb-2">
                    <h2>{gallery.name}</h2>
                    <div className="text-left my-3">
                        <p className="mb-0">Start: {moment(gallery.startDate).format('LL')}</p>
                        <p className="mb-0">End: {moment(gallery.endDate).format('LL')}</p>
                    </div>
                </div>
                {(gallery.user as IUser)._id === user._id ? <>
                    <div className="row description-modal-editor">
                        <div className="col-12">
                            <TmceEditor text={description} onChange={(e: any) => setDescription(e)} />
                        </div>
                    </div>
                    <div className="pt-2">
                        <button className="at-btn at-btnupdate at-btn-fullwidth" disabled={loading} onClick={() => onUpdateDescription()}>{loading ? `Please wait...` : `Update`}</button>
                    </div>
                </>
                    :
                    <div className=" ">
                        {description && <div className="text-justify" dangerouslySetInnerHTML={{ __html: description }}>
                        </div>}
                    </div>
                }
            </div>
        </UniversalModal>}
        {
            showPicture && gallery && gallery.images.length > 0 && <UniversalModal open={showPicture} onClose={() => setShowPicture(false)}>
                <div className="at-modal-images">
                    <h5 className="at-popupheading">Gallery Walk/Event Pictures</h5>
                    {gallery.images.map((i: any) => {
                        return (
                            <figure className="at-roundbox-figure-plain">
                                <ImageLoading src={eventImages({}, `high_${i.url}`)} thumbnail={eventImages({}, `blur_${i.url}`)} />
                                {/* <img src={eventImages({}, `high_${i.url}`)} alt="" /> */}
                            </figure>
                        )
                    })}
                </div>
            </UniversalModal>
        }
    </div>
}