import React, { FormEvent, useEffect } from 'react'
import { currencyFormatter } from '../../helper'
import MaskInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

interface IProps {
    value?: string,
    name?: string,
    className?: string
    id?: string
    placeholder?: string
    disabled?: boolean
    prefix?: string
    onChange: Function
    type?: string
    defaultValue?:any
}

export const CurrencyField = ({ type, value, name, className, id, placeholder, disabled, prefix, onChange, defaultValue }: IProps) => {
    const maskProps = createNumberMask({
        prefix: prefix,
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 2,
        allowNegative: false,
        allowLeadingZeroes: false,
    })

    const handleChange = (e: any) => {
        onChange(e)
    }
    return <MaskInput
        type={type || 'text'}
        name={name}
        className={className || ''}
        id={id || ''}
        value={value}
        placeholder={placeholder}
        disabled={disabled || false}
        mask={maskProps}
        onChange={handleChange}
        defaultValue={defaultValue||''}
    />
}