import React, { useState } from "react";
interface IProps {
    click?: any
    onLongPress?: any
    children: any
    [key: string]: any
}
export const LongPressDoubleClickComponent = ({ click, onLongPress, children, ...other }: IProps) => {
    const [longPress, setlongPress] = useState(false)

    let buttonPressTimer: any = null;
    const handleButtonPress = () => {
        buttonPressTimer = setTimeout(() => {
            setlongPress(true)
            if (onLongPress) {
                onLongPress(other)
            }
        }, 300);
    }

    const handleButtonRelease = () => {
        // if (!longPress) {
        //     click && click(other)
        // }
        if (longPress){
        setlongPress(false);
        clearTimeout(buttonPressTimer);
        }
    
    }

    return (
        <div
            onTouchStart={handleButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}
            onDoubleClick={() => click && click(other)}
            >
            {
                children
            }
        </div>
    );
}