import React, { FormEvent } from 'react'
import { StepWizardChildProps } from 'react-step-wizard';
import { EPrintType } from '../../../../enums';
import { ICollectionItemState, ICreateCollection, IFormates } from '../../../../interfaces';
import { toastService } from '../../../../services';
import { CurrencyField } from '../../../common/CurrencyInput';

interface IProps extends StepWizardChildProps {
    item: ICollectionItemState
    formates: IFormates
    onFormateChanges: Function
    createFormates: Function
    confirmDeleteFormats: Function
    openShowServices: Function
    editFormats: Function
}

export const CreateCollectionStepFive = ({ item, formates, onFormateChanges, createFormates, confirmDeleteFormats, openShowServices, editFormats, nextStep, previousStep }: IProps) => {
    let formatArray = item.formates;

    const validateFormats = () => {
        let cFormats = formatArray.filter((f: IFormates) => !f.selectedService)
        if (cFormats.length > 0) {
            return false
        }

        return true
    }
    const onClickNext = () => {
        if (!validateFormats()) {
            toastService.warning('Please select printer for each of your editions to complete this step.')
            return
        }

        nextStep()

    }
    return (
        <>
            <div className="at-orangeheading pb-2 float-none">
                <h5>STEP 5: Add Open and Limited Editions</h5>
                <h6>(Skip Step 5 if you are not planning to sell editions of your art or want to complete this step later)</h6>
            </div>

            {/* <h5 className="at-popupheading pb-3">
                {formates && formates._id
                    ? "Edit Open and Limited Editions"
                    : "Add Open and Limited Editions"}
            </h5> */}
            <div className="at-greyborderbox pt-4">
                <div className="row row-margin-lr7 align-items-center">
                    <div className="col-12 pad-lr-7">
                        <div className="form-group form-w-100 at-floatlabel">
                            <input
                                type="text"
                                name="size"
                                className="form-control"
                                value={formates.size}
                                placeholder=" "
                                onChange={(e: FormEvent<HTMLInputElement> | any) => onFormateChanges(e)}
                            />
                            <label>Description</label>
                        </div>
                    </div>
                    <div className="col-12 pad-lr-7">
                        <div className="form-group form-w-100 at-floatlabel at-form-group-mb15">
                            <CurrencyField
                                id="input-example-format-price"
                                className="form-control "
                                name="price"
                                placeholder=" "
                                prefix="$"
                                value={formates.price}
                                onChange={(e: FormEvent<HTMLInputElement> | any) => onFormateChanges(e)}
                            />
                            <label>price</label>
                        </div>
                    </div>
                    {/* <div className="col-6 pad-lr-7">
                        <div className="form-group form-w-100 at-floatlabel at-form-group-mb15">
                            <CurrencyField
                                id="input-example-format-shipping"
                                className="form-control "
                                name="shipping"
                                placeholder=" "
                                prefix="$"
                                value={formates.shipping}
                                onChange={(e: FormEvent<HTMLInputElement> | any) => onFormateChanges(e)}
                            />
                            <label>shipping</label>
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <div className="form-group form-w-100 at-form-group-mb15">
                            <p className="at-notered mb-2">
                                NOTE: Printing and framing costs will be added to your edition
                                price in setting the total price shown to the buyer.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 pad-lr-7">
                        <div className="form-group form-w-100">
                            <span className="at-select at-floatlabel">
                                <select
                                    className="form-control floating-select"
                                    name="printType"
                                    value={formates.printType}
                                    required
                                    onChange={(e: FormEvent<HTMLInputElement> | any) => onFormateChanges(e)}
                                >
                                    <option value=""></option>
                                    <option value={EPrintType.FRAMED}>With Frame</option>
                                    <option value={EPrintType.UNFRAMED}>Without Frame</option>
                                </select>
                                <label className="at-lineheight-27">Select Frame</label>
                            </span>
                        </div>
                    </div>

                </div>
                <div className="form-group form-w-100 ">
                    <button
                        className="at-btn at-longbtn-h40imp"
                        onClick={() => createFormates()}
                        type="button"
                        disabled={!formates.printType}
                    >
                        ADD EDITION
                    </button>
                </div>
                {formatArray?.map(
                    (item: any, ind: number) =>
                        item &&
                        item.active && (
                            <div className={`at-haslayout py-2 ${ind === 0 ? "at-bordertop-small pt-3 mt-2" : ""}`}>
                                <div className="row row-form-three-cols align-items-center mb-0">
                                    <div className="col-5 pad-lr5"></div>
                                    <div className="col-5 pad-lr5">
                                        <h6 className="at-color-orange">Print Edition Price</h6>
                                    </div>
                                </div>
                                <div className="row row-form-three-cols align-items-center mb-0">
                                    <div className="col-5 pad-lr5 at-detail-new">
                                        <h6>{item.size}</h6>
                                        {item.printType === EPrintType.FRAMED && (
                                            <p>
                                                {"Framed"}{" "}
                                                <span
                                                    className="at-add-details"
                                                    onClick={() => openShowServices(item, ind)}
                                                >
                                                    Select Printer
                                                </span>
                                            </p>
                                        )}
                                        {item.printType === EPrintType.UNFRAMED && (
                                            <p>
                                                {"Unframed"}{" "}
                                                <span
                                                    className="at-add-details"
                                                    onClick={() => openShowServices(item, ind)}
                                                >
                                                    Select Printer
                                                </span>
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-5 pad-lr5">
                                        <div className="row at-row-mlr-5">
                                            <div className="col-12 pad-lr5">
                                                <div className="form-group">
                                                    {/* <input
													className="form-control cust-input-padding-5px"
													name=""
													placeholder="$600"
													prefix="$"
												/> */}
                                                    <CurrencyField
                                                        id="input-example-format-price"
                                                        className="form-control cust-input-padding-5px text-center"
                                                        name="price"
                                                        placeholder="price"
                                                        prefix="$"
                                                        disabled={true}
                                                        value={item.price}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-6 pad-lr5">
                                                <div className="form-group"> */}
                                            {/* <input
													className="form-control cust-input-padding-5px"
													name=""
													placeholder="$25"
													prefix="$"
												/> */}
                                            {/* <CurrencyField
                                                        id="input-example-format-shipping"
                                                        className="form-control cust-input-padding-5px text-center"
                                                        name="shipping"
                                                        placeholder="shipping"
                                                        prefix="$"
                                                        disabled={true}
                                                        value={item.shipping}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-2 pad-lr5">
                                        <div className="at-editformat-new">
                                            <i
                                                className="icon-delete2"
                                                onClick={() => confirmDeleteFormats(ind)}
                                            />
                                            <i
                                                className="icon-edit2"
                                                onClick={() => editFormats(ind)}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                )}
                <div className="clearfix"></div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => previousStep()}
                        className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                    >
                        BACK
                    </button>
                </div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => onClickNext()}
                        className={`at-btn at-medbtn-h40-mauto ${!validateFormats() ? 'at-btn-disabled' : ''}`}
                        // disabled={!validateFormats()}
                    >
                        NEXT
                    </button>
                </div>
            </div>
            {/* at-greyborderbox ends */}
        </>
    )
}