import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import ArtDetailPage from './ArtDetailPage'
import ArtistsPage from './ArtistsPage'
import AboutPage from '../components/AboutPage'
import ContactPage from '../components/ContactPage'
import ExhibitionsPage from './ExhibitionsPage'
import HomePage from './HomePage'
import { routes } from './routes'
import CollectionPage from './CollectionPage'
import EventPage from './EventPage'
import '../assets/templates.scss'
import { useDispatch, useSelector } from 'react-redux'
import { HttpService } from '../../services/base.service'
import { FetchTemplateData } from '../../store/actions'
import { authSelector, inPlatformSelector, templateSelector, userSelector } from '../../store/selectors'
import LoadingComponent from '../../components/common/Loading.component'
import PrivateRoutes from '../components/PrivateRoutes'
import { TemplateContainer } from '../assets/_global-template-style'
import PortalComponent from '../../components/common/Portal.component'

interface IProps {
    name: string
}
const MainRoute = ({ name }: IProps) => {
    const location = useLocation()
    const auth = useSelector(authSelector).access_token
    HttpService.setToken(auth)
    const template = useSelector(templateSelector)
    const inPlatform = useSelector(inPlatformSelector)
    return (
        <div className={`At-TemplateWrap ${inPlatform.activeSplitScreen ? "At-PortalEnabled" : ''}`}>
            <TemplateContainer templateBackground_Color={template.backgroundColor || '#2b2b2b'} textColor={template.textColor || "#fff"} lineColor={template.lineColor || '#eaedf1'} className="At-Theme At-ThemeBlack">
                <Header />
                <Switch>
                    <Route path={routes(name).home} exact component={HomePage} />
                    <Route path={routes(name).about} exact component={AboutPage} />
                    <Route path={routes(name).Contact} exact component={ContactPage} />
                    <PrivateRoutes>
                        <Route path={routes(name).exhibitions.index} exact component={ExhibitionsPage} />
                        <Route path={`${routes(name).exhibitions.viewSingleExhibition}/:id`} exact component={EventPage} />
                        <Route path={`${routes(name).artists.collectionItemView}/:id`} exact component={ArtDetailPage} />
                        <Route path={`${routes(name).artists.collectionView}/:id`} exact component={CollectionPage} />
                        <Route path={routes(name).artists.index} exact component={ArtistsPage} />
                    </PrivateRoutes>
                </Switch>
                <Footer />
            {inPlatform.activeSplitScreen && <PortalComponent />}
            </TemplateContainer>
        </div>
    )
}

export default MainRoute 