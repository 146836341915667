import React from 'react'
import { StepWizardChildProps } from 'react-step-wizard';
import { ICreateCollection } from '../../../../interfaces';

interface IProps extends StepWizardChildProps {
    newCollection: ICreateCollection
    setNewCollection: Function
    errorElement: Function
    setShowSampleKeywordBox: Function
    submitCollection: Function
}

export const CreateCollectionStepOne = ({ newCollection, setNewCollection, errorElement, setShowSampleKeywordBox, submitCollection, nextStep }: IProps) => {
    return (
        <>
            <div className="at-orangeheading">
                <h5>Create New Collection</h5>
                <h6>(skip Step 1 if adding art to existing collection)</h6>
            </div>
            <div className="at-greyborderbox pt-4">
                <div className="form-group form-w-100 at-floatlabel">
                    <input
                        type="text"
                        name="createnewcollection"
                        className="form-control"
                        value={newCollection.title}
                        autoComplete="off"
                        placeholder=" "
                        onChange={({ target: { value } }) => {
                            setNewCollection({ ...newCollection, title: value });
                        }}
                    />
                    <label>New Collection Name</label>
                    {() => errorElement("title")}
                    {newCollection.title.length > 30 && (
                        <span
                            className={`${(newCollection.title || "").length > 30 && "text-danger"
                                }`}
                        >
                            Collection Name cannot be more than 30 characters
                        </span>
                    )}
                </div>
                <div className="newCollectionDescLabel">
                    <small className="">
                        Please enter a short collection description to help buyers
                        fall in love with your art.
                    </small>
                </div>
                <div className="form-group form-w-100 at-floatlabel">
                    <textarea
                        name="description"
                        placeholder=" "
                        className="form-control"
                        onChange={({ target: { value } }) =>
                            setNewCollection({ ...newCollection, description: value })
                        }
                        value={newCollection.description}
                    >
                        {newCollection.description}
                    </textarea>
                    <label>Collection Description</label>
                    {/* {errorFor === selectedFile && this.errorElement('description')} */}
                </div>
                {/* keywords field */}
                <div className="newCollectionDescLabel">
                    <small className="">
                        Please enter a few keywords (seprated by a comma) of the genre
                        and style of your art. This is important to give buyers a
                        better experience (<span className="text-primary cursor-pointer" onClick={() => setShowSampleKeywordBox(true)}>click here</span>{" "}
                        for sample list).
                    </small>
                </div>
                <div className="form-group form-w-100 at-floatlabel">
                    <textarea
                        name="keywords"
                        placeholder=" "
                        className="form-control"
                        value={newCollection.keywords}
                        onChange={({ target: { value } }) =>
                            setNewCollection({ ...newCollection, keywords: value })
                        }
                    ></textarea>
                    <label>Keywords</label>
                    {/* {errorFor === selectedFile && this.errorElement('description')} */}
                </div>

                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => nextStep()}
                        className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                    >
                        SKIP STEP
                    </button>
                </div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => submitCollection()}
                        disabled={!newCollection.title}
                        className="at-btn at-medbtn-h40-mauto"
                    >
                        ADD COLLECTION
                    </button>
                </div>
            </div>
            {/* at-greyborderbox ends */}

        </>
    )
}