import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Subscription } from 'rxjs'
import { MobileArtCollections } from '../../components/ArtCentral/art_collections/MobileArtCollections.component'
import { ADD_NEW_MEMBER, USER_ALERT_SPACE_ENTER, USER_ALERT_SPACE_MESSAGE } from '../../constants'
import { APP_URL } from '../../environment'
import { ICollection, IPaginateResponse, ISpace, ITemplateReducerState, IUser } from '../../interfaces'
import { spaceService, toastService, UserLoginAction, userService } from '../../services'
import { collectionService } from '../../services/collection.service'
import { socketNode } from '../../services/socket.service'
import { activateSpaceAction, changeActiveSpace, fetchUserStudio } from '../../store/actions'
import { authSelector, isSpaceActivatedSelector, userSelector } from '../../store/selectors'
import { useHistory, useLocation } from 'react-router-dom'
import { IZoom, EZoom, ZoomActions } from '../../services/zoom.service'
import { LargeDisplayArtCollections } from '../../components/ArtCentral/art_collections/LargeDisplayArtCollection'
import { getUserTemplateRoute } from '../../shared'


interface IProps {
    onSelectCollection?: (c: ICollection) => any
    isShowMobile?: boolean
}

interface IPageState {
    loading: boolean
    error: any
    isZoomed: boolean
}

const initialState = {
    isZoomed: false,
    error: null,
    loading: false,
}
const Art_Collections = ({ onSelectCollection, isShowMobile }: IProps) => {

    const zoomSubscription = new Subscription();

    const user: IUser = useSelector(userSelector)
    const token = useSelector(authSelector).access_token
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    const [state, setState] = useState<IPageState>({ ...initialState })
    const [page, setPage] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [filtered, setFiltered] = useState<boolean>(false)
    const [query, setQuery] = useState<string>('')
    const [next, setNext] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [collections, setCollections] = useState<Array<ICollection>>([])
    const [showMessageBox, setMessageBox] = useState<boolean>(false)
    const [selectedCollection, setSelectedCollection] = useState<ICollection>()
    const [zoom, setZoom] = useState<IZoom>({ type: EZoom.NONE, value: null })
    const [role, setRole] = useState<string>('')


    const fetchCollections = async (page = 1, query = "", filtered = false, role = ''): Promise<any> => {
        toggleLoading(true)
        let userId = ''

        if (filtered && user) {
            userId = user._id
        }
        setPage(page)
        collectionService.artCollections({ page, limit: 6, filtered, query, userId, role }).then((res: AxiosResponse<IPaginateResponse<ICollection>>) => {
            const { count, documents, next } = res.data;
            const newFeeds = page === 1 ? [...documents] : [...collections, ...documents];
            setNext(next)
            setCount(count)
            setCollections([...newFeeds])
            toggleLoading(false)
        }, (err: any) => {
            console.log(err)
            toggleLoading(false)
        })
    }

    const toggleLoading = (l: boolean) => {
        setState({
            ...state,
            loading: l
        })
    }

    const openSearch = () => {
        setShowSearch(true)
    }

    const closeSearch = () => {
        setQuery('')
        setShowSearch(false)
        fetchCollections(1, '', filtered)
    }

    const search = (e: any) => {
        setQuery(e.target.value)
        fetchCollections(1, e.target.value, filtered)
    }

    const getSearchQuery = () => {
        return new URLSearchParams(location.search).get("name")
    }

    const onToggleFilter = (value: boolean) => {
        if (value) {
            setFiltered(true)
        } else {
            setFiltered(false)
        }
        fetchCollections(1, query, value)
    }

    const onChangeRole = (e: any) => {
        setRole(e.target.value)
        fetchCollections(1, query, filtered, e.target.value)
    }

    const onScroll = () => {

    }

    const openArtCentralSpacePopup = async (route: string = '', c: ICollection) => {

        let cUser = c.user
        if (!token) {
            UserLoginAction.triggerAction(true)
            return
        }

        // if (window.innerWidth > 770) {
        //     if (cUser.template && (cUser.template as ITemplateReducerState).url) {
        //         window.location.replace(`${APP_URL}/${(cUser.template as ITemplateReducerState)?.url}/${getUserTemplateRoute(cUser.template as ITemplateReducerState)}`)
        //         return
        //     }
        // }
        const space: ISpace = (await spaceService.userArtCentralSpace(c.user._id)).data;
        changeActiveSpace && dispatch(changeActiveSpace(space))
        socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
        dispatch(fetchUserStudio(c.user._id))
        sendUserAlert(c, `${user?.firstName} ${user?.lastName} has just entered your space.`, USER_ALERT_SPACE_ENTER, false, space._id)
        dispatch(activateSpaceAction(true))
        if (onSelectCollection) {
            onSelectCollection(c)
            
        }
        history.push(route, c.id)
    }

    const openMessageBox = (c: ICollection) => {
        setMessageBox(true)
        setSelectedCollection(c)
    }

    const closeMessageBox = () => {
        setMessageBox(false)
        setSelectedCollection(undefined)
    }

    const onSumbitMessageBox = (message: string) => {
        sendUserAlert(selectedCollection as ICollection, message, USER_ALERT_SPACE_MESSAGE, true)
    }

    const sendUserAlert = (c: ICollection, message: string, type: string, showToast: boolean = false, spaceId: any = null) => {
        let cUser = c.user

        if (user?._id === cUser._id) {
            return
        }
        let data = {
            message: message,
            to: cUser._id,
            from: user?._id,
            type: type,
            spaceID: spaceId
        }
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
            if (showToast && res.data.status === 'Success') {
                toastService.success("Message sent successfully")
            }
            closeMessageBox()
        }).catch((err: any) => {
            console.log(err)
            closeMessageBox()
        })
    }

    useEffect(() => {
        if (getSearchQuery()) {
            openSearch()
            setQuery(getSearchQuery() as string)
            fetchCollections(1, getSearchQuery() as string)
        } else {
            fetchCollections(1)
        }
    }, [])

    useEffect(() => {
        const sub$ = ZoomActions.listen.subscribe((data: IZoom) => {
            if (data) {
                setZoom({ ...data })
            }
        });

        zoomSubscription.add(sub$)

        return () => {
            zoomSubscription.unsubscribe()
        }
    }, [])

    let { loading, isZoomed, } = state
    return <div className="at-haslayout-heightinherit">
        {zoom.type === EZoom.ART_COLLECTION && !isShowMobile ? <LargeDisplayArtCollections
            user={user}
            fetch={fetchCollections}
            page={page}
            loading={state.loading}
            isZoomed={state.isZoomed}
            filtered={filtered}
            showSearch={showSearch}
            next={next}
            query={query}
            collections={collections}
            showMessageBox={showMessageBox}
            onScroll={onScroll}
            onOpenSearch={openSearch}
            closeSearch={closeSearch}
            onToggleFilter={onToggleFilter}
            onSearch={search}
            openArtCentralSpacePopup={openArtCentralSpacePopup}
            openMessageBox={openMessageBox}
            closeMessageBox={closeMessageBox}
            onSumbitMessageBox={onSumbitMessageBox}
            count={count}
            onChangeRole={onChangeRole}
            role={role}
        />
            :
            <MobileArtCollections
                user={user}
                fetch={fetchCollections}
                page={page}
                loading={state.loading}
                isZoomed={state.isZoomed}
                filtered={filtered}
                showSearch={showSearch}
                next={next}
                query={query}
                collections={collections}
                showMessageBox={showMessageBox}
                onScroll={onScroll}
                onOpenSearch={openSearch}
                closeSearch={closeSearch}
                onToggleFilter={onToggleFilter}
                onSearch={search}
                openArtCentralSpacePopup={openArtCentralSpacePopup}
                openMessageBox={openMessageBox}
                closeMessageBox={closeMessageBox}
                onSumbitMessageBox={onSumbitMessageBox}
                count={count}
                onChangeRole={onChangeRole}
                role={role}
            />}
    </div>
}

export default Art_Collections