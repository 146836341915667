import React from 'react'
import * as type from '../../constants/transactionTypes'
import { ICollectionItem, ICommissionResponse, IFormates, IMilestone, INotification, IOffer, ITransaction } from '../../interfaces'
import { AcceptOfferLayout, ReturnArtLayout, RecievedOfferLayout, RefundLayout, RejectedOfferLayout, TransactionLayout, RequestCommissionWork, RequestProposal, ReturnArtshippingLayout, ShippingItemLayout, PaymentLayout } from '../../components/Notifications'
import { ServiceTransactionlayout } from './Account/ServiceTransactionLayout'
import { SoldPrintingServiceLayout } from '../Notifications/SoldPrintingService'
import { CuratorialNotificationBox } from '../Notifications/CuratorialNotificationBox'
import { CuratorialInvitationResponseBox } from '../Notifications/CuratorialInvitationResponse'
import { CuratorialSendProposalBox } from '../Notifications/CuratorialSendProposalBox'
import { CuratorialOfferSendNotification } from '../Notifications/CuratorialOfferSendNotification'
import { CuratorialOfferRequestPaymentNotification } from '../Notifications/CuratorialOfferRequestPaymentNotification'

interface IProps {
    notificationType: string,
    notification: INotification
}

export const NotitcationLayOut = ({ notificationType, notification }: IProps) => {

    let item = (notification.itemId as ICollectionItem)
    let transaction = (notification.transaction as ITransaction)
    let commission = notification.commissionID ? (notification.commissionID as ICommissionResponse) : null

    const getItemType = (t: ITransaction) => {
        if (t.formatesId) {
            let format: Array<IFormates> = item?.formates?.filter((value: IFormates) => value._id === t?.formatesId)
            if (format && format.length <= 0) {
                return ` `
            }
            if (format && format[0]) {
                return format[0].size
            }
            return ` `
        }
        return `Original`
    }

    const getLayouts = () => {
        switch (notificationType) {
            case type.ACCEPT_OFFER:
                return <AcceptOfferLayout notification={notification} />
            case type.REJECT_OFFER:

                return <RejectedOfferLayout notification={notification} />
            case type.RECIEVED_OFFER:

                return <RecievedOfferLayout notification={notification} getItemType={getItemType} />
            case type.CONFORMATION_OF_SHIPMENT:
                return <ShippingItemLayout notification={notification} />

            case type.RETURN_OF_ART_NOTICE:
                return <ReturnArtLayout notification={notification} getItemType={getItemType} />

            case type.REFUND_PAYMENT:
                return <RefundLayout notification={notification} getItemType={getItemType} />

            case type.TRANSFER_PAYMENT:
                return <TransactionLayout notification={notification} getItemType={getItemType} />
            case type.TRANSFER_PAYMENT_PRINTING_SERVICE_PROVIDER:
                return <SoldPrintingServiceLayout notification={notification} getItemType={getItemType} />

            case type.RELEASE_OF_FUNDS:
                return <PaymentLayout notification={notification} getItemType={getItemType} />

            case type.STRIPE_ACCOUNT:
                return <PaymentLayout notification={notification} getItemType={getItemType} />

            case type.RETURN_ALLOWED:
                return <ReturnArtLayout notification={notification} getItemType={getItemType} />

            case type.RETURN_NOT_ALLOWED:
                return <ReturnArtLayout notification={notification} getItemType={getItemType} />

            case type.RETURN_EXPIRE:
                return <ReturnArtLayout notification={notification} getItemType={getItemType} />

            case type.RETURN_SHIPPED:
                return <ReturnArtshippingLayout notification={notification} />
            case type.COMMISSION_WORK_REQUEST:
                return <RequestCommissionWork notification={notification} />

            case type.COMMISSION_WORK_REJECTED:
                return <RequestCommissionWork notification={notification} />
            case type.COMMISSION_WORK_PROPOSAL:
                return <RequestProposal notification={notification} />
            case type.COMMISSION_WORK_PROPOSAL_APPROVED:
                return <RequestProposal notification={notification} />
            case type.COMMISSION_WORK_PROPOSAL_rejected:
                return <RequestProposal notification={notification} />
            case type.ON_BUY_SERVICE:
                return <ServiceTransactionlayout t={notification.serviceTransaction} />
            case type.CURATORIAL_SEND_INVITATIONS:
                return (notification.curatorialService && notification.curatorialService._id ? <CuratorialNotificationBox service={notification.curatorialService} n={notification} /> : null)
            case type.CURATORIAL_SEND_PROPOSAL:
                return (notification.curatorialService && notification.curatorialService._id ? <CuratorialSendProposalBox service={notification.curatorialService} n={notification} /> : null)
            case type.CURATORIAL_RESPONSE_INVITATIONS:
                return (notification.curatorialService && notification.curatorialService._id ? <CuratorialInvitationResponseBox service={notification.curatorialService} /> : null)
            case type.CURATORIAL_SEND_OFFER:
                return (notification.curatorialService && notification.curatorialService._id ? <CuratorialOfferSendNotification n={notification} /> : null)
            case type.CURATORIAL_ACCEPT_OFFER:
                return (notification.curatorialService && notification.curatorialService._id ? <CuratorialOfferSendNotification n={notification} /> : null)
            case type.CURATORIAL_REJECT_OFFER:
                return (notification.curatorialService && notification.curatorialService._id ? <CuratorialOfferSendNotification n={notification} /> : null)
            case type.CURATORIAL_REQUEST_OFFER_PAYMENT:
                return (notification.curatorialService && notification.curatorialService._id ? <CuratorialOfferRequestPaymentNotification n={notification} /> : null)
            case type.CURATORIAL_OFFER_PAYMENT_PAID:
                return (notification.curatorialService && notification.curatorialService._id ? <CuratorialOfferRequestPaymentNotification n={notification} /> : null)
            case type.SUBSCRIPTION_REFUND:
                return
            case type.END_ACCOUNT_ASSIGNMENT:
                return
            default:
                break;
        }
    }
    return (
        <>
            {getLayouts()}
        </>
    )
}