import { template } from "lodash"
import { EHubsAndSpaces } from "../constants"

export const images = {
    logoOrangecon: require('./images/logo/logo.png'),
    logoOmniNew: require('./images/logo/logo-omni.png'),
    logoOmniNew1: require('./images/logo/logo-omni2.png'),
    logoOmniNew2: require('./images/logo/logo-omni2.png'),
    logoOmniChati: require('./images/logo/logo-omnichati.png'),
    logoOmni: require('./images/logo/logo-two.png'), 
    hubsAndSpaces: {
        [EHubsAndSpaces.globeImg]: require('./images/hubs-and-spaces/globe-img.png'),
        [EHubsAndSpaces.hubImg]: require('./images/hubs-and-spaces/hub.png'),
        [EHubsAndSpaces.spaceRed]: require('./images/hubs-and-spaces/red.png'),
        [EHubsAndSpaces.spaceOrange]: require('./images/hubs-and-spaces/orange.png'),
        [EHubsAndSpaces.spaceCyan]: require('./images/hubs-and-spaces/cyan.png'),
        [EHubsAndSpaces.spaceBlue]: require('./images/hubs-and-spaces/blue.png'),
        [EHubsAndSpaces.spaceGray]: require('./images/hubs-and-spaces/gray.png'),
        [EHubsAndSpaces.art_central]: require('./images/artcentral-img.png'),
    },
    facebook: require('./images/icons/facebook.png'),
    instagram: require('./images/icons/instagram.png'),
    art_central: require('./images/art_central.png'),
    my_Central: require('./images/my_Central.png'),
    homeGlobe: require('./images/icons/home-globe.png'),
    img12: require('./images/user-img/img-12.jpg'),
    img06: require('./images/user-img/img-06.jpg'),
    img07: require('./images/user-img/img-07.jpg'),
    img08: require('./images/user-img/img-08.jpg'),
    img09: require('./images/user-img/img-09.jpg'),
    img01: require('./images/post-img/img-01.jpg'),
    img03: require('./images/img-03.jpg'),
    img04: require('./images/img-04.jpg'),
    img05: require('./images/img-05.png'),
    star_grey: require('./images/icons/star-grey.svg'), 
    star_green: require('./images/icons/star-green.svg'),
    star_yellow: require('./images/icons/star-yellow.svg'),
    star_blue: require('./images/icons/star-blue.svg'),
    no_image: require('./images/no_image.svg'),
    defaultUser: 'https://cdn4.iconfinder.com/data/icons/linecon/512/photo-512.png',
    frameWhite: require('./images/frame-white.jpg'),
    gallery: {
        img01: require('./images/gallery-img/img-01.jpg'),
        img02: require('./images/gallery-img/img-02.jpg'),
        img03: require('./images/gallery-img/img-03.jpg'),
        img04: require('./images/gallery-img/img-04.jpg'),
        img05: require('./images/gallery-img/img-05.jpg'),
        img06: require('./images/gallery-img/img-06.jpg'),
    },
    spaces: {
        img01: require('./images/active-spaces-icon/img-01.png'),
        img02: require('./images/active-spaces-icon/img-02.png'),
        img03: require('./images/active-spaces-icon/img-03.png'),
        img04: require('./images/active-spaces-icon/img-04.png'),
        img05: require('./images/active-spaces-icon/img-05.png'),    
    },
    admin: {
        img01: require('./images/active-spaces-icon/img-01.png'),
        img02: require('./images/user-img/transact-img.jpg'),
    },
    template: {
        logoTemplate1: require('./images/template-images/logo.png'),
        logoTemplate2: require('./images/template-images/logo-new.svg'),
        omniLogo: require('./images/template-images/omni-logo.png'),
        bannerTemplate1: require('./images/template-images/banner-template1.jpg'),
        bannerTemplate2: require('./images/template-images/template-slider.jpg'),
        bannerTemplate3: require('./images/template-images/gallery-banner.jpg'),
        gallery01: require('./images/template-images/gallery01.jpg'),
        gallery02: require('./images/template-images/gallery02.jpg'),
        gallery03: require('./images/template-images/gallery03.jpg'),
        gallery04: require('./images/template-images/gallery04.jpg'),
        artDetailImg: require('./images/template-images/art-detail.jpg'),
        aboutImg: require('./images/template-images/about.jpg'),
        mapImg: require('./images/template-images/map.jpg'),
        artShowHome: require('./images/template-images/art-show0.jpg'),
        artShowCollection: require('./images/template-images/art-show1.jpg'),
        artShowCollectionShow: require('./images/template-images/art-show2.jpg'),
        artShowItem: require('./images/template-images/art-show3.jpg'),
        galleryShowHome: require('./images/template-images/gallery1.jpg'),
        galleryShowCollection: require('./images/template-images/gallery2.jpg'),
        galleryShowItem: require('./images/template-images/gallery3.jpg'),
        printingFramingItem1: require('./images/template-images/template3a.jpg'),
        printingFramingItem2: require('./images/template-images/template3b.jpg'),
        printingFramingItem3: require('./images/template-images/template3c.jpg'),
        frame: require('./images/template-images/frame.jpg'),
        consulting: require('./images/template-images/consulting.jpg'),
    },
    artCollection: {
        img01: require('./images/art-collection/img-01.jpg'),
        img02: require('./images/art-collection/img-02.jpg'),
        img03: require('./images/art-collection/img-03.jpg'),
        img04: require('./images/art-collection/img-04.jpg'),
        img05: require('./images/art-collection/img-05.jpg'),
        img06: require('./images/art-collection/img-06.jpg'),
        img07: require('./images/art-collection/img-07.jpg'),
        img08: require('./images/art-collection/img-08.jpg'),
    }, 
    emptyMessage:  require('./images/notification/empty-message.png'),
    emptyNotification: require('./images/notification/empty-notification.png'),

} 