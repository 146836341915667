import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { _logger } from '../../helper/utils.helper';
import { AdminUsersListComponent } from './Users/Users.component'
import { AdminPendingUser } from './Users/PendingUser'
interface IPageProps extends RouteComponentProps {
    [key: string]: any
}
export const UsersPage = ({ history }: IPageProps) => {
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount at-admin-main scroll-style-3 at-contentarea-two at_pad_lr_0">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Users</h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling ">
                    <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                        <Tab label="Users">
                            <AdminUsersListComponent />
                        </Tab>
                        <Tab label="Pending Requests">
                            <AdminPendingUser />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </main>
    )
}