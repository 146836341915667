import moment from 'moment';
import React, { useState, FormEvent, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ETransactionStatus } from '../../../enums';
import { IShippingDetail, ITransaction } from '../../../interfaces'
import { ErrorComponent } from '../Error.component'
interface IProps {
    open: boolean
    onClose: Function
    onSubmit: Function
    transaction?: ITransaction
    isReturnArt?: boolean
}

export const ShippingPopup = ({ open, onClose, onSubmit, transaction, isReturnArt }: IProps) => {
    const [state, setState] = useState<IShippingDetail>({
        status: false,
        tracking_number: '',
        delivery_date: '',
        shipment_date: '',
        shipment_company: '',
        labelStatus: '',
    })
    const [errors, setErrors] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
      if(transaction){
        let shipment:any = transaction.shipment
          if(isReturnArt){
            shipment = transaction.return_art
            if (shipment && shipment.shipment_company){
                setState({
                    ...state,
                    shipment_company: shipment.shipment_company,
                    shipment_date: new Date(shipment.shipment_date),
                    delivery_date: new Date(shipment.delivery_date),
                    tracking_number: shipment.tracking_number
                })
            }
          } else if (transaction.shipment.status){
            setState({
                ...state,
                shipment_company: shipment.shipment_company,
                shipment_date: new Date(shipment.shipment_date),
                delivery_date: new Date(shipment.delivery_date),
                tracking_number: shipment.tracking_number
            })
          }
      }
    },[])

    const onChange = (e: FormEvent<HTMLInputElement> | any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleDate = (date: any, name: string) => {
        setState({
            ...state,
            [name]: date
        })
    }

    const onSubmittingData = () => {
        if (!validateData()) return
        let data: IShippingDetail = state
        data.status = true
        data.labelStatus = ETransactionStatus.SHIPPED
        onSubmit(data)
    }

    const validateData = () => {
        let {shipment_company, shipment_date, delivery_date, tracking_number} = state
        if (!shipment_company){
            let newErrors = errors
            newErrors['shipment_company'] = ['Please enter shipping company name']
            setErrors({...newErrors})
            return false
        }
       
        if (isNaN(moment(shipment_date).valueOf())){
            let newErrors = errors
            newErrors['shipment_date'] = ['Please select shipping date']
            setErrors({...newErrors})
            return false
        }
        if (isNaN(moment(delivery_date).valueOf())){
            let newErrors = errors
            newErrors['delivery_date'] = ['Please select delivery date']
            setErrors({...newErrors})
            return false
        }
        if(moment(delivery_date).valueOf() < moment(shipment_date).valueOf()){
            let newErrors = errors
            newErrors['delivery_date'] = ['Delivery date must be greated than shipment date']
            setErrors({...newErrors})
            return false
        }
        if (!tracking_number){
            let newErrors = errors
            newErrors['tracking_number'] = ['Please enter tracking number']
            setErrors({...newErrors})
            return false
        }
        setErrors({})
        return true
    }
    let customInput = <input type="text" className="form-control" />


    return (
        <div className={`at-thankyou-popup at-themepopupholder ${open && 'at-showthemesignuppopup'}`}>
            <div className="at-themepopupbox text-left">
                <a className="at-close cursor-pointer" onClick={() => onClose()} id="at-close"><i className="icon-cancel"></i></a>
                <div className="at-addactionholder">
                    <div className="at-formthemehead">
                        <h2>Enter Shipping Details</h2>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad-lr-7">
                            <div className="form-group">
                                <input type="text" name="shipment_company" value={state.shipment_company} className="form-control" placeholder="Shipping Company" onChange={(e: any) => onChange(e)} />
                            </div>
                            {errors && errors['shipment_company'] && <ErrorComponent errors={errors['shipment_company']} multiple={false} />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad-lr-7">
                            <div className="form-group at-inputwithicon">
                                <DatePicker name="shipment_date" customInput={customInput} selected={state.shipment_date} placeholderText="Shipping Date" onChange={(date: any) => handleDate(date, 'shipment_date')} />
                                <i className="icon-Calender"></i>
                            </div>
                            {errors && errors['shipment_date'] && <ErrorComponent errors={errors['shipment_date']} multiple={false} />}
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad-lr-7">
                            <div className="form-group at-inputwithicon">
                                <DatePicker customInput={customInput} name="delivery_date" placeholderText="Est. Delivery Date" selected={state.delivery_date} onChange={(date: any) => handleDate(date, 'delivery_date')} />
                                <i className="icon-Calender"></i>
                            </div>
                            {errors && errors['delivery_date'] && <ErrorComponent errors={errors['delivery_date']} multiple={false} />}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 col-sm-12 pad-lr-7">
                            <div className="form-group">
                                <input type="text" name="tracking_number" value={state.tracking_number} className="form-control" placeholder="Tracking Number" onChange={(e: any) => onChange(e)} />
                            </div>
                            {errors && errors['tracking_number'] && <ErrorComponent errors={errors['tracking_number']} multiple={false} />}
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="at-btn at-minwidth-100" onClick={() => onSubmittingData()}>{loading ? 'Please wait...' : 'Next'}</button>
                    </div>

                </div>
            </div>
        </div>
    )
}