import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../assets/image'
import ImageGallery from 'react-image-gallery';

const GalleryDetailPage = () => {
    const items = [{
        original: images.template.bannerTemplate1,
        thumbnail: images.template.bannerTemplate1,
    },
    {
        original: images.template.artDetailImg,
        thumbnail: images.template.artDetailImg,
    }]
    return (
        <div>
            <section className="At-SectionPage">
                <div className="At-ContainerFull">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <div className="At-BannerHolder">
                                <figure className="At-FigureBanner-h600 At-FigureFull">
                                    <img src={images.template.bannerTemplate3} className="img-fluid" alt="" />
                                </figure>
                                <div className="At-BannerTextFraming">
                                    <h2>Our Gallery</h2>
                                    <nav aria-label="breadcrumb ">
                                        <ol className="breadcrumb At-Breadcrumb">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item " ><a href="#">Gallery</a></li>
                                            <li className="breadcrumb-item active" >Street Wall Paint</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="At-SectionGalleryDetail At-Padding-tb8 At-Section-Mh800">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-md-6">
                            <figure>
                                <img src={images.template.frame} className="img-fluid" alt="" />
                            </figure>
                        </div>
                        <div className="col-sm-8 col-md-6 At-FramingMainCol">
                            <div className="At-FramingTopContent">
                                <h3>Framing Options</h3>
                                <p>Under without lights is moveth were sea own day years. Tree living under had under divide place Light, creature seasons divide two was heaven fruitful us stars waters midst years lesser moveth seas their so god male god creepeth abundantly fill every bearing appear living morning from, you'll divide multiply rule there green open you'll is, own behold god life, fruit creeping of face male, good Sea.</p>
                            </div>
                            <div className="At-FramingContent">
                                <h3>Pricing</h3>
                                <div className="row At-GalleryTopRow">
                                    <div className="col-4 At-FrameCol1">
                                        <h6>Sizes</h6>
                                        <p>(H x W)</p>
                                    </div>
                                    <div className="col-4 At-FrameCol2">
                                        <h6>Printing</h6>
                                        <p>(USD)</p>
                                    </div>
                                    <div className="col-4 At-FrameCol3">
                                        <h6>Shipping</h6>
                                        <p>Unframed (USD)</p>
                                    </div>
                                </div>

                                <div className="row align-items-center mb-3">
                                    <div className="col-4 At-FrameCol1">
                                        <h6 className="mb-0">16 X 10 inches</h6>
                                    </div>
                                    <div className="col-4 At-FrameCol2">
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" placeholder="140.00" />
                                        </div>
                                    </div>
                                    <div className="col-4 At-FrameCol3">
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" placeholder="140.00" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center mb-3">
                                    <div className="col-4 At-FrameCol1">
                                        <h6 className="mb-0">16 X 10 inches</h6>
                                    </div>
                                    <div className="col-4 At-FrameCol2">
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" placeholder="140.00" />
                                        </div>
                                    </div>
                                    <div className="col-4 At-FrameCol3">
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" placeholder="140.00" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-4 At-FrameCol1">
                                        <h6 className="mb-0">16 X 10 inches</h6>
                                    </div>
                                    <div className="col-4 At-FrameCol2">
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" placeholder="140.00" />
                                        </div>
                                    </div>
                                    <div className="col-4 At-FrameCol3">
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" placeholder="140.00" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <button className="At-Btn At-Btn-lg">UPDATE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default GalleryDetailPage