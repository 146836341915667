import React, { useState, useEffect } from 'react';
import { IUser } from '../../interfaces';
import { ErrorComponent } from './Error.component';

interface userAddress {
    shippingAddress: string
    city: string
    zipcode: string
    state: string,
}
interface IProps {
    onClose: Function
    onSubmit: Function
    user?: IUser
    inValidAddress?: boolean,
    showLoading?: boolean
}

export const AddressPopup = ({ onClose, onSubmit, user, inValidAddress, showLoading }: IProps) => {
    const [userState, setState] = useState<userAddress>({
        shippingAddress: '',
        city: '',
        zipcode: '',
        state: '',
    })
    const [isValidAddr, setValidAddress] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>({})
    const [loading, setLoading] = useState<any>(false)

    const onChange = (e: any) => {
        setState({
            ...userState,
            [e.target.name]: e.target.value
        })
    }

    const verifyAddress = () => {
        setLoading(true)
        let url = `https://zip.getziptastic.com/v2/US/${userState.zipcode}`
        fetch(url, { method: 'GET' }).then((res) => res.json()).then((res: any) => {
            if (res.city && res.state && res.country) {
                setState({
                    ...userState,
                    city: res.city,
                    state: res.state,
                })
                setValidAddress(true)
                setLoading(false)
                setErrors({})
            } else {
                setErrors({ ['zipcode']: ['Please enter valid zip code'] })
            }
        }).catch(err => {
            setErrors({ ['zipcode']: ['Please enter valid zip code'] })
        })
    }

    useEffect(() => {
        if (userState.zipcode.length === 5) {
            verifyAddress()
        }
        if (userState.zipcode.length < 5) {
            setValidAddress(false)
        }
    }, [userState.zipcode])

    useEffect(() => {
        if (user?.zipcode) {
            setState({
                ...userState,
                zipcode: user.zipcode,
                shippingAddress: user.address ? user.address : ''
            })
        }
    }, [])

    useEffect(() => {
        if (inValidAddress) {
            let newErrors: any = {}
            newErrors['shippingAddress'] = ['Please provide valid address without any special characters (% & * #)']
            setErrors({ ...newErrors })
        }
        // if (showLoading) {
        //     setLoading(showLoading)
        // }
    }, [inValidAddress])

    const onAdressSubmit = () => {
        if (!validateAddress()) {
            return
        }
        if (userState.zipcode.length < 5) {
            setErrors({ ['zipcode']: ['Please enter 5 digits zip code'] })
            return
        }
        onSubmit(userState)
    }

    const validateAddress = () => {
        let keys = Object.keys(userState)
        let state: any = userState
        let newErrors: any = {}
        keys.forEach((item: any) => {
            if (!state[item]) {
                newErrors[item] = [`Please enter valid ${item}`]
            }
        })
        if (Object.keys(newErrors).length > 0) {
            setErrors({ ...newErrors })
            return false
        }
        setErrors({})
        return true
    }


    return (
        <div className="at-themepopupbox text-left">
            <a className="at-close cursor-pointer" onClick={() => onClose()} id="at-close"><i className="icon-cancel"></i></a>
            <div className="at-addactionholder">
                <div className="at-formthemehead">
                    <h2>Enter Shipping Address</h2>
                </div>
                <div className="clearfix"></div>

                {isValidAddr && <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad-lr-7">
                        <div className="form-group at-floatlabel-h40">
                            <input type="text" name="shippingAddress" value={userState.shippingAddress} className="form-control" placeholder=" " onChange={(e: any) => onChange(e)} />
                            <label>Shipping address</label>
                            {errors && errors['shippingAddress'] && <ErrorComponent errors={errors['shippingAddress']} multiple={false} />}
                        </div>
                    </div>
                </div>}
                <div className="row">
                    {isValidAddr && <><div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pad-lr-7">
                        <div className="form-group at-floatlabel-h40">
                            <input type="text" disabled name="city" value={userState.city} className="form-control" placeholder=" " onChange={(e: any) => onChange(e)} />
                            <label>City</label>
                        </div>
                    </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pad-lr-7">
                            <div className="form-group at-floatlabel-h40">
                                <input type="text" disabled name="state" value={userState.state} className="form-control" placeholder=" " onChange={(e: any) => onChange(e)} />
                                <label>State</label>
                            </div>
                        </div> </>}
                    <div className="col-xs-12 col-sm-12 pad-lr-7">
                        <div className="form-group at-floatlabel-h40">
                            <input type="text" name="zipcode" value={userState.zipcode} className="form-control" placeholder=" " onChange={(e: any) => onChange(e)} />
                            <label>Zipcode</label>
                            {errors && errors['zipcode'] && <ErrorComponent errors={errors['zipcode']} multiple={false} />}
                        </div>
                    </div>
                    <div className="col-sm-12 pad-lr-7">
                        <div className="form-group mb-0">
                            <button type="submit" disabled={loading || showLoading} className="at-btn at-longbtn-h40" onClick={() => onAdressSubmit()}>{loading || showLoading ? 'Please wait...' : 'Next'}</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}