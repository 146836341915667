import React, { useRef } from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css';
interface IProps {
    children: any,
    onChangeSwiperIndex: Function
}

export const CustomSwiper = ({ children, onChangeSwiperIndex }: IProps) => {

    const params = {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        allowTouchMove: false,
     
        on: {
            activeIndexChange: (e: any) => onChangeSwiperIndex(e)
        }
    }


    return (
        <div>
            <Swiper {...params}>
                {children}
            </Swiper>
        </div>

    )
}