import { AxiosError, AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BuyerGalleryViewComponent } from '../../components/ArtCentral/GalleryWalk/BuyerGalleryView.Component'
import { GalleryWalkComponent } from '../../components/ArtCentral/GalleryWalk/GalleryWalk.Component'
import { EGalleryWalkFiltered } from '../../enums'
import { IResponse } from '../../interfaces/db.interface'
import { IGalleryDocument } from '../../interfaces/Gallery-walk.interface'
import { galleryWalkService, spaceService } from '../../services'
import { userSelector } from '../../store/selectors'

interface IProps {
    onClickOnGalleryWalk: (g: any) => any
}
const BuyerGalleryWalk = ({ onClickOnGalleryWalk }: IProps) => {

    const history = useHistory()
    const user = useSelector(userSelector)

    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [query, setQuery] = useState<string>('')
    const [filtered, setFiltered] = useState<boolean>(false)
    const [galleryWalks, setGalleryWalks] = useState<Array<IGalleryDocument>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [spaceType, setSpaceType] = useState<string>(EGalleryWalkFiltered.HAPPENING_NOW)
    const fetchGalleryWalks = (s: string, search: string = '', filter: boolean = false) => {
        setLoading(true)
        galleryWalkService.getGalleryWalks({ timeString: s, filtered: filter, query: search }).then((res: AxiosResponse<IResponse<Array<IGalleryDocument>>>) => {

            setGalleryWalks([...res.data.data])
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
        })
    }

    const onChangeSpace = (e: any) => {
        setSpaceType(e.target.value)
        fetchGalleryWalks(e.target.value)
    }

    const openSearch = () => {
        setShowSearch(true)
    }

    const closeSearch = () => {
        setQuery('')
        setShowSearch(false)
        fetchGalleryWalks(spaceType, '', filtered)
    }

    const onSearch = (e: any) => {
        setQuery(e.target.value)
        fetchGalleryWalks(spaceType, e.target.value, filtered)
    }

    const onToggleFilter = (value: boolean) => {
        if (value) {
            setFiltered(true)
        } else {
            setFiltered(false)
        }
        fetchGalleryWalks(spaceType, query, value)
    }

    useEffect(() => {
        fetchGalleryWalks(spaceType)
    }, [])

    return (
        <div id="feed-scroll" className={`at-posts at-themescrollbar scrollable scrollbar-hidden`}>
            <div className="at-createcollectionholder ">
                <div className="at-themeheader-outer">
                    <div className="at-themehead">
                        {/* <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a> */}
                        <h3>Gallery Walks & Events</h3>
                        <div className="form-group at-artcollectionselect">
                            <span className="at-select at-floatlabel-h40">
                                <select className="form-control floating-select" value={spaceType} name="" onChange={onChangeSpace} >
                                    {/* <option value=''>Select Spaces</option> */}
                                    <option value={EGalleryWalkFiltered.HAPPENING_NOW}>Happening Now</option>
                                    <option value={EGalleryWalkFiltered.COMMING_SOON}>Coming Soon</option>
                                    <option value={EGalleryWalkFiltered.PAST}> Past Gallery Walks</option>
                                </select>
                            </span>
                        </div>

                        <div className="at-selectfilterwrap">
                            {showSearch && <a className="at-left-arrowicon" onClick={() => closeSearch()}><i className="icon-left-arrow"></i></a>}
                            {!showSearch && <a className="at-filtericon cursor-pointer" onClick={() => openSearch()}><i title="Search" className="icon-search"></i></a>}
                            <a className={`at-filtericon cursor-pointer mr-2 ${filtered && 'text-orange'}`} onClick={() => onToggleFilter(!filtered)}><i title="Filter Preferred" className="icon-filter"></i></a>
                            <h2>
                                <div className={`from-group At-MarginTopSearchMinus dn ${showSearch && 'display'}`}>
                                    <div className="input-group">
                                        {loading && <div className="input-group-prepend">
                                            <span className="input-group-text search-field" id="basic-addon1"><i className="fa fa-spinner"></i></span>
                                        </div>}
                                        <input type="search" value={query} className="form-control" placeholder="Search" onChange={(e: any) => onSearch(e)} />
                                    </div>
                                </div>
                                {/* {!showSearch ? 'Home Screen' : ''} */}
                            </h2>
                        </div>
                    </div>
                </div>

                {/* {
                        (spaces && spaces.length > 0 && spaces.map((s: ISpace) => {
                            return s && s.userId && s.userId._id ? <GalleryWalkComponent onClickOnFeed={onClickOnFeed} space={s} /> : null
                        }))
                    } */}
                {
                    galleryWalks && galleryWalks.length > 0 && galleryWalks.map((g: IGalleryDocument) => <BuyerGalleryViewComponent fetch={() => fetchGalleryWalks(spaceType, query, filtered)} onClickOnGalleryWalk={onClickOnGalleryWalk} gallery={g} user={user} />)
                }
                {
                    (galleryWalks && galleryWalks.length === 0 && !loading) && <div className="text-center  mt-5 mb-5">
                        <h4>No Active Space Found! </h4>
                    </div>
                }


            </div>
        </div>
    )
}

export default BuyerGalleryWalk