import {APP} from "../../types";

export const SAVE_USER = `${APP}:USER:SAVE`;
export const ADD_USER_TO_SPACE = `${APP}:ADD USER TO SPACE`;
export const LIKE_POST = `${APP}:POST:LIKE`;
export const UNLIKE_POST = `${APP}:POST:UNLIKE`;
export const LIKE_USER = `${APP}:USER:LIKE`;
export const UNLIKE_USER = `${APP}:USER:UNLIKE`;
export const REFRESH_USER = `${APP}:USER:REFRESH`;
export const UPDATE_USER_DETAILS = `${APP}:USER:UPDATE:DETAILS`;
