import { Subject } from 'rxjs'

export enum ECommunicationMessages {
	OPEN_SPACE_ADD_FORM = 'OPEN_SPACE_ADD_FORM',
	OPEN_HUB_ADD_FORM = 'OPEN_HUB_ADD_FORM',
}

export interface ICommunication {
	type: ECommunicationMessages
	payload: any
}

export const communicationService = (() => {
	const subject = new Subject<ICommunication>()
	return {
		setData: (d: ICommunication) => subject.next(d),
		clearData: () => subject.next(),
		getData: () => subject.asObservable(),
	}
})()
