import React, { Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { AxiosResponse, AxiosError } from 'axios';
import { connect } from 'react-redux';
import { UNAUTHORIZED } from 'http-status';
import { IStoreActionsStates, IAccount, } from '../../interfaces'
import { CUser } from "../../classes";
import { toastService, authService } from '../../services'
import { routes } from '../../router'
import { images } from '../../assets/image'
import HeaderSimpleComponent from '../HeaderSimple.component'
import { loginAction, socialLoginAction } from "../../store/actions/auth.action";
import { saveUser } from '../../store/actions';
import { dispatch } from 'rxjs/internal/observable/pairs';


interface IProps extends IStoreActionsStates, RouteComponentProps {
	onClose: any
	openSignup: any
	socialLoginAction: any
	openForgotPassword: any
	[key: string]: any
}

interface IPageState {
	username: string
	password: string
	remember: boolean
	signingIn: boolean
}

class AdminLogin extends Component<IProps> {
	state: IPageState = {
		username: '',
		remember: false,
		password: '',
		signingIn: false
	};

	handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// this.props.history.push('/home')
		const { username, password } = this.state;
		this.setState({ signingIn: true });
		authService.login({ username, password }).then(this.handleSuccess, this.errorHandler)
	}
	handleSuccess = (response: AxiosResponse<IAccount>) => {
		const user: CUser = new CUser(response.data.user);
		if (!response.data.user.isAdmin) return
		toastService.success(`Welcome to admin panel`)
		this.setState({ signingIn: false });
		this.props.loginAction({ ...response.data, remember: this.state.remember, isAdmin: true });
		this.props.saveUser(response.data.user);
		this.props.history.replace(routes.adminRoutes.index)
	}

	errorHandler = ({ response }: any) => {
		this.setState({ signingIn: false });
		switch (response.status) {
			case UNAUTHORIZED:
				toastService.error('Incorrect phone number or password!')
				break;
			default:
				break;
		}
	}

	onChangeText = (e: FormEvent<EventTarget>): void => {
		const { name, value } = (e.target as HTMLInputElement);
		this.setState({ [name]: value })
	}
	render() {
		const { username, remember, password, signingIn } = this.state
		return (
			<>
				<HeaderSimpleComponent />

				{/*Main Globe*/}
				<main id="at-main" className="at-main at-haslayout">
					<div className="at-loginsignupcontent">
						<div className="at-themecontentbox">
							<figure className="at-globeicon">
								<a >
									<img src={images.hubsAndSpaces.globeImg} alt="" />
								</a>
							</figure>

						</div>
					</div>
				</main>
				<div id="at-themesignuppopup" className="at-themepopupholder at-showthemesignuppopup">
					<div className="at-themepopupbox">
						<a className="at-close at-btncloselogin cursor-pointer" id="at-close" onClick={() => { this.props.history.push('/') }}><i className="icon-cancel"></i></a>
						<form className="at-formtheme at-formlogin" onSubmit={this.handleSubmit}>
							<fieldset>
								<h2>Admin</h2>
								<div className="form-group at-inputwithicon">
									<i className="icon-user"></i>
									<input type="text" name="username" value={username} className="form-control" placeholder="user name" onChange={this.onChangeText} />
								</div>
								<div className="form-group at-inputwithicon">
									<i className="icon-lock"></i>
									<input type="password" name="password" value={password} className="form-control" placeholder="Password" onChange={this.onChangeText} />
								</div>
								<div className="form-group">
									<div className="at-checkbox" onClick={() => this.setState({ remember: !remember })}>
										<input type="checkbox" name="remember" id="remember" checked={remember} />
										<label>Remember me</label>
									</div>
									<a className="at-btnforgetpassword" onClick={() => { }}>Forgot your password?</a>
								</div>
								<div className="form-group">
									<button type="submit" className="at-btn at-minwidth-100" disabled={signingIn}>{signingIn ? 'LOGGING IN...' : 'LOGIN'}</button>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</>
		)
	}
}

const mapDispatchToProps = {
	loginAction,
	saveUser,
	socialLoginAction
}

export default connect(null, mapDispatchToProps)(AdminLogin)