import React, { FormEvent, useState } from 'react'
import PinField from 'react-pin-field'
import PhoneInput from 'react-phone-number-input'
import * as bcrypt from 'bcryptjs'
import { ErrorComponent } from '../../../components/common/Error.component'
import { BSLoading } from '../../../components/common/Loading.component'
import { ISignupState, IUser, IVerifyPhoneResponse } from '../../../interfaces'
import { getInitialsAsBlob, _logger } from '../../../helper'
import { authService, toastService } from '../../../services'
import { AxiosResponse, AxiosError } from 'axios'
import { CUser } from '../../../classes'
import { useDispatch } from 'react-redux'
import { loginAction, saveUser } from '../../../store/actions'
import { validationErrors } from '../../../shared'
import { BAD_REQUEST, CONFLICT } from 'http-status'

interface IProps {
    onClose: Function
    openSignin: Function
}

const initialState: ISignupState = {
    errors: null,
    loading: false,
    questions: [],
    questionTab: 1,
    verifying: false,
    codeSent: false,
    phoneVerified: false,
    isValidZip: false,
    user: {
        firstName: '',
        lastName: '',
        phoneNumber: '+1',
        password: '',
        confirmPassword: '',
        isDeaf: false,
        question1: '',
        answer1: '',
        question2: '',
        answer2: '',
        provider: '',
        roles: [],
        gallery_name: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        selling: ''
    },
    zipLoading: false
};
export const TemplateSignup = (props: IProps) => {

    const [verifyKey, setVerifyKey] = useState<string>('')
    const dispatch = useDispatch()
    const [state, setState] = useState<ISignupState>({ ...initialState })
    const [errors, setErrors] = useState<any>()


    const toggleLoading = (): void => {
        setState({ ...state, loading: !state.loading })
    }
    const onChangeText = (e: FormEvent<HTMLInputElement> | any): void => {
        const { name, value } = e.target;
        _logger('', {
            name, value
        });

        const { user } = state;
        const _user = { ...user, [name]: value };
        setState({ ...state, user: _user })
    }

    const verifyPhone = () => {
        setState({ ...state, verifying: true, codeSent: false })
        authService.verifyPhone({ phoneNumber: state.user.phoneNumber }).then((response: AxiosResponse<IVerifyPhoneResponse>) => {
            setVerifyKey(response.data.id);
            // toastService.success(response.data.message)
            setState({ ...state, verifying: false, codeSent: true })
            // setState({ ...state, verifying: false })
        }, (error: AxiosError) => {
            setState({ ...state, verifying: false })
            toastService.error(error.response?.data.message)
        })
    }

    const verifyPinCode = (value: any) => {
        if (value.length < 4) return;
        const compare = bcrypt.compareSync(`${value}`, verifyKey);
        if (!compare) return toastService.error('Incorrect pin')
        setState({ ...state, phoneVerified: true })
    }

    const submit = (e: FormEvent): void => {
        e.preventDefault();
        let { user, phoneVerified, errors } = state;
        let newUserData = user
        newUserData['approve_status'] = 'approved'
        if (!phoneVerified) return toastService.info('Phone number is not verified.');
        if (user && user.provider) {
            const password = `66*&687@jkHKJ${(new Date()).getTime()}`;
            user.password = password;
            user.confirmPassword = password
        }
        toggleLoading();
        authService.signup(newUserData).then(successHandler, errorHandler)
    }

    const successHandler = async (response: any) => {
        let newUser: IUser = response.data.user
        await setState({ ...state, user: response.data.user })
        updatePicture(newUser._id)
        toggleLoading();
        toastService.success(response.data.message);
        const user: CUser = new CUser(response.data.user);
        toastService.info(`Welcome, ${user.fullName}`)
        dispatch(loginAction(response.data))
        dispatch(saveUser(response.data.user))

    }

    const errorHandler = ({ response }: any) => {
        let errors = null;
        toggleLoading();
        switch (response.status) {
            case BAD_REQUEST:
                errors = validationErrors(response.data);
                break;
            case CONFLICT:
                errors = response.data
                toastService.error(response.data.message)
                break;
            default:
                break;
        }
        setState({ ...state, errors })
    }

    const updatePicture = async (id: any) => {
        const { firstName, lastName, phoneNumber, question1, question2, answer1, answer2, isDeaf, _id, city, address, zipcode, } = state.user;

        // let data: any = {
        //     firstName, lastName, phoneNumber, question1, question2, answer1, answer2, isDeaf, _id, city, address, state, zipcode
        // }
        const formData = new FormData()
        // for (let key in data) {
        //     formData.append(key, data[key]);
        // }

        const blob = await getInitialsAsBlob(firstName, lastName)

        formData.append('file', blob, `${firstName}_${lastName}_${Date.now()}.jpg`);

        authService.createUserImage(id, formData).then()
    }

    const errorElement = (key: string) => (
        state.errors && state.errors[key] && <ErrorComponent errors={state.errors[key]} multiple={false} />
    )

    let { user, verifying, codeSent, phoneVerified, loading } = state
    return (
        <div className="at-themepopupbox">
            <a className="at-close at-btnclosesignup" id="at-close" onClick={() => props.onClose()}><i className="icon-cancel"></i></a>
            <form className="at-formtheme at-signupform" onSubmit={submit}>
                <fieldset>
                    <h2>New User</h2>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group at-floatlabel-h40">
                                <input autoComplete="off" type="text" name="firstName" className="form-control" value={user.firstName} placeholder=" " onChange={onChangeText} />
                                <label>First name</label>
                                {errorElement('firstName')}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group at-floatlabel-h40">
                                <input autoComplete="off" type="text" name="lastName" className="form-control" value={user.lastName} placeholder=" " onChange={onChangeText} />
                                <label>Last name</label>
                                {errorElement('lastName')}
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group mb-0 d-flex align-items-center nh-input-verify">
                                <PhoneInput
                                    placeholder="Phone (e.g., +1 412 877 4312)"
                                    value={user.phoneNumber}
                                    defaultCountry="US"
                                    onChange={(phoneNumber: any) => setState({ ...state, phoneVerified: false, codeSent: false, user: { ...user, phoneNumber } })} />

                                {!verifying ? (
                                    !phoneVerified ? <button onClick={() => verifyPhone()} className="at-btn at-minwidth-78 ">{codeSent ? 'RESEND' : 'VERIFY'}</button> : <span className="icon-tick at-verify-tick"></span>
                                ) : <BSLoading />}
                            </div>
                            {errorElement('phoneNumber')}
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                {(codeSent && !phoneVerified) && <>
                                    <PinField type="number" length={4} validate={/^[0-9]$/} onChange={verifyPinCode} className="form-control mt-3" />
                                    <p className="at-text-verify-danger">Please enter the 4-digit code sent to you via text</p>
                                </>}
                            </div>
                        </div>
                        <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 ${user.provider && 'display-none'}`}>
                            <div className="form-group at-floatlabel-h40">
                                <input autoComplete="off" type="password" name="password" className="form-control" placeholder=" " onChange={onChangeText} />
                                <label>Password</label>
                                {errorElement('password')}
                            </div>
                        </div>
                        <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 ${user.provider && 'display-none'}`}>
                            <div className="form-group at-floatlabel-h40">
                                <input autoComplete="off" type="password" name="confirmPassword" className="form-control" placeholder=" " onChange={onChangeText} />
                                <label>Confirm Password</label>
                                {errorElement('confirmPassword')}
                            </div>
                        </div>
                        {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <ul className="nav nav-tabs at-themenavtabs at-questiontab" id="at-questiontab" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab1}`} id="questionone-tab" data-toggle="tab" onClick={() => this.setState({ questionTab: 1 })} role="tab" aria-controls="questionone" aria-selected="true"><span>Question 1</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tab2}`} id="questiontwo-tab" data-toggle="tab" onClick={() => this.setState({ questionTab: 2 })} role="tab" aria-controls="questiontwo" aria-selected="false"><span>Question 2</span></a>
                                        </li>
                                    </ul>
                                    <div className="at-tabcontent tab-content" id="myTabContent">
                                        { this.questionElement(1) }
                                        { this.questionElement(2) }
                                    </div>
                                </div> 
                        </div> */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                <button className="at-btn at-btnrecoveycode at-minwidth-100" disabled={loading} type="submit">{loading ? 'Please wait...' : 'SIGNUP'}</button>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                <em>Already have an account?<a className="at-gotologinfromsignup" onClick={() => props.openSignin()}>LOGIN</a></em>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}