import { AxiosResponse } from 'axios'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import RTSelect from 'react-select'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import DatePicker from "react-datepicker";
import LoadingComponent from '../../common/Loading.component'
import { SliderComponent } from '../../common/Slider.component'
import { bytesToMegaBytes, dataURLtoFile, _logger } from '../../../helper'
import { ICollectionItem, ISpace } from '../../../interfaces'
import { galleryWalkService, toastService } from '../../../services'
import { userSelector } from '../../../store/selectors'
import moment from 'moment';
import { ErrorComponent } from '../../common/Error.component';
import { UniversalModal } from '../../common/Modals/Universal.modal';
import { EScreens } from '../../../enums/screens.enum';
import { TutorialIcon } from '../../Tutorials/TutorialIcon';
import { IGalleryDocument, IGalleryState, IGalleryWalkSpaceState, initGallery } from '../../../interfaces/Gallery-walk.interface';
import { ERole } from '../../../enums';
import { UserSpacesList } from './UserSpacesList.component';
import { OVERLAY_CLASS } from '../../../constants';
import { countryZone, eventImages, postImage } from '../../../shared';
import { IResponse } from '../../../interfaces/db.interface';
import { routes } from '../../../router';
import { TmceEditor } from '../../common/TMCEditor.comopnent'


const initState = {
    name: '',
    startDate: null,
    endDate: null,
    items: [],
    description: '',
    images: [],
    pdf: null,
    poster: null
}

const initImageState = {
    preview: [],
    previewsCopy: [],
    selectedFiles: [],
    selectedFile: 0,
}


const CreateGalleryWalkPage = () => {
    const history = useHistory()
    const location = useLocation()
    const params = (useParams() as any).id
    const user = useSelector(userSelector)
    const [state, setState] = useState<IGalleryState>({ ...initGallery })
    const [loading, setLoading] = useState<boolean>(false)
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const imageRef = useRef<any>(null)
    const pdfRef = useRef<any>(null)
    const posterRef = useRef<any>(null)
    const [errors, setErrors] = useState<any>({})
    const [previewPoster, setPreviewPoster] = useState<Array<any>>([])
    const [showSpaces, setShowSpaces] = useState<boolean>(false)
    const [imageState, setImageState] = useState<any>({ ...initImageState })
    const [galleryWalks, setGalleryWalks] = useState<Array<IGalleryDocument>>([])
    const [deletedSpaces, setDeletedSpaces] = useState<Array<IGalleryWalkSpaceState>>([])


    const fetchGallery = () => {
        setLoading(true)
        galleryWalkService.getAllGalleryWalkById(params).then((res: AxiosResponse<IResponse<IGalleryDocument>>) => {
            let { data } = res.data
            setState({
                ...state,
                _id: data._id,
                id: data.id,
                name: data.name,
                startDate: new Date(data.startDate),
                endDate: new Date(data.endDate),
                startTime: new Date(data.startTime),
                endTime: new Date(data.endTime),
                description: data.description,
                images: data.images,
                pdf: data.pdf,
                isHide: data.isHide,
                isActive: data.isActive,
                poster: data.poster,
                timeZone: data.timeZone,
                galleryWalkSpaces: data.galleryWalkSpaces
            })
            setPreviewPoster([eventImages({}, res.data.data.poster)])
            const preview = ((res.data && res.data.data.images) || []).map((a: any) => ({ url: eventImages(res.data.data, a.url), isVideo: a.isVideo }))
            setImageState({ ...imageState, preview, previewsCopy: preview })
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const fetchGalleryWalks = () => {
        setLoading(true)
        galleryWalkService.getAllUserGalleryWalks().then((res: AxiosResponse<IResponse<Array<IGalleryDocument>>>) => {
            setGalleryWalks([...res.data.data])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const checkUser = () => {
        if (user && !user.roles.includes(ERole.PROMOTER)) {
            history.goBack()
        }
    }

    useEffect(() => {

        // checkUser()
        fetchGalleryWalks()
        if (params) {
            fetchGallery()
        }
    }, [])




    /* ===================================== Images Handling =========================================================== */
    const onChangeFiles = (e: any): void => {
        const { preview, selectedFile, selectedFiles } = imageState;
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    setImageState({
                        ...imageState,
                        preview: [...preview, ...images],
                        previewsCopy: [...preview, ...images],
                        selectedFiles: [...selectedFiles, ...files],
                        selectedFile: selectedFile ? selectedFile : 0
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onCrop = (image: any) => {
        let fileName
        let index = image.index
        if (params) {
            index = imageState.preview.length - image.index
            fileName = imageState.selectedFiles[index - 1].name
        } else {
            fileName = imageState.selectedFiles[index].name
        }
        // const fileName = imageState.selectedFiles[image.index].name
        const file = dataURLtoFile(image.cropped, fileName)
        const updatedFiles = [...imageState.selectedFiles]
        updatedFiles[index] = file
        const updatedPreviews = [...imageState.preview]
        updatedPreviews[image.index] = image.cropped
        setImageState({
            ...imageState,
            selectedFiles: updatedFiles,
            preview: updatedPreviews
        })
    }

    const onCropPoster = (image: any) => {
        const fileName = state.poster.name
        const file = dataURLtoFile(image.cropped, fileName)
        setState({
            ...state,
            poster: file,
        })

        setPreviewPoster([image.cropped])
    }

    const deleteImage = (): void => {

        let { preview, selectedFiles, selectedFile: i } = imageState;


        if (i > -1 && preview[i]) preview.splice(i, 1)
        let oldPlusNew = [...state.images, ...selectedFiles];
        oldPlusNew.splice(i, 1);
        state.images = oldPlusNew.filter((v: any) => v.proposal)
        const newAssets = oldPlusNew.filter((v: any) => !v.proposal)
        const selectedFile = i > 0 ? i - 1 : 0;

        setImageState({ ...imageState, preview, previewsCopy: preview, selectedFiles: newAssets, selectedFile })
        setState({ ...state })
    }


    const selectImages = () => {

        imageRef.current.click();
    }
    const selectPdf = () => {

        pdfRef.current.click();
    }
    const selectPoster = () => {

        posterRef.current.click();
    }

    const onChangePoster = (e: any, name: string) => {
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    if (name === 'poster') {
                        setPreviewPoster(images)
                    }
                    setState({
                        ...state,
                        [name]: files[0]
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onDeletePoster = () => {
        setState({
            ...state,
            poster: undefined
        })
        setPreviewPoster([])
    }

    const onChangeText = (e: FormEvent<EventTarget>) => {
        const { name, value } = (e.target as HTMLInputElement);
        setState({
            ...state,
            [name]: value
        })
    }

    const handleDate = (date: any, name: string) => {
        setState({
            ...state,
            [name]: date
        })
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (!onValidate()) return
        let galleryWalkSpaces = state.galleryWalkSpaces.map((i: any) => {
            i.space = i.space._id
            if (i.user) i.user = i.user?._id
            if (i.featuredArts && i.featuredArts.length > 0) {
                i.featuredArts = i.featuredArts.map((a: ICollectionItem) => a.id)
            }
            return i
        })
        let data = new FormData()
        imageState.selectedFiles.forEach((i: any) => data.append('files', i))
        data.append('name', state.name)
        data.append('startDate', JSON.stringify(state.startDate))
        data.append('endDate', JSON.stringify(state.endDate))
        data.append('startTime', JSON.stringify(state.startTime))
        data.append('endTime', JSON.stringify(state.endTime))
        data.append('description', state.description)
        data.append('poster', state.poster)
        data.append('pdf', state.pdf)
        data.append('galleryWalkSpaces', JSON.stringify(galleryWalkSpaces))
        data.append('timeZone', state.timeZone)
        if (deletedSpaces && deletedSpaces.length > 0) {
            let delArray = deletedSpaces.map((d: IGalleryWalkSpaceState) => d.id)
            data.append('deletedSpaces', JSON.stringify(delArray))
        }
        if (state.images && state.images.length > 0) data.append('images', JSON.stringify(state.images))

        setSubmitLoading(true)
        if (params) {
            galleryWalkService.updateGalleryWalk(params, data).then((res: AxiosResponse) => {
                if (res.data.success) {
                    toastService.success(res.data.message)
                    fetchGalleryWalks()
                    setState({ ...initGallery })
                    setImageState({ ...initImageState })
                    setPreviewPoster([])
                    history.push(routes.artCentral.gelleryWalk.create)
                } else {
                    toastService.error(res.data.message)
                }
                setSubmitLoading(false)
            }).catch((err: any) => {
                console.log(err)
                setSubmitLoading(false)
            })
            return
        }
        galleryWalkService.createGalleryWalk(data).then((res: AxiosResponse) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                setState({ ...initGallery })
                setImageState({ ...initImageState })
                setPreviewPoster([])
                fetchGalleryWalks()
                history.push(routes.artCentral.gelleryWalk.create)
            } else {
                toastService.error(res.data.message)
            }
            setSubmitLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setSubmitLoading(false)
        })
    }

    const onDeleteGalleryWalk = (g: IGalleryDocument) => {
        setLoading(true)
        galleryWalkService.deleteGalleryWalkById(g._id).then((res: AxiosResponse<IResponse<IGalleryDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetchGalleryWalks()
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setSubmitLoading(false)
            setLoading(false)
        })
    }

    const onValidate = () => {
        let { name, startDate, endDate, startTime, endTime, galleryWalkSpaces } = state
        let newErr: any = {}
        if (!name) {
            newErr['name'] = ['Name is required']
        }

        // if (!items || (items && items.length === 0)) {
        //     toastService.warning('You must add to the event at least one work of art from your collections.')
        //     newErr['items'] = ['Name is required']
        // }
        if (!startDate) {
            newErr['startDate'] = ['Start Date is required']
        }
        if (!endDate) {
            newErr['endDate'] = ['End Date is required']
        }
        if (!startTime) {
            newErr['startTime'] = ['Start Time is required']
        }
        if (!endTime) {
            newErr['endTime'] = ['End Time is required']
        }
        if (galleryWalkSpaces.length === 0) {
            toastService.warning('Add at least one space before updating.')
            return false
        }

        if (moment(startDate).valueOf() > moment(endDate).valueOf()) {
            newErr['endDate'] = ['End date must be greater than start date']
        }
        if (moment(startTime).valueOf() > moment(endTime).valueOf()) {
            newErr['endTime'] = ['End time must be greater than start date']
        }

        if (Object.keys(newErr).length > 0) {
            setErrors({ ...newErr })
            return false
        }
        setErrors({})
        return true

    }

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    const openSpacesModal = () => {
        setShowSpaces(true)
    }

    const closeSpaceModal = () => {
        setShowSpaces(false)
    }

    // const onSelectSpace = (s: ISpace) => {
    //     if (state.spaces.map((i: ISpace) => i._id).includes(s._id)) {
    //         let newArray = state.spaces.filter((i: ISpace) => i._id !== s._id)
    //         setState({
    //             ...state,
    //             spaces: newArray
    //         })
    //         return
    //     }
    //     let newArray = state.spaces
    //     newArray.push(s)
    //     setState({
    //         ...state,
    //         spaces: newArray
    //     })

    // }
    const onSelectSpace = (s: IGalleryWalkSpaceState) => {
        if (state.galleryWalkSpaces && state.galleryWalkSpaces.length > 0 && state.galleryWalkSpaces.map((i: IGalleryWalkSpaceState) => i.space._id).includes(s.space._id)) {
            let newArray = state.galleryWalkSpaces.filter((i: IGalleryWalkSpaceState) => i.space._id !== s.space._id)
            setState({
                ...state,
                galleryWalkSpaces: newArray
            })
            return
        }
        let newArray = state.galleryWalkSpaces
        newArray.push(s)
        setState({
            ...state,
            galleryWalkSpaces: newArray
        })

    }

    const onDeleteSpace = (s: IGalleryWalkSpaceState) => {
        let newSPaces = state.galleryWalkSpaces.filter((i: IGalleryWalkSpaceState) => i.space._id !== s.space._id)
        setState({
            ...state,
            galleryWalkSpaces: newSPaces
        })
        if (s && s._id) {
            let array = deletedSpaces
            array.push(s)
            setDeletedSpaces([...array])
        }
    }

    const onAddFeatureArts = (s: Array<IGalleryWalkSpaceState>) => {
        setState({
            ...state,
            galleryWalkSpaces: [...s]
        })
    }


    const [openTutorialBox, setTutorialBox] = useState<boolean>(false)
    const [openLinkModal, setLinkModal] = useState<boolean>(false)

    let customInput = <input type="text" autoComplete="off" className="form-control" />
    return (
        <div className="at-createcollection at-contentarea">
            <div className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Create Gallery Walk/Event <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.CREATE_EVENT} onClick={() => setTutorialBox(true)} /></span></h2>
                </div>
                <form className="at-formtheme at-formcreatecollection" onSubmit={onSubmit}>
                    <fieldset>
                        <div className="form-group form-w-100">
                            <div className="at-themefileupload">
                                <input type="file" name="poster" multiple accept="image/*" ref={posterRef} onChange={(e: any) => onChangePoster(e, 'poster')} id="at-uploadfile-3" />
                                <label onClick={selectPoster}>
                                    <span><i className="icon-gallery"></i>UPLOAD Gallery Walk/Event POSTER</span>
                                </label>
                            </div>
                        </div>

                        {previewPoster && previewPoster.length > 0 && <div className={`form-group form-w-100`}>
                            <SliderComponent items={previewPoster} originalItems={previewPoster} onCrop={onCropPoster} currentIndex={0} onChangeIndex={() => { }} />
                            {
                                previewPoster && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeletePoster()}></i>)
                            }
                            {/* <CropImageComponent image={preview[selectedFile]} /> */}
                        </div>}
                        <div className="form-group form-w-50">
                            <div className="at-themefileupload">
                                <input type="file" name="files[name]" multiple accept="image/*" ref={imageRef} onChange={onChangeFiles} id="at-uploadfile" />
                                <label onClick={selectImages}>
                                    <span><i className="icon-gallery"></i>UPLOAD Gallery Walk/Event Pictures</span>
                                </label>
                            </div>
                        </div>
                        <div className="form-group form-w-50">
                            <div className="at-themefileupload">
                                <input type="file" name="pdf" accept="application/pdf" ref={pdfRef} onChange={(e: any) => onChangePoster(e, 'pdf')} id="at-uploadfile-2" />
                                <label onClick={selectPdf}>
                                    {state.pdf && state.pdf !== "null" ? <span><i className="icon-tick  at-color-greenlight"></i>pdf uploaded</span> : <span><i className="icon-pdf-big"></i>Upload Gallery Walk/Event pdf</span>}
                                </label>
                            </div>
                        </div>
                        <div className={`form-group form-w-100`}>
                            <SliderComponent items={imageState.preview} originalItems={imageState.previewsCopy} onCrop={onCrop} currentIndex={imageState.selectedFile} onChangeIndex={(selectedFile: number) => setImageState({ ...imageState, selectedFile: selectedFile })} />
                            {
                                imageState.preview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={() => deleteImage()}></i>)
                            }
                            {/* <CropImageComponent image={preview[selectedFile]} /> */}
                        </div>
                        <div className="form-group form-w-100 at-formgroup-datepicker at-floatlabel">
                            <input autoComplete="off" type="text" name="name" value={state.name} className="form-control" placeholder=" " onChange={onChangeText} />
                            <label>Gallery Walk/Event Name</label>
                            {errorElement('name')}
                        </div>
                        <div className="form-group form-w-50 at-inputwithicon at-datepicker-zindex999">
                            <DatePicker name="startDate" autoComplete="off" customInput={customInput} selected={state.startDate} placeholderText="Start Date" onChange={(date: any) => handleDate(date, 'startDate')} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('startDate')}
                        </div>
                        <div className="form-group form-w-50 at-inputwithicon at-datepicker-zindex999">
                            <DatePicker name="endDate" autoComplete="off" popperPlacement="bottom-end" customInput={customInput} selected={state.endDate} placeholderText="End Date" onChange={(date: any) => handleDate(date, 'endDate')} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('endDate')}
                        </div>
                        <div className="form-group form-w-50 at-inputwithicon at-datepicker-zindex999">
                            <DatePicker
                                name="startTime"
                                autoComplete="off"
                                popperPlacement="bottom-end"
                                customInput={customInput}
                                selected={state.startTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                placeholderText="Start Time"
                                onChange={(date: any) => handleDate(date, 'startTime')} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('startTime')}
                        </div>
                        <div className="form-group form-w-50 at-inputwithicon at-datepicker-zindex999">
                            <DatePicker
                                name="endTime"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                autoComplete="off"
                                popperPlacement="bottom-end"
                                customInput={customInput}
                                selected={state.endTime}
                                placeholderText="End Time"
                                onChange={(date: any) => handleDate(date, 'endTime')} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('endTime')}
                        </div>
                        <div className="form-group at-assignselect">
                            <div className="at-customfont-holder">
                                <label className="at-color-grey" htmlFor="">Time Zone</label>
                                <RTSelect
                                    value={countryZone().find((i: any) => i.value === (state.timeZone.includes('/') ? state.timeZone.split('/')[1] : state.timeZone))}
                                    options={countryZone()}
                                    // defaultValue={countryZone().find((i: any) => i.value === state.timeZone)}
                                    onChange={(data: any) => setState({ ...state, timeZone: data.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group ">
                            <label className="text-left at-color-grey">Gallery Walk/Event Description</label>
                            {/* <textarea name="description" placeholder=" " value={state.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}

                            <TmceEditor text={state.description} onChange={(e: any) => setState({ ...state, description: e })} />

                        </div>
                        <div className="form-group form-w-100 text-right" onClick={() => openSpacesModal()}>
                            <a className="at-orangelink-borderbottom">Add spaces to Gallery Walk/Event</a>
                        </div>
                        <div className="form-group form-w-50 float-right">
                            <button type="submit" className="at-btn at-minwidth-100 " disabled={loading || submitLoading || (imageState.selectedFiles && imageState.selectedFiles.length === 0 && !params)}>{submitLoading ? 'Please wait...' : 'UPDATE'}</button>
                        </div>

                    </fieldset>
                </form>


                {galleryWalks && galleryWalks.length > 0 && <div className={`at-managepostholder ${loading && OVERLAY_CLASS}`}>
                    <div className="at-manageposttitle">
                        <h2>Manage Gallery Walks</h2>
                    </div>
                    <div className="row">
                        {
                            loading && <LoadingComponent />
                        }
                    </div>
                    <ul className="at-manageposts">
                        {
                            galleryWalks.map((c: IGalleryDocument) => {


                                return (
                                    <li key={c._id}>
                                        {c.poster && <figure><img src={postImage(c._id, c.poster)} alt="" /></figure>}
                                        <div className="at-managepostcontent">
                                            <span>{c.name}<em>{moment(c.startDate).format('MM/DD/YYYY')}</em></span>
                                            <div className="at-btniconholder">
                                                <button className="at-btndelete">
                                                    {
                                                        location.pathname !== `${routes.artCentral.post.edit}/${c._id}` && <Link to={`${routes.artCentral.gelleryWalk.create}/${c._id}`}>
                                                            <i className="icon-edit2 mr-2" title="Edit Post"></i>
                                                        </Link>
                                                    }
                                                    <i className="icon-delete2 text-danger" title="Delete Post" onClick={() => onDeleteGalleryWalk(c)}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>}

            </div>
            {showSpaces && <UniversalModal className="at-themepopupholder-plr0" open={showSpaces} onClose={closeSpaceModal}>
                <UserSpacesList onSelectSpace={onSelectSpace} selectedGallerySpaces={state.galleryWalkSpaces} onDeleteSpace={onDeleteSpace} onAddFeatureArts={onAddFeatureArts} />
            </UniversalModal>}

            {/* {openTutorialBox && <UniversalModal open={openTutorialBox} onClose={() => setTutorialBox(false)} position='bottom-0'>
                <ScreenTutorial screen={EScreens.CREATE_EVENT} />
                at-categorybox ends
            </UniversalModal>
            } */}

            {/* {openLinkModal && <UniversalModal open={openLinkModal} onClose={() => setLinkModal(false)} position='bottom'>
                <h5 className="at-popupheading">Share</h5>
            </UniversalModal>
            } */}
        </div>
    )

}

export default CreateGalleryWalkPage