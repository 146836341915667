import React, { useState, useEffect, useRef } from 'react'
import Video from 'react-video-renderer'
import { secondsToTime } from '../../shared'
import { isInViewport } from '../../helper';
interface IProps {
    url: string
    index?: number
    autoControls?: boolean
    autoPlay?: boolean
    className?: string
    id?: string
}
export const VideoComponent = ({ url, index, autoPlay, className, autoControls, id }: IProps) => {
    const [play, setPlay] = useState(autoPlay || false);

    const videoRef = useRef(null)

    useEffect(() => {
        const refPart = videoRef.current
        const isInside = isInViewport(refPart)
        isInside && setPlay(true)
        return () => {
            isInside || (play && setPlay(false))
        }
    }, [index])
    
    const playPause = (actions: any) => {
            if (play) {
                actions.pause();
                setPlay(false);
                return;
            }
            actions.play();
            setPlay(true);

    }
    return (
        <Video src={url} key={`video-index-${id}`} autoPlay={autoPlay || false} ref={videoRef}>
            {(video, state, actions) => (
                <div className={`select-video ${className || ''} ${play ? 'at-videoplay' : ''}`} onClick={() => playPause(actions)}>
                    {video}
                    <div className={state.isLoading ? 'blink_me' : ''}>{secondsToTime(Math.round(state.currentTime))} / {secondsToTime(Math.round(state.duration))}</div>
                    {
                        !play && <button type="button" className="videoButton text-white d-flex fs-20" onClick={() => playPause(actions)}><i className='icon-play-button'></i></button>
                    }
                    {
                        state.isLoading && <button type="button" className="videoButton no-bg text-white d-flex fs-20">
                            <div className="spinner-grow c-sunshade" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </button>
                    }
                </div>
            )}
        </Video>
    )
}
