import React, { useState } from 'react'
// import { LongPressAableComponent } from '../../common/LongPress.component'
import { LongPressDoubleClickComponent } from '../../common/LongPressDoubleClick'
import { IUser, IArtAssignment, IAssignmentCollection } from '../../../interfaces'
import { collectionImage } from '../../../shared'
import { UserNameComponent } from '../../User/index.component'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../router'

interface IProps {
    user: IUser
    assignment: IArtAssignment
    isSelected: boolean
    onLongPress: Function
    onClick: Function
    onMoveDownward?: any,
    onMoveUpward?: any,
    index?: any
    c?: IAssignmentCollection
}

export const AgentAssignmentComponent = ({ user, assignment, onClick, onLongPress, isSelected, index, onMoveUpward, onMoveDownward, c }: IProps) => {
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const history = useHistory()
    const item = assignment.art;
    const collection = assignment.art.art_collection;

    const isAdmin = user._id === (assignment.user as IUser)._id;

    const onClickButton = () => {
        let user = collection?.user;
        history.push(`${!user?.isAgent ? routes.artCentral.collection.item : routes.agent.showAssigment}/${user?.isAgent ? item.assignment : item.id}`);
    }
    return (
        <li key={item.id} className="itemImage">
            <LongPressDoubleClickComponent
                id={assignment.id}
                click={() => onClick(assignment)}
                {...(isAdmin ? {} : {})}
            >
                <div className="at-artgallerycollectionbox atv2-collectionbox">
                    <span className="at-checkbox" >
                        <img src={collectionImage(item, `high_${item.url}`, true)} alt={collectionImage(item, item.url || '', true)} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                        {user._id && (assignment.user as IUser)._id && <><input type="checkbox" name="select" checked={isSelected} id="artcollectionselectall" />
                            <label htmlFor="artcollectionone" title="Select" onClick={() => onLongPress({ id: assignment.id })}></label></>}

                        {showInfo && <div className="info-box text-capitalize">
                            <div>
                                ARTIST: <UserNameComponent user={collection.user} />
                            </div>
                            {assignment.type && <div>TYPE OF ASSIGNMENT: {assignment.type}</div>}

                            <div>ORIGINAL TERM: {assignment.period} Months</div>
                            <div>
                                ORIGINAL PAYOUT: {assignment.commission}%
                            </div>
                            <div>OTHER FORMAT TERM: {assignment.formatPeriod} Months</div>
                            <div>
                                OTHER FORMAT PAYOUT: {assignment.formatCommission}%
                            </div>
                            {assignment.originalResponsibility && <div>
                                ORIGINAL SHIPPING: {assignment.originalResponsibility}
                            </div>}
                            {assignment.formatResponsibility && <div>
                                OTHER FORMAT SHIPPING: {assignment.formatResponsibility}
                            </div>}
                        </div>}

                        <div className="at-clearfix"></div>
                        <div className="at-artdetail-box">
                            {item.name && <h5>
                                {item.name}
                            </h5>}
                            {item.artTechnique && item.height && item.width && <p> {item.height}" X {item.width}" {item.depth ? `X ${item.depth}"` : null}   {item.artTechnique}</p>}
                            <i className="icon-dotted-circle" title="Art Detail" onClick={() => onClickButton()}></i>
                        </div>

                        {user._id && (assignment.user as IUser)._id && c?.id && <>
                            <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowup" title="Sequence Art" onClick={() => onMoveUpward(index)}></i>
                            <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowdw" title="Sequence Art" onClick={() => onMoveDownward(index)} ></i>
                        </>}
                    </span>
                </div>
            </LongPressDoubleClickComponent>
            <i className="icon icon-info at-assign-btn at-btn-info" title="Show Info" onClick={() => setShowInfo(!showInfo)}></i>
        </li>
    )
}


// collection.items.map((item: ICollectionItem) => <ArtItemImageComponent user={user} collection={collection} item={item} isSelected={this.isSelected(item.id)} onClick={this.onClick} onLongPress={this.onLongPress}/>)