export * from './roles.enum'
export * from './space.enum'
export * from './environment.enum'
export * from './transaction.enum'
export * from './selling.enum'
export * from './assignAccount.enum'
export * from './template.enum'
export * from './services.enum'
export * from './gallery-walk.enum'
export * from './curotorial.enum'
export * from './shipping-factor.enum'
export enum EStatus {
    ART_PAYMENT_SUCCESSFULL = 'ART_PAYMENT_SUCCESSFULL',
    ART_PAYMENT_FAILED = 'ART_PAYMENT_FAILED'
}