import { AxiosResponse } from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { GalleryWalkComponent } from '../../components/ArtCentral/GalleryWalk/GalleryWalk.Component'
import { ERole } from '../../enums'
import { ISpace } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { IGalleryDocument, IGalleryWalkSpacesDocument } from '../../interfaces/Gallery-walk.interface'
import { galleryWalkService } from '../../services'

interface IProps {
    onClickOnFeed: (u: any) => any
    galleryWalkId?: string
    onClickOnGalleryWalkSpace: Function
    onGoBack: Function
}
export const BuyerGalleryWalkView = ({ onClickOnFeed, galleryWalkId, onClickOnGalleryWalkSpace , onGoBack}: IProps) => {

    const [galleryWalk, setGalleryWalk] = useState<IGalleryDocument>()
    const [loading, setLoading] = useState<boolean>(false)
    const fetchGalleryWalk = () => {
        if (!galleryWalkId) return
        setLoading(true)
        galleryWalkService.getAllGalleryWalkById(galleryWalkId).then((res: AxiosResponse<IResponse<IGalleryDocument>>) => {
            setGalleryWalk(res.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchGalleryWalk()
    }, [])
    
    return (
        <>
            <div className="at-themeheader-outer">
                <div className="at-themehead at-panelcollection-heading">
                    <a onClick={() => onGoBack()} className="at-left-arrowicon"><i className="icon-arrow-bold"></i></a>
                    <div className='at-panelcollection-text'>
                    {galleryWalk && galleryWalk.name && <h3 className='ml-2'>{galleryWalk.name}</h3>}
                    </div>
                </div>
            </div>
            <div className="at-grid">
                {galleryWalk && galleryWalk.galleryWalkSpaces && galleryWalk.galleryWalkSpaces.length > 0 && galleryWalk.galleryWalkSpaces.map((s: IGalleryWalkSpacesDocument) => {
                    if (s.user && s.user.roles && s.user.roles.includes(ERole.TESTER)) {
                        return null
                    }
                    return <GalleryWalkComponent space={s.space} onClickOnFeed={onClickOnFeed} onClickOnGalleryWalkSpace={() => onClickOnGalleryWalkSpace(s)} showArts={s.featuredArts && s.featuredArts.length > 0} />
                })}
            </div>
        </>
    )
}