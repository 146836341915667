import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import HomePage from './HomePage'
import GalleryPage from './GalleryPage'
import GalleryDetailPage from './GalleryDetailPage'
import { routes } from './routes'
import '../assets/templates.scss'
 
const MainRoute = () => {
    return (
        <div className="At-Theme">
            <Header />
            <Switch>
                <Route path={routes.home} exact component={HomePage} />
                <Route path={routes.viewGallery} exact component={GalleryPage} /> 
                <Route path={routes.viewGalleryDetail} exact component={GalleryDetailPage} /> 
            </Switch>
            <Footer />
        </div>
    )
}

export default MainRoute