import { IStoreAction } from '../../interfaces/store.interface'
import {
	FETCH_CONTACTS_REQUEST,
	FETCH_CONTACTS_SUCCESS,
	FETCH_CONTACTS_FAILURE,
} from '../types'
import { IContact } from '../../interfaces'
import { contactService } from '../../services'

export const fetchContactsRequest = (): IStoreAction => ({
	type: FETCH_CONTACTS_REQUEST,
})

export const fetchContactsSuccess = (payload: IContact[]): IStoreAction => ({
	type: FETCH_CONTACTS_SUCCESS,
	payload,
})

export const fetchContactsFailure = (payload: any): IStoreAction => ({
	type: FETCH_CONTACTS_FAILURE,
	payload,
})

export const fetchContacts = (): any => {
	return function (dispatch: Function) {
		dispatch(fetchContactsRequest())
		contactService.list().then(
			({ data: contacts }) => {
				dispatch(fetchContactsSuccess(contacts))
			},
			(rejection) => {
				dispatch(fetchContactsFailure(JSON.stringify(rejection)))
			}
		)
	}
}
