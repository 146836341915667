import React, { FormEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ErrorComponent } from './Error.component'
import PhoneInput from 'react-phone-number-input'
import { contactService, toastService } from '../../services'
import { AxiosResponse } from 'axios'
import { fetchContacts } from '../../store/actions'
import { IContact } from '../../interfaces'


interface IProps {
    addContact: boolean
    editContact: boolean
    close: Function
    contact?: IContact
}

const initialState = {
    firstName: '',
    lastName: '',
    code: '1',
    phoneNumber: '+1',
    _id: undefined,
}

const initialError = {
    firstName: '',
    lastName: '',
    code: '1',
    phoneNumber: {
        error: '',
        touched: false
    },
    _id: undefined,
}
export const ManageContacts = ({ addContact, editContact, close, contact }: IProps) => {

    const [state, setState] = useState({
        ...initialState
    })

    const [errors, setErrors] = useState({
        ...initialError
    })
    const dispatch = useDispatch()

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (contact && contact._id) {
            setState({
                ...state,
                firstName: contact.firstName,
                lastName: contact.lastName,
                phoneNumber: contact.phoneNumber,
                code: contact.code ? contact.code : '1',
                _id: contact._id ? contact._id as any : undefined
            })
        } else {
            setState({ ...initialState })
        }
    }, [contact])

    const onAddContact = (e: FormEvent) => {
        e.preventDefault()
        if (!validateForm()) return
        setLoading(true)
        contactService.add({ ...state }).then((res: AxiosResponse<any>) => {
            toastService.success(
                `${state.firstName} is added in your contact list`
            )
            dispatch(fetchContacts())
            close()
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onEditContact = (e: FormEvent) => {
        e.preventDefault()
        if (!validateForm()) return
        setLoading(true)
        contactService.udpate({ ...state }).then((res: AxiosResponse<any>) => {
            dispatch(fetchContacts())
            toastService.success(`${state.firstName} is updated`)
            close()
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onChangeText = (e: FormEvent<EventTarget>) => {
        const { name, value } = (e.target as HTMLInputElement);
        setState({
            ...state,
            [name]: value
        })
    }

    const validateForm = () => {
        if (!state.firstName) {
            setErrors({
                ...errors,
                firstName: 'First name is Required'
            })
            return false
        }
        if (!state.lastName) {
            setErrors({
                ...errors,
                lastName: 'Last name is Required'
            })
            return false
        }
        if (!state.phoneNumber) {
            setErrors({
                ...errors,
                phoneNumber: {
                    ...errors.phoneNumber,
                    error: 'Phone number is Required'
                }
            })
            return false
        }
        setErrors({ ...initialError })
        return true
    }
    return (
        <div
            id='at-themeaddusercontactpopup'
            className={`at-themepopupholder at-themeaddusercontactpopup ${(addContact || editContact) && 'at-showthemecreatecontactpopup'
                }`}
        >
            <div className='at-themepopupbox'>
                <a className='at-close' id='at-close' onClick={() => close()}>
                    <i className='icon-cancel'></i>
                </a>
                <form
                    onSubmit={addContact ? onAddContact : onEditContact}
                    className='at-formtheme at-formaddusercontact'
                >
                    <fieldset>
                        <div className='at-formthemehead'>
                            {addContact && <h2>Add Contact</h2>}
                            {editContact && <h2>Edit Contact</h2>}
                        </div>
                        <div className='at-formthemeholder'>
                            <div className='form-group at-floatlabel'>
                                <input
                                    type='text'
                                    name='firstName'
                                    className='form-control'
                                    placeholder=' '
                                    value={state.firstName}
                                    onChange={onChangeText}
                                />
                                <label>First Name</label>
                                <ErrorComponent
                                    errors={[errors.firstName]}
                                    multiple={false}
                                />
                            </div>
                            <div className='form-group at-floatlabel'>
                                <input
                                    type='text'
                                    name='lastName'
                                    className='form-control'
                                    placeholder=' '
                                    value={state.lastName}
                                    onChange={onChangeText}
                                />
                                <label>Last Name</label>
                                <ErrorComponent
                                    errors={[errors.lastName]}
                                    multiple={false}
                                />
                            </div>
                            <div className='form-group at-inputwithicon'>
                                <i className='icon-user'></i>
                                {/* <input type="text" name="username" value={username} className="form-control" placeholder="Phone number" onChange={onChangeText}/> */}
                                <PhoneInput
                                    placeholder='Phone (e.g., +1 412 877 4312)'
                                    value={state.phoneNumber}
                                    defaultCountry="US"
                                    onChange={async (phoneNumber: any) => {
                                        await setState({ ...state, phoneNumber })
                                        validateForm()
                                    }}
                                    onFocus={async () => {
                                        await setErrors({
                                            ...errors,
                                            phoneNumber: {
                                                ...errors.phoneNumber,
                                                touched: true
                                            }
                                        })
                                    }}
                                />
                                <ErrorComponent
                                    errors={[errors.phoneNumber.error]}
                                    multiple={false}
                                />
                            </div>
                            {/* <div className='form-group'>
										<span className='at-select'>
											<select
												name='code'
												value={code}
												onChange={onChangeText}
											>
												<option value='1'>UK (+44)</option>
												<option value='44'>Norway (+47)</option>
												<optgroup label='Other countries'>
													<option value='213'>Algeria (+213)</option>
													<option value='376'>Andorra (+376)</option>
												</optgroup>
											</select>
										</span>
									</div>
									<div className='form-group'>
										<input
											type='text'
											id='customer-phonenumber'
											name='number'
											className='form-control'
											placeholder='+92 311 444 7777'
											value={number}
											onChange={onChangeText}
										/>
									</div> */}
                            <div className='form-group'>
                                <button
                                    type='submit'
                                    className='at-btn'
                                    disabled={loading}
                                >
                                    {addContact && 'Add'}
                                    {editContact && 'Edit'}
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}