import React, {Component} from 'react';
import {images} from "../assets/image";
 
class HeaderSimpleComponent extends Component {
    render() {
        return (
            <header id="at-header" className="at-header at-headerone at-haslayout">
                <div className="at-navigationarea">
                    <strong className="at-logo">
                        <a >
                            <img src={images.logoOmniNew} alt="company logo here"/>
                        </a>
                     
                    </strong>
                </div>
            </header>
        );
    }
}

export default HeaderSimpleComponent;