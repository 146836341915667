import { AxiosError, AxiosResponse } from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ICuratorialInvitationDocument } from '../../../../../interfaces'
import { IResponse } from '../../../../../interfaces/db.interface'
import { curatorialService } from '../../../../../services'
import { ParticipantInvitationBox } from './ParticipantInvations.component'

const AccessCuratorialProjectComponent = () => {
    const [loading, setLoading] = useState<boolean>()
    const [invitations, setInvitations] = useState<Array<ICuratorialInvitationDocument>>([])
    const fetchInvitations = () => {
        setLoading(true)
        curatorialService.getInvitationsByUsers().then((res: AxiosResponse<IResponse<Array<ICuratorialInvitationDocument>>>) => {
            let reversedata = res.data.data.reverse()
            setInvitations([...reversedata])
        }).catch((err: AxiosError) => {
            setLoading(false)
            console.log(err)
        })
    }

    useEffect(() => {
        fetchInvitations()
    }, [])
    return (
        <div className="at-transaction-main">
            {invitations && invitations.length > 0 && invitations.map((i: ICuratorialInvitationDocument) => <ParticipantInvitationBox invitation={i} />)}
        </div>
    )
}

export default AccessCuratorialProjectComponent