import React, { useState } from 'react'
import { Elements, useElements, useStripe, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from '../../environment'
import { toastService } from '../../services'
import { ErrorComponent } from '../common/Error.component'


interface ICardProps {
    text: string,
    onClick: Function
    loading?: boolean
}
export const Card = ({ text, onClick, loading }: ICardProps) => {

    const [isLoad, setLoad] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>([])
    const stripe = useStripe()
    const elements = useElements()

    const CARD_OPTIONS: any = {
        hidePostalCode: true,
        showIcon: true,
        iconStyle: 'default',
        style: {
            base: {
                color: '#000000',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': { color: '#fce883' },
                '::placeholder': { color: '#87bbfd' },
                padding: '10px 10px'
            },
            invalid: {
                iconColor: '#dc3545',
                color: '#dc3545',
            },
        },
    };
    const OTHER_OPTIONS: any = {
        style: {
            base: {
                color: '#000000',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': { color: '#fce883' },
                '::placeholder': { color: '#87bbfd' },
                padding: '10px 10px'
            },
            invalid: {
                color: '#dc3545',
            },
        },
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setLoad(true)
        if (!stripe || !elements) {
            return;
        }
        const cardElement: any = elements.getElement(CardNumberElement);

        const { source, error } = await stripe.createSource(cardElement)
        setLoad(false)
        if (error) {
            console.log(error)
            let newError = [error.message]
            setErrors(newError)
        } else {
            onClick(source)
            setErrors([])
        }
        // const {paymentIntent, error} = await stripe.confirmCardPayment(stripeData.clientSecret,{
        //     payment_method: {
        //         card: cardElement,
        //       }
        // });
        // setLoad(false)
        // if (error){
        //  console.log(error)
        //  let newError = [error.message]
        //  setErrors(newError)
        // }else {
        //     toastService.success('Payment has been received successfully.')
        //   onConfirmPay()
        //   setErrors([])
        // }
    }

    return (
        <div>
            <div className="row row-margin-lr7">
                <div className="col-12 pad-lr-7">
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Name" />
                    </div>
                    <div className="form-group">
                        <CardNumberElement
                            id="cardNumber"
                            options={CARD_OPTIONS}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <CardExpiryElement
                            id="expiry"
                            options={OTHER_OPTIONS}
                        />
                    </div>
                </div>
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <CardCvcElement
                            id="cvc"
                            options={OTHER_OPTIONS}
                        />
                    </div>
                </div>
            </div>
            {errors && errors.length > 0 && <div className="mb-3"><ErrorComponent errors={errors} multiple={false} /></div>}

            <button className="at-btn at-longbtn-h40 mb-4" disabled={loading} onClick={handleSubmit}>{loading ? "Wait..." : text}</button>
        </div>
    )
}

interface IFormProps {
    text: string,
    onClick: Function,
    loading?: boolean
}
export const UpdateCardForm = ({ text, onClick, loading }: IFormProps) => {
    const stripePromise = loadStripe(STRIPE_KEY)
    return (
        <Elements stripe={stripePromise}>
            <Card loading={loading} text={text} onClick={onClick} />
        </Elements>
    )
}