import { HttpService } from './base.service'
import { data } from 'jquery';

class SubmissionProcessService extends HttpService {
    private readonly prefix: string = 'submission-process'
    /**
     * 
     * Create Application
     */
    submitApplication = (data: any): Promise<any> => this.post(`${this.prefix}/create`, data)

    /**
     * 
     * Fetch Applications
     */
    fetchApplications = (data: any): Promise<any> => this.get(`${this.prefix}/pending-application`, { search: data })

    /**
     * 
     * Fetch Applications
     */
    singleApplicationById = (id: any): Promise<any> => this.get(`${this.prefix}/application/${id}`,)

    /**
     * 
     * Approve Application
     */

    approveApplication = (data: any): Promise<any> => this.post(`${this.prefix}/approve`, data)
    /**
     * 
     * Reject Application
     */

    rejectApplication = (data: any): Promise<any> => this.post(`${this.prefix}/reject`, data)
}

export const submissionProcessService = new SubmissionProcessService();