import React, { Component } from 'react'
import { AxiosResponse } from 'axios'
import { connect } from 'react-redux'
import { offerService, toastService } from '../../../services'
import { IOffer, IStoreReducers, IUser } from '../../../interfaces'
import { socketNode } from '../../../services/socket.service'
import { OfferView } from './Offers.View'
import { actionMessage } from '../../../actions'
import * as type from '../../../constants/transactionTypes'
import { throws } from 'assert'

interface IProps {
    user?: IUser
    routeUser?: IUser
}

interface IState {
    offers: Array<IOffer>
    loading: boolean
    confirmApprove: boolean
    selectedOffer?: IOffer
    confirmReject: boolean
}
class TransactionsPage extends Component<IProps> {
    state: IState = {
        offers: [],
        loading: false,
        confirmApprove: false,
        confirmReject: false,
    }

    componentDidMount() {
        this.fetchOffers()
    }

    fetchOffers = () => {

        const { user, routeUser } = this.props
        let currUser = routeUser ? routeUser : user
        this.setState({ loading: true })
        offerService.getSellerOffersOffer(currUser?._id).then((res: AxiosResponse<Array<IOffer>>) => {
            this.setState({ offers: res.data.reverse(), loading: false })
        }).catch(error => {
            console.log(error)
            this.setState({ loading: false })
        })
    }


    getMessage = (offer: IOffer, action: string) => {
        let buyerName = `${offer.offeredBy.firstName} ${offer.offeredBy.lastName}`
        let sellerName = `${offer.offeredTo.firstName} ${offer.offeredTo.lastName}`
        let artName = `$`
        let getMessage = actionMessage(action, buyerName, sellerName, artName).message
        return getMessage
    }
    handleOfferState = (offer: IOffer, approved: boolean = false, reject: boolean = false) => {
        let { offers } = this.state
        let newOffers = offers
        newOffers.forEach((item: IOffer) => {
            if (item._id === offer._id) {
                item.approved = approved
                item.pending = false
                item.rejected = reject
            }
        })
        this.setState({ offers: newOffers })
    }

    // Offer approve handler
    onOfferApprove = (offer: IOffer) => {
        console.log(offer)
        this.setState({
            selectedOffer: offer,
            confirmApprove: true
        })
    }

    onConfirmApprove = () => {
        let { selectedOffer } = this.state
        let offer = (selectedOffer as IOffer)
        let message = this.getMessage(offer, type.ACCEPT_OFFER)
        this.handleOfferState(offer, true)
        let path = `art-central/${offer.offeredTo.isAgent ? 'assignment' : 'collection/item'}/${offer.itemId.id}?offer=${offer._id}`
        socketNode.emit(type.ACCEPT_OFFER, {
            from: offer.offeredTo._id,
            to: offer.offeredBy._id,
            message: message,
            itemId: offer.itemId.id,
            phone: offer.offeredTo.phoneNumber,
            offerID: offer._id,
            path
        })
        toastService.success("You have approved the offer successfully.")
        this.setState({selectedOffer:{}, confirmApprove: false})
    }

    onRejectOffer = (offer: IOffer) => {
        console.log(offer)
        this.setState({
            selectedOffer: offer,
            confirmReject: true
        })
    }

    onConfirmReject = () => {
        let { selectedOffer } = this.state
        let offer = (selectedOffer as IOffer)
        let message = this.getMessage(offer, type.REJECT_OFFER)
        this.handleOfferState(offer, false, true)
        let path = `art-central/${offer.offeredTo.isAgent ? 'assignment' : 'collection/item'}/${offer.itemId.id}?offer=${offer._id}`
        socketNode.emit(type.REJECT_OFFER, {
            from: offer.offeredTo._id,
            to: offer.offeredBy._id,
            message: message,
            itemId: offer.itemId.id,
            phone: offer.offeredTo.phoneNumber,
            offerID: offer._id,
            path
        })
        toastService.success("“You have rejected the offer successfully.")
        this.setState({selectedOffer:{}, confirmReject: false})
    }

    onConfirmApproveClose = () => this.setState({confirmApprove: false})

    onConfirmRejectClose = () => this.setState({confirmReject: false})


    getOfferStatus = (item: IOffer) => {
        if (item.approved) {
            return { status: 'approved', color: 'orange' }
        }
        if (item.pending) {
            return { status: 'pending', color: 'grey' }
        }
        if (item.rejected) {
            return { status: 'rejected', color: 'grey' }
        }
        return { status: 'pending', color: 'grey' }
    }


    render() {
        const { offers, confirmReject, confirmApprove, loading } = this.state
        // console.log(Object.keys(selectedItem))
        return (
            <>

                <OfferView
                    offers={offers}
                    getOfferStatus={this.getOfferStatus}
                    loading={loading}
                    onAccept={this.onOfferApprove}
                    showButton={true}
                    onReject={this.onRejectOffer}
                    confirmApprove = {confirmApprove}
                    confirmReject = {confirmReject}
                    onConfirmApprove = {this.onConfirmApprove}
                    onConfirmReject = {this.onConfirmReject}
                    onConfirmApproveClose={this.onConfirmApproveClose}
                    onConfirmRejectClose={this.onConfirmRejectClose}
                />
            </>
        )
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(TransactionsPage)
