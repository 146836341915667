import { HttpService } from "./base.service";
import { IUser } from "../interfaces";

class AssignAccountService extends HttpService {
    private readonly prefix: string = 'assign-account';

    /**
     * 
     *  Assign Account
     */
    assignAcount = (data: any): Promise<any> => this.post(`${this.prefix}/create`, data)

    /**
     * 
     *  Agent Assigned Account List
     */
    getAllAccounts = (): Promise<any> => this.get(`${this.prefix}/get-agent-list`)
    
    getAllAccountsWithoutlogin = (id: any): Promise<any> => this.get(`${this.prefix}/get-agent-list-withoutLogin/${id}`)

    /**
     * 
     *  Artist Assigned Account List
     */
    getAllArtistAccounts = (): Promise<any> => this.get(`${this.prefix}/get-artist-list`)

    /**
     * 
     * Accept Account
     */
    acceptAccount = (data: any): Promise<any> => this.post(`${this.prefix}/accept-account`, data)
    /**
     * 
     * Reject Account
     */
    rejectAccount = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/reject-account/${id}`, data)

    onAgentEndAssignment = (data: any): Promise<any> => this.post(`${this.prefix}/end-assignment/agent`, data)
    onArtistEndAssignment = (data: any): Promise<any> => this.post(`${this.prefix}/end-assignment/artist`, data)
}

export const assignAccountService = new AssignAccountService()