import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { EWeightFactorType } from '../../../enums'
import { IShippingFactorDocument, IWeihgtFactorsDocument } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { adminService, toastService } from '../../../services'
import LoadingComponent from '../../common/Loading.component'
import { UniversalModal } from '../../common/Modals/Universal.modal'
import { FactorNameComponent } from './Factor.component'
import { WeightFactorForm } from './WeightFactorForm.component'

interface IProps {
    shipping?: IShippingFactorDocument
    fetch: Function
    onSwap: Function
}

export const AdminWeightFactor = ({ shipping, fetch, onSwap }: IProps) => {

    const [type, setType] = useState<string>('')
    const [showForm, setShowForm] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const openForm = (t: string) => {
        setType(t)
        setShowForm(true)
    }
    const closeForm = () => {
        setType('')
        setShowForm(false)
    }
    const submitForm = () => {
        closeForm()
        fetch()
    }

    const onSwipe = (list: Array<string>, name: string) => {
        setLoading(true)
        adminService.udpateFactorSequence({ [name]: list }).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetch()
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }

    const onFactorDown = (index: number, factors: Array<IWeihgtFactorsDocument>, varName: string) => {
        if (!shipping) return
        if ((index + 1) === factors.length) {
            toastService.error("Unable to move the Factor Downword")
            return
        }


        let data = factors
        let temp = data[index]
        data[index] = data[index + 1]
        data[index + 1] = temp

        let newData = data.map((d: IWeihgtFactorsDocument) => d._id)
        onSwipe(newData, varName)
        // let newShip: any = shipping
        // newShip[varName] = data
        // onSwap(newShip)
    }

    const onFactorUp = (index: number, factors: Array<IWeihgtFactorsDocument>, varName: string) => {
        if ((index - 1) != -1) {

            let data = factors
            let temp = data[index]
            data[index] = data[index - 1]
            data[index - 1] = temp
            let newData = data.map((d: IWeihgtFactorsDocument) => d._id)
            onSwipe(newData, varName)
            // let newShip: any = shipping
            // newShip[varName] = data
            // onSwap(newShip)
        } else {
            toastService.error("Unable to move the Factor Upword")
        }
    }


    return <div className="at-createservice-innertab at-innertab-mlr-minus20">
        <div className="at-themebody">
            <div className="at-categorybox">
                <div className="at-categoryhead">
                    <h4 className="at-additional-heading">Art</h4>
                    <button className="at-btnadd-relative c-sunshadebg" type="button" onClick={() => openForm(EWeightFactorType.ART)}>
                        <i className="icon-add-bold"></i>
                    </button>
                </div>
                {shipping && shipping.arts && shipping.arts.length > 0 && shipping.arts.map((s: IWeihgtFactorsDocument, index: number) => <FactorNameComponent fetchFactors={fetch} s={s} onFactorDown={() => onFactorDown(index, shipping.arts, 'arts')} index={index} onFactorUp={() => onFactorUp(index, shipping.arts, 'arts')} updating={loading} />)}
            </div>

            <div className="at-categorybox">
                <div className="at-categoryhead">
                    <h4 className="at-additional-heading">Frame</h4>
                    <button className="at-btnadd-relative c-sunshadebg" type="button" onClick={() => openForm(EWeightFactorType.FRAME)}>
                        <i className="icon-add-bold"></i>
                    </button>
                </div>
                {shipping && shipping.frames && shipping.frames.length > 0 && shipping.frames.map((s: IWeihgtFactorsDocument, index: number) => <FactorNameComponent fetchFactors={fetch} s={s} onFactorDown={() => onFactorDown(index, shipping.frames, 'frames')} index={index} onFactorUp={() => onFactorUp(index, shipping.frames, 'frames')} />)}
            </div>

            <div className="at-categorybox">
                <div className="at-categoryhead">
                    <h4 className="at-additional-heading">Packaging</h4>
                    <button className="at-btnadd-relative c-sunshadebg" type="button" onClick={() => openForm(EWeightFactorType.PACKAGE)}>
                        <i className="icon-add-bold"></i>
                    </button>
                </div>
                {shipping && shipping.packages && shipping.packages.length > 0 && shipping.packages.map((s: IWeihgtFactorsDocument, index: number) => <FactorNameComponent fetchFactors={fetch} s={s} onFactorDown={() => onFactorDown(index, shipping.packages, 'packages')} index={index} onFactorUp={() => onFactorUp(index, shipping.packages, 'packages')} />)}
            </div>
        </div>

        {showForm && type && <UniversalModal open={showForm} onClose={closeForm}>
            <WeightFactorForm onClose={closeForm} factoryType={type} onSubmit={submitForm} />
        </UniversalModal>}

        {loading && <LoadingComponent className='at-transaction-loader' />}
    </div>
}
