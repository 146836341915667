import { IUser } from './../interfaces'
export class CUser {
    firstName: string = '';
    lastName: string = '';

    constructor(user: IUser) {
        Object.assign(this, user);
    }

    get fullName (): string {
        return `${this.firstName} ${this.lastName}`
    }
}