import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IPost, ICollectionItem, ICollection, IStoreReducers, IUser, IArtAssignment, ICommonHttpResponse, IOffer } from '../../../interfaces';
import { AxiosResponse, AxiosError } from 'axios';
import LoadingComponent, { BSLoading } from '../../../components/common/Loading.component';
import { collectionService } from '../../../services/collection.service';
import { connect } from 'react-redux';
import { ConfirmAlertComponent } from '../../../components/common/ConfirmAlert.component';
import { toastService, assignmentService, offerService } from '../../../services';
import { locale, lang } from '../../../locale';
import CollectionItemViewComponent from '../../../components/ArtCentral/collection/ItemView.component';
import { routes } from '../../../router';

interface IPageProps extends RouteComponentProps<{ id: string }> {
    user: IUser
}

interface IPageState {
    item?: IArtAssignment
    loading: boolean
    onAccept: boolean
    onReject: boolean
    loadingButton: boolean
    offer?: IOffer
}
class AgentAssignmentDetailsView extends Component<IPageProps> {
    state: IPageState = {
        loading: false,
        onAccept: false,
        onReject: false,
        loadingButton: false
    }

    get query (){
        return this.props.location.search
    }

    componentDidMount() {
        this.fetch();
        if(this.query.includes("offer")){
            this.fetchOffer()
          }
    }

    toggleLoading = (): void => {
        this.setState({ loading: !this.state.loading, loadingButton: false })
    }

    get params() {
        return this.props.match.params;
    }

    fetchOffer = () => {
        let query = new URLSearchParams(this.props.location.search).get("offer")
        offerService.getSingleOffer(query).then((res: AxiosResponse<Array<IOffer>>) => {
            this.setState({offer: res.data[0]})
        })
      }

    fetch = (): void => {
        this.toggleLoading();
        assignmentService.show(this.params.id).then((response: AxiosResponse<ICollectionItem>) => {
            this.setState({ item: response.data })
            this.toggleLoading();
        }, (error: AxiosError) => {
            this.toggleLoading();
            this.props.history.goBack();
        })
    }

    reject = () => this.setState({ onAccept: false, onReject: true })
    accept = () => this.setState({ onAccept: true, onReject: false })

    confirmAction = async () => {
        const { loading, item: assignment, onAccept, onReject } = this.state;
        this.setState({ loadingButton: true })
        const response: ICommonHttpResponse = (await assignmentService[onAccept ? 'accept' : 'reject'](assignment?.id || '')).data;
        toastService.info(response.message);
        this.closePopup()
        onReject ? this.props.history.push(routes.agent.assignments) : this.fetch()
    }

    closePopup = () => this.setState({ onAccept: false, onReject: false, loadingButton: false })

    get confirmTitle() {
        const { onAccept, onReject, loadingButton } = this.state;
        if (loadingButton) return <BSLoading />

        return onAccept ? 'Accept' : 'Reject';
    }

    render() {
        const { loading, item, onAccept, onReject, offer } = this.state;
        const { _id: userId } = this.props.user;

        const assignee = item && (item.user as IUser)._id === userId;        

        return (
            <div className="at-collectiondetail at-collectiondetailview at-contentarea scrollable">
                {
                    loading && <LoadingComponent />
                }
                {
                    item && (
                        <CollectionItemViewComponent item={item.art} {...this.props} refresh={this.fetch} actionOffer={offer} isAgentView={true} agent={item.user as IUser} refreshOffer={this.fetchOffer}/>
                    )
                }
                {/* { 
                    (item && assignee) && (
                        <>
                            {
                                (item.accepted) ? (
                                    <div className="at-themepopupholder at_themebottom_350 at_themebottom_pad30">
                                        <div className="at-themepopupbox">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button className="at-btn">Accepted</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                        <>
                                            <div className="at-themepopupholder at_themebottom_350">
                                                <div className="at-themepopupbox">
                                                    <div className="row">
                                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <button type="button" className="btn btn-orange btn-block" onClick={this.accept}>Accept</button>
                                                            </div>
                                                        </div>

                                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <button type="button" className="btn btn-secondary btn-block" onClick={this.reject}>Reject</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                            }
                        </>
                    )
                } */}

                <ConfirmAlertComponent open={onAccept || onReject} title="Confirmation!" message={onAccept ? lang.assignment.acceptAssignment : lang.assignment.rejectAssignment} onCancel={this.closePopup} onConfirm={this.confirmAction} confirmTitle={this.confirmTitle} />
            </div>
        );
    }
}

const mapState = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapState, {})(AgentAssignmentDetailsView);