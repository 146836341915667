import {IUser, IActiveSpaceDetails, IContact} from "../../interfaces";
import {IActionType} from "../types";
import { LIKE_POST, UNLIKE_POST, LIKE_USER, UNLIKE_USER, SAVE_USER, UPDATE_USER_DETAILS, ADD_USER_TO_SPACE } from "../types/user.type";

const initialState: IUser | any = {
    user: {}
}

export const userReducer = (state = initialState, action: IActionType ): IUser => {

    switch (action.type) {
        case SAVE_USER:
            return { ...action.payload };
        case LIKE_POST:
            return { ...state, favorites: [...state.favorites, action.payload] };
        case UNLIKE_POST:
            state.favorites = state.favorites.filter((v: string) => v !== action.payload)
        case LIKE_USER:
            return { ...state, favoriteArtist: [...state.favoriteArtist, action.payload] };
        case UNLIKE_USER:
            state.favoriteArtist = state.favoriteArtist.filter((v: string) => v !== action.payload)
            return { ...state };
        case UPDATE_USER_DETAILS:
            return { ...state, ...action.payload };
        case ADD_USER_TO_SPACE:
            state.activeSpace && (state.activeSpace.contacts || []).push(action.payload);
            return { ...state };
        default:
            return state;
    }
}
