import React, {Component, FormEvent} from 'react';
import { BAD_REQUEST, NOT_FOUND } from 'http-status'
import {validationErrors} from "../../shared";
import { authService, toastService } from '../../services';
import {ErrorComponent} from "../common/Error.component";
import PhoneInput from 'react-phone-number-input'
import { _logger } from '../../helper';

interface ForgotPasswordProps {
    onClose: any,
    openSignin: any,
    openVerificationCode: any
}

interface IPageState {
    errors: any;
    loading: boolean;
    phoneNumber: string;
}
class ForgotPasswordComponent extends Component<ForgotPasswordProps> {

    state: IPageState = {
        errors: null,
        phoneNumber: '+1',
        loading: false
    }

    onChangeText = (e: FormEvent<HTMLInputElement> | any): void => {
		const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    toggleLoading = (): void => {
        this.setState({ loading: !this.state.loading })
    }

    submit = (e: FormEvent): void => {
        e.preventDefault();
        this.toggleLoading();
        const { phoneNumber } = this.state;
        this.setState({ errors: null })
        authService.forgotPassword({ phoneNumber }).then(this.successHandler, this.errorHandler)
    }

    successHandler = (response: any) => {
        this.toggleLoading();
        toastService.info('PIN code sent to your phone number.');
        this.props.openVerificationCode(this.state.phoneNumber)
    }

    errorHandler = ({ response }: any) => {
        this.toggleLoading();
        _logger('', response.data.message)
        let errors = null;
        switch (response.status) {
            case BAD_REQUEST:
                errors = validationErrors(response.data);
                break;
            case NOT_FOUND:
                toastService.error('Incorrect phone number')
                break;
            default:
                break;
        }
        this.setState({ errors })
    }

    errorElement = (key: string) => (
        this.state.errors && this.state.errors[key] && <ErrorComponent errors={this.state.errors[key]} multiple={false}/>
    )

    render() {
        const { loading, errors, phoneNumber } = this.state;
        return (
            <div className="at-themepopupbox">
                <a className="at-close" id="at-close"  onClick={this.props.onClose}><i className="icon-cancel"></i></a>
                <form className="at-formtheme at-formforgetpassword" onSubmit={this.submit}>
                    <fieldset>
                        <h2>Forgot Your Password?<span>Please enter your registered number to <em>change password</em></span></h2>
                        <div className="form-group">
                            {/* <input type="text" name="phoneNumber" className="form-control customer-phonenumber" value={phoneNumber} placeholder="+92 311 444 7777" onChange={this.onChangeText}/> */}
                            <PhoneInput
                                placeholder="Phone (e.g., +1 412 877 4312)"
                                value={phoneNumber}
                                defaultCountry="US"
                                onChange={(phoneNumber: any) => this.setState({phoneNumber})}/>
                            { this.errorElement('phoneNumber') }
                        </div>
                        <div className="form-group">
                            <button className="at-btn at-btnrecoveycode" disabled={loading}>{ loading ? 'Please wait...' : 'Get Code'}</button>
                        </div>
                        <div className="form-group">
                            <em>Back to<button className="at-gotologinfromforgotpassword" onClick={this.props.openSignin}>LOGIN</button></em>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }
}

export default ForgotPasswordComponent;
