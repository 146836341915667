import React, { useState, useCallback, useEffect, FormEvent } from 'react'
import { AxiosResponse } from 'axios'
import { IUser } from '../../../interfaces'
import { adminService, submissionProcessService, toastService } from '../../../services'
import { userImage } from '../../../shared'
import { getInitials } from '../../../helper'
import { ERole } from '../../../enums'
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component'
import { RouteComponentProps, Link, useHistory } from 'react-router-dom'
import { AdminApproveModal } from '../Modals/AdminApproveModal'
import { ISubmissionProcess } from '../../../interfaces/submission-process.interface'
import { IResponse } from '../../../interfaces/db.interface'
import { routes } from '../../../router'

interface IPageProps {
}

interface IblockUser {
    _id: string,
    status: boolean
}

export const AdminPendingUser = ({ }: IPageProps) => {
    const [agents, setAgents] = useState<Array<ISubmissionProcess>>()
    const [artists, setArtists] = useState<Array<ISubmissionProcess>>()
    const [serviceProvider, setServiceProvider] = useState<Array<ISubmissionProcess>>()
    const [searchContent, setSearchContent] = useState<string>("")
    const [showApprove, setShowApprove] = useState<boolean>(false)
    const [showReject, setShowReject] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [currentApp, setCurrentApp] = useState<ISubmissionProcess>()
    const history = useHistory()

    // Component Did mount
    useEffect(() => {
        fetch('')
    }, [])

    // Fecth
    const setDataInState = (data: any) => {
        let artistList = data.filter((item: ISubmissionProcess) => item.roles.includes(ERole.ARTIST))
        let agentList = data.filter((item: ISubmissionProcess) => item.roles.includes(ERole.AGENT))
        let service = data.filter((item: ISubmissionProcess) => item.roles.includes(ERole.CONSULTANT))
        // let guesList = data.filter((item: ISubmissionProcess) => item.roles.length === 0 || item.roles[0].length === 0)
        // let service = data.filter((item: ISubmissionProcess) => item.roles.includes(ERole.SERVICE_PROVIDER))
        // let consultdata = data.filter((item: ISubmissionProcess) => item.roles.includes(ERole.CONSULTANT))
        setArtists([...artistList])
        setAgents([...agentList])
        setServiceProvider([...service])
    }

    const fetch = useCallback((value: string) => {
        submissionProcessService.fetchApplications(value).then((response: AxiosResponse<IResponse<Array<ISubmissionProcess>>>) => {
            setDataInState(response.data.data)
        }).catch(error => {
            console.log("Error in getting users list: ", error)
        })
    }, [])


    // Handlers
    const handleSearch = (e: FormEvent<EventTarget>) => {
        const { value } = (e.target as HTMLInputElement)
        setSearchContent(value)
        fetch(value)
    }


    const handleBlockUser = (s: ISubmissionProcess) => {
        // setCurrentApp(s)
        // setOpenBlockBox(true)
    }


    // Confirmational Box handlers
    const onCancelApprove = () => {
        setShowApprove(false)
        setCurrentApp({} as any)
    }
    const onCancelReject = () => {
        setShowReject(false)
        setCurrentApp({} as any)
    }

    const onConfirmApprove = () => {
        setLoading(true)
        submissionProcessService.approveApplication({ id: currentApp?._id }).then((res: AxiosResponse<IResponse<ISubmissionProcess>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetch('')
                onCancelApprove()
            }
            setLoading(false)
        }).catch(error => {
            console.log("Error in getting users list: ", error)
            setLoading(false)
        })
    }
    const onConfirmReject = () => {
        setLoading(true)
        submissionProcessService.rejectApplication({ id: currentApp?._id }).then((res: AxiosResponse<IResponse<ISubmissionProcess>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetch('')
                onCancelReject()
            }
            setLoading(false)
        }).catch(error => {
            console.log("Error in getting users list: ", error)
            setLoading(false)
        })
    }

    const onApproveApp = (s: ISubmissionProcess) => {
        setShowApprove(true)
        setCurrentApp(s)
    }


    const onRejectApp = (s: ISubmissionProcess) => {
        setShowReject(true)
        setCurrentApp(s)
    }



    // rendering func
    const renderUser = (user: IUser, s: ISubmissionProcess) => (
        <div className="form-group">
            <div className="at-uservideobox">
                <div className="at-uservideocontent border-0">
                    <div className="at-adminaction">
                        <div onClick={() => history.push(`${routes.adminRoutes.submissionApplicationView}/${s._id}`)}>
                            <figure><img src={user.profile ? userImage(user.profile) : getInitials(user?.firstName, user?.lastName)} alt="User Image" /></figure>
                            <h3>{`${user.firstName} ${user.lastName}`}<span>{user.phoneNumber}</span></h3>
                        </div>
                        <div className="at-iconlikeholder">
                            <span className="at-iconlike cursor-pointer" onClick={() => onApproveApp(s)}><i className={`icon-like `}></i></span>
                            <span className="at-iconlike at-icondislike cursor-pointer" onClick={() => onRejectApp(s)}><i className={`icon-dislike `}></i></span>
                        </div>
                        <div className="at-adminicon cursor-pointer" onClick={() => history.push(`${routes.adminRoutes.submissionApplicationView}/${s._id}`)}><i className={`icon-pending-approve-icon `}></i></div>
                    </div>
                </div>
            </div>
        </div>
    )


    return (
        <>
            <div className="at-formusercontacts  p-0 pt-3">
                <fieldset>
                    <div className="form-group at-paddinglr-15">
                        <input type="text" name="searchnamenumber" value={searchContent} className="form-control" placeholder="Search..." onChange={handleSearch} />
                        {/* <a  className="at-btnsearch"><i className="icon-search"></i></a> */}
                    </div>
                    <div className="at-usereditcontactholder at-usereditcontactholder-two at-themescrollbar scrollable">
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Agent / Gallery</h6>
                            <div className="at-paddinglr-15">
                                {agents?.map((s: ISubmissionProcess) => (
                                    renderUser(s.user, s)
                                ))}
                            </div>
                        </div>
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Artists</h6>
                            <div className="at-paddinglr-15">
                                {artists?.map((s: ISubmissionProcess) => (
                                    renderUser(s.user, s)
                                ))}
                            </div>
                        </div>
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Service Provider</h6>
                            <div className="at-paddinglr-15">
                                {serviceProvider?.map((s: ISubmissionProcess) => (
                                    renderUser(s.user, s)
                                ))}
                            </div>
                        </div>
                        {/* <div className="at-userbox">
                            <h6 className="at-userbox-heading">Service Provider</h6>
                            <div className="at-paddinglr-15">
                                {serviceProvider?.map((user: IUser) => (
                                    renderUser(user)
                                ))}
                            </div>
                        </div>
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Consultant</h6>
                            <div className="at-paddinglr-15">
                                {consultant?.map((user: IUser) => (
                                    renderUser(user)
                                ))}
                            </div>
                        </div> */}
                    </div>
                </fieldset>
            </div>
            {showApprove && <ConfirmAlertComponent
                open={showApprove}
                message={`Are you sure you want to approve this request?`}
                title="Confirm!"
                onClose={onCancelApprove}
                onCancel={onCancelApprove}
                onConfirm={onConfirmApprove}
                loading={loading}
            />}
            {showReject && <ConfirmAlertComponent
                open={showReject}
                message={`Are you sure you want to reject this request?`}
                title="Confirm!"
                onClose={onCancelReject}
                onCancel={onCancelReject}
                onConfirm={onConfirmReject}
                loading={loading}
            />}
        </>
    )
}
