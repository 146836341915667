import React, { useState, FormEvent, useEffect } from 'react'
import { ETransactionStatus } from '../../../enums'
import { IDisputed, ITransaction } from '../../../interfaces'
import { ErrorComponent } from '../Error.component'

interface IProps {
    open: boolean,
    onClose: Function,
    onSubmit: Function
    transaction?: ITransaction,
}

export const DisputeModal = ({ open, onClose, onSubmit, transaction }: IProps) => {

    const [state, setState] = useState<IDisputed>({
        status: false,
        reason: '',
        details: '',
        resolve: false,
        labelStatus:''
    })

    const [errors, setErrors] = useState<any>({})

    useEffect(() => {
        if (transaction) {
            let { disputed } = transaction
            if (disputed.status) {
                setState({
                    ...state,
                    status: disputed.status,
                    reason: disputed.reason,
                    details: disputed.details
                })
            }
        }
    }, [])

    const onChange = (e: FormEvent<HTMLInputElement> | any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const onSubmitDetails = () => {
        if (!validateData()) return
        let data: IDisputed = state
        data.status = true
        data.labelStatus = ETransactionStatus.PENDING
        onSubmit(data)
    }

    const validateData = () => {
        let { reason } = state
        if (!reason) {
            let newErrors = errors
            newErrors['reason'] = ['Please enter title of dipsute']
            setErrors({ ...newErrors })
            return false
        }
        setErrors({})
        return true
    }
    return (
        <div className={`at-thankyou-popup at-themepopupholder ${open && 'at-showthemesignuppopup'}`}>
            <div className="at-themepopupbox text-left">
                <a className="at-close cursor-pointer" onClick={() => onClose()} id="at-close"><i className="icon-cancel"></i></a>
                <div className="at-addactionholder">
                    <div className="at-formthemehead">
                        <h2>Dispute Form</h2>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12x">
                            <div className="form-group">
                                <label>Title</label>
                                <input type="text" name="reason" value={state.reason} className="form-control" placeholder="reason" onChange={(e: any) => onChange(e)} />
                            {errors && errors['reason'] && <ErrorComponent errors={errors['reason']} multiple={false} />}
                            </div>
                        </div>
                    </div>

                    <div className="row at-dispute-text-area">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea name="details" value={state.details} className="form-control" placeholder="Details" onChange={(e: any) => onChange(e)} />
                            </div>

                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="at-btn at-minwidth-100" onClick={() => onSubmitDetails()}>{'Next'}</button>
                    </div>
                    <p className="at-disputecasetext">In the case that a refund is approved during the dispute, credit card processing fees and platform fees will not be refunded.</p>
                </div>
            </div>
        </div>
    )
}