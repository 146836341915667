import { HttpService } from './base.service'
import { data } from 'jquery';

class TutorialsService extends HttpService {
    private readonly prefix: string = 'tutorials'
    /**
     * 
     * Create Caregory
     */
    addCategories = (data: any): Promise<any> => this.post(`${this.prefix}/category`, data)

    /**
     * 
     * update Category
     */
    updateCategories = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/update-category/${id}`, data)

    /**
     * 
     * Add Tutorials
     */
    addTutorials = (data: any): Promise<any> => this.post(`${this.prefix}`, data)

    /**
    * 
    * Get Categories
    */
    getCategories = (data?: any): Promise<any> => this.get(`${this.prefix}/category`, data)

    /**
     * 
     * Get Categories by Id
     */
    getCategoryById = (id: string): Promise<any> => this.get(`${this.prefix}/category/${id}`)

    /**
    * 
    * Get Tutorials
    */
    getTutorials = (data?: any): Promise<any> => this.get(`${this.prefix}`, data)

    /**
     * 
     * Get Tutorials by Id
     */
    getTutorialById = (id: string): Promise<any> => this.get(`${this.prefix}/${id}`)

    /**
     * 
     * Delete Tutorial
     */

    deleteTutorial = (id: string): Promise<any> => this.delete(`${this.prefix}/delete/${id}`)

    /**
     * 
     * Delete Tutorial
     */

    sendSms = (data: any): Promise<any> => this.post(`${this.prefix}/send-sms`, data)
}

export const tutorialsService = new TutorialsService();