import { AxiosError, AxiosResponse } from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { images } from '../../../../assets/image'
import { EServicesType } from '../../../../enums'
import { IResponse } from '../../../../interfaces/db.interface'
import { IConsultantServicesDocument, IServiceCategoryDocument } from '../../../../interfaces/services.interface'
import { toastService } from '../../../../services'
import { consultantService } from '../../../../services/consultantServices.service'
import { userSelector } from '../../../../store/selectors'
import { ServiceCategoryComponent } from './ServiceCatogry.component'

export const CreateServices = () => {

    const user = useSelector(userSelector)
    const [categories, setCategories] = useState<Array<IServiceCategoryDocument>>()
    const [defaultcategories, setDefaultCategories] = useState<Array<IServiceCategoryDocument>>()
    const [loading, setLoading] = useState<boolean>(false)

    const [title, setTitle] = useState<string>('')

    const fetch = () => {
        setLoading(true)
        consultantService.getCategories().then((res: AxiosResponse<IResponse<Array<IServiceCategoryDocument>>>) => {
            if (res.data.success) {
                let defCat = res.data.data.filter((c: IServiceCategoryDocument) => c.type === 'DEFAULT')
                let otherCat = res.data.data.filter((c: IServiceCategoryDocument) => c.type !== 'DEFAULT')
                setCategories([...otherCat])
                setDefaultCategories([...defCat])
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        consultantService.addCategories({ title, type: EServicesType.USER_CREATED }).then((res: AxiosResponse<IResponse<IServiceCategoryDocument>>) => {
            if (res.data.success) {
                fetch()
                setTitle('')
                toastService.success(res.data.message)
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    return <div className="at-createservice-innertab at-innertab-mlr-minus20">
        <form className="at-formtheme at-formcreatecollection" onSubmit={onSubmit} >
            <fieldset>
                <div className="form-group at-floatlabel form-w-100 mt-3">
                    <input type="text" name="title" value={title} className="form-control" autoComplete="off" placeholder=" " onChange={(e: any) => setTitle(e.target.value)} />
                    <label>Create Service Category</label>
                    <button type="submit" disabled={!title} className="at-btnadd c-sunshadebg" ><i className="icon-add-bold"></i></button>
                </div>
            </fieldset>
        </form>
        <div className="at-themebody">
            {/* <div className="at-categorybox">
                <div className="at-categoryhead">
                    <h4 className="at-additional-heading">OMNI Platform Services</h4>
                </div> */}

            {defaultcategories && defaultcategories.length > 0 && defaultcategories.map((c: IServiceCategoryDocument) => <ServiceCategoryComponent fetch={fetch} user={user} c={c} />)}

            {categories && categories.length > 0 && categories.map((c: IServiceCategoryDocument) => <ServiceCategoryComponent fetch={fetch} user={user} c={c} />)}
        </div>
    </div>
}