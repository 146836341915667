import React, { Component, FormEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import PhoneInput from 'react-phone-number-input'

import { IContact, IStoreReducers, IContactState } from '../../interfaces'
import ContactComponent from '../../components/MyCentral/Contact/Contact.component'
import { contactService, toastService } from '../../services'
import { isPlural } from '../../helper'
import { ConfirmAlertComponent } from '../../components/common/ConfirmAlert.component'
import { fetchContacts } from '../../store/actions'
import { ErrorComponent } from '../../components/common/Error.component'

interface IContactsPageProps extends RouteComponentProps {
	fetchContacts: Function
	contacts: IContactState
}

enum FormFields {
	firstName = 'firstName',
	lastName = 'lastName',
	phoneNumber = 'phoneNumber',
}

interface IContactPageState extends IAddContactState {
	addContact: boolean
	editContact: boolean
	openDelConfirmation: boolean
	// selectedContacts: IContactMap | Array<string>
	selectedContacts: Array<string>
	contacts: IContact[]
	searchTerm: string
	errors: {
		[FormFields.firstName]: IFormError
		[FormFields.lastName]: IFormError
		[FormFields.phoneNumber]: IFormError
	}
	invalidForm: boolean
	loading: boolean
}

interface IFormError {
	touched: boolean
	error: string
}

interface IAddContactState {
	_id?: string
	[FormFields.firstName]: string
	[FormFields.lastName]: string
	code: string
	number: string
	[FormFields.phoneNumber]: string
}
interface IContactMap {
	[_id: string]: IContact
}

const errorsInitialState = {
	firstName: {
		touched: false,
		error: '',
	},
	lastName: {
		touched: false,
		error: '',
	},
	phoneNumber: {
		touched: false,
		error: '',
	},
}

class ContactsPage extends Component<IContactsPageProps> {
	addContactInitialState = {
		firstName: '',
		lastName: '',
		code: '1',
		number: '',
		phoneNumber: '+1',
		_id: undefined,
	}

	state: IContactPageState = {
		addContact: false,
		editContact: false,
		openDelConfirmation: false,
		...this.addContactInitialState,
		// selectedContacts: {},
		selectedContacts: [],
		contacts: [],
		searchTerm: '',
		errors: { ...errorsInitialState },
		invalidForm: true,
		loading: false,
	}

	mappedContacts: IContactMap = {}

	componentDidMount() {
		this.fetchContacts()
	}

	fetchContacts = () => {
		// if (!this.props.contacts.isLoaded) {
		this.props.fetchContacts()
		// }
		// contactService.list().then(
		// 	({ data: contacts }) => {
		// 		this.setState({ contacts })
		// 		this.mappedContacts = MapToIndices(contacts, '_id')
		// 	},
		// 	(rejection) => {
		// 		console.error(JSON.stringify(rejection, undefined, 2))
		// 	}
		// )
	}

	closeAll = (): void => {
		this.setState({
			addContact: false,
			editContact: false,
			openDelConfirmation: false,
			errors: { ...errorsInitialState },
			invalidForm: true,
		})
	}

	openContactForm = (): void => {
		this.closeAll()
		this.setState({ addContact: true })
		this.clearAddContactForm()
	}

	addContact = async (e: FormEvent): Promise<any> => {
		try {
			e.preventDefault()
			this.toggleLoading(true)
			const newContact = this.getContact(this.state)
			await contactService.add(newContact)
			this.toggleLoading(false)
			this.fetchContacts()
			this.closeAll()
			toastService.success(
				`${newContact.firstName} is added in your contact list`
			)
			this.clearAddContactForm()
		} catch (error) {
			if (error.response.status === 409) {
				toastService.error(error.response.data.message)
			} else {
				toastService.error('Network error occured')
			}
		}
	}

	toggleLoading = (loading: boolean): void => {
		this.setState({ loading })
	}

	clearAddContactForm = () => {
		this.setState({ ...this.addContactInitialState })
	}

	onChangeText = async (e: FormEvent) => {
		let { name, value } = e.target as HTMLInputElement

		const errors = this.state.errors

		switch (name) {
			case FormFields.firstName:
				if (!value.trim().length)
					errors.firstName.error = 'First name is required'
				else if (value.match(/[0-9]/g))
					errors.firstName.error = 'Name can not contain numbers'
				else errors.firstName.error = ''
				break
			case FormFields.lastName:
				if (!value.trim().length)
					errors.lastName.error = 'First name is required'
				else if (value.match(/[0-9]/g))
					errors.lastName.error = 'Name can not contain numbers'
				else errors.lastName.error = ''
				break
			default:
				break
		}

		await this.setState({ errors, [name]: value })

		this.validateForm()
	}

	validateForm = () => {
		const { errors, phoneNumber } = this.state

		errors.phoneNumber.error =
			errors.phoneNumber.touched && (!phoneNumber || phoneNumber.length === 0)
				? 'Phone number is required'
				: ''

		let valid = true
		Object.values(errors).some((val) => {
			return val.error.length > 0 && (valid = false)
		})

		this.setState({ invalidForm: !valid, errors })
	}

	searchHandler = (value: string) => {
		const parsed = parseInt(value)
		const isName = isNaN(parsed)
		return (contact: IContact) => {
			if (isName) {
				const fullName =
					contact.firstName.toLowerCase() + ' ' + contact.lastName.toLowerCase()
				return (
					fullName.includes(value.toLocaleLowerCase()) ||
					// contact.firstName.toLowerCase().includes(value.toLowerCase()) ||
					// contact.lastName.toLowerCase().includes(value.toLowerCase()) ||
					false
				)
			} else {
				return contact.phoneNumber.includes(parsed + '') || false
			}
		}
	}
	isSelected = (id: string) =>
		this.state.selectedContacts.some((v: string) => v === id)

	toggleSelect = (contactId: string) => {
		// const isSelected = this.state.selectedContacts[contactId]
		// const idx = Object.keys(this.mappedContacts).indexOf(contactId)
		// const updatedContacts = [...this.state.contacts]
		// if (isSelected) {
		// 	delete this.state.selectedContacts[contactId]
		// 	updatedContacts[idx].selected = false
		// } else {
		// 	const updatedSelection = {
		// 		...this.state.selectedContacts,
		// 		...{ [contactId]: this.mappedContacts[contactId] },
		// 	}
		// 	// eslint-disable-next-line react/no-direct-mutation-state
		// 	this.state.selectedContacts = updatedSelection
		// 	// this.setState({ selectedContacts: updatedSelection })
		// 	updatedContacts[idx].selected = true
		// }
		// this.setState({
		// 	contacts: updatedContacts,
		// })

		// Code by Shafique
		const isExisted = this.isSelected(contactId)
		let selectedContacts: Array<string> = this.state.selectedContacts
		if (isExisted) {
			selectedContacts = selectedContacts.filter((v) => v !== contactId)
		} else {
			selectedContacts = [...selectedContacts, contactId]
		}
		this.setState({ selectedContacts })
	}

	editContactForm = (contact: IContact) => {
		this.setState({ editContact: true })
		this.setState(contact)
	}

	editContact = async (e: FormEvent) => {
		try {
			e.preventDefault()
			this.toggleLoading(true)
			const newContact = this.getContact(this.state)
			await contactService.udpate(newContact)
			this.toggleLoading(false)
			this.fetchContacts()
			this.closeAll()
			toastService.success(`${newContact.firstName} is updated`)
			this.clearAddContactForm()
		} catch (error) {
			if (error.response.status === 409) {
				toastService.error(error.response.data.message)
			} else {
				toastService.error('Network error occured')
			}
		}
	}

	openConfirmationModal = async (e: FormEvent) => {
		e.preventDefault()
		this.setState({ openDelConfirmation: true })
	}

	deleteContacts = async (e: FormEvent) => {
		e.preventDefault()
		if (!this.state.selectedContacts.length) return
		await contactService.remove(this.state.selectedContacts)
		this.fetchContacts()
		toastService.success(
			`${this.state.selectedContacts.length} contact${
				isPlural(this.state.selectedContacts.length) ? 's' : ''
			} deleted`
		)
		this.setState({ selectedContacts: [] })
		this.closeAll()
	}

	private readonly getContact = ({
		firstName,
		lastName,
		code,
		number,
		_id,
		phoneNumber,
	}: IContactPageState) => {
		const contact = {
			firstName,
			lastName,
			code,
			number,
			_id,
			phoneNumber,
		}
		return contact
	}

	render() {
		const {
			addContact,
			editContact,
			openDelConfirmation,
			firstName,
			lastName,
			phoneNumber,
			// contacts,
			searchTerm,
			selectedContacts,
			errors,
			invalidForm,
			loading,
		} = this.state

		const {
			contacts: { data: contacts },
		} = this.props

		// start - repetitive components / components to be rendered
		const contactsList = contacts
			.filter(this.searchHandler(searchTerm))
			.map((contact) => {
				return (
					<ContactComponent
						key={contact._id}
						contact={contact}
						toggleSelect={this.toggleSelect}
						editContact={this.editContactForm}
						isEditable={true}
						isSelected={this.isSelected}
					/>
				)
			})
		// end - repetitive components / components to be rendered

		return (
			// <div id="at-wrapper" className="at-wrapper">
			<>
				<main id='at-main' className='at-main at-haslayout'>
					<form className='at-formtheme at-formusercontacts at-contentarea-two'>
						<fieldset>
							<div className='at-contacthead'>
								<h3>
									Contact<span>{contacts.length} Online</span>
								</h3>
								<div className='at-editdeletebox'>
									{selectedContacts.length !== 0 && (
										<a
											className='at-deleteicon'
											onClick={this.openConfirmationModal}
										>
											<i className='icon-delete'></i>
										</a>
									)}
									<a
										className='at-closeicon'
										onClick={this.props.history.goBack}
									>
										<i className='icon-cancel'></i>
									</a>
								</div>
							</div>
							<div className='form-group'>
								<input
									type='text'
									name='searchTerm'
									className='form-control'
									placeholder='Search by names & numbers'
									value={searchTerm}
									onChange={this.onChangeText}
								/>
							</div>
							<div className='at-usereditcontactholder at-themescrollbar scrollable'>
								<div className='form-group'>
									<div className='at-uservideobox'>{contactsList}</div>
								</div>
							</div>
						</fieldset>
					</form>
					<a className='at-btnadduser' onClick={this.openContactForm}>
						<i className='icon-add text-white'></i>
					</a>
				</main>

				{/*Add Contact*/}
				<div
					id='at-themeaddusercontactpopup'
					className={`at-themepopupholder at-themeaddusercontactpopup ${
						(addContact || editContact) && 'at-showthemecreatecontactpopup'
					}`}
				>
					<div className='at-themepopupbox'>
						<a className='at-close' id='at-close' onClick={this.closeAll}>
							<i className='icon-cancel'></i>
						</a>
						<form
							onSubmit={addContact ? this.addContact : this.editContact}
							className='at-formtheme at-formaddusercontact'
						>
							<fieldset>
								<div className='at-formthemehead'>
									{addContact && <h2>Add Contact</h2>}
									{editContact && <h2>Edit Contact</h2>}
								</div>
								<div className='at-formthemeholder'>
									<div className='form-group at-floatlabel'>
										<input
											type='text'
											name='firstName'
											className='form-control'
											placeholder=' '
											value={firstName}
											onChange={this.onChangeText}
										/>
										<label>First Name</label>
										<ErrorComponent
											errors={[errors.firstName?.error]}
											multiple={false}
										/>
									</div>
									<div className='form-group at-floatlabel'>
										<input
											type='text'
											name='lastName'
											className='form-control'
											placeholder=' '
											value={lastName}
											onChange={this.onChangeText}
										/>
										<label>Last Name</label>
										<ErrorComponent
											errors={[errors.lastName?.error]}
											multiple={false}
										/>
									</div>
									<div className='form-group at-inputwithicon'>
										<i className='icon-user'></i>
										{/* <input type="text" name="username" value={username} className="form-control" placeholder="Phone number" onChange={this.onChangeText}/> */}
										<PhoneInput
											placeholder='Phone (e.g., +1 412 877 4312)'
											value={phoneNumber}
											defaultCountry="US"
											onChange={async (phoneNumber: any) => {
												await this.setState({ phoneNumber })
												this.validateForm()
											}}
											onFocus={async () => {
												await this.setState({
													errors: {
														...this.state.errors,
														phoneNumber: {
															...this.state.errors.phoneNumber,
															touched: true,
														},
													},
												})
											}}
										/>
										<ErrorComponent
											errors={[errors.phoneNumber?.error]}
											multiple={false}
										/>
									</div>
									{/* <div className='form-group'>
										<span className='at-select'>
											<select
												name='code'
												value={code}
												onChange={this.onChangeText}
											>
												<option value='1'>UK (+44)</option>
												<option value='44'>Norway (+47)</option>
												<optgroup label='Other countries'>
													<option value='213'>Algeria (+213)</option>
													<option value='376'>Andorra (+376)</option>
												</optgroup>
											</select>
										</span>
									</div>
									<div className='form-group'>
										<input
											type='text'
											id='customer-phonenumber'
											name='number'
											className='form-control'
											placeholder='+92 311 444 7777'
											value={number}
											onChange={this.onChangeText}
										/>
									</div> */}
									<div className='form-group'>
										<button
											type='submit'
											className='at-btn'
											disabled={invalidForm || loading}
										>
											{addContact && 'Add'}
											{editContact && 'Edit'}
										</button>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
				{openDelConfirmation && (
					<ConfirmAlertComponent
						open={openDelConfirmation}
						onCancel={this.closeAll}
						message={`Delete ${selectedContacts.length} contact${
							isPlural(selectedContacts.length) ? 's' : ''
						}?`}
						onConfirm={this.deleteContacts}
						confirmTitle={'Delete'}
					/>
				)}
			</>
		)
	}
}

const mapStateToProps = (state: IStoreReducers) => {
	return {
		contacts: state.contacts,
	}
}

const mapActions = {
	fetchContacts,
}

export default connect(mapStateToProps, mapActions)(ContactsPage)
