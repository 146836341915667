import React, { Component, useState, useCallback, Fragment, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { likeAction, unLikeAction, unLikeUserAction, likeUserAction } from '../../../store/actions/user.action';
import { postService } from '../../../services/post.service';
import { IUser, IStoreReducers, IPost, IPostImage, zoomImageInterface } from '../../../interfaces';
import { postImage } from '../../../shared';
import { images } from '../../../assets/image';
import { routes } from '../../../router';
import SwipeableViews from 'react-swipeable-views';
import MultiClickComponent from '../../common/MultiClick.component'
import ImageModalComponent from '../../common/ImageModal.component';
import { UserLoginAction, userService } from '../../../services';
import LazyLoadImageComponent from '../../common/Lazy-load-image.component';
import { zoomImageAction } from '../../../store/actions';
import { ZoomActions, IZoom, EZoom } from '../../../services/zoom.service';
import { authSelector } from '../../../store/selectors';
import { FeedVideoWithImageComponent } from '../../common/FeedVideoWithImage.component';

interface IProps extends RouteComponentProps {
    feed: IPost
    redirect?: boolean
    user?: IUser
    likeAction?: any
    unLikeAction?: any
    unLikeUserAction: (id: any) => any
    likeUserAction: (id: any) => any
    zoomImageAction: (value: zoomImageInterface) => any
    showReactVideo?: boolean
}

const styles = {
    slide: {
        minHeight: 100,
        color: '#fff',
    },
    slide1: {
    }
};

const FeedImageComponent = (props: IProps) => {
    const { _id: authorId } = props.feed.user;

    const token = useSelector(authSelector).access_token
    const [heart, setHeart] = useState(false)
    const [imageModal, setImageModal] = useState({ display: false, details: { original: postImage(props.feed._id, props.feed.assets[0].url), _id: props.feed.assets[0]._id, extra: props.feed } })
    const [imageNumber, setImageNumber] = useState(1)
    const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false)

    const { feed, likeAction, unLikeAction, unLikeUserAction, likeUserAction, zoomImageAction, showReactVideo } = props
    // Favorit Posts
    // const isFavorit = (props.user && props.user.favorites || []).some((f: any) => f === feed._id);

    //Favorit Artics
    const isFavorit = (props.user && props.user.favoriteArtist || []).some((f: any) => f === authorId);


    const likeUnlikeButton = async () => {
        isFavorit ? unLikeAction(feed._id) : likeAction(feed._id)
        postService.likeUnlike(feed._id, !isFavorit);

    }

    const likeUnlikeUser = async () => {
        isFavorit ? unLikeUserAction(authorId) : likeUserAction(authorId)
        userService.likeUnlike(authorId, !isFavorit).then()

    }

    const doubleClick = () => {
        if (!token) {
            UserLoginAction.triggerAction(true)
            return
        }
        setHeart(true)
        likeUnlikeUser()
        setTimeout(() => {
            setHeart(false)
        }, 1000);
    }
    const tripeClick = () => {
        // alert('tripe Clicked');
    }

    const onClick = (url: string, thumbnail: string) => {
        // props.history.push(`${routes.artCentral.post.details}/${props.feed._id}`, props.feed._id)
        if (!token) {
            UserLoginAction.triggerAction(true)
            return
        }
        if (isFullScreenEnabled) return;
        return zoomImageAction({ original: url, thumbnail: thumbnail });
    }

    const activeImage = useCallback((index: any, indexLatest: any, meta: any) => {
        setImageNumber(index + 1)
    }, [])

    const onModalClose = (image: any) => {
        setImageModal({
            ...imageModal,
            display: false
        })
    }

    useEffect(() => {
        ZoomActions.listen.subscribe((zoom: IZoom) => {
            setIsFullScreenEnabled(zoom.type !== EZoom.NONE)
        })
    }, [])

    return (
        <>
            <SwipeableViews enableMouseEvents onChangeIndex={activeImage}>
                {
                    feed.assets.map((image: IPostImage, index: number) => (
                        <div style={Object.assign({}, styles.slide, styles.slide1)} key={`image-${index}`} className="cursor-pointer">
                            <MultiClickComponent onSingleClick={() => {
                                if (!image.isVideo) onClick(postImage(feed._id, `${image.url}`), postImage(feed._id, `blur_${image.url}`))
                            }} onDoubleClick={doubleClick} onTripleClick={tripeClick}>
                                {showReactVideo ? <FeedVideoWithImageComponent id={image._id} index={imageNumber} isVideo={image.isVideo} videoUrl={postImage(feed._id, image.url)} midResolution={postImage(feed._id, `high_${image.url}`, true)} thumbnail={postImage(feed._id, image.url, true)} /> : <LazyLoadImageComponent id={image._id} index={imageNumber} isVideo={image.isVideo} videoUrl={postImage(feed._id, image.url)} midResolution={postImage(feed._id, `high_${image.url}`, true)} thumbnail={postImage(feed._id, image.url, true)} />}
                            </MultiClickComponent>
                        </div>
                    ))
                }
            </SwipeableViews>
            {feed.assets.length > 1 && (
                <p>
                    {imageNumber} / {feed.assets.length}
                </p>
            )}
            {
                heart && <svg className={isFavorit ? 'floatingheart' : 'floatingheart-o'} viewBox="0 0 32 29.6">
                    <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                    c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"/>
                </svg>
            }
        </>
    )
}


const mapState = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapState, { likeAction, unLikeAction, unLikeUserAction, likeUserAction, zoomImageAction })(FeedImageComponent)