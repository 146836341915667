import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../assets/image'
import ImageGallery from 'react-image-gallery';
import { routes } from './routes'

const GalleryPage = () => {
    const items = [{
        original: images.template.bannerTemplate1,
        thumbnail: images.template.bannerTemplate1,
    },
    {
        original: images.template.artDetailImg,
        thumbnail: images.template.artDetailImg,
    }]
    return (
        <div>
            <section className="At-SectionPage">
                <div className="At-ContainerFull">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <div className="At-BannerHolder">
                                <figure className="At-FigureBanner-h600 At-FigureFull">
                                    <img src={images.template.bannerTemplate3} className="img-fluid" alt="" />
                                </figure>
                                <div className="At-BannerTextFraming">
                                    <h2>Our Gallery</h2>
                                    <nav aria-label="breadcrumb ">
                                        <ol className="breadcrumb At-Breadcrumb">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Library</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="At-SectionGallery At-Padding-tb8  At-Section-Mh800">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-lg-3">
                            <div className="At-ProductBox">
                                <figure className="At-FigureFull At-Figure-h340">
                                    <img src={images.artCollection.img01} alt="" />
                                </figure>
                                <div className="At-ProductText">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h4 className="mb-0">$5.00</h4>
                                        </div>
                                        <div className="col-6">
                                            <Link className="At-LinkLogo" to={routes.viewGalleryDetail}>
                                                <button className="At-Btn">BUY</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* col-md-3 ends  */}
                        <div className="col-md-4 col-lg-3">
                            <div className="At-ProductBox">
                                <figure className="At-FigureFull At-Figure-h340">
                                    <img src={images.artCollection.img02} alt="" />
                                </figure>
                                <div className="At-ProductText">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h4 className="mb-0">$5.00</h4>
                                        </div>
                                        <div className="col-6">
                                            <Link className="At-LinkLogo" to={routes.viewGalleryDetail}>
                                                <button className="At-Btn">BUY</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* col-md-3 ends  */}
                        <div className="col-md-4 col-lg-3">
                            <div className="At-ProductBox">
                                <figure className="At-FigureFull At-Figure-h340">
                                    <img src={images.artCollection.img03} alt="" />
                                </figure>
                                <div className="At-ProductText">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h4 className="mb-0">$5.00</h4>
                                        </div>
                                        <div className="col-6">
                                            <Link className="At-LinkLogo" to={routes.viewGalleryDetail}>
                                                <button className="At-Btn">BUY</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* col-md-3 ends  */}
                        <div className="col-md-4 col-lg-3">
                            <div className="At-ProductBox">
                                <figure className="At-FigureFull At-Figure-h340">
                                    <img src={images.artCollection.img04} alt="" />
                                </figure>
                                <div className="At-ProductText">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h4 className="mb-0">$5.00</h4>
                                        </div>
                                        <div className="col-6">
                                            <Link className="At-LinkLogo" to={routes.viewGalleryDetail}>
                                                <button className="At-Btn">BUY</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* col-md-3 ends  */}
                        <div className="col-md-4 col-lg-3">
                            <div className="At-ProductBox">
                                <figure className="At-FigureFull At-Figure-h340">
                                    <img src={images.artCollection.img05} alt="" />
                                </figure>
                                <div className="At-ProductText">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h4 className="mb-0">$5.00</h4>
                                        </div>
                                        <div className="col-6">
                                            <Link className="At-LinkLogo" to={routes.viewGalleryDetail}>
                                                <button className="At-Btn">BUY</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* col-md-3 ends  */}
                        <div className="col-md-4 col-lg-3">
                            <div className="At-ProductBox">
                                <figure className="At-FigureFull At-Figure-h340">
                                    <img src={images.artCollection.img06} alt="" />
                                </figure>
                                <div className="At-ProductText">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h4 className="mb-0">$5.00</h4>
                                        </div>
                                        <div className="col-6">
                                            <Link className="At-LinkLogo" to={routes.viewGalleryDetail}>
                                                <button className="At-Btn">BUY</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* col-md-3 ends  */}
                        <div className="col-md-4 col-lg-3">
                            <div className="At-ProductBox">
                                <figure className="At-FigureFull At-Figure-h340">
                                    <img src={images.artCollection.img07} alt="" />
                                </figure>
                                <div className="At-ProductText">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h4 className="mb-0">$5.00</h4>
                                        </div>
                                        <div className="col-6">
                                            <Link className="At-LinkLogo" to={routes.viewGalleryDetail}>
                                                <button className="At-Btn">BUY</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* col-md-3 ends  */}
                        <div className="col-md-4 col-lg-3">
                            <div className="At-ProductBox">
                                <figure className="At-FigureFull At-Figure-h340">
                                    <img src={images.artCollection.img08} alt="" />
                                </figure>
                                <div className="At-ProductText">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h4 className="mb-0">$5.00</h4>
                                        </div>
                                        <div className="col-6">
                                            <Link className="At-LinkLogo" to={routes.viewGalleryDetail}>
                                                <button className="At-Btn">BUY</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* col-md-3 ends  */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default GalleryPage