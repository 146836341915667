import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { adminService } from "../../services";

export const SapmleKeywords = () => {
  const [keywords, setKeywords] = useState<string>("");
  const fetchKeyWords = ()  => {
    adminService.getPolicy().then((response: AxiosResponse<any>) => {
      
      let text =
        response.data?.length > 0 && response.data[0].genreAndStyleKeywords ? response.data[0].genreAndStyleKeywords : "";
      setKeywords(text);
    console.log("runnnn",text)

    }).catch((err) => {
      console.log("err:-=", err)
    })
  };

  useEffect(() => {
    fetchKeyWords();
  }, []);

  return (
    <div className="text-left">
      
        <h5 className="at-popupheading at-lineHeight-13">
          Sample list of keywords for genre and style
        </h5>
     
      <div dangerouslySetInnerHTML={{ __html: keywords }} className="dangerouslySetHTML"></div>
    </div>
  );
};
