import React, { Component, Fragment } from 'react'
import AgoraRTC, { IAgoraRTCClient, IAgoraRTCRemoteUser, ILocalVideoTrack } from "agora-rtc-sdk-ng"
import $ from 'jquery'

import { rtcService, RtcStreamActions, GSTT, toastService, RtcStreamConnectionActions, AgoraLeaveActions, userService, spaceService } from '../../services'
import {
	IUser,
	IStoreReducers,
	ERtcEvent,
	IRtcAction,
	IRtcConnectionState,
	IContact,
	ISpace,
} from '../../interfaces'
import { THIRD_PARTY } from '../../environment'
import { connect } from 'react-redux'
import { Subscription } from 'rxjs'
import { _logger, isEmptyBuffer, downsampleBuffer, getInitials } from '../../helper'
import { socketNode } from '../../services/socket.service'
import { images } from '../../assets/image'
import { routes } from '../../router'
import { userImage } from '../../shared'
import { ADD_NEW_MEMBER, CONNECTED_USER, SET_RECOGNITION_STATUS, TURN_ON_SPEECH_TO_TEXT, REMOVE_USER_FROM_SPACE } from '../../constants'
import { debug } from 'console'
import SpaceChatBoxComponent from '../MyCentral/SpaceChatBox.component'
import _ from 'lodash';
import { accountService } from '../../services/account.service'
import { AxiosResponse } from 'axios'
import { LongPressAableComponent } from './LongPress.component'
import { TutorialIcon } from '../Tutorials/TutorialIcon'
import { EScreens } from '../../enums/screens.enum'
import { UniversalModal } from './Modals/Universal.modal'
import { ScreenTutorial } from '../Tutorials/ScreenTutorial'
import { IResponse } from '../../interfaces/db.interface'

AgoraRTC.setLogLevel(4)

export interface ICallInformation {
	isConnecting?: boolean
	isCalling?: boolean
	username?: string
	video?: boolean
	screen?: boolean
	mute?: boolean
	speechToText?: boolean
	userId?: string
}

export interface IStartCall {
	channel: string
	username: string
	userId?: string
	video?: boolean
	screen?: boolean
	transcriptId?: string
}

const initialRtcSettings = {
	client: null as any,
	joined: false,
	published: false,
	localStream: null,
	remoteStreams: [],
	remoteUsers: [],
	params: { uid: '' },
	// For the local audio and video tracks
	localAudioTrack: null,
	localVideoTrack: null,
}

// rtc object
let rtc: {
	client: IAgoraRTCClient
	joined: boolean
	published: boolean
	localStream: any
	remoteStreams: any[]
	remoteUsers: IContact[]
	// For the local audio and video tracks
	localAudioTrack: any,
	localVideoTrack: any,
	params: { uid: any }
} = { ...initialRtcSettings }

let user: IUser

let bufferSize = 16384
let context: AudioContext
let processor: ScriptProcessorNode
let input

interface IRtcComponentProps {
	user: IUser
	connected: any,
	enlargeBands: any
	expandList: any
	expandStreamingList: any
	isSpaceAdmin: any
	lockUnlockSpace: any
	activeSpace: any
	deactiveSpace: any
	toggleAudio: any
	activeAudio: any
	toggleVideo: any,
	activeVideo: any
	activateSpace: any
	leaveSpace: any
	toggleSpeechToText: any
	activeSpeechToText: any
	inviteNewUser: any
	openInviteModal: any
	joining: any
	onEnlargeBands: any
	onExpandList: any
	getTotalNumberOfStream: Function
	setControlsStatus: Function
	spaceLeft: Function
	addUserToSpace: Function
	isSpaceActivated: boolean
	showTutorial: boolean
	activateSpaceAction: any
	onIncreasingScreen: Function
	onDecreasingScreen: Function
	toggleView: Function
	showView: boolean
	showRow: boolean
	showGrid: boolean
	showStack: boolean
	toggleRow: Function
	toggleStack: Function
	toggleGrid: Function
	onStandardView: Function
	showStandard: boolean
	onClickingOnExist: Function
	onOpenTutorial: Function
	onCloseTutorial: Function
	onOpenShowPermissionPopup: Function
	onCloseShowPermissionPopup: Function
}

interface IState {
	videoEnabledBy: any
	members: {
		[key: string]: IUser
	}
	subscribedUsers: {
		[key: string]: boolean
	}
	user: IUser | any
	message: string
}


export class AgoraNextGeneration extends Component<IRtcComponentProps> {
	spaceID: string | any = null
	currentUser: any
	subscription = new Subscription()

	members: {
		[key: string]: IUser
	} = {};

	subscribedUsers: any = {};

	activeAudio: boolean = true;
	activeVideo: boolean = false;
	isVideoOn: boolean = false;
	activeSpeechToText: boolean = false;

	isSocketConnected: boolean = false;

	state: IState = {
		videoEnabledBy: {},
		members: {},
		subscribedUsers: {},
		user: null,
		message: ''
	}

	leaveSpace: boolean = false;

	enlargeVideo: boolean = false;

	timeOutOnProps: any

	textScrollTime: any;

	componentWillReceiveProps(nextProps: IRtcComponentProps, nextState: any) {
		this.timeOutOnProps && clearTimeout(this.timeOutOnProps);
		this.timeOutOnProps = setTimeout(() => {
			this.startCall(nextProps);
		}, 100);
		_logger('HERE IS NEW SPACE: ', this.spaceID)
	}

	shouldComponentUpdate() {
		return false;
	}

	componentDidMount() {

		this.enableSocket();

		const control = RtcStreamActions.listen.subscribe(this.controlStream)
		const leaveAgora = AgoraLeaveActions.listen.subscribe(this.leaveAgoraChannel)
		this.subscription.add(control)
		this.subscription.add(leaveAgora)
		// this.startCall(this.props.user);
	}

	componentWillUnmount() {
		this.subscription.unsubscribe();
	}

	mainDiv = () => 'agoraStreams';//window.location.href.search(routes.myCentral.chat.video) > -1 ? 'videoPageUsers' : 'agoraStreams'

	startCall = async ({ user, isSpaceActivated }: IRtcComponentProps) => {

		if (this.props.user && user.activeSpace && user.activeSpace._id) {
			this.members = {};
			user.activeSpace.contacts?.forEach((user: any) => this.members[user._id] = user)

			this.currentUser = user
			this.subscribedUsers[user._id] = user._id
			this.setState({ user: this.currentUser, members: this.members, subscribedUsers: { ...this.subscribedUsers } }, this.forceUpdate)


			if (this.spaceID === user.activeSpace._id) return;
			this.addNewMemberSocket(user.activeSpace._id)
			if (!isSpaceActivated) return;
			if (rtc.client) await this.leaveAgoraChannel()
			this.props.setControlsStatus({ activeVideo: false, activeAudio: false, joining: true, connected: false })
			rtc.client = null as any;
			this.spaceID = user.activeSpace._id

			this.activeVideo = false;
			this.activeSpeechToText = false;
			const spaceCanbeJoined = await this.isSpaceAvailable(user.activeSpace);
			if (!spaceCanbeJoined) return this.props.setControlsStatus({ activeVideo: false, activeAudio: false, joining: false, connected: false })
			this.initAgora(this.currentUser)

			this.isSocketConnected = true;

			this.registerMeetingWithSocket();
			this.initializeGoogleSpeechToText();
			return;
		}
		this.activeSpeechToText = false;
		this.currentUser = null;
	}

	isSpaceAvailable = async (activeSpace: ISpace) => {
		const { data: space }: AxiosResponse<IResponse<ISpace>> = await accountService.currentActiveSpace();
		if (space.data.isLocked && (space.data.userId as any) !== this.props.user._id) {
			this.setState({ joining: false })
			let spaceName = spaceService.getSpaceName(activeSpace);
			toastService.info(`There is currently a private meeting in ${spaceName}`)
			this.props.activateSpaceAction(false)
			return false;
		}
		return true;
	}

	initAgora = (activeUser: IUser, refresh = false) => {
		console.log("run")
		user = activeUser
		rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' })
		this.initializeAgoraClient()
	}

	// Initialize the client
	initializeAgoraClient = (): void => {
		this.joinAgoraChannel()
		this.listenToStreams()
	}

	// Join a channel
	joinAgoraChannel = async () => {

		if (!this.spaceID) return

		rtcService
			.getToken({ spaceId: this.spaceID })
			.then(async ({ token, channelName }: any) => {
				_logger('Backend Channel Join', channelName)
				rtc.params.uid = await rtc.client.join(THIRD_PARTY.agora.appID, this.spaceID, token, this.currentUser._id)
				this.createAgoraStream();
			})
			.catch((error) => {
				console.error(error)
			})
	}

	createAgoraStream = () => {
		this.initializeAgoraStream(false)
	}

	// Initialize the local stream
	initializeAgoraStream = async (isVideoOn: boolean) => {
		// Use Agora Web SDK NG

		// await navigator.permissions.query({ name: 'microphone' }).then((result) => {
		// 	if (result.state == 'prompt') {
		// 		this.props.onOpenShowPermissionPopup()
		// 		result.onchange = () => {
		// 			if (result.state === "granted") {
		// 				this.props.onCloseShowPermissionPopup()
		// 			}
		// 		}
		// 	}
		// });


		rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

		rtc.localAudioTrack.setAudioFrameCallback(this.agoraAudioBuffer, bufferSize)

		if (isVideoOn) {
			try {
				rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
				$('#myVideoStreaming').html('');
				rtc.localVideoTrack.play('myVideoStreaming');
				this.isVideoOn = true;
			} catch (error) {
				toastService.error('Camera is not available. Kindly refresh you page.')
				this.activeVideo = false;
				this.props.setControlsStatus({ activeVideo: false })
			}
		}
		_logger("create local audio/video track success", true);
		this.publishLocalAgoraStream()
	}

	// Publish the local stream
	publishLocalAgoraStream = async () => {

		// Use Agora Web SDK NG
		try {
			// Remove this line if the channel profile is live broadcast
			// await rtc.client.setClientRole("host");

			let tracks = [rtc.localAudioTrack];
			if (this.activeVideo) tracks.push(rtc.localVideoTrack);
			await rtc.client.publish(tracks);
			RtcStreamConnectionActions.triggerAction(true)
			this.props.setControlsStatus({ activeAudio: true, joining: false })
			_logger("publish success", true);
		} catch (e) {
			_logger("publish failed", e);
		}
	}

	controlStream = (p: { activeAudio: boolean, activeVideo: boolean, leaveSpace: boolean }) => {
		if (p.leaveSpace) {
			this.leaveSpace = true;
			this.leaveAgoraChannel();
			return;
		}
		if (p.activeVideo !== this.activeVideo) this.toggleVideo(p.activeVideo)
		if (p.activeAudio !== this.activeAudio) this.toggleAudio(p.activeAudio)
	}

	toggleVideo = async (status: boolean) => {
		this.activeVideo = status;
		let { user } = this.state
		let { activeSpace } = this.props
		let spaceOwner = activeSpace && activeSpace._id ? activeSpace.userId : null

		if (rtc.client && rtc.localAudioTrack) {
			// await rtc.client.unpublish(rtc.localAudioTrack);
			// this.initializeAgoraStream(true);

			rtc.localVideoTrack = rtc.localVideoTrack ?? await AgoraRTC.createCameraVideoTrack();
			if ((user && spaceOwner) && (user._id === spaceOwner._id)) {

				$('#myVideoStreaming').html('');
				if (status) {
					rtc.localVideoTrack.play('myVideoStreaming');
					await rtc.client.publish([rtc.localVideoTrack]);
					this.isVideoOn = true;
					this.showStudioOwnerVideo()
					return;
				} else {
					rtc.localVideoTrack?.stop();
					await rtc.client.unpublish([rtc.localVideoTrack]);
					this.isVideoOn = false;
					this.showStudioOwnerProfileImage();
				}
			} else {

				$('#userVideoStreaming').html('');
				if (status) {
					rtc.localVideoTrack.play('userVideoStreaming');
					await rtc.client.publish([rtc.localVideoTrack]);
					this.isVideoOn = true;
					this.showLocalVideo()
					return;
				} else {
					rtc.localVideoTrack?.stop();
					await rtc.client.unpublish([rtc.localVideoTrack]);
					this.isVideoOn = false;
					this.showLocalProfileImage();
				}
			}
		}
		// if (status) {
		// 	await rtc.localVideoTrack && rtc.localVideoTrack.setEnabled(true)
		// 	this.showLocalVideo()
		// } 
	}

	showLocalVideo = () => {
		$('#user-videoprofile').css('display', 'none');
		$('#userVideoStreaming').css('display', 'block');
	}

	showLocalProfileImage = () => {
		$('#user-videoprofile').css('display', 'block');
		$('#userVideoStreaming').css('display', 'none');
	}

	showStudioOwnerVideo = () => {
		$('#my-videoprofile').css('display', 'none');
		$('#myVideoStreaming').css('display', 'block');
	}

	showStudioOwnerProfileImage = () => {
		$('#myVideoStreaming').css('display', 'none');
		$('#my-videoprofile').css('display', 'block');
	}

	toggleAudio = async (status: boolean) => {
		if (status) {
			await rtc.localAudioTrack && rtc.localAudioTrack.setVolume(1000)
		} else {

			await rtc.localAudioTrack && rtc.localAudioTrack.setVolume(0)
		}
		this.activeAudio = status;
	}

	listenToStreams = (): void => {
		if (!rtc.client) return


		// On User Published
		rtc.client.on("user-published", async (user: any, mediaType: any) => {

			console.log("subscribe success", user.uid, mediaType);
			await rtc.client.subscribe(user, mediaType);
			this.subscribedUsers[user.uid] = user.uid
			const streamID = this.remoteStreamIdGenerator(user.uid);


			if (mediaType === "video" || mediaType === "all") {
				const remoteVideoTrack = user.videoTrack;
				// ownerVideoStreaming
				if (this.isSpaceOwner(user.uid)) {
					// if ($(`#myVideoStreaming`).length) $(`#myVideoStreaming`).html('');
					await setTimeout(() => {
						remoteVideoTrack.play(document.getElementById('myVideoStreaming'));
					}, 2000);
					// $('#my-videoprofile').css('display', 'none');
					$('#myVideoStreaming').css('display', 'block');

				} else {
					if ($(`#${streamID}`).length) $(`#${streamID}`).html('');
					await setTimeout(() => {
						remoteVideoTrack.play(document.getElementById(streamID));
					}, 2000);
					// $(`#profile${streamID}`).css('display', 'none')
					$(`#${streamID}`).css('display', 'block')
				}
				// Hide profile
				// Show video
			}

			if (mediaType === "audio" || mediaType === "all") {
				if (this.isSpaceOwner(user.uid)) {

					const remoteAudioTrack = user.audioTrack;
					remoteAudioTrack.play();
					// this.showStudioOwnerProfileImage()
				} else {
					if ($(`#${this.userStreamDiv(user.uid)}`).length) {
						const remoteAudioTrack = user.audioTrack;
						remoteAudioTrack.play();
					} else {

						this.addView(user.uid);
						const remoteAudioTrack = user.audioTrack;
						remoteAudioTrack.play();
					}
				}



				// Get `RemoteAudioTrack` in the `user` object.
				// Play the audio track. Do not need to pass any DOM element
			}
			this.sendTotalNumberOfStream();
		});

		// On User Unpublished
		rtc.client.on("user-unpublished", async (user: any, mediaType: any) => {
			// this.removeView(user.uid)
			// delete this.subscribedUsers[user.uid];
			this.sendTotalNumberOfStream();
		})

		// On mute  Audio/Video
		rtc.client.on('user-mute-updated', (user: IAgoraRTCRemoteUser, mediaType: any) => {
			console.log("mute success", { user, mediaType });
			const uid: any = user.uid;
			const streamID = this.remoteStreamIdGenerator(uid);
			if (!user.hasVideo) {

				// if (this.isSpaceOwner(uid)) {
				// 	this.showStudioOwnerProfileImage()
				// 	return
				// }
				// Hide Video
				$(`#${streamID}`).css('display', 'none')
				// Show Prfile
				$(`#profile${streamID}`).css('display', 'block')
				return;
			}
			// if (this.isSpaceOwner(uid)) {
			// 	this.showStudioOwnerVideo()
			// 	return
			// }
			// Hide profile
			$(`#profile${streamID}`).css('display', 'none')
			// Show video
			$(`#${streamID}`).css('display', 'block')
		})

		rtc.client.on('connection-state-change', (evt) => {
			if (evt === 'DISCONNECTED') {
				this.spaceID = null;
			}
		})

		rtc.client.on('user-left', (user: IAgoraRTCRemoteUser) => {
			this.removeView(user.uid)
		})
	}

	sendTotalNumberOfStream = () => {
		this.props.getTotalNumberOfStream(Object.keys(this.subscribedUsers).length)
	}

	userStreamDiv = (id: string): string => 'main' + id;
	remoteStreamIdGenerator = (id: string): string => 'remote_stream_' + id;
	interimTranscriptGenerator = (id: string): string => 'interim_transcript_' + id;
	finalTranscriptGenerator = (id: string): string => 'final_transcript_' + id;

	isSpaceOwner = (id: string) => {
		let { user } = this.state
		let spaceOwner = user?.activeSpace && user?.activeSpace._id ? user?.activeSpace?.userId : null

		return (spaceOwner && (spaceOwner._id === id))
	}

	addView = async (id: string) => {
		const member: IUser = (await userService.getUsersByID(id, true)).data;
		const streamID = this.remoteStreamIdGenerator(id);
		// this.setState({ subscribedUsers: { ...this.subscribedUsers } }, this.forceUpdate)
		// $(`#${this.mainDiv()}`).append(`
		$(`
			<div class="at-chatvideo ${id}" id='${this.userStreamDiv(id)}'>
				<div class="at-img-wrap">
					<figure> 
						<div class="image-holder stream-video-container">
							<img id='profile${streamID}' src='${member.profile ? userImage(member.profile) : getInitials(member.firstName, member.lastName)}' alt=""/>
							<div id='${streamID}' class='user-streaming'></div>
						</div> 
					</figure>
					${member.isDeaf ? `<span class='at-hand-span'>
							<i class='icon-hand'></i>
						</span>` : ''
			}
					<span class='at-user-fullvideo-switch' userId="${id}">
						<i class='icon-full-screen'></i>
						<i class='icon-restore'></i>
					</span>
					<span class='at-personname'>${member.firstName} ${member.lastName}</span>
				</div>
				<!--	<div class="at-speech-wrap">
					<p id='${this.finalTranscriptGenerator(member._id)}'></p>
				</div>  -->
			</div>
			`
		).insertAfter('#personalVideo')

		$('.video-call-stream').css('width', '200px').css('height', '200px')

		let reactProps = this;
		$('.at-user-fullvideo-switch').on('click', function (e) {
			const userStreamElement: any = reactProps.userStreamDiv($(this).attr('userId') || '')
			if (!reactProps.enlargeVideo) {
				console.log("hi");

				$('#at-wrapper').addClass('wrapper-with-large-video')
				$(`#${userStreamElement}`).addClass('user-video-enlarge')
			} else {
				console.log("no hi");

				$('#at-wrapper').removeClass('wrapper-with-large-video')
				$(`.at-chatvideo`).removeClass('user-video-enlarge')
			}
			reactProps.enlargeVideo = !reactProps.enlargeVideo;
		})

		// // Hide profile
		// $(`#${streamID}`).css('display', 'none')
		// // Show video
		// $(`#profile${streamID}`).css('display', 'block')

		// let elem = document.getElementById(this.userStreamDiv(id))
		// 		if(this.isSpaceOwner(this.props.user._id) && this.props.showStandard){
		// 			if (elem){
		// 				elem.classList.add("at-left-corner")
		// 			}
		// 		} else {
		// 			if (elem){
		// 				elem.classList.remove("at-left-corner")
		// 			}
		// 		}

		if (this.isSpaceOwner(this.props.user._id) && !this.props.expandStreamingList && Object.keys(this.state.subscribedUsers).length === 2) {
			this.props.onExpandList()
		}
	}

	removeView = (id: any) => {

		delete this.subscribedUsers[id];
		this.setState({ subscribedUsers: { ...this.subscribedUsers } }, this.forceUpdate)
		if (this.isSpaceOwner(id)) {
			this.showStudioOwnerProfileImage()
			return
		}
		_logger(`removeView:`, this.userStreamDiv(id))
		console.log('======================================================>>>>>', this.userStreamDiv(id))
		$(`#${this.userStreamDiv(id)}`).remove()
	}

	startMicrophone = (stream: any) => {
		input = context.createMediaStreamSource(stream)
		input.connect(processor)

		processor.onaudioprocess = (e) => {
			this.microphoneProcess(e);
		}
	}

	microphoneProcess = (e: any) => {
		if (this.currentUser && this.activeSpeechToText && this.activeAudio) {
			const left = e.inputBuffer.getChannelData(0);

			const gSTTBuffer = downsampleBuffer(left, context.sampleRate, 16000)
			if (!isEmptyBuffer(gSTTBuffer)) {
				this.startGoogleSpeechToText(gSTTBuffer)
			}
		}
	}

	agoraAudioBuffer = (e: AudioBuffer) => {
		if (this.currentUser && this.activeSpeechToText && this.activeAudio) {
			const left = e.getChannelData(0);
			const gSTTBuffer = downsampleBuffer(left, e.sampleRate, 16000)
			if (!isEmptyBuffer(gSTTBuffer)) {
				this.startGoogleSpeechToText(gSTTBuffer)
			}
		}
	}

	leaveAgoraChannel = async (left = false) => {

		if (!rtc.client) return;

		RtcStreamConnectionActions.triggerAction(false)
		if (left) {
			this.spaceID = null;
			this.props.spaceLeft()
		}

		socketNode.emit(REMOVE_USER_FROM_SPACE, this.currentUser)
		// Destroy the local audio and video tracks
		rtc.localAudioTrack && rtc.localAudioTrack.close();
		rtc.localVideoTrack && rtc.localVideoTrack.close();

		// Traverse all remote users
		(rtc.client.remoteUsers || []).forEach((user: any) => {
			// Destroy the dynamically created DIV container
			this.removeView(user.uid);
		});

		// Leave the channel
		try {
			await rtc.client.leave();
			rtc = { ...initialRtcSettings }
		} catch (error) { }
		this.resetAllProps();
		return Promise.resolve();
	}

	enableSocket = () => {
		socketNode.on('connect', () => {
			_logger('=====================>>> Connection made.', true);

			if (this.spaceID && this.props.isSpaceActivated && !this.isSocketConnected) {
				this.registerMeetingWithSocket();
				this.initializeGoogleSpeechToText()
			}
		})
		socketNode.on('disconnect', () => {
			this.isSocketConnected = false;
		})
	}

	registerMeetingWithSocket = () => {
		socketNode.emit(CONNECTED_USER, this.currentUser)

		socketNode.emit(GSTT.CREATE_MEETING, {
			channel: this.spaceID,
			userId: this.currentUser._id,
			username: this.currentUser.firstName
		})
	}

	addNewMemberSocket = (spaceID: string) => {

		socketNode.on(spaceID, (user: any) => {
			this.props.addUserToSpace(user)
			let { members } = this.state;
			members[user?._id] = user;
			this.setState({ members: { ...members } }, this.forceUpdate)
		})
	}

	initializeGoogleSpeechToText = (): void => {

		if (this.activeSpeechToText) socketNode.emit(TURN_ON_SPEECH_TO_TEXT, this.props.user);

		this.speechToTextStatusSocket();
		this.googleSpeechToTextTranscriptSocket();

	}

	speechToTextStatusSocket = (): void => {
		socketNode.on(`${SET_RECOGNITION_STATUS}-${this.spaceID}`, (status: boolean) => {
			if (status) this.initGoogleSpeechToText()
			else this.destoryGoogleSpeechToText()
		})
	}

	googleSpeechToTextTranscriptSocket = (): void => {
		socketNode.on(`${GSTT.INTERIM_RESPONSE}-${this.spaceID}`, this.generateMessage)
		socketNode.on(`${GSTT.FINAL_RESPONSE}-${this.spaceID}`, this.generateMessage)
	}

	generateMessage = (data: { message: string, whoIsSpeaking: string, username: string, isFinal: boolean }) => {
		const msg = `<strong>${data.username}:</strong> ${data.message}`
		$('#transcript').html(msg)
		var elem: any = document.getElementById('transcript');
		if (elem && elem.scrollTop) elem.scrollTop = elem?.scrollHeight;
	}

	// Speach To Text Services
	initGoogleSpeechToText = (): void => {
		if (!this.currentUser) return;
		this.activeSpeechToText = true;
		console.log('Initialize Google Speech To Text', this.currentUser._id);
		socketNode.emit(GSTT.INIT_GOOGLE_CLOUD_STREAM, { channel: this.spaceID, userId: this.currentUser._id });
	}

	startGoogleSpeechToText = (buffer: any): void => {
		socketNode.emit(GSTT.START_GOOGLE_CLOUD_STREAM, { channel: this.spaceID, userId: this.currentUser._id, buffer });
	}

	destoryGoogleSpeechToText = (): void => {
		if (!this.currentUser) return;
		this.activeSpeechToText = false;
		console.log('Destroy Google Speech To Text', null);
		socketNode.emit(GSTT.END_GOOGLE_CLOUD_STREAM, { channel: this.spaceID, userId: this.currentUser._id });
	}

	getUsersProfiles = () => {
		const currentUserID = this.currentUser && this.currentUser._id;
		const members = Object.values(this.state.members).filter((user: IUser) => !this.state.subscribedUsers[user._id] && user._id !== currentUserID)
		return members.map((member: IUser, index: number) => (
			<div className="at-chatvideo" key={member._id} id={this.userStreamDiv(member._id)}>
				<div className="at-img-wrap">
					<figure>
						<div className="image-holder no-border stream-video-container">
							<img src={member.profile ? userImage(member.profile) : getInitials(member.firstName, member.lastName)} alt="" />
						</div>
					</figure>
					{
						member.isDeaf && <span className="at-hand-span">
							<i className='icon-hand'></i>
						</span>
					}
					{/* Remove from SprintONE */}
					{/* <span className="at-star-span">
						<img src={images.star_yellow} alt="" />
					</span> */}
					<span className='at-personname'>{member.firstName} {member.lastName}</span>
				</div>
				{/* <div className="at-speech-wrap">
					<p id={`number-${index}`}></p>
				</div> */}
			</div>
		))
	}

	scrollToTop = (id: string) => {
		const el: any = $(`#${this.userStreamDiv(id)}`).offset();
		if (!el || !el.top) return;
		$('#agoraStreams').animate({
			scrollTop: el.top - 200
		}, 2000);
	}

	resetAllProps = () => {
		this.activeSpeechToText = false;
		this.activeAudio = true;
		this.isVideoOn = false;
		this.subscribedUsers = {};
		rtc = { ...initialRtcSettings };

		this.setState({ subscribedUsers: {} })
		this.showLocalProfileImage();
		if (this.activeSpeechToText) {
			this.destoryGoogleSpeechToText();

		}
	}

	checkMemberList = () => {
		let keys = Object.keys(this.state.subscribedUsers)
		let { user } = this.state
		let spaceOwner = user?.activeSpace && user?.activeSpace._id ? user?.activeSpace?.userId : null
		if (spaceOwner && !keys.includes(spaceOwner._id) && keys.length > 1) {
			return true
		}
		if (spaceOwner && keys.includes(spaceOwner._id) && keys.length > 2) {
			return true
		}
	}

	layoutIcon = () => {
		let { showView, showRow, showGrid, showStack, showStandard, expandStreamingList, isSpaceActivated } = this.props
		if (showStandard && isSpaceActivated) {
			return 'icon-standard'
		}
		if (showStack && isSpaceActivated) {
			return 'icon-stack'
		}
		if (expandStreamingList && isSpaceActivated) {
			return 'icon-gallery2'
		}
		if (showGrid && isSpaceActivated) {
			return 'icon-gallery2'
		}

		return 'icon-grid'
	}

	render() {
		const { user, message } = this.state;
		let { connected, enlargeBands, expandStreamingList, onEnlargeBands, onExpandList, lockUnlockSpace, activeSpace, isSpaceAdmin, toggleAudio, activeAudio, toggleVideo, toggleSpeechToText, activeVideo, activeSpeechToText, joining, isSpaceActivated, deactiveSpace, activateSpace, leaveSpace, onStandardView, showGrid, showStack, toggleGrid, toggleStack, showStandard, onClickingOnExist, showTutorial } = this.props
		let spaceOwner = activeSpace && activeSpace._id ? activeSpace.userId : null
		return (
			<Fragment>
				<div id='agoraStreams' className="streams-main scroll-style-3">
					{(user && user._id && !this.isSpaceOwner(user._id)) && (<div id="user-container" className="at-myvideo">
						<div className="at-myvideo-inner">
							<figure>
								<div className="at-overlay"></div>
								<div id="userVideoStreaming" className='image-holder' ></div>
								<div id='user-videoprofile' className="image-holder">
									<img src={user.profile ? userImage(user.profile) : getInitials(user.firstName, user.lastName)} className="at-video-img" alt="" />
								</div>
							</figure>
							<span className="at-hand-span">
								<i className='icon-hand'></i>
							</span>
							<span className='at-personname'>{user.firstName}</span>
						</div>
					</div>)}
					{
						(spaceOwner && spaceOwner._id) && (user && user._id) && (
							<>
								<div id="personalVideo">
									<div className='video-image-wrapper'>
										<div id="myVideoStreaming" className="stream-video-container"></div>
										<div id='my-videoprofile' className="stream-video-container">
											<img id='myprofileImage' src={spaceOwner.profile ? userImage(spaceOwner.profile) : getInitials(spaceOwner.firstName, spaceOwner.lastName)} alt="" />
										</div>
										<span>{spaceOwner.firstName} {spaceOwner.lastName}</span>
									</div>
									{/* <div className="at-speech-wrap">
									<p id={this.finalTranscriptGenerator(user._id)}></p>
									</div> */}
									<SpaceChatBoxComponent userId={user._id} />

									<div className="at-topcontorls">
										{/* <div className="at-lefttop-controls">

											{
												isSpaceAdmin && <span className="at-cameraicon"
													onClick={(e) => {
														e.preventDefault()
														lockUnlockSpace()
													}}

													onTouchEnd={(e) => {
														e.preventDefault()
														lockUnlockSpace()
													}}
												>
													<i className={`icon-lock ${activeSpace?.isLocked && 'c-sunshade'}`} ></i>
												</span>
											}
										</div> */}

										{/* {
										!showControls && (
											<button className="btn btn-info btn-block mt-3 mb-2" disabled>
												<div className="spinner-border c-sunshade wh-25 mr-2" role="status">
													<span className="sr-only">Loading...</span>
												</div>
												Joining <i>{user.activeSpace.name}</i>...
											</button>
										)
									} */}
										<ul className="at-controlul at-controlulbottom">
											<li
												onClick={(e) => {
													e.preventDefault()
													toggleAudio()
												}}

												onTouchEnd={(e) => {
													e.preventDefault()
													toggleAudio()
												}}
												className={`${!connected && 'disabled'}`}
											>
												<a>
													<i className={`${activeAudio ? 'icon-sound c-sunshade' : 'icon-mute'}`}></i>
												</a>
											</li>
											<li
												onClick={(e) => {
													e.preventDefault()
													toggleVideo()
												}}
												onTouchEnd={(e) => {
													e.preventDefault()
													toggleVideo()
												}}
												className={`${!connected && 'disabled'}`}
											>
												<a>
													<i className={`${activeVideo ? 'icon-video-camera c-sunshade' : 'icon-video-close'}`}></i>
												</a>
											</li>
											<li
												onClick={(e) => {
													e.preventDefault()
													toggleSpeechToText()
												}}
												onTouchEnd={(e) => {
													e.preventDefault()
													toggleSpeechToText()
												}}
												className={`${!connected && 'disabled'}`}
											>
												<a>
													<i className={`icon-chat ${activeSpeechToText ? 'c-sunshade' : ''}`}></i>
												</a>
											</li>
										</ul>
										<ul className="at-controlul at-controlultop">
											{/* {
												isSpaceAdmin && <li
													onClick={(e) => {
														e.preventDefault()
														inviteNewUser()
													}}
													onTouchEnd={(e) => {
														e.preventDefault()
														inviteNewUser()
													}}
												>
													<a>
														<i className={`icon-add-user ${openInviteModal ? 'c-sunshade' : ''}`}></i>
													</a>
												</li>
											} */}
											{/* <span className={`at-cameraicon at-fullscreenbtn ${!connected || isFullScreenViewEnable ? 'disabled' : ''}`} onClick={(e) => {
												e.preventDefault()
												onEnlargeBands()
											}}
												onTouchEnd={(e) => {
													e.preventDefault()
													onEnlargeBands()
												}}
											>
												<i className={`${!enlargeBands ? 'icon-expand' : 'c-sunshade icon-minimize-view'}`}></i>
											</span> */}

											<ul className={`at-dropdownul-views `}>
												<li onClick={(e) => {
													e.preventDefault()
													onStandardView()
												}}
													onTouchEnd={(e) => {
														e.preventDefault()
														onStandardView()
													}}
													className={`${showStandard ? 'at-acitve-view' : ''}`}
													title="Standard"
												>
													{/* <h6 className="at-dropdownheading">Standard</h6> */}
													<i className="icon-standard"></i>
												</li>
												<li onClick={(e) => {
													e.preventDefault()
													onExpandList()
												}}
													onTouchEnd={(e) => {
														e.preventDefault()
														onExpandList()
													}}
													className={`${expandStreamingList ? 'at-acitve-view' : ''}`}
													title="Gallery"
												>
													{/* <h6 className="at-dropdownheading">Gallery</h6> */}
													<i className="icon-gallery-view"></i>
												</li>
												<li
													onClick={(e) => {
														e.preventDefault()
														toggleStack()
													}}
													onTouchEnd={(e) => {
														e.preventDefault()
														toggleStack()
													}}
													className={`${showStack ? 'at-acitve-view' : ''}`}
													title="Stack"
												>
													{/* <h6 className="at-dropdownheading">Stack</h6> */}
													<i className="icon-stack"></i>
												</li>

												{/* <li
													onClick={(e) => {
														e.preventDefault()
														toggleRow()
													}}
													onTouchEnd={(e) => {
														e.preventDefault()
														toggleRow()
													}}>
													<h6 className="at-dropdownheading">Row</h6>
													<i className="icon-row"></i>
												</li> */}
												<li
													onClick={(e) => {
														e.preventDefault()
														toggleGrid()
													}}
													onTouchEnd={(e) => {
														e.preventDefault()
														toggleGrid()
													}}
													className={`at-btn-fullscreen ${showGrid ? 'at-acitve-view' : ''}`}
													title="Full Screen"
												>
													{/* <h6 className="at-dropdownheading">Full Screen</h6> */}
													<i className="icon-gallery2"></i>
												</li>
												<li
													onClick={(e) => {
														e.preventDefault()
														onEnlargeBands()
													}}
													onTouchEnd={(e) => {
														e.preventDefault()
														onEnlargeBands()
													}}
													className={`at-btnlargescreen ${enlargeBands ? 'at-acitve-view' : ''}`}
													title="Large Screen"
												>
													{/* <h6 className="at-dropdownheading">Stack</h6> */}
													<i className={`${!enlargeBands ? 'icon-expand' : 'icon-minimize-view'}`}></i>
												</li>
											</ul>
											<ul className="at-powerul">
												{/* <li> */}
												{/* <span className={`at-btn-views at-cameraicon ${!connected && 'disabled'}`}
														onClick={(e) => {
															e.preventDefault()
															toggleView()
														}} 
														onTouchEnd={(e) => {
															e.preventDefault()
															toggleView()
														}}
													> */}
												{/* <i className={`${expandStreamingList ? 'icon-rectangle' : 'icon-grid'}`}></i> */}
												{/* <i className={this.layoutIcon()}></i><span>View</span> */}
												{/* </span>
												</li> */}
												{/* {user && this.isSpaceOwner(user?._id) && Object.keys(this.state.subscribedUsers).length > 1 &&  <span className={`at-cameraicon at-show-icon ${!connected && 'disabled'}`}
												onClick={(e) => {
													e.preventDefault()
													onExpandList()
												}}
												onTouchEnd={(e) => {
													e.preventDefault()
													onExpandList()
												}}
											>
												<i className={`${expandStreamingList ? 'icon-rectangle' : 'icon-grid'}`}></i>
											</span>}

											{user && !this.isSpaceOwner(user?._id) && this.checkMemberList() && <span className={`at-cameraicon at-show-icon ${!connected && 'disabled'}`}
												onClick={(e) => {
													e.preventDefault()
													onExpandList()
												}}
												onTouchEnd={(e) => {
													e.preventDefault()
													onExpandList()
												}}
											>
												<i className={`${expandStreamingList ? 'icon-rectangle' : 'icon-grid'}`}></i>
											</span>} */}

												<li>
													{
														<span className={`at-cameraicon at-countusers`}>
															<i className="icon-user-2"></i>	{Object.keys(this.state.subscribedUsers).length}
														</span>
													}
												</li>
												<li>
													{
														// isSpaceAdmin && <span className="at-cameraicon"
														isSpaceAdmin && <span className="at-cameraicon py-0"
															onClick={(e) => {
																e.preventDefault()
																lockUnlockSpace()
															}}

															onTouchEnd={(e) => {
																e.preventDefault()
																lockUnlockSpace()
															}}
														>
															<i className={`icon-lock ${activeSpace?.isLocked && 'c-sunshade'}`} ></i>
														</span>
													}
												</li>
												<li className="mr-2">
													<a title="Info">
														<TutorialIcon onClick={this.props.onOpenTutorial} screen={EScreens.MANGE_SPACE} hideText={true} iconName="icon-info" />
													</a>
												</li>

												{
													(!isSpaceActivated && !connected && !joining) && <li className="at-lipowericon">
														<LongPressAableComponent
															click={() => activateSpace()}
															onLongPress={() => deactiveSpace()}
														>
															<a>
																<i className='icon-power text-c-sunshade'></i>
															</a>
														</LongPressAableComponent>
													</li>
												}


												{
													joining && <li>
														<a>
															<i className='spinner-border text-c-sunshade wh-20'></i>
														</a>
													</li>
												}
												{
													(isSpaceActivated && connected) && <li>

														<LongPressAableComponent
															click={() => leaveSpace()}
															onLongPress={() => deactiveSpace()}
														>
															<a>
																<i className='icon-power c-sunshade'></i>
															</a>
														</LongPressAableComponent>
													</li>
												}
												<li className="at-logout"
													onClick={(e) => {
														e.preventDefault()
														onClickingOnExist()
													}}
													onTouchEnd={(e) => {
														e.preventDefault()
														onClickingOnExist()
													}}
												>
													<a >
														<i className="icon-exit"></i>
													</a>
												</li>

											</ul>
										</ul>

									</div>
								</div>
							</>
						)
					}

					{
						// this.getUsersProfiles()
					}

					{/* {user && user._id && <SpaceChatBoxComponent userId={user._id} />} */}
					<div className="at-gridbtn-holder">
						<button className="at-grid-btn" onClick={() => this.props.onDecreasingScreen()}><i className="icon-menu"></i></button>
						<button className="at-grid-btn" onClick={() => this.props.onIncreasingScreen()}><i className="icon-grid-small"></i></button>
					</div>
				</div>
				{showTutorial && (
					<UniversalModal
						open={showTutorial}
						onClose={this.props.onCloseTutorial}
						position="agorainfo"
					>
						<ScreenTutorial screen={EScreens.MANGE_SPACE} />
						{/* at-categorybox ends */}
					</UniversalModal>
				)}


			</Fragment>
		)
	}
}

export default AgoraNextGeneration

