export enum EWeightFactorType {
    ART = 'ART',
    FRAME = 'FRAME',
    PACKAGE = 'PACKAGE',
}
export enum EWeightFactorUnit {
    POUNDS_PER_SQUARE_INCH = 'POUNDS_PER_SQUARE_INCH',
    POUNDS_PER_LINEAR_INCH = 'POUND_PER_LINEAR_INCH',
    POUNDS_PER_CUBIC_INCH = 'POUNDS_PER_CUBIC_INCH',
}

export enum ECostFactorUnit {
    USD_PER_SQUARE_INCH = 'USD_PER_SQUARE_INCH',
    USD_PER_LINEAR_INCH = 'USD_PER_LINEAR_INCH',
    USD_PER_CUBIC_INCH = 'USD_PER_CUBIC_INCH',
}