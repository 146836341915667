import React, { useRef } from 'react'
import { Editor, IAllProps } from '@tinymce/tinymce-react';

interface IProps {
    text: string,
    onChange: Function,
}
export const TmceEditor = ({ text, onChange }: IProps) => {
    const editorRef = useRef<any>(null);
    
    const onChangeEditorText = (e: any) => {
        onChange(e)
    }


    return (
        <div>
            <Editor
                apiKey="dhxfjxbio9ioui9sf28fj3nugkw23y8s6wggp19ckkxi6bcl"
                onInit={(evt, editor) => editorRef.current = editor}
                // initialValue="<p>This is the initial content of the editor.</p>"
                value={text}
                init={{
                    selector: 'textarea' as any,
                    height: 300,
                    menubar: false,
                    
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: `undo redo styleselect  bold underline italic alignleft aligncenter alignright alignjustify outdent indent
                    bullist numlist outdent indent removeformat link
                    `,
                    // toolbar: 'undo redo | formatselect | ' +
                    //     'bold italic backcolor | alignleft aligncenter ' +
                    //     'alignright alignjustify | bullist numlist outdent indent | ' +
                    //     'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                onEditorChange={(newValue: string, editor: any) => onChangeEditorText(newValue)}
            />
            {/* <button onClick={log}>Log editor content</button> */}
        </div>
    )

}