import { AxiosResponse, AxiosError } from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { images } from '../../../../../assets/image'
import { EArtistPrintType, EPrintingServiceType, EPrintType } from '../../../../../enums'
import { PLATFORM_FEE } from '../../../../../environment'
import { IFormates, IUser } from '../../../../../interfaces'
import { IResponse } from '../../../../../interfaces/db.interface'
import { IArtistServicesState, initialSelectedServices, IPrintingServices, IPrintingServiesPrices, IPrintingServiesSizes, ISelectedServiceState } from '../../../../../interfaces/services.interface'
import { consultantService } from '../../../../../services/consultantServices.service'
import { CurrencyFormat } from '../../../../common/CurrencyFormat'
import { ProposalImageView } from '../../../../common/ProposalImageView'

interface IProps {
    onAdd: Function
    format: IFormates
    selectedPrinter?: IUser
    onUpdateData: Function
    parentState: IArtistServicesState
}

interface IState {
    selectedServices: Array<ISelectedServiceState>
}



const initialState = {
    selectedServices: [],
}

export const SelectPrintMediumWithFrame = ({ onAdd, selectedPrinter, format, onUpdateData, parentState }: IProps) => {
    const [printingServices, setPrintingServices] = useState<Array<IPrintingServices>>([])
    const [framingServices, setFramingServices] = useState<Array<IPrintingServices>>([])
    const [selectedState, setSelectedState] = useState<ISelectedServiceState>({ ...initialSelectedServices })
    const [loading, setLoading] = useState<boolean>(false)
    const [sizeOptions, setSizeOptions] = useState<Array<IPrintingServiesPrices>>([])
    const [selectedServiceIndex, setSelectedServiceIndex] = useState<number>(NaN)

    const [state, setState] = useState<IState>({ ...initialState })

    const fetchServices = () => {
        if (!selectedPrinter) return
        setLoading(true)
        consultantService.getPrintingServicesByUserId(selectedPrinter._id).then((res: AxiosResponse<IResponse<Array<IPrintingServices>>>) => {
            if (res.data.success) {
                let prints = res.data.data.filter((p: IPrintingServices) => p.serviceType === EPrintingServiceType.PRINTING_OPTION)
                let framess = res.data.data.filter((p: IPrintingServices) => p.serviceType === EPrintingServiceType.FRAMING_OPTION)
                setPrintingServices([...prints])
                setFramingServices([...framess])
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            setLoading(false)
            console.log(err)
        })
    }

    useEffect(() => {
        fetchServices()
    }, [])

    const onChangeService = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target
        let print = printingServices.find((p: IPrintingServices) => p._id === value)
        if (print) {
            setSelectedState({
                ...selectedState,
                printingService: print
            })
            // setSizeOptions(print?.printingPrices)
        }
    }

    const onChangeFrameService = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target
        let print = framingServices.find((p: IPrintingServices) => p._id === value)
        if (print) {
            setSelectedState({
                ...selectedState,
                framingService: print
            })
            // setSizeOptions(print?.printingPrices)
        }
    }

    const onChangeServicePrice = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target
        let print = sizeOptions.find((s: IPrintingServiesPrices) => s.printingSizes._id === value)
        let frame = selectedState.framingService.printingPrices.find((s: IPrintingServiesPrices) => s.printingSizes._id === value)
        if (print) {
            setSelectedState({
                ...selectedState,
                selectedPrintrSevicePrice: { ...print },
                selectedFrameSevicePrice: { ...frame },
                selectedSize: print.printingSizes._id === frame?.printingSizes?._id ? print.printingSizes : undefined
            })
        }
    }

    const getTotalPrice = () => {
        let total = 0
        if (selectedState.printingService && selectedState.framingService && selectedState.selectedPrintrSevicePrice) {
            total = total + Number(selectedState.selectedPrintrSevicePrice.price + selectedState.selectedPrintrSevicePrice.shippingPrice) + Number(selectedState.selectedFrameSevicePrice.price) + Number(selectedState.selectedFrameSevicePrice.shippingPrice) + Number(PLATFORM_FEE)
            return `${total}`
        }
        return `${total}`
    }
    const setSizeData = () => {
        if (selectedState.framingService && selectedState.printingService) {
            let printPrices = selectedState.printingService.printingPrices.map((i: IPrintingServiesPrices) => i.printingSizes._id)
            let framePrices = selectedState.framingService.printingPrices.map((i: IPrintingServiesPrices) => i.printingSizes._id)

            let sizeArray = selectedState.printingService.printingPrices.filter((p: IPrintingServiesPrices) => {
                return framePrices.includes(p.printingSizes._id)
            })
            setSizeOptions([...sizeArray])
        }
    }

    const onClickOnAdd = () => {
        if (selectedState.printingService && selectedState.framingService && selectedState.selectedPrintrSevicePrice) {
            let data = selectedState
            let array = state.selectedServices

            data.totalCost = getTotalPrice()
            if (!isNaN(selectedServiceIndex)) {
                array[selectedServiceIndex] = data
                setSelectedServiceIndex(NaN)
            } else {

                array.push(data)
            }

            setState({
                ...state,
                selectedServices: [...array]
            })

            setSelectedState({
                ...initialSelectedServices
            })

        }
    }

    const onDeleteSelectedService = (index: number) => {
        let filterArray = state.selectedServices.filter((f: ISelectedServiceState, ind: number) => ind !== index)
        setState({
            ...state,
            selectedServices: [...filterArray]
        })
    }

    const onEditSelectedService = (index: number) => {
        setSelectedState({ ...state.selectedServices[index] })
        setSelectedServiceIndex(index)
        console.log("state.selectedServices[index]", state.selectedServices[index])
    }

    const setArtistChoice = () => {
        setSelectedState({
            ...selectedState,
            artistChoice: !selectedState.artistChoice
        })
    }

    const onAddServiceData = () => {
        if (!selectedPrinter) return
        let data = {
            selectedServices: state.selectedServices,
            serviceProvider: selectedPrinter._id,
            printType: EArtistPrintType.FRAMED
        }

        onAdd(data)
    }

    useEffect(() => {
        if (selectedState.framingService && selectedState.printingService) {
            setSizeData()
        }
    }, [selectedState.printingService, selectedState.framingService])

    useEffect(() => {
        if (parentState && parentState.selectedServices && parentState.selectedServices.length > 0) {
            setState({
                ...state,
                selectedServices: parentState.selectedServices
            })
        }
    }, [parentState])

    return <div className="at-addactionholder">
        <div className="at-formthemehead at-headselectprinter pb-2 ">
            <h2 className="at-textcenter">Select Print Medium, Frame, and Size</h2>
            <p>Small Print Framed</p>
        </div>
        <div className="at-selectprinter">
            <div className="at-greybgboxtable">
                <h4 className="at-heading-greybgbox">Selected Options</h4>
                <table className="table table-borderless at-tableprintermedium">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total Cost</th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.selectedServices && state.selectedServices.length > 0 && state.selectedServices.map((s: ISelectedServiceState, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{s.printingService.name}/{s.framingService.name}/{s.selectedSize.height} inches x {s.selectedSize.width} inches</td>
                                    <td>{s.totalCost}</td>
                                    <td>
                                        <div className="at-editformat-new">
                                            <i className="icon-delete2" onClick={() => onDeleteSelectedService(index)} />
                                            <i className="icon-edit2" onClick={() => onEditSelectedService(index)} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}

                        {/* <tr>
                            <td>Arches® Rag Photographique </td>
                            <td>$325.00</td>
                            <td>
                                <div className="at-editformat-new">
                                    <i className="icon-delete2" />
                                    <i className="icon-edit2" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Hahnemühle German Etching</td>
                            <td>$325.00</td>
                            <td>
                                <div className="at-editformat-new">
                                    <i className="icon-delete2" />
                                    <i className="icon-edit2" />
                                </div>
                            </td>
                        </tr> */}
                        {/* <tr>
                            <td>Hahnemühle Photo Rag® Bright</td>
                            <td>$325.00</td>
                            <td>
                                <div className="at-editformat-new">
                                    <i className="icon-delete2" />
                                    <i className="icon-edit2" />
                                </div>
                            </td>
                        </tr> */}

                    </tbody>
                </table>
            </div>
            <div className="row row-margin-lr7">
                <div className="col-6 pad-lr-7">
                </div>
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button type="submit" className="at-btn at-longbtn-h40" disabled={state.selectedServices.length === 0} onClick={() => onAddServiceData()} >Next</button>
                    </div>
                </div>
            </div>

            <div className="at-printerbox">
                <h4>Add Options</h4>

                <div className="form-group mt-3">
                    <span className="at-select at-floatlabel-h40 ">
                        <select className="floating-select" name="art_collection" value={selectedState.printingService?._id ? selectedState.printingService._id : ''} disabled={printingServices.length === 0} onChange={onChangeService} >
                            <option value="" disabled></option>
                            {printingServices && printingServices.length > 0 && printingServices.map((p: IPrintingServices) => {
                                return (
                                    <option key={p._id} value={p._id}>{p.name}</option>
                                )
                            })}
                        </select>
                        <label className="at-lineheight-27">Select Print Medium Option</label>
                    </span>
                </div>

                <div className="form-group mt-3">
                    <span className="at-select at-floatlabel-h40 ">
                        <select className="floating-select" name="art_collection" value={selectedState.framingService?._id ? selectedState.framingService._id : ''} disabled={framingServices.length === 0} onChange={onChangeFrameService} >
                            <option value="" disabled></option>
                            {framingServices && framingServices.length > 0 && framingServices.map((p: IPrintingServices) => {
                                return (
                                    <option key={p._id} value={p._id}>{p.name}</option>
                                )
                            })}
                        </select>
                        <label className="at-lineheight-27">Select Framing Option</label>
                    </span>
                </div>
 
                {/* <div className="form-group mt-3">
                    <span className="at-select at-floatlabel ">
                        <select className="floating-select" name="art_collection" >
                            <option value="" disabled></option>
                            <option value="">Option</option>
                            <option value="">Option</option>
                        </select>
                        <label className="at-lineheight-27">Select Print Medium Option</label>
                    </span>
                </div> */}

                <div className="form-group mt-3">
                    <span className="at-select at-floatlabel-h40 ">
                        <select className="floating-select" name="art_collection" disabled={sizeOptions.length === 0} value={selectedState.selectedPrintrSevicePrice?.printingSizes._id ? selectedState.selectedPrintrSevicePrice.printingSizes._id : ''} onChange={onChangeServicePrice} >
                            <option value="" disabled></option>
                            {sizeOptions && sizeOptions.length > 0 && sizeOptions.map((s: IPrintingServiesPrices) => {
                                return (
                                    <option key={s._id} value={s.printingSizes._id}>{s.printingSizes.height} X {s.printingSizes.width}</option>
                                )
                            })}
                            {/* <option value="">Select Print Medium Option</option> */}
                        </select>
                        <label className="at-lineheight-27">Select Print Size (inhces)</label>
                    </span>
                </div>

                {/* <div className="form-group mt-3">
                    <span className="at-select at-floatlabel ">
                        <select className="floating-select" name="art_collection" >
                            <option value="" disabled></option>
                            <option value="">Option</option>
                            <option value="">Option</option>
                        </select>
                        <label className="at-lineheight-27">Select Framing Option</label>
                    </span>
                </div> */}

                {/* <div className="form-group mt-3">
                    <span className="at-select at-floatlabel ">
                        <select className="floating-select" name="art_collection" >
                            <option value="" disabled></option>
                            <option value="">Option</option>
                            <option value="">Option</option>
                        </select>
                        <label className="at-lineheight-27">Select Print Size (inhces)</label>
                    </span>
                </div> */}
            </div>
            <div className="at-printerbox">
                <h5><span className="at-color-orange">Total Cost:</span> <CurrencyFormat amount={getTotalPrice() || 0} /></h5>
                <p className="at-notered">NOTE: This total cost will be added to your edition price in setting the total price shown to the buyer.</p>
            </div>
            <div className="at-tb-border at-tb-border-singleswitch">
                <div className="form-group form-w-100 at-form-custmargin">
                    <div className="at-deafhardholder">
                        <span className="at-onoffToggle">Artist Choice</span>
                        <div className="onoffswitch at-accept-offer" onClick={() => setArtistChoice()}>
                            <input type="checkbox" name="artist" checked={selectedState.artistChoice} className="onoffswitch-checkbox" id="myonoffswitch6" />
                            <label className="onoffswitch-label"></label>
                        </div>
                    </div>
                </div>
            </div>
            {selectedState.printingService && <div className="at-printerbox">
                <h6 className="at-printer-greyheading">PRINT MEDIUM</h6>
                <h6>{selectedState.printingService.name}: <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: selectedState.printingService.description }}></span></h6>
            </div>}
            {selectedState.framingService && <div className="at-printerbox">
                <h6 className="at-printer-greyheading">FRAMING OPTION</h6>
                <h6>{selectedState.framingService.name}: {selectedState.framingService.description && <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: selectedState.framingService.description }}></span>}</h6>
            </div>}
            {selectedState.selectedSize && <div className="at-printerbox">
                <h6>Size: <span className="at-span-printerbox" >{selectedState.selectedSize.height} inches X {selectedState.selectedSize.width} inches</span></h6>
            </div>}
            {selectedState.framingService && <div className="at-printerbox">
                <h6>Shipping:  {selectedState.framingService.shipmentInformation && <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: selectedState.framingService.shipmentInformation }}></span>}</h6>
            </div>}
            {selectedState.framingService && selectedState.framingService.images && <figure className="at-frameimg">
                <ProposalImageView isServicImage={true} item={selectedState.framingService} />
            </figure>}
            <div className="row row-margin-lr7">
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button className="at-btn at-btn-cancel at-longbtn-h40" >Delete</button>
                    </div>
                </div>
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button type="button" className="at-btn at-longbtn-h40" onClick={() => onClickOnAdd()} >{!isNaN(selectedServiceIndex) ? "UPDATE":'ADD'}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}