import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { routes } from "./index";
import NewsFeedsPage from "../pages/ArtCentral/NewsFeeds.page";
import BottomTabsComponent from "../components/ArtCentral/BottomTabs.component";
import { connect } from 'react-redux';
import NotFound404Page from "../pages/NotFound404.page";
import * as Admin from '../pages/Admin';
import Tabs from '../components/Admin/Tabs.component'
import {
    IStoreActionsStates,
    IStoreReducers,
} from '../interfaces/store.interface'
import { ZoomActions, EZoom } from '../services/zoom.service';
import { AdminRouteAction } from '../services';

interface IPageProps extends IStoreActionsStates, RouteComponentProps {
    [key: string]: any
}

class AdminRoute extends Component<IPageProps> {

    componentDidMount() {
        ZoomActions.triggerAction({ type: EZoom.NONE, value: null })
        AdminRouteAction.triggerAction(true)
    }

    componentWillUnmount(){
        ZoomActions.triggerAction({ type: EZoom.ART_COLLECTION, value: null })
        AdminRouteAction.triggerAction(false)
    }
    render() {
        const { token, user } = this.props
        return (
    
                
                <Switch>
                    <Route path={routes.adminRoutes.login} exact component={Admin.AdminLogin} />
                    {/* <Route path={routes.adminRoutes.index} exact component={Admin.AdminHomePage} /> */}
                    <Tabs {...this.props} />
                    <Route path="*" component={NotFound404Page} />
                </Switch>

        );
    }
}

const mapState = (state: IStoreReducers) => ({
    token: state.auth.access_token,
    spaces: state.spaces,
    misc: state.misc,
    spaceEnabled: state.user.activeSpace && state.user.activeSpace._id,
    user: state.user
})

export default connect(mapState, null)(AdminRoute);