import { zoomImageInterface } from "../../interfaces";
import { SET_IMAGE_TO_ZOOM, ACTIVATE_SPACE } from "../types";

export const zoomImageAction = (payload: zoomImageInterface) => ({
    type: SET_IMAGE_TO_ZOOM,
    payload
})

export const activateSpaceAction = (payload: boolean) => ({
    type: ACTIVATE_SPACE,
    payload
})