import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { dispatch } from "rxjs/internal/observable/pairs";
import { images } from "../../../assets/image";
import { ADD_NEW_MEMBER, USER_ALERT_SPACE_ENTER } from "../../../constants";
import { ERole } from "../../../enums";
import { getInitials } from "../../../helper";
import { ISpace, IUser } from "../../../interfaces";
import { routes } from "../../../router";
import { spaceService } from "../../../services";
import { socketNode } from "../../../services/socket.service";
import { userImage } from "../../../shared";
import {
  changeActiveSpace,
  fetchUserStudio,
  activateSpaceAction,
} from "../../../store/actions";
import {
  userSelector,
  authSelector,
  isSpaceActivatedSelector,
} from "../../../store/selectors";

interface IProps {
  space: ISpace;
  onClickOnFeed?: (u: any) => any;
  onClickOnGalleryWalkSpace?: Function;
  showArts: boolean;
}
export const GalleryWalkComponent = ({
  space,
  onClickOnFeed,
  onClickOnGalleryWalkSpace,
  showArts,
}: IProps) => {
  const dispatch = useDispatch();
  const user: IUser = useSelector(userSelector);
  const actiavtedSpace = useSelector(isSpaceActivatedSelector);
  const token = useSelector(authSelector).access_token;
  const history = useHistory();

  const [showUsers, setShoeUsers] = useState<boolean>(false);

  const onActivateCall = async () => {
    if (!space.userId) return;
    if (
      user.activeSpace &&
      user.activeSpace._id &&
      user.activeSpace._id === space._id &&
      actiavtedSpace
    )
      return;
    // const space: ISpace = (await spaceService.userArtCentralSpace(c.user._id)).data;
    changeActiveSpace && dispatch(changeActiveSpace(space));
    socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user });
    dispatch(fetchUserStudio(space.userId?._id));
    // sendUserAlert(c, `${user?.firstName} ${user?.lastName} has just entered your space.`, USER_ALERT_SPACE_ENTER, false, space._id)
    dispatch(activateSpaceAction(true));
  };

  const onClickArtGallery = (isActive: any) => {
    if (!space.userId) return;
    onActivateCall();
    if (isActive) {
      if (onClickOnFeed) onClickOnFeed(space.userId);
      history.push(`${routes.artCentral.collection.list}/${space.userId?._id}`);
    }
  };

  const onClickFeatureArt = () => {
    if (onClickOnGalleryWalkSpace) {
      onClickOnGalleryWalkSpace();
      return;
    }
  };

  const setName = (u: IUser) => {
    if (u && u.roles.includes(ERole.AGENT)) {
      return `${
        u.gallery_name ? u.gallery_name : `${u.firstName} ${u.lastName}`
      }`;
    } else {
      return `${u.firstName} ${u.lastName} Studio`;
    }
  };

  let isCurrentActive =
    user.activeSpace && user.activeSpace._id === space._id && actiavtedSpace;

  return (
    <div
      className={`at-gallerybox ${
        isCurrentActive ? "at-gallerybox-active" : ""
      }`}
    >
      <figure className="at-galleryfigure">
        {/* <ul className="at-controlul-general "> */}
        {/* <li>
                    <span className="at-controlicon " onClick={() => setShoeUsers(!showUsers)}><i className={showUsers ? "icon-rectangle" : "icon-grid"}></i></span>
                </li> */}
        {/* {space.activeUsers && <li><span className=" at-countusers">{space.activeUsers.length}</span></li>}
                <li>
                    <span className="at-controlicon" onClick={() => onActivateCall()}><i className={`icon-power ${isCurrentActive ? 'c-sunshade' : ''}`}></i></span>
                </li>
            </ul> */}
        {space && space.userId && (
          <img
            src={
              space?.userId?.profile
                ? userImage(space?.userId?.profile)
                : getInitials(space?.userId?.firstName, space?.userId?.lastName)
            }
            className="img-fluid"
            alt=""
          />
        )}
      </figure>
      <div className="at-galleryusers">
        {showUsers &&
          space.activeUsers &&
          space.activeUsers.length > 0 &&
          space.activeUsers.map((u: any) => {
            if (u?._id === space.userId?._id) return null;
            return (
              <div className="at-galleryuser">
                <span className="at-galleryuser-name">{u.firstName}</span>
                <figure className="at-galleryuser-figure at-figureFull">
                  <img
                    src={
                      u.profile
                        ? userImage(u.profile)
                        : getInitials(u.firstName, u.lastName)
                    }
                    alt=""
                  />
                  <div className="at-overlay"></div>
                </figure>
              </div>
            );
          })}
      </div>
      <div className="at-gallerytext">
        {space.userId && (
          <h4 className="mb-0">
            {setName(space.userId)}
            {showArts && (
              <div>
                <a
                  className="at-orangelink-borderbottom fs-14"
                  onClick={() => onClickFeatureArt()}
                >
                  Featured Art
                </a>
              </div>
            )}
          </h4>
        )}
        <div className="at-gallerytext-right">
          <button
            className={`at-btn mr-1 at-doubleextrasmallbtn cursor-default`}
          >
            <span className="m-auto">
              <i className="icon-user-2 at-gallerytext-icon cursor-default"></i>{" "}
              {space.activeUsers && space.activeUsers.length > 0
                ? space.activeUsers.length
                : 0}
            </span>
          </button>
          <button
            className={`at-btn at-doubleextrasmallbtn ${
              isCurrentActive ? "at-btn-blue" : ""
            }`}
            onClick={() => onClickArtGallery(isCurrentActive)}
          >
            {isCurrentActive ? "SEE COLLECTIONS" : "WALK IN"}
          </button>

          {/* {space.activeUsers && <span className={`at-cameraicon at-countusers`}>
                    <i className="icon-user-2"></i>	{space.activeUsers.length}
                </span>} */}
        </div>
      </div>
    </div>
  );
};
