import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { images } from '../../assets/image'
import LoadingComponent from '../../components/common/Loading.component'
import { ICollection, ICollectionItem } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { collectionService } from '../../services/collection.service'
import { collectionImage } from '../../shared/functions'
import { templateSelector } from '../../store/selectors'
import { routes } from './routes'

const ArtistPage = () => {
    const history = useHistory()
    const template = useSelector(templateSelector)
    const [collections, setCollections] = useState<Array<ICollection>>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchCollections = () => {
        setLoading(true)
        collectionService.listWithItems(template.user?._id).then((response: AxiosResponse<IResponse<Array<ICollection>>>) => {
            setCollections([...response.data.data])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchCollections()
    }, [])

    const renderCollection = (c: ICollection) => {
        let item = (c.items as Array<ICollectionItem>)
        if (c.isHide) {
            return null
        }
        
        return (
           item?.length > 0 ? <div className="col-sm-4" onClick={() => history.push(`${routes(template.url).artists.collectionView}/${c.id}`)}>
                <div className="At-EventBox">
                    {item && item.length > 0 && <figure className="At-FigureFull At-Figure-h300">
                        <img src={collectionImage(item[0], `${item[0].url}`, true)} className="img-fluid" alt="" />
                    </figure>}
                    <p >
                    {c.title}
                    </p>
                </div>
            </div> : null
        )
    }
    return (
        <div >
            <section className="At-SectionExhibitions At-Padding-t10b5 At-Section-Mh800">
                <div className="container">
                    <div className="row">
                        {collections && collections.length > 0 && collections.map((c: ICollection) => renderCollection(c))}
                    </div>
                </div>
                {loading && <LoadingComponent className='at-transaction-loader' />}
            </section>
        </div>
    )
}
export default ArtistPage