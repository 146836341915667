import { useDispatch, useSelector } from 'react-redux'
import { auctionEventType, BLOCKED_USER_BY_ADMIN, LOGOUT_CACHE_CLEAR_MANUAL } from '../constants'
import * as type from '../constants'
import { count } from 'console'
import { toastService, NotificationAction, NotificationCountAction, UserAlertAction, UserAlertCountAction, AuctionAddNewArtAction, AuctionArtBiddingUpdateAction, AuctionArtBiddingStopAction, AuctionArtPurchasedAction, AuctionArtBiddingRestartAction } from '../services'
export const notificationActions = (event: any, count: number, userAlertCount: number, logoutAction: Function, saveUser: Function, manaulLogoutAndCacheClear: Function) => {
    switch (event.type) {
        case BLOCKED_USER_BY_ADMIN:
            let obj: any = {}
            toastService.info("You have been blocked by admin")
            logoutAction()
            saveUser(obj)
            break;
        // case LOGOUT_CACHE_CLEAR_MANUAL:
        //     toastService.info("Logout")
        //     manaulLogoutAndCacheClear()
        //     break;
        case type.ACCEPT_OFFER:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.REJECT_OFFER:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CONFORMATION_OF_SHIPMENT:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.RELEASE_OF_FUNDS:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.COMMISSION_WORK_PAYMENT:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.SEND_PURCHASE_RECIEVE:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.RELEASE_OF_FUNDS_APPROVAL:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.RETURN_OF_ART_NOTICE:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.RECIEVED_OFFER:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.TRANSFER_PAYMENT:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.TRANSFER_PAYMENT_PRINTING_SERVICE_PROVIDER:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.REFUND_PAYMENT:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.STRIPE_ACCOUNT:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.RETURN_ALLOWED:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.RETURN_NOT_ALLOWED:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.RETURN_EXPIRE:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.RETURN_SHIPPED:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.COMMISSION_WORK_REQUEST:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.COMMISSION_WORK_PROPOSAL:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.COMMISSION_WORK_PROPOSAL_APPROVED:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.COMMISSION_WORK_PROPOSAL_rejected:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;

        case type.COMMISSION_WORK_REJECTED:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.SUBSCRIPTION_REFUND:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.END_ACCOUNT_ASSIGNMENT:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.ON_BUY_SERVICE:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CURATORIAL_SEND_INVITATIONS:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CURATORIAL_RESPONSE_INVITATIONS:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CURATORIAL_SEND_PROPOSAL:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CURATORIAL_SEND_OFFER:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CURATORIAL_ACCEPT_OFFER:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CURATORIAL_REJECT_OFFER:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CURATORIAL_REQUEST_OFFER_PAYMENT:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;
        case type.CURATORIAL_OFFER_PAYMENT_PAID:
            NotificationAction.triggerAction(event)
            // Tunes.beep();
            NotificationCountAction.triggerAction(count + 1)
            toastService.info("You have a new notification")
            break;

        // User Alerts
        case type.USER_ALERT_SPACE_MESSAGE:
            UserAlertAction.triggerAction(event)
            // Tunes.beep();
            UserAlertCountAction.triggerAction(userAlertCount + 1)
            toastService.info("You have a new notification")
            break;

        case type.USER_ALERT_SPACE_ENTER:
            UserAlertAction.triggerAction(event)
            // Tunes.beep();
            UserAlertCountAction.triggerAction(userAlertCount + 1)
            toastService.info("You have a new notification")
            break;
        case type.USER_ALERT_SPACE_MESSAGE_REPLY:
            UserAlertAction.triggerAction(event)
            // Tunes.beep();
            UserAlertCountAction.triggerAction(userAlertCount + 1)
            toastService.info("You have a new notification")
            break;
        case type.USER_ALERT_TEMPLATE_CONTACT:
            UserAlertAction.triggerAction(event)
            // Tunes.beep();
            UserAlertCountAction.triggerAction(userAlertCount + 1)
            toastService.info("You have a new notification")
            break;

        // User Auction
        case auctionEventType.CURRENT_AUCTION_ART_UPDATE:
            console.log("run:-=-=-=", event)
            AuctionAddNewArtAction.triggerAction(true)
            // Tunes.beep();
            break;
        case auctionEventType.CURRENT_AUCTION_ART_BIDDING_UPDATE:
            AuctionArtBiddingUpdateAction.triggerAction(event.auctionArtId)
            // Tunes.beep();
            break;

        case auctionEventType.CURRENT_AUCTION_ART_BIDDING_STOP:
            AuctionArtBiddingStopAction.triggerAction(event.auctionArtId)
            // Tunes.beep();
            break;

        case auctionEventType.CURRENT_AUCTION_ART_PURCHASED:
            console.log("notPur:-=-=-=")
            AuctionArtPurchasedAction.triggerAction(event.auctionArtId)
            // Tunes.beep();
            break;

        case auctionEventType.CURRENT_AUCTION_ART_BIDDING_RESTART:
            AuctionArtBiddingRestartAction.triggerAction(event.auctionArtId)
            // Tunes.beep();
            break;

        default:
            break
    }
}