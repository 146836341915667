import React from 'react'
import { PLATFORM_FEE } from '../../../environment'
import { ICollectionItem } from '../../../interfaces'
import { collectionImage } from '../../../shared'
import { CustomImage } from '../../../templates/components/CustomImage'
import { CurrencyFormat } from '../../common/CurrencyFormat'


interface IProps {
    art: ICollectionItem
    route?: string
    showAuction?: boolean
    onClickThreeDots: Function
    showThreeDots: boolean
    className?: string
}
export const ArtBoxComponent = ({ art, route, showAuction, onClickThreeDots, showThreeDots, className }: IProps) => {
    let user = art.art_collection?.user
    return (
        <div className={className ? className : `col-md-6 col-lg-4`} key={art.id}>
             
            <div className="At-BorderFigureHolder">
                <div className="At-BorderFigure">
                    <CustomImage src={collectionImage(art, `${art.url}`)} thumbnail={collectionImage(art, `blur_${art.url}`)} />
                    <div className="at-art-infobox">
                        {art.name && <h5 className="at-title">
                            {art.name}
                        </h5>}
                        <div>
                            {art.artTechnique && art.height && art.width && <p> {art.height}" X {art.width}" {art.depth ? `X ${art.depth}"` : null}   {art.artTechnique}</p>}
                            {showAuction && art.auctionMinPrice && <h6 className="mb-0">Minimum Price: <CurrencyFormat amount={Number(Number(art.auctionMinPrice) + Number(art.shipping) + Number(PLATFORM_FEE))} /></h6>}
                            {showAuction && user && <h6 className="pt-1">Artist: {user.firstName} {user.lastName}</h6>}
                        </div>
                        {showThreeDots && <i className="icon-dotted-circle" onClick={() => onClickThreeDots(art)}></i>}
                    </div>
                </div>

            </div>
        </div>
    )
}