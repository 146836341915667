import { HttpService } from './base.service'
import { data } from 'jquery';

class CommissionWorkService extends HttpService {
    private readonly prefix: string = 'commission';

    /**
     *  User make offer
     */
    requestCommission = (data:any): Promise<any> => this.post(`${this.prefix}`, data);

    /**
     *  User make offer
     */
    getAllRequests = (): Promise<any> => this.get(`${this.prefix}`);

    /**
     *  Seller offers
     */
    getRequestRecived = (id: any): Promise<any> => this.get(`${this.prefix}/recieved/${id}`);

    /**
     *  Buyer offers
     */
    getRequestMade = (id: any): Promise<any> => this.get(`${this.prefix}/sent/${id}`);

    /**
     *  get single offers
     */
    getRequest = (id: any): Promise<any> => this.get(`${this.prefix}/${id}`);

    createProposal = (data: any): Promise<any> => this.post(`${this.prefix}/proposal`,data)

    updateRequest = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/update-request/${id}`, data)

    rejectProposal = (data: any): Promise<any> => this.post(`${this.prefix}/proposal/reject`,data)

    rejectRequest = (data: any): Promise<any> => this.post(`${this.prefix}/commission/reject`,data)

    approveProposal = (data: any): Promise<any> => this.post(`${this.prefix}/proposal/approve`,data)

    updateProposal = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/update-proposal/${id}`, data)

    requestPayment = (data: any): Promise<any> => this.post(`${this.prefix}/request/payment`,data)
    
    sendProposalMessage = (data: any): Promise<any> => this.post(`${this.prefix}/proposal/msg`,data)

    sendRequestMessage = (data: any): Promise<any> => this.post(`${this.prefix}/commission/msg`,data)


}

export const commissionWorkService = new CommissionWorkService();