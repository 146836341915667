import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AxiosResponse } from 'axios'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { IFormates, ITransaction, IUser } from '../../interfaces'
import { transactionService } from '../../services/Transaction.service'
import moment from 'moment'
import LoadingComponent from '../../components/common/Loading.component'
import { collectionImage } from '../../shared'
import { TransactionLayout } from '../../components/common/TransactionLayout'
import { ERole, ETransactionStatus } from '../../enums'
import { paymentService, toastService } from '../../services'
import { ConfirmAlertComponent } from '../../components/common/ConfirmAlert.component'
import { userSelector } from '../../store/selectors'
import { UniversalModal } from '../../components/common/Modals/Universal.modal'
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial'
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon'
import { EScreens } from '../../enums/screens.enum'

interface IPageProps {
    routeUser?: IUser
    hideBackButton?: boolean
}
interface IProps {
    onClick: Function
}

const ReturnArtPage = ({ routeUser, hideBackButton }: IPageProps) => {
    const history = useHistory()
    const storeUser = useSelector(userSelector)
    const user = routeUser && routeUser._id ? routeUser : storeUser
    const [showBuyerDetails, setShowBuyerDetails] = useState<boolean>()
    const [showSellerDetails, setShowSellerDetails] = useState<boolean>()
    const [disputedTransactions, setDisputedTransaction] = useState<Array<ITransaction>>()
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedDispute, setSelectedDispute] = useState<ITransaction>()
    const [confirmBox, setConfirmBox] = useState<boolean>(false)
    const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        transactionService.getReturns(user?._id).then((response: AxiosResponse<any>) => {
            let newTransaction = [...response.data.response]
            setDisputedTransaction(newTransaction.reverse())
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

    const onReturnCompleted = () => {
        setLoading(true)
        paymentService.RefundPayment({ transactionID: selectedDispute?._id, transactionState: ETransactionStatus.RETURN_COMPLETED }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'success') {
                toastService.success("Thanks for updating")
                fetch()
            } else if (res.data && res.data.length > 0) {
                toastService.error(res.data[0].message)
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
            onCloseConfirmBox()
        }).catch(err => {
            console.log(err)
            onCloseConfirmBox()
            setLoading(false)
        })
    }

    const getButtonStatus = (item: ITransaction) => {
        return { status: item.return_art.labelStatus ? item.return_art.labelStatus.replace(/_/g, ' ').toLocaleLowerCase() : "Return Pending", color: 'grey' }
    }

    const getItemType = (item: ITransaction) => {
        if (item.formatesId) {
            let format: Array<IFormates> = item?.itemID?.formates.filter((value: IFormates) => value._id === item?.formatesId)
            if (format && format.length <= 0) {
                return ` `
            }
            if (format && format[0]) {
                return format[0].size
            }
            return ` `
        }
        return `Original`
    }

    const openConfirmBox = (item: ITransaction) => {
        console.log("click")
        setSelectedDispute(item)
        setConfirmBox(true)
    }

    const onCloseConfirmBox = () => {
        let obj: any = {}
        setSelectedDispute(obj)
        setConfirmBox(false)
    }

    const openTutorialBox = () => {
        setShowTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setShowTutorialBox(false)
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two ">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>Returns <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.RETURNS} onClick={openTutorialBox} /></span></h2>
                </div>
                <div className="at-transaction-main">
                    {disputedTransactions && disputedTransactions?.length > 0 ? (
                        <>
                            {disputedTransactions?.map((item: ITransaction) => {
                                 let isAgent = item && item?.sellerID && item?.sellerID?.roles?.includes(ERole.AGENT)
                                 let artistName = isAgent ? `${item.itemID?.artistName}` || `${item?.sellerID?.firstName} ${item?.sellerID?.lastName}` : `${item?.sellerID?.firstName} ${item?.sellerID?.lastName}`
                                return (
                                    <div>
                                        {item.return_art.labelStatus === ETransactionStatus.RETURN_PENDING ?
                                            <TransactionLayout item={item} handleDetailPopup={() => { }} getButtonStatus={getButtonStatus} getItemType={getItemType} /> :
                                            <div className="at-transaction-wrap">
                                                <div className="at-transaction-box">
                                                    <div className="at-transact-left">
                                                        <div className="at-transact-line">
                                                            <p>{moment(item.createdAt).format("L")}</p>
                                                            <p className="pl-5">{moment(item.createdAt).format("hh:mm:ss a")}</p>
                                                        </div>
                                                        <div className="at-transact-line">
                                                            <p>Seller:</p>
                                                            <p className="pl-2 text-capitalize"><span>{item.sellerID.firstName} {item.sellerID.lastName}</span></p>
                                                        </div>
                                                        <div className="at-transact-line">
                                                            <p>Artist:</p>
                                                            <p className="pl-2 text-capitalize"><span>{item.sellerID.firstName} {item.sellerID.lastName}</span></p>
                                                        </div>
                                                        <div className="at-transact-line" >
                                                            <p>Buyer:</p>
                                                            <p className="pl-3 text-capitalize"><span>{`${item.buyerID.firstName} ${item.buyerID.lastName}`}</span></p>
                                                            {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                                                        </div>
                                                        <div className="at-transact-line">
                                                            <p className=" text-capitalize">Shipping Company: <span>{item.return_art.shipment_company}</span></p>
                                                        </div>
                                                        <div className="at-transact-line pt-2">
                                                            <p className="">Shipping Date: <span>{moment(item.return_art.shipment_date).format("L")}</span></p>
                                                        </div>
                                                        <div className="at-transact-line pt-2">
                                                            <p className="">Est. Delivery Date: <span>{moment(item.return_art.delivery_date).format("L")}</span></p>
                                                        </div>
                                                        <div className="at-transact-line pt-2">
                                                            <p className="">Tracking Number: <span>{item.return_art.tracking_number}</span></p>
                                                        </div>

                                                    </div>
                                                    {item.itemID && <div className="at-transact-right">
                                                        {item.itemID && item.itemID.name && <p className=""><span>{item.itemID.name}</span></p>}
                                                        <figure>
                                                            <img src={collectionImage(item.itemID, `high_${item.itemID.url}`, true)} alt="" />
                                                        </figure>
                                                        <button className={`at-btn at-btn-sm at-btn-${getButtonStatus(item).color} text-capitalize`}>{getButtonStatus(item).status}</button>
                                                    </div>}
                                                    <div className="at-transactbox-bottom">
                                                        <button className={`at-btn at-btn-sm at-btn-${item.return_art.labelStatus === ETransactionStatus.RETURN_COMPLETED ? 'grey' : 'orange'}`} onClick={() => openConfirmBox(item)} disabled={loading || item.return_art.labelStatus === ETransactionStatus.RETURN_COMPLETED} >{loading ? "Please wait..." : 'Return Completed'}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </>) : (<>{loading && <LoadingComponent className="at-transaction-loader" />}</>)
                    }
                    {/* showSellerDetails popup ends */}
                </div>
                {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={closeTutorialBox} position='bottom-0'>
                    <ScreenTutorial screen={EScreens.RETURNS} />
                    {/* at-categorybox ends */}
                </UniversalModal>
                }
            </div>
            {confirmBox && <ConfirmAlertComponent open={confirmBox} title='Confirm' onClose={onCloseConfirmBox} onCancel={onCloseConfirmBox} onConfirm={onReturnCompleted} />}
        </main>
    )
}

export default ReturnArtPage
