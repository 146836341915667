import { HttpService } from './base.service'
import { data } from 'jquery';

class TransactionService extends HttpService {
    private readonly prefix: string = 'transactions';
    /**
     *  Get all Transactions
     */

    getAllTransactions = (): Promise<any> => this.get(`${this.prefix}/`);

    /**
     *  User Transactions
     */

    getUserTransactions = (id: any): Promise<any> => this.get(`${this.prefix}/user/${id}`);

    /**
     *  Seller Transactions
     */
    getSellerTransactions = (id: any): Promise<any> => this.get(`${this.prefix}/seller/${id}`);

    /**
     *  Provider Transactions
     */
    getProviderTransactions = (id: any): Promise<any> => this.get(`${this.prefix}/provider/${id}`);

    /**
     *  Buyer Transactions
     */
    getBuyerTransactions = (id: any): Promise<any> => this.get(`${this.prefix}/buyer/${id}`);

    /**
     *  Update Transaction Shipment
     */

    updateTransactionShipment = (data: any): Promise<any> => this.post(`${this.prefix}/update/shipment/`, data)
    /**
     *  Update transaction Dispute
     */

    updateTransactionDispute = (data: any): Promise<any> => this.post(`${this.prefix}/update/dispute/`, data)
    /**
     *  Update transaction Return Art
     */
    updateTransactionArt = (data: any): Promise<any> => this.post(`${this.prefix}/update/returnart/`, data)

    /**
     *  Get All disputes
     */
    getDisputedTransaction = (): Promise<any> => this.get(`${this.prefix}/disputes`)

    /**
     *  Get User disputes
     */
    getUserDisputes = (id: any): Promise<any> => this.get(`${this.prefix}/disputes/${id}`)

    /**
     *  Get User returns art
     */
    getReturns = (id: any): Promise<any> => this.get(`${this.prefix}/returns-art/${id}`)

    /**
     *  Get Seller disputes
     */
    getSellerDisputes = (id: any): Promise<any> => this.get(`${this.prefix}/disputes/seller/${id}`)

    /**
     *  Get Seller disputes
     */
    getBuyerDisputes = (id: any): Promise<any> => this.get(`${this.prefix}/disputes/buyer/${id}`)


    /**
     *  Get User Tax Summary
    */

    getSaleTaxSummary = (id: any): Promise<any> => this.get(`${this.prefix}/taxation/${id}`)

    /**
     *  update Transaction
    */

    updateTransaction = (data: any): Promise<any> => this.post(`${this.prefix}/update/transaction`, data)

    /**
     *  Get all Curatorial Transactions
     */

    getAllCuratorialTransactions = (): Promise<any> => this.get(`${this.prefix}/curatorial-offers`);


}

export const transactionService = new TransactionService();