import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { images } from '../../assets/image'
import { templatesImages } from '../../shared'
import { setInPlatformWebState } from '../../store/actions'
import { inPlatformSelector, templateSelector } from '../../store/selectors'

import { routes } from './routes'
import { routes as mainRoutes } from '../../router'
import { ZoomActions, EZoom } from '../../services/zoom.service'
import { ERole } from '../../enums'


const Header = () => {
    let template = useSelector(templateSelector)
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    let isAboutContact = location.pathname.includes(`${routes(template.url).about}`) || location.pathname.includes(`${routes(template.url).Contact}`)
    let isIframe = window.self !== window.top
    const getOmniRoutes = () => {
        let path = location.pathname
        if (path.includes(routes(template.url).exhibitions.index)) {
            return `${mainRoutes.artCentral.events.list}/${template.user._id}`
        }
        if (path.includes(routes(template.url).gallery.index)) {
            if (template.user.roles.includes(ERole.CONSULTANT)){
                return `${mainRoutes.home}`
            }
            return `${mainRoutes.artCentral.collection.list}/${template.user._id}`
        }

        return `${mainRoutes.artCentral.collection.list}/${template.user._id}`
    }

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const onClickLogo = () => {
        if (isIframe) return
        dispatch(setInPlatformWebState({
            nextPath: `${getOmniRoutes()}`,
            activeSplitScreen: false,
            isActive: false
        }))
        if (window.innerWidth > 767) {
            ZoomActions.triggerAction({ type: EZoom.ART_COLLECTION, value: null })
        }
        history.push(mainRoutes.loadingRoutes)
    }

    const onClickLink = () => {
        if (!isNavCollapsed) {
            setIsNavCollapsed(true)
        }
    }

    return (
        <div>
            <section className={`At-SectionHeader ${isAboutContact ? '' : 'At-SectionHeaderAbsolute'}`}>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-dark ">
                        {template.logoImage && <Link className="At-LinkLogo" to={routes(template.url).home}>
                            <figure className="At-FigureLogo mb-0"> 
                                <img src={templatesImages(`high_${template.logoImage}`, true)} className="img-fluid " alt="" />
                                {/* <img src={images.template.logoTemplate2} className="img-fluid At-Template2" alt="" /> */}
                            </figure>
                        </Link>}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded={isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item" onClick={() => onClickLink()}>
                                    <Link to={routes(template.url).home}>Home</Link>
                                </li>
                                <li className="nav-item" onClick={() => onClickLink()}>
                                    <Link to={routes(template.url).gallery.index}>{template.user.roles.includes(ERole.CONSULTANT) ? 'Services' : 'Gallery'}</Link>
                                </li>
                                <li className="nav-item" onClick={() => onClickLink()}>
                                    <Link to={routes(template.url).about}>About</Link>
                                </li>

                                <li className="nav-item" onClick={() => onClickLink()}>
                                    <Link to={routes(template.url).Contact}>Contact</Link>
                                </li>
                                <li className="nav-item" onClick={() => onClickLink()}>
                                    <Link to="#">
                                        <figure className="At-OmniLogo">
                                            <img src={images.logoOmniNew2} className="img-fluid" alt="" onClick={() => onClickLogo()} />
                                        </figure>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </section>
        </div>
    )
}

export default Header