import React, { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { AxiosResponse } from 'axios'
import { Subscription } from 'rxjs'
import SignupComponent from "../components/Authentication/Signup.component";
import SigninComponent from "../components/Authentication/Signin.component";
import ForgotPasswordComponent from "../components/Authentication/ForgotPassword.component";
import VerificationCodeComponent from "../components/Authentication/VerificationCode.component";
import ResetPasswordComponent from "../components/Authentication/ResetPassword.component";
import { connect } from "react-redux";
import { IStoreActionsStates, IStoreReducers, IUser } from "../interfaces";
import { routes } from "../router";
import { ADMIN_PHONE, APP_DOMAIN, CURRENT_VERSION } from '../environment';
import { spaceService, adminService, UserLoginAction, toastService, ShowTutorialPageAction } from '../services';
import { UniversalModal } from '../components/common/Modals/Universal.modal';
import { saveUser, loginAction } from '../store/actions'
import HomePage from './Home.page';
import { EZoom, IZoom, ZoomActions } from '../services/zoom.service';

import { SingleTutorialView } from '../components/Tutorials/SingleTutorialView.component';
import ShowTutorialsPage from '../components/Tutorials/ShowTutorials';
import Art_Collections from './ArtCentral/Art_Collections';
import NewsFeedsPage from './ArtCentral/NewsFeeds.page';

interface IPageProps extends RouteComponentProps, IStoreActionsStates {
    saveUser?: any
    showFeed: boolean
    showCollection: boolean
    showHome: boolean
}

interface ILandingPage {
    showSignup: boolean,
    showSignin: boolean,
    showForgotPassword: boolean,
    showVerificationCode: boolean,
    showResetPassword: boolean,
    phoneNumber: string,
    pin: string,
    openAction: boolean
    openTermsPopup: boolean,
    terms: string,
    policy: string,
    searchQuery: string,
    response?: IUser,
    showSubscriptionPopup: boolean
    zoom: IZoom,
    showTutorial: boolean
    showTutorialComponent: boolean,
}

class LandingPage extends Component<IPageProps> {
    tutorailScreenSubscription = new Subscription();
    zoomSubscription = new Subscription();

    state: ILandingPage = {
        showSignup: false,
        showSignin: false,
        showForgotPassword: false,
        showVerificationCode: false,
        showResetPassword: false,
        phoneNumber: '',
        pin: '',
        openAction: false,
        openTermsPopup: false,
        terms: '',
        policy: '',
        searchQuery: '',
        showSubscriptionPopup: false,
        zoom: {
            type: EZoom.NONE,
            value: null
        },
        showTutorial: false,
        showTutorialComponent: false
    };

    componentDidMount() {
        if (this.props.token) {
            this.props.history.push(routes.home);
        } else {
            this.fetch()
        }

        if (this.tutotial) {
            this.setState({
                showTutorial: true
            })
        }

        const showTutorial = ShowTutorialPageAction.listen.subscribe((status: boolean) => this.setState({ showTutorialComponent: status }))
        this.tutorailScreenSubscription.add(showTutorial)
        const sub$ = ZoomActions.listen.subscribe(this.zoomListener);
        this.zoomSubscription.add(sub$)
    }

    componentWillReceiveProps(props: IPageProps) {
        if (props.token) {
            this.props.history.push(routes.home);
        }
    }

    componentWillUnmount() {
        this.tutorailScreenSubscription.unsubscribe()
        this.zoomSubscription.unsubscribe();
    }

    zoomListener = (data: IZoom) => {
        this.setState({ zoom: data })
    }

    fetch = (): void => {
        adminService.getPolicy().then((response: AxiosResponse<any>) => {
            let text = response.data.length > 0 ? response.data[0].terms : this.state.terms
            let policy = response.data.length > 0 ? response.data[0].policy : this.state.policy
            this.setState({ terms: text, policy })
        })
    }

    close = (): void => {
        console.log("close all subs")

        this.setState({
            showSignup: false,
            showSignin: false,
            showForgotPassword: false,
            showVerificationCode: false,
            showResetPassword: false,
            showSubscriptionPopup: false
        })
        UserLoginAction.triggerAction(false)
    }

    // openSignup = (): void => {
    //     this.close()
    //     this.setState({ showSignup: true })
    // }

    // openSignin = (): void => {
    //     this.close()
    //     this.setState({ showSignin: true })
    // }

    // openForgotPassword = (): void => {
    //     this.close()
    //     this.setState({ showForgotPassword: true })
    // }

    // openVerificationCode = (phoneNumber: string): void => {
    //     this.close()
    //     this.setState({ phoneNumber, showVerificationCode: true })
    // }

    // openResetPassword = (pin: string): void => {
    //     this.close()
    //     this.setState({ showResetPassword: true, pin })
    // }

    openAction = () => {
        this.setState({ openAction: true })
    }

    closeOpenAction = () => {
        this.setState({ openAction: false })
    }

    openTermsPopup = () => {
        this.setState({ openTermsPopup: true })
    }

    closeTermsPopup = () => {
        this.setState({ openTermsPopup: false })
    }

    openSubscriptionPopup = (res: any) => {
        let u: IUser = res.user
        this.close()
        if (u.roles.length === 0 || u.roles[0].length === 0) {
            toastService.info("Please wait for account approval")
            return
        }
        if (u.phoneNumber === ADMIN_PHONE) {
            return
        }
        this.setState({ response: res, showSubscriptionPopup: true })
    }

    closeSubscriptionPopup = (subsLevel: string) => {
        let { response } = this.state
        if (!subsLevel) {
            toastService.info("Please wait for account approval")
        } else {
            let user: IUser = response?.user
            toastService.info(`Welcome, ${user.firstName} ${user.lastName} and enjoy your ${subsLevel} subscription.`)
            this.props.loginAction(response);
            this.props.saveUser(response?.user);
            this.props.history.push(routes.home)
        }
        this.close()
    }

    // onCloseTutorial = () => {
    //     this.setState({ showTutorial: false })
    //     ShowTutorialPageAction.triggerAction(false)
    // }

    get tutotial() {
        return new URLSearchParams(this.props.location.search).get("tutorial")
    }


    render() {
        const { showSignup, showSignin, showForgotPassword, showVerificationCode, showResetPassword, showTutorialComponent, openAction, openTermsPopup, terms, policy, phoneNumber, pin, showSubscriptionPopup, response, zoom: { type }, showTutorial } = this.state;
        let { showCollection, showFeed, showHome } = this.props
        const zoomClass = (type === EZoom.ART_COLLECTION) ? 'zoom-enabled' : ''
        return (
            // <div
            //     id='at-wrapper'
            //     className={`at-wrapper ${zoomClass}  ${(type === EZoom.ART_COLLECTION) ? 'at-wrapperfullpanel' : ''} `}
            // >
            //     {zoomClass && <ZoomItemComponent />}
            //     <div className="at-themeholder at-loginsignuparea">
            <>
                <main id="at-main" className="at-main at-haslayout">
                    <div className="at-height100">
                        {/* <div className="at-loginsignupcontent"> */}
                        {!showTutorialComponent ? (
                            <>
                                {showHome && <HomePage {...this.props} />}
                                {showCollection && <Art_Collections isShowMobile={true} {...this.props} />}
                                {showFeed && <NewsFeedsPage {...this.props} />}
                            </>

                        ) : <ShowTutorialsPage {...this.props} onBack={() => this.setState({ showTutorialComponent: false })} />}
                    </div>
                    <nav className="at-nav at-orangeeconnav">
                        <ul className="text-muted at_anch_privacy d-flex justify-content-center">
                            <li>
                                <a onClick={this.openAction}><i className="icon-privacy"></i></a>
                            </li>
                            <li>
                                <a className="ml-3" onClick={this.openTermsPopup}><i className="icon-document"></i></a>
                            </li>
                            <li>
                                <a className="at-iconlandingtutorial  ml-3" onClick={() => this.setState({ showTutorialComponent: true })}>
                                    <div>
                                        <i className="icon-video-tutorial"></i>
                                        <span>
                                            HELP
                                            </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <p className="at-version">{CURRENT_VERSION}</p>
                    </nav>
                </main>

                {/* {
                    showSignup && (
                        <div id="at-themesignuppopup" className={`at-themepopupholder ${showSignup && 'at-showthemesignuppopup'}`}>
                            <SignupComponent {...this.props} onClose={this.close} openSignin={this.openSignin} openSubscriptionPopup={this.openSubscriptionPopup} />
                        </div>
                    )
                } */}

                {/*Sign In Component*/}
                {/* <div id="at-themeloginpopup" className={`at-themepopupholder ${showSignin && 'at-showthemeloginpopup'}`}>
                    <SigninComponent {...this.props} onClose={this.close} openSignup={this.openSignup} openForgotPassword={this.openForgotPassword} />
                </div> */}

                {/*Forgot Password Component*/}
                {/* <div id="at-themeforgetpasswordpopup" className={`at-themepopupholder ${showForgotPassword && 'at-showthemeforgetpasswordpopup'}`}>
                    <ForgotPasswordComponent onClose={this.close} openSignin={this.openSignin} openVerificationCode={this.openVerificationCode} />
                </div> */}

                {/*Verification Code Component*/}
                {/* <div id="at-themerecoverycodepopup" className={`at-themepopupholder ${showVerificationCode && 'at-showthemerecoverycodepopup'}`}>
                    <VerificationCodeComponent onClose={this.close} openSignin={this.openSignin} openResetPassword={this.openResetPassword} phoneNumber={phoneNumber} />
                </div> */}

                {/*Reset Password Component*/}
                {/* <div id="at-themecreatenewpasswordpopup" className={`at-themepopupholder ${showResetPassword && 'at-showthemecreatenewpasswordpopup'}`}>
                    <ResetPasswordComponent onClose={this.close} openSignin={this.openSignin} phoneNumber={phoneNumber} pin={pin} />
                </div> */}


                {showTutorial && this.tutotial && <UniversalModal open={showTutorial} onClose={() => this.setState({ showTutorial: false })} >
                    <SingleTutorialView id={this.tutotial} />
                </UniversalModal>}

                {/* Privacy popup */}
                {openAction && <div id="at-themeaddactionpopup" className={`at-themepopupholder ${openAction && 'at-showthemesignuppopup'}`}>
                    <div className="at-themepopupbox">
                        <a className="at-close cursor-pointer" onClick={this.closeOpenAction} id="at-close"><i className="icon-cancel"></i></a>
                        <div className="at-addactionholder">
                            <div className="at-formthemehead text-center">
                                <h2>Privacy Policy</h2>
                            </div>
                            <div className="at-privacy-textholder at-popupinner-mh-55vh" dangerouslySetInnerHTML={{ __html: policy }}>
                            </div>
                        </div>
                    </div>
                </div>}

                {/* Terms popup */}
                {openTermsPopup && <div id="at-themeaddactionpopup" className={`at-themepopupholder ${openTermsPopup && 'at-showthemesignuppopup'}`}>
                    <div className="at-themepopupbox">
                        <a className="at-close cursor-pointer" onClick={this.closeTermsPopup} id="at-close"><i className="icon-cancel"></i></a>
                        <div className="at-addactionholder">
                            <div className="at-formthemehead text-center">
                                <h2>Terms</h2>
                            </div>
                            <div className="at-privacy-textholder at-popupinner-mh-55vh">
                                <p dangerouslySetInnerHTML={{ __html: terms }}>

                                </p>
                            </div>
                        </div>
                    </div>
                </div>}

            </>
            //     </div>
            // </div>
        );
    }
}
const mapState = (state: IStoreReducers) => ({
    token: state.auth.access_token
})

export default connect(mapState, { loginAction, saveUser })(LandingPage);
