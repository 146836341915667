import React, { useState } from 'react'
import { socketNode } from '../../services/socket.service';
import { GSTT } from '../../services';

export default function SpaceChatBoxComponent(props: { userId: string }) {

    const [message, setMessage] = useState('');

    const sendMessage = (e: any = null): void => {
        e && e.preventDefault();
        socketNode.emit(GSTT.SEND_MESSAGE, { userId: props.userId, message });
        setMessage('')
    }

    return (
        <div className="at-manual-speech">
            <div className="at-speech-wrap">
                <p id="transcript"></p>
            </div>
            <div className="form-group">
                <form onSubmit={sendMessage}>
                    <input type="text" name="write" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Write..." />
                    <button type="button" onClick={sendMessage} className="at-sendicon"><i className="icon-send"></i></button>
                </form>
            </div>

        </div>
    )
}
