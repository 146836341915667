import React from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { _logger } from '../../../helper';
import { Billing, SubscriptionComponent } from '../../UserSubscription'
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/selectors';


interface IProps extends RouteComponentProps {

}
const AdminUserSubscriptionPage = ({ history }: IProps) => {
    let stateUser = useLocation().state as any
    
    let user = stateUser
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createcollection scroll-style-3 scrollable at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Subscription</h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                        <Tab label="Subscription">
                            <SubscriptionComponent user={user} />
                        </Tab>
                        <Tab label="Billing">
                            <Billing user={user} />
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </main>
    )
}

export default AdminUserSubscriptionPage