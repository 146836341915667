import { IUser, ITemplateReducerState } from '../../interfaces'
import { TEMPLATE_DATA, IActionType } from '../types'


const initialState: ITemplateReducerState | any = {

}
export const templateReducer = (state = initialState, action: IActionType): ITemplateReducerState => {
    switch (action.type) {
        case TEMPLATE_DATA:
            return { ...action.payload }
        default:
            return state
    }
}