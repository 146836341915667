import moment from 'moment'
import React from 'react'
import { IServiceTransaction } from '../../interfaces/services.interface'
import { CurrencyFormat } from '../common/CurrencyFormat'

interface IProps {
    t: IServiceTransaction,
    onOpenConfirmBox: Function
    loading: boolean
}
export const AdminServiceTransactionlayout = ({ t, onOpenConfirmBox, loading }: IProps) => {

    return (
        <div className="at-transaction-box">
            <div className="at-transact-left">
                <div className="at-transact-line">
                    <p>{moment(t.createdAt).format("L")}</p>
                    <p className="pl-5">{moment(t.createdAt).format("hh:mm:ss a")}</p>
                </div>
                <div className="at-transact-line">
                    <p>Service:</p>
                    {t.service && <p className="pl-2 text-capitalize"><span>{t.service.name}</span></p>}
                </div>
                <div className="at-transact-line">
                    <p>Artist:</p>
                    {t.provider && t.provider._id && <p className="pl-2 text-capitalize"><span>{t.provider.firstName} {t.provider.lastName}</span></p>}
                </div>
                <div className="at-transact-line">
                    <p>Buyer:</p>
                    {t.buyer && t.buyer._id && <p className="pl-2 text-capitalize"><span>{`${t.buyer.firstName} ${t.buyer.lastName}`}</span></p>}
                    {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                </div>
                <div className="at-transact-line mw-200 pt-2">
                    <p>Pricing:</p>
                    {t.service && <p className="pl-2 text-capitalize"><span>{`${t.service.frequency?.toLowerCase()}ly payments of $${t.service.amount}`}</span></p>}
                </div>
                <div className="at-transact-line mw-200 pt-2">
                    <p>TotalAmount:</p>
                    {t.totalAmount && <p className="pl-2 text-capitalize"><span><CurrencyFormat amount={t.totalAmount} /></span></p>}

                </div>

            </div>

            <div className="at-transact-right">
                <button className={`at-btn at-btn-sm at-btn-${t.status === 'paid' ? 'orange' : 'grey'} text-capitalize`}>{t.status === 'paid' ? 'Paid' : 'Pending'}</button>
            </div>
            <div className="at-transactbox-bottom">
                <div className="at-transfer-amount at-transfer-amount-threecol">
                    <div className="at-transfer-upper">
                        <h5 className="text-center at-text-underline">Transfer Amount Calculation</h5>
                        <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                            </div>
                            <div className="col-3 at-custcol-4">
                                <h6>{'Provider'}</h6>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <h6>Payout</h6>
                            </div>
                        </div>
                        {/* heading row  */}
                        <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                                <p>
                                    Service Price
                                </p>
                            </div>
                            <div className="col-3 at-custcol-4">
                                <p><CurrencyFormat amount={t.service.amount} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p>
                                    100%
                            </p>
                            </div>

                        </div>
                        {/* item row */}
                        {/* item row */}
                        {/* <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                                <p>Sales Tax:</p>
                            </div>
                            <div className="col-3 at-custcol-4">
                                <p><CurrencyFormat amount={artist.salesTax} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> 100%</p>
                            </div>
                        </div> */}
                        {/* item row */}

                        {/* item row */}

                        <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                                <p>Payments**:</p>
                            </div>
                            <div className="col-3 at-custcol-4">
                                <p>$0.00</p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> 0%</p>
                            </div>
                        </div>
                        {/* item row */}

                        <div className="row justify-content-md-center">
                            <div className="col-3 at-custcol-4">
                                <p>Total:</p>
                            </div>
                            <div className="col-3 at-custcol-4 at-bordertop">
                                <p> <CurrencyFormat amount={Number(t.totalAmount)} /></p>
                            </div>
                            <div className="col-2 at-custcol-4">
                                <p> </p>
                            </div>
                        </div>
                        {/* item row */}

                    </div>

                    <div className="at-transfer-help d-flex justify-content-center pb-2">
                        <div>
                            <p>
                                * Includes credit card and fund transfer fees
                                                </p>
                            <p>   ** Includes payments to service providers</p>
                        </div>

                    </div>
                </div>
                <div className="pt-2">
                    <button disabled={t.status === 'paid' || loading} className={`at-btn at-btn-sm at-btn-${t.status === 'paid' ? 'grey' : 'orange'}`} onClick={() => onOpenConfirmBox(t)}>{t.status === 'paid' ? 'Paid' : loading ? "Wait..." : 'Release Funds'}</button>
                </div>
            </div>
        </div>
    )
}