import React, { useState, useCallback, useEffect } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { _logger } from '../../helper'

function CropImageComponent(props: any) {
	const [upImg, setUpImg] = useState('' as any)
	const [imgRef, setImgRef] = useState(null)
	const [imgBlob, setImgBlob] = useState('')
	const [crop, setCrop] = useState({
		unit: 'px',
		width: 100,
		height: 100,
		// aspect: 16 / 9,
	} as ReactCrop.Crop)
	const [previewUrl, setPreviewUrl] = useState('')

	const { onCropComplete } = props

	useEffect(() => {
		setUpImg(props.image)
	}, [props])

	useEffect(() => {
		if (imgBlob) onCropComplete(imgBlob)
	}, [imgBlob, onCropComplete])

	const onLoad = useCallback((img) => {
		setImgRef(img)
	}, [])

	const onChange = useCallback((c: ReactCrop.Crop) => {
		setCrop(c)
	}, [])

	const makeClientCrop = (crop: any, percentCrop: any) => {
		if (imgRef && crop.width && crop.height) {
			createCropPreview(imgRef, crop, `${(new Date()).getTime()}-file.jpeg`)
		}
	}

	const createCropPreview = async (image: any, crop: any, fileName: any) => {
		const canvas = document.createElement('canvas')
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		var originWidth = crop.width * scaleX
		var originHeight = crop.height * scaleY
		// maximum width/height
		var maxWidth = 5000,
			maxHeight = 5000
		var targetWidth = originWidth,
			targetHeight = originHeight
		if (originWidth > maxWidth || originHeight > maxHeight) {
			if (originWidth / originHeight > maxWidth / maxHeight) {
				targetWidth = maxWidth
				targetHeight = Math.round(maxWidth * (originHeight / originWidth))
			} else {
				targetHeight = maxHeight
				targetWidth = Math.round(maxHeight * (originWidth / originHeight))
			}
		}
		// set canvas size
		canvas.width = targetWidth
		canvas.height = targetHeight
		const ctx = canvas.getContext('2d')

		ctx?.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			targetWidth,
			targetHeight
		)

		return new Promise((resolve, reject) => {
			try {
				canvas.toBlob(
					async (blob) => {
						if (!blob) {
							reject(new Error('Canvas is empty'))
							return
						}
						blob.slice = fileName
						window.URL.revokeObjectURL(previewUrl || '')
						// setPreviewUrl(window.URL.createObjectURL(blob))
						setImgBlob(canvas.toDataURL('image/jpeg', 1.0))
						resolve()
					},
					'image/jpeg',
					1
				)
			} catch(e) {
				_logger('', e);
			}
		})
	}

	return (
		<div>
			{/* <div>
				<input type='file' accept='image/*' onChange={onSelectFile} />
			</div> */}
			<ReactCrop
				src={upImg}
				onImageLoaded={onLoad}
				crop={crop}
				onChange={onChange}
				onComplete={makeClientCrop}
				// keepSelection={true}
			/>
			{/* {previewUrl && <img alt='Crop preview' src={previewUrl} />} */}
		</div>
	)
}

export default CropImageComponent
