import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { images } from '../../assets/image'
import LoadingComponent from '../../components/common/Loading.component'
import { ICollection, ICollectionItem } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { collectionService } from '../../services/collection.service'
import { collectionImage, templatesImages } from '../../shared'
import { templateSelector } from '../../store/selectors'
import { BannerComponent } from '../components/BannerComponent'
import { CustomImage } from '../components/CustomImage'
import { routes } from './routes'

const CollectionPage = () => {
    const history = useHistory()
    const params = (useParams() as any).id
    const template = useSelector(templateSelector)
    const [collection, setCollection] = useState<ICollection>()
    const [loading, setLoading] = useState<boolean>(false)

    const fetch = useCallback(() => {
        setLoading(true)
        collectionService.show(params).then((response: AxiosResponse<IResponse<ICollection>>) => {
            setCollection(response.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        fetch()
    }, [])
    return <div >
        <section className="At-SectionPage">
            <div className="At-ContainerFull">
                <BannerComponent title="" imgSrc={templatesImages(`${template.galleryImage}`) || images.template.bannerTemplate3}>
                    <nav aria-label="breadcrumb ">
                        <ol className="breadcrumb At-Breadcrumb">
                            <li className="breadcrumb-item"><Link to={routes(template.url).home}>Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page"><Link to={routes(template.url).gallery.index}>Gallery</Link></li>
                            <li className="breadcrumb-item active">Collection</li>

                        </ol>
                    </nav>
                </BannerComponent>
            </div>
        </section>
        <section className="At-ArtistsPage At-Padding-t10b5 my-0 At-Section-Mh800">
            <div className="container">
                <div className="row ">
                    <div className="col-12 mb-5">
                        {collection && <h2>{collection.title}</h2>}
                    </div>

                    {collection && collection.items.length > 0 && collection.items.map((i: ICollectionItem) => {

                        return (<div className="col-md-4 col-lg-4">
                            <div className="At-BorderFigureHolder">
                                <div className="At-BorderFigure">
                                    <CustomImage src={collectionImage(i, `${i.url}`)} thumbnail={collectionImage(i, `blur_${i.url}`)} />

                                </div>
                                <div className="At-ArtDetailBox">
                                    {i.name && <h5>
                                        {i.name}
                                    </h5>}
                                    {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>}
                                    <i className="icon-dotted-circle" onClick={() => { history.push(`${routes(template.url).gallery.collectionItemView}/${i.id}`) }}></i>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
                {loading && <LoadingComponent className='at-transaction-loader' />}
            </div>
        </section>
    </div>
}

export default CollectionPage