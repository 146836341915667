import { ISpaceState, IStoreAction } from '../../interfaces/store.interface'
import {
	TOGGLE_MEMBER_SIDEBAR,
	CHANGE_ACTIVE_SPACE_SUCCESS,
	CHANGE_ACTIVE_SPACE_REQUEST,
	CHANGE_ACTIVE_SPACE_FAILURE,
} from '../types'

const initialState: ISpaceState = {
	openActiveMembersSideBar: false,
	activeSpace: { name: '' },
	isLoading: false,
	isLoaded: false,
	error: '',
}
export const spaceReducer = (
	state = initialState,
	action: IStoreAction
): ISpaceState => {
	switch (action.type) {
		case TOGGLE_MEMBER_SIDEBAR:
			return {
				...state,
				openActiveMembersSideBar: !state.openActiveMembersSideBar,
			}

		case CHANGE_ACTIVE_SPACE_SUCCESS:
			return {
				...state,
				activeSpace: action.payload,
				isLoaded: true,
			}

		case CHANGE_ACTIVE_SPACE_REQUEST:
			return {
				...state,
				isLoading: true,
			}

		case CHANGE_ACTIVE_SPACE_FAILURE:
			return {
				...state,
				error: action.payload,
			}

		default:
			return state
	}
}
