import React from 'react'
import { useSelector } from 'react-redux'
import { templateSelector } from '../../store/selectors'

const Footer = () => {
    let template = useSelector(templateSelector)
    const onClickLink = (l: string) => {
        if (!l) return
        window.open(l, '_blank')
    }
    return <div>
        <section className="At-SectionFooter">
            <div className="container">
                <div className="At-BorderTop">
                    <div className="row">
                        <div className="col-sm-6">
                            <p>© Omni 2021. All rights reserved.</p>
                        </div>
                        <div className="col-sm-6">
                            {/* <ul className="At-Socials">
                                <li>
                                    <a onClick={() => onClickLink(template.contactInformation.facebookId)}><i className="icon-fb"></i></a>
                                </li>
                                <li>
                                    <a><i className="icon-twitter"></i></a>
                                </li>
                                <li>
                                    <a><i className="icon-instagram2"></i></a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>

            </div>
        </section>

    </div>
}

export default Footer