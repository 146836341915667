import React, { useRef, useState } from 'react'
import { images } from '../../../assets/image'
import { ISubmissionProcessState } from '../../../interfaces/submission-process.interface'
import { ErrorComponent } from '../../common/Error.component'
import ImageModalComponent from '../../common/ImageModal.component'
import LoadingComponent from '../../common/Loading.component'

interface IProps {
    state: ISubmissionProcessState
    onSubmit: Function
    errors: any
    loading: boolean
    onChangeFiles: Function
    onDeleteImage: Function
    onCrop: Function
}
const initialDetails = { cropped: '', original: '', imageIndex: 0 }
export const StepFour = ({ state, errors, onChangeFiles, loading, onSubmit, onDeleteImage, onCrop }: IProps) => {
    const imageRef = useRef<any>(null)
    const selectImages = () => {

        imageRef.current.click();
    }

    const [imageModal, setImageModal] = useState({
        display: false,
        crop: true,
        details: { ...initialDetails },
    })

    const onOpenImage = (i: any, index: number) => {
        setImageModal({
            ...imageModal,
            display: true,
            details: {
                original: i,
                cropped: i,
                imageIndex: index,
            },
        })
    }

    const onCropComplete = (image: { original: string; cropped: string }) => {
        setImageModal({
            ...imageModal,
            display: false,
            details: {
                ...imageModal.details,
                cropped: image.cropped,
            },
        })
        onCrop({ ...image, index: imageModal.details.imageIndex })
    }
    return <div>
        <div className="at-stepheading text-left">
            <h5>Please upload 6-10 pictures in JPEG format of recent and available work. Label as follows:</h5>
            <p>LastName_Title_Medium_Size_Price.jpg</p>
        </div>

        <div className="row row-margin-lr7 align-items-center">
            <div className="col-12 pad-lr-7">
                <div className="form-group form-w-100 mb-0">
                    <div className="at-themefileupload">
                        <input type="file" name="files[name]" multiple accept="image/*" id="at-uploadfile" ref={imageRef} onChange={(e: any) => onChangeFiles(e)} />
                        <label onClick={selectImages}>
                            <span><i className="icon-gallery"></i>Upload Your art</span>
                        </label>
                        {errors && errors['images'] && <ErrorComponent errors={errors['images']} multiple={false} />}
                    </div>
                </div>
            </div>
        </div>
        <div className="row at-step-uploadbox-row">
            <div className="col-md-12">
                <h5 className="text-left">Uploaded Files</h5>
            </div>
            <div className="col-12">

                {state.preview && state.preview.length > 0 && state.preview.map((i: any, index: number) => {
                    return (
                        <div className="at-step-uploadbox">
                            <div className="at-stepfigure">
                                <img src={i} alt="" />
                            </div>
                            <div className="at-steptext">
                                <h6>{state.images[index].name}</h6>
                            </div>
                            <div className="at-editformat-new">
                                <i className="icon-delete2" onClick={() => onDeleteImage(index)} />
                                <i className="icon-edit2" onClick={() => onOpenImage(state.preview[index], index)} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        {loading && <LoadingComponent className='at-transaction-loader' />}
        {imageModal.display && (
            <ImageModalComponent onClose={onCropComplete} onCancel={() => setImageModal({ ...imageModal, display: false })} image={imageModal} />
        )}
    </div>
}