import React from 'react'
import { images } from '../../assets/image'

const Footer = () => {
    return <div>
        <section className="At-SectionFooter">
            <div className="container">
                <div className="At-BorderTop">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-4">
                            <p className="mb-0">© Omni 2021. All rights reserved.</p>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <ul className="At-Socials">
                                <li>
                                    <a href="#"><i className="icon-fb"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="At-Fs24"><i className="icon-youtube1"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="icon-instagram2"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="icon-email2"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-4">
                            <figure className="At-FooterLogo">
                                <img src={images.logoOmniNew} className="img-fluid" alt=""/>
                            </figure>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    </div>
}

export default Footer