import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { images } from '../../assets/image'
import LoadingComponent from '../../components/common/Loading.component'
import { EAssignAccount } from '../../enums'
import { IAssignAccountResponse, ICollection, ICollectionItem } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { assignAccountService } from '../../services'
import { collectionService } from '../../services/collection.service'
import { collectionImage, templatesImages } from '../../shared'
import { templateSelector, userSelector } from '../../store/selectors'
import { BannerComponent } from '../components/BannerComponent'
import { CustomText } from '../components/CustomText'
import { routes } from './routes'

const HomePage = () => {
    const history = useHistory()
    const user = useSelector(userSelector)
    const template = useSelector(templateSelector)
    const [loading, setLoading] = useState<boolean>(false)
    const [collections, setCollections] = useState<Array<ICollection>>()
    const [accounts, setAccounts] = useState<Array<IAssignAccountResponse>>()


    const fetchCollections = () => {
        setLoading(true)
        collectionService.listWithoutAuth(template.user._id).then((response: AxiosResponse<IResponse<Array<ICollection>>>) => {
            setCollections([...response.data.data])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    const fetchAssignAccount = () => {
        setLoading(true)
        assignAccountService.getAllAccountsWithoutlogin(template.user._id).then((res: AxiosResponse<any>) => {
            let approvedData = res.data.data.filter((d: IAssignAccountResponse) => d.status === EAssignAccount.ACCEPTED)
            setAccounts([...approvedData])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchCollections()
        fetchAssignAccount()
    }, [])

    const renderCollection = (c: ICollection) => {
        let item = (c.items as Array<ICollectionItem>)
        return (
            item?.length > 0 ? <div className="col-sm-4" onClick={() => history.push(`${routes(template.url).artists.collectionView}/${c.id}`)}>
                <div className="At-EventBox">
                    {item && item.length > 0 && <figure className="At-FigureFull At-Figure-h300">
                        <img src={collectionImage(item[0], `${item[0].url}`, true)} className="img-fluid" alt="" />
                    </figure>}
                    <p dangerouslySetInnerHTML={{ __html: c.title }}></p>
                </div>
            </div> : null
        )
    }
    return (
        <div className="At-Section-Mh800">
            <div className="At-ContainerFull">
                <BannerComponent hideDownIcon={true} title={``} imgSrc={templatesImages(`${template.homeImage}`)}>
                    {/* <button className="At-Btn At-Btn-lg At-BtnMw-250" onClick={() => history.push(routes(template.url).gallery.index)}>VIEW GALLERY</button> */}
                </BannerComponent>

            </div>
            {/* {accounts && accounts.length > 0 && <section className="At-SectionTopLinks">
                <div className="container">
                    <div className="At-ContainerInner">
                        <div className="row">
                            {accounts.map((a: IAssignAccountResponse) => {
                                return (
                                    <div className="col-sm-6">
                                        <Link to={`${routes(template.url).artists.collectionView}/${a.assignmentCollection.id}`}>{a.assignmentCollection.title} </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>} */}
            <section className="At-SectionExhibitions At-Padding-pt7b5">
                <div className="container">
                    <h2 className="mb-5">{template.homeInformation.heading}</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="At-PostContent px-0">
                                {/* <h3>{template.homeInformation.heading}</h3> */}
                                <CustomText>
                                    <p dangerouslySetInnerHTML={{ __html: template.homeInformation.description }}>
                                    </p>
                                </CustomText>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        {collections && collections.length > 0 && collections.map((c: ICollection) => renderCollection(c))}
                    </div> */}
                    {loading && <LoadingComponent className='at-transaction-loader' />}
                </div>
            </section>
        </div>
    )
}

export default HomePage