export const routes = (name: string) => {
    return {
        home: `/${name}`,
        exhibitions: {
            index: `/${name}/exhibitions`,
            viewSingleExhibition: `/${name}/exhibitions/view-single-exhibition`,
        },
        about: `/${name}/about`,
        gallery: {
            index: `/${name}/gallery/collections`,
            collectionView: `/${name}/gallery/collections/collection-view`,
            collectionItemView: `/${name}/gallery/collections/item-view`
        },
        Contact: `/${name}/contact`,
        ArtDetail: `/${name}/art-detail`,
        Collection: `/${name}/collections`,
    }
}