import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ICollection, ICollectionItem, ICreateCollectionItem, IEventDocumentResponse, IUser } from '../../../../interfaces'
import AssignedCollectionItemView from '../collection/AssignedCollectionItemView'
import AssignedCollectionViewPage from '../collection/AssignedCollectionViewPage'
import AssignedCreateCollection from '../collection/AssignedCreateCollections'
import AssignedCreateEventPage from './AssignedCreateEvent'
import AssignedEventList from './AssignedEventList'
import AssignedViewSignleEvent from './AssignedViewSignleEvent'

interface IProps extends RouteComponentProps {
    user: IUser
}
const AssignedEvents = (props: IProps) => {

    const [comp, setComp] = useState<number>(1)
    const [routeArray, setRouteArray] = useState<Array<number>>([])
    const [selectedEvent, setSelectedEvent] = useState<IEventDocumentResponse>()
    const [selectedCollection, setSelectedCollection] = useState<ICollection>()
    const [selectedItem, setSelectedItem] = useState<ICollectionItem>()
    const [didUpated, setDidUpdate] = useState(false)

    const setRoute = () => {
        let array = routeArray
        array.push(comp)
        setRouteArray([...array])
    }

    const onClickViewEvents = () => {
        setRoute()
        setComp(2)
    }

    const onClickOnEvent = (e: IEventDocumentResponse) => {
        setRoute()
        setComp(3)
        setSelectedEvent({ ...e })
    }


    const onClickOnCollection = (c: ICollection) => {
        setRoute()
        setComp(5)
        setSelectedCollection({ ...c })
    }
    const onClickOnItem = (i: ICollectionItem) => {
        setRoute()
        setComp(6)
        setSelectedItem({ ...i })
    }

    const onGoBack = () => {
        let currPage = routeArray[routeArray.length - 1]
        setComp(currPage)
        let array = routeArray
        array.pop()
        setRouteArray([...array])
    }

    const onEditEvent = (e: IEventDocumentResponse) => {
        setSelectedEvent({ ...e })
        setComp(1)
    }
    const onEditItem = (i: ICollectionItem) => {
        setSelectedItem({ ...i })
        setComp(4)
    }

    const onEditCollection = (c: ICollection) => {
        setSelectedCollection({ ...c })
        setComp(4)
    }

    useEffect(() => {
        if (selectedEvent) {
            setDidUpdate(!didUpated)
        }
    }, [selectedEvent])
    const renderComponent = () => {
        switch (comp) {
            case 1:
                return <AssignedCreateEventPage user={props.user} onClickViewEvents={onClickViewEvents} onBack={onGoBack} id={selectedEvent?._id} onClickOnCollection={onClickOnCollection} onClickOnItem={onClickOnItem} />
            case 2:
                return <AssignedEventList user={props.user} onBack={onGoBack} onClickOnEvent={onClickOnEvent} />
            case 3:
                return <AssignedViewSignleEvent user={props.user} onBack={onGoBack} id={(selectedEvent?._id as string)} onClickOnItem={onClickOnItem} onEditEvent={onEditEvent} />
            case 4:
                return <AssignedCreateCollection onEditCollection={onEditCollection} onClickOnItem={onClickOnItem} {...props} user={props.user} onClickCollection={onClickOnCollection} id={selectedCollection?.id} onGoBack={onGoBack} />;
            case 5:
                return <AssignedCollectionViewPage onEditCollection={onEditCollection} onBack={onGoBack} onClickOnItem={onClickOnItem} {...props} user={props.user} id={selectedCollection?.id || undefined} />;
            case 6:
                return <AssignedCollectionItemView onEditItem={onEditItem} onBack={onGoBack} {...props} user={props.user} id={selectedItem?.id || undefined} />;
            default:
                return null;
        }
    }

    return (
        <div>
            {renderComponent()}
        </div>
    )
}

export default AssignedEvents