import { AxiosResponse } from 'axios'
import React, { FormEvent, useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ERole } from '../../../enums'
import { getInitials } from '../../../helper'
import { ICollection, ICollectionItem, ISpace, IUser } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { IGalleryWalkSpaceState, initGalleryWalkState } from '../../../interfaces/Gallery-walk.interface'
import { routes } from '../../../router'
import { spaceService } from '../../../services'
import { collectionService } from '../../../services/collection.service'
import { collectionImage, userImage } from '../../../shared'
import { userSelector } from '../../../store/selectors'
import LoadingComponent from '../../common/Loading.component'
import { UniversalModal } from '../../common/Modals/Universal.modal'
import { EventCollectionView } from '../events/EventsCollectionView'

interface IProps {
    onSelectSpace: Function
    selectedGallerySpaces: Array<IGalleryWalkSpaceState>
    onDeleteSpace: Function
    onAddFeatureArts: Function
}
export const UserSpacesList = ({ onSelectSpace, selectedGallerySpaces, onDeleteSpace, onAddFeatureArts }: IProps) => {
    const [searchContent, setSearchContent] = useState<string>("")
    const [spaces, setSpaces] = useState<Array<ISpace>>([])
    const [gallerySpaces, setGallerySpaces] = useState<Array<IGalleryWalkSpaceState>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const user = useSelector(userSelector)
    const [filtered, setFiltered] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [showFeaturedArts, setShowFeaturedArts] = useState<boolean>(false)
    const [selectedSpaces, setSelectedSpaces] = useState<IGalleryWalkSpaceState>()
    const [collections, setCollections] = useState<Array<ICollection>>([])
    const [selectedArts, setSelectedArts] = useState<Array<ICollectionItem>>([])

    const fetchSpaces = (search: string = '', filter: boolean = false) => {
        setLoading(true)
        let data: any = {
            search,
            filtered: filter
        }
        if (filter) data['userId'] = user._id
        spaceService.listOfActiveSpaces({ ...data }).then((res: AxiosResponse<Array<ISpace>>) => {
            let array = res.data
            if (selectedGallerySpaces && selectedGallerySpaces.length > 0) {
                array = array.filter((s: ISpace, index: number) => {
                    return selectedGallerySpaces.filter((i: IGalleryWalkSpaceState) => i.space._id === s._id).length === 0
                })
            }

            let newArray = array.map((s: ISpace) => {
                let data = { ...initGalleryWalkState }
                data.space = { ...s }
                data.user = { ...s.userId as IUser }
                return { ...data }
            })
            setSpaces([...array])
            setGallerySpaces([...newArray])
            setLoading(false)
        })
    }

    const fetchCollections = useCallback((id: string, s: IGalleryWalkSpaceState) => {
        collectionService.listWithItems(id).then((response: AxiosResponse<IResponse<Array<ICollection>>>) => {
            setCollections([...response.data.data])
            let artsData = response.data.data.reduce((n: any, c: ICollection) => {
                return n.concat(c.items)
            }, [])
            openFeaturedArts(s)
        }).catch((err: any) => {
            console.log(err)
        })
    }, [])

    const handleSearch = (e: FormEvent<EventTarget>) => {
        const { value } = (e.target as HTMLInputElement)
        setSearchContent(value)
        fetchSpaces(value, filtered)
    }

    const onToggleFilter = (value: boolean) => {
        if (value) {
            setFiltered(true)
        } else {
            setFiltered(false)
        }
        fetchSpaces(searchContent, value)
    }

    const openSearch = () => {
        setShowSearch(true)
    }

    const closeSearch = () => {
        setSearchContent('')
        setShowSearch(false)
        fetchSpaces('', filtered)
    }
    const openFeaturedArts = (s: IGalleryWalkSpaceState) => {
        setSelectedSpaces(s)
        setShowFeaturedArts(true)
    }

    const closeFeaturedArts = () => {
        setSelectedSpaces(undefined)
        setShowFeaturedArts(false)
    }

    const onAddEventArts = (array: Array<ICollectionItem>) => {
        if (!selectedSpaces) return
        let newArts = selectedSpaces.featuredArts ? selectedSpaces.featuredArts : []
        newArts = newArts.concat(array)

        let newSPaces = selectedGallerySpaces.map((s: IGalleryWalkSpaceState) => {
            if (s.space._id === selectedSpaces.space._id) {
                s.featuredArts = [...newArts]
            }

            return s
        })

        onAddFeatureArts(newSPaces)

        // setEventState({
        //     ...eventState,
        //     items: newArts
        // })
    }

    const isSelected = (item: ICollectionItem) => {
        return selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1
    }

    const onSelectEventArts = (item: ICollectionItem) => {
        if (selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1) {
            let newItems = selectedArts.filter((i: ICollectionItem) => i.id !== item.id)
            setSelectedArts([...newItems])
            return
        }
        let newArts = selectedArts
        newArts.push(item)
        setSelectedArts([...newArts])
    }

    const onDeleteArts = () => {
        if (!selectedSpaces) return
        let { featuredArts } = selectedSpaces
        if (!selectedArts.length) return
        let array = (featuredArts as Array<ICollectionItem>).filter((i: ICollectionItem) => {
            return selectedArts.map((s: ICollectionItem) => s.id).indexOf(i.id) === -1 ? true : false
        })

        let newSPaces = selectedGallerySpaces.map((s: IGalleryWalkSpaceState) => {
            if (s.space._id === selectedSpaces.space._id) {
                s.featuredArts = [...array]
            }

            return s
        })

        onAddFeatureArts(newSPaces)
        setSelectedArts([])
    }

    const isAllEventsArtSelected = () => {
        if (!selectedSpaces) return
        let { featuredArts } = selectedSpaces
        return selectedArts.length > 0 && selectedArts.length === featuredArts.length
    }

    const onSelectAllArts = () => {
        if (!selectedSpaces) return
        let { featuredArts } = selectedSpaces
        if (selectedArts.length === featuredArts.length) {
            setSelectedArts([])
            return
        }
        setSelectedArts([...featuredArts])
    }

    useEffect(() => {
        fetchSpaces('')
    }, [])
    useEffect(() => {
        if (selectedGallerySpaces.length > 0) {

            fetchSpaces('')
        }
    }, [selectedGallerySpaces.length])


    const renederSelectedItems = () => {
        if (!selectedSpaces) return null
        return (
            <div className="at-collectiongallerybox at-artgallerybox px-0 pb-2">
                <div className="at-gallerytitle">
                    <ul className="at-gallery">
                        {
                            selectedSpaces.featuredArts.map((item: ICollectionItem, index: any) => (
                                <li key={item.id}>
                                    <div>
                                        <figure>
                                            <img src={collectionImage(item, `high_${item.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                                            <div className="at-artgallerycollectionbox atv2-event-positionabs">
                                                <span className="at-checkbox" title="Select">
                                                    <input type="checkbox" name="select" id="artcollectionselectall" checked={isSelected(item)} />
                                                    <label htmlFor="artcollectionone" onClick={() => onSelectEventArts(item)}></label>
                                                </span>
                                            </div>
                                            {/* <div className="atv2-event-arrows">
                                                <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowup" title="Sequence Arts" onClick={() => onMoveUp(index)} ></i>
                                                <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowdw" title="Sequence Arts" onClick={() => onMoveDown(index)} ></i>
                                            </div> */}
                                        </figure>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }

    const renderUser = (u: IUser, space: IGalleryWalkSpaceState) => (
        <div className="form-group pr-0">
            <div className="at-uservideobox">
                <div className="at-uservideocontent border-0">
                    <div className="at-adminaction">
                        <div className="at-adminaction-left">
                            <figure><img src={u.profile ? userImage(u.profile) : getInitials(user?.firstName, user?.lastName)} alt="User Image" /></figure>
                            <h3>{`${u.firstName} ${u.lastName}`}</h3>
                        </div>
                        <div className="at-iconlikeholder ml-auto">
                            <button className="at-btnadd-relative c-sunshadebg" onClick={() => onSelectSpace(space)}>
                                <i className="icon-add-bold"></i>
                            </button>
                            {/* <span className="at-iconlike cursor-pointer"><i className={`icon-like `}></i></span> */}
                            {/* <span className="at-iconlike at-icondislike cursor-pointer" onClick={() => onDeleteSpace(space)}><i className={`icon-dislike `}></i></span> */}
                        </div>
                        {/* <div className="at-adminicon cursor-pointer" onClick={() => history.push(`${routes.adminRoutes.submissionApplicationView}/${s._id}`)}><i className={`icon-pending-approve-icon `}></i></div> */}
                    </div>
                </div>
            </div>
        </div>
    )

    const renderSelectedSpaces = (u: IUser, space: IGalleryWalkSpaceState) => (
        <div className="form-group pr-0">
            <div className="at-uservideobox">
                <div className="at-uservideocontent border-0">
                    <div className="at-adminaction">
                        <div className="at-adminaction-left">
                            <figure><img src={u.profile ? userImage(u.profile) : getInitials(user?.firstName, user?.lastName)} alt="User Image" /></figure>
                            <h3>{`${u.firstName} ${u.lastName}`}</h3>
                        </div>

                        <div className="at-iconlikeholder ml-auto">
                            <div className='at-likeholder-left'>
                                <a onClick={() => fetchCollections(u._id, space)} className="at-btnmenu " ><span className="at-btn at-doubleextrasmallbtn">{loading ? "Wait..." : 'Featured Arts'}</span></a>
                            </div>
                            <button className="at-btnadd-relative at-btn-bermudagrey" onClick={() => onDeleteSpace(space)}>
                                <i className="icon-delete2"></i>
                            </button>
                            {/* <span className="at-iconlike at-icondislike cursor-pointer" ><i className={`icon-dislike `}></i></span> */}
                            {/* <span className="at-iconlike at-icondislike cursor-pointer" onClick={() => onRejectApp(s)}><i className={`icon-dislike `}></i></span> */}
                        </div>
                        {/* <div className="at-adminicon cursor-pointer" onClick={() => history.push(`${routes.adminRoutes.submissionApplicationView}/${s._id}`)}><i className={`icon-pending-approve-icon `}></i></div> */}
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="at-formusercontacts p-0 pt-4">
            <fieldset>
                <div className="at-usereditcontactholder at-usereditcontactholder-two at-themescrollbar scrollable">
                    {selectedGallerySpaces && selectedGallerySpaces.length > 0 &&
                        <>
                            <div className="at-userbox">
                                <h5 className='ml-2'>Selected Spaces</h5>
                                <h6 className="at-userbox-heading">Agent / Gallery</h6>
                                <div className="at-paddinglr-15">
                                    {selectedGallerySpaces.map((s: IGalleryWalkSpaceState) => {
                                        if (s.user && s.user.roles.includes(ERole.AGENT)) {
                                            return renderSelectedSpaces(s.user, s)
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                            <div className="at-userbox">
                                <h6 className="at-userbox-heading">Artist</h6>
                                <div className="at-paddinglr-15">
                                    {selectedGallerySpaces.map((s: IGalleryWalkSpaceState) => {
                                        if (s.user && s.user.roles.includes(ERole.ARTIST)) {
                                            return renderSelectedSpaces(s.user, s)
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                            <div className="at-userbox">
                                <h6 className="at-userbox-heading">Service Provider</h6>
                                <div className="at-paddinglr-15">
                                    {selectedGallerySpaces.map((s: IGalleryWalkSpaceState) => {
                                        if (s.user && s.user.roles.includes(ERole.CONSULTANT)) {
                                            return renderSelectedSpaces(s.user, s)
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className="at-usereditcontactholder at-usereditcontactholder-two at-themescrollbar scrollable">
                    <div className="at-searchbox">
                        <a className={`at-filtericon cursor-pointer mr-2 ${filtered && 'text-orange'}`} onClick={() => onToggleFilter(!filtered)}><i title="Filter Preferred" className="icon-filter"></i></a>
                        {showSearch && <a className="at-left-arrowicon" onClick={() => closeSearch()}><i className="icon-left-arrow"></i></a>}
                        {!showSearch && <a className="at-filtericon cursor-pointer" onClick={() => openSearch()}><i title="Search" className="icon-search"></i></a>}
                        {showSearch && <div className="form-group at-paddinglr-15">
                            <input type="text" name="searchnamenumber" value={searchContent} className="form-control" placeholder="Search..." onChange={handleSearch} />
                            {/* <a  className="at-btnsearch"><i className="icon-search"></i></a> */}
                        </div>}
                    </div>
                    {gallerySpaces && gallerySpaces.length > 0 &&
                        <>
                            <div className="at-userbox">
                            <h5 className='ml-2'>Available Spaces</h5>
                                <h6 className="at-userbox-heading">Agent / Gallery</h6>
                                <div className="at-paddinglr-15">
                                    {gallerySpaces.map((s: IGalleryWalkSpaceState) => {
                                        if (s.user && s.user.roles.includes(ERole.AGENT)) {
                                            return renderUser(s.user, s)
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                            <div className="at-userbox">
                                <h6 className="at-userbox-heading">Artist</h6>
                                <div className="at-paddinglr-15">
                                    {gallerySpaces.map((s: IGalleryWalkSpaceState) => {
                                        if (s.user && s.user.roles.includes(ERole.ARTIST)) {
                                            return renderUser(s.user, s)
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                            <div className="at-userbox">
                                <h6 className="at-userbox-heading">Service Provider</h6>
                                <div className="at-paddinglr-15">
                                    {gallerySpaces.map((s: IGalleryWalkSpaceState) => {
                                        if (s.user && s.user.roles.includes(ERole.CONSULTANT)) {
                                            return renderUser(s.user, s)
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                        </>
                    }
                </div>
            </fieldset>

            {showFeaturedArts && selectedSpaces && <UniversalModal open={showFeaturedArts} onClose={closeFeaturedArts} >
                <>
                    {selectedSpaces.featuredArts.length > 0 && <div className="at-collectiongallery">
                        <div className="col-md-12">
                            {
                                selectedSpaces.featuredArts.length > 0 && <div className="at-manageposttitle mt-4 d-flex justify-content-between">
                                    <h2>{`Art Selected for Gallery Walk/Event`}</h2>
                                    <div className="at-btndelete d-flex ">
                                        {/* <i className="icon-event fs-20 mr-3 cursor-pointer right" ></i> */}
                                        {selectedSpaces.featuredArts && selectedSpaces.featuredArts.length > 0 && <i className="icon-delete2 fs-20 mr-3 cursor-pointer right" title="Delete" onClick={onDeleteArts}></i>}

                                        <div className="at-checkbox" title="Select Arts">
                                            <input type="checkbox" name="remember" checked={isAllEventsArtSelected()} />
                                            <label htmlFor="artcollectionselectall" className="at-label-bdradius50" onClick={onSelectAllArts} ></label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {renederSelectedItems()}
                        </div>
                    </div>
                    }
                    {!loading && <div className="at-collectiongallery pt-3">
                        <div className="col-md-12">
                            {
                                <div className="at-manageposttitle">
                                    <h2>{`Collections`}</h2>
                                </div>
                            }
                            {
                                loading && <LoadingComponent />
                            }
                            {
                                collections.map((c: ICollection) => <EventCollectionView key={c.id} c={c} user={c.user} selectedForEvent={selectedSpaces.featuredArts} onAddToEvents={onAddEventArts} />)
                            }
                        </div>
                    </div>
                    }
                </>
            </UniversalModal>}
        </div>
    )
}