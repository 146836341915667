import { ICollectionItem } from "./collection.interface";
import { IUser } from "./user.interface";

interface Image {
    url: string;
    isVideo: boolean;
    thumbnail: string
}

export interface IServiceCategoryDocument {
    _id?: string
    title: string,
    type: string,
    services: Array<IConsultantServicesDocument>,
    user: IUser
}

export interface IConsultantServicesDocument {
    id: string
    _id: string,
    user: IUser,
    name: string,
    paymentMethod: string,
    numberOfPayments: number,
    frequency: any,
    amount: string,
    overView: string,
    images: Array<Image>,
    description: string,
    isShow: boolean,
    isActive: boolean,
    privacy: any,
    category: IServiceCategoryDocument,
    buyers: Array<IUser>
    serviceType: string
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly deletedAt: Date,
}

export interface IServiceState {
    id?: string,
    _id?: string,
    user?: IUser,
    name: string,
    paymentMethod: string,
    numberOfPayments: number,
    frequency: any,
    amount: string,
    overView: string,
    images: Array<any>,
    description: string,
    serviceType: string
    privacy?: any,
    category?: IServiceCategoryDocument
    isShow?: boolean,
    isActive?: boolean,
}

export const initServiceState = {
    name: '',
    paymentMethod: '',
    numberOfPayments: NaN,
    frequency: null,
    amount: '',
    overView: '',
    images: [],
    description: '',
    serviceType: ''
}

export interface IServiceImageState {
    preview: any,
    image: any,
    previewsCopy: any,
    uploadServiceImage: boolean,
    imageIndex: number
}

export const serviceImageState: IServiceImageState = {
    preview: [],
    image: [],
    previewsCopy: [],
    uploadServiceImage: false,
    imageIndex: 0
}

export interface IServiceTransaction {
    _id: string
    service: IConsultantServicesDocument,
    buyer: IUser,
    subscription: string,
    provider: IUser,
    totalAmount: string,
    platformFee: string
    status: string,
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly deletedAt: Date,
}

/**  Printing and Framing services interfaces */

export interface IPrintingServiesSizes {
    _id: string,
    id: string,
    user: IUser
    height: number
    width: number
    isShow: boolean
    isActive: boolean
    printingServices: Array<IPrintingServices>
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly deletedAt: Date,
}

export interface IPrintingServiesPrices {
    _id: string,
    id: string,
    printingSizes: IPrintingServiesSizes,
    printingServices: IPrintingServices
    price: number,
    shippingPrice: number,
    isShow: boolean
    isActive: boolean
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly deletedAt: Date,
}

export interface IPrintingServices {
    _id: string,
    id: string,
    user: IUser,
    name: string,
    shipmentInformation: string,
    images: Array<Image>,
    description: string,
    printingSizes: Array<IPrintingServiesSizes>
    printingPrices: Array<IPrintingServiesPrices>
    isShow: boolean,
    isActive: boolean
    privacy: any
    serviceType: string
    sequence: string
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly deletedAt: Date,
}

export interface IPrintingServicesState {
    _id?: string,
    id?: string,
    user?: IUser,
    name: string,
    shipmentInformation: string,
    images: Array<any>,
    description: string,
    printingSizes?: Array<any>
    printingPrices?: Array<IPrintingServiesPrices>
    isShow?: boolean,
    isActive?: boolean
    privacy?: any
    serviceType?: string
}

export const initprintingPricesState = {
    printingSizes: '',
    printingServices: '',
    price: NaN,
    shippingPrice: NaN,

}
export const initPrintingServiceState: IPrintingServicesState = {
    name: '',
    shipmentInformation: '',
    images: [],
    description: '',
}

export interface IArtistOwnService {
    information: string
    height: number
    width: number
    price: string
    shipping: string
}

export interface IArtistSelectedService {
    _id: string
    serviceProvider: IUser,
    selectedServices: Array<ISelectedService>
    artImage: Image
    printType: string
    artistOwnService: IArtistOwnService
    format: string
    isActive: boolean
    art: ICollectionItem
}

export interface ISelectedService {
    _id: any
    printingService: any,
    framingService: any,
    selectedSize: any,
    selectedPrintrSevicePrice: any
    selectedFrameSevicePrice: any
    totalCost: string,
    artistChoice: boolean
}

export interface ISelectedServiceState {
    _id?: any
    printingService: any,
    framingService: any,
    selectedSize: any,
    selectedPrintrSevicePrice: any
    selectedFrameSevicePrice: any
    totalCost: string,
    artistChoice: boolean
}

export const initialSelectedServices = {
    printingService: undefined,
    framingService: undefined,
    selectedSize: undefined,
    selectedPrintrSevicePrice: undefined,
    selectedFrameSevicePrice: undefined,
    totalCost: '',
    artistChoice: false
}

export interface IArtistServicesState {
    _id?: string,
    art?: ICollectionItem
    serviceProvider: string,
    selectedServices: Array<ISelectedServiceState>
    artImage: any
    printType: string
    isActive?: boolean
    artistOwnService?: IArtistOwnService
    format?: string
}

export const initArtistServiceState: IArtistServicesState = {
    serviceProvider: '',
    selectedServices: [],
    artImage: null,
    printType: ''
}

export interface IServicesBilling {
    transaction: IServiceTransaction,
    invoiceData: Array<{
        amount: string,
        date: any
    }>

    nextInvoice: {
        amount: string,
        date: any
    }
}