import { HttpService } from "./base.service";
import {IAuthForgotPassword, IAuthLogin, IAuthResetPassword, IAuthSignup, IAuthVerifyCode, ISocialLogin} from "../interfaces";

class AuthService extends HttpService {
    private readonly prefix: string = 'auth';

    /**
     * Basic Authenticate User
     * @param data
     */
    login = (data: IAuthLogin): Promise<any> => this.post(`${this.prefix}/login`, data);

    /**
     * Authenticate User
     * @param data
     */
    socialLogin = (data: ISocialLogin): Promise<any> => this.post(`${this.prefix}/social-login`, data);

    /**
     * Resigter User
     * @param data
     */
    signup = (data: any): Promise<any> => this.post(`${this.prefix}/signup`, data);

    /**
     * Forgot Password
     * @param data
     */
    forgotPassword = (data: IAuthForgotPassword): Promise<any> => this.post(`${this.prefix}/forgot-password`, data);

    /**
     * Forgot Password
     * @param data
     */
    verifyPinCode = (data: IAuthVerifyCode): Promise<any> => this.post(`${this.prefix}/verify-code`, data);

    /**
     * Verify Phone Number
     * @param data
     */
    verifyPhone = (data: { phoneNumber: string }): Promise<any> => this.post(`${this.prefix}/verify-phone`, data);

    /**
     * Forgot Password
     * @param data
     */
    resetPassword = (data: IAuthResetPassword): Promise<any> => this.post(`${this.prefix}/reset-password`, data);

    createUserImage = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/create-user-image/${id}`, data)
}

export const authService = new AuthService();
