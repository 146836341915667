import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import NewsFeedsPage from './ArtCentral/NewsFeeds.page';
import Art_Collections from './ArtCentral/Art_Collections';
import { ZoomActions, EZoom, IZoom } from '../services/zoom.service';



interface IProps extends RouteComponentProps {
}

const HomePage = (props: IProps) => {

	const [zoom, setZoom] = useState<IZoom>({ type: EZoom.NONE, value: null })
	const zoomSubscription = new Subscription();

	// useEffect(() => {
	// 	let item: any = document.getElementById('zoom-collection')
	// 	if (isLargeScreen && !item) {
	// 		ZoomActions.triggerAction({ type: EZoom.ART_COLLECTION, value: { querySearch: props.querySearch } })
	// 	}

	// }, [])

	useEffect(() => {
		const sub$ = ZoomActions.listen.subscribe((data: IZoom) => {
			setZoom({ ...data })
		});
		zoomSubscription.add(sub$)

		return () => {
			// ZoomActions.triggerAction({ type: EZoom.NONE, value: null })
			zoomSubscription.unsubscribe()
		}
	}, [])

	return (
		<div className="at-orangeeconpost text-center at-height100">
			<div className="at_tabs_styling at_tabs_homepage">
				<Tabs >
					<Tab label="Art Collections">
						<Art_Collections isShowMobile={true} />
					</Tab>
					<Tab label="Latest Updates">
						<NewsFeedsPage {...props} />
					</Tab>
				</Tabs>
			</div>
		</div>
	)
}

export default HomePage