import { ICuratorialInvitationDocument, ICuratorialServiceDocument, IMilestone, ITransaction, IUser } from ".";
import { ECuratorialOfferStatus } from "../enums";
import { ICollectionItem, IFormates } from "./collection.interface";


interface Image {
    url: string;
    isVideo: boolean;
    thumbnail: string
}

export interface IProposalArtFormats extends IFormates {
    isSelected?: boolean
    formatId: any
    isSelectedForOffer?: boolean
    offerPrice: string
    qauntity: number
    proposalPrice: string
}

export interface ICuratorialProposalDocument {
    _id: string,
    id: string
    images: Array<Image>
    description: string
    proposalArts: Array<ICuratorialProposalArtsDocument>
    curatorialService: ICuratorialServiceDocument
    curatorialInvitation: ICuratorialInvitationDocument
    curatorialUser: IUser
    curatorialOffer: ICuratorialOfferDocument
    isHide: boolean
    isActive: boolean
    isSubmitted: boolean
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

export interface ICuratorialProposalArtsDocument {
    _id: string,
    id: string
    price: string
    description: string
    curatorialProposal: ICuratorialProposalDocument
    curatorialService: ICuratorialServiceDocument
    curatorialInvitation: ICuratorialInvitationDocument
    art: ICollectionItem
    proposalFormats: Array<IProposalArtFormats>
    user: IUser
    isSelectedForOffer: boolean
    isHide: boolean
    isActive: boolean
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

export interface ICuratorialProposalArtState {
    _id?: string,
    id?: string
    price: string
    description: string
    curatorialProposal?: ICuratorialProposalDocument
    curatorialService?: ICuratorialServiceDocument
    curatorialInvitation?: ICuratorialInvitationDocument
    art: any
    proposalFormats: Array<IProposalArtFormats>
    user?: IUser
    isHide?: boolean
    isActive?: boolean
    isSelectedForOffer?: boolean
    readonly createdAt?: Date
    readonly updatedAt?: Date
    readonly deletedAt?: Date
}
export interface ICuratorialProposalState {
    _id?: string,
    id?: string
    images: Array<Image>
    description: string
    proposalArts: Array<ICuratorialProposalArtState>
    curatorialService?: ICuratorialServiceDocument
    curatorialInvitation?: ICuratorialInvitationDocument
    curatorialOffer?: ICuratorialOfferDocument
    curatorialUser?: IUser
    isHide?: boolean
    isActive?: boolean
    isSubmitted?: boolean
    readonly createdAt?: Date
    readonly updatedAt?: Date
    readonly deletedAt?: Date
}

export const initProposalState: ICuratorialProposalState = {
    images: [],
    description: '',
    proposalArts: [],
}

export const initCuratorialProposalArtState: ICuratorialProposalArtState = {
    price: '',
    art: null,
    proposalFormats: [],
    description: ''
}

export interface ICuratorialProposalImageState {
    preview: any,
    image: any,
    previewsCopy: any,
    uploadServiceImage: boolean,
    imageIndex: number
}

export const curatorialProposalImageState: ICuratorialProposalImageState = {
    preview: [],
    image: [],
    previewsCopy: [],
    uploadServiceImage: false,
    imageIndex: 0
}

/**************************************   Curatorial Offer  ****************************************** */

export interface ICuratorialOfferDocument extends Document {
    _id: string,
    id: string
    curatorialProposal: ICuratorialProposalDocument
    curatorialService: ICuratorialServiceDocument
    curatorialInvitation: ICuratorialInvitationDocument
    participant: IUser
    curatorialUser: IUser
    proposalArts: Array<ICuratorialProposalArtsDocument>
    milestone: Array<IMilestone>
    totalPrice: string
    totalOfferPrice: string
    transaction: ITransaction
    information: string
    offerStatus: string
    isSubmitted: boolean
    isSaved: boolean
    isHide: boolean
    isActive: boolean
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}


export interface ICuratorialOfferState {
    _id?: string,
    id?: string
    curatorialProposal?: ICuratorialProposalDocument
    curatorialService?: ICuratorialServiceDocument
    curatorialInvitation?: ICuratorialInvitationDocument
    participant?: IUser
    curatorialUser?: IUser
    proposalArts: Array<ICuratorialProposalArtsDocument>
    milestone: Array<IMilestone>
    totalPrice: string
    totalOfferPrice: string
    transaction?: ITransaction
    information: string
    offerStatus: string
    isSubmitted?: boolean
    isSaved?: boolean
    isHide?: boolean
    isActive?: boolean
    readonly createdAt?: Date
    readonly updatedAt?: Date
    readonly deletedAt?: Date
}

export const initCuratorialOfferState: ICuratorialOfferState = {
    proposalArts: [],
    milestone: [],
    totalPrice: '',
    totalOfferPrice: '',
    information: '',
    offerStatus: ECuratorialOfferStatus.PENDING,
    isSubmitted: false
}