import { ILeftPanelReducer } from '../../interfaces'
import { IActionType, LEFT_PANEL_DATA } from '../types'

const initialState: ILeftPanelReducer = {
    component: 'art_collection',
    route_array: ['art_collection'],
    collection: undefined,
    item: undefined,
    user: undefined,
    galleryWalk: undefined,
    activeRigthPanel: false,
    galleryWalkSpace: undefined,
    auction: undefined,
    liveAuction: undefined
}

export const LeftPanelReducer = (state = initialState, action: IActionType): ILeftPanelReducer => {

    switch (action.type) {
        case LEFT_PANEL_DATA:

            return { ...state, ...action.payload };

        default:
            return state
    }
}