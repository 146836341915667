import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { IUser } from '../../../../interfaces'
import { IAuctionAttendessDocument, IAuctionDocument } from '../../../../interfaces/auction.interface'
import { IResponse } from '../../../../interfaces/db.interface'
import { auctionService, toastService } from '../../../../services'

interface IPorps {
    a: IAuctionDocument
    onClose: Function
    user: IUser
    fetch: Function
}

export const AuctionRegistration = ({ a, onClose, user, fetch }: IPorps) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [attendees, setAttendees] = useState<Array<IAuctionAttendessDocument>>([])

    const fetchUserAsAttendees = useCallback(() => {
        setLoading(true)
        auctionService.getUserAuctionReg().then((res: AxiosResponse<IResponse<Array<IAuctionAttendessDocument>>>) => {
            setAttendees([...res.data.data])
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        fetchUserAsAttendees()
    }, [fetchUserAsAttendees])

    const onCofirmReg = () => {
        setLoading(true)
        auctionService.registerUserToAuction({ auctionId: a._id }).then((res: AxiosResponse<IResponse<IAuctionDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetch()
                fetchUserAsAttendees()
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const checkIfUserRegistered = () => {
        let attend = attendees

        let fil = attend.filter((u: IAuctionAttendessDocument) => u.user._id === user._id && u.auction._id === a._id)

        if (fil && fil.length > 0) {
            return true
        }

        return false
    }

    const onDeleteAttende = (attendee: IAuctionAttendessDocument) => {
        setLoading(true)
        auctionService.deleteAttendee(attendee._id).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.data)
                fetch()
                fetchUserAsAttendees()
            }

        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <div className="at-addactionholder pb-3">
            <div className="at-formthemehead">
                <h2>Auction Registration</h2>
            </div>
            <div className="row at-row-mlr-6 at-textleft">
                <div className="col-12">
                    <h4 className="at-color-grey mb-0"><strong>{a.name}</strong></h4>
                    <p className="at-color-grey">{moment(a.startDate).format("DD/MM/YYYY")} {moment(a.startTime).format("hh:mm a")}-{moment(a.endTime).format("hh:mm a")} {a.timeZone ? a.timeZone.split('/')[1] : 'EST'}</p>
                </div>
                <div className="col-6 ">
                    <div className="form-group">
                        <button type="button" className="btn btn-secondary btn-block" onClick={() => onClose()}>CANCEL</button>
                    </div>
                </div>
                <div className="col-6 ">
                    <div className="form-group">
                        <button type="button" className="at-btn at-normalbtn-h40" disabled={checkIfUserRegistered() || loading} onClick={() => onCofirmReg()}>CONFIRM</button>
                    </div>
                </div>
            </div>
            {attendees && attendees.length > 0 && <>
                <div className="row at-row-mlr-6 at-textleft at-bordertop-1 pt-3">
                    <div className="col-12">
                        <h4><strong>Current Registrations</strong></h4>
                    </div>
                </div>
                <div className="row at-row-mlr-6 ">
                    <div className="col-8 at-textleft">
                    </div>
                    <div className="col-3">
                        <h6 className="at-borderbottom">PADDLE NUMBER</h6>
                    </div>
                    <div className="col-1">
                    </div>
                </div>
                {attendees.map((u: IAuctionAttendessDocument) => {
                    let auc = u.auction
                    return (
                        <div key={u._id} className="row at-row-mlr-6 align-items-center mb-3">
                            <div className="col-8 at-textleft">
                                <p className="mb-0 ">
                                    {auc.name}
                                </p>
                                <p className="mb-0">{moment(auc.startDate).format("DD/MM/YYYY")} {moment(auc.startTime).format("hh:mm a")}-{moment(auc.endTime).format("hh:mm a")} {auc.timeZone ? auc.timeZone.split('/')[1] : 'EST'}</p>
                            </div>
                            <div className="col-3">
                                <h5 className="mb-0"><strong>{u.paddleNumber}</strong></h5>
                            </div>
                            <div className="col-1">
                                <i className="icon-delete at-color-grey at-fs18 at-cursor-pointer" onClick={() => onDeleteAttende(u)}></i>
                            </div>
                        </div>
                    )
                })}
            </>}

        </div>
    )
}