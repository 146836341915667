
import { HttpService } from "./base.service";
import { IUser } from "../interfaces";

class CuratorialService extends HttpService {
    private readonly prefix: string = 'curatorial-service';

    /**
     * 
     *  Create Gallery Walk
     * @param data
     */
    createCuratorialService = (data: any): Promise<any> => this.post(`${this.prefix}/create`, data)


    /**
     * 
     *  List of User Gallery Walks
     * 
     */
    getAllUserCuratorialServices = (): Promise<any> => this.get(`${this.prefix}/user-services`)

    /**
     * 
     *  List of Gallery Walks
     * 
     */
    //  getGalleryWalks = (data: any): Promise<any> => this.get(`${this.prefix}/all`, data)

    /**
     * 
     *  List of Gallery Walks
     * @param id
     */
    getServiceId = (id: string): Promise<any> => this.get(`${this.prefix}/single/${id}`)

    /**
     * 
     *  Create Gallery Walk
     * @param data
     */

    updateCuratorialService = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/update/${id}`, data)

    /**
    * 
    * Add Participants
    * @param id
    */
    addParticipants = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/add-participants/${id}`, data)
    // deleteGalleryWalkById = (id: any): Promise<any> => this.put(`${this.prefix}/delete/${id}`)

    /**
    * 
    * Delete Curatorial Service
    * @param id
    */

    deleteServiceById = (id: any): Promise<any> => this.put(`${this.prefix}/delete/${id}`)
    // updateSingleGalleryWalkById = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/update-single-gallery-walk/${id}`, data)

    /**
     * 
     * Send invitaions
     */

    sendInvitaions = (id: string): Promise<any> => this.put(`${this.prefix}/send-invitations/${id}`)
    /**
     * 
     * Accept invitaions
     */

    acceptInvitaion = (id: string): Promise<any> => this.put(`${this.prefix}/accept-invitations/${id}`)
    /**
     * 
     * Reject invitaions
     */

    rejectInvitaions = (id: string): Promise<any> => this.put(`${this.prefix}/decline-invitations/${id}`)

    /**
     * 
     * Get Invitations by user
     */
    getInvitationsByUsers = (): Promise<any> => this.get(`${this.prefix}/get-invitation-by-user`)

    /**
     * 
     * Get Invitations by Id
     */
    getInvitationsById = (id: string): Promise<any> => this.get(`${this.prefix}/get-invitation-by-id/${id}`)

    /*********************************    Curatorial Proposal    ***************************************** */

    createCuratorialProposal = (data: any): Promise<any> => this.post(`${this.prefix}/proposal-create`, data)

    /**
     * 
     * Get Proposal by Id
     */
    getProposalById = (id: string): Promise<any> => this.get(`${this.prefix}/get-proposal-by-id/${id}`)

    /**
     * 
     * Get Proposal with Curatorial
     */
    getProposalWithCuratorial = (id: string): Promise<any> => this.get(`${this.prefix}/get-proposal-with-curatorial/${id}`)

    /**
     * 
     * Send Proposal
     */

    sendCuratorialProposal = (id: string): Promise<any> => this.put(`${this.prefix}/send-proposal-notification/${id}`)

    /**
     * 
     *  Create Curatorial Offer
     * @param data
     */
    createCuratorialOffer = (data: any): Promise<any> => this.post(`${this.prefix}/create-curatorial-offer`, data)

    /**
     * 
     *  Update Curatorial Offer
     * @param data
     */
    updateCuratorialOffer = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/update-curatorial-offer/${id}`, data)
    /**
     * 
     *  Save Curatorial Offer
     * @param data
     */
    saveCuratorialOffer = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/save-curatorial-offer/${id}`, data)

    /**
   * 
   * Get Invitation with Offer
   */
    curatorialInvitationWithOffer = (id: string): Promise<any> => this.get(`${this.prefix}/curatorial-invitation-with-offer/${id}`)



    /**
     * 
     *  Save Curatorial Offer
     * @param data
     */
    sendCuratorialOffer = (id: any): Promise<any> => this.put(`${this.prefix}/send-offer-notification/${id}`)

    /**
     * 
     * Get All Offer by user
     *
     */

    getAllUserCuratorialOffers = (): Promise<any> => this.get(`${this.prefix}/all-participant-curatorial-offers`)

    /**
     * 
     * Get All Offer by user
     *
     */

    getAllCuratorialUserOffers = (): Promise<any> => this.get(`${this.prefix}/all-user-curatorial-offers`)

    /**
     * 
     * Accept Curatorial Offers
     */

    acceptCuratorialOffer = (id: string): Promise<any> => this.put(`${this.prefix}/accept-curatorial-offer/${id}`)
    /**
     * 
     * Reject Curatorial Offers
     * 
     */

    rejectCuratorialOffer = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/reject-curatorial-offer/${id}`, data)

    /**
    * 
    * Request Curatorial Offers payment
    */

    requestCuratorialOfferPayment = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/request-payment/${id}`, data)

    offerCheckout = (data: any): Promise<any> => this.post(`${this.prefix}/checkout`, data);


    releaseOfferPayment = (data: any): Promise<any> => this.post(`${this.prefix}/payout/release`, data)
}

export const curatorialService = new CuratorialService()