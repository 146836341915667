import { ICuratorialOfferDocument, ICuratorialProposalDocument } from "./curatorial-proposal.interface";
import { IUser } from "./user.interface";

export interface ICuratorialServiceDocument {
    _id: string,
    id: string
    name: string,
    startDate: Date,
    endDate: Date,
    participants: Array<IUser>
    description: string,
    images: Array<any>,
    pdf: any
    isHide: boolean,
    isActive: boolean,
    poster: any,
    user: IUser,
    estBudget: string,
    invitations: Array<ICuratorialInvitationDocument>
    submissionDeadline: Date
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}


export interface ICuratorialServiceState {
    _id?: string,
    id?: string
    name: string,
    estBudget: string,
    startDate: any | Date,
    endDate: any | Date,
    submissionDeadline: any | Date,
    participants: Array<IUser>
    description: string,
    images: Array<any>,
    pdf: any
    isHide: boolean,
    isActive: boolean,
    poster: any,
}

export const initCuratorialServiceState: ICuratorialServiceState = {

    name: '',
    startDate: null,
    endDate: null,
    submissionDeadline: null,
    participants: [],
    description: '',
    estBudget: '',
    images: [],
    pdf: null,
    poster: null,
    isHide: false,
    isActive: true,
}


export interface ICuratorialInvitationDocument {
    _id: string,
    id: string
    participant: IUser
    curatorialService: ICuratorialServiceDocument
    curatorialProposal: ICuratorialProposalDocument
    curatorialOffer: ICuratorialOfferDocument
    invitationStatus: string
    curatorialUser: IUser
    isHide: boolean,
    isActive: boolean,
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}