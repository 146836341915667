import React, { useRef, useState } from 'react'
import LoadingComponent from './Loading.component';
import { VideoComponent } from './Video.component';
import ReactPlayer from 'react-player'


interface IProps {
    videoUrl: string
    midResolution: string
    thumbnail: string
    scrollPosition?: any
    isVideo: boolean
    id: string
    index?: number
}

export const FeedVideoWithImageComponent = (props: IProps) => {
    const { isVideo, midResolution, videoUrl, thumbnail, id, index } = props;
    const [loading, setLoading] = useState(true);
    const [clickPlayVideo, setClickPlayVideo] = useState(false);
    const [play, setPlay] = useState(false);

    const imageRef = useRef(null);
    const videoRef = useRef(null);

    const imageUrl = isVideo ? thumbnail : midResolution;
    const [image, setImage] = useState(imageUrl);
    const [muted, setMuted] = useState<boolean>(false)

    const onClickImage = (e: any) => {
        if (isVideo) {
            setPlay(true)
            setClickPlayVideo(true)
            setMuted(false)
        }
    }

    const imageView = (
        <img
            src={image}
            onLoad={() => setLoading(false)}
            alt={thumbnail}
            onError={() => !isVideo && setImage(thumbnail)}
            className={`post-image ${loading && 'blue-affect'}`}
            style={{ backgroundImage: `url(${thumbnail})` }}
            onClick={onClickImage}
        />
    );

    // const videoView = <VideoComponent url={videoUrl} index={index} autoPlay={playVideo} id={props.id} autoControls={true} className="at-feedvideo" />
    const videoView = <ReactPlayer
        url={videoUrl}
        playing={play}
        autoplay={true}
        muted={muted}
        onPause={() => {
            setPlay(false)
            setClickPlayVideo(false)
        }}
        onPlay={() => {
            setPlay(true)
            // setClickPlayVideo(true)
        }}
        controls
        playsinline
        height="100%"
        width="100%"
        ref={videoRef}
    />

    const onMouseEnter = () => {
        if (isVideo) {
            if (videoRef.current) {
                console.log("video ref:-=-=-=", videoRef.current)
                return
            }
            setPlay(true)

            setMuted(true)
        }

    }
    const onMouseleave = () => {
        if (isVideo) {
            if (clickPlayVideo) return
            setPlay(false)
            setMuted(false)
        }

    }

    return (
        <span className="image-post" id={id} ref={imageRef} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseleave()}>
            {
                play ? videoView : imageView

            }
            {
                (isVideo) && <span onClick={onClickImage} className={`${!clickPlayVideo && 'videoButton icon-play-button'}`}></span>
            }
            {
                loading && <div className="shammering-affect">
                    <LoadingComponent />
                </div>
            }
        </span>
    )
}