import React, { useState, useEffect } from 'react';
import { parse } from 'querystring';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CurrencyFormat } from '../../../components/common/CurrencyFormat'
import LoaderComponent from '../../common/Loading.component'
import { PaymentForm } from '.././PaymentForm'
import LoadingComponent from '../../common/Loading.component';
import { STRIPE_KEY } from '../../../environment';

interface IProps {

    onClose: Function
    onSubmit: Function
    item: any,
    tax: any,
    platformFee: any,
    offerPrice?: string
    loading?: boolean,
    showPaymentForm: boolean
    setDropinInstance: Function
    onConfirmPay: Function,
    message?: string,
    stripeData: any,
    hideOtherPay?: any
}
const stripePromise = loadStripe(STRIPE_KEY)
export const PayPopup = ({ item, onClose, onSubmit, tax, platformFee, offerPrice, message, loading, setDropinInstance, showPaymentForm, onConfirmPay, stripeData, hideOtherPay }: IProps) => {

    const [showMessageBox, setShowMessageBox] = useState<Boolean>(false)
    const [showShippingDetail, setShippingDetail] = useState<boolean>(false)

    let total
    if (tax && tax !== null) {
        total = `${(offerPrice ? parseFloat(offerPrice) : 0)  + parseFloat(tax)}`
    } else {
        total = `${(offerPrice ? parseFloat(offerPrice) : 0) }`
    }
    if (hideOtherPay){
        total = `${(offerPrice ? parseFloat(offerPrice) : 0) +  parseFloat(tax) + platformFee}`
    }
    
    return (
        <>
            <div className="at-themepopupbox text-left">
                <a className="at-close cursor-pointer" onClick={() => { onClose(); setShowMessageBox(false) }} id="at-close"><i className="icon-cancel"></i></a>
                {/* <div className="at-addactionholder scrollable at-height-500"> */}
                <div className="at-addactionholder scrollable">
                    <div className="at-formthemehead">
                        <h2>Thank you for your Purchase</h2>
                    </div>
                    <div className="row at-row-mlr-12">
                        <div className="col-6">
                            <p>Price</p>
                        </div>
                        <div className="col-6 text-right">
                            <p className="at-padding-right-40"> <span><CurrencyFormat amount={offerPrice} /></span></p>
                        </div>
                    </div>
                    {/* {!hideOtherPay && <div className="row at-row-mlr-12">
                        <div className="col-6">
                            <p>Shipping</p>
                        </div>
                        <div className="col-6 text-right">
                            <p> <span><CurrencyFormat amount={item.shipping || 0} /><i className="icon-info at-icon-infomessage d-inline" onClick={() => setShippingDetail(!showShippingDetail)}></i> </span></p>
                        </div>
                    </div>}
                    {!hideOtherPay &&<div className="row at-row-mlr-12">
                        <div className="col-6">
                            <p>Platform Fee</p>
                        </div>
                        <div className="col-6 text-right">
                            <p><span><CurrencyFormat amount={platformFee} /><i className="icon-info at-icon-infomessage d-inline" onClick={() => setShowMessageBox(!showMessageBox)} ></i></span></p>
                        </div>
                    </div>} */}
                    <div className="row at-row-mlr-12">
                        <div className="col-6">
                            <p>TAX</p>
                        </div>
                        <div className="col-6 text-right">
                            <p className="at-padding-right-40"><span><CurrencyFormat amount={tax} /></span></p>
                        </div>
                    </div>
                    <span className="border-top d-block pb-4"></span>
                    <div className="row at-row-mlr-12">
                        <div className="col-6">
                            <p>Total</p>
                        </div>
                        <div className="col-6 text-right">
                            <p><strong><CurrencyFormat amount={total} /></strong></p>
                        </div>
                    </div>
                    <div className="row at-row-mlr-12">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <button type="button" className="cursor-pointer btn at-btn-cancel btn-block" onClick={() => onClose()}>CANCEL</button>
                        </div>
                        {!showPaymentForm && <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 at-mt-sm-10px">
                            <button type="button" className="cursor-pointer at-btn-fullblock at-btn-padding10" disabled={loading} onClick={() => onConfirmPay()}  >{loading ? 'Please wait' : 'Pay'}</button>
                        </div>}
                    </div>
                   {showShippingDetail && !hideOtherPay && <div className="at-message-box" style={{ display: (showShippingDetail ? 'block' : 'none') }}>
                        <h6>Shipping</h6>
                        <p>The shipping fee is based on in-country ground shipping.</p>
                    </div>}
                    {showMessageBox && !hideOtherPay &&<div className="at-message-box" style={{ display: (showMessageBox ? 'block' : 'none') }}>
                        <h6>Platform</h6>
                        <p>100% percent of the price proceeds are paid to the ARTIST or the ARTIST & GALLERY.
                    The PLATFORM FEE is the revenue source for OMNI. Thank you for your understanding.</p>
                    </div>}
                    {showPaymentForm && <div className="pt-2 pb-2">
                        <Elements stripe={stripePromise}>
                            <PaymentForm message={message} setDropinInstance={setDropinInstance} onConfirmPay={onSubmit} loading={loading} stripeData={stripeData} />
                        </Elements>
                    </div>
                    }

                </div>
            </div>
        </>
    )
}