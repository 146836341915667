import {IAccount} from "../../interfaces";
import {IActionType, LOGIN, LOGOUT} from "../types";
import { LOGGED_IN } from "../../constants";
import { socketNode } from "../../services/socket.service";

const initialState: IAccount | any = {
    access_token: '',
    user: {},
    remember: false,
    isAdmin:false
}

export const authReducer = (state = initialState, action: IActionType ): IAccount => {

    
    switch (action.type) {
        case LOGIN:
            sessionStorage.setItem(LOGGED_IN, 'true');
            return { ...action.payload };
        case LOGOUT:
            socketNode.removeEventListener(state.user._id)
            sessionStorage.clear();
            return { ...initialState };
        default:
            return state;
    }
}
