import React from 'react'
import { useHistory } from 'react-router'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { _logger } from '../../helper'
import { CreateServices } from '../../components/User/Services/ProfessionalServices/CreateServices'
import { ManageServices } from '../../components/User/Services/ProfessionalServices/ManageServices';

interface IProps {

}
const ProfessionalSerives = ({ }: IProps) => {
    const history = useHistory()
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Professional Services</h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                        <Tab label="Create Services">
                            <CreateServices />
                        </Tab>
                        <Tab label="Manage Services">
                            <ManageServices />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </main>
    )
}

export default ProfessionalSerives