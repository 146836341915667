import React, { useState, useEffect } from 'react'
import Dropzone, { IUploadParams, IFileWithMeta, StatusValue } from 'react-dropzone-uploader'
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { tokenSelector } from '../../store/selectors';
import { toastService } from '../../services';
import { _logger } from '../../helper';

type TProps = {
    url: string
    onComplete: Function
}

export const UploaderComponent = (props: TProps) => {
  
  const [files, setFiles] = useState<Array<any>>([]);
  
  const token = useSelector(tokenSelector);
  
  const getUploadParams = ({ meta }: any): IUploadParams => {
    const { url } = props
    return {
        url,
        method: 'POST',
        meta: { fileUrl: `${url}/${encodeURIComponent(meta.name)}` },
        headers: { Authorization: `Bearer ${token}`}
    }
  }

  const handleChangeStatus = ({ meta, xhr, ...rest }: IFileWithMeta, status: StatusValue) => {
    
    if (status === 'done' && xhr) {
      let response: any = JSON.parse(xhr.response);
      
      const _files = files.filter(({ file: {name} }: IFileWithMeta) => name !== rest.file.name);
      if (_files.length < 1) {
        props.onComplete()
      }
      setFiles([..._files])
      
    } else if (status === 'ready') {
        setFiles([...files, rest])
    }
  }

  const handleSubmit = (files: any, allFiles: any) => {
    _logger('files', files.map((f: any) => f.meta))
  }

  const startUpload = () => {
    files.forEach((f: any) => {
      f.restart()
    })
  }

  return (
    <div style={{width: '100%'}}>
        <div style={{width: '100%'}}>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                canCancel={true}
                autoUpload={false}
                submitButtonDisabled={false}
                submitButtonContent="Upload"
                SubmitButtonComponent={() => <button className="at-btn at-minwidth-100" onClick={startUpload}>Upload</button>}
                accept="image/*, video/*"
                inputContent={(files: any, extra: any) => (extra.reject ? 'Image files only' : 'UPLOAD IMAGES')}
                styles={{
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (files: any, extra: any) => (extra.reject ? { color: 'red' } : {}),
                }}
            />
        </div>
    </div>
  )
}