import React from 'react'
import { IFormates } from '../../../../../interfaces'
import { ISelectedService, ISelectedServiceState } from '../../../../../interfaces/services.interface'
import { ProposalImageView } from '../../../../common/ProposalImageView'
interface IProps {
    format: IFormates
    selectedPrint: ISelectedServiceState
}
export const BuyerSeePrinterDetails = ({ format, selectedPrint }: IProps) => {
    return (
        <div className="at-addactionholder">
            <div className="at-formthemehead at-headselectprinter pb-2 ">
                <h2 className="at-textcenter">Details</h2>
                <p>{format.size}</p>
            </div>
            <div className="at-selectprinter">
                <div className="at-greybgboxtable">

                    {selectedPrint && selectedPrint.printingService && <>  <div className="at-printerbox">
                        <h6 className="at-printer-greyheading">PRINT MEDIUM</h6>
                        <h6>{selectedPrint.printingService.name}: <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: selectedPrint.printingService.description }}></span></h6>
                    </div>
                        {selectedPrint.selectedSize && <div className="at-printerbox">
                            <h6>Size: <span className="at-span-printerbox" >{selectedPrint.selectedSize.height} x {selectedPrint.selectedSize.width}</span></h6>
                        </div>}
                        <div className="at-printerbox">
                            <h6>Shipping: <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: selectedPrint.printingService.shipmentInformation }}></span></h6>
                        </div>
                        <figure>
                            <ProposalImageView isServicImage={true} item={selectedPrint.printingService} />
                        </figure> </>}
                </div>
            </div>
        </div>
    )
}