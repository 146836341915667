import { AxiosResponse } from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GalleryWalkComponent } from '../../components/ArtCentral/GalleryWalk/GalleryWalk.Component'
import { ERole } from '../../enums'
import { ISpace } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { IGalleryDocument, IGalleryWalkSpacesDocument } from '../../interfaces/Gallery-walk.interface'
import { routes } from '../../router'
import { galleryWalkService } from '../../services'

interface IProps {

}
export const SingleGalleryView = ({ }: IProps) => {

    const [galleryWalk, setGalleryWalk] = useState<IGalleryDocument>()
    const [loading, setLoading] = useState<boolean>(false)
    const params = (useParams() as any).id
    const history = useHistory()
    const fetchGalleryWalk = () => {
        if (!params) {
            history.goBack()
            return
        }
        setLoading(true)
        galleryWalkService.getAllGalleryWalkById(params).then((res: AxiosResponse<IResponse<IGalleryDocument>>) => {
            setGalleryWalk(res.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onClickFeatureArts = (s: IGalleryWalkSpacesDocument) => {
        history.push(`${routes.artCentral.gelleryWalk.featureArts}/${s._id}`)
    }
    useEffect(() => {
        fetchGalleryWalk()
    }, [])
    return (
        <div className="at-createcollection at-gallerywalkpage at-contentarea">
            <div className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>{galleryWalk && galleryWalk.name ? galleryWalk.name : 'Gallery Walk'}</h2>
                </div>
                {galleryWalk && galleryWalk.galleryWalkSpaces && galleryWalk.galleryWalkSpaces.length > 0 && galleryWalk.galleryWalkSpaces.map((s: IGalleryWalkSpacesDocument) => {
                    if (s.user && s.user.roles && s.user.roles.includes(ERole.TESTER)) {
                        return null
                    }
                    return <GalleryWalkComponent space={s.space} onClickOnGalleryWalkSpace={() => onClickFeatureArts(s)} showArts={s.featuredArts && s.featuredArts.length > 0} />
                })}
            </div>
        </div>
    )
}