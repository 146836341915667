import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../router'
import { IAssignmentCollection, IArtAssignment, IUser } from '../../../interfaces'
import { collectionImage } from '../../../shared'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector } from '../../../store/selectors'
import { NoDataComponent } from '../../common/NoData.component'
import { images } from '../../../assets/image'
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component'
import { assignmentService, toastService } from '../../../services'
import { BSLoading } from '../../common/Loading.component'
import { refreshUser } from '../../../store/actions'
import { async } from 'rxjs/internal/scheduler/async'
import { AxiosResponse } from 'axios'
import { DescriptionModal } from '../../common/Modals/Description.modal'
import { IResponse } from '../../../interfaces/db.interface'
interface IProps {
    collection: IAssignmentCollection
    refresh: Function
    onMoveDown?: any
    index?: any,
    onMoveUp?: any
}
export const AgentCollectionComponent = ({ collection, refresh, onMoveDown, onMoveUp, index }: IProps) => {
    const currentUser: IUser = useSelector(userSelector);
    const [editing, setEditing] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)
    const [isHide, setIsHide] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')
    const [openDeleteBox, setOpenDeleteBox] = useState<boolean>(false)
    const [showDescription, setShowDescription] = useState<boolean>(false)
    const [selectedCol, setSelectedCol] = useState<any>()
    const dispatch = useDispatch();
    useEffect(() => {
        setTitle(collection.title)
        setIsHide(collection.isHide)
    }, [])

    const actions = () => {
        const user: any = collection.user;
        if (currentUser && currentUser._id !== user) return;
        return (
            <span>
                <div className="at-btndelete pt-1 d-flex align-item-center">
                    <i title="Hide Collection" className={`${isHide ? 'icon-view-disable' : 'icon-view'} fs-14 mr-3 cursor-pointer `} onClick={() => hideCollection(!isHide)}></i>
                    <i className="icon-delete fs-14 cursor-pointer " title="Delete" onClick={() => setOpenDeleteBox(true)}></i>
                    <div className="d-flex at-agentarrow-wrap">
                        <i title="Sequence Collection" className={`icon-Down-Arrow fa-rotate-180 fs-14 cursor-pointer `} onClick={() => onMoveUp(index)}></i>
                        <i title="Sequence Collection" className={`icon-Down-Arrow fs-14 mr-3 cursor-pointer `} onClick={() => onMoveDown(index)}></i>
                    </div>
                </div>
            </span>
        )
    }

    const deleteCollection = async (): Promise<void> => {
        setDeleting(true)
        await assignmentService.deleteAssignmentToCollection(collection.id)
        toastService.success('Collection deleted successfully.')
        dispatch(refreshUser())
        refresh();
        setDeleting(false)
        setOpenDeleteBox(false)
    }

    const updateCollectionTitle = async (): Promise<void> => {
        setUpdating(true)
        await assignmentService.updateAssignmentToCollection(collection.id, title)
        toastService.success('Collection updated successfully.')
        refresh();
        setUpdating(false)
        setEditing(false)
    }

    const hideCollection = async (status: boolean) => {
        assignmentService.hideAgentCollection(collection.id, { isHide: status }).then((res: AxiosResponse<IResponse<any>>) => {
            setIsHide(status)
            toastService.success(res.data.message)
        })
    }

    const openDescription = (c: any) => {
        setSelectedCol(c)
        setShowDescription(true)
    }
    const onCloseDescriptionBox = () => {
        let obj: any = {}
        setSelectedCol(obj)
        setShowDescription(false)
    }

    const onSubmitDescriptionBox = () => {
        let obj: any = {}
        setSelectedCol(obj)
        setShowDescription(false)
        refresh()
    }

    const fifthAssignmentImage = (c: IAssignmentCollection) => {
        let item = c.assignments[5]?.art

        return item && item.id ? <img src={collectionImage(item, `high_${item.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
            :
            <img src={images.gallery.img01} alt="" />
    }
    const collectionImages = (assignments: Array<IArtAssignment>) => {
        const items = assignments.length > 5 ? assignments.slice(0, 5) : assignments;

        return (
            items.map(({ art: item, id }: IArtAssignment) => (
                <li key={item.id}>
                    <Link to={`${routes.agent.showAssigment}/${id}`}>
                        <figure>
                            <img src={collectionImage(item, `high_${item.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                        </figure>
                    </Link>
                </li>
            ))
        )
    }

    return (
        <>
            <div className="at-collectiongallery">
                <div className="at-collectiongallerybox at-artgallerybox" key={collection.id}>
                    <div className="at-gallerytitle">
                        {/* <h3 className="cursor-pointer" onClick={() => this.props.history.push(`${routes.artCentral.collection.view}/${collection.id}`)}>{collection.title}<span>{collection.itemsCount} Images</span></h3> */}
                        <h3>
                            {
                                !editing ? <Link to={`${routes.agent.collection}/${collection.id}`}>
                                    {title}
                                </Link> : <input className='form-control' value={title} onBlur={updateCollectionTitle} onChange={(v: any) => setTitle(v.target.value)} />
                            }
                            {
                                updating ? <BSLoading /> : <><i className={`ml-3 cursor-pointer fs-16 ${editing ? 'icon-tick' : 'icon-edit'}`} title={`${editing ? 'Done' : 'Edit'}`}
                                    onClick={() => setEditing(true)}></i>
                                    <i className="icon-info fs-16 ml-2 cursor-pointer" title="Collection Information" onClick={() => openDescription(collection)}></i>
                                </>
                            }
                            <span>{collection.assignmentsCount} Assignments</span>

                        </h3>

                        {
                            actions()
                        }
                        {

                        }

                    </div>
                    <ul className="at-gallery">
                        {collection.assignments.length < 1 && <NoDataComponent text="No Assignment Added." />}
                        {collectionImages(collection.assignments)}
                        {
                            (collection.assignmentsCount > 5) && (
                                <li>
                                    <Link to={`${routes.agent.collection}/${collection.id}`}>
                                        <figure>
                                            {fifthAssignmentImage(collection)}
                                            <figcaption>
                                                <span><i className="icon-add"></i>{collection.assignmentsCount - 5}</span>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
            {showDescription && <DescriptionModal open={showDescription} title={selectedCol.title} description={selectedCol.description} keyword={selectedCol.keywords} onClose={onCloseDescriptionBox} collection={selectedCol} event={assignmentService.updateDescription} onSubmit={onSubmitDescriptionBox} user={currentUser} />}
            <ConfirmAlertComponent open={openDeleteBox} message="Are you sure you want to delete this collection?" title="Confirmation!" onCancel={() => setOpenDeleteBox(false)} onConfirm={deleteCollection} confirmTitle={!deleting ? 'Delete' : <BSLoading />} />
        </>
    )
}
