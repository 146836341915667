import React from 'react'
import { ICommissionResponse, INotification } from '../../interfaces'
import { collectionImage } from '../../shared'
import { CurrencyFormat } from '../common/CurrencyFormat'

interface IProps {
    notification: INotification
}

export const RequestCommissionWork = ({ notification }: IProps) => {
    let commission = notification.commissionID ? (notification.commissionID as ICommissionResponse) : null
    let user = notification.itemId?.art_collection.user
    return (
        <div className="at-transaction-wrap">
            <div className="at-transaction-box">
                {commission && <div className="at-rejectaccept-two at-haslayout">
                    <h5><span>Inspiration Art:</span></h5>
                    <div className="at-figure-box d-flex">
                        {notification.itemId && <figure>
                            <img src={collectionImage(notification.itemId, `high_${notification.itemId.url}`, true)} className="img-fluid" alt="" />
                        </figure>}
                        <div className="at-figure-text">
                            {user && <h4><span className="at-color-grey">{user.firstName} {user.lastName}</span></h4>}
                            <h6>{notification.itemId?.name}</h6>
                            <p>
                                {notification.itemId?.description}
                            </p>
                        </div>
                    </div>

                    <h5 className="mb-1"><span className="at-color-grey">Additional Information: </span>{commission.additionalInformation}</h5>
                    <h5 className="mb-1"><span className="at-color-grey">Budget: </span><CurrencyFormat amount={commission.budget} /></h5>
                    <h5 className="mb-1"><span className="at-color-grey">Deadline (weeks): </span>{commission.deadline}</h5>
                    <div className="at-transfer-upper text-center mt-2">
                        <button className="at-btn at-btn-sm" >Next</button>
                    </div>
                </div>}
            </div>
        </div>
    )
}
