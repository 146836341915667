import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { connect, useSelector } from 'react-redux'
import { IArtReturn, IDisputed, IFormates, IShippingDetail, IStoreReducers, ITransaction, IUser } from '../../../interfaces'
import { toastService, transactionService } from '../../../services'
import moment from 'moment'
import { CurrencyFormat } from '../../common/CurrencyFormat'
import { collectionImage } from '../../../shared'
import LoadingComponent from '../../common/Loading.component'
import { UserDetailPopup } from '../../common/Modals/UserDetailPopup'
import { DisputeModal } from '../../common/Modals/DisputePopup'
import { ArtReturnModal } from '../../common/Modals/ArtReturnPopup'
import { TransactionLayout } from '../../common/TransactionLayout'
import { transactionStatus } from '../../../helper'
import { ETransactionStatus } from '../../../enums'
import { ShippingPopup } from '../../common/Modals/ShippingPopup'
import { userSelector } from '../../../store/selectors'
import { useLocation } from 'react-router-dom'

interface IProps {
    routeUser?: IUser
}
const BuyerTransaction = ({ routeUser }: IProps) => {
    const storeUser = useSelector(userSelector)
    const user = routeUser && routeUser._id ? routeUser : storeUser
    const [transactions, setTransactions] = useState<Array<ITransaction>>([])
    const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<IUser>()
    const [userType, setUserType] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [openDisputePopup, setDisputePopup] = useState<boolean>(false)
    const [selectedTransaction, setSelectedTransaction] = useState<ITransaction>()
    const [openArtReturn, setArtReturn] = useState<boolean>(false)
    const [showArtReturnForm, setArtReturnForm] = useState<boolean>(false)
    const [openShipmentPopup, setShipmentPopup] = useState<boolean>(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        transactionService.getBuyerTransactions(user?._id).then((res: AxiosResponse<any>) => {
            let data = [...res.data.response]
            setLoading(false)
            setTransactions(data.reverse())
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const handleDetailPopup = (item: IUser, type: string, data: ITransaction) => {
        setShowDetailPopup(true)
        setUserType(type)
        setSelectedUser(item)
        setSelectedTransaction(data)
    }

    const onCloseDetailPopup = () => {
        let obj: any = {}
        setSelectedTransaction(obj)
        setShowDetailPopup(false)
    }

    const getItemType = (item: ITransaction) => {
        if (item.formatesId) {
            let format: Array<IFormates> = item?.itemID?.formates.filter((value: IFormates) => value._id === item?.formatesId)
            if (format && format.length <= 0) {
                return ` `
            }
            if (format && format[0]) {
                return format[0].size
            }
            return ` `
        }
        return `Original`
    }

    const onOpenDisputePopup = (item: ITransaction) => {
        setSelectedTransaction(item)
        setDisputePopup(true)
    }

    const onCloseDisputePopup = () => {
        let obj: any = {}
        setSelectedTransaction(obj)
        setDisputePopup(false)
    }

    const onSubmitDisputePopup = (dispute: IDisputed) => {
        let data = {
            id: selectedTransaction?._id,
            dispute: { ...dispute }
        }
        if (data) {
            transactionService.updateTransactionDispute(data).then((response: AxiosResponse<any>) => {
                toastService.success("Dispute request send to administrator")
                fetch()
                onCloseDisputePopup()

            }).catch(err => console.log(err))


        }
    }

    const onOpenArtReturnPopup = (item: ITransaction) => {
        if (item.shipment.status) {
            let date = new Date()
            let currentDate = moment(date)
            let conditionalDate = moment(item.shipment.delivery_date).add(7, 'days')
            let diff = conditionalDate.valueOf() - currentDate.valueOf()
            if (diff < 0) {
                setArtReturnForm(false)
            } else {
                setArtReturnForm(true)
            }
        } else {
            setArtReturnForm(true)
        }
        setSelectedTransaction(item)
        setArtReturn(true)
    }

    const onCloseArtReturnPopup = () => {
        let obj: any = {}
        setSelectedTransaction(obj)
        setArtReturn(false)
        setArtReturnForm(false)
    }

    const onSubmitArtReturn = (item: IArtReturn) => {
        let data = {
            id: selectedTransaction?._id,
            return_art: { ...item },
            transactionState: ETransactionStatus.RETURN_PENDING
        }
        transactionService.updateTransactionArt(data).then((res: AxiosResponse<any>) => {
            toastService.success("You have successfully submitted the request to return the work of art you purchased.")
            fetch()
            onCloseArtReturnPopup()
        }).catch((err => {
            onCloseArtReturnPopup()
            console.log(err)
        }))
    }

    const onOpenShipmentModal = (item: ITransaction) => {
        setSelectedTransaction(item)
        setShipmentPopup(true)
    }

    const onCloseShipmentPopup = () => {
        let object: any = {}
        setSelectedTransaction(object)
        setShipmentPopup(false)
    }

    const onSubmitShipment = (shipment: IShippingDetail) => {
        let data = selectedTransaction?.return_art

        if (data) {
            data.shipment_company = shipment.shipment_company
            data.delivery_date = shipment.delivery_date
            data.shipment_date = shipment.shipment_date
            data.tracking_number = shipment.tracking_number
            data.labelStatus = ETransactionStatus.RETURN_SHIPPED
            let obj = {
                id: selectedTransaction?.id,
                transactionState: ETransactionStatus.RETURN_SHIPPED,
                return_art: { ...data }
            }

            transactionService.updateTransactionArt(obj).then((res: AxiosResponse<any>) => {
                toastService.success("Successfully updated shipment details.")
                fetch()
                onCloseShipmentPopup()
            }).catch((err => {
                onCloseShipmentPopup()
                console.log(err)
            }))
        }
    }

    const getButtonStatus = (item: ITransaction) => {

        return transactionStatus(item.transactionState)
        // if (item.transactionState === "completed") {
        //     return { status: 'Paid', color: 'orange' }
        // } else if (item.transactionState === "refunded") {
        //     return { status: 'Refunded', color: 'orange' }
        // } else {
        //     return { status: 'Pending', color: 'grey' }
        // }
    }
    const disputeDisabled = (item: ITransaction) => {
        if (item.disputed.status || (item.disputed.labelStatus === ETransactionStatus.RESOLVED) || (item.transactionState === ETransactionStatus.RETURN_COMPLETED)
            || item.transactionState === ETransactionStatus.PAID
        ) {
            return true
        }

        return false
    }

    const disableReturnArt = (t: ITransaction) => {
        if (t.transactionState === ETransactionStatus.RETURN_NOT_ALLOWED) {
            return true
        }

        if (t.disputed.labelStatus === ETransactionStatus.RETURN_COMPLETED) {
            return true
        }

        if (t.transactionState === ETransactionStatus.COMPLETED) {
            return true
        }
        if (t.transactionState === ETransactionStatus.RETURN_PENDING) {
            return true
        }

        return false
    }
    return (
        <>
            <div className="at-transaction-main">
                {transactions?.length > 0 ? (
                    <>
                        {transactions?.map((item: ITransaction) => (
                            <>
                                {item.shipment && item.shipment.status && item.transactionType === 'ART' && <div className='at-btniconsbox'>
                                    <button title="Dispute" className="at-btnicon at-btnpendingapproveicon" disabled={disputeDisabled(item)} onClick={() => onOpenDisputePopup(item)}><i className="icon-dispute-icon"></i></button>
                                    {item.return_art && item.return_art.status ? <button title="Shipping details" disabled={item.return_art.shipment_company ? true : false} className="at-btnshipping at-btnicon" onClick={() => onOpenShipmentModal(item)}><i className="icon-Bus" ></i></button> : <button title="Return art" disabled={disableReturnArt(item)} className="at-btnicon at-btnreturnart" onClick={() => onOpenArtReturnPopup(item)}><i className="icon-Box-1"></i></button>}
                                </div>}
                                <div key={item._id} className="at-transaction-wrap">
                                    <TransactionLayout item={item} handleDetailPopup={handleDetailPopup} getButtonStatus={getButtonStatus} getItemType={getItemType} />
                                </div>
                            </>
                        ))}
                    </>
                ) : (<>{loading && <LoadingComponent className='at-transaction-loader' />}
                </>)}
            </div>
            {showDetailPopup && <UserDetailPopup userType={userType} user={selectedUser} showSellerDetails={showDetailPopup} onClose={onCloseDetailPopup} transaction={transactions} item={selectedTransaction} />}
            {openDisputePopup && <DisputeModal open={openDisputePopup} onClose={onCloseDisputePopup} onSubmit={onSubmitDisputePopup} transaction={selectedTransaction} />}
            {openArtReturn && <ArtReturnModal open={openArtReturn} onClose={onCloseArtReturnPopup} onSubmit={onSubmitArtReturn} transaction={selectedTransaction} showForm={showArtReturnForm} />}
            {openShipmentPopup && <ShippingPopup open={openShipmentPopup} onClose={onCloseShipmentPopup} onSubmit={onSubmitShipment} transaction={selectedTransaction} isReturnArt={true} />}
        </>
    )
}



export default BuyerTransaction