import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux';
import { Subscription } from 'rxjs';
import { images } from '../../../../../assets/image';
import { PLATFORM_FEE } from '../../../../../environment';
import { ICollectionItem } from '../../../../../interfaces';
import { IAuctionArtDocument, IAuctionAttendessDocument, IAuctionDocument } from '../../../../../interfaces/auction.interface';
import { IResponse } from '../../../../../interfaces/db.interface';
import { AuctionAddNewArtAction, AuctionArtBiddingRestartAction, AuctionArtBiddingStopAction, AuctionArtPurchasedAction, auctionService, toastService } from '../../../../../services';
import { collectionImage } from '../../../../../shared';
import { userSelector } from '../../../../../store/selectors';
import { CustomImage } from '../../../../../templates/components/CustomImage';
import { CurrencyFormat } from '../../../../common/CurrencyFormat';
import { CurrencyField } from '../../../../common/CurrencyInput';
import { ImageLoading } from '../../../../common/ImageLoading';
import LoadingComponent from '../../../../common/Loading.component';
import BiddingArtListBoxComponent from './BiddingArtListBox.component';
import BiddingBoxComponent from './BiddingBox.component';

interface IProps {
    onGoBack: Function
    id?: string
}
export const BuyerLiveAuction = ({ onGoBack, id }: IProps) => {
    const [name, setName] = useState('')

    const time = 2
    const interval = useRef<any>(null)
    const user = useSelector(userSelector)
    const [attendee, setAttendee] = useState<IAuctionAttendessDocument>()
    const [auctionArt, setAuctionArt] = useState<IAuctionArtDocument>()
    const [auction, setAuction] = useState<IAuctionDocument>()
    const [selectedArt, setSelectedArt] = useState<ICollectionItem>()
    const [images, setImages] = useState<Array<any>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [auctionLoading, setAuctionLoading] = useState<boolean>(false)
    const subscribe = new Subscription()

    const fetchAuction = useCallback(() => {
        if (!id) return
        setLoading(true)
        auctionService.getAuctionById(id).then((response: AxiosResponse<IResponse<IAuctionDocument>>) => {
            setAuction(response.data.data)
            fetchCurrentuctionArt(response.data.data._id)
            if (response.data.data.attendees) {
                let newAtted = response.data.data.attendees.find((a: IAuctionAttendessDocument) => a.user._id === user._id)
                setAttendee(newAtted)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    const fetchCurrentuctionArt = useCallback((auctionId: string) => {
        setAuctionLoading(true)
        auctionService.getCurrentArtInAuction(auctionId).then((response: AxiosResponse<IResponse<IAuctionArtDocument>>) => {
            setAuctionArt(response.data.data)
            setAuctionLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setAuctionLoading(false)
        })
    }, [])

    const fetchAuctionArtById = useCallback((auctionId: string, showMessage: boolean = false, showSoldMessage: boolean = false, showResatrtMessage: boolean = false) => {
        setAuctionLoading(true)
        auctionService.getAuctionArtById(auctionId).then((response: AxiosResponse<IResponse<IAuctionArtDocument>>) => {
            setAuctionArt(response.data.data)

            if (showMessage) {
                let { data } = response.data
                if (!data.activeForBidding && (data.finalBid && data.finalBid.attendee && data.finalBid.attendee.user && data.finalBid.attendee.user._id === user._id)) {
                    toastService.success("The bidding has been stopped. You have 2 minutes to buy this peice of art.")
                }

            }

            if (showSoldMessage) {
                let { data } = response.data
                if (!data.activeForBidding && data.isPurchased && (data.finalBid && data.finalBid.attendee && data.finalBid.attendee.user && data.finalBid.attendee.user._id !== user._id)) {
                    toastService.success("This art has been sold")
                    fetchAuction()
                }
            }

            // if () {
            //     let { data } = response.data
            //     if (!data.activeForBidding && data.isPurchased && (data.finalBid && data.finalBid.attendee && data.finalBid.attendee.user && data.finalBid.attendee.user._id !== user._id)) {
            //         toastService.success("This art has been sold")
            //     }
            // }

            setAuctionLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setAuctionLoading(false)
        })
    }, [])

    const onRestartAuctionOnArt = (id: string) => {
        setLoading(true)
        auctionService.restartAuctionOnArt(id).then((res: AxiosResponse<IResponse<IAuctionArtDocument>>) => {
            // if (res.data.success) {
            //     toastService.success(res.data.message)
            // }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(true)
        })
    }

    useEffect(() => {
        fetchAuction()
    }, [fetchAuction])

    // useEffect(() => {
    //     if (auctionArt) {
    //         if (auctionArt.isPurchased && (auctionArt.finalBid && auctionArt.finalBid.attendee && auctionArt.finalBid.attendee.user && auctionArt.finalBid.attendee.user._id === user._id || auctionArt.auction.user._id === user._id)) {
    //             toastService.success("This art has been sold")
    //             if (interval && interval.current) {
    //                 clearInterval(interval.current)
    //             }
    //         }

    //         // if (!auctionArt.isPurchased && !auctionArt.activeForBidding && auctionArt.purchasingTime && (auctionArt.finalBid && auctionArt.finalBid.attendee && auctionArt.finalBid.attendee.user && auctionArt.finalBid.attendee.user._id === user._id)) {
    //         //     checkTime()
    //         // }
    //     }
    // }, [auctionArt])



    useEffect(() => {
        const newArt = AuctionAddNewArtAction.listen.subscribe((status: boolean) => {

            if (status) {
                // if (!auction) return
                fetchCurrentuctionArt(id as string)
            }
        })
        subscribe.add(newArt)

        const stopBidding = AuctionArtBiddingStopAction.listen.subscribe((status: string) => {
            if (status) {
                fetchAuctionArtById(status, true)
            }
        })
        subscribe.add(stopBidding)

        const artPurchased = AuctionArtPurchasedAction.listen.subscribe((status: string) => {

            if (status) {

                fetchAuctionArtById(status, false, true)
            }
        })
        subscribe.add(artPurchased)


        const restartBidding = AuctionArtBiddingRestartAction.listen.subscribe((status: string) => {
            if (status) {
                fetchAuctionArtById(status)
            }
        })

        subscribe.add(restartBidding)

        return () => {
            subscribe.unsubscribe()
        }

    }, [])

    const getImages = () => {

        if (auctionArt) {
            let origImage = [
                {
                    original: collectionImage({} as any, `${auctionArt.art.url}`),
                    blurImage: collectionImage({} as any, `blur_${auctionArt.art.url}`),
                    thumbnail: collectionImage({} as any, `${auctionArt.art.url}`, true),
                },
            ];
            if (auctionArt.art && auctionArt.art.items && auctionArt.art.items.length > 0) {
                let items = auctionArt.art.items.map((i: any) => {
                    return {
                        original: collectionImage({} as any, `${i.url}`),
                        blurImage: collectionImage({} as any, `blur_${i.url}`),
                        thumbnail: collectionImage({} as any, `${i.url}`, true),
                    }
                })
                return [...origImage, ...items]
            }
            return [...origImage]
        }
        return []
    }

    const onSelecteArt = (a: ICollectionItem) => {
        if (selectedArt && selectedArt.id === a.id) {
            setSelectedArt(undefined)
            return
        }
        setSelectedArt(a)
    }


    const onAuctionArt = (artId: string) => {
        if (!auction) return
        setAuctionLoading(true)

        auctionService.startAuctionOnArt({ auctionId: auction._id, artId: artId }).then((res: AxiosResponse<IResponse<IAuctionArtDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            }
            setAuctionLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setAuctionLoading(false)
        })
    }

    const getCurrentAuctionArtIndex = () => {
        let index = 0
        if (!auction || !auctionArt) return index

        let findIndex = auction.arts.map((a: ICollectionItem, index: number) => {
            if (a.id === auctionArt._id) {
                index = index
            }
            return a
        })

        return (index + 1)
    }

    const checkTime = () => {
        if (!auctionArt) return
        if (auctionArt.purchasingTime) {
            let currentTimeDif = moment().diff(moment(auctionArt.purchasingTime), "seconds")

            interval.current = setInterval(() => {
                if ((currentTimeDif / 60) >= time) {
                    toastService.success("Time up")
                    onRestartAuctionOnArt(auctionArt._id)
                    clearInterval(interval.current)

                } else {
                    currentTimeDif = currentTimeDif + 1
                }
            }, 1000)
        }
    }

    let auctionArtUser = auctionArt?.art.art_collection.user
    return (
        <div >
            <section className="at-auctionpage">
                <div className="container">
                    <div className="row">
                        <div className="col-12 at-panelcollection-heading">
                            <a onClick={() => onGoBack()} className="at-left-arrowicon"><i className="icon-arrow-bold"></i></a>
                            <div className="at-panelcollection-text">
                                <h2>Live Auction</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row at-row-mlr-12">
                        <div className="col-md-12 col-lg-6 col-xl-7">
                            {auctionArt &&
                                <div className="At-ArtDetailFigure at-artdetailcount">
                                    <h4 className="at-textleft">Current Auction Art</h4>
                                    <figure className="At-ImageGallery At-ImageGallery-H400">
                                        <ImageGallery
                                            items={getImages()}
                                            showFullscreenButton={true}
                                            showPlayButton={false}
                                            showNav={false}
                                            renderItem={(item: any) => <ImageLoading src={item.original} className="image-gallery-image" thumbnail={item.blurImage} />}
                                        />
                                    </figure>
                                    {getCurrentAuctionArtIndex() > 0 && <span className="at-round-count">
                                        {getCurrentAuctionArtIndex()}
                                    </span>}
                                    {/* <div className="At-ArtDetailBox">
                                        <h4 className="at-color-grey">Current Auction Art</h4>
                                        <h5 className="at-fontbold ">
                                            {auctionArt.art.name}
                                        </h5>
                                        {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>}
                                        <p>{auctionArt.art.height}" X {auctionArt.art.width}" X {auctionArt.art.depth}" Oil on Canvas</p>
                                        <p>Minimum Price: <CurrencyFormat amount={Number((Number(auctionArt.art.auctionMinPrice) + Number(PLATFORM_FEE) + Number(auctionArt.art.shipping)).toFixed(2))} /></p>
                                        {auctionArtUser && auctionArtUser.firstName && <p>Artist: {auctionArtUser.firstName} {auctionArtUser.lastName}</p>}
                                        <i className="icon-dotted-circle" onClick={() => onClickOnItem(i)}></i>
                                    </div> */}
                                </div>
                            }

                        </div>

                        {!auctionArt &&
                            <div className="col-md-12 ">
                                <div className="at-auction-emptybox ">
                                    {!auctionLoading &&
                                        <div>
                                            <i className="icon-auction2 at-emptyicon"></i>
                                            <h4 className="">No art has been selected for Art Auction</h4>
                                        </div>
                                    }
                                    {auctionLoading && <LoadingComponent className='at-transaction-loader' />}
                                </div>
                            </div>
                        }

                        {auctionArt && auction && <BiddingBoxComponent onRestartAuctionOnArt={onRestartAuctionOnArt} attendee={attendee} auctionArt={auctionArt} user={user} auction={auction} />}
                        {auction && <div className="col-md-12 col-lg-12 mt-3">
                            <BiddingArtListBoxComponent isRight={false} auction={auction} auctionArt={auctionArt} selectedArt={selectedArt} onSelecteArt={onSelecteArt} onAuctionArt={onAuctionArt} user={user} />
                        </div>}
                    </div>
                </div>
            </section>
        </div>
    )
}