import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { images } from '../../assets/image'
import LoadingComponent from '../../components/common/Loading.component'
import { ICollection, ICollectionItem } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { collectionService } from '../../services/collection.service'
import { collectionImage, templatesImages } from '../../shared'
import { templateSelector } from '../../store/selectors'
import { CustomImage } from '../components/CustomImage'
import { CustomText } from '../components/CustomText'
import { routes } from './routes'

const CollectionPage = () => {
    const history = useHistory()
    const params = (useParams() as any).id
    const template = useSelector(templateSelector)
    const [collection, setCollection] = useState<ICollection>()
    const [loading, setLoading] = useState<boolean>(false)

    const fetch = useCallback(() => {
        setLoading(true)
        collectionService.show(params).then((response: AxiosResponse<IResponse<ICollection>>) => {
            setCollection(response.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        fetch()
    }, [])

    let item = (collection?.items[0] as ICollectionItem)?.url
    return (
        <div>
            <section className="At-SectionPage At-Padding-t5 At-Section-Mh800">
                <div className="container">
                    <div className="row">
                        {collection && collection.items?.length > 0 && <div className="col-md-12">
                            <div className="At-BannerHolder">
                                <figure className="At-FigureFull At-Figure-h470">
                                    <img src={item ? templatesImages(item) : images.template.gallery01} className="img-fluid" alt="" />
                                </figure>
                                <div className="At-BannerText">
                                    {/* <h4>Sub Heading</h4> */}
                                    <h2>{collection?.title}</h2>
                                    {/* <h5>July 07, 2020 - August 27, 2020</h5> */}
                                </div>
                            </div>
                            <div className="At-PostContent At-Padding-t5 px-0">
                                {/* <h3>Collection Description</h3> */}
                                <CustomText>
                                    <p dangerouslySetInnerHTML={{ __html: collection?.description }}></p>
                                </CustomText>
                            </div>
                        </div>}

                    </div>
                </div>
            </section>
            {/* At-SectionPage ends */}
            <section className="At-SectionExhibitions At-Padding-pt7b5">
                <div className="container">
                    {collection && collection.items?.length > 0 && <div className="row">
                        <div className="col-sm-12">
                            <h4 className="At-SectionHeading">Featured Art</h4>
                        </div>
                        {collection?.items && collection.items.length > 0 && collection.items.map((i: ICollectionItem) => (

                            <div className="col-sm-4">
                                <div className="At-EventBox" >
                                    {/* <figure className="At-FigureFull At-Figure-h470">
                                        <img src={collectionImage(i, `${i.url}`, true)} className="img-fluid" alt="" />
                                    </figure> */}
                                    <div className="At-BorderFigureHolder">
                                        <div className="At-BorderFigure">
                                            <CustomImage src={collectionImage(i, `${i.url}`)} thumbnail={collectionImage(i, `blur_${i.url}`)} />

                                        </div>
                                        <div className="At-ArtDetailBox">
                                            {i.name && <h5>
                                                {i.name}
                                            </h5>}
                                            {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>}
                                            <i className="icon-dotted-circle" onClick={() => { history.push(`${routes(template.url).artists.collectionItemView}/${i.id}`) }}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                    {loading && <LoadingComponent className='at-transaction-loader' />}
                </div>
            </section>
        </div>
    )
}

export default CollectionPage