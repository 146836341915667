import React, {Component, FormEvent} from 'react';
import { BAD_REQUEST, NOT_FOUND } from 'http-status'
import {validationErrors} from "../../shared";
import { authService, toastService } from '../../services';
import {ErrorComponent} from "../common/Error.component";
import { _logger } from '../../helper';

interface ResetPasswordProps {
    onClose: any
    openSignin: any
    phoneNumber: string
    pin: string
}

interface IPageState {
	errors: any
	loading: boolean
    password: string
    confirmPassword: string
}

class ResetPasswordComponent extends Component<ResetPasswordProps> {

    state: IPageState = {
        errors: null,
        loading: false,
        password: '',
        confirmPassword: ''
    }

    onChangeText = (e: FormEvent<HTMLInputElement> | any): void => {
		const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    toggleLoading = (): void => {
        this.setState({ loading: !this.state.loading })
    }

    submit = (e: FormEvent): void => {
        e.preventDefault();
        this.toggleLoading();
        const { password, confirmPassword } = this.state;
        const { phoneNumber, pin } = this.props;
        this.setState({ errors: null })
        authService.resetPassword({ phoneNumber, pin, password, confirmPassword }).then(this.successHandler, this.errorHandler)
    }

    successHandler = (response: any) => {
        this.toggleLoading();
        toastService.info('Password reset successfully.');
        this.props.openSignin()
    }

    errorHandler = ({ response }: any) => {
        this.toggleLoading();
        _logger('', response.data.message)
        let errors = null;
        switch (response.status) {
            case BAD_REQUEST:
                errors = validationErrors(response.data);
                break;
            case NOT_FOUND:
                toastService.error('Incorrect phone number')
                break;
            default:
                break;
        }
        this.setState({ errors })
    }

    errorElement = (key: string) => (
        this.state.errors && this.state.errors[key] && <ErrorComponent errors={this.state.errors[key]} multiple={false}/>
    )

    render() {
        const { loading, errors, password, confirmPassword } = this.state;
        return (
            <div className="at-themepopupbox">
                <a className="at-close" id="at-close"  onClick={this.props.onClose}><i className="icon-cancel"></i></a>
                <form className="at-formtheme at-formcreatenewpassword" onSubmit={this.submit}>
                    <fieldset>
                        <h2>Create New Password<span>Please create a new password</span></h2>
                        <div className="form-group">
                            <input type="password" name="password" className="form-control" value={password} placeholder="New Password" onChange={this.onChangeText}/>
                            { this.errorElement('password') }
                        </div>
                        <div className="form-group">
                            <input type="password" name="confirmPassword" className="form-control" value={confirmPassword} placeholder="Confirm New Password" onChange={this.onChangeText}/>
                            { this.errorElement('confirmPassword') }
                        </div>
                        <div className="form-group">
                            <button className="at-btn at-btnrecoveycode" disabled={loading}>{ loading ? 'Please wait...' : 'Save Password'}</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }
}

export default ResetPasswordComponent;
