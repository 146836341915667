import React, { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import StreamingHeaderComponent from "../../components/common/StreamingHeader.component";
import { images } from "../../assets/image";
import { IPost, IStoreReducers, IUser } from '../../interfaces';
import { postService } from '../../services/post.service';
import { AxiosResponse, AxiosError } from 'axios';
import { APP_DOMAIN, THIRD_PARTY } from '../../environment';
import HeartComponent from './../../components/ArtCentral/feed/HeartIcon.component'
import { routes } from '../../router';
import LoadingComponent from '../../components/common/Loading.component';
import { postImage } from '../../shared';
import { toastService } from '../../services';
import { ConfirmAlertComponent } from '../../components/common/ConfirmAlert.component';
import { locale, lang } from '../../locale';
import { connect } from 'react-redux';
import ImageComponent from '../../components/ArtCentral/feed/Image.component';
import { UserPicComponent } from '../../components/User/index.component';
import CommentsComponent from '../../components/common/Comments.component';

interface IPageParams {
    postId: string
}

interface IProps extends RouteComponentProps<IPageParams> {
    user: IUser
}

interface IPageState {
    feed: IPost | any
    loading: boolean
    openAction: boolean
    openDeleting: boolean
    deleting: boolean
}
class PostDetailPage extends Component<IProps> {
    state: IPageState = {
        feed: {},
        loading: false,
        openAction: false,
        openDeleting: false,
        deleting: false
    }

    componentDidMount() {
        this.fetch();
    }

    toggleLoading = (): void => {
        this.setState({ loading: !this.state.loading })
    }

    get postId() {
        return this.props.match.params.postId;
    }

    fetch = (): void => {
        this.toggleLoading();
        postService.show(this.postId).then((response: AxiosResponse<IPost>) => {
            this.setState({ feed: response.data })
            this.toggleLoading();
        }, (error: AxiosError) => {
            this.toggleLoading();
        })
    }


    openAction = () => {
        this.setState({ openAction: true })
    }

    openDeleteBox = () => {
        this.setState({ openAction: false, openDeleting: true })
    }

    close = () => {
        this.setState({ openAction: false, openDeleting: false })
    }

    deleteItem = () => {
        const { feed } = this.state;
        if (!feed) return;

        postService.deletePost(feed._id).then((response: any) => {
            toastService.success(response.data.message)
            this.props.history.goBack()
        }, error => {
            // toastService.error(lang.common.httpError)
        })
    }

    render() {
        const { loading, feed, openDeleting, openAction, deleting } = this.state;
        const { _id: userId } = this.props.user
        const [first] = feed.assets || []

        return (
            <>
                {
                    loading && (
                        <main id="at-main" className="at-main at-haslayout">
                            <div className="at-collectiondetail at-contentarea-two scrollable">
                                <LoadingComponent />
                            </div>
                        </main>
                    )
                }
                {/*Main Data*/}
                {
                    (feed && feed.user) && (
                        <main id="at-main" className="at-main at-haslayout">
                            <div className="at-collectiondetail at-contentarea-two scrollable" onMouseDown={this.close}>
                                <div className="at-collectiondetailholder ">
                                    <div className="at-orangeeconhead">
                                        <Link to={{ pathname: routes.artCentral.newsFeeds, state: feed._id }} className="at-left-arrowicon"><i className="icon-left-arrow"></i></Link>
                                        {feed.user && <UserPicComponent user={feed.user} />}
                                        <div className="at-orangeeconcontent">
                                            <span className="at-postcontenticon"><i className="icon-hand"></i></span>
                                            <h3>{feed.user && feed.user.firstName}</h3>
                                            <div className="at-btniconholder">
                                                <a className="at-btnomniicon at-postbtn">
                                                    <i className="icon-facebook-1"></i>
                                                </a>
                                                <a  id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a>
                                                <HeartComponent postId={feed._id} userId={feed.user._id} />
                                                {
                                                    (feed.user && feed.user._id === userId) && <a onClick={this.openAction} className="at-btnmore at-postbtn cursor-pointer"><i className="icon-more"></i></a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="at-collectiondetailbox">
                                        <figure className="at-postimg">
                                            {
                                                // !loading && (first ? <img src={`${APP_DOMAIN}/PostImages/${feed._id}/${first.url}`} alt=""/> : <img src={images.no_image} alt=""/>)
                                                (feed && feed.user) && <ImageComponent feed={feed} {...this.props} />
                                            }
                                        </figure>
                                        <div className="at-collectiondetailcontent">
                                            {/* <h3>Multicolored Abstract Painting</h3> */}
                                            <div className="at-description">
                                                <p>{feed.description}</p>
                                            </div>
                                            {/* <span className="at-pricedetail">Price: <em>$90</em></span> */}
                                            {userId && <div className="commentsbox">
                                                <CommentsComponent id={this.postId} />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {openAction && <div id="at-themeaddactionpopup" className={`at-themepopupholder ${openAction && 'at-showthemesignuppopup'}`}>
                                <div className="at-themepopupbox">
                                    <a className="at-close cursor-pointer" onClick={this.close} id="at-close"><i className="icon-cancel"></i></a>
                                    <div className="at-addactionholder">
                                        <div className="at-formthemehead">
                                            <h2>Action</h2>
                                        </div>
                                        <ul>
                                            <li>
                                                <Link to={{ pathname: `${routes.artCentral.post.edit}/${feed._id}` }}>Edit</Link>
                                            </li>
                                            <li className="at-active">
                                                <a className="cursor-pointer" onClick={this.openDeleteBox}>Delete</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}

                            {openDeleting && <ConfirmAlertComponent open={openDeleting} onCancel={this.close} onConfirm={this.deleteItem} confirmTitle={deleting ? 'Deleing...' : 'Delete'} />}
                        </main>
                    )
                }
            </>
        );
    }
}

const mapState = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapState, {})(PostDetailPage);