import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { images } from '../../../../assets/image'
import { ICommissionResponse, IMilestone } from '../../../../interfaces'
import { routes } from '../../../../router'
import { commissionWorkService, toastService } from '../../../../services'
import { collectionImage } from '../../../../shared'
import { CurrencyFormat } from '../../../common/CurrencyFormat'
import { MessageModal } from '../../../common/Modals/MessagePopup'
import { ProposalImageView } from '../../../common/ProposalImageView'

interface IProps {
    item: ICommissionResponse,
    history: any
}
export const ProposalLayout = ({ item, history }: IProps) => {
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')

    const onUpdate = () => {
        history.push({ pathname: `${routes.artCentral.commissionWork.proposalCommissionwork}/${item.id}`, state: { update: true } })
    }

    const openMessageBox = () => {
        setShowMessageBox(true)
    }

    const onCloseMessageBox = () => {
        setShowMessageBox(false)
    }

    const onSubmitMessage = (data: any) => {
        setMessage(data)
        commissionWorkService.sendProposalMessage(({ message: data, proposalID: item.proposalID.id })).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success('Message sent successfully.')
            }
            setShowMessageBox(false)
        }).catch((err: any) => {
            console.log(err)
            setShowMessageBox(false)
        })
        // commissionWorkService.rejectProposal({ proposalID: item.proposalID.id, message: data }).then((res: AxiosResponse<any>) => {

        //     if (res.data.status === 'Success') {
        //         toastService.success('Proposal has been rejected')
        //     }
        //     setShowMessageBox(false)
        // }).catch((err: any) => console.log(err))
    }

    const getButtonStatus = (item: ICommissionResponse) => {
        switch (item.proposalID.status) {
            case 'pending':
                return { class: '',disabled : false, text: 'Update Proposal' }
            case 'approved':
                return { class: 'at-btn-approved', disabled : true, text: 'Approved' }
            case 'paid':
                return { class: 'at-btn-approved', disabled : true, text: 'Approved' }
            default:
                return { class: '', disabled : false, text: 'Update Proposal' }
        }
    }

    const getSecondSecondButtonStatus = (item: ICommissionResponse) => {
        switch (item.proposalID.status) {
            case 'pending':
                return { class: '', text: 'Send Message', disabled : false, onClick: openMessageBox }
            case 'approved':
                return { class: '', text: 'Request Payment', disabled : false, onClick: onUpdate }
            case 'paid':
                return { class: 'at-btn-approved', text: 'Paid', disabled : true, onClick:() => {} }
            default:
                return { class: '', text: 'Send Message', disabled : false, onClick: openMessageBox }
        }
    }
    return (
        <div key={item._id} className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-rejectaccept-proposal">
                    <h5><span>Project Pictures</span></h5>
                    <figure className='at-figure-rejectaccept'>
                    <ProposalImageView item={item.proposalID} />
                        {/* <img src={collectionImage(item.itemId, `high_${item.itemId.url}`, true)} className="img-fluid" alt="" /> */}
                    </figure>
                    {item.itemId && <h5 className="mb-0"><span className="at-color-grey">Art Name: </span>{item.itemId.name}</h5>}
                    <h5 className="mb-0"><span className="at-color-grey">Description: </span>{item.proposalID.description}</h5>
                    <ul className="d-flex mt-2">
                        <li className="mr-3"><h5><span className="at-color-grey">Price: </span><CurrencyFormat amount={item.proposalID.budget} /></h5></li>
                        <li><h5><span className="at-color-grey">Shipping: </span><CurrencyFormat amount={item.proposalID.shipping} /></h5> </li>
                    </ul>
                    <div className="at-transfer-amount at-transfer-amount-threecol at-haslayout mt-1">
                        <div className="at-transfer-upper">
                            <h5 className="at-text-underline">Payment Schedule</h5>
                            {item.proposalID.milestone && item.proposalID.milestone.length > 0 && item.proposalID.milestone.map((m: IMilestone, index:any) => {
                                return (
                                    <div className="row align-items-center" key={index}>
                                        <div className="col-5 at-custcol-4">
                                            <h4>
                                                {m.name}
                                            </h4>
                                        </div>
                                        <div className="col-4 at-custcol-4">
                                            <h5 className="text-center"><CurrencyFormat amount={m.payment} /></h5>
                                        </div>
                                        {m.status === 'paid' && <div className="col-3 at-custcol-4">
                                            <p className="at-color-orange">PAID</p>
                                        </div>}
                                    </div>
                                )
                            })}
                            
                            {/* item row */}
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="row at-rejectaccept-btns row-margin-lr7">
                        <div className="col-6 pad-lr-7">

                        <button className={`at-btn at-btn-sm at-btn-grey at-minwidth-auto ${getSecondSecondButtonStatus(item).class}`} disabled={getSecondSecondButtonStatus(item).disabled} onClick={() => getSecondSecondButtonStatus(item).onClick()}>{getSecondSecondButtonStatus(item).text}</button>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <button className={`at-btn at-btn-sm at-minwidth-auto ${getButtonStatus(item).class}`} disabled={getButtonStatus(item).disabled} onClick={() => onUpdate()}>{getButtonStatus(item).text}</button>
                        </div>
                    </div>
                </div>
            </div>
            {showMessageBox && <MessageModal message={message} open={showMessageBox} onClose={onCloseMessageBox} onSubmit={onSubmitMessage} />}
        </div>
    )
}
