import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { _logger } from '../../helper';
import { IStoreReducers, ITransaction, IUser } from '../../interfaces';
import { transactionService } from '../../services';
import { AxiosResponse } from 'axios';
import { MessageModal } from '../../components/common/Modals/MessagePopup'
import { UniversalModal } from '../../components/common/Modals/Universal.modal';
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial';
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon';
import { EScreens } from '../../enums/screens.enum';


interface IProps extends RouteComponentProps {
    user?: IUser
    routeUser?: IUser
    hideBackButton?: boolean
}
interface IState {
    buyerTransaction: Array<ITransaction>,
    sellerTransaction: Array<ITransaction>,
    loading: boolean
    showMessageBox: boolean
    showMessageModal: boolean
    showTutorialBox: boolean
}
class MetricsPage extends Component<IProps> {
    state: IState = {
        buyerTransaction: [],
        sellerTransaction: [],
        loading: false,
        showMessageBox: false,
        showMessageModal: false,
        showTutorialBox: false
    }

    componentDidMount() {
        this.fetchUserTransaction()
    }

    fetchUserTransaction = () => {
        let { routeUser } = this.props
        let user = routeUser ? routeUser : (this.props.user as IUser)
        transactionService.getUserTransactions(user?._id).then((res: AxiosResponse<any>) => {
            let data = [...res.data.response].reverse()
            let sellerData = user ? data.filter((item: ITransaction) => item.sellerID._id === user?._id) : []
            let buyerData = user ? data.filter((item: ITransaction) => item.buyerID._id === user?._id) : []
            this.setState({ sellerTransaction: sellerData, buyerTransaction: buyerData, loading: false })
        }).catch(err => {
            console.log(err)
            this.setState({ loading: false })
        })
    }

    getNumberOfDisputedTransaction = (transactions: Array<ITransaction>) => {
        let count = transactions.filter((item: ITransaction) => item.disputed.status)
        return count.length || 0
    }

    getNumberOfUnresolveDisputedTransaction = (transactions: Array<ITransaction>) => {
        let count = transactions.filter((item: ITransaction) => item.disputed.status && !item.disputed.resolve)
        return count.length || 0
    }

    openTutorialBox = () => {
        this.setState({
            showTutorialBox: true
        })
    }

    closeTutorialBox = () => {
        this.setState({
            showTutorialBox: false
        })
    }

    render() {
        const { sellerTransaction, buyerTransaction, showMessageBox, showMessageModal } = this.state
        return (
            <main id="at-main" className="at-main at-haslayout">
                <div className="at-createaccount scroll-style-3 at-contentarea-two">
                    <div className="at-themehead">
                        {!this.props.hideBackButton && <a onClick={() => this.props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                        <h2>Metrics <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.METRICS} onClick={this.openTutorialBox} /></span></h2>
                    </div>
                    <div className="at_theme_innercontent at_tabs_styling">
                        <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                            <Tab label="As a Seller">
                                <ul className="metrics_ul">
                                    <li>
                                        <div className="at_metrics_box">
                                            <h6>Number of transactions completed</h6>
                                            <h3>{sellerTransaction.length}</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="at_metrics_box">
                                            <h6>Number of disputed transactions</h6>
                                            <h3>{this.getNumberOfDisputedTransaction(sellerTransaction)}</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="at_metrics_box">
                                            <h6>Number of unresolved disputed transactions</h6>
                                            <h3>{this.getNumberOfUnresolveDisputedTransaction(sellerTransaction)}</h3>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div className="at_metrics_box">
                                            <h6>Numbers of days to receive an order</h6>
                                            <h3>10</h3>
                                        </div>
                                    </li> */}
                                </ul>
                                {/* metrics_ul ends */}
                                {/* <div className="at-star-boxouter">
                                    <ul className="at-star-topbtns d-flex justify-content-end">
                                        <li><i className="icon-add-user at-color-grey mr-2"></i></li>
                                        <li><i className="icon-send at-color-nightrider" onClick={() => this.setState({ showMessageModal: true })} ></i></li>
                                    </ul>
                                    <div className="at-star-box">
                                        <div className="at-transact-line">
                                            <p>10/27/2020</p>
                                            <p className="pl-5">04:11:43 pm</p>
                                        </div>
                                        <h6>Star Rating: <i className="icon-star-grey fs-20 mr-1 at-color-yellow"></i>
                                            <i className="icon-info fs-20 at-color-nightrider" onClick={() => this.setState({ showMessageBox: !this.state.showMessageBox })}></i></h6>
                                        <div className="at-level-box">
                                            <h5 className="at-text-underline">OMNI LEVEL</h5>
                                            <p>Number of Purchases: <span>10</span></p>
                                            <p>Number of Offers: <span>15</span></p>
                                            <p>Number of Disputes Filed: <span>2</span></p>
                                        </div>
                                        <div className="at-level-box">
                                            <h5 className="at-text-underline">SPACE LEVEL</h5>
                                            <p>Number of Space Visits (1 week): <span>2</span></p>
                                            <p>Number of Space Visits (1 month): <span>7</span></p>
                                            <p>Number of Space Visits (6 months): <span>50</span></p>
                                        </div>
                                    </div>
                                    {showMessageBox && <div className="at-stardefinition-box" style={{ display: (showMessageBox ? 'block' : 'none') }}>
                                        <h6>Star Rating Definition</h6>
                                        <li className="d-flex"><i className="icon-star-grey at-color-greenlight"></i><p>User has purchased 10 or more works of ART.</p></li>
                                        <li className="d-flex"><i className="icon-star-grey at-color-blue2"></i><p>User has purchased 5 or more works of ART.</p></li>
                                        <li className="d-flex"><i className="icon-star-grey at-color-yellow"></i><p>User has purchased 3 or more works opf ART.</p></li>
                                        <li className="d-flex"><i className="icon-star-grey at-color-lightgrey2"></i><p>User has purchased 1 or more works of ART.</p></li>
                                    </div>}
                                </div> */}
                                {/* at-star-boxouter ends */}

                                {/* <div className="at-star-boxouter">
                                    <ul className="at-star-topbtns d-flex justify-content-end">
                                        <li><i className="icon-menu at-color-grey"></i></li>
                                    </ul>
                                    <div className="at-star-box">
                                        <div className="at-level-box px-3">
                                            <p>Christian, sorry I missed you when you stopped by the studio, I am back at the studio and I’ll be here for a couple of hours. Please visit again.</p>
                                        </div>
                                    </div>
                                </div> */}
                                {/* at-star-boxouter ends */}
                            </Tab>
                            <Tab label="As a Buyer">
                                <ul className="metrics_ul">
                                    <li>
                                        <div className="at_metrics_box">
                                            <h6>Number of transactions completed</h6>
                                            <h3>{buyerTransaction.length}</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="at_metrics_box">
                                            <h6>Number of disputed transactions</h6>
                                            <h3>{this.getNumberOfDisputedTransaction(buyerTransaction)}</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="at_metrics_box">
                                            <h6>Number of unresolved disputed transactions</h6>
                                            <h3>{this.getNumberOfUnresolveDisputedTransaction(buyerTransaction)}</h3>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div className="at_metrics_box">
                                            <h6>Numbers of days to receive an order</h6>
                                            <h3>10</h3>
                                        </div>
                                    </li> */}
                                </ul>
                            </Tab>
                        </Tabs>
                    </div>
                    {/* at_theme_innercontent ends  */}
                    {showMessageModal && <MessageModal message='' open={showMessageModal} onClose={() => this.setState({ showMessageModal: false })} onSubmit={() => this.setState({ showMessageModal: false })} />}

                    {this.state.showTutorialBox && <UniversalModal open={this.state.showTutorialBox} onClose={this.closeTutorialBox} position='bottom-0'>
                        <ScreenTutorial screen={EScreens.METRICS} />
                        {/* at-categorybox ends */}
                    </UniversalModal>
                    }
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(MetricsPage)
