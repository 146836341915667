import { HttpService } from "./base.service";

class QuestionService extends HttpService {
    private readonly prefix: string = 'question';

    /**
     * Questions List
     * @param data
     */
    list = (): Promise<any> => this.get(this.prefix);
}

export const questionService = new QuestionService();
