import React, { useState } from "react";
interface IProps {
    click?: any
    onLongPress?: any
    children: any
    [key: string]: any
}
export const LongPressAableComponent = ({ click, onLongPress, children, ...other }: IProps) => {
    const [longPress, setlongPress] = useState(false)

    let buttonPressTimer: any = null;
    const handleButtonPress = (e: any) => {
        e.preventDefault()
        buttonPressTimer = setTimeout(() => {
            setlongPress(true)
            if (onLongPress) onLongPress(other)
        }, 300);
    }

    const handleButtonRelease = (e: any) => {
        e.preventDefault()
        if (!longPress) {
            click && click(other)
        }
        setlongPress(false);
        clearTimeout(buttonPressTimer);
    }

    return (
        <div
            onTouchStart={handleButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}>
            {
                children
            }
        </div>
    );
}