import React from "react";
import { useHistory } from "react-router-dom";

interface IProps {}
export const SystemParameters = (props: IProps) => {
  const history = useHistory();
  return (
    <div
      className="at-createaccount at-contentarea-two scrollable scrollbar-hidden"
    
    >
      <div className="at-themehead">
        <a onClick={() => history.goBack()} className="at-left-arrowicon">
          <i className="icon-left-arrow"></i>
        </a>
        <h2>System Parameters</h2>
      </div>
      <div className="at_theme_innercontent system-parameter-parent">
          <div className="row"> 
              <div className="col-7">
                  <p>Platform-Fee:</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control text-end'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Calculation Margin</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Shipping Open (hours):</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Return Open (hours):</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Expired Offer Time Threshold (days)</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Education Lavel (number)</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Interest Lavel (number)</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Disregard Lavel (number)</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Stale Request (hours)</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>
          <div className="row">
              <div className="col-7">
                  <p>Stale Proposal (hours)</p>
              </div>
              <div className="col-5">
                  <input type="text" className='form-control'/>
              </div>
          </div>

          <div className="row mt-3 justify-content-end">
              <div className="col-7"></div>
              <div className="col-5">
              <button className='at-btn at-longbtn-h40imp at-extrasmallbtn'>
                  UPDATE
              </button>
              </div>
          </div>
      </div>
    </div>
  );
};
