import React, { Component } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { images } from '../../assets/image'
import { routes } from '../../router'
import { RtcStreamActions, toastService, RtcStreamConnectionActions, AgoraLeaveActions, spaceService, routerService, NotificationCountAction, UserAlertCountAction, UserLoginAction } from '../../services'
import { ERtcEvent, EConnectionState, IStoreActionsStates, IUser, IContactState, IContact, ISpace, IPlatformWebReducer } from '../../interfaces'
import { Subscription } from 'rxjs'
import { connect } from 'react-redux'
import $ from 'jquery'
import { socketNode } from '../../services/socket.service'
import { TURN_ON_SPEECH_TO_TEXT, TURN_OFF_SPEECH_TO_TEXT, EHubsAndSpaces } from '../../constants'
import AgoraNextGeneration from './AgoraNextGeneration.component'
import { getHubColorPath, _logger } from '../../helper'
import { activateSpaceAction, fetchContacts, refreshUser, addUserToSpace, setStudioUser, logoutAction, saveUser, switchProfile, } from '../../store/actions'
import { ZoomActions, IZoom, EZoom } from '../../services/zoom.service'
import { InviteUserComponent } from './InviteUser.component'
import ContactComponent from '../MyCentral/Contact/Contact.component'
import { LongPressAableComponent } from './LongPress.component'
import { ConfirmAlertComponent } from './ConfirmAlert.component'
import { accountService } from '../../services/account.service'
import { AxiosResponse, AxiosError } from 'axios'
import { ESpaceType } from '../../enums'
import Draggable from 'react-draggable'
import { OmniHeaderDropdown } from './OmniHeaderDropdown'
import { HelpBox } from './HelpBox'
import { IResponse } from '../../interfaces/db.interface'

interface IProps extends RouteComponentProps {
	user: IUser
	isSpaceActivated: boolean
	activateSpaceAction: Function
	fetchContacts: Function
	contacts: IContactState
	refreshUser: Function
	addUserToSpace: Function
	[key: string]: any
	setStudioUser: Function,
	token: any
	logoutAction: Function,
	saveUser: Function,
	authUser: IUser,
	hideHeader?: boolean,
	inPlatform: IPlatformWebReducer,
	rigthPanelActive: boolean
}

const initialState = {
	mute: true,
	connected: false,
	activeVideo: false,
	activeAudio: false,
	activeSpeechToText: false,
	expandStreamingList: false,
	enlargeBands: false,
	showPlusIcon: false,
	showControls: false,
	isSpaceActivated: false,
	joining: false,
	isFullScreenViewEnable: false,
	openInviteModal: false,
	searchTerm: '',
	selectedContacts: [],
	deactivationPopUp: false,
	splitScreen: 1,
	openSpaceModal: false,
	isInRoutes: true,
	showView: false,
	showRow: false,
	showStack: false,
	showGrid: false,
	showStandard: true,
	showTutorial: false,
	showPermissionPopup: false
}

class StreamingHeaderComponent extends Component<IProps> {
	subscription = new Subscription()
	fullScreenSub = new Subscription()
	userAlertSubscription = new Subscription()
	notificationSubscription = new Subscription()
	state = { ...initialState, userAlertCount: 0, count: 0, }

	unlisten: any;

	componentDidMount() {
		const { token } = this.props
		if (!token) return
		this.props.fetchContacts()
		if (this.props.isSpaceActivated) this.activateSpace();
	}

	componentWillUnmount() {
		this.subscription.unsubscribe()
		this.fullScreenSub.unsubscribe()
		this.notificationSubscription.unsubscribe()
		this.userAlertSubscription.unsubscribe()
		this.unlisten();
	}

	listenRoutes = (): void => {
		this.unlisten = this.props.history.listen((location: any, actions: any) => this.checkIfInArtCentralSpace(location.pathname));
	}

	checkIfInArtCentralSpace = (route: string) => {
		if (this.activeSpace && this.activeSpace.scope === ESpaceType.GLOBAL) {
			if (route === "/" || !this.props.token || this.props.inPlatform.isActive) return
			// if (this.activeSpace && this.activeSpace.scope === ESpaceType.GLOBAL && this.state.connected && this.props.isSpaceActivated) {
			const isInArtCentral = routerService.isArtCentralSpace(route)
			if (!isInArtCentral) this.confirmDeactivation();
		}
	}

	componentWillMount() {
		this.listenRoutes();
		const sub = RtcStreamConnectionActions.listen.subscribe((status: boolean) => {
			this.setState({ connected: status, joining: false })
			if (status) {
				console.log("run specch")
				this.toggleSpeechToText()
			}
		})
		const fullScreen = ZoomActions.listen.subscribe((zoom: IZoom) => {
			const isFullScreenViewEnable = zoom.type === EZoom.ART_COLLECTION;
			this.setState({ isFullScreenViewEnable, enlargeBands: false })
		})

		const count = NotificationCountAction.listen.subscribe((status: number) => this.setState({ count: status }))
		const userCount = UserAlertCountAction.listen.subscribe((status: number) => this.setState({ userAlertCount: status }))
		this.notificationSubscription.add(count)
		this.userAlertSubscription.add(userCount)
		this.fullScreenSub.add(fullScreen)
	}

	componentWillReceiveProps(props: IProps) {
		if (props.location.pathname !== this.props.location.pathname || this.props.user !== props.user) {
			this.checkIfUserInRoutes(props.location.pathname, props.user)
		}
	}

	enlargeBands = () => {
		const { enlargeBands } = this.state;
		let isExpanded = !enlargeBands;
		if (isExpanded) {
			$('#at-wrapperinner').addClass('show-in-full-screen')
			$('#at-wrapperinner').removeClass('show-stack-row')
			$('#at-wrapperinner').removeClass('show-stack-grid')
			$('#at-wrapperinner').removeClass('show-stack-column')
			// $('#at-wrapperinner').removeClass('show-in-full-screen')
		}
		else {
			$('#at-wrapperinner').removeClass('show-in-full-screen')
		}

		this.setState({
			enlargeBands: isExpanded,
			expandStreamingList: false,
			showRow: false,
			showStack: false,
			showGrid: false,
			showStandard: false
		})
	}

	expandList = () => {
		const { expandStreamingList, activeSpeechToText } = this.state;
		// if (activeSpeechToText) return;
		let isExpanded = !expandStreamingList;
		if (isExpanded) {
			$('#at-wrapperinner').addClass('expanded-video-list')
			$('#at-wrapperinner').removeClass('show-stack-row')
			$('#at-wrapperinner').removeClass('show-stack-grid')
			$('#at-wrapperinner').removeClass('show-stack-column')
			$('#at-wrapperinner').removeClass('show-in-full-screen')
		}
		else $('#at-wrapperinner').removeClass('expanded-video-list')

		this.setState({
			expandStreamingList: isExpanded,
			showRow: false,
			showStack: false,
			showGrid: false,
			showStandard: false,
			enlargeBands: false,
		})
	}

	toggleSpeechToText = () => {
		const { activeSpeechToText, expandStreamingList } = this.state;
		let isExpanded = !activeSpeechToText;
		if (isExpanded) {
			$('#at-wrapperinner').addClass('speech-to-text-enabled')
			socketNode.emit(TURN_ON_SPEECH_TO_TEXT, this.props.user)
		} else {
			socketNode.emit(TURN_OFF_SPEECH_TO_TEXT, this.props.user)
			$('#at-wrapperinner').removeClass('speech-to-text-enabled')
		}
		// if (expandStreamingList) $('#at-wrapperinner').removeClass('expanded-video-list')
		this.setState({ activeSpeechToText: isExpanded })
	}

	toggleAudio = () => {
		const { activeAudio, activeVideo } = this.state;
		const info = { activeAudio: !activeAudio, activeVideo }
		RtcStreamActions.triggerAction(info)
		this.setState({ activeAudio: !activeAudio })
	}

	toggleVideo = () => {
		const { activeVideo, activeAudio } = this.state;
		const info = { activeVideo: !activeVideo, activeAudio }
		RtcStreamActions.triggerAction(info)
		this.setState({ activeVideo: !activeVideo })
	}

	getTotalNumberOfStream = (length: number) => {

		if (length < 4) {
			// $('#at-wrapperinner').removeClass('expanded-video-list')
			this.setState({ showPlusIcon: false })
		}
		else this.setState({ showPlusIcon: true })
	}

	setControlsStatus = (props: any = {}) => {
		this.setState({ ...props })
	}

	get activeSpace(): ISpace {
		return this.props.user.activeSpace as ISpace;
	}

	activateSpace = async () => {
		this.setState({ joining: true })
		try {
			const { data: space }: AxiosResponse<IResponse<ISpace>> = await accountService.currentActiveSpace();
			if (space.data.isLocked && (space.data.userId as any) !== this.props.user._id) {
				this.setState({ joining: false })
				let spaceName = spaceService.getSpaceName(this.activeSpace);
				return toastService.info(`There is currently a private meeting in ${spaceName}`)
			}
		} catch (error) {
			this.setState({ joining: false })
			return;
		}

		const { user } = this.props;
		if (user && !user.activeSpace) toastService.info('No space has been selected, please select one.')
		// if(user.isDeaf){
		// 	this.toggleSpeechToText()	
		// }
		this.props.activateSpaceAction(true)
	}

	spaceNotActive = () => {
		const { user, isSpaceActivated } = this.props;
		if (!isSpaceActivated) {
			return <button className="btn btn-secondary btn-block mt-3" onClick={this.activateSpace}>Activate your space.</button>
		} else if (user && !user.activeSpace) {
			return <Link to={routes.myCentral.spaces} className="btn btn-info btn-block mt-3">No space is selected.</Link>
		}
	}

	leaveSpace = () => {
		AgoraLeaveActions.triggerAction(true)
	}

	spaceLeft = () => {
		this.props.activateSpaceAction(false)
		this.setState({ ...initialState })

		$('#at-wrapperinner').removeClass('expanded-video-list')
		$('#at-wrapperinner').removeClass('speech-to-text-enabled')
		$('#at-wrapperinner').removeClass('show-in-full-screen')
	}

	get isSpaceAdmin() {
		const user: IUser = this.props.user;
		return (user && user._id) === (user.activeSpace && user.activeSpace.userId && user.activeSpace.userId._id);
	}

	deactiveSpace = () => {
		this.setState({ deactivationPopUp: true })

	}
	closePop = () => {
		this.setState({ deactivationPopUp: false })
	}
	confirmDeactivation = () => {
		let { hideHeader } = this.props
		this.leaveSpace();
		accountService.deactivateSpace().then((response: AxiosResponse<IResponse<any>>) => {
			if (this.activeSpace && this.activeSpace._id) {
				let spaceName = spaceService.getSpaceName(this.activeSpace);
				hideHeader ? toastService.info(`Thank you for participating in the conversation at ${spaceName}`) : toastService.info(`You have exited ${spaceName}`)
			}
			// this.props.setStudioUser({})
			this.props.refreshUser();
			if (this.state.isInRoutes) this.setState({ isInRoutes: false })
			this.props.activateSpaceAction(false)
			this.closePop();
		}, (error: any) => {
			// toastService.error('Some error occurred.')
			this.closePop();
		})
	}

	inviteNewUser = () => {

		if (this.activeSpace.isLocked) return toastService.info('To add more contacts you have to unlock your space first.')
		this.setState({ openInviteModal: true })
	}

	lockUnlockSpace = async (): Promise<void> => {
		const { data }: AxiosResponse<{ space: ISpace }> = await spaceService.lockUnlockCurrentSpace()
		let message = '';
		let term = data.space.isLocked ? 'locked' : 'unlocked';
		let spaceName = spaceService.getSpaceName(this.activeSpace);
		message = `${spaceName} ${term} successfully`
		toastService.success(message)
		this.props.refreshUser();
	}

	// split screen handlers

	onIncreasingScreen = () => {
		const { splitScreen } = this.state
		console.log('test', splitScreen)
		if (splitScreen === 4) return;
		$('#at-wrapperinner').removeClass(`split-up-videos-mode${splitScreen}`)
		let newValue = splitScreen + 1
		$('#at-wrapperinner').addClass(`split-up-videos-mode${newValue}`)
		this.setState({ splitScreen: newValue })
	}

	onDecreasingScreen = () => {
		const { splitScreen } = this.state
		$('#at-wrapperinner').removeClass(`split-up-videos-mode${splitScreen}`)
		if (splitScreen === 1) return;
		let newValue = splitScreen - 1
		$('#at-wrapperinner').addClass(`split-up-videos-mode${newValue}`)
		this.setState({ splitScreen: newValue })
	}

	logout = async () => {

		let { hideHeader, user } = this.props
		if (user.activeSpace && user.activeSpace._id) {
			this.leaveSpace();
			accountService.deactivateSpace().then((response: AxiosResponse<IResponse<any>>) => {
				if (this.activeSpace && this.activeSpace._id) {
					let spaceName = spaceService.getSpaceName(this.activeSpace);
					hideHeader ? toastService.info(`Thank you for participating in the conversation at ${spaceName}`) : toastService.info(`You have exited ${spaceName}`)
				}
				this.props.setStudioUser({})
				this.props.refreshUser();
				// if (this.state.isInRoutes) this.setState({ isInRoutes: false })
				this.props.logoutAction()
				this.props.saveUser({})
				this.props.history.push('/')
				this.props.activateSpaceAction(false)
				this.closePop();
			}, (error: any) => {
				// toastService.error('Some error occurred.')
				this.closePop();
			})
			return
		}

		this.props.logoutAction()
		this.props.saveUser({})
		this.props.history.push('/')


	}

	openSpaceModal = () => {
		let { token } = this.props
		if (!token) {
			// this.openSignin()
			UserLoginAction.triggerAction(true)
			return
		}
		this.setState({ openSpaceModal: true })
	}

	onSwitchProfile = async () => {
		const res: any = await this.props.switchProfile({ userId: this.props.authUser._id, isBackToAgent: true })
		if (res.success) {
			toastService.success(res.message)
			this.props.history.push(routes.landingPage)
		} else {
			toastService.error(res.message)
		}
	}

	checkIfUserInRoutes = (r: string, u: IUser) => {
		// console.log("run:-=-=-=", r)
		if (this.props.inPlatform.isActive) return
		if (r === routes.home || this.state.isFullScreenViewEnable) {
			// if (!u.activeSpace){

			// 	if (this.state.isInRoutes) this.setState({ isInRoutes: false })
			// } else{
			// 	if (!this.state.isInRoutes)	this.setState({ isInRoutes: true })
			// }
			if (!this.state.isInRoutes) this.setState({ isInRoutes: true })
			return
		}
		const isInArtCentral = routerService.isArtCentralSpace(r)
		if (this.state.isInRoutes !== isInArtCentral) this.setState({ isInRoutes: isInArtCentral })
	}

	toggleView = () => {
		this.setState({
			showView: !this.state.showView
		})
	}

	toggleRow = () => {
		const { showRow } = this.state;
		let isRowView = !showRow

		if (isRowView) {
			$('#at-wrapperinner').addClass('show-stack-row')
			$('#at-wrapperinner').removeClass('expanded-video-list')
			$('#at-wrapperinner').removeClass('show-stack-grid')
			$('#at-wrapperinner').removeClass('show-stack-column')
			$('#at-wrapperinner').removeClass('show-in-full-screen')
		} else {
			$('#at-wrapperinner').removeClass('show-stack-row')
		}
		this.setState({
			showRow: isRowView,
			showGrid: false,
			showStack: false,
			expandStreamingList: false,
			showStandard: false,
			enlargeBands: false,
		})
	}

	toggleStack = () => {

		const { showStack } = this.state;
		let isStackView = !showStack
		if (isStackView) {
			$('#at-wrapperinner').addClass('show-stack-column')
			$('#at-wrapperinner').removeClass('expanded-video-list')
			$('#at-wrapperinner').removeClass('show-stack-grid')
			$('#at-wrapperinner').removeClass('show-stack-row')
			$('#at-wrapperinner').removeClass('show-in-full-screen')
		}
		else {
			$('#at-wrapperinner').removeClass('show-stack-column')
		}
		this.setState({
			showStack: isStackView,
			expandStreamingList: false,
			showRow: false,
			showGrid: false,
			showStandard: false,
			enlargeBands: false,
		})
	}

	toggleGrid = () => {

		const { showGrid } = this.state;
		let isGridView = !showGrid

		if (isGridView) {
			$('#at-wrapperinner').addClass('show-stack-grid')
			$('#at-wrapperinner').removeClass('expanded-video-list')
			$('#at-wrapperinner').removeClass('show-stack-column')
			$('#at-wrapperinner').removeClass('show-stack-row')
			$('#at-wrapperinner').removeClass('show-in-full-screen')
		} else {
			$('#at-wrapperinner').removeClass('show-stack-grid')
		}
		this.setState({
			showGrid: isGridView,
			showStack: false,
			showRow: false,
			expandStreamingList: false,
			showStandard: false,
			enlargeBands: false,
		})
	}

	onStandardView = () => {
		$('#at-wrapperinner').removeClass('show-stack-grid')
		$('#at-wrapperinner').removeClass('expanded-video-list')
		$('#at-wrapperinner').removeClass('show-stack-column')
		$('#at-wrapperinner').removeClass('show-stack-row')
		$('#at-wrapperinner').removeClass('show-in-full-screen')

		this.setState({
			showGrid: false,
			showStack: false,
			showRow: false,
			expandStreamingList: false,
			showStandard: true,
			enlargeBands: false,
		})
	}

	onClickingOnExist = () => {
		this.confirmDeactivation()
	}

	onOpenTutorial = () => {
		this.setState({ showTutorial: true })
	}

	onCloseTutorial = () => {
		this.setState({ showTutorial: false })
	}
	onOpenShowPermissionPopup = () => {
		this.setState({ showPermissionPopup: true })
	}

	onCloseShowPermissionPopup = () => {
		this.setState({ showPermissionPopup: false })
	}

	render() {

		const { match: { path }, location: { pathname }, authUser, user, contacts: { data: contacts }, isSpaceActivated, token, inPlatform, rigthPanelActive } = this.props

		const { joining, connected, activeVideo, activeAudio, activeSpeechToText, expandStreamingList, enlargeBands, openInviteModal, deactivationPopUp, count, userAlertCount, isInRoutes, showTutorial, showPermissionPopup } = this.state
		const isStreamingPage = pathname.includes('/account') || pathname.includes('/home') || pathname.includes('/my-central');

		const logo = images.logoOmniNew

		const activeSpace = user.activeSpace

		return (
			<>
				<Draggable
					disabled={!inPlatform.isActive && rigthPanelActive}
					defaultClassName={inPlatform.isActive || !rigthPanelActive ? 'react-draggable At-AgoraTemplate' : 'At-AgoraTransformNone'}
					defaultClassNameDragging={inPlatform.isActive || !rigthPanelActive ? 'react-draggable-dragging' : ''}
					defaultClassNameDragged={inPlatform.isActive || !rigthPanelActive ? 'react-draggable-dragged' : ''}
					allowAnyClick={true}
				>
					<div>
						<header id='at-header' className={inPlatform.isActive && !inPlatform.activeSplitScreen ? 'd-none' : 'at-header at-headertwo at-haslayout'}>
							<div className='at-navigationarea'>
								{!this.props.hideHeader && <>
									{authUser && !authUser.isAssigned ?
										<OmniHeaderDropdown onClose={() => this.setState({ openInviteModal: false })} />
										// <a className="at-artcentralicon at-left-topicon cursor-pointer">
										// 	<img src={images.logoOmniChati} alt='omni chat' />


										// </a>
										:
										<a onClick={this.onSwitchProfile} className="at-icon-switchprofile at-left-arrowicon cursor-pointer"><i className="icon-left-arrow"></i></a>
									}
									<strong className='at-logo'>
										<Link to={token ? routes.home : ''}>
											<img src={logo} alt='company logo here' />
										</Link>
									</strong>
									<div className="at-right-topicon d-flex">
										{token && <>
											<a className="cursor-pointer position-relative">
												<i className={`icon-notification ${count > 0 ? 'c-sunshade' : ''}`} onClick={() => this.props.history.push('/notifications')}></i>
												{count > 0 && <label className="at-notifybadge at-orangebadge">{count}</label>}
											</a>
											<a className="cursor-pointer ml-3 at-useralert-icon" >
												<i className={`icon-comment ${userAlertCount > 0 ? 'c-sunshade' : ''}`} onClick={() => this.props.history.push(routes.userAlert)}></i>
												{userAlertCount > 0 && <label className="at-notifybadge at-orangebadge">{userAlertCount}</label>}
											</a>
										</>}
										{token ? <a className="at-logintext ml-2 cursor-pointer" onClick={this.logout}>
											<i className="icon-logout at-color-orange"></i>
											<span>Logout</span>
										</a>
											:
											<a className="at-logintext ml-2 cursor-pointer" onClick={() => UserLoginAction.triggerAction(true)}>
												<i className="icon-login02"></i>
												<span>Login</span>
											</a>
										}
									</div>
								</>}
								{/* { this.spaceNotActive() } */}
								<div id='at-navigation' className={`at-navigation ${!(user?.activeSpace?._id) ? 'd-none' : ''}`}>
									{/* <div className="at-topcontorls">
								<span className={`at-cameraicon ${!connected || isFullScreenViewEnable ? 'disabled' : ''}`} onClick={this.enlargeBands}>
									<i className={`icon-full-screen ${enlargeBands && 'c-sunshade'}`}></i>
								</span>
								{<span className={`at-cameraicon at-show-icon ${!connected && 'disabled'}`} onClick={this.expandList}>
									<i className={`icon-add ${expandStreamingList && 'c-sunshade'}`}></i>
								</span>}

								{
									this.isSpaceAdmin && <span className="at-cameraicon" onClick={this.lockUnlockSpace}>
										<i className={`icon-lock ${this.activeSpace?.isLocked && 'c-sunshade'}`} ></i>
									</span>
								}

								<ul className="at-controlul at-controlulbottom">
									<li
										onClick={this.toggleAudio}
										className={`${!connected && 'disabled'}`}
									>
										<a>
											<i className={`${activeAudio ? 'icon-sound c-sunshade' : 'icon-mute'}`}></i>
										</a>
									</li>
									<li
										onClick={this.toggleVideo}
										className={`${!connected && 'disabled'}`}
									>
										<a>
											<i className={`${activeVideo ? 'icon-video-camera c-sunshade' : 'icon-video-close'}`}></i>
										</a>
									</li>
									<li
										onClick={() => this.toggleSpeechToText()}
										className={`${!connected && 'disabled'}`}
									>
										<a>
											<i className={`icon-chat ${activeSpeechToText ? 'c-sunshade' : ''}`}></i>
										</a>
									</li>

								</ul>
								<ul className="at-controlul at-controlultop">
									{
										this.isSpaceAdmin && <li 
											onClick={this.inviteNewUser}
										>
											<a>
												<i className={`icon-add-user ${openInviteModal ? 'c-sunshade' : ''}`}></i>
											</a>
										</li>
									}
									{
										(!isSpaceActivated && !connected && !joining) && <li className="at-lipowericon">
											<LongPressAableComponent
												click={this.activateSpace}
												onLongPress={this.deactiveSpace}
											>
												<a>
													<i className='icon-power text-c-sunshade'></i>
												</a>
											</LongPressAableComponent>
										</li>
									}
									{
										joining && <li>
											<a>
												<i className='spinner-border text-c-sunshade wh-20'></i>
											</a>
										</li>
									}
									{
										(isSpaceActivated && connected) && <li>

											<LongPressAableComponent
												click={this.leaveSpace}
												onLongPress={this.deactiveSpace}
											>
												<a>
													<i className='icon-power c-sunshade'></i>
												</a>
											</LongPressAableComponent>
										</li>
									}
								</ul>
							</div> */}

									{/* <RtcComponent user={this.props.user} setControlsStatus={this.setControlsStatus} activeAudio={activeAudio} activeVideo={activeVideo} activeSpeechToText={activeSpeechToText} getTotalNumberOfStream={this.getTotalNumberOfStream} /> */}
									{token && (isInRoutes) && <AgoraNextGeneration
										user={this.props.user}
										isSpaceActivated={isSpaceActivated}
										setControlsStatus={this.setControlsStatus}
										getTotalNumberOfStream={this.getTotalNumberOfStream}
										spaceLeft={this.spaceLeft}
										addUserToSpace={this.props.addUserToSpace}
										activateSpaceAction={this.props.activateSpaceAction}
										onIncreasingScreen={this.onIncreasingScreen}
										onDecreasingScreen={this.onDecreasingScreen}
										leaveSpace={this.leaveSpace}
										activateSpace={this.activateSpace}
										activeAudio={activeAudio}
										toggleAudio={this.toggleAudio}
										toggleSpeechToText={this.toggleSpeechToText}
										toggleVideo={this.toggleVideo}
										connected={connected}
										joining={joining}
										enlargeBands={enlargeBands}
										onEnlargeBands={this.enlargeBands}
										expandList={this.expandList}
										expandStreamingList={expandStreamingList}
										isSpaceAdmin={this.isSpaceAdmin}
										lockUnlockSpace={this.lockUnlockSpace}
										activeSpace={activeSpace}
										deactiveSpace={this.deactiveSpace}
										activeVideo={activeVideo}
										activeSpeechToText={activeSpeechToText}
										inviteNewUser={this.inviteNewUser}
										openInviteModal={openInviteModal}
										onExpandList={this.expandList}
										toggleView={this.toggleView}
										showView={this.state.showView}
										showRow={this.state.showRow}
										showGrid={this.state.showGrid}
										showStack={this.state.showStack}
										showStandard={this.state.showStandard}
										toggleRow={this.toggleRow}
										toggleStack={this.toggleStack}
										toggleGrid={this.toggleGrid}
										onStandardView={this.onStandardView}
										onClickingOnExist={this.onClickingOnExist}
										onOpenTutorial={this.onOpenTutorial}
										onCloseTutorial={this.onCloseTutorial}
										showTutorial={showTutorial}
										onOpenShowPermissionPopup={this.onOpenShowPermissionPopup}
										onCloseShowPermissionPopup={this.onCloseShowPermissionPopup}
									/>}
								</div>
							</div>
						</header>
					</div>
				</Draggable>
				<div className={!openInviteModal ? 'd-none' : 'd-block'}>
					<InviteUserComponent isOpen={openInviteModal} onClose={() => this.setState({ openInviteModal: false })} contacts={contacts} />
				</div>
				{/* {openSpaceModal && <SpaceModal open={openSpaceModal} onClose={() => this.setState({ openSpaceModal: false })} />} */}
				<ConfirmAlertComponent open={deactivationPopUp} title="Confirmation!" message="Are you sure you want to deactivate space?" onCancel={this.closePop} onConfirm={this.confirmDeactivation} />
				{showPermissionPopup && <HelpBox onClose={this.onCloseShowPermissionPopup} />}
			</>
		)
	}
}

const mapToStateProps = (state: IStoreActionsStates) => ({
	user: state.user,
	token: state.auth.access_token,
	authUser: state.auth.user,
	isSpaceActivated: state.misc.isSpaceActivated,
	contacts: state.contacts,
	inPlatform: state.inPlatform,
	rigthPanelActive: state.leftPanel.activeRigthPanel,
})

const mapToActionProps = {
	activateSpaceAction,
	fetchContacts,
	refreshUser,
	addUserToSpace,
	setStudioUser,
	logoutAction,
	saveUser,
	switchProfile,

}
export default connect(mapToStateProps, mapToActionProps)(withRouter(StreamingHeaderComponent))
