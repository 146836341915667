import React, { useState, useEffect } from 'react'
import { CurrencyFormat } from '../../../common/CurrencyFormat'
import { ICommissionResponse, IUser } from '../../../../interfaces'
import { commissionWorkService } from '../../../../services'
import { AxiosResponse } from 'axios'
import LoadingComponent from '../../../common/Loading.component'
import { RequestLayout } from './RequestComponent'
import { ProposalLayout } from './ProposalComponent'
import { RouteComponentProps } from 'react-router-dom'
import { IResponse } from '../../../../interfaces/db.interface'

interface IProps {
    user: IUser,
    history: any
}



export const RequestReceived = ({ user, history }: IProps) => {
    const [requests, setRequest] = useState<Array<ICommissionResponse>>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        commissionWorkService.getRequestRecived(user._id).then((res: AxiosResponse<IResponse<any>>) => {
            let data = [...res.data.data]
            setRequest(data.reverse())
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    return (
        <div className="at-transaction-main">
            {requests?.length > 0 ?
                requests.map((item: ICommissionResponse) => {
                    return item.status !== 'approved' ? <RequestLayout item={item} history={history} /> : <ProposalLayout item={item} history={history} />
                })
                :
                (<>{loading && <LoadingComponent className='at-transaction-loader' />}</>)
            }
        </div>
    )
}