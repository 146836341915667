import React, { Fragment, useCallback, useState } from 'react'
import { Props } from 'react-modal';
import { APP_DOMAIN } from '../../environment'
import SwipeableViews from 'react-swipeable-views';
import { ICommissionworkPROPOSAL } from '../../interfaces'
import { proposalImage } from '../../shared'
import { VideoComponent } from './Video.component'

interface IProps {
    item: any
    isServicImage?: boolean
}
export const ProposalImageView = ({ item, isServicImage }: IProps) => {
    const [imageNumber, setImageNumber] = useState(1)
    const activeImage = useCallback((index: any, indexLatest: any, meta: any) => {
        setImageNumber(index + 1)
    }, [])
    // collectionImage(item.itemId, `high_${item.itemId.url}`, true)
    const imageRenderElement = (image: any, index: any) => {
        return (
            !image.isVideo ? (<div key={index} className={isServicImage ? "at-img-h250" : ""}>
                <img
                    src={proposalImage(item, `high_${image.url}`, true)}
                    alt=''
                    className={`post-image`}
                />
            </div>) :
                (
                    <VideoComponent url={proposalImage(item, image.url)} index={index} autoPlay={true} id={item.id} autoControls={true} className="at-feedvideo" />
                )
        )
    }
    return (
        item.images && item.images.length > 0 ? <Fragment>
            <SwipeableViews enableMouseEvents onChangeIndex={activeImage}>
                {item.images.map((i: any, index: any) => (
                    imageRenderElement(i, index)
                ))
                }

            </SwipeableViews>
            {item.images.length > 1 && <p className="sliderText mt-2 mb-0">
                {imageNumber} / {item.images.length}
            </p>}
        </Fragment> : null
    )
}