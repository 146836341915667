import { AxiosError, AxiosResponse } from 'axios'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { EProfessionalServiceType, EServicesFrequencyType, EServicesPaymentType } from '../../../../enums'
import { dataURLtoFile } from '../../../../helper'
import { IResponse } from '../../../../interfaces/db.interface'
import { IConsultantServicesDocument, initServiceState, IServiceImageState, IServiceState, serviceImageState } from '../../../../interfaces/services.interface'
import { routes } from '../../../../router'
import { toastService } from '../../../../services'
import { consultantService } from '../../../../services/consultantServices.service'
import { collectionImage } from '../../../../shared'
import { CurrencyField } from '../../../common/CurrencyInput'
import { ErrorComponent } from '../../../common/Error.component'
import { SliderComponent } from '../../../common/Slider.component'
import { TmceEditor } from '../../../common/TMCEditor.comopnent'
import { CustomEditior } from '../../../Editor'

export const ServicesForm = () => {
    const history = useHistory()
    const params = (useParams() as any).id
    const artDetailRef = useRef<any>(null)
    const [state, setState] = useState<IServiceState>({ ...initServiceState })
    const [serviceImages, setServiceImage] = useState<IServiceImageState>({ ...serviceImageState })
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>({})



    useEffect(() => {
        fetchService()
    }, [])

    const fetchService = () => {
        if (!params) return
        setLoading(true)
        consultantService.getServiceById(params).then((res: AxiosResponse<IResponse<IConsultantServicesDocument>>) => {
            if (res.data.success) {
                setState({
                    ...state,
                    ...res.data.data
                })
            }
            let othrImages = res.data.data?.images?.map((i: any) => collectionImage({} as any, i.url || '')) || ['']
            setServiceImage({
                ...serviceImages,
                preview: [...othrImages],
                previewsCopy: [...othrImages]
            })
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        let { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const onChangePrice = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target
        let price = value < 0 ? 0 : value;
        if (price && (name === "amount")) {
            price = price.split("$")[1].replace(/,/gi, '')
        }

        onChangeText({ target: { name, value: price } })
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()

        if (!valiateData()) return

        let data = new FormData()
        for (let i of serviceImages.image) {
            data.append('file', i)
        }

        data.append('overView', state.overView)
        data.append('description', state.description)
        data.append('paymentMethod', state.paymentMethod)
        data.append('amount', state.amount)
        data.append('name', state.name)
        data.append('serviceType', state.serviceType)
        if (state.numberOfPayments) data.append('numberOfPayments', JSON.stringify(state.numberOfPayments))
        if (state._id) data.append('serviceId', state._id)
        if (state.paymentMethod === EServicesPaymentType.RECURRING_PAYMENTS) data.append('frequency', state.frequency)

        if (state.images.length > 0) data.append('images', JSON.stringify(state.images))
        setLoading(true)
        consultantService.addServiceData(data).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                history.push(routes.account.services.professional.index)
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const valiateData = () => {
        if (serviceImages?.preview?.length === 0) {
            let obj = errors
            obj['image'] = ['Media files reqiured']
            setErrors({ ...obj })
            return false
        }

        if (!state.paymentMethod) {
            let obj = errors
            obj['paymentMethod'] = ['Payment method is required']
            setErrors({ ...obj })
            return false
        }

        if (!state.amount) {
            let obj = errors
            obj['amount'] = ['Amount is required']
            setErrors({ ...obj })
            return false
        }


        if (state.paymentMethod === EServicesPaymentType.RECURRING_PAYMENTS && !state.frequency) {
            let obj = errors
            obj['frequency'] = ['Payment Structure is reqiured']
            setErrors({ ...obj })
            return false
        }

        setErrors({} as any)
        return true
    }

    const selectArtImages = () => {
        artDetailRef.current.click()
    }


    const onChangeArtImages = (e: any) => {
        if (e.target.files) {
            let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = serviceImages
            /* Get files in array form */
            const files = Array.from(e.target.files);
            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then((base64Strings: any) => {
                    setServiceImage({
                        ...serviceImages,
                        image: [...image, ...files],
                        preview: [...preview, ...base64Strings],
                        previewsCopy: [...previewsCopy, ...base64Strings],
                        uploadServiceImage: true
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onCropArtImages = (cropedImage: any) => {
        let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = serviceImages
        let currentIndex = (preview.length - 1) - (image.length - 1)
        const fileName = image[imageIndex - currentIndex].name
        const file = dataURLtoFile(cropedImage.cropped, fileName)
        const updatedFiles = image
        updatedFiles[imageIndex - currentIndex] = file
        const updatedPreviews = [...preview]
        updatedPreviews[imageIndex] = cropedImage.cropped
        setServiceImage({
            ...serviceImages,
            image: updatedFiles,
            preview: updatedPreviews,
            previewsCopy: updatedPreviews
        })
    }

    const onDeleteArtImage = () => {
        let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = serviceImages
        let updateImage = []
        if (image && image.length > 0) {
            let currentIndex = (preview.length - 1) - (image.length - 1)
            updateImage = image.filter((a: any, index: any) => index !== (imageIndex - currentIndex))
        }
        let updatePreview = preview?.filter((a: any, index: any) => index !== imageIndex)
        let newIndaex = imageIndex

        if (state && state._id && state.images && (state.images.length - 1) >= imageIndex) {
            let currentIndex = (preview.length - 1) - (state.images.length - 1)
            state.images = state.images.filter((i: any, index: number) => index !== (imageIndex - currentIndex))
        }


        if (newIndaex >= updatePreview.length && newIndaex !== 0) {
            newIndaex = newIndaex - 1
        }


        setServiceImage({
            ...serviceImages,
            image: updateImage,
            preview: updatePreview,
            previewsCopy: updatePreview,
            imageIndex: newIndaex
        })
    }

    const onChangeIndex = (selectedFile: number) => {
        setServiceImage({
            ...serviceImages,
            imageIndex: selectedFile
        })
    }

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two at_pad_lr_0">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>{state.name ? state.name : 'Services form'}</h2>
                </div>
                <div className="at_theme_innercontent">
                    <form className="at-formtheme at-formcreatecollection" onSubmit={onSubmit}>
                        <fieldset>
                            <div className="form-group form-w-100 at-floatlabel mt-4">
                                <input type="text" name="name" value={state.name} disabled={loading} onChange={onChangeText} className="form-control " placeholder=" " />
                                <label>Service Name</label>
                            </div>
                            <div className="form-group form-w-100">
                                <span className="at-select at-floatlabel">
                                    <select name="serviceType" className="form-control floating-select" value={state.serviceType} onChange={onChangeText} placeholder=" " >
                                        <option value="">Select Service</option>
                                        <option value={EProfessionalServiceType.CONTENT_CREATION_MARKETING}>Content Creation &amp; Marketing</option>
                                        <option value={EProfessionalServiceType.BUSINESS_MANAGEMENT_FOR_ARTISTS}>Business Management for Artists</option>
                                        <option value={EProfessionalServiceType.ART_ADVISORY_FOR_BUYERS}>Art Advisory for Buyers</option>
                                    </select>
                                    <label className="at-lineheight-27 at-texttransf-none">Type of Service</label>
                                </span>
                            </div>

                            <div className="form-group form-w-100 mb-0">
                                <div className="at-themefileupload text-center">
                                    <input type="file" name="image" multiple ref={artDetailRef} accept="image/*,video/*" onChange={onChangeArtImages} id="at-service-image" />
                                    <label onClick={selectArtImages}>
                                        <span><i className="icon-gallery"></i>Upload Service Pictures</span>
                                    </label>
                                    {errorElement('image')}
                                </div>
                            </div>

                            <div className="form-group form-w-100">
                                <SliderComponent items={serviceImages.preview} originalItems={serviceImages.previewsCopy} onCrop={onCropArtImages} currentIndex={serviceImages.imageIndex} onChangeIndex={onChangeIndex} />
                                {
                                    serviceImages.preview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={onDeleteArtImage}></i>)
                                }

                            </div>

                            <div className="form-group at-floatlabel form-w-100">
                                <textarea name="overView" placeholder=" " className="form-control " onChange={onChangeText} value={state.overView}>{state.overView}</textarea>
                                <label>Overview</label>
                                {errorElement('overView')}
                            </div>

                            <div className="form-group form-w-100">
                                <label className="text-left at-color-grey">Service Description</label>
                                {/* <textarea name="description" placeholder=" " value={state.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}
                                {/* {state.description ? <CustomEditior hideToolBar={false} recieve={true} text={state.description} onChange={(e: any) => onChangeText({ target: { name: 'description', value: e } })} />
                                    :
                                    <CustomEditior hideToolBar={false} recieve={false} text={state.description} onChange={(e: any) => onChangeText({ target: { name: 'description', value: e } })} />
                                } */}

                                <TmceEditor text={state.description} onChange={(e: any) => onChangeText({ target: { name: 'description', value: e } })} />
                            </div>

                            <div className="form-group form-w-100">
                                <span className="at-select at-floatlabel">
                                    <select className="form-control floating-select" value={state.paymentMethod} name="paymentMethod" onChange={onChangeText} placeholder=" " >
                                        <option value="">Payment Structure</option>
                                        <option value={EServicesPaymentType.RECURRING_PAYMENTS}>Recurring Payments</option>
                                        <option value={EServicesPaymentType.UPFRONT_PAYMENT}>Upfront  Payment</option>

                                    </select>
                                    {/* <label>Payment Structure</label> */}
                                    {errorElement('paymentMethod')}
                                </span>
                            </div>

                            {state.paymentMethod === EServicesPaymentType.UPFRONT_PAYMENT &&

                                <div className="form-group form-w-50 at-floatlabel">
                                    <CurrencyField
                                        id="input-example-shipping"
                                        className="form-control "
                                        name="amount"
                                        placeholder=" "
                                        prefix="$"
                                        value={state.amount}
                                        onChange={onChangePrice}
                                    />
                                    <label>Amount</label>
                                    {errorElement('amount')}
                                </div>

                            }

                            {state.paymentMethod === EServicesPaymentType.RECURRING_PAYMENTS &&
                                <>

                                    <div className="form-group form-w-50 at-floatlabel">
                                        <CurrencyField
                                            id="input-example-shipping"
                                            className="form-control "
                                            name="amount"
                                            placeholder=" "
                                            prefix="$"
                                            value={state.amount}
                                            onChange={onChangePrice}
                                        />
                                        <label>Amount</label>
                                        {errorElement('amount')}
                                    </div>

                                    <div className="form-group form-w-50">
                                        <span className="at-select at-floatlabel">
                                            <select className="form-control floating-select" value={state.frequency} name="frequency" onChange={onChangeText} placeholder=" " >
                                                <option value="">Payment Structure</option>
                                                <option value={EServicesFrequencyType.WEEK}>Weekly</option>
                                                <option value={EServicesFrequencyType.MONTH}>Monthly</option>
                                                <option value={EServicesFrequencyType.YEAR}>Annual</option>

                                            </select>
                                            {/* <label>Payment Structure</label> */}
                                            {errorElement('frequency')}
                                        </span>
                                    </div>

                                    <div className="form-group form-w-50 at-floatlabel">
                                        <input autoComplete="off" type="number" min="0.0001" step="any" name="numberOfPayments" className="form-control at-input-semibold" placeholder=" " value={state.numberOfPayments} onWheel={(e: any) => e.target.blur()} onChange={onChangeText} />
                                        <label>Number of Payments</label>
                                        {errorElement('numberOfPayments')}
                                    </div>
                                </>
                            }
                            <div className="form-group form-w-100">
                                {/* <span className={`${(newCollection.description || '').length > 250 && 'text-danger'}`}>You have {250 - (newCollection.description || '').length} characters left</span> */}
                                <button type="submit" disabled={loading} className="at-btn at-longbtn-h40imp">{loading ? 'Wait...' : 'Update'}</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </main>
    )
}