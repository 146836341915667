import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { images } from '../../assets/image'
import LoadingComponent from '../../components/common/Loading.component'
import { ICollection, ICollectionItem } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { collectionService } from '../../services/collection.service'
import { collectionImage } from '../../shared'
import { templateSelector } from '../../store/selectors'
import { CustomImage } from '../components/CustomImage'
import { CustomText } from '../components/CustomText'
import ExhibitionsPage from './ExhibitionsPage'
import { routes } from './routes'

const CollectionPage = () => {
    const history = useHistory()
    const params = (useParams() as any).id
    const template = useSelector(templateSelector)
    const [collection, setCollection] = useState<ICollection>()
    const [loading, setLoading] = useState<boolean>(false)

    const fetch = useCallback(() => {
        setLoading(true)
        collectionService.show(params).then((response: AxiosResponse<IResponse<ICollection>>) => {
            setCollection(response.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        fetch()
    }, [])
    return <div >

        <section className="At-ArtistsPage At-Padding-b6 At-Section-Mh800">
            <div className="container">
                <div className="row mx-lg-n6">
                    {collection && <div className="col-sm-6 px-lg-6 At-ArtistLeftCol">
                        {/* <h5>July 07, 2020 - August 27, 2020</h5> */}
                        <h3>{collection.title}</h3>
                        <CustomText>
                            <p dangerouslySetInnerHTML={{ __html: collection.description }}></p>
                        </CustomText>
                    </div>}
                    <div className="col-sm-6 px-lg-6 At-ArtistRightCol">
                        {collection?.items && collection.items.length > 0 && collection.items.map((i: ICollectionItem) => (
                            // <figure className="At-FigureFull At-Figure-h470" onClick={() => { history.push(`${routes(template.url).artists.collectionItemView}/${i.id}`) }}>
                            //     <img src={collectionImage(i, `${i.url}`, true)} className="img-fluid" alt="" />
                            // </figure>
                            <div className="At-BorderFigureHolder">
                                <div className="At-BorderFigure">
                                    <CustomImage src={collectionImage(i, `${i.url}`)} thumbnail={collectionImage(i, `blur_${i.url}`)} />

                                </div>
                                <div className="At-ArtDetailBox">
                                    {i.name && <h5>
                                        {i.name}
                                    </h5>}
                                    {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>}
                                    <i className="icon-dotted-circle" onClick={() => { history.push(`${routes(template.url).artists.collectionItemView}/${i.id}`) }}></i>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                {loading && <LoadingComponent className='at-transaction-loader' />}
            </div>
        </section>
    </div>
}

export default CollectionPage