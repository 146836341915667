import { AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { IUser } from '../../../interfaces'
import { IServiceTransaction } from '../../../interfaces/services.interface'
import { consultantService } from '../../../services/consultantServices.service'
import { IResponse } from '../../../interfaces/db.interface'
import { collectionImage } from '../../../shared'
import { CurrencyFormat } from '../../common/CurrencyFormat'
import LoadingComponent from '../../common/Loading.component'
import { ServiceTransactionlayout } from './ServiceTransactionLayout'

interface IProps {
    user: IUser
}
export const SellerServiceTransaction = ({ user }: IProps) => {
    const [transactions, setTransactions] = useState<Array<IServiceTransaction>>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchTransaction = () => {
        setLoading(true)
        consultantService.getSellerTransactions(user._id).then((res: AxiosResponse<IResponse<Array<IServiceTransaction>>>) => {
            if (res.data.success) {
                let rev = [...res.data.data].reverse()
                setTransactions([...rev])
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchTransaction()
    }, [])

    return (
        <>
            <div className="at-transaction-main">
                {transactions?.length > 0 ? (
                    <>
                        {transactions?.map((item: IServiceTransaction) => (
                            <>
                                <div key={item._id} className="at-transaction-wrap">
                                    <ServiceTransactionlayout t={item} />
                                </div>
                            </>
                        ))}
                    </>
                ) : (<>{loading && <LoadingComponent className='at-transaction-loader' />}</>)}
            </div>
        </>
    )
}