import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { IPost, IUser } from '../../../../interfaces'
import AssignedCreateEditPostPage from './AssignedCreatePost'

interface IProps extends RouteComponentProps {
    user: IUser
}
const AssignedPost = (props: IProps) => {
    const [selectedPost, setSelectedPost] = useState<IPost>()
    const [didUpated, setDidUpdate] = useState(false)

    useEffect(() => {
        if (selectedPost) {
            setDidUpdate(!didUpated)
        }
    }, [selectedPost])
    const onSelecPost = (p: IPost) => {
        setSelectedPost({ ...p })
    }

    const onSubmitPost = () => {
        let obj: any = {}
        setSelectedPost(obj)
    }
    return <AssignedCreateEditPostPage {...props} id={selectedPost?._id} onSelecPost={onSelecPost} onSubmitPost={onSubmitPost} />
}

export default AssignedPost