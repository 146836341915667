import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CustomTooltip } from '../../components/common/CustomTooltip'
import { ErrorComponent } from '../../components/common/Error.component'
import { typesOfAssignment } from '../../constants'
import { ERole } from '../../enums'
import { routes } from '../../router'
import { userSelector } from '../../store/selectors'
import Select, { components } from 'react-select'
import { IAssignAccountResponse, IAssignAccountState, IUser } from '../../interfaces'
import { assignAccountService, toastService, userService } from '../../services'
import { AxiosResponse } from 'axios'
import { UserPicComponent } from '../../components/User/index.component'
import { images } from '../../assets/image'
import { ConfirmAlertComponent } from '../../components/common/ConfirmAlert.component'
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon'
import { EScreens } from '../../enums/screens.enum'
import { UniversalModal } from '../../components/common/Modals/Universal.modal'
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial'

interface IProps {

}

const initialState = {
    commission: NaN,
    period: NaN,
    formatCommission: NaN,
    formatPeriod: NaN,
    type: '',
    originalResponsibility: 'gallery',
    formatResponsibility: 'gallery',
    agent: ''
}


const ArtistAssignAccount = ({ }: IProps) => {
    const history = useHistory()
    const user = useSelector(userSelector)
    const [errors, setError] = useState<any>()
    const [agents, setAgents] = useState<Array<any>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [state, setState] = useState<IAssignAccountState>({ ...initialState })
    const [selectedAgent, setSelectedAgent] = useState()
    const [accounts, setAccounts] = useState<IAssignAccountResponse>()
    const [selectedAccounts, setSelectedAccounts] = useState<IAssignAccountResponse>()
    const [confirmBox, setConfirmBox] = useState<boolean>(false)
    const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false)

    useEffect(() => {
        if (!user.roles.includes(ERole.ARTIST)) {
            history.replace(routes.account.index)
        }
        fetch()
        fetchAccounts()
    }, [])

    const fetch = useCallback(() => {
        setLoading(true)
        userService.agents().then((res: AxiosResponse<Array<IUser>>) => {
            let data = res.data.map((a: IUser) => {
                return {
                    value: a._id,
                    label: `${a.firstName} ${a.lastName}`,
                    galleryAgent: a
                }
            })
            setAgents([...data])
            setLoading(false)

        }).catch((err: any) => {
            console.log(err)
            setLoading(false)

        })
    }, [])

    const fetchAccounts = useCallback(() => {
        setLoading(true)
        assignAccountService.getAllArtistAccounts().then((res: AxiosResponse<any>) => {
            setAccounts({ ...res.data.data })
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    const onChangeData = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }

    const onSelectAgent = (data: any) => {
        setSelectedAgent({ ...data })
        setState({
            ...state,
            agent: data.value
        })
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (!validateForm()) return;

        let data: any = state
        data.artist = user._id
        setLoading(true)
        assignAccountService.assignAcount(data).then((res: AxiosResponse) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                setState({ ...initialState })
                fetchAccounts()
            } else {

                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }

    const validateForm = () => {
        let keys = Object.keys(initialState)
        let data: any = state
        let newErr: any = {}
        keys.forEach((k: any) => {
            if (!data[k]) {
                newErr[k] = [`${k} is Required`]
            }
        })
        setError({ ...newErr })
        if (Object.keys(newErr).length > 0) {
            return false
        }

        return true
    }

    const onEndAssignment = () => {
        setLoading(true)
        if (!selectedAccounts) return
        assignAccountService.onArtistEndAssignment({ id: selectedAccounts._id }).then((res: AxiosResponse<any>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            } else {
                toastService.error(res.data.message)
            }
            fetchAccounts()
            closeConfirm()
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
            closeConfirm()

        })
    }

    /* React Select Changes */
    const formatOptionLabel = (data: any) => {
        return (
            <div className="at-assign-search d-flex align-items-center">
                <div onClick={() => data.selectOption(data.data)}><UserPicComponent user={data.data.galleryAgent} /></div>
                <div className="at-assignsearch-text"><components.Option {...data} /></div>
            </div>
        )
    };

    const filterOptions = (sData: any, input: string) => {
        if (sData && sData.label) {
            return sData.label.replace(/ /g, '').toLowerCase().includes(input.toLowerCase())
        }
        return false
    }

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    const openCormfirmBox = (a: IAssignAccountResponse) => {
        setSelectedAccounts({ ...a })
        setConfirmBox(true)
    }

    const closeConfirm = () => {
        let obj: any = {}
        setSelectedAccounts(obj)
        setConfirmBox(false)
    }



    const openTutorialBox = () => {
        setShowTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setShowTutorialBox(false)
    }

    const assignForm = () => {
        return (
            <form onSubmit={onSubmit} className="at-assignment-form">
                <div className='form-group at-inputwithicon at-assignselect'>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isLoading={loading}
                        isSearchable={true}
                        name="agent"
                        options={agents}
                        isClearable={true}
                        components={{ Option: formatOptionLabel }}
                        filterOption={filterOptions}
                        value={selectedAgent}
                        onChange={onSelectAgent}
                    />
                    {errorElement('agent')}

                </div>

                <div className="form-group">
                    <div className="d-flex align-items-center ">
                        <span className="at-select at-floatlabel-h40">
                            <select className="text-capitalize floating-select" value={state.type} onChange={onChangeData} name="type" required >
                                <option value="" disabled></option>
                                <option>exclusive</option>
                                <option>non-exclusive</option>
                            </select>
                            <label>Type of Assignment</label>
                        </span>
                        {/* <i className="icon-info at-infoassignment" ></i> */}
                    </div>
                    {errorElement('type')}
                </div>
                <div className='form-group'>
                    <div className="at-floatlabel-h40">
                        <input
                            type='number'
                            name='period'
                            value={state.period}
                            onChange={onChangeData}
                            className='form-control text-capitalize'
                            onWheel={(e: any) => e.target.blur()}
                            placeholder={' '}
                        />
                        <label className="at-label-small">Original length of Assignment in Months</label>
                    </div>
                    {errorElement('period')}
                </div>
                <div className='form-group at-floatlabel-h40'>
                    <input
                        type='number'
                        name='commission'
                        value={state.commission}
                        className='form-control text-capitalize'
                        placeholder={' '}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={onChangeData}
                    />
                    <label className="at-label-small">Original percent (%) commission paid to Gallery</label>

                    {errorElement('commission')}
                </div>

                <div className="form-group">
                    <div className="d-flex align-items-center ">
                        <span className="at-select at-floatlabel-h40">
                            <select className="text-capitalize floating-select" name="originalResponsibility" value={state.originalResponsibility} onChange={onChangeData} required  >
                                <option disabled value=""></option>
                                <option>artist</option>
                                <option>gallery</option>
                            </select>
                            <label>Original Shipping Responsibility</label>
                        </span>
                    </div>
                    {errorElement('originalResponsibility')}
                </div>

                <div className='form-group'>
                    <div className="at-floatlabel-h40">
                        <input
                            type='number'
                            name='formatPeriod'
                            value={state.formatPeriod}
                            onChange={onChangeData}
                            onWheel={(e: any) => e.target.blur()}
                            className='form-control text-capitalize'
                            placeholder={' '}

                        />
                        <label className="at-label-small">Other Formats length of Assignment in Months</label>

                    </div>
                    {errorElement('formatPeriod')}
                </div>
                <div className='form-group at-floatlabel-h40'>
                    <input
                        type='number'
                        name='formatCommission'
                        value={state.formatCommission}
                        onChange={onChangeData}
                        onWheel={(e: any) => e.target.blur()}
                        className='form-control text-capitalize'
                        placeholder={' '}

                    />
                    <label className="at-label-small">Other Formats percent (%) commission paid to Gallery</label>

                    {errorElement('formatCommission')}
                </div>
                <div className="form-group">
                    <div className="d-flex align-items-center ">
                        <span className="at-select at-floatlabel-h40">
                            <select className="text-capitalize floating-select" name="formatResponsibility" value={state.formatResponsibility} onChange={onChangeData} required >
                                <option value="" disabled></option>
                                <option>artist</option>
                                <option>gallery</option>
                            </select>
                            <label>OTHER FORMAT Shipping Responsibility</label>
                        </span>
                    </div>
                    {errorElement('formatResponsibility')}
                </div>
                <div className='form-group'>
                    <button
                        type='submit'
                        className='at-btn at-assignbtn-from'
                        disabled={loading}
                    >
                        {loading ? 'Please wait....' : 'ASSIGN'}
                    </button>
                </div>
            </form>
        )
    }

    const accountAssigned = () => {
        let agent = accounts?.agent as IUser
        return (
            <div className="at-assign-managerbox ">
                <h5 className="at-color-grey">Your Account Is Currently<br /> Managed By:</h5>
                {/* <figure className="at-theme-figureround at-figure-w100">
                {agent && agent._id && <img src={agent.p} className="img-fluid" alt="" />}
            </figure> */}
                { agent && agent._id && <UserPicComponent className="at-theme-figureround at-figure-w100" user={agent} />}
                {agent && agent._id && <h4>{agent.firstName} {agent.lastName}</h4>}
                <button className="at-btn at-minwidth-100 mt-3" disabled={loading} onClick={() => openCormfirmBox((accounts as IAssignAccountResponse))}>End Assignment</button>

            </div>
        )
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Assign Account Management <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.ASSIGN_ACCOUNT_MANAGEMENT} onClick={openTutorialBox} /></span></h2>
                </div>
                <div className="at_theme_innercontent">
                    {accounts && accounts._id ? accountAssigned() : assignForm()}
                    {/* at-assign-managerbox ends */}
                </div>
                {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={closeTutorialBox} position='bottom-0'>
                    <ScreenTutorial screen={EScreens.ASSIGN_ACCOUNT_MANAGEMENT} />
                    {/* at-categorybox ends */}
                </UniversalModal>
                }
            </div>
            {confirmBox && <ConfirmAlertComponent closeable={true} onClose={closeConfirm} open={confirmBox} onCancel={closeConfirm} title="Confirm!" message={`Are you sure you want to end this Assignment`} onConfirm={onEndAssignment} confirmTitle="Confirm" cancelTitle="Cancel" />}
        </main>
    )
}

export default ArtistAssignAccount