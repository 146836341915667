import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import LoadingComponent from '../../components/common/Loading.component'
import { ICollectionItem } from '../../interfaces'
import { collectionImage } from '../../shared'
import { CustomImage } from '../../templates/components/CustomImage'
import { IGalleryWalkSpacesDocument } from '../../interfaces/Gallery-walk.interface'
import { galleryWalkService } from '../../services'
import { IResponse } from '../../interfaces/db.interface'
import { ERole } from '../../enums'

interface IProps {
    galleryWalkSpaceId?: string
    onClickOnItem: Function,
    onGoBack: Function
}
const BuyerGalleryFeatureArtView = ({ galleryWalkSpaceId, onClickOnItem, onGoBack }: IProps) => {
    const [galleryWalkSpace, setGalleryWalkSpace] = useState<IGalleryWalkSpacesDocument>()
    const [loading, setLoading] = useState<boolean>(false)
    const fetchGalleryWalk = () => {
        if (!galleryWalkSpaceId) return
        setLoading(true)
        galleryWalkService.getSingleGallerySpace(galleryWalkSpaceId).then((res: AxiosResponse<IResponse<IGalleryWalkSpacesDocument>>) => {
            setGalleryWalkSpace(res.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchGalleryWalk()
    }, [])

    const confirmDeactivation = () => {

        onGoBack()
        return
        // AgoraLeaveActions.triggerAction(true)
        // accountService.deactivateSpace().then((response: any) => {
        //     let spaceName = spaceService.getSpaceName(activeSpace);
        //     toastService.info(`You have exited ${spaceName}`)
        //     dispatch(setStudioUser({} as any))
        //     dispatch(refreshUser())
        //     dispatch(activateSpaceAction(false))

        //     // $('#at-wrapper').removeClass('expanded-video-list')
        //     // $('#at-wrapper').removeClass('speech-to-text-enabled')
        //     // $('#at-wrapper').removeClass('show-in-full-screen')
        //     onGoBack()
        // }, (error: any) => {
        //     toastService.error('Some error occurred.')

        // })
    }


    return <div >
        <section className="At-ArtistsPage ">
            <div className="container">
                <div className="row ">
                    <div className="col-12 at-panelcollection-heading">
                        <a onClick={() => confirmDeactivation()} className="at-left-arrowicon"><i className="icon-arrow-bold"></i></a>
                        <div className="at-panelcollection-text">
                            {galleryWalkSpace && galleryWalkSpace.user && <h2> {galleryWalkSpace.user?.roles.includes(ERole.AGENT) ? galleryWalkSpace.user?.gallery_name : `${galleryWalkSpace.user?.firstName} ${galleryWalkSpace.user?.lastName}`} </h2>}
                            {galleryWalkSpace && <h3 className="at-color-grey">{`Featured Art`}</h3>}
                        </div>

                    </div>

                    {galleryWalkSpace && galleryWalkSpace.featuredArts.length > 0 && galleryWalkSpace.featuredArts.map((i: ICollectionItem) => {

                        return (<div className="col-md-6 col-lg-4">
                            <div className="At-BorderFigureHolder">
                                <div className="At-BorderFigure">
                                    <CustomImage src={collectionImage(i, `${i.url}`)} thumbnail={collectionImage(i, `blur_${i.url}`)} />

                                </div>
                                <div className="At-ArtDetailBox">
                                    {i.name && <h5>
                                        {i.name}
                                    </h5>}
                                    {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>}
                                    <i className="icon-dotted-circle" onClick={() => onClickOnItem(i)}></i>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
                {loading && <LoadingComponent className='at-transaction-loader' />}
            </div>
        </section>
    </div>
}

export default BuyerGalleryFeatureArtView