import React, {Component} from 'react';
import { Switch, Route } from 'react-router-dom';

import StreamingHeaderComponent from "../components/common/StreamingHeader.component";
import BottomTabsComponent from "../components/MyCentral/BottomTabs.component";
import NotFound404Page from "../pages/NotFound404.page";
import HomePage from "../pages/MyCentral/Home.page";
import {routes} from "./index";
import SpacesPage from "../pages/MyCentral/Spaces.page";

class MyCentralRoute extends Component {
    render() {
        return (
            <main id="at-main" className="at-main at-haslayout">

                {/*Switch Routes*/}
                    <Switch>
                        <Route path={routes.myCentral.home} exact component={SpacesPage} />
                        <Route path={routes.myCentral.spaces} exact component={HomePage} />
                    </Switch>

                {/*Bottom Tabs*/}
                <BottomTabsComponent {...this.props} />
            </main>
        );
    }
}

export default MyCentralRoute;