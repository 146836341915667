import React, { useEffect, useState } from 'react'
import { assignmentService, toastService } from '../../../services'
import { AxiosResponse } from 'axios'
import { IUser, IArtAssignment, ICollection, IDynamic, IAssignmentCollection, ICollectionItem } from '../../../interfaces'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector } from '../../../store/selectors'
import { AgentAssignmentComponent } from '../../../components/ArtCentral/collection/AgentAssignment.component'
import { useHistory, Link } from 'react-router-dom'
import { routes } from '../../../router'
import { trim } from 'lodash'
import { ErrorComponent } from '../../../components/common/Error.component'
import { collectionImage } from '../../../shared'
import { images } from '../../../assets/image'
import { NoDataComponent } from '../../../components/common/NoData.component'
import { AgentCollectionComponent } from '../../../components/ArtCentral/collection/AgentCollection.component'
import { ConfirmAlertComponent } from '../../../components/common/ConfirmAlert.component'
import { BSLoading } from '../../../components/common/Loading.component'
import { refreshUser } from '../../../store/actions'
import { IResponse } from '../../../interfaces/db.interface'

export const AgentCollectionsPage = () => {
    const router = useHistory();
    const user = useSelector(userSelector);
    const [assignments, setAssignments] = useState<Array<IArtAssignment>>([])
    const [collections, setCollections] = useState<Array<IAssignmentCollection>>([])
    const [selectedAssignments, setSelectedAssignments] = useState<IDynamic>({})
    const [addToCollection, setAddToCollection] = useState<boolean>()
    const [collectionName, setCollectionName] = useState('')
    const [selectedCollection, setSelectedCollection] = useState('')
    const [creatingCollection, setCreatingCollection] = useState<boolean>()
    const [addingItems, setAddingItems] = useState<boolean>()
    const [isRejectedPopup, setRejectedPopup] = useState<boolean>(false)
    const [rejectionLoading, setRejectionLoading] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [keywords, setKeywords] = useState<string>('')
    const dispatch = useDispatch()
    const fetch = () => {
        return assignmentService.list().then((response: AxiosResponse<IUser>) => {
            setAssignments(response.data.assignments || [])
            setCollections(response.data.assignment_collections || [])
            dispatch(refreshUser())
        })
    }
    useEffect(() => {
        fetch()
    }, [])

    const isSelected = (id: string) => {
        return selectedAssignments[id] === true
    };
    const onLongPress = ({ id }: any) => selectUnselect(id)

    const selectUnselect = (id: string) => {
        if (selectedAssignments[id]) {
            delete selectedAssignments[id]
        } else {
            selectedAssignments[id] = true;
        }
        setSelectedAssignments({ ...selectedAssignments })
    }

    const selectionOn = () => Object.keys(selectedAssignments)

    const onClick = ({ id, art }: IArtAssignment) => {

        if (selectionOn().length > 0) return selectUnselect(id)
        router.push(`${routes.agent.showAssigment}/${id}`)
    }

    const createCollection = () => {
        setCreatingCollection(true)
        assignmentService.createCollection({ title: collectionName, description, keywords }).then((response: AxiosResponse<{ message: string }>) => {
            setCreatingCollection(false)
            toastService.success(response.data.message)
            setCollectionName('')
            setDescription('')
            setKeywords('')
            fetch();
        })
    }

    const addItemsToCollections = () => {
        setAddingItems(true)
        const assignments: Array<string> = selectionOn();
        assignmentService.addAssignmentToCollection(selectedCollection, assignments).then((response: AxiosResponse<{ message: string }>) => {
            setAddingItems(false)
            setAddToCollection(false)
            toastService.success(response.data.message)
            setSelectedCollection('');
            setSelectedAssignments({})
            fetch();
        })
    }

    /**
     * Rejection Stuff
     */
    const onOpenRejectPopup = () => setRejectedPopup(true)

    const onCloseRejectPopup = () => setRejectedPopup(false)

    const rejectSelectedItem = async () => {
        setRejectionLoading(true)
        for (let i = 0; i < selectionOn().length; i++) {
            await new Promise(async (resolve, reject) => {
                await assignmentService.reject(selectionOn()[i])
                await fetch();
                resolve()
            })
        }
        setRejectionLoading(false)
        onCloseRejectPopup()
    }

   const onMoveUp = (index: any) => {
        if (index !== 0) {
            let items = collections;
            let temp = items[index];
            items[index] = items[index - 1];
            items[index - 1] = temp;
            setCollections([...items])
            let data = items.map((item:any) => item.id)
           UpdatingCollectionSequence(data)
        } else {

            toastService.error("Unable to move the collection Upword")

        }
    }
   const onMoveDown = (index: any) => {
        let items = collections;
        if (index !== items.length - 1) {
            let temp = items[index];
            items[index] = items[index + 1];
            items[index + 1] = temp;
            setCollections([...items])
            let data = items.map((item:any) => item.id)
            UpdatingCollectionSequence(data)
        } else {
            toastService.error("Unable to move the collection Downward")
        }
    };
    const UpdatingCollectionSequence = (data: Array<any>) => {
     assignmentService.updateUserCollectionSequence(user._id,{collections: data}).then((res: AxiosResponse<IResponse<any>>) => {
     }).catch(err => console.log(err))
    }

    return (
        <div className="at-main-inner">
            <div className="at-artcollections scrollable at-contentarea">
                {/* { selectionOn() && <button onClick={() => setAddToCollection(true)}>Add</button>} */}
                <div className="at-artcollectionsarea">
                    <div className="at-formtheme at-formartcollection pt-4">
                        <fieldset className="at-artcollectioncontent">
                            <div className="form-group">
                                <h3>
                                    <a className="at-left-arrowicon cursor-pointer" onClick={router.goBack}>
                                        <i className="icon-left-arrow"></i>
                                    </a>
                                    {/* {selectionOn()  ? `Selected ${selectionOn().length}/${assignments.length} Images` : ''} */}
                                Assigned Art
                            </h3>
                                <div className="at-btniconholder">
                                    <div className={`at-checkbox ${selectionOn().length < 1 ? 'disabled' : ''}`} onClick={() => setAddToCollection(true)}>
                                        <input type="checkbox" name="remember" checked={true} id="artcollectionlike" />
                                        <label className="label-like green-label" htmlFor="artcollectionlike"></label>
                                    </div>
                                    <div className={`at-checkbox ${selectionOn().length < 1 ? 'disabled' : ''}`} onClick={onOpenRejectPopup}>
                                        <input type="checkbox" name="remember" checked={true} id="artcollectiondislike" />
                                        <label className="dark-orange" htmlFor="artcollectiondislike"></label>
                                    </div>
                                    {/* <div className="at-checkbox">
                                    <button className="at-assingn-btn at-pt3px" onClick={() => setAddToCollection(true)}><i className="icon-add-menu"></i></button>
                                </div> */}
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="at-artgallerycollections at-themescrollbar">
                            <ul className="at-artcollectiongrid at-artgallerycollection">
                                {
                                    assignments?.map((assignment: IArtAssignment) => <AgentAssignmentComponent key={assignment.id} user={user} assignment={assignment} onClick={onClick} onLongPress={onLongPress} isSelected={isSelected(assignment.id)} />)
                                }
                            </ul>
                        </fieldset>
                    </div>
                </div>
                {
                }
                <div className="at-managepostholder">

                    <div className="at-manageposttitle">
                        <h2>Collections</h2>
                    </div>
                </div>
                <div className='at-collectiongallery at-themescrollbar '>
                    {
                        collections.map((c: IAssignmentCollection, index: any) => <AgentCollectionComponent key={c.id} collection={c} refresh={fetch} onMoveDown={onMoveDown} onMoveUp={onMoveUp} index={index} />)
                    }
                </div>

            </div>
            {addToCollection &&
                <div id="at-themeaddactionpopup" className={`at-themepopupholder at-showthemesignuppopup`}>
                    <div className="at-themepopupbox">
                        <a className="at-close cursor-pointer" onClick={() => setAddToCollection(false)} id="at-close"><i className="icon-cancel"></i></a>
                        <div className="at-addactionholder">
                            <div className="at-formthemehead  pr-0">
                                <h2>Add To Collection</h2>

                                <div className="form-group mt-4 at-floatlabel-h40">
                                    <span className="at-select">
                                        <select className="floating-select" value={selectedCollection} name="art_collection" onChange={(v: any) => setSelectedCollection(v.target.value)}>
                                            <option value="" disabled></option>
                                            {
                                                collections.map((c: IAssignmentCollection) => <option value={c.id} key={c.id}>{c.title}</option>)
                                            }
                                        </select>
                                        <label>Select Collection</label>
                                    </span>
                                    <ErrorComponent errors={['Collection name is required.']} multiple={false} />
                                </div>

                                <div className="form-group at-pos-relative at-floatlabel-h40">
                                    <input type="text" name="createnewcollection" className="form-control" value={collectionName} autoComplete="off" placeholder=" " onChange={({ target: { value } }) => {
                                        setCollectionName(value)
                                    }} />
                                    <label>Create New Collection</label>
                                    <button type="button" onClick={createCollection} className="at-btnadd c-sunshadebg" disabled={trim(collectionName).length < 1 || creatingCollection}><i className="icon-add"></i></button>
                                </div>

                                <div className="form-group at-floatlabel">
                                    <textarea name="description" placeholder=" " className="form-control agent-textarea" onChange={({target: {value}}) => setDescription(value)} value={description}>{description}</textarea>
                                    <label>Description</label>
                                </div>

                                <div className="form-group at-floatlabel">
                                    <textarea name="description" placeholder=" " className="form-control agent-textarea" onChange={({target: {value}}) => setKeywords(value)} value={description}>{description}</textarea>
                                    <label>KeyWord</label>
                                </div>

                                <button className="at-btn at-minwidth-100" disabled={addingItems} onClick={addItemsToCollections}>Add</button>

                            </div>
                        </div>
                    </div>
                </div>
            }

            <ConfirmAlertComponent open={(selectionOn().length > 0 && isRejectedPopup)} title="Confirm!" message="Are you sure you want to reject these assignments?" cancelTitle="No" confirmTitle={rejectionLoading ? <BSLoading /> : 'Yes'} closeable={true} onClose={onCloseRejectPopup} onCancel={onCloseRejectPopup} onConfirm={rejectSelectedItem} />
        </div>

    )
}
