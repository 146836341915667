import { AxiosResponse, AxiosError } from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { IWeihgtFactorsDocument } from "../../../interfaces";
import { IResponse } from "../../../interfaces/db.interface";
import { IPrintingServices } from "../../../interfaces/services.interface";
import { routes } from "../../../router";
import { adminService, toastService } from "../../../services";
import { ConfirmAlertComponent } from "../../common/ConfirmAlert.component";
import { UniversalModal } from "../../common/Modals/Universal.modal";
import { WeightFactorForm } from "./WeightFactorForm.component";

interface IProps {
    s: IWeihgtFactorsDocument;
    fetchFactors: Function;
    onFactorDown: Function;
    onFactorUp: Function;
    index: Number;
    updating?: boolean
}
export const FactorNameComponent = ({
    s,
    fetchFactors,
    onFactorDown,
    onFactorUp,
    index,
    updating
}: IProps) => {
    const history = useHistory();
    const [confirm, setConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false)

    const openForm = () => {
        setShowForm(true)
    }
    const closeForm = () => {
        setShowForm(false)
    }

    const submitForm = () => {
        closeForm()
        fetchFactors()
    }

    const onClickEdit = () => {
        openForm()
    };

    const openConfirm = () => {
        setConfirm(true);
    };
    const closeConfirm = () => {
        setConfirm(false);
    };

    const onDelete = () => {
        setLoading(true);
        adminService.deleteWeightFactors(s._id)
            .then((res: AxiosResponse<IResponse<IWeihgtFactorsDocument>>) => {
                if (res.data.success) {
                    toastService.success(res.data.message);
                }
                setLoading(false);
                fetchFactors();
                closeConfirm();
            })
            .catch((err: AxiosError) => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <div key={s._id} className="at-categoryitem">
            <div className="at-rightcateg">
                <h6 className="p-0">{s.name} </h6>
                <ul className="at-categaction at-categaction-aligncenter">
                    <li>
                        <button className="d-flex at-btndelete at-arrow-wrap at-fs16" disabled={updating || loading}>
                            <i
                                className="icon-Down-Arrow fa-rotate-180 
    "
                                onClick={() => onFactorUp(index)}
                            >
                            </i>
                            <i
                                className="icon-Down-Arrow"
                                onClick={() => onFactorDown(index)}
                            ></i>

                        </button>
                    </li>

                    <li>
                        <button className="at-btndelete">
                            <i className="icon-delete2" onClick={() => openConfirm()}></i>
                        </button>
                    </li>
                    <li>
                        <button className="at-btndelete">
                            <i className="icon-edit2" onClick={() => onClickEdit()}></i>
                        </button>
                    </li>
                </ul>
            </div>

            {confirm && (
                <ConfirmAlertComponent
                    open={confirm}
                    message={`Are you sure you want to delete this Factor?`}
                    title="Confirmation!"
                    onCancel={closeConfirm}
                    onConfirm={onDelete}
                    confirmTitle="Delete"
                />
            )}

            {showForm && <UniversalModal open={showForm} onClose={closeForm}>
                <WeightFactorForm onClose={closeForm} factoryType={s.factorType} factor={s} onSubmit={submitForm} />
            </UniversalModal>}
        </div>
    );
};
