import { AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ERole } from '../../../../enums'
import { AWS_ENDPOINT } from '../../../../environment'
import { ICollectionItem, IEventDocumentResponse, IUser } from '../../../../interfaces'
import { IResponse } from '../../../../interfaces/db.interface'
import { routes } from '../../../../router'
import { eventService, toastService } from '../../../../services'
import { collectionImage, eventImages } from '../../../../shared'
import { userSelector } from '../../../../store/selectors'
import LoadingComponent from '../../../common/Loading.component'
import { UniversalModal } from '../../../common/Modals/Universal.modal'
import { NoDataComponent } from '../../../common/NoData.component'
import { TmceEditor } from '../../../common/TMCEditor.comopnent'

import { UserNameComponent, UserPicComponent } from '../../../User/index.component'

interface IProps {
    user: IUser
    onBack: Function
    id: string
    onClickOnItem: Function
    onEditEvent: Function
}

const AssignedViewSignleEvent = ({ user, onBack, id, onClickOnItem, onEditEvent }: IProps) => {
    const history = useHistory()
    const params = (useParams() as any).id
    const [event, setEvent] = useState<IEventDocumentResponse>()
    const [showPicture, setShowPicture] = useState<boolean>(false)
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')

    const [selectedItems, setSelectedItems] = useState<Array<ICollectionItem>>([])


    const fetch = useCallback(() => {
        eventService.getSingleEvent(id).then((res: AxiosResponse<IResponse<any>>) => {
            // if (!res.data.data) {
            //     history.goBack()
            // }
            setEvent({ ...res.data.data })
            setDescription(res.data.data.description)
        })
    }, [])
    useEffect(() => {
        // if (!params) {
        //     history.goBack()
        //     return
        // }
        fetch()
    }, [])

    const onPdfClick = () => {
        if (!event || !event.pdf || event.pdf === 'null') return
        let link = `${AWS_ENDPOINT}/pdf/${event?.pdf}`
        window.open(link, "_blank");
    }

    const onSelectItems = (item: ICollectionItem) => {
        if (event) {
            if (selectedItems.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1) {
                let newItems = selectedItems.filter((i: ICollectionItem) => i.id !== item.id)
                setSelectedItems([...newItems])
                return
            }
            let newArts = selectedItems
            newArts.push(item)
            setSelectedItems([...newArts])
        }
    }

    const isItemSelected = (item: ICollectionItem) => {
        return selectedItems.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1
    }

    const selectAllItems = () => {
        if (event) {
            let arts = event.items as Array<ICollectionItem>
            if (selectedItems.length === arts.length) {
                setSelectedItems([])
                return
            }
            setSelectedItems([...arts])
        }
    }

    const isAllItemsSelected = () => {
        return selectedItems.length > 0 && selectedItems.length === event?.items.length
    }

    const updateEvent = (data: any, showToast: boolean = false) => {
        if (!event) return
        setLoading(true)
        eventService.updateSingleEvent(event?._id, { ...data }).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                fetch()
                showToast && toastService.success(res.data.message)
            } else {
                toastService.error(res.data.message)
            }
            setSelectedItems([])
            onCloseModals()
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onMoveUp = (index: any) => {
        if (index !== 0 && event) {
            let items = event.items;
            let temp = items[index];
            items[index] = items[index - 1];
            items[index - 1] = temp;
            let data = event
            data.items = items
            let array = (items as Array<ICollectionItem>).map((i: ICollectionItem) => i.id)
            updateEvent({ items: array })
        } else {
            toastService.error("Unable to move the collection Upword")
        }
    }

    const onMoveDown = (index: any) => {
        if (!event) return
        let items = event.items;
        if (index !== items.length - 1) {
            let temp = items[index];
            items[index] = items[index + 1];
            items[index + 1] = temp;
            let data = event
            data.items = items
            let array = (items as Array<ICollectionItem>).map((i: ICollectionItem) => i.id)
            updateEvent({ items: array })
        } else {
            toastService.error("Unable to move the collection Downward")
        }
    };

    const onDeleteItems = () => {
        if (event) {
            let items = (event.items as Array<ICollectionItem>).filter((i: ICollectionItem) => {
                return selectedItems.map((s: ICollectionItem) => s.id).indexOf(i.id) === -1 ? true : false
            })
            let list = items.map((i: ICollectionItem) => i.id)
            updateEvent({ items: list })
        }
    }

    const onCloseModals = () => {
        setShowInfo(false)
    }
    let eventUser = (event?.user as IUser)
    return (
        <div className='at-postcollections scrollable at-contentarea'>
            {event && eventUser && <div className='at-collections'>
                <div className='at-orangeeconhead'>
                    <a
                        onClick={() => onBack()}
                        className='at-left-arrowicon'
                    >
                        <i className='icon-left-arrow'></i>
                    </a>
                    {eventUser && <UserPicComponent user={eventUser} />}
                    <div className='at-orangeeconcontent'>
                        {eventUser?.isDeaf && (
                            <span className='at-postcontenticon'>
                                <i className='icon-hand'></i>
                            </span>
                        )}
                        <fieldset className="at-artcollectioncontent w-100">
                            <h3>
                                {eventUser?.roles.includes(ERole.AGENT) ? eventUser?.gallery_name : 'Art Studio'}
                                {eventUser?.roles.includes(ERole.ARTIST) && <UserNameComponent user={eventUser} />}
                            </h3>

                        </fieldset>
                    </div>
                </div>
                <div className="at-gallerytitle at-paddinglr-15">
                    <h3 className="d-block mb-2">
                        <Link to="#">
                            {event.name}
                        </Link>
                        <span>{event.items.length} Images</span>
                    </h3>
                    <span>
                        <div className="at-btndelete d-flex ">
                            <button className="fs-20 mr-2 cursor-pointer at-bgtransparent" onClick={() => setShowPicture(true)}><i className="icon-gallery"></i></button>
                            <button className="fs-20 mr-2 cursor-pointer at-bgtransparent" onClick={() => setShowInfo(!showInfo)} ><i className="icon-info"></i></button>
                            <button className="fs-20 mr-2 cursor-pointer at-bgtransparent" onClick={() => onPdfClick()}><i className="icon-pdf"></i></button>
                            {user._id === eventUser._id && <>
                                <button className="fs-20 mr-2 cursor-pointer at-bgtransparent" onClick={() => onEditEvent(event)}><i className="icon-edit2"></i></button>
                                <button className="fs-20 mr-2 cursor-pointer at-bgtransparent" disabled={selectedItems.length === 0} onClick={onDeleteItems}><i className="icon-delete2"></i></button>

                                <div className="at-checkbox">
                                    <input type="checkbox" name="remember" checked={isAllItemsSelected()} />
                                    <label htmlFor="artcollectionselectall" className="at-label-bdradius50" onClick={() => selectAllItems()} ></label>
                                </div>
                            </>}
                        </div>
                    </span>
                </div>
                <fieldset className="at-artgallerycollections at-themescrollbar">
                    <ul className="at-artcollectiongrid at-artgallerycollection" id="dnd" >
                        {
                            event.items.length < 1 && <NoDataComponent icon="fa fa-images" />
                        }
                        {
                            (event.items as Array<ICollectionItem>).map((item: ICollectionItem, index: any) => (
                                <li key={item.id} className="itemImage" id={`${item.id}`} onDoubleClick={() => onClickOnItem(item)}>
                                    <div className="at-artgallerycollectionbox atv2-collectionbox">
                                        <span className="at-checkbox">
                                            <img src={collectionImage(item, `high_${item.url}`, true)} alt={collectionImage(item, `high_${item.url}`, true)} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                                            {user._id === eventUser._id && <><input type="checkbox" name="select" id="artcollectionselectall" checked={isItemSelected(item)} />
                                                <label htmlFor="artcollectionone" onClick={() => onSelectItems(item)}></label> </>}
                                        </span>
                                    </div>
                                    {user._id === eventUser._id && <>
                                        <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowup" title="Show Info" onClick={() => onMoveUp(index)}></i>
                                        <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowdw" title="Show Info" onClick={() => onMoveDown(index)} ></i>
                                    </>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </fieldset>
            </div>}
            {showInfo && <UniversalModal open={showInfo} onClose={() => setShowInfo(false)}>
                <div className="at-addactionholder">
                    <div className="at-formthemehead text-center pb-2">
                        <h2>{event?.name}</h2>
                        <div className="text-left my-3">
                            <p className="mb-0">Start: {moment(event?.startDate).format('LL')}</p>
                            <p className="mb-0">End: {moment(event?.endDate).format('LL')}</p>
                        </div>
                    </div>
                    {(event?.user as IUser)._id === user._id ? <>
                        <div className="row description-modal-editor">
                            <div className="col-12">
                                <TmceEditor text={description} onChange={(e: any) => setDescription(e)} />
                            </div>
                        </div>
                        <div className="pt-2">
                            <button className="at-btn at-btnupdate at-btn-fullwidth" disabled={loading} onClick={() => updateEvent({ description }, true)}>{loading ? `Please wait...` : `Update`}</button>
                        </div>
                    </>
                        :
                        <div className="at-popupinner-mh-55vh ">
                            {description && <div className="text-justify" dangerouslySetInnerHTML={{ __html: description }}>
                            </div>}
                        </div>
                    }
                </div>
            </UniversalModal>}
            {
                showPicture && event && event.images.length > 0 && <UniversalModal open={showPicture} onClose={() => setShowPicture(false)}>
                    <div className="at-modal-images">
                        <h5 className="at-popupheading">Art Show Pictures</h5>
                        {event.images.map((i: any) => {
                            return (
                                <figure className="at-roundbox-figure-plain">
                                    <img src={eventImages({}, `high_${i.url}`)} alt="" />
                                </figure>
                            )
                        })}
                    </div>
                </UniversalModal>
            }
            {loading && <LoadingComponent className='at-transaction-loader' />}
        </div >
    )
}

export default AssignedViewSignleEvent