import { AxiosError, AxiosResponse } from 'axios'
import React, { useState, useEffect } from 'react'
import { ICuratorialOfferDocument } from '../../../../../interfaces'
import { IResponse } from '../../../../../interfaces/db.interface'
import { curatorialService } from '../../../../../services'
import { ParticipantCuratorialOfferView } from './ParticapantOfferView'


export const ParticapantOffersComponent = () => {

    const [offers, setOffers] = useState<Array<ICuratorialOfferDocument>>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchOffers = () => {
        setLoading(true)
        curatorialService.getAllUserCuratorialOffers().then((res: AxiosResponse<IResponse<Array<ICuratorialOfferDocument>>>) => {
            let data = [...res.data.data].reverse()
            setOffers([...data])
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchOffers()
    }, [])

    return (
        <div>
            <div className="at-transaction-main">
                {offers && offers.length > 0 && offers.map((o: ICuratorialOfferDocument, index: number) => <ParticipantCuratorialOfferView offer={o} key={index} fetchOffers={fetchOffers} />)}
            </div>
        </div>
    )
}