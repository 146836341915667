import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import { ERole } from '../../enums';
import { ESubPakcges } from '../../enums/subsPackage.enum';
import { IStoreReducers, IUser } from '../../interfaces';
import { ISubscriptionPackage } from '../../interfaces/subscription.interface';
import { routes } from '../../router';

interface IPageProps extends RouteComponentProps {
    user: IUser
}

class AccountPage extends Component<IPageProps> {

    artistPages = () => {
        let { user } = this.props
        let isNotGuestUser = !(user.roles.length === 0 || user.roles[0].length === 0)
        return (
            <ul className="at_account_mainul">
                <li>
                    <Link to={routes.account.editProfile}><span><i className="icon-user-2"></i></span><h4>Edit Account</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {isNotGuestUser && <li>
                    <Link to={routes.account.subscription}><span><i className="icon-subscription"></i></span><h4>Subscription</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                }
                {user.packageID && ((user.packageID as ISubscriptionPackage).package_name === ESubPakcges.Premium || (user.packageID as ISubscriptionPackage).package_name === ESubPakcges.Professional) && <li>
                    <Link to={routes.account.inPlatformWebsite}><span><i className="icon-globe"></i></span><h4>In-Platform Website </h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                <li>
                    <Link to={routes.account.services.professional.access}> <span><i className="icon-pro-services"></i></span><h4>Access Professional Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.artistAssignAccount}><span><i className="icon-admin-icon-2"></i></span><h4>Assign Account Management</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {user.roles.includes(ERole.PROMOTER) && <li>
                    <Link to={routes.artCentral.gelleryWalk.create}><span><i className="icon-gallery-walk"></i></span><h4>Gallery Walks & Art Events</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                {user.roles.includes(ERole.AUCTIONEER) && <li>
                    <Link to={routes.account.services.auction.add}><span><i className="icon-auction"></i></span><h4>Live Art Auction Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                {/* Remove from SprintONE */}
                <li>
                    <Link to={routes.account.offers}><span><i className="icon-offer"></i></span><h4>Offers</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.services.curatorialServices.participantCuratorialProject}> <span><i className="icon-curatorial"></i></span><h4>Curatorial Projects</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.transactions}><span><i className="icon-transaction"></i></span><h4>Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.services.professional.transactions}><span><i className="icon-transaction"></i></span><h4>Service Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.services.professional.scheduledServices}><span><i className="icon-transaction"></i></span><h4>Scheduled Service Payments</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.commissionWork}><span><i className="icon-handshake"></i></span><h4>Commission Work</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.diputes}><span><i className="icon-dispute-icon"></i></span><h4>Disputes</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.returnArt}><span><i className="icon-Box-1"></i></span><h4>Returns</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.metrics}> <span><i className="icon-bars"></i></span> <h4>Metrics</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.terms}> <span><i className="icon-document"></i></span><h4>Terms</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {/* <li>
                                <Link to={routes.account.funds}> <span><i className="icon-funds"></i></span><h4>Funds</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                            </li> */}
                <li>
                    <Link to={routes.account.funds}> <span><i className="icon-funds"></i></span><h4>Manage Funds</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.salelogs}> <span><i className="icon-tax"></i></span><h4>Sale Tax Logs</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
            </ul>
        )
    }

    guestUser = () => {
        let { user } = this.props
        return (
            <ul className="at_account_mainul">
                <li>
                    <Link to={routes.account.editProfile}><span><i className="icon-user-2"></i></span><h4>Edit Account</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {/* {user.packageID && ((user.packageID as ISubscriptionPackage).package_name === ESubPakcges.Premium || (user.packageID as ISubscriptionPackage).package_name === ESubPakcges.Professional) && <li>
                    <Link to={routes.account.inPlatformWebsite}><span><i className="icon-globe"></i></span><h4>In-Platform Website </h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>} */}
                {/* <li>
                    <Link to={routes.account.services.professional.access}> <span><i className="icon-document"></i></span><h4>Access Professional Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li> */}
                {/* Remove from SprintONE */}
                <li>
                    <Link to={routes.account.offers}><span><i className="icon-offer"></i></span><h4>Offers</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.transactions}><span><i className="icon-transaction"></i></span><h4>Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>



                {/* <li>
                    <Link to={routes.account.services.professional.transactions}><span><i className="icon-transaction"></i></span><h4>Service Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li> */}
                {user.roles.includes(ERole.PROMOTER) && <li>
                    <Link to={routes.artCentral.gelleryWalk.create}><span><i className="icon-gallery-walk"></i></span><h4>Gallery Walks & Art Events</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                {user.roles.includes(ERole.AUCTIONEER) && <li>
                    <Link to={routes.account.services.auction.add}><span><i className="icon-auction"></i></span><h4>Live Art Auction Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                <li>
                    <Link to={routes.account.commissionWork}><span><i className="icon-handshake"></i></span><h4>Commission Work</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.diputes}><span><i className="icon-dispute-icon"></i></span><h4>Disputes</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.returnArt}><span><i className="icon-Box-1"></i></span><h4>Returns</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.metrics}> <span><i className="icon-bars"></i></span> <h4>Metrics</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.terms}> <span><i className="icon-document"></i></span><h4>Terms</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {/* <li>
                                <Link to={routes.account.funds}> <span><i className="icon-funds"></i></span><h4>Funds</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                            </li> */}
            </ul>
        )
    }

    agentPages = () => {
        let { user } = this.props
        return (
            <ul className="at_account_mainul">
                <li>
                    <Link to={routes.account.editProfile}><span><i className="icon-user-2"></i></span><h4>Edit Account</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.subscription}><span><i className="icon-subscription"></i></span><h4>Subscription</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                {user.packageID && ((user.packageID as ISubscriptionPackage).package_name === ESubPakcges.Premium || (user.packageID as ISubscriptionPackage).package_name === ESubPakcges.Professional) && <li>
                    <Link to={routes.account.inPlatformWebsite}><span><i className="icon-globe"></i></span><h4>In-Platform Website </h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                <li>
                    <Link to={routes.account.services.professional.access}> <span><i className="icon-document"></i></span><h4>Access Professional Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.agentAssignAccount}><span><i className="icon-admin-icon-2"></i></span><h4>Assigned Accounts</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.agentCreateArt}><span><i className="icon-color-palette"></i></span><h4>Manage External Art</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {user.roles.includes(ERole.PROMOTER) && <li>
                    <Link to={routes.artCentral.gelleryWalk.create}><span><i className="icon-gallery-walk"></i></span><h4>Gallery Walks & Art Events</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                {user.roles.includes(ERole.AUCTIONEER) && <li>
                    <Link to={routes.account.services.auction.add}><span><i className="icon-auction"></i></span><h4>Live Art Auction Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                {/* Remove from SprintONE */}
                <li>
                    <Link to={routes.account.offers}><span><i className="icon-offer"></i></span><h4>Offers</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.transactions}><span><i className="icon-transaction"></i></span><h4>Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.services.curatorialServices.participantCuratorialProject}> <span><i className="icon-curatorial"></i></span><h4>Curatorial Projects</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.services.professional.transactions}><span><i className="icon-transaction"></i></span><h4>Service Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.services.professional.scheduledServices}><span><i className="icon-transaction"></i></span><h4>Scheduled Service Payments</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.commissionWork}><span><i className="icon-handshake"></i></span><h4>Commission Work</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.diputes}><span><i className="icon-dispute-icon"></i></span><h4>Disputes</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.returnArt}><span><i className="icon-Box-1"></i></span><h4>Returns</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.metrics}> <span><i className="icon-bars"></i></span> <h4>Metrics</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.terms}> <span><i className="icon-document"></i></span><h4>Terms</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {/* <li>
                                <Link to={routes.account.funds}> <span><i className="icon-funds"></i></span><h4>Funds</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                            </li> */}
                <li>
                    <Link to={routes.account.funds}> <span><i className="icon-funds"></i></span><h4>Manage Funds</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.salelogs}> <span><i className="icon-tax"></i></span><h4>Sale Tax Logs</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
            </ul>
        )
    }

    getServiceProvide = () => {
        let { user } = this.props
        return (
            <ul className="at_account_mainul">
                <li>
                    <Link to={routes.account.editProfile}><span><i className="icon-user-2"></i></span><h4>Edit Account</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.subscription}><span><i className="icon-subscription"></i></span><h4>Subscription</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {user.packageID && ((user.packageID as ISubscriptionPackage).package_name === ESubPakcges.Premium || (user.packageID as ISubscriptionPackage).package_name === ESubPakcges.Professional) && <li>
                    <Link to={routes.account.inPlatformWebsite}><span><i className="icon-globe"></i></span><h4>In-Platform Website </h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                <li>
                    <Link to={routes.account.services.professional.index}> <span><i className="icon-document"></i></span><h4>Professional Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                {user.roles.includes(ERole.PROMOTER) && <li>
                    <Link to={routes.artCentral.gelleryWalk.create}><span><i className="icon-gallery-walk"></i></span><h4>Gallery Walks & Art Events</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}
                {user.roles.includes(ERole.AUCTIONEER) && <li>
                    <Link to={routes.account.services.auction.add}><span><i className="icon-auction"></i></span><h4>Live Art Auction Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>}

                <li>
                    <Link to={routes.account.services.printingAndFraming.index}> <span><i className="icon-frame"></i></span><h4>Printing &amp; Framing Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.services.curatorialServices.index}> <span><i className="icon-curatorial"></i></span><h4>Curatorial Services</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>

                <li>
                    <Link to={routes.account.transactions}><span><i className="icon-transaction"></i></span><h4>Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.services.professional.transactions}><span><i className="icon-transaction"></i></span><h4>Service Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.services.printingAndFraming.transactions}><span><i className="icon-transaction"></i></span><h4>Printing {`&`} Framing Transactions</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
                <li>
                    <Link to={routes.account.funds}> <span><i className="icon-funds"></i></span><h4>Manage Funds</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                </li>
            </ul>
        )
    }
    getUserAccountPages = () => {
        let { user } = this.props
        let isNotGuestUser = !(user?.roles && (user?.roles?.length === 0 || user?.roles[0]?.length === 0))
        if (user.roles.includes(ERole.ARTIST)) {
            return this.artistPages()
        }
        if (user.roles.includes(ERole.AGENT)) {
            return this.agentPages()
        }
        if (user.roles.includes(ERole.CONSULTANT)) {
            return this.getServiceProvide()
        }
        // if (!isNotGuestUser) {
        //     return this.guestUser()
        // }

        // if (user.roles.includes(ERole.PRIVATE_ART_BUYER) || user.roles.includes(ERole.PROFESSIONAL_ART_BUYER)) {
        //     return this.guestUser()
        // }
        return this.guestUser()
    }
    render() {
        let { user } = this.props
        return (
            <main id="at-main" className="at-main at-haslayout">
                <div className="at-createaccount scroll-style-3 at-contentarea-two at_pad_lr_0">
                    <div className="at-themehead">
                        <a onClick={() => this.props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                        <h2>Account</h2>
                    </div>
                    <div className="at_theme_innercontent">
                        {user && user._id && this.getUserAccountPages()}
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(AccountPage);