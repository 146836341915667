import { AxiosResponse } from 'axios'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { images } from '../../../assets/image'
import { ITransaction, IUser } from '../../../interfaces'
import { transactionService } from '../../../services'
import { forEach } from 'lodash'
import { CurrencyFormat } from '../../common/CurrencyFormat'
interface IProps {
    user: IUser
}
export const SalesSummary = ({ user }: IProps) => {

    const [taxData, setTaxData] = useState<any>()
    const [selectedYear, setSelectedYear] = useState<string>('')
    const [selectedMonth, setSelectedMonth] = useState<string>('')
    const [renderData, setRenderData] = useState<Array<any>>()

    const getMonths = () => {
        let monthArray = moment.months().map((item: any) => {
            return {
                value: item,
                label: item
            }
        })

        return monthArray
    }

    const getYears = () => {
        let currentYear = new Date('2020').getFullYear()
        let yearDiff = new Date().getFullYear() - currentYear
        if (yearDiff > 0) {
            let year = []
            let object = { value: '', label: '' }
            for (let i = 0; i <= yearDiff; i++) {
                object.value = `${currentYear + i}`
                object.label = `${currentYear + i}`
                year.push(object)
            }
            return year
        }

        return [{ value: `${currentYear}`, label: `${currentYear}` }]
    }

    const getTaxData = () => {
        let dataString = `${selectedMonth}-${selectedYear}`
        let dataArray = []

        for (let item in taxData) {
            if (item.includes(dataString)) {
                dataArray.push({
                    name: item.replace('-', ' '),
                    value: taxData[item]
                })
            }
        }
        setRenderData(dataArray)
    }


    useEffect(() => {
        transactionService.getSaleTaxSummary(user?._id).then((res: AxiosResponse<any>) => {
            setTaxData(res.data)
        })
    }, [])

    useEffect(() => {

        if (selectedYear || selectedMonth) {
            getTaxData()
        }

    }, [selectedMonth, selectedYear])

    const taxRendering = () => {
        return renderData?.map((item: any, index: any) => {
            let key = Object.keys(item.value)
            return (
                <div key={index} className="at-salestaxsummaryholder">
                    <div className="at-addactionholder">
                    <ul>
                        <li>
                    <div className="at-summarydetailcontent">
                        <p>{`${item.name} Total: `}<span><CurrencyFormat amount={item.value.total} /></span></p>
                    </div>
                    </li>
                    </ul>
                    </div>
                    {key && key.length > 0 && key.map((keyItem: string, ind: any) => {
                        return (
                            keyItem !== 'total' && <div key={ind} className="at-summarydetailcontent pt-2">
                                <p className="pl-2">{`${keyItem} Total`}:<span><CurrencyFormat amount={item.value[keyItem]} /></span></p>
                            </div>
                            //  <div className="at-summarydetailcontent pt-2">
                            //     <p>Colorado Total:<span>$125.00</span></p>
                            // </div>
                            // <div className="at-summarydetailcontent pt-2">
                            //     <p>New York Total:<span>$200.00</span></p>
                            // </div> 
                        )
                    })
                    }
                </div>
            )
        })
    }

    let months = getMonths()
    let years = getYears()
    return (
        <div className="at-formtheme at-formsummary at-transaction-main">
            <div>
                <div className="at-twocols">
                    <div className='form-group'>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={false}
                            name="months"
                            options={months}
                            onChange={(e: any) => setSelectedMonth(e.value)}
                        />
                        {/* {errorElement('user')} */}
                    </div>
                    <div className='form-group'>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={false}
                            name="years"
                            options={years}
                            onChange={(e: any) => setSelectedYear(e.value)}
                        />
                        {/* {errorElement('user')} */}
                    </div>
                </div>
            </div>
            {renderData && renderData.length > 0 ? (
                taxRendering()
            ) : (null)}
        </div>
    )
}