import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { images } from '../../../../assets/image'
import { PLATFORM_FEE } from '../../../../environment'
import { ICommissionResponse, IMilestone, IUser } from '../../../../interfaces'
import { commissionWorkService, paymentService, toastService } from '../../../../services'
import { collectionImage } from '../../../../shared'
import { AddressPopup } from '../../../common/AddressPopup'
import { CurrencyFormat } from '../../../common/CurrencyFormat'
import LoadingComponent from '../../../common/Loading.component'
import { MessageModal } from '../../../common/Modals/MessagePopup'
import { PayPopup } from '../../../common/Modals/PayPopup'
import {ProposalImageView} from '../../../common/ProposalImageView'

interface IProps {
    item: ICommissionResponse,
    user: IUser
    refresh: Function
}
export const ProposalLayout = ({ item, user, refresh }: IProps) => {
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [showAddressBox, setShowAddressBox] = useState<boolean>(false)
    const [selectedMilstone, setSelectedMilestone] = useState<IMilestone>()
    const [tax, setTax] = useState<any>()
    const [inValidAddress, setInValidAddress] = useState<boolean>(false)
    const [showPayBox, setShowPayBox] = useState<boolean>(false)
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [stripeData, setStripeData] = useState<any>()
    const [last, setLast] = useState<boolean>(false)
    const openMessageBox = () => {
        setShowMessageBox(true)
    }

    const onCloseMessageBox = () => {
        setShowMessageBox(false)
    }

    const onSubmitMessage = (data: any) => {
        setMessage(data)
        commissionWorkService.rejectProposal({ proposalID: item.proposalID.id, message: data }).then((res: AxiosResponse<any>) => {

            if (res.data.status === 'Success') {
                toastService.success('Proposal has been rejected')
            }
            setShowMessageBox(false)
        }).catch(err => console.log(err))
    }

    const OpenAddressBox = (m: IMilestone) => {
        let check = item.proposalID.milestone.filter((i: IMilestone) => i.status !== 'paid')
        // item.proposalID.milestone.forEach((i: IMilestone, index: any) => {
        //     if (m._id === i._id) {
        //         if (index === (item.proposalID.milestone.length - 1) && check && check.length === (item.proposalID.milestone.length - 1)) {
        //             setLast(true)
        //         }
        //     }
        // })
        if(check && check.length === 1){
            setLast(true)
        }
        setSelectedMilestone({ ...m })
        setShowAddressBox(true);
    }

    const onCloseAddressBox = () => {
        let obj: any = {}
        setSelectedMilestone(obj)
        setShowAddressBox(false);
    }

    const onSubmitAddressBox = (address: any) => {
        let price = last ? (Number(selectedMilstone?.payment) + Number(item.proposalID.shipping) + Number(PLATFORM_FEE)).toFixed(2) : selectedMilstone?.payment
        setLoading(true)
        paymentService.taxPayment({
            state: address.state,
            address: address.shippingAddress,
            price: price,
            sellerID: item.offeredTo._id,
            zip: address.zipcode,
            city: address.city
        }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'success') {
                if (!res.data.total_tax || isNaN(res.data.total_tax)) {
                    setInValidAddress(true)
                    return
                }
                setTax(`${res.data.total_tax}`)
                openPayBox()
            }
            if (res.data.status === 'Fail') {
                setInValidAddress(true)
                return
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
        setShowAddressBox(false);
    }

    const openPayBox = () => {
        setShowPayBox(true)
    }

    const onClosePaybox = () => {
        let obj: any = {}
        setSelectedMilestone(obj)
        setShowPayBox(false)
    }

    const onSubmitPaybox = () => {
        let obj: any = {}
        setSelectedMilestone(obj)
        setShowPayBox(false)
        setShowPaymentForm(false)
        setLoading(true)
        setTimeout(() => {
            refresh()
            setLoading(false)
        },4000)
        
    }

    const onConfirmPay = () => {
        handlePayment()
    }

    const handlePayment = () => {
        let data = {
            milestoneID: selectedMilstone?._id,
            proposalID: item.proposalID.id,
            tax: tax,
            price: selectedMilstone?.payment,
            last: last,
            itemName: `Commission Work ${selectedMilstone?.name} payment`
        }
        setLoading(true)
        paymentService.milestoneCheckout({ ...data }).then((res: AxiosResponse<any>) => {
            if (res.data.success) {
                // toastService.success(res.data.message)
                setStripeData({ clientSecret: res.data.client_secret })
            }
            setLoading(false)
            setShowPaymentForm(true)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const onApprove = () => {
        setLoading(true)
        commissionWorkService.approveProposal({ proposalID: item.proposalID.id }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success('Proposal approved successfully.')
                refresh()
            }
            setShowMessageBox(false)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const getButtonStatus = (item: ICommissionResponse) => {
        switch (item.proposalID.status) {
            case 'pending':
                return { class: '', text: 'Approve Proposal' }
            case 'approved':
                return { class: 'at-btn-approved', text: 'Approved' }
            case 'paid':
                return { class: 'at-btn-approved', text: 'Paid' }
            default:
                return { class: '', text: 'Approve Proposal' }
        }
    }
    return (
        <div key={item.id} className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-rejectaccept-proposal">
                    <h5><span>Project Pictures</span></h5>
                    <figure className='at-figure-rejectaccept'>
                        <ProposalImageView item={item.proposalID} />
                        {/* <img src={collectionImage(item.itemId, `high_${item.itemId.url}`, true)} className="img-fluid" alt="" /> */}
                    </figure>
                    {item.itemId && <h5 className="mb-0"><span className="at-color-grey">Art Name: </span>{item.itemId.name}</h5>}
                    <h5 className="mb-0"><span className="at-color-grey">Description: </span><span className="at-texttransf-none">{item.proposalID.description}</span></h5>
                    <ul className="d-flex mt-2">
                        <li className="mr-3"><h5><span className="at-color-grey">Price: </span><CurrencyFormat amount={item.proposalID.budget} /></h5></li>
                        <li><h5><span className="at-color-grey">Shipping: </span><CurrencyFormat amount={item.proposalID.shipping} /></h5> </li>
                    </ul>
                    <div className="at-transfer-amount at-transfer-amount-threecol at-haslayout mt-1">
                        <div className="at-transfer-upper">
                            <h5 className="at-text-underline">Payment Schedule</h5>
                            {item.proposalID.milestone && item.proposalID.milestone.length > 0 && item.proposalID.milestone.map((m: IMilestone) => {
                                return (
                                    <div className="row align-items-center">
                                        <div className="col-5 at-custcol-4">
                                            <h4>
                                                {m.name}
                                            </h4>
                                        </div>
                                        <div className="col-4 at-custcol-4">
                                            <h5 className="text-center"><CurrencyFormat amount={m.payment} /></h5>
                                        </div>
                                        {m.status === 'requested' && <div className="col-3 at-custcol-4">
                                            <button className="at-btn at-paybtn" disabled={loading} onClick={() => OpenAddressBox(m)}>{loading ? 'Wait...' : 'PAY'}</button>
                                        </div>}
                                        {m.status === 'paid' && <div className="col-3 at-custcol-4">
                                            <p className="at-color-orange">PAID</p>
                                        </div>}
                                    </div>
                                )
                            })}

                            {/* item row */}
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="row at-rejectaccept-btns row-margin-lr7">
                        <div className="col-6 pad-lr-7">
                            <button className="at-btn at-btn-sm at-btn-grey at-minwidth-auto" disabled={item.proposalID.status !== 'pending'} onClick={() => openMessageBox()}>Reject Proposal</button>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <button className={`at-btn at-btn-sm at-minwidth-auto ${getButtonStatus(item).class}`} disabled={loading ? true : item.proposalID.status === 'pending' ? false : true } onClick={() => onApprove()}>{loading ? 'Please wait...' : getButtonStatus(item).text}</button>
                        </div>
                    </div>
                </div>
            </div>
            {showMessageBox && <MessageModal message={message} open={showMessageBox} onClose={onCloseMessageBox} onSubmit={onSubmitMessage} />}
            {showAddressBox && <div className={`at-thankyou-popup at-themepopupholder ${showAddressBox && 'at-showthemesignuppopup'}`}><AddressPopup onClose={onCloseAddressBox} onSubmit={onSubmitAddressBox} user={user} inValidAddress={inValidAddress} /></div>}
            {showPayBox && <div className={`at-thankyou-popup at-themepopupholder ${showPayBox && 'at-showthemesignuppopup'}`}>
                <PayPopup loading={loading} message='' tax={tax} platformFee={last ? 10 : 0} offerPrice={selectedMilstone?.payment} item={item.proposalID} onClose={onClosePaybox} onConfirmPay={onConfirmPay} showPaymentForm={showPaymentForm} onSubmit={onSubmitPaybox} setDropinInstance={() => { }} stripeData={stripeData} hideOtherPay={!last} />
            </div>}
        </div>
    )
}
