import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import AboutPage from '../components/AboutPage'
import ContactPage from '../components/ContactPage'
import HomePage from './Home'
import { routes } from './routes'
import '../assets/templates.scss'
import { useSelector } from 'react-redux'
import { authSelector, inPlatformSelector, templateSelector } from '../../store/selectors'
import { HttpService } from '../../services/base.service'
import PrivateRoutes from '../components/PrivateRoutes'
import { TemplateContainer } from '../assets/_global-template-style'
import GalleryPage from './GalleryPage'
import ArtDetailPage from './ArtDetailPage'
import PortalComponent from '../../components/common/Portal.component'
import { ERole } from '../../enums'
import AccessProfessionalServiceTepmlate from './Service.page'

interface IProps {
    name: string
}
const MainRoute = ({ name }: IProps) => {
    const location = useLocation()
    const auth = useSelector(authSelector).access_token
    HttpService.setToken(auth)
    const template = useSelector(templateSelector)
    const inPlatform = useSelector(inPlatformSelector)
    let isServiceProvicer = template.user.roles.includes(ERole.CONSULTANT)
    // let checkRoute = location.pathname.includes(routes(template.url).gallery.index) || location.pathname.includes(routes(template.url).exhibitions.index)

    return (
        <div className={`At-TemplateWrap ${inPlatform.activeSplitScreen ? "At-PortalEnabled" : ''}`}>
            <TemplateContainer templateBackground_Color={template.backgroundColor || '#fff'} lineColor={template.lineColor || '#eaedf1'} textColor={template.textColor} className="At-Theme">

                <Header />
                <Switch>
                    <Route path={routes(name).home} exact component={HomePage} />
                    <Route path={routes(name).about} exact component={AboutPage} />
                    <Route path={routes(name).Contact} exact component={ContactPage} />
                    <PrivateRoutes>
                        <Route path={routes(name).gallery.index} exact component={isServiceProvicer ? AccessProfessionalServiceTepmlate : GalleryPage} />
                        <Route path={`${routes(name).gallery.collectionItemView}/:id`} exact component={ArtDetailPage} />
                    </PrivateRoutes>
                </Switch>
                <Footer />
                {inPlatform.activeSplitScreen && <PortalComponent />}
            </TemplateContainer>
        </div>
    )
}

export default MainRoute