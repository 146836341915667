/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, Fragment, useCallback } from 'react'
import Modal from 'react-modal'
import { IPost } from '../../interfaces'
import CropImageComponent from './CropImage.component'

interface IImageDetails {
	display: boolean
	crop?: boolean
	details: {
		original: string
		cropped?: string
		_id?: string
		extra?: IPost
	}
}

interface IProps {
	onClose: Function
	onCancel: () => any
	image: IImageDetails
}

const ImageModalComponent = (props: IProps) => {
	Modal.setAppElement('#at-main')

	const { onClose, image } = props

	const [isModalOpen, setIsModalOpen] = useState(true)
	const [croppedImg, setCroppedImg] = useState(image.details.original)

	const afterOpenModal = () => {}

	const closeModal = () => {
		setIsModalOpen(false)
		if (image.crop) {
			onClose({ original: image.details.original, cropped: croppedImg })
		} else {
			onClose(undefined)
		}
	}

	const onCropComplete = useCallback((image: string) => {
		setCroppedImg(image)
	}, [])

	const customStyles: ReactModal.Styles = {
		content: {
			width: 500,
			height: 700,
		},
	}
	const styles = {...(document.getElementById('at-main') as any).getBoundingClientRect()}
	return (
		<Fragment>
			<Modal
				isOpen={isModalOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={{content: {...styles}}}
				contentLabel='Image modal'
				className="at-themeholder br-0"
			>
				{image.crop ? (
					<CropImageComponent
						image={image.details.original}
						onCropComplete={onCropComplete}
					/>
				) : (
					<img src={image.details.original} className='post-image' />
				)}
				<button onClick={closeModal} className='at-close' style={{right: '55px', background: 'green'}}>
					<i className='icon-tick text-white'></i>
				</button>
				<button onClick={props.onCancel} className='at-close btn-primary'>
					<i className='icon-cancel'></i>
				</button>
				<div className='img-holder'></div>
			</Modal>
		</Fragment>
		// 		<button onClick={closeModal} className="at-close" ><i className="icon-cancel"></i></button>
		// 		<div className='img-holder'>
		// 		<img src={image.details.url} className='post-image' />
		// 		</div>

		// 	</Modal>
		// </div>
	)
}

export default ImageModalComponent
