import React, { useEffect, useState, FormEvent } from 'react'
import { AxiosResponse } from 'axios'
import { connect, useSelector } from 'react-redux'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { UserNameComponent, UserPicComponent } from '../../components/User/index.component'
import { ICollectionItem, ICommissionResponse, IStoreReducers, IUser } from '../../interfaces'
import { collectionService, } from '../../services/collection.service'
import { commissionWorkService, toastService } from '../../services'
import { collectionImage } from '../../shared'
import { CurrencyField } from '../../components/common/CurrencyInput'
import { ERole } from '../../enums'
import { templateSelector } from '../../store/selectors'
import { IResponse } from '../../interfaces/db.interface'

interface IProps {
    art: string,
    artUser: IUser
    user: IUser
}
interface IState {
    additionalInformation: string,
    budget: string
    deadline: string
}
const TemplateRequestCommisionWork = (props: IProps) => {

    const [art, setArt] = useState<ICollectionItem>()
    const [requestState, setRequestState] = useState<IState>({
        additionalInformation: '',
        budget: '',
        deadline: ''
    })
    const template = useSelector(templateSelector)

    const location = useLocation().state as any
    const [loading, setLoading] = useState<boolean>(false)

    let artUser = (art?.art_collection.user as IUser)

    useEffect(() => {
        fecthItem()
    }, [])

    const fecthItem = () => {
        collectionService.showItem(props.art).then((res: AxiosResponse<IResponse<ICollectionItem>>) => {
            setArt({ ...res.data.data })
        }).catch(err => console.log(err))
    }

    const onChangeText = (e: FormEvent<EventTarget>) => {
        let { name, value } = (e.target as HTMLInputElement)
        if (value && name === 'budget') value = value.split("$")[1].replace(/,/gi, '')
        setRequestState({
            ...requestState,
            [name]: value
        })
    }

    const onSubmitRequest = (e: FormEvent) => {
        e.preventDefault()
        let sellerData: any = setSellerId()

        let reqData = {
            ...requestState,
            offeredBy: props.user._id,
            offeredTo: artUser._id,
            ...sellerData,
            itemId: art?.id
        }
        setLoading(true)
        commissionWorkService.requestCommission(reqData).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                if (res.data.status === 'Fail') {
                    toastService.error(res.data.message)
                } else {
                    toastService.success(res.data.message)
                }
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }

    const setSellerId = () => {
        let agent = art?.assignment && art.assignment.user ? (art.assignment.user as IUser) : null
        let studioUser = template.user

        if (!agent) {
            return { sellerId: artUser._id, agentId: null, artistId: artUser._id }
        }
        if (art?.assignment?.type === 'non-exclusive' && studioUser && !studioUser?.roles.includes(ERole.AGENT)) {
            return { sellerId: artUser._id, agentId: null, artistId: artUser._id }
        }
        if (art?.assignment?.type === 'non-exclusive' && studioUser?._id === agent._id) {
            return { sellerId: agent._id, agentId: agent._id, artistId: artUser._id }
        }
        if (!art?.assignment) {
            return { sellerId: artUser._id, agentId: null, artistId: artUser._id }
        }

        return { sellerId: agent._id, agentId: agent._id, artistId: artUser._id }
    }

    return (
        <div className="at-collectiondetailholder scrollable at-contentarea">
            <div className="at-orangeeconhead">
                {/* <a onClick={props.history.goBack} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a> */}
                {artUser && <UserPicComponent user={artUser} />}
                <div className="at-orangeeconcontent">
                    {props.user.isDeaf && <span className="at-postcontenticon"><i className="icon-hand"></i></span>}
                    <h3 className="text-left">
                        {art && art.art_collection && art.art_collection.title}
                        {/* {isAgentView ? props.user.gallery_name : artCollection.title} */}
                        {artUser && <UserNameComponent user={artUser} />}
                    </h3>
                    <div className="at-btniconholder">
                        {/* Remove from SprintONE */}
                        {/* <a id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a>
                        <a className="at-btnheart at-postbtn cursor-pointer">
                            <i className={`icon-favorite`}></i>
                        </a> */}

                        {/* {
                            (!hideMoreButton && artCollection.user && artCollection.user._id === userId && isArtist(artCollection.user)) && (
                                <a className="at-btnmore at-postbtn" onClick={this.openAction}><i className="icon-more"></i></a>
                            )
                        } */}
                    </div>
                </div>
            </div>
            <form className="at-formtheme at-formcreatepost border-bottom-0 text-left" onSubmit={onSubmitRequest}>
                <fieldset>
                    <div className="at-commissionbox">
                        <div className="at-commbox-top">
                            <h4>Commission Work Request</h4>
                            <p>Please use the Art below as inspiration for the commission work:</p>
                        </div>
                        <div className="at-figure-box at-figurebox-fullgreybg d-flex">
                            {art && <figure>
                                <img src={collectionImage(art, `high_${art?.url}`, true)} className="img-fluid" alt="" />
                            </figure>}
                            <div className="at-figure-text">
                                <h4> {artUser && <UserNameComponent user={artUser} />}</h4>
                                <h6>{art?.name}</h6>
                                <p>
                                    {art?.description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group at-floatlabel mt-3">
                                <textarea name="additionalInformation" placeholder=" " className="form-control" value={requestState.additionalInformation} onChange={onChangeText} ></textarea>
                                <label>Additional Information</label>
                            </div>
                        </div>

                    </div>
                    <div className="row row-margin-lr7">
                        <div className="col-6 pad-lr-7">
                            <div className="form-group at-floatlabel-h40">
                                <CurrencyField
                                    id="input-example-price"
                                    className="form-control"
                                    name="budget"
                                    placeholder=" "
                                    prefix="$"
                                    value={requestState.budget}
                                    onChange={onChangeText}
                                />
                                <label>Budget</label>
                            </div>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <div className="form-group at-floatlabel-h40">
                                <input
                                    type='text'
                                    name='deadline'
                                    className='form-control'
                                    placeholder=" "
                                    onChange={onChangeText}
                                    value={requestState.deadline}
                                />
                                <label>Deadline (weeks)</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" disabled={loading} className="at-btn at-minwidth-100imp" >{loading ? 'Please wait...' : 'Send Request'}</button>
                    </div>

                </fieldset>
            </form>

        </div>

    )
}


export default TemplateRequestCommisionWork