import { HttpService } from "./base.service";
import { IUser } from "../interfaces";
import { ERole } from "../enums";

class UserService extends HttpService {

    prefix: string = 'account';

    account = (): Promise<any> => this.get(`${this.prefix}/me`)


    /**
     * Like and Unlike User
     */
    likeUnlike = (id: string, status: boolean): Promise<any> => this.put(`user/like-unlike/${id}/${status ? 1 : 0}`)

    changeActiveSpace = (spaceId: string) => this.put('user/active-space', { spaceId })

    /**
     * Agents List
     */
    agents = () => this.get('user/agents')

    /**
     * Get Users List
     */
    getUsersByID = (ids: Array<string> | string, first = false) => this.get('user/find-users-by-ids', { ids, first });

    /**
     * User Alert
     */

    sendUserAlert = (data: any): Promise<any> => this.post(`user/user-alert`, data)

    /**
     * Admin Alert
     */

    sendAdminUserAlert = (data: any): Promise<any> => this.post(`user/admin-chat-alerts`, data)

    /**
     * Multiple User Message
     */

    multiUserMessage = (data: any): Promise<any> => this.post(`user/multi-user-alerts`, data)

    /**
     * Update User Roles
     * @param id
     */

    addUserSUbRole = (id: string, data: any): Promise<any> => this.put(`user/add-sub-roles/${id}`, data)
    /**
     * Update User Roles
     * @param id
     */

    removeUserSUbRole = (id: string, data: any): Promise<any> => this.put(`user/remove-sub-roles/${id}`, data)


    /**
     * User List
     * @param data
     */
    userList = (data: any): Promise<any> => this.get(`user/user-list`, data)
    
    /**
     * Update User Logout Status
     * @param data
     */
    userUpdateManualLogout = (): Promise<any> => this.put(`user/manual-logout-cache-clear-status`)
}

export const userService = new UserService();

export const isAgent = (user: IUser) => user?.roles?.includes(ERole.AGENT)
export const isArtist = (user: IUser) => user?.roles?.includes(ERole.ARTIST)

export const activatePost = (user: IUser) => ((user.assignment_collections || []).length > 0) || ((user.collections || []).length > 0);
export const activateRolePost = (user: IUser) => (user?.roles?.includes(ERole.AGENT) || user?.roles?.includes(ERole.ARTIST) || user?.roles?.includes(ERole.COLLECTOR) || user.roles.includes(ERole.CONSULTANT));