import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { images } from '../../assets/image'
import { ECuratorialOfferStatus, EMileStoneStatus } from '../../enums'
import { ICuratorialProposalArtsDocument, IMilestone, INotification } from '../../interfaces'
import { routes } from '../../router'
import { userSelector } from '../../store/selectors'
import { CurrencyFormat } from '../common/CurrencyFormat'
import { CurrencyField } from '../common/CurrencyInput'
import { SeeOfferProposalArtsComponent } from '../User/Services/CuratorialServices/Offer/SeeOfferProposalArts.component'


interface IProps {
    n: INotification
}
export const CuratorialOfferRequestPaymentNotification = ({ n }: IProps) => {

    const history = useHistory()
    let { curatorialService, curatorialOffer } = n

    const user = useSelector(userSelector)
    const onSeeProposal = () => {
        if (curatorialOffer.curatorialUser._id === user._id) {
            history.push({ pathname: `${routes.account.services.curatorialServices.index}`, state: { page: 'pay' } })
            return
        }
        history.push({ pathname: `${routes.account.services.curatorialServices.participantCuratorialProject}`, state: { page: 'offer' } })
    }
    return (
        <div className="at-transaction-main">
            <div className="at-transaction-wrap">
                <div className="at-transaction-box">
                    <div className="at-curatorial-wrap">
                        <div className="row at-row-mlr-3 at-curatorial-format ">
                            <div className="col-12 mb-3">
                                {curatorialService && curatorialService._id && <h5 ><span className="at-color-grey">Project Name:</span> {curatorialService.name}</h5>}
                                {/* {curatorialOffer && curatorialOffer._id && <div className="mt-3">
                                    <p dangerouslySetInnerHTML={{ __html: curatorialOffer.information }}>
                                    </p>
                                </div>} */}
                            </div>

                        </div>
                        {/* row ends */}

                        {/* <div className="row at-row-mlr-3 at-curatorial-format at-row-fs12">
                            <div className="col-2">
                                <h6 className="at-color-grey">Sky in the Night</h6>
                            </div>
                            <div className="col-10">
                                <div className="row at-row-mlr-3 at-row-underline">
                                    <div className="col-4">
                                        <h6 className="at-color-grey">Format</h6>
                                    </div>
                                    <div className="col-2 at-textcenter">
                                        <h6 className="at-color-grey">Qty</h6>
                                    </div>
                                    <div className="col-3 at-textcenter">
                                        <h6 className="at-color-grey">Your Proposal Price</h6>
                                    </div>
                                    <div className="col-3 at-textcenter">
                                        <h6 className="at-color-grey">Our Offer</h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {curatorialOffer.proposalArts && curatorialOffer.proposalArts.length > 0 && curatorialOffer.proposalArts.map((pA: ICuratorialProposalArtsDocument, index: number) => {
                            return (<SeeOfferProposalArtsComponent proposalArt={pA} key={index} />)
                        })
                        }

                        {/* outer row ends */}

                        <div className="row at-row-mlr-3 at-curatorial-format at-row-fs12">
                            <div className="col-12">
                                <div className="row at-row-mlr-3 mt-2">
                                    <div className="col-4">
                                    </div>
                                    <div className="col-2 at-textcenter">

                                    </div>
                                    <div className="col-3 at-textcenter">
                                        <hr className="At-Hr5 at-opacity-0" />
                                        <h5 className="at-fontbold">Total Offer</h5>
                                    </div>
                                    <div className="col-3 at-textcenter">
                                        <hr className="At-Hr5" />
                                        <h5><CurrencyFormat amount={Number(curatorialOffer.totalOfferPrice)} /></h5>
                                    </div>
                                </div>
                                {/* inner row ends */}
                            </div>
                        </div>
                        {/* outer row ends */}
                        {curatorialOffer.milestone && curatorialOffer.milestone.length > 0 && curatorialOffer.milestone.map((m: IMilestone, index: number) => {
                            return (
                                <div className="row at-row-mlr-3 at-curatorial-format mt-4 align-items-center">
                                    <div className="col-6">
                                        <h6 className="at-color-grey">{m.name}</h6>
                                    </div>
                                    <div className="col-3 at-textcenter">
                                        <h6><CurrencyFormat amount={Number(m.payment)} /></h6>
                                    </div>
                                    {curatorialOffer.offerStatus === ECuratorialOfferStatus.ACCEPTED && <div className="col-3 at-textcenter">
                                        {m.status === EMileStoneStatus.PAID && <h6 className="at-color-orange at-fs12">Paid</h6>}
                                        {m.status === EMileStoneStatus.PENDING && <h6 className="at-color-orange at-fs12">Request Payment</h6>}
                                        {m.status === EMileStoneStatus.REQUESTED && <h6 className="at-color-orange at-fs12">Requested</h6>}
                                    </div>}
                                </div>
                            )
                        })}
                        {/* row ends */}
                        {/* row ends */}
                        <div className="row at-row-mlr-3 at-curatorial-format">
                            <div className="col-6 at-textcenter">
                                <hr className="At-Hr5 at-opacity-0" />
                                <h6 className="at-fontbold">Total Offer</h6>
                            </div>
                            <div className="col-3 at-textcenter">
                                <hr className="At-Hr5" />
                                <h6><CurrencyFormat amount={Number(curatorialOffer.totalOfferPrice)} /></h6>
                            </div>
                            <div className="col-3">
                            </div>
                        </div>
                        {/* inner row ends */}
                        <div className="row at-rowprinting at-row-mlr-3">
                            <div className="col-sm-6 offset-sm-3 mt-4">
                                <button className="at-btn at-btn-lightgreen at-smallbtn-h40-w100" onClick={() => onSeeProposal()}>{`See Offer`}</button>
                            </div>
                        </div>
                        {/* row ends */}
                    </div>
                </div>
            </div>
        </div>
    )
}