import React, { useState, memo } from 'react'

interface IProps {
    onSingleClick?: any
    onDoubleClick?: any
    onTripleClick?: any
    children?: any
}

var timeout: any;
const MultiClickComponent = (props: IProps) => {
    const [state, setState] = useState(1);

    const click = () => {
        setState(state+1);
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            triggerEvent()
        }, 300);
    }

    const triggerEvent = () => {
        
        if (state === 3 && props.onTripleClick) {
            props.onTripleClick();
        } else if (state === 2 && props.onDoubleClick) {
            props.onDoubleClick();
        } else if (state === 1 && props.onSingleClick) {
            props.onSingleClick();
        } else {

        }
        setState(1);
    }

    return (
        <span onClick={click}>
            { props.children }
        </span>
    )
}

export default memo(MultiClickComponent)