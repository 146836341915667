import React from 'react'
import { ISubmissionProcessState } from '../../../interfaces/submission-process.interface'
import { ErrorComponent } from '../../common/Error.component'

interface IProps {
    state: ISubmissionProcessState
    onChangeText: Function
    errors: any
}
export const StepTwo = ({ state, onChangeText, errors }: IProps) => {
    return <div>
        <h5 className="at-stepheading text-left">Please enter a link to your website and Instagram account. If not available, please enter “none”.</h5>
        <div className="row row-margin-lr7 align-items-center">
            <div className="col-12 pad-lr-7 at-formgroup-outer">
                <div className="form-group at-floatlabel-h40 mt-3">
                    <input type="text" name="webUrl" className="form-control" value={state.webUrl} placeholder=" " onChange={(e: any) => onChangeText(e)} />
                    <label>Website Link</label>
                    {errors && errors['webUrl'] && <ErrorComponent errors={errors['webUrl']} multiple={false} />}
                </div>
            </div>
            <div className="col-12 pad-lr-7">
                <div className="form-group at-floatlabel-h40">
                    <input type="text" name="instagramUrl" className="form-control" value={state.instagramUrl} placeholder=" " onChange={(e: any) => onChangeText(e)} />
                    <label>Instagram Account Link</label>
                    {errors && errors['instagramUrl'] && <ErrorComponent errors={errors['instagramUrl']} multiple={false} />}
                </div>
            </div>
        </div>
    </div>
}