import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { IResponse } from '../../interfaces/db.interface'
import { IServiceTransaction } from '../../interfaces/services.interface'
import { toastService } from '../../services'
import { consultantService } from '../../services/consultantServices.service'
import { ConfirmAlertComponent } from '../common/ConfirmAlert.component'
import LoadingComponent from '../common/Loading.component'
import { ServiceTransactionlayout } from '../User/Account/ServiceTransactionLayout'
import { AdminServiceTransactionlayout } from './AdminServiceTransactionLayout'

interface IProps {

}
const AdminServiceTransaction = () => {
    const history = useHistory()
    const [transactions, setTransactions] = useState<Array<IServiceTransaction>>([])
    const [loading, setLoading] = useState<boolean>(false)

    const [showConfirmBox, setConfirmBox] = useState<boolean>(false)
    const [selectedTransaction, setSelectedTransaction] = useState<IServiceTransaction>()

    const fetchTransaction = () => {
        setLoading(true)
        consultantService.getAdminTransactions().then((res: AxiosResponse<IResponse<Array<IServiceTransaction>>>) => {
            if (res.data.success) {
                let rev = [...res.data.data].reverse()
                setTransactions([...rev])
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchTransaction()
    }, [])

    const onOpenConfirmBox = (item: IServiceTransaction) => {
        setSelectedTransaction(item)
        setConfirmBox(true)
    }

    const onCloseConfirmBox = () => {
        let object: any = {}
        setSelectedTransaction(object)
        setConfirmBox(false)
    }
    const onSubmitConfirmBox = () => {
        setLoading(true)
        consultantService.releaseServiceFunds({ transactionId: selectedTransaction?._id }).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetchTransaction()
            } else {
                toastService.error(res.data.message)
            }
            onCloseConfirmBox()
            setLoading(false)
        }).catch((err: AxiosResponse) => {
            console.log(err)
            setLoading(false)
        })
    }
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Transactions</h2>
                </div>
                <div className="at-transaction-main">
                    {transactions?.length > 0 ? (
                        <>
                            {transactions?.map((item: IServiceTransaction) => (
                                <>
                                    <div key={item._id} className="at-transaction-wrap">
                                        <AdminServiceTransactionlayout loading={loading} onOpenConfirmBox={onOpenConfirmBox} t={item} />
                                    </div>
                                </>
                            ))}
                        </>
                    ) : (<>{loading && <LoadingComponent className='at-transaction-loader' />}</>)}
                </div>
            </div>
            {showConfirmBox && <ConfirmAlertComponent loading={loading} open={showConfirmBox} onClose={onCloseConfirmBox} onCancel={onCloseConfirmBox} onConfirm={onSubmitConfirmBox} message="Are you sure you want to release the fund." />}
        </main>
    )

}

export default AdminServiceTransaction