import { AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { OVERLAY_CLASS } from '../../../../../constants'
import { IAuctionDocument } from '../../../../../interfaces/auction.interface'
import { IResponse } from '../../../../../interfaces/db.interface'
import { routes } from '../../../../../router'
import { auctionService, toastService } from '../../../../../services'
import { postImage } from '../../../../../shared'
import LoadingComponent from '../../../../common/Loading.component'

interface IProps {

}
const AddAuctionComponent = ({ }: IProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [auctions, setAuctions] = useState<Array<IAuctionDocument>>([])
    const history = useHistory()


    const fetchAuctions = useCallback(() => {
        setLoading(true)
        auctionService.getUserAuctions().then((res: AxiosResponse<IResponse<Array<IAuctionDocument>>>) => {
            setAuctions([...res.data.data])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        fetchAuctions()
    }, [fetchAuctions])

    const onDeletingAuction = (a: IAuctionDocument) => {
        setLoading(true)
        auctionService.deteAuction(a._id).then((res: AxiosResponse<IResponse<Array<IAuctionDocument>>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetchAuctions()
            }
            setLoading(true)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    return (
        <div className="at-createcollection at-contentarea">
            <div className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon">
                        <i className="icon-left-arrow"></i>
                    </a>
                    <h2>
                        {/* {params.id ? "Update" : "Create"} */}
                        {"Add Live Auction Event"}

                    </h2>
                </div>
                <form
                    className="at-formtheme at-formcreatecollection"
                    noValidate
                >
                    <fieldset>
                        <div className="at-addpost" onClick={() => history.push(routes.account.services.auction.form)}>
                            <div className="at-addiconbox">
                                <i className="icon-add-bold"></i>
                            </div>
                            <h4 className="at-addpost-title">Add Event</h4>
                        </div>
                    </fieldset>
                </form>

                {auctions && auctions.length > 0 && <div className={`at-managepostholder ${loading && OVERLAY_CLASS}`}>
                    <div className="at-manageposttitle">
                        <h2>Manage Live Auction Events</h2>
                    </div>
                    <div className="row">
                        {
                            loading && <LoadingComponent />
                        }
                    </div>
                    <ul className="at-manageposts">
                        {
                            auctions.map((c: IAuctionDocument) => {

                                return (
                                    <li key={c._id}>
                                        {c.poster && <figure><img src={postImage(c._id, c.poster)} alt="" /></figure>}
                                        <div className="at-managepostcontent">
                                            <span className="mr-2">{c.name}<em>{moment(c.startDate).format('MM/DD/YYYY')}</em></span>
                                            <div className="at-btniconholder">
                                                <button className="at-btndelete">
                                                    {
                                                        <Link to={`${routes.account.services.auction.form}/${c._id}`}>
                                                            <i className="icon-edit2 mr-2" title="Edit Post"></i>
                                                        </Link>
                                                    }
                                                    <i className="icon-delete2 text-danger" title="Delete Post" onClick={() => onDeletingAuction(c)}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>}
            </div>
        </div>
    )
}

export default AddAuctionComponent