import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { useHistory } from 'react-router-dom';
import { EInvitationStatus } from '../../enums';
import { AWS_ENDPOINT } from '../../environment';
import { ICuratorialInvitationDocument, ICuratorialServiceDocument, INotification, IVideo } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface';
import { routes } from '../../router';
import { curatorialService, toastService } from '../../services';
import { collectionImage, getCuratorialProjectImages } from '../../shared'
import { ImageLoading } from '../common/ImageLoading';

interface IPorps {
    service: ICuratorialServiceDocument,
    n: INotification
}
export const CuratorialSendProposalBox = ({ service, n }: IPorps) => {

    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)


    // const onClickPdf = () => {
    //     if (!service.pdf) return
    //     let link = `${AWS_ENDPOINT}/pdf/${service.pdf}`
    //     window.open(link, "_blank");
    // }



    return (
        <div className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-rejectaccept-proposal">
                    <div className="at-new-thumbslider">

                        <ImageGallery
                            items={getCuratorialProjectImages(service)}
                            showFullscreenButton={true}
                            showPlayButton={false}
                            showNav={false}
                            // slideOnThumbnailOver={true}
                            renderItem={(item: any) => (
                                <div className="at-imagebox">
                                    <figure className="at-figure">
                                        <ImageLoading className="image-gallery-image" src={item.original} thumbnail={item.blurImage} />
                                    </figure>
                                </div>
                            )}
                        />
                    </div>
                    <h6 className="mb-0 text-left"><span className="at-color-grey">Project Name: </span>{service.name} </h6>
                    <ul className="d-flex mt-2 ">
                        <li className="mr-3 w-50">
                            <h6><span className="at-color-grey">Start Date: </span> <br />
                                <span>{moment(service.startDate).format('MM/DD/YYYY')}</span></h6>
                        </li>
                        <li className="w-50">
                            <h6><span className="at-color-grey">End Date: </span>
                                <br />
                                <span>{moment(service.endDate).format('MM/DD/YYYY')}</span></h6>
                        </li>
                    </ul>

                    {/* <div className="at-transfer-amount at-transfer-amount-threecol at-haslayout mt-1">
                    </div> */}
                    <div className="at-transfer-amount at-transfer-amount-threecol at-haslayout mt-1">
                        {/* <div className="at-transfer-upper text-center mt-2">
                        </div> */}


                        {n.curatorialInvitation && n.curatorialInvitation.curatorialProposal && <div className="at-transfer-upper text-center mt-2">
                            <button className="at-btn at-smallbtn-h40" onClick={() => history.push(`${routes.account.services.curatorialServices.seeProposal}/${n.curatorialInvitation.curatorialProposal._id}`)} >See Proposal</button>
                        </div>}


                        {n.curatorialInvitation.invitationStatus === EInvitationStatus.DECLINED && <div className="at-transfer-upper text-center mt-2">
                            <button className="at-btn at-smallbtn-h40 at-btn-red" disabled onClick={() => { }} >Declined</button>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    )

}