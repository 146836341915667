import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { ITutorials } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { tutorialsService } from '../../services'

interface IProps {
    screen: string
    onClick: Function
    iconName?: string
    text?: string
    hideText?: boolean
}
export const TutorialIcon = ({ screen, onClick, iconName, text, hideText }: IProps) => {
    const [tutorials, setTutorials] = useState<Array<ITutorials>>([])
    const fetchTutorials = () => {
        tutorialsService.getTutorials({ screen }).then((res: AxiosResponse<IResponse<Array<ITutorials>>>) => {
            let { data } = res
            setTutorials([...data.data])
        })
    }
    useEffect(() => {
        fetchTutorials()
    }, [])

    return (
        tutorials.length > 0 ? <><i className={`${iconName ? iconName : 'icon-video-tutorial'}`} onClick={() => onClick()}></i>{!hideText && <em>{text ? text : 'HELP'}</em>}</> : null
    )
}