import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IUser } from '../../interfaces'
import { ISubscriptionPackage } from '../../interfaces/subscription.interface'
import { subscriptionService, toastService } from '../../services'
import { refreshUser } from '../../store/actions'
import LoadingComponent from '../common/Loading.component'
import { SubscriptionPackage } from './SubscriptionPackage'

interface IProps {
    user: IUser
    onCLose?: any
}
export const SubscriptionComponent = ({ user, onCLose }: IProps) => {
    const [packages, setPackages] = useState<Array<ISubscriptionPackage>>()
    const [loading, setLoading] = useState<boolean>(false)

    const dispatch = useDispatch()

    const fetch = async () => {
        setLoading(true)
        await subscriptionService.getPackages(user.roles[0]).then((res: AxiosResponse<any>) => {
            let data = [...res.data].sort((a: any, b: any) => a.price > b.price ? 1 : -1)
            setPackages([...data])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        console.log("sskfnnfd")
        fetch()
    }, [user])


    const onBasicSubscriptionBuy = (p: ISubscriptionPackage) => {
        let data = {
            userId: user._id,
            packageId: p._id
        }
        setLoading(true)
        subscriptionService.onBuyBasic(data).then((res: AxiosResponse<any>) => {
            if (onCLose) {
                setLoading(false)
                onCLose(p.package_name)
                return
            }
            toastService.success(res.data.message)
            dispatch(refreshUser())
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onPaidSubscriptionBuy = (source: any, p: ISubscriptionPackage) => {
        // console.log("source:-=-=-=", source, "p:-=-=", p)
        let data = {
            userId: user._id,
            packageId: p._id,
            sourceToken: source.id
        }
        setLoading(true)
        subscriptionService.onBuyPaid(data).then((res: AxiosResponse<any>) => {
            if (onCLose) {
                setLoading(false)
                onCLose(p.package_name)
                return
            }
            toastService.success(res.data.message)
            dispatch(refreshUser())
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <div className="at-roundcontainer clearfix">
            {packages?.map((p: ISubscriptionPackage, index: any) => {
                return (
                    <SubscriptionPackage key={p._id} p={p} index={index} onBuyBasic={onBasicSubscriptionBuy} onBuyPaid={onPaidSubscriptionBuy} user={user} />
                )
            })}
            {loading && <LoadingComponent className='at-transaction-loader' />}
        </div>
    )
}