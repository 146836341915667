import React, { useState } from 'react'
import { images } from '../../../../../assets/image'
import { EArtistPrintType } from '../../../../../enums'
import { ICuratorialProposalArtsDocument, IFormates, IProposalArtFormats } from '../../../../../interfaces'
import { collectionImage } from '../../../../../shared'
import { ShowArtServiceComponent } from '../../../../ArtCentral/ArtServices/ShowArtService.component'
import { CurrencyFormat } from '../../../../common/CurrencyFormat'
import { UniversalModal } from '../../../../common/Modals/Universal.modal'

interface IProps {
    proposalArt: ICuratorialProposalArtsDocument
}
export const SeeOfferProposalArtsComponent = ({ proposalArt }: IProps) => {
    let { art, proposalFormats } = proposalArt

    const [selectedFormat, setSelectedFormat] = useState<IFormates>()
    const [selectedFormatIndex, setSelectedFormatIndex] = useState<number>(NaN)
    const [showService, setShowService] = useState<boolean>(false)

    const onOpenShowService = (format: IProposalArtFormats, fIndex: number) => {
        if (!format.selectedService) return
        setSelectedFormat(format)
        setSelectedFormatIndex(fIndex)
        setShowService(true)
        // this.setState({ formatSelected: format, showService: true })
    }

    const onCloseShowService = () => {
        setSelectedFormat(undefined)
        setSelectedFormatIndex(NaN)
        setShowService(false)
        // this.setState({ formatSelected: undefined, showService: false })
    }
    return (
        <>
            <div className="row at-row-mlr-3 at-curatorial-format at-row-fs12">
                <div className="col-12 at-textleft">
                    <h6 className="at-color-grey">{art.name}</h6>
                </div>
                <div className="col-12">
                    {/* <div className="row at-row-mlr-3 at-row-textunderline">
                        <div className="col-4">
                            <h6 className="at-color-grey">Formats</h6>
                        </div>
                        <div className="col-2 at-textcenter">
                            <h6 className="at-color-grey">Qty</h6>
                        </div>
                        <div className="col-3 at-textcenter">
                            <h6 className="at-color-grey">Your Proposal Price</h6>
                        </div>
                        <div className="col-3 at-textcenter">
                            <h6 className="at-color-grey">Our Offer</h6>
                        </div>
                    </div>
                    inner row ends */}
                </div>
                {art && art.url && <div className="col-12">
                    <div className="at-figurelandscape">
                        <figure className="at-figure-h130">
                            <img src={collectionImage({} as any, art.url)} className="img-fluid" alt="" />
                        </figure>
                    </div>
                </div>}
            </div>
            <div className="row at-row-mlr-3 at-curatorial-format at-row-fs12">
                {/* <div className="col-2">
                            <h6 className="at-color-grey">Sky in the Night</h6>
                        </div> */}
                <div className="col-12">
                    <div className="row at-row-mlr-3 at-row-underline">
                        <div className="col-4">
                            <h6 className="at-color-grey">Format</h6>
                        </div>
                        <div className="col-2 at-textcenter">
                            <h6 className="at-color-grey">Qty</h6>
                        </div>
                        <div className="col-3 at-textcenter">
                            <h6 className="at-color-grey">Your Proposal Price</h6>
                        </div>
                        <div className="col-3 at-textcenter">
                            <h6 className="at-color-grey">Our Offer</h6>
                        </div>
                    </div>
                    {/* inner row ends */}
                </div>
            </div>
            {art && art.url && <div className="row at-row-mlr-3 at-curatorial-format at-row-fs12">

                <div className="col-12">
                    {proposalFormats && proposalFormats.length > 0 && proposalFormats.map((f: IProposalArtFormats, index: number) => {
                        return (f.isSelectedForOffer ?
                            <div className="row at-row-mlr-3" key={index}>
                                <div className="col-4">
                                    <h6>{f.size}
                                        <br />
                                        {f.printType === EArtistPrintType.FRAMED && <p className="at-paralabelgrey">Framed <a className="at-btn-linkorange" onClick={() => onOpenShowService(f, index)}>See Details</a></p>}
                                        {f.printType === EArtistPrintType.UNFRAMED && <p className="at-paralabelgrey">Unframed <a className="at-btn-linkorange" onClick={() => onOpenShowService(f, index)}>See Details</a></p>}
                                    </h6>
                                </div>
                                <div className="col-2 at-textcenter">
                                    <h6 className="at-color-grey">{f.qauntity}</h6>
                                </div>
                                <div className="col-3 at-textcenter">
                                    <h6 className="at-color-grey"><CurrencyFormat amount={Number(Number(f.price) * f.qauntity)} /></h6>
                                </div>
                                <div className="col-3 at-textcenter">
                                    <h6 className="at-color-grey"><CurrencyFormat amount={Number(f.offerPrice)} /></h6>
                                </div>
                            </div>
                            : null
                        )
                    })}

                    {/* inner row ends */}
                    {/* <div className="row at-row-mlr-3 mt-2">
                        <div className="col-4">
                            <h6>Original
                            </h6>
                        </div>
                        <div className="col-2 at-textcenter">
                            <h6 className="at-color-grey">10</h6>
                        </div>
                        <div className="col-3 at-textcenter">
                            <h6 className="at-color-grey">$9,000.00</h6>
                        </div>
                        <div className="col-3 at-textcenter">
                            <h6 className="at-color-grey">$7,000.00</h6>
                        </div>
                    </div> */}
                    {/* inner row ends */}
                </div>
            </div>}
            {showService && selectedFormat && <UniversalModal open={showService} onClose={onCloseShowService} >
                <ShowArtServiceComponent format={selectedFormat} onSubmitShowService={() => { }} />
            </UniversalModal>}
        </>
    )
}