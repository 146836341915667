import { AxiosResponse } from 'axios';
import { State } from 'country-state-city';
import { ICity, IState } from 'country-state-city/dist/lib/interface';
import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';
import { usStateArray } from '../../../../constants';
import { ECostFactorUnit, EPrintType, EWeightFactorUnit } from '../../../../enums';
import { ICollectionItemState, ICreateCollection, IFormates, IPackageDimension, IShippingFactorDocument, IWeihgtFactorsDocument } from '../../../../interfaces';
import { IResponse } from '../../../../interfaces/db.interface';
import { ICarrier } from '../../../../interfaces/ShipEngine.interface';
import { adminService, shipengineService, toastService } from '../../../../services';
import { userSelector } from '../../../../store/selectors';
import { CurrencyFormat } from '../../../common/CurrencyFormat';
import { CurrencyField } from '../../../common/CurrencyInput';
import LoadingComponent from '../../../common/Loading.component';
import { UniversalModal } from '../../../common/Modals/Universal.modal';
import { CalculateShipping } from '../CollectionShipping/CalculateShipping.component';
import { CalculateArtWeight } from '../CollectionShipping/CalculateWeight.component';

interface IProps extends StepWizardChildProps {
    item: ICollectionItemState
    onChangeText: Function
    addPackageDimensionsWithWeight: Function
    addPackageDimensionsWithShipping: Function
    submittingItem: boolean
    onSetOmniShipping: Function
    onSetOwniShipping: Function
}

export const CreateCollectionStepSix = ({ nextStep, previousStep, item, addPackageDimensionsWithWeight, addPackageDimensionsWithShipping, submittingItem, onSetOmniShipping, onSetOwniShipping }: IProps) => {

    const user = useSelector(userSelector)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [shipping, setShipping] = useState<IShippingFactorDocument>()
    const [selectedArt, setSelectedArt] = useState<string>('')
    const [selectedFrame, setSelectedFrame] = useState<string>('')
    const [selectedPackage, setSelectedPackage] = useState<string>('')
    const [totalWeight, setTotalWeight] = useState<number>(NaN)
    const [artWeight, setArtWeight] = useState<number>(NaN)
    const [frameWeight, setFrameWeight] = useState<number>(NaN)
    const [packageWeight, setPackageWeight] = useState<number>(NaN)
    const [packageDimension, setPackageDimensions] = useState<IPackageDimension>({
        height: NaN,
        width: NaN,
        depth: NaN,
        weight: NaN
    })

    const [shippingPrice, setShippingPrice] = useState<number>(NaN)
    const [packingPrice, setPackingPrice] = useState<number>(NaN)
    const [totalPrice, setTotalPrice] = useState<number>(NaN)
    const [insurancePrice, setInsurancePrice] = useState<number>(NaN)
    const [state, setState] = useState<IState | any>()
    const [city, setCity] = useState<ICity | any>()
    const [zipcode, setZipCode] = useState<string>('')

    const stateList = State.getStatesOfCountry('US')

    const [providers, setProviders] = useState<Array<ICarrier>>([])

    const fetchProviders = useCallback(() => {
        shipengineService.getProviders({}).then((res: AxiosResponse<IResponse<Array<ICarrier>>>) => {
            let filterData = res.data.data.filter((c: ICarrier) => c.carrier_id !== "se-729979")
            setProviders([...filterData])
        })

    }, [])

    const fetchFactors = useCallback(() => {
        setLoading(true)
        adminService.getShippingFactor().then((res: AxiosResponse<IResponse<IShippingFactorDocument>>) => {
            if (res.data.data) {
                if (res.data.data.packages && res.data.data.packages.length > 0) {
                    let packageFactor = res.data.data.packages.find((e: IWeihgtFactorsDocument) => e._id === item.packageFactor)
                    packageFactor && calculatePackingPrice(packageFactor)
                }
                setShipping(res.data.data)
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }, [])

    useEffect(() => {
        fetchProviders()
        fetchFactors()
    }, [fetch])

    useEffect(() => {
        getUserState()
        if (item.artFactor && item.frameFactor && item.packageFactor && item.packageDimension) {
            setSelectedArt(item.artFactor)
            setSelectedFrame(item.frameFactor)
            setSelectedPackage(item.packageFactor)

            if (!isNaN(item.packageDimension.weight)) {
                setPackageDimensions({
                    ...packageDimension,
                    ...item.packageDimension
                })
            }
        }

        if (!isNaN(item.shippingInsuranceAmount)) setInsurancePrice(item.shippingInsuranceAmount)
        if (item.shipping) {
            setTotalPrice(Number(item.shipping))
        }



    }, [])

    useEffect(() => {
        if (selectedArt && selectedPackage && selectedFrame && (shipping && shipping._id) && item.width && item.height && item.depth) {
            calCulateWeight()
        }
    }, [selectedPackage, selectedArt, selectedFrame, shipping, item.width, item.height, item.depth])

    useEffect(() => {
        if (zipcode) {
            verifyAddress()
        }
    }, [zipcode])

    useEffect(() => {
        if (!isNaN(packageDimension.weight) && packageDimension.weight) {
            getShippingRates()
        }
    }, [packageDimension.weight])

    useEffect(() => {
        if (selectedPackage && shipping) {
            let packageFactor = shipping.packages.find((e: IWeihgtFactorsDocument) => e._id === selectedPackage)
            packageFactor && calculatePackingPrice(packageFactor)
        }
    }, [selectedPackage])

    useEffect(() => {
        if (!(isNaN(shippingPrice)) && !(isNaN(packingPrice)) && !(isNaN(packingPrice))) {
            let total = Number((Number(shippingPrice) + Number(packingPrice) + Number(insurancePrice)).toFixed(2))
            if (shipping?.businessProtectionFactor) {
                console.log("business factor:-=-=-=", shipping?.businessProtectionFactor, "total Price", total)
                total = total * shipping.businessProtectionFactor
            }
            total = Math.ceil(total / 50) * 50
            submitShowShippingCalculation(packageDimension, Number(total), insurancePrice, total.toFixed(2))
            setTotalPrice(total)
        }
    }, [packingPrice, shippingPrice, insurancePrice])


    const Calculation = (factor: IWeihgtFactorsDocument, width: number, height: number, depth: number = 0, lenght: number = 0) => {
        console.log("width:-=-=", width, "length:-=-=", lenght, "height:-=-=", height, "depth:-=-=", depth, "factor:-=-=", factor)
        let weight = 0
        if (factor.weightFactorUnit === EWeightFactorUnit.POUNDS_PER_SQUARE_INCH) {
            weight = Number(factor.weightFactor) * Number(factor.sizingFactor) * Number(factor.businessWeightFactor) * Number(height) * Number(width)
        }
        if (factor.weightFactorUnit === EWeightFactorUnit.POUNDS_PER_LINEAR_INCH) {
            weight = Number(factor.weightFactor) * Number(factor.sizingFactor) * Number(factor.businessWeightFactor) * ((2 * Number(height)) + (2 * Number(width)))
        }
        if (factor.weightFactorUnit === EWeightFactorUnit.POUNDS_PER_CUBIC_INCH) {
            weight = Number(factor.weightFactor) * Number(factor.sizingFactor) * Number(factor.businessWeightFactor) * Number(height) * Number(width) * Number(depth)
        }
        return Number(weight.toFixed(2))
    }

    const calCulateWeight = () => {
        if (!shipping) return
        let art = shipping.arts.find((m: IWeihgtFactorsDocument) => m._id === selectedArt)
        let framae = shipping.frames.find((m: IWeihgtFactorsDocument) => m._id === selectedFrame)
        let pack = shipping.packages.find((m: IWeihgtFactorsDocument) => m._id === selectedPackage)

        let newartWeight = 0, newframeWeight = 0, newpackageWeight = 0

        if (art && art._id) {

            newartWeight = Calculation(art as IWeihgtFactorsDocument, item.width, item.height, item.depth)
            setArtWeight(Number(newartWeight.toFixed(2)))
        }

        if (framae && framae._id) {

            newframeWeight = Calculation(framae as IWeihgtFactorsDocument, item.width, item.height, item.depth)
            setFrameWeight(Number(newframeWeight.toFixed(2)))
        }
        let packageWidth = 0, packageDepth = 0, packageHeight = 0
        if (pack && pack._id) {

            if (pack.rolled) {
                packageWidth = 10
                packageDepth = 10
                packageHeight = (((Number(item.height) + Number(item.width)) * 0.5) + 8) * Number(pack.sizingFactor)
            } else {
                packageHeight = (Number(item.height) + 8) * Number(pack.sizingFactor)
                packageWidth = (Number(item.width) + 8) * Number(pack.sizingFactor)
                packageDepth = (Number(item.depth) + 8) * Number(pack.sizingFactor)
            }
            newpackageWeight = Calculation(pack as IWeihgtFactorsDocument, packageWidth, packageHeight, packageDepth)
            setPackageWeight(Number(newpackageWeight.toFixed(2)))
        }
        let totalWeight = Number((newartWeight + newframeWeight + newpackageWeight).toFixed(2))

        let dim = {
            height: Number(packageHeight.toFixed(2)),
            width: Number(packageWidth.toFixed(2)),
            depth: Number(packageDepth.toFixed(2)),
            weight: Number(totalWeight.toFixed(2))
        }
        setPackageDimensions({
            ...packageDimension,
            ...dim
        })

        setTotalWeight(totalWeight)

        submitShowWeightCalculation({
            packageDimension: { ...dim },
            artFactor: selectedArt,
            frameFactor: selectedFrame,
            packageFactor: selectedPackage,
            weight: totalWeight
        })
    }



    const submitShowWeightCalculation = (data: any) => {
        addPackageDimensionsWithWeight(data)
    }


    const submitShowShippingCalculation = (dimension: IPackageDimension, w: number, insurance: number, totalShipping: string) => {
        if (isNaN(w)) {
            addPackageDimensionsWithShipping(dimension, '', insurance, '')
            return
        }
        addPackageDimensionsWithShipping(dimension, w.toFixed(2), insurance, totalShipping)
    }

    /********************************* format shipping Calculation ***************************************** */



    const calculatePackingPrice = (f: IWeihgtFactorsDocument) => {
        let weight = 0
        if (!f.costFactor || !f.costFactorUnit) {

        } else {
            console.log("cost Factor:-=-=", f)
            if (f.costFactorUnit === ECostFactorUnit.USD_PER_SQUARE_INCH) {
                weight = Number(f.costFactor) * Number(f.sizingFactor) * Number(f.businessWeightFactor) * Number(item.height) * Number(item.width)
            }
            if (f.costFactorUnit === ECostFactorUnit.USD_PER_LINEAR_INCH) {
                weight = Number(f.costFactor) * Number(f.sizingFactor) * Number(f.businessWeightFactor) * ((2 * Number(item.height)) + (2 * Number(item.width)))
            }
            if (f.costFactorUnit === ECostFactorUnit.USD_PER_CUBIC_INCH) {
                weight = Number(f.costFactor) * Number(f.sizingFactor) * Number(f.businessWeightFactor) * Number(item.height) * Number(item.width) * Number(item.depth)
            }
        }

        setPackingPrice(Number(weight.toFixed(2)))
    }


    const getShippingRates = () => {

        let fromState = stateList.find((s: IState) => s.name === user.state)?.isoCode
        let toState = stateList.find((s: IState) => s.name === state)?.isoCode

        let data = {
            carriers: [
                {
                    carrierId: 'se-729981',
                    serviceCode: `fedex_ground`,
                },
                {
                    carrierId: 'se-729980',
                    serviceCode: `ups_ground`,
                }
            ],
            from: {
                state: fromState
            },
            to: {
                city,
                state: toState,
                zipcode
            },
            package: {
                ...packageDimension
            }
        }

        setLoading(true)
        shipengineService.getShiipingRates(data).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                setShippingPrice(Number(Number(res.data.data).toFixed(2)))
                let artInsur = Number(((Number(item.price) * 1) / 100).toFixed(2))
                setInsurancePrice(artInsur)
                setError(false)
            } else {
                setError(true)
                setInsurancePrice(NaN)
                setShippingPrice(NaN)
                setTotalPrice(NaN)
                setPackingPrice(NaN)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log("err", err)
            setError(true)
            setInsurancePrice(NaN)
            setShippingPrice(NaN)
            setTotalPrice(NaN)
            setPackingPrice(NaN)
            setLoading(false)
        })
    }

    const getUserState = () => {
        let fromState = stateList.find((s: IState) => s.name === user.state)?.isoCode
        let arrayfind = usStateArray.find((s: any) => s.isoCode === fromState)?.desZip
console.log("zip code:-=-=-=", arrayfind, "from state: -=-=", fromState, "stateList", stateList)
        if (arrayfind) {
            setZipCode(arrayfind)
        }

    }

    const verifyAddress = () => {
        // if (!state || !city) return
        let url = `https://zip.getziptastic.com/v2/US/${zipcode}`
        fetch(url, { method: 'GET' }).then((res) => res.json()).then((res: any) => {
            if (res.city && res.state && res.country) {
                setCity(res.city)
                setState(res.state)

            }
        }).catch((err: any) => {
            console.log("error")
        })
    }

    const handleChange = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        let price = value < 0 ? 0 : value;
        if (price.includes("$") || price.includes(","))
            price = price.split("$")[1].replace(/,/gi, "");

        submitShowShippingCalculation(packageDimension, Number(price), 0, price)
        setTotalPrice(Number(price))
    }

    const onClickOmniShipping = (value: boolean) => {
        if (value) {
            getShippingRates()
        }

        onSetOmniShipping()
    }

    const onClickOwniShipping = () => {
        onSetOwniShipping()
    }


    return (
        <>
            <div className="at-orangeheading pb-2 float-none">
                <h5>STEP 6: Add Shipping Information</h5>
                <p className="mt-3 at-textleft">
                    Please select the art medium, art framing and packaging that best describes your work of art and how you prefer to ship your art.
                </p>
            </div>


            <div className="row row-margin-lr7 align-items-center pb-3">
                <div className="col-12 pad-lr-7">
                    <div className="form-group form-w-100 ">
                        <span className="at-select at-floatlabel">
                            <select
                                className="form-control floating-select"
                                value={selectedArt}
                                name="weightFactorUnit"
                                onChange={(e: any) => setSelectedArt(e.target.value)}
                                placeholder=" "
                                required
                            >
                                <option value=""></option>
                                {shipping && shipping.arts && shipping.arts.length > 0 && shipping.arts.map((a: IWeihgtFactorsDocument) => {
                                    return <option key={a.id} value={a._id}>{a.name}</option>
                                })}
                            </select>
                            <label className="at-lineheight-27">Art</label>
                        </span>

                        {/* {errors && errors['weightFactorUnit'] && <ErrorComponent errors={errors['weightFactorUnit']} multiple={false} />} */}
                    </div>
                </div>

                <div className="col-12 pad-lr-7">
                    <div className="form-group form-w-100 ">
                        <span className="at-select at-floatlabel">
                            <select
                                className="form-control floating-select"
                                value={selectedFrame}
                                name="weightFactorUnit"
                                onChange={(e: any) => setSelectedFrame(e.target.value)}
                                placeholder=" "
                                required
                            >
                                <option value=""></option>
                                {shipping && shipping.frames && shipping.frames.length > 0 && shipping.frames.map((a: IWeihgtFactorsDocument) => {
                                    return <option key={a.id} value={a._id}>{a.name}</option>
                                })}
                            </select>
                            <label className="at-lineheight-27">Frame</label>
                        </span>
                        {/* {errors && errors['weightFactorUnit'] && <ErrorComponent errors={errors['weightFactorUnit']} multiple={false} />} */}
                    </div>
                </div>
                <div className="col-12 pad-lr-7">
                    <div className="form-group form-w-100">
                        <span className="at-select at-floatlabel">
                            <select
                                className="form-control floating-select"
                                value={selectedPackage}
                                name="weightFactorUnit"
                                onChange={(e: any) => setSelectedPackage(e.target.value)}
                                placeholder=" "
                                required
                            >
                                <option value=""></option>
                                {shipping && shipping.packages && shipping.packages.length > 0 && shipping.packages.map((a: IWeihgtFactorsDocument) => {
                                    return <option key={a.id} value={a._id}>{a.name}</option>
                                })}
                            </select>
                            <label className="at-lineheight-27">Package</label>
                        </span>
                        {/* {errors && errors['weightFactorUnit'] && <ErrorComponent errors={errors['weightFactorUnit']} multiple={false} />} */}
                    </div>
                </div>
                {/* {!(isNaN(totalWeight)) && <>
                        <div className="col-6">

                        </div>
                        <div className="col-6">
                            
                        </div>
                    </>} */}
                {/* {!(isNaN(totalWeight)) && <div className="col-12 pad-lr-7">
                    <p className="at-calcweight-p">Calculated Weight (lbs): <span className="at-calcweight-orange">{totalWeight}</span></p>
                </div>} */}
                <div className="col-6 pad-lr-7">

                </div>


            </div>
            {/* row ends */}

            <div className="row at-rowprinting align-items-center row-margin-lr7">
                <div className="col-md-12">
                    <p className="mb-3 at-textleft">
                        The information  below is based on the art information  you have provided and current packing, insurance and shipping rates. Please select one option:
                    </p>
                </div>
                <div className="col-7 at-colprinting-label align-items-center" >

                    <div className="at-checkbox at-checkbox-round" onClick={() => onClickOmniShipping(!item.omniShipping)}>
                        <input type="checkbox" checked={item.omniShipping} name="omniCheck" id="abc1" />
                        <label htmlFor="abc" ></label>
                    </div>
                    <h6>Use OMNI estimate</h6>
                </div>
                {item.omniShipping && <div className="col-5 text-center">
                    <h6 className="at-color-orange mb-0 at-fs12">{!error && !isNaN(totalPrice) && totalPrice > 0 ? <CurrencyFormat amount={Number(totalPrice || 0)} /> : error ? 'Given the size and weight of your art, please contact OMNI to get an estimate.' : ''}</h6>
                </div>}
            </div>
            {/* row ends */}

            <div className="row at-rowprinting align-items-center row-margin-lr7">
                <div className="col-8 at-colprinting-label align-items-center" >
                    <div className="at-checkbox at-checkbox-round" onClick={() => onClickOwniShipping()}>
                        <input type="checkbox" checked={item.ownShipping} name="ownCheck" id="abc2" />
                        <label htmlFor="abc" ></label>
                    </div>
                    <h6>Use my own estimate</h6>
                </div>
                <div className="col-4 text-center">
                    <CurrencyField
                        id="input-example-format-shipping"
                        className="form-control cust-input-padding-5px text-center"
                        name="price"
                        placeholder="Price"
                        value={`${totalPrice}`}
                        prefix="$"
                        disabled={!item.ownShipping}
                        onChange={(e: any) => handleChange(e)} />
                </div>
            </div>
            {/* row ends */}

            {/* at-greyborderbox ends */}

            <div className="clearfix"></div>
            <div className="form-group form-w-50 mt-4">
                <button
                    type="button"
                    onClick={() => previousStep()}
                    className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                >
                    BACK
                </button>
            </div>
            <div className="form-group form-w-50 mt-4">
                <button
                    type="submit"
                    className="at-btn at-medbtn-h40-mauto"
                    disabled={submittingItem || loading}


                >
                    {submittingItem ? "WAIT..." : "FINISH"}
                </button>
            </div>

            <div className="row at-row-mlr-3">
                <div className="col-12 ">
                    <div className="row flex-nowrap at-row-mlr-3 at-textcenter at-overflow-xauto">
                        {!(isNaN(artWeight)) && <div className="col ">
                            <p className="at-fs12 mb-0">{artWeight}</p>
                        </div>}
                        {!(isNaN(frameWeight)) && <div className="col ">
                            <p className="at-fs12 mb-0">{frameWeight}</p>
                        </div>}
                        {!(isNaN(packageWeight)) && <div className="col ">
                            <p className="at-fs12 mb-0">{packageWeight}</p>
                        </div>}
                        {!(isNaN(packageDimension.height)) && <div className="col ">
                            <p className="at-fs12 mb-0">{packageDimension.height}</p>
                        </div>}
                        {!(isNaN(packageDimension.width)) && <div className="col ">
                            <p className="at-fs12 mb-0">{packageDimension.width}</p>
                        </div>}
                        {!(isNaN(packageDimension.depth)) && <div className="col ">
                            <p className="at-fs12 mb-0">{packageDimension.depth}</p>
                        </div>}
                        {!(isNaN(insurancePrice)) && <div className="col ">
                            <p className="at-fs12 mb-0">{insurancePrice}</p>
                        </div>}
                        {!(isNaN(shippingPrice)) && <div className="col ">
                            <p className="at-fs12 mb-0">{shippingPrice}</p>
                        </div>}
                        {!(isNaN(packingPrice)) && <div className="col ">
                            <p className="at-fs12 mb-0">{packingPrice}</p>
                        </div>}
                        {zipcode && <div className="col ">
                            <p className="at-fs12 mb-0">{zipcode}</p>
                        </div>}

                        {/* <div className="col-3 pad-lr-7">
                                <p className="at-fs12 mb-0">6,000</p>
                            </div> */}
                    </div>
                </div>
            </div>

            {/* {showWeightCal && (
                <UniversalModal open={showWeightCal} onClose={closeShowWeightCalculation}>
                    <CalculateArtWeight onClose={closeShowWeightCalculation} onSubmit={submitShowWeightCalculation} item={item} isFormat={false} />
                </UniversalModal>
            )}

            {showShippingCal && (
                <UniversalModal open={showShippingCal} onClose={closeShowShippingCalculation}>
                    <CalculateShipping onClose={closeShowShippingCalculation} onSubmit={submitShowShippingCalculation} item={item} />
                </UniversalModal>
            )}
            {showFormatWeightCal && (
                <UniversalModal open={showFormatWeightCal} onClose={closeShowWeightFormatsCalculation}>
                    <CalculateArtWeight onClose={closeShowWeightFormatsCalculation} onSubmit={submitShowWeightFormatsCalculation} item={item} isFormat={true} />
                </UniversalModal>
            )}

            {showFormatShippingCal && (
                <UniversalModal open={showFormatShippingCal} onClose={closeShowShippingFormatsCalculation}>
                    <CalculateShipping onClose={closeShowShippingFormatsCalculation} onSubmit={(d: IPackageDimension, w: number, insurance: number) => submitShowShippingFormatsCalculation(d, w, insurance)} item={item} />
                </UniversalModal>
            )} */}
            {loading && <LoadingComponent className='at-transaction-loader' />}
        </>
    )
}