import { HttpService } from "./base.service";

class AssignmentService extends HttpService {
    private readonly prefix: string = 'assignments';

    /**
     * Get Assignment
     * @param data
     */
    show = (id: string): Promise<any> => this.get(`${this.prefix}/${id}`);

    /**
     * Accept Assignment
     * @param data
     */
    accept = (id: string): Promise<any> => this.put(`${this.prefix}/accept/${id}`);

    /**
     * Reject Assignment
     * @param data
     */
    reject = (id: string): Promise<any> => this.delete(`${this.prefix}/reject/${id}`);

    /**
     * Assignments List with items (Agent)
     * @param data
     */
    list = (): Promise<any> => this.get(`${this.prefix}`);

    /**
     * Create Collection
     * @param data
     */
    collectionsList = (): Promise<any> => this.get(`${this.prefix}/collection/list`);

    /**
     * Create Collection
     * @param data
     */
    createCollection = (data: { title: string, description: string, keywords: string }): Promise<any> => this.post(`${this.prefix}/collection`, data);

    /**
     * Add Assignments To Collection
     * @param data
     */
    addAssignmentToCollection = (id: string, assignments: Array<string>): Promise<any> => this.put(`${this.prefix}/collection/add-assignments/${id}`, { assignments });

    /**
     * Delete Assignments Collection
     * @param data
     */
    deleteAssignmentToCollection = (id: string): Promise<any> => this.delete(`${this.prefix}/collection/${id}`);

    /**
     * Update Assignments Collection Name
     * @param data
     */
    updateAssignmentToCollection = (id: string, title: string): Promise<any> => this.put(`${this.prefix}/collection/update-name/${id}`, { title });

    /**
     * Get Collection
     * @param data
     */
    collection = (id: any): Promise<any> => this.get(`${this.prefix}/collection/${id}`);

    /**
     * Delete Assignments From Collection
     * @param data
     */
    deleteCollectionItems = (id: any, assignments: Array<string>): Promise<any> => this.put(`${this.prefix}/collection/${id}/assignments/remove`, assignments);
    /**
     * Hide agent collectio
     */

    hideAgentCollection = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/hide/${id}`, data);

    /**
     * Hide agent collectio
     */

    updateDescription = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/description/${id}`, data);

     /**
     * Update Collection Sequnces
     */
    updateUserCollectionSequence = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/user-collections-sorting/${id}`, data);

    /**
     * Update Assignments Sequence in Collections
     */

    updateArtSeq = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/updateSorting/${id}`, data);
}

export const assignmentService = new AssignmentService();
