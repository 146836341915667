export const LOGGED_IN = 'loggedIn'

export enum EHubsAndSpaces {
	globeImg = 'globeImg',
	hubImg = 'hubImg',
	spaceRed = 'spaceRed',
	spaceOrange = 'spaceOrange',
	spaceCyan = 'spaceCyan',
	spaceBlue = 'spaceBlue',
	spaceGray = 'spaceGray',
	art_central = 'art_central',
}

export const OVERLAY_CLASS = 'disable-overlay'

export * from './socket-events.type'
export * from './constant'
export * from './transactionTypes'
export * from './userAlert.type'
export * from './user_roles_services'
export * from './auction-event.type'
