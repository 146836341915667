import { AxiosError, AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { IAssignAccountResponse, IUser } from '../../../../interfaces'
import { IResponse } from '../../../../interfaces/db.interface'
import { IConsultantServicesDocument } from '../../../../interfaces/services.interface'
import CommissionWork from '../../../../pages/User/CommissonWork.page'
import DisputePage from '../../../../pages/User/Disputes.page'
import InPlatformWebsite from '../../../../pages/User/InPlatformWebsitePage'
import MetricsPage from '../../../../pages/User/Metrics.page'
import OfferPage from '../../../../pages/User/Offers.page'
import ReturnArtPage from '../../../../pages/User/ReturnArt.page'
import TransactionsPage from '../../../../pages/User/Transaction.page'
import UserAlertPage from '../../../../pages/UserAlert.page'
import { toastService } from '../../../../services'
import { consultantService } from '../../../../services/consultantServices.service'
import AssignedCollection from '../../../AssignAccounts/AgentManageableComonents/collection/AssignedCollection'
import AssignedEvents from '../../../AssignAccounts/AgentManageableComonents/events/AssignedEvents'
import AssignedPost from '../../../AssignAccounts/AgentManageableComonents/posts/AssignedPost'
import { UniversalModal } from '../../../common/Modals/Universal.modal'
import AgentNotification from '../../AgentNotification'


interface IProps extends RouteComponentProps {

}
export const ManageServicesComponent = (props: IProps) => {

    const [showArtistComponent, setShowArtistComponent] = useState({
        open: false,
        component: '',
    })

    const [selectedUser, setSelectedUser] = useState<IUser>()
    const [serviceName, setServiceName] = useState<string>('OMNI Onboarding')
    const [loading, setLoading] = useState<boolean>(false)
    const [service, setService] = useState<IConsultantServicesDocument>()

    const fetchService = (name: string) => {
        if (!name) return

        consultantService.getServiceByName(name).then((res: AxiosResponse<IResponse<IConsultantServicesDocument>>) => {
            setLoading(true)
            if (res.data.success) {
                setService(res.data.data)
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchService(serviceName)
    }, [])
    const seeArtistAccounts = (component: string, sUser: IUser) => {
        setSelectedUser({ ...sUser })

        setShowArtistComponent({
            ...showArtistComponent,
            open: true,
            component: component
        })
    }

    const closeArtistComopnent = () => {
        let obj: any = {}
        setSelectedUser({ ...obj })
        setShowArtistComponent({
            ...showArtistComponent,
            open: false,
            component: ''
        })
    }

    const onChangeName = (e: any) => {
        setServiceName(e.target.value)
        fetchService(e.target.value)
    }

    const getComponents = (name: string) => {
        if (!selectedUser) return
        switch (name) {
            case 'transaction':
                return <TransactionsPage hideBackButton={true} routeUser={(selectedUser as IUser)} />
            case 'offers':
                return <OfferPage hideBackButton={true} routeUser={(selectedUser as IUser)} />
            case 'commission-work':
                return <CommissionWork hideBackButton={true} routeUser={(selectedUser as IUser)} />
            case 'disputes':
                return <DisputePage hideBackButton={true} routeUser={(selectedUser as IUser)} />
            case 'returns':
                return <ReturnArtPage hideBackButton={true} routeUser={(selectedUser as IUser)} />
            case 'collections':
                return <AssignedCollection {...props} user={(selectedUser as IUser)} />
            case 'post':
                return <AssignedPost {...props} user={(selectedUser as IUser)} />
            case 'notifications':
                return <AgentNotification hideBackButton={true} user={(selectedUser as IUser)} />
            case 'userAlerts':
                return <UserAlertPage hideBackButton={true} routeUser={(selectedUser as IUser)} />
            case 'metrics':
                return <MetricsPage hideBackButton={true} {...props} routeUser={(selectedUser as IUser)} />
            case 'inplatform-sites':
                return <InPlatformWebsite hideBackButton={true} {...props} routeUser={(selectedUser as IUser)} />
            case 'events':
                return <AssignedEvents {...props} user={(selectedUser as IUser)} />
            default:
                return
        }
    }

    const approvedAccounts = (b: IUser) => {

        if (!b) return
        return (
            <div>
                {/* <h6 className="at-color-grey">Current Assigned Accounts</h6> */}
                <div className="at-assign-roundbox">

                    <div className="d-flex ">
                        <h6>{b.firstName} {b.lastName}</h6>
                        <i className="icon-notification at-assign-icon" onClick={() => seeArtistAccounts('notifications', b)}></i>
                        <i className="icon-user-2 at-assign-icon" onClick={() => seeArtistAccounts('userAlerts', b)}></i>
                        {/* <i className='icon-transaction at-assign-icon ml-auto' onClick={() => onSwitchProfile(b)}></i> */}
                    </div>
                    <div className="at-assign-accountbox">
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('post', b)}>
                            <i className="icon-add-post"></i>
                            <p>Post</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('collections', b)}>
                            <i className="icon-add-menu"></i>
                            <p>Collection</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('events', b)}>
                            <i className="icon-event"></i>
                            <p>Events</p>
                        </div>

                    </div>
                    <div className="at-assign-accountbox mt-2">
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('inplatform-sites', b)}>
                            <i className="icon-globe"></i>
                            <p>In -Platform Website</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('offers', b)}>
                            <i className="icon-offer"></i>
                            <p>Offers</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('transaction', b)}>
                            <i className="icon-transaction"></i>
                            <p>Transactions</p>
                        </div>


                        {/* <button className="at-btn at-minwidth-100 mt-3">End Assignment</button> */}
                    </div>
                    <div className="at-assign-accountbox mt-2">
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('commission-work', b)}>
                            <i className="icon-handshake"></i>
                            <p>Commission Work</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('disputes', b)}>
                            <i className="icon-dispute-icon"></i>
                            <p>Disputes</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('returns', b)}>
                            <i className="icon-Box-1"></i>
                            <p>Returns</p>
                        </div>

                    </div>
                    <div className="at-assign-accountbox mt-2">
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('metrics', b)}>
                            <i className="icon-bars"></i>
                            <p>Metrics</p>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    return <div className="at-createservice-innertab">
        <form className="at-formtheme at-formcreatecollection px-0" >
            <fieldset>
                <div className="form-group w-100 mt-3 mx-0">
                    <span className="at-select at-floatlabel-h40">
                        <select className="form-control floating-select" value={serviceName} name="" onChange={(e: any) => onChangeName(e)} >
                            <option value="">Select Service</option>
                            <option >OMNI Onboarding</option>
                            <option >In-Platform  Website  Development</option>
                            <option >Portfolio Review, Analysis and Update</option>
                            <option >Platform Marketing Program Management</option>
                            <option >Complete  Account  Management</option>
                        </select>
                        {/* <label>Select Screen to Place Video Link</label> */}
                    </span>
                </div>
            </fieldset>
        </form>
        <div className="clearfix"></div>
        {service && service.buyers.length > 0 && service.buyers.map((b: IUser) => approvedAccounts(b))}
        {/* <div className="at-assign-roundbox">
            <div className="d-flex ">
                <h6>Alex Echavarria</h6>
                <i className="icon-notification at-assign-icon" ></i>
                <i className="icon-user-2 at-assign-icon" ></i>
                <i className='icon-transaction at-assign-icon ml-auto' onClick={() => onSwitchProfile(a)}></i>
            </div>
            <div className="at-assign-accountbox">
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-add-post"></i>
                    <p>Post</p>
                </div>
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-add-menu"></i>
                    <p>Collection</p>
                </div>
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-event"></i>
                    <p>Events</p>
                </div>

            </div>
            <div className="at-assign-accountbox mt-2">
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-globe"></i>
                    <p>In -Platform Website</p>
                </div>
                <div className="at-accountitem cursor-pointer">
                    <i className="icon-offer"></i>
                    <p>Offers</p>
                </div>
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-transaction"></i>
                    <p>Transactions</p>
                </div>

                <button className="at-btn at-minwidth-100 mt-3">End Assignment</button>
            </div>
            <div className="at-assign-accountbox mt-2">
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-handshake"></i>
                    <p>Commission Work</p>
                </div>
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-dispute-icon"></i>
                    <p>Disputes</p>
                </div>
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-Box-1"></i>
                    <p>Returns</p>
                </div>
            </div>
            <div className="at-assign-accountbox mt-2">
                <div className="at-accountitem cursor-pointer" >
                    <i className="icon-bars"></i>
                    <p>Metrics</p>
                </div>
            </div>
            <button className="at-btn at-minwidth-100 mt-3" >End Service</button>

        </div> */}
        {showArtistComponent.open && <UniversalModal className="at-universal-padding-20" open={showArtistComponent.open} onClose={closeArtistComopnent}>
            {getComponents(showArtistComponent.component)}
        </UniversalModal>}
    </div>
}

export const ManageServices = withRouter(ManageServicesComponent)