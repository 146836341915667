import { IStoreAction, IContactState } from '../../interfaces'
import {
	FETCH_CONTACTS_SUCCESS,
	FETCH_CONTACTS_FAILURE,
	FETCH_CONTACTS_REQUEST,
} from '../types'

const initialState: IContactState = {
	data: [],
	isLoading: false,
	isLoaded: false,
	error: '',
}
export const contactReducer = (
	state = initialState,
	action: IStoreAction
): IContactState => {
	switch (action.type) {
		case FETCH_CONTACTS_REQUEST:
			return {
				...state,
				isLoading: true,
			}

		case FETCH_CONTACTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				data: action.payload,
			}

		case FETCH_CONTACTS_FAILURE:
			return {
				...state,
				isLoading: false,
				isLoaded: false,
				error: action.payload,
			}

		default:
			return state
	}
}
