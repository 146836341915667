import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../assets/image'
import { routes } from '../../router'
import { communicationService } from '../../services'
import { ECommunicationMessages } from '../../services/communication.service'

class BottomTabsComponent extends Component {
	state = {
		openSpacesMenu: false,
	}

	openSpaceMenu = () => {
		this.setState({
			openSpacesMenu: !this.state.openSpacesMenu,
		})
	}

	closeAllModals = () => {
		this.setState({
			openSpacesMenu: false,
		})
	}

	addHub = () => {
		communicationService.setData({
			type: ECommunicationMessages.OPEN_HUB_ADD_FORM,
			payload: undefined,
		})
		this.closeAllModals()
	}

	addSpace = (color: string) => {
		communicationService.setData({
			type: ECommunicationMessages.OPEN_SPACE_ADD_FORM,
			payload: { color },
		})
		this.closeAllModals()
	}

	render() {
		const { openSpacesMenu } = this.state

		return (
			<>
				{/* Options Menu for hubs, spaces and globe */}
				{openSpacesMenu && (
					<div id='iopiopiop' className='at-themepopupholder at-add-hub-popup'>
						<div className='at-themepopupbox'>
							<a
								className='at-close'
								id='at-close'
								onClick={this.closeAllModals}
							>
								<i className='icon-cancel'></i>
							</a>
							<fieldset>
								<div className='at-formthemehead'>
									<h5>Add Space</h5>
									<span>(Click sphere to add Hub or Space)</span>
								</div>
								<div className='at-formthemeholder'>
									<ul>
										<li className='list-group-item' onClick={this.addHub}>
											<img src={images.hubsAndSpaces.hubImg} alt='Hub' />
										</li>
										<li
											className='list-group-item'
											onClick={() => {
												this.addSpace('spaceBlue')
											}}
										>
											<img
												src={images.hubsAndSpaces.spaceBlue}
												alt='Blue space'
											/>
										</li>
										<li
											className='list-group-item'
											onClick={() => {
												this.addSpace('spaceCyan')
											}}
										>
											<img
												src={images.hubsAndSpaces.spaceCyan}
												alt='Cyan space'
											/>
										</li>
										<li
											className='list-group-item'
											onClick={() => {
												this.addSpace('spaceOrange')
											}}
										>
											<img
												src={images.hubsAndSpaces.spaceOrange}
												alt='Orange space'
											/>
										</li>
										<li
											className='list-group-item'
											onClick={() => {
												this.addSpace('spaceGray')
											}}
										>
											<img
												src={images.hubsAndSpaces.spaceGray}
												alt='Gray space'
											/>
										</li>
										<li
											className='list-group-item'
											onClick={() => {
												this.addSpace('spaceRed')
											}}
										>
											<img
												src={images.hubsAndSpaces.spaceRed}
												alt='Red space'
											/>
										</li>
									</ul>
								</div>
							</fieldset>
						</div>
					</div>
				)}
				{/* Options Menu for hubs, spaces and globe */}
				<nav className='at-nav'>
					<ul>
						<li>
							<Link to={routes.myCentral.home}>
								<img src={images.homeGlobe} alt='' />
								<span>Home</span>
							</Link>
						</li>
						<li>
							<Link to={routes.myCentral.contacts}>
								<i className='icon-add-user'></i>
								<span>Contacts</span>
							</Link>
						</li>
						<li>
							<Link
								to='/'
								onClick={(event) => {
									event.preventDefault()
									this.openSpaceMenu()
								}}
							>
								<i className='icon-add-space'></i>
								<span>Spaces</span>
							</Link>
						</li>
						<li>
							<Link to={routes.account.index}>
								<i className='icon-user-2'></i>
								<span>Account</span>
							</Link>
						</li>
					</ul>
				</nav>
			</>
		)
	}
}

export default BottomTabsComponent
