import React, { Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { UNAUTHORIZED, FORBIDDEN } from 'http-status';
import { authService, toastService, UserLoginAction } from "../../services";
import { CUser } from "../../classes";
import { connect } from 'react-redux';
import { loginAction, socialLoginAction } from "../../store/actions/auth.action";
import { IStoreActionsStates, IAccount, ISocialSignup } from "../../interfaces";
import { AxiosResponse, AxiosError } from 'axios';
import { saveUser } from '../../store/actions';
import PhoneInput from 'react-phone-number-input'
import { _logger } from '../../helper';
import { socketNode } from '../../services/socket.service'


interface IProps extends IStoreActionsStates, RouteComponentProps {
	onClose: any
	openSignup: any
	socialLoginAction: any
	openForgotPassword: any
	[key: string]: any,
	onSigninSucees?: any
}

interface IPageState {
	username: string
	password: string
	remember: boolean
	signingIn: boolean
}

class SigninComponent extends Component<IProps> {

	state: IPageState = {
		username: '+1',
		remember: false,
		password: '',
		signingIn: false
	};


	doLogin = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// this.props.history.push('/home')
		const { username, password } = this.state;
		this.setState({ signingIn: true });
		authService.login({ username, password }).then(this.handleSuccess, this.errorHandler)
	}

	handleSuccess = (response: AxiosResponse<IAccount>) => {
		const user: CUser = new CUser(response.data.user);
		toastService.success(`Welcome, ${user.fullName}`)
		this.setState({ signingIn: false, username: '', password: '' });
		this.props.loginAction({ ...response.data, remember: this.state.remember, isAdmin: false });
		this.props.saveUser(response.data.user);
		socketNode.emit("user", { user: response.data.user._id })
		this.props.onSigninSucees && this.props.onSigninSucees()
		UserLoginAction.triggerAction(false)
	}

	errorHandler = ({ response }: any) => {
		this.setState({ signingIn: false });
		switch (response.status) {
			case UNAUTHORIZED:
				toastService.error('Incorrect phone number or password!')
				break;
			case FORBIDDEN:
				toastService.error(response.data.message)
				break;
			default:
				break;
		}
	}

	handleSocialLogin = (user: any) => {
		_logger('User', user);
	}

	onLoginSuccess = (user: any) => {
		const { provider, token: { accessToken }, profile: { profilePicURL, ...profile } } = user;
		authService.socialLogin({ provider, accessToken }).then(this.handleSuccess, (error: AxiosError) => {
			if (error && error.response?.status === 404) {
				const user: ISocialSignup = {
					accessToken,
					provider,
					...profile,
					profile: profilePicURL,
					socialUserId: profile.id
				}
				this.props.socialLoginAction(user);
				this.props.openSignup()
			}
		})
	}

	onLoginFailure = (user: any) => {
		_logger('User', user);
	}

	onChangeText = (e: FormEvent<EventTarget>): void => {
		const { name, value } = (e.target as HTMLInputElement);
		this.setState({ [name]: value })
	}

	render() {
		const { username, password, remember, signingIn } = this.state;

		return (
			<div className="at-themepopupbox">
				<a className="at-close at-btncloselogin cursor-pointer" id="at-close" onClick={this.props.onClose}><i className="icon-cancel"></i></a>
				<form className="at-formtheme at-formlogin" onSubmit={this.doLogin}>
					<fieldset>
						<h2>Sign in</h2>
						<div className="form-group at-inputwithicon ">
							<i className="icon-user"></i>
							{/* <input type="text" name="username" value={username} className="form-control" placeholder="Phone number" onChange={this.onChangeText}/> */}
							<PhoneInput
								placeholder="Phone (e.g., +1 412 877 4312)"
								value={username}
								className="floating-input-outer"
								defaultCountry="US"
								onChange={(username: any) => this.setState({ username })} />
							{/* <label>Enter phone number</label> */}
						</div>
						<div className="form-group at-inputwithicon at-floatlabel-h40">
							<i className="icon-lock"></i>
							<input type="password" name="password" value={password} className="form-control" placeholder=" " onChange={this.onChangeText} />
							<label>Password</label>
						</div>
						<div className="form-group">
							<div className="at-checkbox" onClick={() => this.setState({ remember: !remember })}>
								<input type="checkbox" name="remember" id="remember" checked={remember} />
								<label>Remember me</label>
							</div>
							<a className="at-btnforgetpassword" onClick={this.props.openForgotPassword}>Forgot your password?</a>
						</div>
						<div className="form-group">
							<button type="submit" className="at-btn at-minwidth-100" disabled={signingIn}>{signingIn ? 'LOGGING IN...' : 'LOGIN'}</button>
						</div>
						{/* <div className="form-group">
							<div className="at-morebox"><h3>OR</h3></div>
						</div> */}
						{/* <div className="form-group">

							{/* <SocialLoginComponent
								provider='facebook'
								appId={THIRD_PARTY.facebook.appID}
								callback={this.handleSocialLogin}
								onLoginSuccess={this.onLoginSuccess}
								onLoginFailure={this.onLoginFailure}
								>
								<i className="facebook"><img src={images.facebook} /></i><span>Sign in With Facebook</span>
							</SocialLoginComponent> */}

						{/* <SocialLoginComponent
								provider='instagram'
								className="at-btnsocial at-bginstagram text-white"
								appId={THIRD_PARTY.instagram.appID}
								redirect="http://localhost:3001"
								callback={this.handleSocialLogin}
								onLoginSuccess={this.onLoginSuccess}
								onLoginFailure={this.onLoginFailure}
								>
								<i className="facebook"><img src={images.instagram} /></i><span>Sign in With Instagram</span>
							</SocialLoginComponent> */}
						{/* </div> */}
						<div className="form-group">
							<em>New User?<a className="at-gotosignupfromlogin" onClick={this.props.openSignup}>Signup</a></em>
						</div>
					</fieldset>
				</form>
			</div>
		);
	}
}
const mapAction = {
	loginAction,
	saveUser,
	socialLoginAction
}
export default connect(null, mapAction)(SigninComponent);
