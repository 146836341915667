import { LIKE_POST, UNLIKE_POST, SAVE_USER, LIKE_USER, UNLIKE_USER, UPDATE_USER_DETAILS, ADD_USER_TO_SPACE } from "../types/user.type";
import { IAccount, IUser } from "../../interfaces";
import { accountService } from "../../services/account.service";
import { AxiosResponse } from "axios";
import { socketNode } from "../../services/socket.service";
import { loginAction } from "./auth.action";
import { HttpService } from "../../services/base.service";
import { IResponse } from "../../interfaces/db.interface";

export const saveUser = (payload: IUser) => ({
    payload,
    type: SAVE_USER
})

export const addUserToSpace = (payload: IUser) => ({
    payload,
    type: ADD_USER_TO_SPACE
})

export const likeAction = (payload: string) => ({
    payload,
    type: LIKE_POST
})

export const unLikeAction = (payload: string) => ({
    payload,
    type: UNLIKE_POST
})

export const likeUserAction = (payload: string) => ({
    payload,
    type: LIKE_USER
})

export const unLikeUserAction = (payload: string) => ({
    payload,
    type: UNLIKE_USER
})

export const udpateUserDetails = (payload: IUser | any) => ({ payload, type: UPDATE_USER_DETAILS })

export const refreshUser = () => (function (dispatch: Function) {
    accountService.me().then((res: AxiosResponse<IUser>) => {
        socketNode.emit("user", { user: res.data._id })
        dispatch(udpateUserDetails(res.data))
    })
})

export const switchProfile = (data: any) => async (dispatch: any) => {
    try {

        const res: AxiosResponse<IResponse<IAccount>> = await accountService.switchProfile({ ...data })
        await dispatch(loginAction({ ...res.data.data, remember: true }))
        await dispatch(saveUser(res.data.data.user))
        return { success: true, res: res.data }
    } catch (error) {
        return { success: false, error, message: 'Unable to switch account' }
    }

}
