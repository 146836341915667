import React from 'react'
import { PLATFORM_FEE } from '../../../../../environment'
import { IFormates } from '../../../../../interfaces'
import { ISelectedService, ISelectedServiceState } from '../../../../../interfaces/services.interface'
import { CurrencyFormat } from '../../../../common/CurrencyFormat'
import { ProposalImageView } from '../../../../common/ProposalImageView'
interface IProps {
    format: IFormates
    selectedServices: Array<ISelectedServiceState>
    onSubmitShowService: Function
}
export const BuyerSeePrinterAndFrame = ({ format, selectedServices, onSubmitShowService }: IProps) => {

    const checkIfSelected = (s: ISelectedServiceState) => {
        if (!format || !format.buyerSelectedService) return false
        if (format.buyerSelectedService && ((format.buyerSelectedService._id && format.buyerSelectedService._id === s._id) || format.buyerSelectedService === s._id)) return true

        return false
    }
    return (
        <div className="at-addactionholder">
            <div className="at-formthemehead at-headselectprinter pb-2 ">
                <h2 className="at-textcenter">Select Framing Options</h2>
                <p>{format.size}</p>
            </div>
            <div className="at-selectprinter">

                {selectedServices && selectedServices.length > 0 && selectedServices.map((s: ISelectedServiceState) => {
                    return <div className="at-greybgboxtable">
                        <div className="at-printerbox">
                            <h6 className="at-printer-greyheading">PRINT MEDIUM</h6>
                            <h6>{s.printingService.name}: <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: s.printingService.description }}></span></h6>
                        </div>
                        <div className="at-printerbox">
                            <h6 className="at-printer-greyheading">FRAMING OPTION</h6>
                            <h6>{s.framingService.name}: {s.framingService.description && <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: s.framingService.description }}></span>} </h6>
                        </div>
                        {s.selectedSize && <div className="at-printerbox">
                            <h6>Size: <span className="at-span-printerbox" >{s.selectedSize.height} x {s.selectedSize.width}</span></h6>
                        </div>}
                        <div className="at-printerbox">
                            <h6>Shipping: {s.printingService.shipmentInformation && <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: s.printingService.shipmentInformation }}></span>}</h6>
                        </div>
                        <figure className="at-frameimg">
                            <ProposalImageView isServicImage={true} item={s.framingService} />
                        </figure>
                        {s.artistChoice && <div className="text-center"><h4>Artist Choice</h4></div>}
                        <h5><span className="at-color-orange">Additional Cost:</span> <CurrencyFormat amount={Number(Number(s.totalCost) + Number(PLATFORM_FEE))} /></h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <button type="button" className="at-btn at-longbtn-h40" disabled={checkIfSelected(s)} onClick={() => onSubmitShowService(s)} >{checkIfSelected(s) ? 'OPTION SELECTED' : 'SELECT'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}