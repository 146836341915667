import React, { FormEvent } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import DatePicker from "react-datepicker";
import RTSelect from 'react-select'
import { IAuctionState } from '../../../../../interfaces/auction.interface'
import { ErrorComponent } from '../../../../common/Error.component'
import { countryZone } from '../../../../../shared'
import moment from 'moment';


interface IProps extends StepWizardChildProps {
    eventState: IAuctionState
    onChangeText: Function
    errors: any
    setErrorData: Function

}

export const AuctionformStepOne = ({ eventState, errors, onChangeText, nextStep, setErrorData }: IProps) => {

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    const onClickNext = () => {
        let { name, startDate, endDate, startTime, endTime } = eventState
        let newErr = errors
        if (!name) {
            newErr['name'] = ['Name is required']
            setErrorData({ ...newErr })
            return
        }

        if (!startDate) {
            newErr['startDate'] = ['Start Date is required']
            setErrorData({ ...newErr })
            return
        }
        if (!endDate) {
            newErr['endDate'] = ['End Date is required']
            setErrorData({ ...newErr })
            return
        }
        if (!startTime) {
            newErr['startTime'] = ['Start Time is required']
            setErrorData({ ...newErr })
            return
        }
        if (!endTime) {
            newErr['endTime'] = ['End Time is required']
            setErrorData({ ...newErr })
            return
        }

        if (moment(startDate).valueOf() > moment(endDate).valueOf()) {
            newErr['endDate'] = ['End date must be greater than start date']
            setErrorData({ ...newErr })
            return
        }
        if (moment(startTime).valueOf() > moment(endTime).valueOf()) {
            newErr['endTime'] = ['End time must be greater than start date']
            setErrorData({ ...newErr })
            return
        }
        newErr = {}
        setErrorData({ ...newErr })
        nextStep()
    }

    let customInput = <input type="text" autoComplete="off" className="form-control" />
    return (
        <>
            <div className="at-orangeheading">
                <h5>Add Live Auction Schedule</h5>
                {/* <h6>(skip Step 1 if adding art to existing collection)</h6> */}
            </div>
            <div className="at-greyborderbox pt-4">
                <div className="form-group form-w-100 at-formgroup-datepicker at-floatlabel">
                    <input autoComplete="off" type="text" name="name" value={eventState.name} className="form-control" placeholder=" " onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)} />
                    <label>Live Auction Name</label>
                    {errorElement('name')}
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group form-w-50 at-inputwithicon at-datepicker-zindex999">
                            <DatePicker name="startDate" autoComplete="off" customInput={customInput} selected={eventState.startDate} placeholderText="Start Date" onChange={(date: any) => onChangeText({ target: { name: "startDate", value: date } })} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('startDate')}
                        </div>

                        <div className="form-group form-w-50 at-inputwithicon at-datepicker-zindex999">
                            <DatePicker name="endDate" autoComplete="off" popperPlacement="bottom-end" customInput={customInput} selected={eventState.endDate} placeholderText="End Date" onChange={(date: any) => onChangeText({ target: { name: "endDate", value: date } })} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('endDate')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group form-w-50 at-inputwithicon at-datepicker-zindex999">
                            <DatePicker
                                name="startTime"
                                autoComplete="off"
                                popperPlacement="bottom-end"
                                customInput={customInput}
                                selected={eventState.startTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                placeholderText="Start Time"
                                onChange={(date: any) => onChangeText({ target: { name: "startTime", value: date } })} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('startTime')}
                        </div>
                        <div className="form-group form-w-50 at-inputwithicon at-datepicker-zindex999">
                            <DatePicker
                                name="endTime"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                autoComplete="off"
                                popperPlacement="bottom-end"
                                customInput={customInput}
                                selected={eventState.endTime}
                                placeholderText="End Time"
                                onChange={(date: any) => onChangeText({ target: { name: "endTime", value: date } })} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('endTime')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group form-w-100 at-assignselect">
                            <div className="at-customfont-holder">
                                <label className="at-color-grey" htmlFor="">Time Zone</label>
                                <RTSelect
                                    value={countryZone().find((i: any) => i.value === (eventState.timeZone?.includes('/') ? eventState.timeZone.split('/')[1] : eventState.timeZone))}
                                    options={countryZone()}
                                    // defaultValue={countryZone().find((i: any) => i.value === eventState.timeZone)}
                                    onChange={(data: any) => onChangeText({ target: { name: "timeZone", value: data.value } })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group form-w-50">
                    {/* <button
                        type="button"
                        onClick={() => previousStep()}
                        className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                    >
                        BACK
                    </button> */}
                </div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => onClickNext()}
                        className="at-btn at-medbtn-h40-mauto"
                    >
                        NEXT
                    </button>
                </div>

            </div>
        </>
    )
}