import { IPaginateCollection } from "../interfaces";
import { HttpService } from "./base.service";

class CollectionService extends HttpService {
    private readonly prefix: string = 'collection';

    /**
     * Collections List
     * @param data
     */
    list = (): Promise<any> => this.get(`${this.prefix}`);
    /**
     * Collections List
     * @param data
     */
    listWithUserId = (id?: string): Promise<any> => this.get(`${this.prefix}/list/${id}`);

    /**
     * Collections List with items
     * @param data
     */
    listWithItems = (id?: any): Promise<any> => this.get(`${this.prefix}/detailed`, { id });

    /**
     * Collections List with items
     * @param data
     */
    AgentlistWithItems = (id?: any): Promise<any> => this.get(`${this.prefix}/agentCollection`, { id });
    /**
     * Collections List with items
     * @param data
     */
    listWithoutAuth = (id: any): Promise<any> => this.get(`${this.prefix}/detailedWithId/${id}`);

    /**
     * View Collection
     * @param data
     */
    show = (id: string): Promise<any> => this.get(`${this.prefix}/${id}`);

    /**
     * View Collection
     * @param data
     */
    deleteCollection = (id: string): Promise<any> => this.delete(`${this.prefix}/${id}`);

    /**
     * Delete Collection Items
     * @param params
     */
    deleteItems = (id: string, params: any): Promise<any> => this.delete(`${this.prefix}/items/${id}`, params);

    /**
     * View Collection Item
     * @param data
     */
    showItem = (id: string): Promise<any> => this.get(`${this.prefix}/item/${id}`);

    /**
     * View Collection
     * @param data
     */
    userCollections = (id: string): Promise<any> => this.get(`${this.prefix}/user/${id}`);

    /**
     * Create Collection
     * @param data
     */
    add = (data: any): Promise<any> => this.post(this.prefix, data);

    /**
     * Update Collection
     * @param data
     */
    update = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/${id}`, data);

    /**
     * Update Collection Art sequence
     * @param data
     */
    updateArtSeq = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/updateSorting/${id}`, data);

    /**
     * Update Collection
     * @param data
     */
    updateName = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/update-name/${id}`, data);

    /**
     * Add Item
     * @param data
     */
    addItem = (data: any): Promise<any> => this.post(`${this.prefix}/item`, data);

    /**
     * Like and Unlike Collection
     */
    likeUnlike = (id: string, status: boolean): Promise<any> => this.put(`${this.prefix}/like-unlike/${id}/${status ? 1 : 0}`)

    /**
     * Like and Unlike Collection
     */
    likeUnlikeItem = (id: string, status: boolean): Promise<any> => this.put(`${this.prefix}/item/like-unlike/${id}/${status ? 1 : 0}`)

    /**
     * Collection Comment
     */
    addComment = (postId: string, data: { comment: string }): Promise<any> => this.post(`${this.prefix}/comment/${postId}`, data);

    /**
     * Assignment
     */
    assignItems = (data: any): Promise<any> => this.post(`${this.prefix}/assignment`, data);

    /**
     * Hide Collection
     */
    hideCollection = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/hide/${id}`, data);

    /**
     * Update Collection Description
     */
    updateDescription = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/description/${id}`, data);
    /**
    * Update Collection Sequnces
    */
    updateUserCollectionSequence = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/user-collections-sorting/${id}`, data);

    /**
     * Fetch all arts collections
     */

    artCollections = (data: any): Promise<any> => this.get(`${this.prefix}/all-collection`, { ...data })

    /**
     * Fetch Auctions arts
     */

     auctionArts = (data: any): Promise<any> => this.get(`${this.prefix}/all-arts-for-auction`, data)
}

export const collectionService = new CollectionService();
