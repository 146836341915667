import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import LoadingComponent from './Loading.component';
import Video from 'react-video-renderer';
import { connect } from 'react-redux';
import { zoomImageAction } from '../../store/actions';
import { secondsToTime } from '../../shared';
import { VideoComponent } from './Video.component';
import { isInViewport } from '../../helper';

interface IProps {
    videoUrl: string
    midResolution: string
    thumbnail: string
    scrollPosition?: any
    isVideo: boolean
    id: string
    index?: number
}

 const LazyLoadImageComponent = (props: IProps) => {
    const { isVideo, midResolution, videoUrl, thumbnail, id, index} = props;
    const [loading, setLoading] = useState(true);
    const [playVideo, setPlayVideo] = useState(false);
    const [playVideoStatus, setPlayVideoStatus] = useState(false);

    const imageRef = useRef(null);
    
    const imageUrl = isVideo ? thumbnail : midResolution;
    const [image, setImage] = useState(imageUrl);

    const onClickImage = (e: any) => {
        if (isVideo) {
            setPlayVideo(true)
            setPlayVideoStatus(true)
        }
    }

    const imageView = (
        <img 
            src={image}
            onLoad={() => setLoading(false)}
            alt={thumbnail}
            onError={() => !isVideo && setImage(thumbnail)}
            className={`post-image ${loading && 'blue-affect'}`}
            style={{ backgroundImage: `url(${thumbnail})`}}
            onClick={onClickImage}
        />
    );

    const videoView = <VideoComponent url={videoUrl} index={index} autoPlay={playVideo} id={props.id} autoControls={true} className="at-feedvideo"/>

    const onScroll = () => {
        const video = document.getElementById(id);
        if (!(video as any)?.getBoundingClientRect()) return
        const { top } = (video as any).getBoundingClientRect()
        if ((top < 200 || top > 700) && playVideo) setPlayVideo(false);
        if(!isVideo) return
        isInViewport(video) ? setPlayVideo(true) : setPlayVideo(false)
    }
    
    useEffect(_.debounce(() => {
        const video = document.getElementById(id);
        if(!isVideo) return
        const isInside = isInViewport(video)
        isInside ? setPlayVideo(true) : setPlayVideo(false)
        return () => {
            if(!isVideo) return
            isInside ? setPlayVideo(true) : setPlayVideo(false)
        }
    }, 300), [index])

    return (
        <span className="image-post" onWheel={_.debounce(() => onScroll(), 100) } id={id} ref={imageRef}>
            {
                playVideo? videoView : imageView
                
            }
            {
                (isVideo) && <span onClick={onClickImage} className={`${!playVideo && 'videoButton icon-play-button'}`}></span>
            }
            {
                loading && <div className="shammering-affect">
                    <LoadingComponent />
                </div>
            }
        </span>
    )
}

export default LazyLoadImageComponent