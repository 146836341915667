import React, { useState } from 'react'
import { IUser } from '../../interfaces'
import { ISubscriptionPackage, ISubsDetail } from '../../interfaces/subscription.interface'
import { ConfirmAlertComponent } from '../common/ConfirmAlert.component'
import { UpdateCardForm } from '../Stirpe'

interface IProps {
    p: ISubscriptionPackage
    onBuyBasic: Function
    index?: any
    onBuyPaid: Function,
    user: IUser
}

export const SubscriptionPackage = ({ p, onBuyBasic, index, onBuyPaid, user }: IProps) => {
    const [showCardForm, setShowCardFoam] = useState<boolean>(false)
    const [showConfirmBox, setConfirmBox] = useState<boolean>(false)
    const currentPackge = user.packageID?._id === p._id
    const onClickBuy = () => {
        closeConfirmBox()
        if (!(p.price > 0)) {
            onBuyBasic(p)
            return
        }
        if(user.subscriptionID){
            onBuyPaid({}, p)

            return
        }
        setShowCardFoam(true)
    }

    const openConfirmBox = () => setConfirmBox(true)
    const closeConfirmBox = () => setConfirmBox(false)

    return (
        <div key={p._id}>
            <div className="at-roundbox">
                <div className="at-pricetopbox">
                    <h3>{p.package_name}</h3>
                    <h2> <sup>$</sup>{p.price.toFixed(2)} <span>/mo</span></h2>
                </div>
                <ul className="mb-3">
                    {p.details.map((d: ISubsDetail, index: any) => {
                        return <li className={`${d.enable ? '' : 'At-CrossLi'}`} key={index} dangerouslySetInnerHTML={{ __html: d.text }}></li>
                    })}
                </ul>
                {!showCardForm && <button className="at-btn at-minw180" disabled={currentPackge} onClick={openConfirmBox}>{!currentPackge ? `Buy` : 'Current Service'}</button>}
                <p className="at-note-para">NOTE: A change of subscription will end the FREE TRIAL</p>
            </div>
            {showCardForm && <>
                <h4>Monthly Fee: <strong>USD {p.price.toFixed(2)}</strong></h4>
                <UpdateCardForm text='Buy' onClick={(source: any) => {
                    setShowCardFoam(false)
                    onBuyPaid(source, p)
                    }} />
            </>
            }
            {
                showConfirmBox && <ConfirmAlertComponent
                    message='Are you sure you want to buy this Subscription?'
                    open={showConfirmBox}
                    onCancel={closeConfirmBox}
                    onClose={closeConfirmBox}
                    onConfirm={onClickBuy}
                />
            }
        </div>
    )
}