import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../assets/image'

import { routes } from './routes'

const Header = () => {
    return (
        <div>
            <section className="At-SectionHeader At-SectionHeaderAbsolute">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-dark ">
                        <Link className="At-LinkLogo" to={routes.home}>
                            <figure className="At-FigureLogo mb-0">
                                <img src={images.template.logoTemplate2} className="img-fluid " alt="" />
                                {/* <img src={images.template.logoTemplate2} className="img-fluid At-Template2" alt="" /> */}
                            </figure> 
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto">
                                {/* <li className="nav-item active">
                                    <Link to={routes.exhibitions.index}>Exhibitions <span className="sr-only">(current)</span></Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to={routes.ArtDetail}>Art detail</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={routes.Collection}>Collection</Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to={routes.about}>About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={routes.artists.index}>Artists</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={routes.Contact}>Contact</Link>
                                </li> */}
                            </ul>
                        </div>
                    </nav>

                </div>
            </section>
        </div>
    )
}

export default Header