import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { ERole } from '../../../enums';
import { ICollection, ICollectionItem, IUser } from '../../../interfaces';
import { routes } from '../../../router';
import { collectionImage } from '../../../shared';

interface IProps {
    c: ICollection,
    user: IUser,
    selectedForEvent: Array<ICollectionItem>
    onAddToEvents: Function
}
export const EventCollectionView = ({ c, user, selectedForEvent, onAddToEvents }: IProps) => {
    const isAgent = c.user.roles.includes(ERole.AGENT);
    const items = c.items
    const [selectedArts, setSelectedArts] = useState<Array<ICollectionItem>>([])

    const onSelectArts = (item: ICollectionItem) => {
        if (selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1) {
            let newItems = selectedArts.filter((i: ICollectionItem) => i.id !== item.id)
            setSelectedArts([...newItems])
            return
        }
        let newArts = selectedArts
        newArts.push(item)
        setSelectedArts([...newArts])
    }

    const isSelected = (item: ICollectionItem) => {
        return selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1
    }

    const isAddedToEvents = (item: ICollectionItem) => {
        if(!selectedForEvent) return false
        return selectedForEvent.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1
    }

    const onSelectAll = (col: ICollection) => {
        let count = col.items.filter((i: ICollectionItem) => isAddedToEvents(i))
        if (selectedArts.length === c.items.length - count.length) {
            setSelectedArts([])
            return
        }
        let array = col.items.filter((i: ICollectionItem) => !isAddedToEvents(i))
        setSelectedArts([...array])
    }
    const isAllSelected = (col: ICollection) => {
        let array = col.items.filter((i: ICollectionItem) => isAddedToEvents(i))
        if (selectedArts.length > 0 &&selectedArts.length === (c.items.length - array.length)) {
            return true
        }
        return false
    }

    const onAddedToEvents = () => {
        console.log("run")
        onAddToEvents(selectedArts)
        setSelectedArts([])
    }



    return (
        c.itemsCount > 0 ? <div className="at-collectiongallerybox at-artgallerybox " id={c.id} key={c.id}>
            <div className="at-gallerytitle">
                <h3 className="d-block mb-2">
                    <Link to={`${routes.artCentral.collection.view}/${c.id}`}>
                        {c.title}
                    </Link>
                    <span>{c.itemsCount} Images</span>
                </h3>
                <span>
                    <div className="at-btndelete d-flex ">
                        <i className="icon-add-circle fs-20 mr-3 cursor-pointer right" title="Add Arts" onClick={() => onAddedToEvents()}></i>
                        <div className="at-checkbox" title='Select Collection'>
                            <input type="checkbox" name="remember" checked={isAllSelected(c)} />
                            <label htmlFor="artcollectionselectall" className="at-label-bdradius50" onClick={() => onSelectAll(c)}></label>
                        </div>
                    </div>
                </span>
                <ul className="at-gallery">
                    {
                        items.map((item: ICollectionItem) => (
                            <li key={item.id} className="position-relative">
                                <div>
                                    <Link to={`${isAgent ? routes.agent.showAssigment : routes.artCentral.collection.item}/${item[isAgent ? 'assignment' : 'id']}`}>
                                        <figure>
                                            <img src={collectionImage(item, `high_${item.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                                        </figure>
                                    </Link>
                                    <div className="at-artgallerycollectionbox atv2-event-positionabs" title="select">
                                        <span className="at-checkbox">
                                            {user._id === c.user._id && !isAddedToEvents(item) && <><input type="checkbox" name="select" checked={isSelected(item)} id="artcollectionselectall" />
                                                <label htmlFor="artcollectionone" onClick={() => onSelectArts(item)}></label> </>}
                                        </span>

                                    </div>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
            :
            null
    )
}