import React, { useCallback, useEffect, useState } from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { ISpaceState, ITemplateState } from '../interfaces'
import PrivateRoutes from "./private.route";
import ArtShowMain from '../templates/Art_Show_Format/MainRoutes'
import GalleryShowMain from '../templates/Gallery_Format/MainRoutes'
import { FetchTemplateData, setInPlatformWebState } from '../store/actions'
import { useDispatch } from 'react-redux'
import { ETemplate } from '../enums'
import LoadingComponent from '../components/common/Loading.component'
import { getDomainUrl } from '../shared'
import CatalogueFormat from '../templates/Catalogue_Format/MianRoutes'
import StudioFormat from '../templates/Studio_Format/MianRoutes'
import { ZoomActions, EZoom } from '../services/zoom.service';

interface OmniProps extends RouteComponentProps {
    spaces?: ISpaceState
    remember: boolean
    logoutAction: any
}
const OmniRoutes = (props: OmniProps) => {
    const [loading, setLoading] = useState(false)
    const [template, setTemplate] = useState<ITemplateState>()
    const [mount, mounted] = useState(false)
    const dispatch = useDispatch()

    const fetch = useCallback(async () => {

        let route = getDomainUrl(props.location.pathname)
        setLoading(true)
        let res: any = await dispatch(FetchTemplateData({ url: route }))
        if (res) {
            dispatch(setInPlatformWebState({ isActive: true }))
            // ZoomActions.triggerAction({ type: EZoom.NONE, value: null })
        }
        setTemplate(res)
        setLoading(false)
        mounted(true)
    }, [])

    useEffect(() => {
        fetch()
    }, [])

    const renderTemplates = () => {
        switch (template?.template) {
            // case ETemplate.ART_SHOW_FORMAT:
            //     return <Route path={`/${template.url}`} component={() => <ArtShowMain name={template.url} />} />
            case ETemplate.ART_SHOW_FORMAT:
                return <ArtShowMain name={template.url} />
            case ETemplate.GALLERY_FORMAT:
                return <GalleryShowMain name={template.url} />
            case ETemplate.CATALOGUE_FORMAT:
                return <CatalogueFormat name={template.url} />
            case ETemplate.SERVICE_FORMAT:
                return <CatalogueFormat name={template.url} />
            case ETemplate.STUDIO_FORMAT:
                return <StudioFormat name={template.url} />
            // case '':
            //     return <PrivateRoutes {...props} />

            default:
                return null
        }
    }
    return (
        <>

            {/* {mount && !loading && <>
                {renderTemplates()}
                <PrivateRoutes {...props} />
            </>} */}
            {template && renderTemplates()}
            {!template && !loading && mount ?
                <>
                    <PrivateRoutes {...props} />
                </>
                :
                <div className="">{loading && <LoadingComponent className='at-transaction-loader' />}</div>}
        </>
    )
}

export default withRouter(OmniRoutes)