import React, { useRef } from 'react'
import { ISubmissionProcessState } from '../../../interfaces/submission-process.interface'
import { ErrorComponent } from '../../common/Error.component'

interface IProps {
    state: ISubmissionProcessState
    onChangePoster: Function
    errors: any
}
export const StepThree = ({ state, onChangePoster, errors }: IProps) => {

    const bioRef = useRef<any>(null)
    const cvRef = useRef<any>(null)
    const selectBio = () => {

        bioRef.current.click();
    }
    const selectCv = () => {

        cvRef.current.click();
    }
    return <div>
        <h5 className="at-stepheading text-left">Please upload your bio/artist statement and CV in PDF format.</h5>
        <div className="row row-margin-lr7 align-items-center">
            <div className="col-12 pad-lr-7">
                <div className="form-group form-w-100 mb-0">
                    <div className="at-themefileupload">
                        <input type="file" name="bio" id="at-uploadfile" accept="application/pdf" ref={bioRef} onChange={(e: any) => onChangePoster(e, 'bio')} />
                        <label onClick={selectBio}>
                            {state.bio && state.bio !== "null" ? <span><i className="icon-tick  at-color-greenlight"></i>Bio/Artist Statement uploaded</span> : <span><i className="icon-pdf-big"></i>Upload Bio/Artist Statement PDF</span>}
                        </label>
                        {errors && errors['bio'] && <ErrorComponent errors={errors['bio']} multiple={false} />}
                    </div>
                </div>
            </div>
            <div className="col-12 pad-lr-7">
                <div className="form-group form-w-100 mb-0">
                    <div className="at-themefileupload">
                        <input type="file" name="cv" accept="application/pdf" id="at-uploadfile2" ref={cvRef} onChange={(e: any) => onChangePoster(e, 'cv')} />
                        <label onClick={selectCv}>
                            {state.cv && state.cv !== "null" ? <span><i className="icon-tick  at-color-greenlight"></i>Cv uploaded</span> : <span><i className="icon-pdf-big"></i>Upload CV PDF</span>}
                        </label>
                        {errors && errors['cv'] && <ErrorComponent errors={errors['cv']} multiple={false} />}
                    </div>
                </div>
            </div>
        </div>
    </div>
}