import { HttpService } from "./base.service";
import { IUser } from "../interfaces";

class AccountService extends HttpService {
    private readonly prefix: string = 'account';

    /**
     * My Account
     */
    me = (): Promise<any> => this.get(`${this.prefix}/me`);

    /**
     * Update Account
     * @param data
     * @return Promise
     */
    update = (data: any): Promise<any> => this.put(`${this.prefix}/update`, data)

    /**
     * Deactivate Space
     * @return Promise
     */
    deactivateSpace = (): Promise<any> => this.put(`${this.prefix}/deactive-space`, {})

    /**
     * Current Active Space
     * @return Promise
     */
    currentActiveSpace = (): Promise<any> => this.get(`${this.prefix}/current-active-space`)

    /**
     * Switch Profile
     * @return Promise
     */

    switchProfile = (data: any): Promise<any> => this.post(`${this.prefix}/assigned-account/login`, data)
}

export const accountService = new AccountService();
