import { IUser } from "./user.interface";


interface Image {
    url: string;
    isVideo: boolean;
    thumbnail: string
}

export interface ISubmissionProcess {
    _id: string
    user: IUser,
    roles: Array<string>;
    state: string;
    address: string;
    city: string;
    zipcode: string;
    webUrl: string;
    instagramUrl: string;
    bio: Image;
    cv: Image;
    images: Array<Image>;
    status: string;
    isActive: boolean;
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly deletedAt: Date,
}

export interface ISubmissionProcessState {
    roles: Array<string>;
    state: string;
    address: string;
    city: string;
    zipcode: string;
    webUrl: string;
    instagramUrl: string;
    bio: any;
    cv: any;
    images: Array<any>;
    preview: Array<any>,
    previewsCopy: Array<any>,
    selectedFile: number,
}

export const initialSubmissionState: ISubmissionProcessState = {
    roles: [],
    state: '',
    address: '',
    city: '',
    zipcode: '',
    webUrl: '',
    instagramUrl: '',
    bio: null,
    cv: null,
    images: [],
    preview: [],
    previewsCopy: [],
    selectedFile: 0,
}