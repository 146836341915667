import React from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import { _logger } from '../../helper'
import { routes } from '../../router'

interface IProps extends RouteComponentProps {
    logoutAction: Function,
    saveUser: Function
}
const AdminHomePage = ({ history }: IProps) => {

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two at_pad_lr_0">
                <div className="at-themehead">
                    <a onClick={() => history.push('/')} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Admin</h2>
                </div>
                <div className="at_theme_innercontent">
                    <ul className="at_account_mainul">
                        {/* <li>
                            <Link to={routes.adminRoutes.dashboard}><span><i className="icon-menu"></i></span><h4>Dashboard</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li> */}
                        <li>
                            <Link to={routes.adminRoutes.users}><span><i className="icon-user-2"></i></span><h4>Users</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>
                        {/* Remove from SprintONE */}
                        <li>
                            <Link to={routes.adminRoutes.transactions}><span><i className="icon-transaction"></i></span><h4>Transaction</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>
                        <li>
                            <Link to={routes.adminRoutes.curatorialtransactions}><span><i className="icon-transaction"></i></span><h4>Curatorial Transaction</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>

                        <li>
                            <Link to={routes.adminRoutes.serviceTransactions}><span><i className="icon-transaction"></i></span><h4>Service Transaction</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>
                        <li>
                            <Link to={routes.adminRoutes.manageDisputes}> <span><i className="icon-handshake"></i></span> <h4>Manage Disputes</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>
                        <li>
                            <Link to={routes.adminRoutes.editText}> <span><i className="icon-document"></i></span><h4>Edit Text</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>
                        <li>
                            <Link to={routes.adminRoutes.subscription}> <span><i className="icon-subscription"></i></span><h4>Subscription & Communication</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>
                        {/* <li>
                            <Link to={routes.adminRoutes.systemParameters}><span><div className="at-admintutorial-link"><i className="icon-video-tutorial"></i><em>HELP</em></div> </span> <h4>System Parameters</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li> */}
                        <li>
                            <Link to={routes.adminRoutes.tutorials}><span><div className="at-admintutorial-link"><i className="icon-video-tutorial"></i><em>HELP</em></div> </span> <h4>Tutorials</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>
                        <li>
                            <Link to={routes.adminRoutes.shippingFactor}><span><i className="icon-Bus"></i> </span> <h4>Shipping Factors</h4><i className="icon-arrow-right at_arrow_right"></i></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </main>
    )
}

export default AdminHomePage
