import { ICollectionItem } from "./collection.interface";
import { ISpace } from "./space.interface";
import { IUser } from "./user.interface";

export interface IGalleryDocument {
    _id: string,
    id: string
    name: string,
    startDate: Date,
    endDate: Date,
    startTime: Date,
    endTime: Date,
    galleryWalkSpaces: Array<IGalleryWalkSpacesDocument>
    description: string,
    images: Array<any>,
    pdf: any
    isHide: boolean,
    isActive: boolean,
    poster: any,
    user: IUser,
    timeZone: string
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

export interface IGalleryState {
    _id?: string,
    id?: string
    name: string,
    startDate: any | Date,
    endDate: any | Date,
    startTime: any | Date,
    endTime: any | Date,
    galleryWalkSpaces: Array<IGalleryWalkSpaceState>
    description: string,
    images: Array<any>,
    pdf: any
    isHide: boolean,
    isActive: boolean,
    poster: any,
    timeZone: string
}

export const initGallery: IGalleryState = {

    name: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    galleryWalkSpaces:[],
    description: '',
    images: [],
    pdf: null,
    poster: null,
    isHide: false,
    isActive: true,
    timeZone: 'US/EST'
}


export interface IGalleryWalkSpacesDocument {
    _id: string,
    id: string
    space: ISpace
    featuredArts: Array<ICollectionItem>
    galleryWalk: IGalleryDocument
    user: IUser
    isActive: boolean
    isHide: boolean
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

export interface IGalleryWalkSpaceState {
    _id?: string,
    id?: string
    space: any | ISpace
    featuredArts: Array<ICollectionItem>
    galleryWalk?: IGalleryDocument
    user?: IUser
    isActive?: boolean
    isHide?: boolean
    readonly createdAt?: Date
    readonly updatedAt?: Date
    readonly deletedAt?: Date
}

export const initGalleryWalkState: IGalleryWalkSpaceState = {
    space: undefined,
    featuredArts: [],
}