import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { RWebShare } from "react-web-share";
import CopyToClipboard from 'react-copy-to-clipboard'
import { ITutorials } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { toastService, tutorialsService } from '../../services'
import { TutorailPlayComponent } from './TuturialPlay.component'
import { APP_URL } from '../../environment';
import PhoneInput from 'react-phone-number-input'

interface IProps {
    screen: string
}
export const ScreenTutorial = ({ screen }: IProps) => {

    const [tutorials, setTutorials] = useState<Array<ITutorials>>([])
    const [showLink, setShowLink] = useState<boolean>(false)
    const [selectedTutorials, setSelectedTutorial] = useState<ITutorials>()
    const [newMessage, setMessage] = useState<string>('')
    const [phoneNumeber, setPhoneNumber] = useState<string>('+1')
    const [loading, setLoading] = useState<boolean>(false)

    const [selectedVideo, setSelectedVideo] = useState<string>('')
    const fetchTutorials = () => {
        tutorialsService.getTutorials({ screen }).then((res: AxiosResponse<IResponse<Array<ITutorials>>>) => {
            let { data } = res
            setTutorials([...data.data])
        })
    }

    const onSelectVideo = (t: ITutorials) => {
        setSelectedVideo(t._id)
    }

    const onShareLink = (l: ITutorials) => {
        setShowLink(true)
        setSelectedTutorial(l)
    }

    const onShareVideo = (link: string) => {
        let finalMessage = `${newMessage}. ${link}`
        setLoading(true)
        let data = {
            message: finalMessage,
            phoneNumber: phoneNumeber
        }
        tutorialsService.sendSms(data).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                setMessage('')
                setPhoneNumber('')
                setShowLink(false)
            }
            if (!res.data.success) {
                toastService.error(res.data.message)
            }
            setLoading(false)

        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTutorials()
    }, [])

    let shareUrl = `${APP_URL}/?tutorial=${selectedTutorials?._id}`
    return (
        <div>
            {!showLink ?
                <div>
                    <h5 className="at-popupheading">Select Tutorial</h5>
                    <div className="at-categorybox at-tutorialplaybox">
                        {/* <div className="at-categoryhead">
                                <h4 className="at-additional-heading">Getting Started</h4>
                            </div> */}
                        {tutorials.length > 0 && tutorials.map((t: ITutorials) => {
                            return (
                                <TutorailPlayComponent seletecedVideo={selectedVideo} onSelectVideo={onSelectVideo} tutorial={t} onShareLink={onShareLink} />
                            )
                        })}

                    </div>
                </div> :
                <div>
                    <h5 className="at-popupheading">Share</h5>
                    <div className="form-group">
                        <PhoneInput
                            placeholder="Phone (e.g., +1 412 877 4312)"
                            value={phoneNumeber}
                            className="floating-input-outer"
                            defaultCountry="US"
                            onChange={(username: any) => setPhoneNumber(username)} />
                        {/* <label>Enter phone number</label> */}
                    </div>
                    <div className="form-group  at-textarea-height100">
                        <textarea name="details" className="form-control at-border-radius10" placeholder="Type Message" value={newMessage} onChange={(e: any) => setMessage(e.target.value)} />
                    </div>
                    <CopyToClipboard text={shareUrl} onCopy={() => toastService.info('Copied to clipboard')}>
                        <div className="form-group form-w-100 at-inputcopyclipboard">
                            <input type="text" name="name" disabled={true} className="form-control " placeholder={shareUrl} />
                            <i className="icon-link"></i>
                        </div>
                    </CopyToClipboard>

                    <div className="row row-margin-lr7">
                        <div className="col-6 pad-lr-7">
                            <div className="form-group">
                                <CopyToClipboard text={shareUrl} onCopy={() => toastService.info('Copied to clipboard')}>
                                    <button className="at-btn at-longbtn-h40" >COPY LINK</button>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <div className="form-group">
                                <button className="at-btn at-longbtn-h40" type='button' disabled={loading} onClick={() => onShareVideo(shareUrl)}>SHARE VIDEO</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}