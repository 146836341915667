import { AxiosResponse } from 'axios'
import { trim } from 'lodash'
import { string } from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { images } from '../../assets/image'
import AssignedCollection from '../../components/AssignAccounts/AgentManageableComonents/collection/AssignedCollection'
import AssignedCreateCollections from '../../components/AssignAccounts/AgentManageableComonents/collection/AssignedCreateCollections'
import AssignedEvents from '../../components/AssignAccounts/AgentManageableComonents/events/AssignedEvents'
import AssignedCreateEditPostPage from '../../components/AssignAccounts/AgentManageableComonents/posts/AssignedCreatePost'
import AssignedPost from '../../components/AssignAccounts/AgentManageableComonents/posts/AssignedPost'
import { ConfirmAlertComponent } from '../../components/common/ConfirmAlert.component'
import LoadingComponent from '../../components/common/Loading.component'
import { UniversalModal } from '../../components/common/Modals/Universal.modal'
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial'
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon'
import AgentNotification from '../../components/User/AgentNotification'
import { EAssignAccount, ERole } from '../../enums'
import { EScreens } from '../../enums/screens.enum'
import { IAssignAccountResponse, ICollection, IUser } from '../../interfaces'
import { routes } from '../../router'
import { assignAccountService, assignmentService, toastService } from '../../services'
import { accountService } from '../../services/account.service'
import { switchProfile } from '../../store/actions'
import { userSelector } from '../../store/selectors'
import UserAlertPage from '../UserAlert.page'
import CommissionWork from './CommissonWork.page'
import DisputePage from './Disputes.page'
import InPlatformWebsite from './InPlatformWebsitePage'
import MetricsPage from './Metrics.page'
import OfferPage from './Offers.page'
import ReturnArtPage from './ReturnArt.page'
import TransactionsPage from './Transaction.page'

interface IProps extends RouteComponentProps {

}

const AgentAssignedAccount = (props: IProps) => {
    const history = useHistory()
    const user = useSelector(userSelector)
    const dispatch = useDispatch()
    const [accounts, setAccounts] = useState<Array<IAssignAccountResponse>>()
    const [approved, setApproved] = useState<Array<IAssignAccountResponse>>()
    const [pending, setPending] = useState<Array<IAssignAccountResponse>>()
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedAccount, setSelectedAccount] = useState<IAssignAccountResponse>()
    const [showCollectionBox, setCollectionBox] = useState<boolean>()
    const [collectionName, setCollectionName] = useState('')
    const [description, setDescription] = useState<string>('')
    const [keywords, setKeywords] = useState<string>('')
    const [creatingCollection, setCreatingCollection] = useState<boolean>(false)
    const [addingItems, setAddingItems] = useState<boolean>(false)
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [collection, setCollection] = useState<ICollection>()
    const [showArtistComponent, setShowArtistComponent] = useState({
        open: false,
        component: '',
    })




    const [confirmBox, setConfirmBox] = useState<boolean>(false)
    const [endBox, setEndBox] = useState<boolean>(false)
    const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false)


    const fetch = useCallback(() => {
        setLoading(true)
        assignAccountService.getAllAccounts().then((res: AxiosResponse<any>) => {
            let approvedData = res.data.data.filter((d: IAssignAccountResponse) => d.status === EAssignAccount.ACCEPTED)
            let pendingData = res.data.data.filter((d: IAssignAccountResponse) => d.status === EAssignAccount.PENDING)
            setApproved([...approvedData])
            setPending([...pendingData])
            setAccounts([...res.data.data])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (!user.roles.includes(ERole.AGENT)) {
            history.replace(routes.account.index)
        }
        fetch()
    }, [fetch])

    const openConfirmBox = (a: IAssignAccountResponse) => {
        setSelectedAccount({ ...a })
        setConfirmBox(true)
    }

    const closeConfimr = () => {
        let obj: any = {}
        setSelectedAccount({ ...obj })
        setConfirmBox(false)
    }
    const openEndBox = (a: IAssignAccountResponse) => {
        setSelectedAccount({ ...a })
        setEndBox(true)
    }

    const closeEndbox = () => {
        let obj: any = {}
        setSelectedAccount({ ...obj })
        setEndBox(false)
    }

    const openCollectionBox = (a: IAssignAccountResponse) => {
        setSelectedAccount({ ...a })
        setCollectionBox(true)
    }

    const closeCollectionBox = () => {
        let obj: any = {}
        setSelectedAccount({ ...obj })
        setCollectionBox(false)
    }

    const onReject = () => {
        setLoading(true)

        assignAccountService.rejectAccount(selectedAccount?._id, { status: EAssignAccount.REJECTED }).then((res: AxiosResponse<any>) => {
            if (!res.data.success) {
                toastService.error(res.data.message)
            } else {
                toastService.success(res.data.message)
                fetch()
            }
            setLoading(false)
            closeConfimr()
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
            closeConfimr()
        })
    }

    const onApproved = () => {

        let data = {
            id: selectedAccount?._id,
            collection: collection?.id,
            artist: (selectedAccount?.artist as IUser)._id
        }
        setLoading(true)
        setAddingItems(true)
        assignAccountService.acceptAccount(data).then((res: AxiosResponse<any>) => {
            if (!res.data.success) {
                toastService.error(res.data.message)
            } else {
                toastService.success(res.data.message)
                fetch()
            }
            setLoading(false)
            closeCollectionBox()
            setAddingItems(false)

            setCollection(undefined)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
            setAddingItems(false)

            closeCollectionBox()
            setCollection(undefined)
        })
    }

    const createCollection = () => {
        setCreatingCollection(true)
        assignmentService.createCollection({ title: collectionName, description, keywords }).then((response: AxiosResponse<any>) => {
            setCreatingCollection(false)
            toastService.success(response.data.message)
            setCollection({ ...response.data.collection })
            setDescription('')
            setKeywords('')
        })
    }

    const onEndAssignment = () => {
        setLoading(true)
        assignAccountService.onAgentEndAssignment({ id: selectedAccount?._id }).then((res: AxiosResponse<any>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            } else {
                toastService.error(res.data.message)
            }
            fetch()
            closeEndbox()
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
            closeEndbox()

        })
    }

    const onSwitchProfile = async (a: IAssignAccountResponse) => {
        let artist = (a.artist as IUser)
        const res: any = await dispatch(switchProfile({ userId: artist._id, isBackToAgent: false }))
        if (res.success) {
            history.push(routes.landingPage)
        } else {
            toastService.error(res.message)
        }
    }

    const seeArtistAccounts = (component: string, a: IAssignAccountResponse) => {
        setSelectedAccount({ ...a })

        setShowArtistComponent({
            ...showArtistComponent,
            open: true,
            component: component
        })
    }

    const closeArtistComopnent = () => {
        let obj: any = {}
        setSelectedAccount({ ...obj })
        setShowArtistComponent({
            ...showArtistComponent,
            open: false,
            component: ''
        })
    }



    const openTutorialBox = () => {
        setShowTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setShowTutorialBox(false)
    }

    const getComponents = (name: string) => {
        if (!selectedAccount) return
        switch (name) {
            case 'transaction':
                return <TransactionsPage hideBackButton={true} routeUser={(selectedAccount?.artist as IUser)} />
            case 'offers':
                return <OfferPage hideBackButton={true} routeUser={(selectedAccount?.artist as IUser)} />
            case 'commission-work':
                return <CommissionWork hideBackButton={true} routeUser={(selectedAccount?.artist as IUser)} />
            case 'disputes':
                return <DisputePage hideBackButton={true} routeUser={(selectedAccount?.artist as IUser)} />
            case 'returns':
                return <ReturnArtPage hideBackButton={true} routeUser={(selectedAccount?.artist as IUser)} />
            case 'collections':
                return <AssignedCollection {...props} user={(selectedAccount?.artist as IUser)} />
            case 'post':
                return <AssignedPost {...props} user={(selectedAccount?.artist as IUser)} />
            case 'notifications':
                return <AgentNotification hideBackButton={true} user={(selectedAccount?.artist as IUser)} />
            case 'userAlerts':
                return <UserAlertPage hideBackButton={true} routeUser={(selectedAccount?.artist as IUser)} />
            case 'metrics':
                return <MetricsPage hideBackButton={true} {...props} routeUser={(selectedAccount?.artist as IUser)} />
            case 'inplatform-sites':
                return <InPlatformWebsite hideBackButton={true} {...props} routeUser={(selectedAccount?.artist as IUser)} />
            case 'events':
                return <AssignedEvents {...props} user={(selectedAccount?.artist as IUser)} />
            default:
                return
        }
    }



    const approvedAccounts = (a: IAssignAccountResponse) => {
        let artist = a.artist as IUser
        if (!artist) return
        return (
            <div>
                <h6 className="at-color-grey">Current Assigned Accounts</h6>
                <div className="at-assign-roundbox">

                    <div className="d-flex ">
                        <h6>{artist.firstName} {artist.lastName}</h6>
                        <i className="icon-notification at-assign-icon" onClick={() => seeArtistAccounts('notifications', a)}></i>
                        <i className="icon-user-2 at-assign-icon" onClick={() => seeArtistAccounts('userAlerts', a)}></i>
                        {/* <i className='icon-transaction at-assign-icon ml-auto' onClick={() => onSwitchProfile(a)}></i> */}
                    </div>
                    <div className="at-assign-accountbox">
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('post', a)}>
                            <i className="icon-add-post"></i>
                            <p>Post</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('collections', a)}>
                            <i className="icon-add-menu"></i>
                            <p>Collection</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('events', a)}>
                            <i className="icon-event"></i>
                            <p>Events</p>
                        </div>

                    </div>
                    <div className="at-assign-accountbox mt-2">
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('inplatform-sites', a)}>
                            <i className="icon-globe"></i>
                            <p>In -Platform Website</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('offers', a)}>
                            <i className="icon-offer"></i>
                            <p>Offers</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('transaction', a)}>
                            <i className="icon-transaction"></i>
                            <p>Transactions</p>
                        </div>


                        {/* <button className="at-btn at-minwidth-100 mt-3">End Assignment</button> */}
                    </div>
                    <div className="at-assign-accountbox mt-2">
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('commission-work', a)}>
                            <i className="icon-handshake"></i>
                            <p>Commission Work</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('disputes', a)}>
                            <i className="icon-dispute-icon"></i>
                            <p>Disputes</p>
                        </div>
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('returns', a)}>
                            <i className="icon-Box-1"></i>
                            <p>Returns</p>
                        </div>

                    </div>
                    <div className="at-assign-accountbox mt-2">
                        <div className="at-accountitem cursor-pointer" onClick={() => seeArtistAccounts('metrics', a)}>
                            <i className="icon-bars"></i>
                            <p>Metrics</p>
                        </div>

                    </div>


                    <button className="at-btn at-minwidth-100 mt-3" disabled={loading} onClick={() => openEndBox(a)}>End Assignment</button>
                </div>
            </div>
        )
    }

    const pendingAccounts = (a: IAssignAccountResponse) => {
        let artist = a.artist as IUser
        if (!artist) return
        return (
            <div>
                <h6 className="at-color-grey">Pending Assigned Accounts</h6>
                <div className='at-account-approve d-flex align-items-center'>
                    <h5>{artist.firstName} {artist.lastName}</h5>
                    <i className="icon-info ml-auto" onClick={() => setShowInfo(!showInfo)}></i>
                    <button title="Accept" disabled={loading} onClick={() => openCollectionBox(a)} className="at-btn-green at-btnaccept at-btnthumbs mr-2 ml-3"> <i className="icon-like"></i> </button>
                    <button title="Reject" disabled={loading} onClick={() => openConfirmBox(a)} className="at-btn-green at-btnaccept at-btnthumbs at-btn-orange"> <i className="icon-dislike"></i></button>
                    {showInfo && <div className="at-message-box pb-4 text-capitalize text-left">

                        {a.type && <div>TYPE OF ASSIGNMENT: {a.type}</div>}

                        <div>ORIGINAL TERM: {a.period} Months</div>
                        <div>
                            ORIGINAL PAYOUT: {a.commission}%
                            </div>
                        <div>OTHER FORMAT TERM: {a.formatPeriod} Months</div>
                        <div>
                            OTHER FORMAT PAYOUT: {a.formatCommission}%
                            </div>
                        {a.originalResponsibility && <div>
                            ORIGINAL SHIPPING: {a.originalResponsibility}
                        </div>}
                        {a.formatResponsibility && <div>
                            OTHER FORMAT SHIPPING: {a.formatResponsibility}
                        </div>}
                    </div>}
                </div>
            </div>

        )
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Assign Account Management <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.MANAGE_ASSIGNED_ACCOUNT} onClick={openTutorialBox} /></span></h2>
                </div>
                <div className="at_theme_innercontent">
                    {approved?.map((a: IAssignAccountResponse) => approvedAccounts(a))}
                    {pending?.map((a: IAssignAccountResponse) => pendingAccounts(a))}
                </div>
                {showCollectionBox && <UniversalModal open={showCollectionBox} position='bottom' onClose={closeCollectionBox} >
                    <div className="at-addactionholder">
                        <div className="at-formthemehead pr-0">
                            <h2>Add To Collection</h2>

                            <div className="form-group at-pos-relative mt-4 at-floatlabel-h40">
                                <input type="text" name="createnewcollection" className="form-control" value={collectionName} disabled={collection && collection.id ? true : false} autoComplete="off" placeholder=" " onChange={({ target: { value } }) => {
                                    setCollectionName(value)
                                }} />
                                <label>Create New Collection</label>

                                <button type="button" onClick={createCollection} className="at-btnadd c-sunshadebg" disabled={trim(collectionName).length < 1 || creatingCollection || (collection && collection.id ? true : false)}><i className="icon-add"></i></button>
                            </div>

                            <div className="form-group at-floatlabel">
                                <textarea name="description" placeholder=" " className="form-control agent-textarea" onChange={({ target: { value } }) => setDescription(value)} value={description}>{description}</textarea>
                                <label>Description</label>
                            </div>

                            <div className="form-group at-floatlabel">
                                    <textarea name="description" placeholder=" " className="form-control agent-textarea" onChange={({target: {value}}) => setKeywords(value)} value={description}>{description}</textarea>
                                    <label>KeyWord</label>
                                </div>

                            <button className="at-btn at-minwidth-100" disabled={addingItems || !collection} onClick={onApproved}>Add</button>

                        </div>
                    </div>
                </UniversalModal>}
                {confirmBox && <ConfirmAlertComponent closeable={true} onClose={closeConfimr} open={confirmBox} onCancel={closeConfimr} title="Confirm!" message={`Are you sure you want to reject this Assignment`} onConfirm={onReject} confirmTitle="Confirm" cancelTitle="Cancel" />}
                {endBox && <ConfirmAlertComponent closeable={true} onClose={closeEndbox} open={endBox} onCancel={closeEndbox} title="Confirm!" message={`Are you sure you want to end this Assignment`} onConfirm={onEndAssignment} confirmTitle="Confirm" cancelTitle="Cancel" />}
                {showArtistComponent.open && <UniversalModal className="at-universal-padding-20" open={showArtistComponent.open} onClose={closeArtistComopnent}>
                    {getComponents(showArtistComponent.component)}
                </UniversalModal>}
                {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={closeTutorialBox} position='bottom-0'>
                    <ScreenTutorial screen={EScreens.MANAGE_ASSIGNED_ACCOUNT} />
                    {/* at-categorybox ends */}
                </UniversalModal>
                }
            </div>
        </main>
    )
}

export default AgentAssignedAccount