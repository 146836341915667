import { AxiosResponse } from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { images } from '../../assets/image'
import { ITutorailsCategory, ITutorials } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { toastService, tutorialsService } from '../../services'
import StreamingHeaderComponent from '../common/StreamingHeader.component'
import { CategoryComponent } from '../Admin/Tutorials/Category.comonent'
import { screenTypeArray, userTypeArray } from '../../constants'
import { TutorailPlayComponent } from './TuturialPlay.component'
import { UniversalModal } from '../common/Modals/Universal.modal'
import CopyToClipboard from 'react-copy-to-clipboard'
import { RWebShare } from 'react-web-share'
import { APP_URL } from '../../environment'
import PhoneInput from 'react-phone-number-input'


interface IProps extends RouteComponentProps {
    onBack?: Function
}
const ShowTutorialsPage = (props: IProps) => {

    const [categories, setCategories] = useState<Array<ITutorailsCategory>>([])
    const history = useHistory()
    const [userType, setUserType] = useState<string>('')
    const [selectedVideo, setSelectedVideo] = useState<string>('')
    const [showLink, setShowLink] = useState<boolean>(false)
    const [selectedTutorials, setSelectedTutorial] = useState<ITutorials>()
    const [newMessage, setMessage] = useState<string>('')
    const [phoneNumeber, setPhoneNumber] = useState<string>('+1')
    const [loading, setLoading] = useState<boolean>(false)

    const fetchCategories = (type: string = '') => {
        tutorialsService.getCategories().then((res: AxiosResponse<IResponse<Array<ITutorailsCategory>>>) => {
            let { data } = res
            if (data.success) {
                if (type) {
                    let filtered: any = data.data.filter((c: ITutorailsCategory) => {
                        c.tutorials = c.tutorials.filter((t: ITutorials) => t.roles.includes(type))
                        return c
                    })
                    setCategories(filtered)
                    return
                }
                setCategories([...data.data])
            }
        })
    }

    const onChange = (e: FormEvent<HTMLInputElement> | any) => {
        setUserType(e.target.value)
        fetchCategories(e.target.value)
    }

    const onSelectVideo = (t: ITutorials) => {
        setSelectedVideo(t._id)
    }

    const onShareLink = (l: ITutorials) => {
        setShowLink(true)
        setSelectedTutorial(l)
    }

    const onShareVideo = (link: string) => {
        let finalMessage = `${newMessage}. ${link}`
        setLoading(true)
        let data = {
            message: finalMessage,
            phoneNumber: phoneNumeber
        }
        tutorialsService.sendSms(data).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                setMessage('')
                setPhoneNumber('')
                setShowLink(false)
            }
            if (!res.data.success) {
                toastService.error(res.data.message)
            }
            setLoading(false)

        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onClickBack = () => {
        if (props.onBack) {
            props.onBack()
            return
        }
        history.goBack()
    }


    useEffect(() => {
        fetchCategories()
    }, [])

    let shareUrl = `${APP_URL}/?tutorial=${selectedTutorials?._id}`

    return (
        // <div className="at-wrapper">
        //     <div className="at-themeholder">
        //         <StreamingHeaderComponent {...props} />
        //         <main id="at-main" className="at-main at-haslayout">
        <>
            <div className="at-createcollection scroll-style-3 scrollable at-contentarea">
                <div className="at-themehead">
                    <a onClick={() => onClickBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Tutorials</h2>
                </div>
                <form className="at-formtheme at-formcreatecollection" >
                    <fieldset>
                        <div className="form-group form-w-100 at-selectscreen">
                            <span className="at-select at-floatlabel">
                                <select value={userType} className="form-control floating-select" name="" onChange={onChange}>
                                    <option value=""></option>
                                    {userTypeArray.map((s: { label: string, value: string }) => (
                                        <option value={s.value}>{s.label}</option>
                                    ))}
                                </select>
                                <label>Select type of User</label>
                            </span>
                        </div>
                        {/* <div className="form-group at-floatlabel ">
                                    <input type="text" name="createnewcollection" className="form-control" autoComplete="off" placeholder=" " />
                                    <label>Create Tutorial Category</label>
                                </div> */}
                    </fieldset>

                </form>
                {/* Categgory body */}
                <div className="at-themebody">
                    <div>
                        {categories.map((c: ITutorailsCategory) => {
                            return (
                                c.tutorials.length > 0 && <div className="at-categorybox ">
                                    <div className="at-categoryhead">
                                        <h4 className="at-additional-heading">{c.title}</h4>
                                    </div>
                                    {c.tutorials.length > 0 && c.tutorials.map((t: ITutorials) => {
                                        return <TutorailPlayComponent tutorial={t} seletecedVideo={selectedVideo} onSelectVideo={onSelectVideo} onShareLink={onShareLink} />
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {showLink && <UniversalModal open={showLink} onClose={() => setShowLink(false)} position='bottom'>
                    <h5 className="at-popupheading">Share</h5>
                    <div className="form-group">
                        <PhoneInput
                            placeholder="Phone (e.g., +1 412 877 4312)"
                            value={phoneNumeber}
                            className="floating-input-outer"
                            defaultCountry="US"
                            onChange={(username: any) => setPhoneNumber(username)} />
                        {/* <label>Enter phone number</label> */}
                    </div>
                    <div className="form-group at-textarea-height100">
                        <textarea name="details" className="form-control at-border-radius10" placeholder="Type Message" value={newMessage} onChange={(e: any) => setMessage(e.target.value)} />
                    </div>
                    <CopyToClipboard text={shareUrl} onCopy={() => toastService.info('Copied to clipboard')}>
                        <div className="form-group form-w-100 at-inputcopyclipboard">
                            <input type="text" name="name" disabled={true} className="form-control " placeholder={shareUrl} />
                            <i className="icon-link"></i>
                        </div>
                    </CopyToClipboard>

                    <div className="row row-margin-lr7">
                        <div className="col-6 pad-lr-7">
                            <div className="form-group">
                                <CopyToClipboard text={shareUrl} onCopy={() => toastService.info('Copied to clipboard')}>
                                    <button className="at-btn at-longbtn-h40" >COPY LINK</button>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <div className="form-group">

                                <button className="at-btn at-longbtn-h40" type='button' disabled={loading} onClick={() => onShareVideo(shareUrl)}>SHARE VIDEO</button>

                            </div>
                        </div>
                    </div>
                </UniversalModal>
                }
            </div>

        </>
        //         </main>
        //     </div>
        // </div>
    )
}

export default ShowTutorialsPage