import { HttpService } from './base.service'
import {
	IResponse,
	ISpace,
	ISpaceDelete,
	ISpaceUpdate,
	ISpaceAdd,
	IUpdateActiveUsers,
} from '../interfaces'
import { AxiosResponse } from 'axios'
import { ESpaceType } from '../enums'

class SpaceService extends HttpService {
	private readonly prefix: string = 'space'

	getSpaceName = (space: ISpace) => (space?.scope && space.scope == ESpaceType.PRIVATE) ? `${space.name}` : `${space?.userId?.firstName} ${space?.userId?.lastName}'s studio`

	/**
	 * Spaces List
	 */
	list = (): Promise<AxiosResponse<ISpace[]>> => this.get(this.prefix)
	/**
	 * Spaces List
	 */
	listOfActiveSpaces = (data: any): Promise<AxiosResponse<ISpace[]>> => this.get(`${this.prefix}/active-spaces-list`, data)

	/**
	 * Gloabel Spaces List
	 */
	globalSpaces = (): Promise<AxiosResponse<ISpace[]>> => this.get(`${this.prefix}/global-spaces`)

	/**
	 * Get User Art Central As Space
	 */
	userArtCentralSpace = (userId: string): Promise<AxiosResponse<ISpace>> => this.get(`${this.prefix}/user-art-central-space/${userId}`)

	/**
	 * Lock Current Space
	 */
	lockUnlockCurrentSpace = (): Promise<AxiosResponse> => this.put(`${this.prefix}/lock-unlock-current-space`)

	/**
	 * Create Space
	 * @param  {ISpace} space
	 */
	add = (space: ISpaceAdd): Promise<AxiosResponse<ISpace | IResponse>> =>
		this.post(this.prefix, space)

	/**
	 * Update active users list
	 * @param  {IUpdateActiveUsers} spaceId
	 */
	updateActiveSpace = (
		payload: IUpdateActiveUsers
	): Promise<AxiosResponse<{ success: boolean; message: string }>> =>
		this.post(this.prefix + '/active-user', payload)

	/**
	 * Update active active space users
	 */
	inviteUserToActiveSpace = (
		payload: Array<string>
	): Promise<AxiosResponse<any>> =>
		this.put(this.prefix + '/active-space/add-users', payload)

	/**
	 * Update Space
	 * @param  {ISpace} space
	 */
	udpate = (space: ISpaceUpdate): Promise<AxiosResponse<ISpace>> =>
		this.put(this.prefix, space)

	/**
	 * Remove Space(s) form space list
	 * @param  {ISpace} space
	 */
	remove = (spaces: ISpaceDelete): Promise<any> =>
		this.delete(this.prefix, undefined, spaces)

	deafUserSpeechToText = (id: string): Promise<any> => this.get(`${this.prefix}/active-speech-text-deaf-user/${id}`)


	/**
	 * Get Admin Art Central As Space
	 */
	adminArtCentralSpace = (): Promise<AxiosResponse<ISpace>> => this.get(`${this.prefix}/admin-art-central-space`)
}

export const spaceService = new SpaceService()
