import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import LoadingComponent from '../../components/common/Loading.component'
import { ICollection, ICollectionItem, ITemplateReducerState } from '../../interfaces'
import { collectionService } from '../../services/collection.service'
import { collectionImage, getUserTemplateRoute } from '../../shared'
import { setInPlatformWebState } from '../../store/actions'
import { templateSelector, userSelector } from '../../store/selectors'
import { CustomImage } from '../../templates/components/CustomImage'
import { ERole } from '../../enums'
import { routes } from '../../router'
import { IResponse } from '../../interfaces/db.interface'

interface IProps {
    id?: string
    onClickOnItem: Function,
    onGoBack: Function
}
const CollectionPage = ({ id, onClickOnItem, onGoBack }: IProps) => {
    const history = useHistory()
    const params = (useParams() as any).id
    const user = useSelector(userSelector)
    const activeSpace = user.activeSpace
    const dispatch = useDispatch()
    const template = useSelector(templateSelector)
    const [collection, setCollection] = useState<ICollection>()
    const [loading, setLoading] = useState<boolean>(false)

    const fetch = useCallback(() => {
        if (!id) return
        setLoading(true)
        collectionService.show(id).then((response: AxiosResponse<IResponse<ICollection>>) => {
            setCollection(response.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    const confirmDeactivation = () => {

        onGoBack()
        return
        // AgoraLeaveActions.triggerAction(true)
        // accountService.deactivateSpace().then((response: any) => {
        //     let spaceName = spaceService.getSpaceName(activeSpace);
        //     toastService.info(`You have exited ${spaceName}`)
        //     dispatch(setStudioUser({} as any))
        //     dispatch(refreshUser())
        //     dispatch(activateSpaceAction(false))

        //     // $('#at-wrapper').removeClass('expanded-video-list')
        //     // $('#at-wrapper').removeClass('speech-to-text-enabled')
        //     // $('#at-wrapper').removeClass('show-in-full-screen')
        //     onGoBack()
        // }, (error: any) => {
        //     toastService.error('Some error occurred.')

        // })
    }

    const WebButtonText = () => {
        let cUser = collection?.user
        if (cUser && cUser.roles && cUser.roles.includes(ERole.ARTIST)) return "VISIT ARTIST WEBSITE"
        if (cUser && cUser.roles && cUser.roles.includes(ERole.AGENT)) return "VISIT GALLERY WEBSITE"
        if (cUser && cUser.roles && cUser.roles.includes(ERole.CONSULTANT)) return "VISIT PROVIDER WEBSITE"

        return "VISIT WEBSITE"
    }

    useEffect(() => {
        fetch()
    }, [])

    const onClikcOnTemplate = () => {
        let cUser = collection?.user
        if (!cUser) return
        if (window.innerWidth > 770) {
            if (cUser.template && (cUser.template as ITemplateReducerState).url) {
                // window.location.replace(`${APP_URL}/${(cUser.template as ITemplateReducerState)?.url}/${getUserTemplateRoute(cUser.template as ITemplateReducerState)}`)
                // history.replace(`${(cUser.template as ITemplateReducerState)?.url}/${getUserTemplateRoute(cUser.template as ITemplateReducerState)}`)
                dispatch(setInPlatformWebState({
                    nextPath: `${(cUser.template as ITemplateReducerState)?.url}`,
                    isActive: true,
                    previousPath: routes.home,
                    activeSplitScreen: true
                }))
                history.push(routes.loadingRoutes)
                return
            }
        }
    }

    return <div >
        <section className="At-ArtistsPage ">
            <div className="container">
                <div className="row ">
                    <div className="col-12 at-panelcollection-heading">
                        <a onClick={() => confirmDeactivation()} className="at-left-arrowicon"><i className="icon-arrow-bold"></i></a>
                        <div className="at-panelcollection-text">
                            {collection && collection.user && <h2> {collection.user?.roles.includes(ERole.AGENT) ? collection.user?.gallery_name : `${collection.user?.firstName} ${collection.user?.lastName}`} </h2>}
                            {collection && <h3 className="at-color-grey">{collection.title}</h3>}
                        </div>
                        {(collection?.user?.template && (collection?.user?.template as ITemplateReducerState).url) && <button className="at-btn" onClick={() => onClikcOnTemplate()}>{WebButtonText()}</button>}

                    </div>

                    {collection && collection.items.length > 0 && collection.items.map((i: ICollectionItem) => {

                        return (<div className="col-md-6 col-lg-4" key={i.id}>
                            <div className="At-BorderFigureHolder">
                                <div className="At-BorderFigure">
                                    <CustomImage src={collectionImage(i, `${i.url}`)} thumbnail={collectionImage(i, `blur_${i.url}`)} />

                                </div>
                                <div className="At-ArtDetailBox">
                                    {i.name && <h5>
                                        {i.name}
                                    </h5>}
                                    {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>}
                                    <i className="icon-dotted-circle" onClick={() => onClickOnItem(i)}></i>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
                {loading && <LoadingComponent className='at-transaction-loader' />}
            </div>
        </section>
    </div>
}

export default CollectionPage