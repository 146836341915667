import { AxiosError, AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { images } from '../../../../../assets/image'
import { ECuratorialOfferStatus, EMileStoneStatus } from '../../../../../enums'
import { ICuratorialOfferDocument, ICuratorialProposalArtsDocument, IMilestone, INotification } from '../../../../../interfaces'
import { IResponse } from '../../../../../interfaces/db.interface'
import { curatorialService, toastService } from '../../../../../services'
import { CurrencyFormat } from '../../../../common/CurrencyFormat'
import { CurrencyField } from '../../../../common/CurrencyInput'
import { MessageModal } from '../../../../common/Modals/MessagePopup'
import { SeeOfferProposalArtsComponent } from '../../../../User/Services/CuratorialServices/Offer/SeeOfferProposalArts.component'


interface IProps {
    offer: ICuratorialOfferDocument
    fetchOffers: Function
}
export const ParticipantCuratorialOfferView = ({ offer, fetchOffers }: IProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const onAccept = () => {
        setLoading(true)
        curatorialService.acceptCuratorialOffer(offer._id).then((res: AxiosResponse<IResponse<ICuratorialOfferDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetchOffers()
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onReject = (message: string) => {
        setLoading(true)
        curatorialService.rejectCuratorialOffer(offer._id, { message }).then((res: AxiosResponse<IResponse<ICuratorialOfferDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetchOffers()
            }
            setLoading(false)
            closeMessageBox()
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onRequestPayment = (m: IMilestone) => {
        setLoading(true)
        curatorialService.requestCuratorialOfferPayment(offer._id, { milestone: m._id }).then((res: AxiosResponse<IResponse<ICuratorialOfferDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetchOffers()
            }
            setLoading(false)
            closeMessageBox()
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const openMessageBox = () => {
        setShowMessageBox(true)
    }

    const closeMessageBox = () => {
        setShowMessageBox(false)
    }


    return (
        <div className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-curatorial-wrap">
                    <div className="row at-row-mlr-3 at-curatorial-format ">
                        <div className="col-12">
                            {offer.curatorialService && offer.curatorialService._id && <h5 ><span className="at-color-grey">Project Name:</span> {offer.curatorialService.name}</h5>}
                            {offer && offer._id && <div className="">
                                {/* <p dangerouslySetInnerHTML={{ __html: offer.information }}>
                                </p> */}
                            </div>}
                        </div>

                    </div>
                    {/* row ends */}

                   
                    {offer.proposalArts && offer.proposalArts.length > 0 && offer.proposalArts.map((pA: ICuratorialProposalArtsDocument, index: number) => {
                        return (<SeeOfferProposalArtsComponent proposalArt={pA} key={index} />)
                    })
                    }

                    {/* outer row ends */}

                    <div className="row at-row-mlr-3 at-curatorial-format at-row-fs12">
                        <div className="col-12">
                            <div className="row at-row-mlr-3 mt-2">
                                <div className="col-4">
                                </div>
                                <div className="col-2 at-textcenter">

                                </div>
                                <div className="col-3 at-textcenter">
                                    <hr className="At-Hr5 at-opacity-0" />
                                    <h5 className="at-fontbold">Total Offer</h5>
                                </div>
                                <div className="col-3 at-textcenter">
                                    <hr className="At-Hr5" />
                                    <h5><CurrencyFormat amount={Number(offer.totalOfferPrice)} /></h5>
                                </div>
                            </div>
                            {/* inner row ends */}
                        </div>
                    </div>
                    {/* outer row ends */}
                    {offer.milestone && offer.milestone.length > 0 && offer.milestone.map((m: IMilestone, index: number) => {
                        return (
                            <div className="row at-row-mlr-3 at-curatorial-format mt-4 align-items-center">
                                <div className="col-6">
                                    <h6 className="at-color-grey">{m.name}</h6>
                                </div>
                                <div className="col-3 at-textcenter">
                                    <h6><CurrencyFormat amount={Number(m.payment)} /></h6>
                                </div>
                                {offer.offerStatus === ECuratorialOfferStatus.ACCEPTED && <div className="col-3 at-textcenter">
                                    {m.status === EMileStoneStatus.PAID && <h6 className="at-color-orange at-fs12">Paid</h6>}
                                    {m.status === EMileStoneStatus.PENDING && <h6 className="at-color-orange at-fs12 cursor-pointer" onClick={() => onRequestPayment(m)}>{loading ? "Wait...":'Request Payment'}</h6>}
                                    {/* {m.status === EMileStoneStatus.PENDING && loading && <h6 className="at-color-orange at-fs12 cursor-pointer" >Wait</h6>} */}
                                    {m.status === EMileStoneStatus.REQUESTED && <h6 className="at-color-orange at-fs12">Requested</h6>}
                                </div>}
                            </div>
                        )
                    })}
                    {/* row ends */}
                    {/* row ends */}
                    <div className="row at-row-mlr-3 at-curatorial-format">
                        <div className="col-6">
                            <hr className="At-Hr5 at-opacity-0" />
                            <h6 className="at-fontbold">Total Offer</h6>
                        </div>
                        <div className="col-3 at-textcenter">
                            <hr className="At-Hr5" />
                            <h6><CurrencyFormat amount={Number(offer.totalOfferPrice)} /></h6>
                        </div>
                        <div className="col-3">
                        </div>
                    </div>
                    {/* inner row ends */}
                    {offer.offerStatus === ECuratorialOfferStatus.PENDING && <div className="row at-rowprinting at-row-mlr-3">
                        <div className="col-sm-6 mt-4">
                            <button className="at-btn at-btn-grey at-smallbtn-h40-w100 " disabled={loading} onClick={() => openMessageBox()}>{loading ? `Wait...` : `Reject`}</button>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <button className="at-btn at-btn-orange at-smallbtn-h40-w100" disabled={loading} onClick={() => onAccept()}>{loading ? `Wait...` : `Accept`}</button>
                        </div>
                    </div>}
                    {offer.offerStatus === ECuratorialOfferStatus.ACCEPTED && <div className="row at-rowprinting at-row-mlr-3">
                        <div className="col-sm-6 offset-sm-3 mt-4">
                            <button className="at-btn at-btn-lightgreen at-color-white at-smallbtn-h40-w100 ">{`Accepted`}</button>
                        </div>
                    </div>}

                    {offer.offerStatus === ECuratorialOfferStatus.REJECTED && <div className="row at-rowprinting at-row-mlr-3">
                        <div className="col-sm-6 offset-sm-3 mt-4">
                            <button className="at-btn at-btn-lightgreen at-color-white at-smallbtn-h40-w100 ">{`Rejected`}</button>
                        </div>
                    </div>}
                    {/* row ends */}
                </div>
            </div>
            {showMessageBox && <MessageModal open={showMessageBox} onClose={closeMessageBox} onSubmit={onReject} message='' title='Send Message' />}
        </div>
    )
}