import React, { useState, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { RouteComponentProps, Link } from 'react-router-dom'
import { images } from '../../assets/image'
import { ITransaction } from '../../interfaces'
import { transactionService } from '../../services/Transaction.service'
import moment from 'moment'
import { CurrencyFormat } from '../common/CurrencyFormat'
import { AdminResolveModal } from './Modals/DisputeResolve.modal'
import { paymentService, toastService } from '../../services'
import { collectionImage } from '../../shared'
import LoadingComponent from '../common/Loading.component'
import { ERole, ETransactionStatus } from '../../enums'
import { ConfirmAlertComponent } from '../common/ConfirmAlert.component'

interface IPageProps extends RouteComponentProps {
    [key: string]: any
}
interface IProps {
    onClick: Function
}

export const AdminManageConflictsComponent = ({ history }: IPageProps) => {
    const [showBuyerDetails, setShowBuyerDetails] = useState<boolean>()
    const [showSellerDetails, setShowSellerDetails] = useState<boolean>()
    const [disputedTransactions, setDisputedTransaction] = useState<Array<ITransaction>>()
    const [showResolvePopup, setShowResolvePopup] = useState<boolean>(false)
    const [selectedDispute, setSelectedDispute] = useState<ITransaction>()
    const [loading, setLoading] = useState<boolean>(false)
    const [returnConfirm, setReturnConfirm] = useState<boolean>(false)
    const [returnNotConfirm, setReturnNotConfirm] = useState<boolean>(false)
    const [acceptLoading, setAcceptLoading] = useState<boolean>(false)
    const [rejectloading, setRejectLoading] = useState<boolean>(false)


    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        transactionService.getDisputedTransaction().then((response: AxiosResponse<Array<ITransaction>>) => {
            let newTransaction = response.data.reverse()
            setDisputedTransaction(newTransaction)
            setLoading(false)
        }).catch(err => console.log(err))
    }


    // const onSubmitDispute = (message: string) => {
    //     let obj = (selectedDispute as ITransaction).disputed
    //     obj.resolve = true
    //     obj.labelStatus=ETransactionStatus.RESOLVED
    //     let data = {
    //         id: selectedDispute?._id,
    //         dispute: { ...obj },
    //         transactionState:ETransactionStatus.RETURN_NOT_ALLOWED
    //     }
    //     transactionService.updateTransactionDispute({...data}).then((res: AxiosResponse<any>) => {
    //         toastService.success("Successfully resolved the dispute")
    //         fetch()
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }

    const onAllowReturn = () => {
        setLoading(true)
        paymentService.allowReturn({ transactionID: selectedDispute?._id, transactionState: ETransactionStatus.RETURN_ALLOWED }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'success') {
                // onSubmitDispute('Successfully allow the user to return art.')
                toastService.success("Successfully resolved the dispute")
                fetch()
                onCloseResolvePopup()
            } else if (res.data && res.data.length > 0) {
                toastService.error(res.data[0].message)
                onCloseResolvePopup()
            } else {
                toastService.error(res.data.message)
                onCloseResolvePopup()
            }
            setReturnConfirm(false)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setReturnConfirm(false)
            onCloseResolvePopup()
            setLoading(false)
        })
    }

    const onNotAllowReturn = () => {
        setLoading(true)
        paymentService.releasePayment({ transactionID: selectedDispute?._id, transactionState: ETransactionStatus.RETURN_NOT_ALLOWED }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                // onSubmitDispute('Successfully release payment to Buyer account.')
                toastService.success("Successfully resolved the dispute")
                fetch()
                onCloseResolvePopup()
            } else if (res.data && res.data.length > 0) {
                toastService.error(res.data[0].message)
                onCloseResolvePopup()
            } else {
                toastService.error(res.data.message)
                onCloseResolvePopup()

            }
            setReturnNotConfirm(false)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            onCloseResolvePopup()
            setReturnNotConfirm(false)
            setLoading(false)
        })
    }

    const onOpenResolvePopup = (item: ITransaction) => {
        setSelectedDispute(item)
        setShowResolvePopup(true)
    }

    const onCloseResolvePopup = () => {
        let obj: any = {}
        setSelectedDispute(obj)
        setShowResolvePopup(false)
    }

    const checkStatus = (item: ITransaction) => {
        // if (item.transactionState === ETransactionStatus.RESOLVED) {
        //     return { color: 'grey', text: 'Refunded', disabled: true }
        // }
        // if (item.transactionState === ETransactionStatus.PAID) {
        //     return { color: 'grey', text: 'Released', disabled: true }
        // }
        if (item.transactionState === ETransactionStatus.DISPUTED) {
            return { color: 'orange', text: 'Resolve Issue', disabled: false }
        }
        return { color: 'grey', text: 'Resolved', disabled: true }
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two ">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Disputes</h2>
                </div>
                <div className="at-transaction-main">
                    {disputedTransactions && disputedTransactions.length > 0 ? (
                        <>
                            {disputedTransactions?.map((item: ITransaction) => {
                                let artist = item.artistID && item.artistID._id ? item.artistID : item.sellerID
                                let isAgent = artist && artist.roles.includes(ERole.AGENT)
                                let artistName = artist && isAgent ? item.itemID?.artistName || `${artist.firstName} ${artist.lastName}` : `${artist.firstName} ${artist.lastName}`
                                return (
                                    <div>
                                        <div className="at-transactionhead">
                                            <div className="at-transact-line">
                                                <h2>Title:<span className="pl-2">{`${item.disputed.reason}`}</span></h2>
                                            </div>
                                            <div className="at-transact-line">
                                                <h2>Description:<span className="pl-2">{`${item.disputed.details}`}</span></h2>
                                            </div>
                                        </div>
                                        <div className="at-transaction-box" >
                                            <div className="at-transact-left">
                                                <div className="at-transact-line">
                                                    <p>{moment(item.createdAt).format("L")}</p>
                                                    <p className="pl-5">{moment(item.createdAt).format("hh:mm:ss a")}</p>
                                                </div>
                                                <div className="at-transact-line">
                                                    <p>Seller:</p>
                                                    <p className="pl-2 text-capitalize"><span onClick={() => setShowSellerDetails(!showSellerDetails)}>{`${item.sellerID.firstName} ${item.sellerID.lastName}`}</span></p>
                                                </div>
                                                <div className="at-transact-line">
                                                    <p>Artist:</p>
                                                    <p className="pl-2 text-capitalize"><span onClick={() => setShowSellerDetails(!showSellerDetails)}>{`${artistName}`}</span></p>
                                                </div>
                                                <div className="at-transact-line">
                                                    <p>Buyer:</p>
                                                    <p className="pl-3 text-capitalize"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>{`${item.buyerID.firstName} ${item.buyerID.lastName}`}</span></p>
                                                </div>
                                            
                                            </div>
                                            <div className="at-transact-right">
                                                {item.itemID && item.itemID.name && <p className=""><span>{item.itemID.name}</span></p>}
                                                <figure>
                                                    {item.itemID && item.itemID.url && <img src={collectionImage(item.itemID, `high_${item.itemID.url}`, true)} alt="" />}
                                                </figure>
                                                {/* <button className="at-btn at-btn-sm at-btn-grey">Unpaid</button> */}
                                            </div>
                                            <div className="at-transactbox-bottom">
                                            <div className="at-transact-line pt-2">
                                                    <p>Price:<span><CurrencyFormat amount={item.totalPrice} /></span></p>
                                                    <p className="pl-3">Shipping: <span><CurrencyFormat amount={item.shippingPrice} /></span></p>
                                                </div>
                                                <button disabled={checkStatus(item).disabled} className={`at-btn at-btn-sm at-btn-${checkStatus(item).color}`} onClick={() => onOpenResolvePopup(item)}>{checkStatus(item).text}</button>
                                           
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    ) : (<>{loading && <LoadingComponent className='at-transaction-loader' />}</>)}
                </div>
            </div>
            {showResolvePopup && <AdminResolveModal
                open={showResolvePopup}
                onClose={onCloseResolvePopup}
                onAllow={() => setReturnConfirm(true)}
                onNotAllow={() => setReturnNotConfirm(true)}
                title="Resolve Dispute"
                message="Please take an action you want to Perform"
                loading={loading}
            />}
            {returnConfirm && 
            <ConfirmAlertComponent
            open={returnConfirm}
            onClose={() => setReturnConfirm(false)}
            onCancel={() => setReturnConfirm(false)}
            onConfirm={onAllowReturn}
            title="Allow buyer to return ART."/>
            }
            {returnNotConfirm && 
            <ConfirmAlertComponent
            open={returnNotConfirm}
            onClose={() => setReturnNotConfirm(false)}
            onCancel={() => setReturnNotConfirm(false)}
            onConfirm={onNotAllowReturn}
            title="Item may not be Returned"/>
            }
        </main>
    )
}
