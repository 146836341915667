import { AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { FormEvent } from 'react';
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ShowArtServiceComponent } from '../../components/ArtCentral/ArtServices/ShowArtService.component';
import ItemViewComponent from '../../components/ArtCentral/collection/ItemView.component';
import { AddressPopup } from '../../components/common/AddressPopup';
import { CurrencyFormat } from '../../components/common/CurrencyFormat';
import { CurrencyField } from '../../components/common/CurrencyInput';
import { ErrorComponent } from '../../components/common/Error.component';
import { ImageLoading } from '../../components/common/ImageLoading';
import { PayPopup } from '../../components/common/Modals/PayPopup';
import { ThanyouPopup } from '../../components/common/Modals/ThankyouPopup';
import { UniversalModal } from '../../components/common/Modals/Universal.modal';
import PortalChild from '../../components/common/PortalChild';
import { EArtistPrintType, EPrintType, ERole } from '../../enums';
import { PLATFORM_FEE } from '../../environment';
import { currencyFormatter } from '../../helper';
import { ICollection, ICollectionItem, IFormates, IFormatesResponse, IOffer, IOFFerResponse, IUser } from '../../interfaces';
import { IResponse } from '../../interfaces/db.interface';
import { IArtistSelectedService, ISelectedService } from '../../interfaces/services.interface';
import { offerService, paymentService, toastService } from '../../services';
import { collectionService } from '../../services/collection.service';
import { collectionImage } from '../../shared';
import { templateSelector, userSelector } from '../../store/selectors';
import { CustomText } from '../components/CustomText';
import TemplateRequestCommisionWork from '../components/TemplateRequestCommission';
import ExhibitionsPage from './ExhibitionsPage'

interface IProps extends RouteComponentProps {

}
interface userAddress {
    shippingAddress: string
    city: string
    zipcode: string
    state: string
    loading: boolean
}

const adressState = {
    shippingAddress: '',
    city: '',
    zipcode: '',
    state: '',
    loading: false
}

const ArtDetailPage = (props: IProps) => {
    const user = useSelector(userSelector)
    const template = useSelector(templateSelector)
    const [art, setArt] = useState<ICollectionItem>()
    const [collection, setCollection] = useState<ICollection>()
    const [offerPrice, setOfferPrice] = useState<string>('')
    const [itemType, setItemType] = useState<string>('')
    const [tax, setTax] = useState<string>('')
    const params = (useParams() as any).id
    const [loading, setLoading] = useState<boolean>(false)
    const [images, setImages] = useState<Array<any>>([])
    const [showBuyModal, setBuyModal] = useState<boolean>(false)
    const [showAdrressPopup, setShowAdrressPopup] = useState<boolean>(false)
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false)
    const [inValidAddress, setInValidAddress] = useState<boolean>(false)
    const [showPayPopup, setPayPopup] = useState<boolean>(false)
    const [selectedFormat, setSelectedFormat] = useState<IFormates>()
    const [offerItem, setOfferItem] = useState<IOffer>()
    const [userAddress, setUserAddress] = useState<userAddress>({ ...adressState })
    const [stripeData, setstripeData] = useState<any>()
    const [errors, setErrors] = useState<any>()
    const [showRequestPopup, setRequestPopup] = useState<boolean>(false)
    const [showService, setShowService] = useState<boolean>(false)
    const [offerState, setOfferState] = useState({
        status: '',
        showThankoutPopup: false,
        message: '',
        approved: false,
    })


    const fetchArt = useCallback(() => {
        setLoading(true)
        collectionService.showItem(params).then((response: AxiosResponse<IResponse<ICollectionItem>>) => {
            let item = checkItemFormats(response.data.data)
            setArt(item)
            setCollection(response.data.data.art_collection)
            let originalImage = [{
                original: collectionImage(response.data.data, `${response.data.data.url}`),
                blurImage: collectionImage(response.data.data, `blur_${response.data.data.url}`),
                thumbnail: collectionImage(response.data.data, `${response.data.data.url}`, true)
            }]

            let attachImages = response.data.data.items?.map((i: any) => {
                return {
                    original: collectionImage(response.data.data, `${i.url}`),
                    blurImage: collectionImage(response.data.data, `blur_${i.url}`),
                    thumbnail: collectionImage(response.data.data, `${i.url}`, true)
                }
            })

            let combine = attachImages && attachImages.length > 0 ? [...originalImage, ...attachImages] : [...originalImage]
            setImages([...combine])
            setLoading(false)

        }, (error: AxiosError) => {
            setLoading(false)
        })
    }, [])

    const checkItemFormats = (item: ICollectionItem) => {
        if (!item) return item
        let formats = item.formates

        if (formats && formats.length > 0) {
            let newFormats = formats.map((f: IFormates) => {
                if (f.printType === EArtistPrintType.UNFRAMED) {
                    f.buyerSelectedService = (f.selectedService as IArtistSelectedService)?.selectedServices[0]
                }
                return f
            })
            item.formates = newFormats
        }
        return item
    }

    useEffect(() => {
        fetchArt()
    }, [])

    const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        let offer = e.target.value
        if (offer && e.target.name === "offer") {
            offer = offer.split("$")[1].replace(/,/gi, '')
        }
        setSelectedFormat(undefined)
        setOfferPrice(offer)
        setItemType('item')
    }

    const setSellerId = () => {

        let studioUser = template.user
        let agent = (art?.assignment?.user as IUser)

        if (!agent) {
            return { sellerID: collection?.user._id, agentID: null, artistID: collection?.user._id }
        }
        if (art?.assignment?.type === 'non-exclusive' && studioUser && !studioUser?.roles.includes(ERole.AGENT)) {
            return { sellerID: collection?.user._id, agentID: null, artistID: collection?.user._id }
        }
        if (art?.assignment?.type === 'non-exclusive' && studioUser?._id === agent._id) {
            return { sellerID: agent._id, agentID: agent._id, artistID: collection?.user._id }
        }
        if (!art?.assignment) {
            return { sellerID: collection?.user._id, agentID: null, artistID: collection?.user._id }
        }

        return { sellerID: agent._id, agentID: agent._id, artistID: collection?.user._id }
    }

    const onMakingOffer = () => {
        if (!art) return
        let price = art?.price ? art?.price : ''
        if (!offerPrice || offerPrice === '0') {
            let obj: any = {}
            obj['offer'] = ['Please Enter value']
            setErrors({ ...obj })
            return
        };
        setErrors(undefined)
        if (Number(offerPrice) >= Number(artOriginalTotalCost(art))) {
            onOpenAddressPopup()
            setItemType('item')
            return
        }
        if (art && collection) {
            setLoading(true)
            let offeredUser = setSellerId()
            offerService.makeOffer({
                offeredPrice: offerPrice,
                offeredBy: user._id,
                offeredTo: offeredUser.artistID,
                itemId: art?.id,
                agentId: offeredUser.agentID,
                approved: parseFloat(offerPrice) >= Number(artOriginalTotalCost(art)) ? true : false,
                pending: parseFloat(offerPrice) >= Number(artOriginalTotalCost(art)) ? false : true
            }).then((response: AxiosResponse<IOFFerResponse>) => {
                setLoading(false)
                setOfferState({
                    message: response.data.message,
                    approved: response.data.approved,
                    status: response.data.status,
                    showThankoutPopup: true
                })
                setOfferPrice('')
            }).catch((error) => {
                console.log("Unable to Make offer", error)
            })
        }
    }

    const onItemBuy = () => {
        setItemType('item')
        setShowAdrressPopup(true)
    }

    const onBuyFormats = (format: IFormates) => {
        let service = format.selectedService as IArtistSelectedService
        if (service && service._id && (service.printType === EArtistPrintType.FRAMED || service.printType === EArtistPrintType.UNFRAMED) && !format.buyerSelectedService) {
            toastService.warning("Please select framing option before buying")
            return
        }
        setSelectedFormat(format)
        setShowAdrressPopup(true)
        setItemType('format')
    }

    const onOpenShowService = (format: IFormates) => {
        if (!format.selectedService) return
        setSelectedFormat(format)
        setShowService(true)
        // this.setState({ formatSelected: format, showService: true })
    }

    const onCloseShowService = () => {
        setSelectedFormat(undefined)
        setShowService(false)
        // this.setState({ formatSelected: undefined, showService: false })
    }

    const onSubmitShowService = (service: ISelectedService) => {
        if (!selectedFormat) return
        let formats = art?.formates
        let newItem = art
        if (newItem && formats && formats.length > 0) {
            let newFormats = formats.map((f: IFormates) => {
                if (f._id === selectedFormat?._id) {
                    f.buyerSelectedService = service
                }
                return f
            })
            newItem.formates = newFormats
            setArt({ ...newItem })
            // this.setState({ item: newItem })
        }
        onCloseShowService()
    }


    const handlePayment = () => {
        let offerData = offerItem
        let data
        setLoading(true)
        let sellerData: any = setSellerId()

        if (itemType === 'format' && selectedFormat) {
            let totalPrice = `${parseFloat(selectedFormat?.price) + parseFloat(selectedFormat.shipping) + 10 + Number(tax)}`

            let serviceData = {}
            if (selectedFormat.selectedService && (selectedFormat.selectedService as IArtistSelectedService)._id) {
                let service = selectedFormat.selectedService as IArtistSelectedService
                if (service.printType === EArtistPrintType.OWN) {
                    serviceData = {
                        service: service._id,
                    }
                } else {
                    serviceData = {
                        service: service._id,
                        buyerService: selectedFormat.buyerSelectedService?._id
                    }
                }
            }

            data = {
                name: art?.name,
                price: selectedFormat?.price,
                tax: tax,
                shippingPrice: selectedFormat?.shipping,
                totalPrice: totalPrice,
                shippingAddress: userAddress.shippingAddress,
                zip: userAddress.zipcode,
                city: userAddress.city,
                state: userAddress.state,
                exclusive: art?.assignment?.type === "exclusive" ? true : false,
                ...sellerData,
                buyerID: user._id,
                itemID: art?.id,
                itemDescription: art?.description,
                quantity: 1,
                formatesId: selectedFormat._id,
                itemName: selectedFormat.size,
                ...serviceData
            }
        }

        if ((offerItem) && itemType === 'offered') {
            let totalPrice = ''
            if (art && offerData) {
                totalPrice = `${parseFloat(offerItem?.offeredPrice) + parseFloat(art?.shipping) + 10 + Number(tax)}`
            }
            data = {
                name: art?.name,
                price: offerItem?.offeredPrice,
                tax: tax,
                shippingPrice: art?.shipping,
                totalPrice: totalPrice,
                shippingAddress: userAddress.shippingAddress,
                zip: userAddress.zipcode,
                city: userAddress.city,
                state: userAddress.state,
                exclusive: art?.assignment?.type === "exclusive" ? true : false,
                ...sellerData,
                buyerID: offerItem?.offeredBy,
                itemID: art?.id,
                itemDescription: art?.description,
                quantity: 1,
                itemName: offerItem.itemId.name
            }
        }

        if (art && itemType === 'item') {
            let totalPrice = offerPrice ? offerPrice : art?.price
            data = {
                name: art?.name,
                price: totalPrice,
                tax: tax,
                shippingPrice: art?.shipping,
                totalPrice: totalPrice,
                shippingAddress: userAddress.shippingAddress,
                zip: userAddress.zipcode,
                city: userAddress.city,
                state: userAddress.state,
                exclusive: art?.assignment?.type === "exclusive" ? true : false,
                ...sellerData,
                buyerID: user._id,
                itemID: art?.id,
                itemDescription: art?.description,
                quantity: 1,
                itemName: art.name
            }
        }
        handleAPICall(data)
    }

    const handleAPICall = (data: any) => {
        setLoading(true)
        paymentService.checkout({
            ...data
        }).then(resp => {
            if (resp.data.success) {
                setstripeData({
                    id: resp.data.id,
                    clientSecret: resp.data.client_secret
                })
                setShowPaymentForm(true)
                setLoading(false)

            }
            if (!resp.data.success) {
                setShowPaymentForm(false)
                setLoading(false)
            }

        }).catch(error => {
            setShowPaymentForm(false)
            setLoading(false)
            console.log(error)
        })

    }

    const openPayPopup = () => setPayPopup(true)
    const closePayPopup = () => {

        setPayPopup(false)
        setLoading(false)
        setShowPaymentForm(false)
    }
    const onConfirmPay = () => {
        handlePayment()
    }
    const onSubmitPayPopup = () => {

        let data = (art as ICollectionItem)
        if (itemType !== 'format') {
            data.sold = true
        }
        setShowPaymentForm(false)
        setLoading(false)
        setPayPopup(false)
        setstripeData(undefined)
        setArt(data)
        setOfferPrice('')
    }

    const onOpenAddressPopup = () => setShowAdrressPopup(true)
    const onAddressClose = () => setShowAdrressPopup(false)
    const onSubmitAddressPopup = (address: userAddress) => {
        setUserAddress(address)
        getSaleTax(address)
    }

    const getSaleTax = (address: userAddress) => {

        setLoading(true)


        let price = itemType === 'offered' ? offerItem?.offeredPrice : offerPrice ? offerPrice : art?.price
        let shipping: any = art?.shipping
        if (itemType === 'format' && selectedFormat) {
            price = Number(Number(selectedFormat.price)).toFixed(2)
            let service = selectedFormat?.selectedService as IArtistSelectedService
            if (service && (service.printType === EArtistPrintType.UNFRAMED || service.printType === EArtistPrintType.FRAMED)) {
                console.log((selectedFormat.selectedService as IArtistSelectedService)?.selectedServices[0]?.totalCost)
                price = Number(Number(price) + Number(selectedFormat.buyerSelectedService?.totalCost || 0) + Number(PLATFORM_FEE)).toFixed(2)
            }

            if (service && service.printType === EArtistPrintType.OWN && service.artistOwnService && service.artistOwnService.price) {
                price = Number(Number(price) + Number(service.artistOwnService.price) + Number(service.artistOwnService.shipping)).toFixed(2)
            }
            shipping = selectedFormat.shipping
        }
        if (price) {
            let totalPrice = `${parseFloat(price) + parseFloat(shipping) + parseFloat(PLATFORM_FEE)}`
            paymentService.taxPayment({
                state: address.state,
                address: address.shippingAddress,
                price: totalPrice,
                sellerID: setSellerId().sellerID,
                zip: address.zipcode,
                city: address.city
            }).then((res: AxiosResponse<any>) => {
                if (res.data.status === 'success') {
                    if (!res.data.total_tax || isNaN(res.data.total_tax)) {
                        setInValidAddress(true)
                        setLoading(false)
                        return
                    }

                    setTax(`${res.data.total_tax}`)
                    setLoading(false)
                    openPayPopup()
                    onAddressClose()
                }
                if (res.data.status === 'Fail') {
                    setInValidAddress(true)
                    setLoading(false)
                    return
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const getActiveFormats = (item: ICollectionItem) => {
        let { formates } = item
        if (formates && formates.length > 0) {
            let check = formates.filter((format: IFormates) => format.active)
            if (check && check.length > 0) {
                return true
            }
            return false
        }
        return false
    }

    const openBuyModal = () => {
        setBuyModal(true)
    }

    const closeModal = () => {
        setBuyModal(false)
    }

    useEffect(() => {
        if (tax) {
            openPayPopup()
            onAddressClose()
        }
    }, [tax])

    const checkUserProfile = () => {
        let _user = art?.assignment?.user as IUser;
        if (user.roles.includes(ERole.AGENT) && user._id === _user?._id) {
            return true
        }
        if (user.roles.includes(ERole.ARTIST) && user._id === art?.art_collection.user._id) {
            return true
        }

        return false
    }

    const openRequestPopup = () => {
        setRequestPopup(true)
    }

    const closeRequestPopup = () => {
        setRequestPopup(false)

    }

    const artOriginalTotalCost = (art: ICollectionItem) => {
        let total = Number(art.price) + Number(art.shipping) + Number(PLATFORM_FEE)
        return `${total}`
    }

    const artFormatesTotalCost = (f: IFormates) => {
        let total = Number(f.price) + Number(f.shipping) + Number(PLATFORM_FEE)
        let service = f.selectedService as IArtistSelectedService
        if (service && (service.printType === EArtistPrintType.UNFRAMED || service.printType === EArtistPrintType.FRAMED)) {
            console.log((f.selectedService as IArtistSelectedService)?.selectedServices[0]?.totalCost)
            total = total + Number(f.buyerSelectedService?.totalCost || 0) + Number(PLATFORM_FEE)
        }

        if (service && service.printType === EArtistPrintType.OWN && service.artistOwnService && service.artistOwnService.price) {
            total = total + Number(service.artistOwnService.price) + Number(service.artistOwnService.shipping)
        }
        return Number(total).toFixed(2)
    }

    const getButtonColor = (f: IFormates) => {
        if (f.selectedService && ((f.selectedService as IArtistSelectedService).printType === EArtistPrintType.FRAMED || (f.selectedService as IArtistSelectedService).printType === EArtistPrintType.UNFRAMED) && !f.buyerSelectedService) {
            return 'at-btn-grey'
        }

        if (f.selectedService && (f.selectedService as IArtistSelectedService).printType === EArtistPrintType.OWN) {
            return ''
        }

        return ''
    }

    const getPrinterSizes = (f: IFormates) => {
        if (!f.selectedService) return
        let services = f.selectedService as IArtistSelectedService
        if (services && (services.printType === EArtistPrintType.FRAMED || services.printType === EArtistPrintType.UNFRAMED)) {
            if (!f.buyerSelectedService) return null
            return `${f.buyerSelectedService.selectedSize.height}x${f.buyerSelectedService.selectedSize.width}`
        }

        if (services && services.printType === EArtistPrintType.OWN) {
            if (!services.artistOwnService || !services.artistOwnService.price) return null
            return `${services.artistOwnService.height}x${services.artistOwnService.width}`
        }
    }

    const checkFramedOption = () => {
        
        if (!art) return false

        if (art.formates.filter((f: IFormates) => (f.printType === EPrintType.FRAMED && f.active === true)).length > 0){
            return true
        }

        return false
    }

    const openThankPopup = () => setOfferState({ ...offerState, showThankoutPopup: true })
    const closeThankPop = () => setOfferState({ ...offerState, showThankoutPopup: false })

    let userPage = checkUserProfile()

    let price = offerPrice ? offerPrice : (itemType === 'item' ? artOriginalTotalCost(art as ICollectionItem) : offerItem?.offeredPrice)
    return (
        <div>
            <section className="At-ArtDetailPage At-Section-Mh800">
                <div className="container">
                    {art && <div className="row ">
                        <div className="col-xl-7 col-lg-12 col-sm-12">
                            <div className="At-ArtDetailFigure">
                                <figure className="At-ImageGallery">
                                    <ImageGallery
                                        items={images}
                                        showFullscreenButton={true}
                                        showPlayButton={false}
                                        showNav={false}
                                        renderItem={(item: any) => <ImageLoading src={item.original} className="image-gallery-image" thumbnail={item.blurImage} />}
                                    />
                                </figure>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-12 At-PricingMain">
                            <div className="At-ArtDetailContent">
                                {/* <h5>Art Details</h5> */}
                                {art && <h3>{art.name}</h3>}
                                <p>Artist:
                                    <strong className="text-capitalize">
                                        {collection && collection?.user?.roles?.includes(ERole.AGENT) ? ` ${art.artistName}` || ` ${collection?.user.firstName} ${collection?.user.lastName}` : ` ${collection?.user.firstName} ${collection?.user.lastName}`}
                                    </strong>
                                </p>
                                {art && art.height && art.width && art.artTechnique && <p className=" mt-3">{art.height}" X {art.width}" {art.depth ? `X ${art.depth}"` : null} {art.artTechnique} </p>}
                                {art && <CustomText> <p dangerouslySetInnerHTML={{ __html: art?.description }}></p></CustomText>}
                                {userPage &&
                                    <div className="row my-3 d-flex align-items-start at-row-original">
                                        <div className="col-6 pad-lr-7">
                                            <label className="at-pricelabel" htmlFor="">ORIGINAL</label>
                                            <span className="at-pricedetail at-pricedetail-two">Price: <em>{currencyFormatter.format(Number(artOriginalTotalCost(art)) || 0)}</em></span>
                                        </div>
                                    </div>
                                }
                                <div className="At-PricingMainInner clearfix">
                                    {!userPage && !art?.sold && !art?.reserved && <div className="row my-3 d-flex align-items-start at-row-original">
                                        <div className="col-6 pad-lr-7">
                                            <label className="at-pricelabel" htmlFor="">ORIGINAL</label>
                                            <span className="at-pricedetail at-pricedetail-two">Price: <em>{currencyFormatter.format(Number(artOriginalTotalCost(art)) || 0)}</em></span>
                                        </div>
                                        {art?.offerState ? <div className="col-6 pad-lr-7">
                                            <label className="at-pricelabel" htmlFor="">Offer</label>
                                            <div className='form-group position-relative'>
                                                <CurrencyField
                                                    type="text"
                                                    name='offer'
                                                    className="form-control text-capitalize at-input-bgwhite"
                                                    onChange={onChangeText}
                                                    placeholder={`${currencyFormatter.format(Number(artOriginalTotalCost(art)) || 0)}`}
                                                    value={offerPrice} />

                                                <button type="button" className="at-sendicon" onClick={onMakingOffer}><i className="icon-send"></i></button>
                                                {errors && errors['offer'] && <ErrorComponent errors={errors['offer']} multiple={false} />}
                                            </div>
                                        </div> : (
                                            <div className="col-6 pad-lr-7">
                                                <div className='form-group position-relative'>
                                                    <label className="at-pricelabel invisible">.</label>

                                                    <button className="at-btn at-btn-buyoriginal" onClick={onItemBuy}>BUY</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>}
                                    {/* {collection?.user._id !== user._id && (art?.sold || art?.reserved) && <div className="form-group">
                                        <button className="at-btn at-minwidth-100" onClick={() => history.push(`${routes.artCentral.commissionWork.requestCommissionwork}/${item.id}`, { studioUser })}>Request Commission work</button>
                                    </div>} */}
                                    {art?.formates && art.formates.length > 0 && <div className="row mt-4">
                                        <div className="col-6">
                                            <p className="At-TextOrangeULine">Other Formats</p>
                                        </div>
                                        <div className="col-3 text-center">
                                            <p className="At-TextOrangeULine">Price</p>
                                        </div>
                                        <div className="col-3"></div>
                                    </div>}
                                    {art?.formates && art.formates.length > 0 && art.formates.map((f: IFormates) => {
                                        return (
                                            f.active && <div className="row At-RowPricing align-items-center">
                                                <div className="col-6">
                                                    <h6>{f.size} {getPrinterSizes(f)}</h6>
                                                    {f.printType === EArtistPrintType.FRAMED && <p className="mb-0">Framed <a className="at-orangelinkspan" onClick={() => onOpenShowService(f)}><span>Select Option</span> *</a></p>}
                                                    {f.printType === EArtistPrintType.UNFRAMED && <p className="mb-0">Unframed <a className="at-orangelink" onClick={() => onOpenShowService(f)}>See Details</a></p>}
                                                </div>
                                                <div className="col-3 text-center">
                                                    <p className="At-ColorGrey">
                                                        <CurrencyFormat amount={artFormatesTotalCost(f)} />
                                                    </p>
                                                </div>
                                                {!userPage && <div className="col-3">
                                                    <button className={`at-btn-fullblock px-2 ${getButtonColor(f)}`} onClick={() => onBuyFormats(f)}>BUY</button>
                                                </div>}
                                            </div>
                                        )
                                    })}
                                    <p className="at-countryshippingtext mt-3 mb-0">In-country shipping included in the price. Sales tax not included in the price.</p>
                                    {checkFramedOption() && <p className="at-countryshippingtext mb-3">* The cost of the print medium &amp; framing option you select will be added to the shown print price.</p>}

                                    {!userPage && collection?.user._id !== user._id && (art?.sold || art?.reserved) && <div className="form-group mt-4 mb-0">
                                        <button className="at-btn at-minwidth-100" onClick={() => openRequestPopup()}>Request Commission work</button>
                                    </div>}
                                    {art?.sold && <h6 className="at-soldlabel">SOLD</h6>}
                                    {art?.reserved && <h6 className="at-soldlabel">Reserved</h6>}
                                </div>
                            </div>

                        </div>

                    </div>}
                </div>
                {/* {showAdrressPopup && <div className={`at-thankyou-popup at-themepopupholder at-themepopup-template ${showAdrressPopup && 'at-showthemesignuppopup'}`}>
                        <AddressPopup onClose={onAddressClose} onSubmit={onSubmitAddressPopup} user={user} inValidAddress={inValidAddress} showLoading={loading} />
                    </div>} */}
                <PortalChild>
                    {showAdrressPopup && <div className={`at-thankyou-popup at-themepopupholder ${showAdrressPopup && 'at-showthemesignuppopup'}`}>
                        <AddressPopup onClose={onAddressClose} onSubmit={onSubmitAddressPopup} user={user} inValidAddress={inValidAddress} showLoading={loading} />
                    </div>}
                    {showPayPopup && <div className={`at-thankyou-popup at-themepopupholder ${showPayPopup && 'at-showthemesignuppopup'}`}>
                        <PayPopup message='' loading={loading} tax={tax} platformFee={10} offerPrice={itemType === 'format' ? artFormatesTotalCost(selectedFormat as IFormates) : price} item={itemType === 'format' ? selectedFormat : art} onClose={closePayPopup} onConfirmPay={onConfirmPay} showPaymentForm={showPaymentForm} onSubmit={onSubmitPayPopup} setDropinInstance={() => { }} stripeData={stripeData} />
                    </div>
                    }

                    {offerState.showThankoutPopup && <div className={`at-thankyou-popup at-themepopupholder ${offerState.showThankoutPopup && 'at-showthemesignuppopup'}`}>
                        <ThanyouPopup header={offerState.status === 'fail' ? 'Your has been failed' : "Thank you for your offer"} message={offerState.message || ''} onClose={closeThankPop} onSubmit={closeThankPop} buttonText={offerState.approved ? "Next" : 'Ok'} />
                        {/* <AddressPopup message={message} onClose={this.closeThankPop} onSubmit={this.onSubmitThankPopup} buttonText={buttonText} /> */}
                    </div>}

                    {showRequestPopup && <UniversalModal open={showRequestPopup} onClose={closeRequestPopup}>
                        <TemplateRequestCommisionWork user={user} art={(art as ICollectionItem).id as string} artUser={collection?.user as IUser} />
                    </UniversalModal>}
                    {showService && selectedFormat && <UniversalModal open={showService} onClose={onCloseShowService} >
                        <ShowArtServiceComponent format={selectedFormat} onSubmitShowService={onSubmitShowService} />
                    </UniversalModal>}
                </PortalChild>
            </section>
        </div>
    )
}

export default ArtDetailPage