import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { images } from '../../../assets/image'
import { ITutorailsCategory, ITutorials } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { toastService, tutorialsService } from '../../../services'
import { eventImages } from '../../../shared'
import { UniversalModal } from '../../common/Modals/Universal.modal'
import { AddTutorial } from './AddTutorial.component'

interface IProps {
    category: ITutorailsCategory
    fetch: Function
}
export const CategoryComponent = ({ category, fetch }: IProps) => {
    const [showTutorialBox, setTutorialBox] = useState<boolean>(false)
    const [tutorials, setTutorials] = useState<Array<ITutorials>>([])
    const [selectedTutorial, setSelectedTutorial] = useState<ITutorials>()
    const [loading, setLoading] = useState<boolean>(false)

    const openTutorialBox = () => {
        setTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setSelectedTutorial({} as any)
        setTutorialBox(false)
    }

    const onSubmitTutorialBox = () => {
        fetch()
        closeTutorialBox()
    }

    const onEdit = (t: ITutorials) => {
        setSelectedTutorial(t)
        openTutorialBox()
    }

    const onDelete = (t: ITutorials) => {
        setLoading(true)
        tutorialsService.deleteTutorial(t._id).then((res: AxiosResponse<IResponse<ITutorials>>) => {
            let { data } = res
            if (data.success) {
                toastService.success(data.message)
                fetch()
            } else {
                toastService.error(data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (category) {
            setTutorials(category.tutorials)
        }
    }, [category])
    return (
        <div>
            <div className="at-categorybox">
                <div className="at-categoryhead">
                    <h4 className="at-additional-heading">{category.title}</h4>
                    <button className="at-btnadd-relative c-sunshadebg" onClick={() => openTutorialBox()}>
                        <i className="icon-add-bold"></i>
                    </button>
                </div>
                {tutorials.length > 0 && tutorials.map((t: ITutorials) => {
                    return (
                        <div className="at-categoryitem">
                            <figure>
                                <img src={t.cover ? eventImages({}, t.cover, true) : eventImages({}, t.tutorial.thumbnail, true)} className="" alt="" />
                            </figure>
                            <div className="at-rightcateg">
                                <h6>{t.name}</h6>
                                <ul className="at-categaction">
                                    <li>
                                        <button disabled={loading} className="at-btndelete" onClick={() => onDelete(t)}>
                                            <i className="icon-delete2"></i>
                                        </button>
                                    </li>
                                    <li>
                                        <button disabled={loading} className="at-btndelete" onClick={() => onEdit(t)}>
                                            <i className="icon-edit3"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* at-categorybox ends */}
 
            {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={() => closeTutorialBox()} position='bottom'>
                <AddTutorial category={category} submit={onSubmitTutorialBox} t={selectedTutorial} onClose={closeTutorialBox} />
            </UniversalModal>
            }
        </div>
    )
}
