import React, { useEffect, useState } from 'react'
import { IMilestone, INotification } from '../../interfaces'
import { CurrencyFormat } from '../common/CurrencyFormat'
import { ProposalImageView } from '../common/ProposalImageView'

interface IProps {
    notification: INotification
}

export const RequestProposal = ({ notification }: IProps) => {
    let proposal = notification.proposalID ? notification.proposalID : null
    let filterMilStone = proposal?.milestone.filter((m: IMilestone) => m.status === 'requested')
    return (
        <div className="at-transaction-wrap">
            <div className="at-transaction-box">
                {proposal && <div className="at-rejectaccept-proposal">
                    <h5><span>Project Pictures</span></h5>
                    {notification.itemId && <figure className='at-figure-rejectaccept'>
                        {proposal && <ProposalImageView item={proposal} />}
                        {/* <img src={collectionImage(notification.itemId, `high_${notification.itemId.url}`, true)} className="img-fluid" alt="" /> */}
                    </figure>}
                    {notification.itemId && <h5 className="mb-0 text-left"><span className="at-color-grey">Art Name: </span>{notification.itemId?.name}</h5>}
                    <h5 className="mb-0 text-left"><span className="at-color-grey">Description: </span>{proposal.description}</h5>
                    <ul className="d-flex mt-2">
                        <li className="mr-3"><h5><span className="at-color-grey">Price: </span><CurrencyFormat amount={proposal.budget} /></h5></li>
                        <li><h5><span className="at-color-grey">Shipping: </span><CurrencyFormat amount={proposal.shipping} /></h5> </li>
                    </ul>
                    <div className="at-transfer-amount at-transfer-amount-threecol at-haslayout mt-1">
                        <div className="at-transfer-upper">
                            <h5 className="at-text-underline text-center">Payment Schedule</h5>
                            {proposal.milestone && proposal.milestone.length > 0 && proposal.milestone.map((item: IMilestone, index:any) => {
                                return (<div className="row align-items-center" key={index}>
                                    <div className="col-5 at-custcol-4">
                                        <h4>
                                            {item.name}
                                        </h4>
                                    </div>
                                    <div className="col-4 at-custcol-4">
                                        <h5 className="text-center"><CurrencyFormat amount={item.payment} /></h5>
                                    </div>
                                    {item.status === 'requested' && <div className="col-3 at-custcol-4">
                                        <button className="at-btn at-paybtn" >Next</button>
                                    </div>}
                                </div>)
                            })}

                        </div>
                        {filterMilStone && filterMilStone.length > 0 ? null : <div className="at-transfer-upper text-center mt-2">
                            <button className="at-btn at-btn-sm" >Next</button>
                        </div>}
                    </div>
                </div>}
            </div>
        </div>)
}
