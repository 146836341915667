import React, { Fragment, useState } from 'react'
import { PullToRefresh, PullDownContent, ReleaseContent } from 'react-js-pull-to-refresh'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { ERole } from '../../../enums'
import { ICollection, IUser } from '../../../interfaces'
import { lang } from '../../../locale'
import { routes } from '../../../router'
import { assignmentService } from '../../../services'
import { collectionService } from '../../../services/collection.service'
import { collectionImage } from '../../../shared'
import { authSelector } from '../../../store/selectors'
import { ImageLoading } from '../../common/ImageLoading'
import LoadingComponent from '../../common/Loading.component'
import { DescriptionModal } from '../../common/Modals/Description.modal'
import { MessageModal } from '../../common/Modals/MessagePopup'
import PostShimmeringComponent from '../../common/PostShimmering.component'
import { UserPicComponent } from '../../User/index.component'
import HeartComponent from '../feed/HeartIcon.component';


interface IProps {
    fetch: Function
    page: number,
    loading: boolean,
    isZoomed: boolean,
    filtered: boolean,
    showSearch: boolean,
    showMessageBox: boolean
    next: boolean,
    query: string,
    collections: Array<ICollection>
    onScroll: Function
    onOpenSearch: Function
    onToggleFilter: Function
    onSearch: Function
    user: IUser
    openArtCentralSpacePopup: Function
    openMessageBox: Function
    onSumbitMessageBox: Function
    closeMessageBox: Function
    closeSearch: Function
    count: any
    onChangeRole: Function
    role: string
}
export const MobileArtCollections = (props: IProps) => {
    const token = useSelector(authSelector).access_token
    const loaction = useLocation()
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [selectedCollection, setSelectedCollection] = useState<ICollection>()

    const openInfoBox = (c: ICollection) => {
        setShowInfo(true)
        setSelectedCollection(c)
    }

    const closeInfoBox = () => {
        setShowInfo(false)
        setSelectedCollection({} as any)
    }

    const onSubmitInfoModal = () => {
        closeInfoBox()
        props.fetch()
    }

    const renderCollection = (c: ICollection) => {
        let cUser = c.user
        let isAgent = cUser?.roles.includes(ERole.AGENT)
        let item = c.items[0]

        return (
            <div className={`at-post at-post-mobile restore-${c.id}`}>
                <div className="at-orangeeconhead">
                    <UserPicComponent user={cUser} />
                    <div className="at-orangeeconcontent">
                        {
                            cUser?.isDeaf && <span className="at-postcontenticon"><i className="icon-hand"></i></span>
                        }
                        <h3>{isAgent ? cUser.gallery_name : `${cUser.firstName} ${cUser.lastName}`}
                            <span>{c.title}</span>
                        </h3>

                        <div className="at-btniconholder">
                            <a onClick={() => props.openArtCentralSpacePopup(`${routes.artCentral.collection.list}/${cUser._id}`, c)} title="Art Collection" className="at-btnmenu at-postbtn at-artcollectbtn">
                                {/* <i className="icon-menu"></i> */}
                                {/* <p>{isAgent ? 'Gallery' : `Studio`}</p> */}
                                <span className="at-btn at-doubleextrasmallbtn">ART {isAgent ? 'GALLERY' : `STUDIO`}</span>
                            </a>
                            <a className="at-btnomniicon at-postbtn" title="Collection Information"><i className="icon-info" onClick={() => openInfoBox(c)}></i></a>
                            {/* Remove from SprintONE */}
                            {token && props.user && <>
                                <a className="at-btnomniicon at-postbtn" title="Message"><i className="icon-send" onClick={() => props.openMessageBox(c)}></i></a>
                                {/* <a className="at-btnomniicon at-postbtn" title="Post">
                                </a> */}
                                <HeartComponent postId={c.id} userId={cUser._id} />
                            </>
                            }
                        </div>
                    </div>
                </div>
                <figure className="at-postimg" onClick={() => props.openArtCentralSpacePopup(`${routes.artCentral.collection.view}/${c.id}`, c)}>
                    {item && <ImageLoading src={collectionImage(item, item.url || '', true)} thumbnail={collectionImage(item, item.url || '', true)} />}
                </figure>
            </div>
        )

    }

    let checkRoute = loaction.pathname === routes.artCentral.artCollection || loaction.pathname === routes.artCollection
    return (
        <PullToRefresh
            pullDownContent={<PullDownContent />}
            releaseContent={<ReleaseContent />}
            refreshContent={<LoadingComponent className="mt-10" />}
            pullDownThreshold={200}
            onRefresh={() => props.fetch(1, props.query, props.filtered)}
            triggerHeight={50}
            backgroundColor='white'
            startInvisible={true}
        >
            <div className="at-orangeeconpost at-contentarea at-themescrollbar scrollable scrollbar-hidden text-center" >
                <div id="feed-scroll" className={`at-posts at-themescrollbar scrollable scrollbar-hidden ${props.isZoomed ? 'disableThumbnailScroll' : ''}`} onScroll={() => props.onScroll()}>
                    <div className="at-themehead">
                        <div className="form-group at-artcollectionselect">
                            <span className="at-select at-floatlabel-h40">
                                <select className="form-control floating-select" value={props.role} name="" onChange={(e: any) => props.onChangeRole(e)} >
                                    <option value=''>Newest Collections</option>
                                    <option value={ERole.ARTIST}>Artists Newest Collections</option>
                                    <option value={ERole.AGENT}>Galleries Newest Collections</option>
                                </select>
                            </span>
                        </div>
                        {props.showSearch && <a className="at-left-arrowicon" onClick={() => props.closeSearch()}><i className="icon-left-arrow"></i></a>}
                        {!props.showSearch && <a className="at-filtericon cursor-pointer" onClick={() => props.onOpenSearch()}><i title="Search" className="icon-search"></i></a>}

                        {token && <a className={`at-filtericon cursor-pointer mr-2 ${props.filtered && 'text-orange'}`} onClick={() => props.onToggleFilter(!props.filtered)}><i title="Filter Preferred" className="icon-filter"></i></a>}
                        <h2>
                            <div className={`from-group At-MarginTopSearchMinus dn ${props.showSearch && 'display'}`}>
                                <div className="input-group">
                                    {props.loading && <div className="input-group-prepend">
                                        <span className="input-group-text search-field" id="basic-addon1"><i className="fa fa-spinner"></i></span>
                                    </div>}
                                    <input type="search" value={props.query} className="form-control" placeholder="Search" onChange={(e: any) => props.onSearch(e)} />
                                </div>
                            </div>
                            {!props.showSearch && checkRoute ? 'Art Collections' : ''}
                        </h2>
                    </div>
                    {
                        (props.loading && props.collections.length < 1) && [1, 1, 1].map((v, i) => <PostShimmeringComponent key={i} />)
                    }

                    {
                        props.collections.length > 0 && props.collections?.map((c: ICollection) => (
                            <Fragment key={`post_${c.id}`} >
                                {renderCollection(c)}
                            </Fragment>
                        ))
                    }
                    {
                        props.loading && <LoadingComponent />
                    }
                    {
                        (props.next && !props.loading) && <div className="at-haslayout">
                            <button id="loadMore" className="at-btn at-loadmore " onClick={() => props.fetch(props.page + 1, props.query, props.filtered)}>{lang.common.loadMore}</button>
                        </div>
                    }
                    {(!props.loading && !props.next && props.count > 0) &&
                        <div className="row text-center">
                            <p className="reachedText">
                                {lang.feeds.reachedEnd}
                            </p>
                            <br />
                        </div>
                    }
                    {
                        (!props.loading && props.count < 1) && <div className="text-center  mt-5 mb-5">
                            <h4>{!props.query ? lang.feeds.notFound : lang.feeds.changeSearch}</h4>
                        </div>
                    }
                    {props.showMessageBox && <MessageModal open={props.showMessageBox} onClose={props.closeMessageBox} onSubmit={props.onSumbitMessageBox} message='' title="Send Message" />}
                    {showInfo && selectedCollection && <DescriptionModal open={showInfo} title={selectedCollection.title} description={selectedCollection.description} keyword={selectedCollection.keywords} onClose={closeInfoBox} collection={selectedCollection} event={(selectedCollection.user as IUser).roles.includes(ERole.AGENT) ? assignmentService.updateDescription : collectionService.updateDescription} onSubmit={onSubmitInfoModal} user={(props.user as IUser)} />}

                </div>

            </div>

        </PullToRefresh>
    )
}