import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ICommissionResponse, IUser } from '../../../../interfaces'
import { routes } from '../../../../router'
import { commissionWorkService, toastService } from '../../../../services'
import { collectionImage } from '../../../../shared'
import { CurrencyFormat } from '../../../common/CurrencyFormat'
import { MessageModal } from '../../../common/Modals/MessagePopup'

interface IProps {
    item: ICommissionResponse,
    history: any
    refresh: Function
}
export const RequestLayout = ({ item, history, refresh }: IProps) => {

    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')

    let artUser = item.itemId.art_collection.user ? (item.itemId.art_collection.user as IUser) : null

    const openMessageBox = () => {
        setShowMessageBox(true)
    }

    const onCloseMessageBox = () => {
        setShowMessageBox(false)
    }

    const onSubmitMessage = (data: any) => {
        setMessage(data)
        commissionWorkService.sendRequestMessage(({ message: data, commissionID: item.id })).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success('Message sent successfully.')
            }
            setShowMessageBox(false)
        }).catch((err: any) => {
            console.log(err)
            setShowMessageBox(false)
        })
    }
    return (
        <div key={item.id} className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-rejectaccept-two at-haslayout">
                    <h5><span>Inspiration Art:</span></h5>
                    {item.itemId && <div className="at-figure-box d-flex">
                        <figure>
                            <img src={collectionImage(item.itemId, `high_${item.itemId.url}`, true)} className="img-fluid" alt="" />
                        </figure>
                        <div className="at-figure-text">
                            {artUser && <h4>{artUser.firstName} {artUser.lastName}</h4>}
                            <h6>{item.itemId.name}</h6>
                            <p>
                                {item.itemId.description}
                            </p>
                        </div>
                    </div>}

                    <h5 className="mb-1"><span className="at-color-grey">Additional Information: </span>{item.additionalInformation}</h5>
                    <h5 className="mb-1"><span className="at-color-grey">Budget: </span><CurrencyFormat amount={item.budget} /></h5>
                    <h5 className="mb-1"><span className="at-color-grey">Deadline (weeks): </span>{item.deadline}</h5>
                    <div className="row at-rejectaccept-btns row-margin-lr7">
                        <div className="col-6 pad-lr-7">
                            <button className="at-btn at-btn-sm at-btn-bermudagrey at-minwidth-auto" onClick={() => openMessageBox()}>Send Message </button>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <button className="at-btn at-btn-sm at-minwidth-auto" onClick={() => history.push({ pathname: `${routes.artCentral.commissionWork.requestCommissionwork}/${item.itemId.id}`, state: { update: true, reqId: item.id } })}>Update Request</button>
                        </div>
                    </div>
                </div>
            </div>
            {showMessageBox && <MessageModal message={message} open={showMessageBox} onClose={onCloseMessageBox} onSubmit={onSubmitMessage} />}
        </div>
    )
}