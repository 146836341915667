import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { routes } from "../../router";
import * as Admin from '../../pages/Admin'
import { AdminHeaderComponent } from './Header.component'
import { logoutAction, saveUser } from '../../store/actions'
import AdminSubscription from '../../pages/LazyloadingComponents/AdminSubscriptionLazyLoading'

import {
    IStoreActionsStates,
    IStoreReducers,
} from '../../interfaces/store.interface'
import { HttpService } from '../../services/base.service';
import UserSubscriptionPage from '../../components/Admin/Users/AdminUserSubscriptionPage';
import TutorialsPage from './Tutorials.page';
import AdminServiceTransaction from './AdminServiceTransaction';
import { SubmissionApplications } from './SubmissionApplications.page';
import AdminShippingFactors from './ShippingFactors/ShippingFactors.component';

interface IPageProps extends IStoreActionsStates, RouteComponentProps {
    [key: string]: any
}

const PrivateRoutes = ({ component: Page, authToken, isAdmin, ...rest }: any) => {
    return (
        <Route {...rest} render={props => {
            return (
                <>
                    {isAdmin && authToken ? <Page {...props} /> : <Redirect to={routes.adminRoutes.login} />}
                </>
            )
        }} />
    );
}
export const AdminUsersTabsComponent = (props: IPageProps) => {
    const { token, user } = props

    HttpService.setToken(token)
    useEffect(() => {

    }, [token])
    const onLogout = () => {
        props.logoutAction()
        props.saveUser({})
    }
    return (
        <>
            <AdminHeaderComponent onLogout={onLogout} />
            <Switch>
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.index} exact component={Admin.AdminHomePage} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.dashboard} exact component={Admin.DashboardComponent} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.users} exact component={Admin.UsersPage} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.transactions} exact component={Admin.AdminTransactionsComponent} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.curatorialtransactions} exact component={Admin.AdminCuratorialTransactionsComponent} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.manageDisputes} exact component={Admin.AdminManageConflictsComponent} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.editText} exact component={Admin.EditText} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.subscription} exact component={AdminSubscription} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.userSubscription} exact component={UserSubscriptionPage} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.systemParameters} exact component={Admin.SystemParameters} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.tutorials} exact component={TutorialsPage} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={routes.adminRoutes.serviceTransactions} exact component={AdminServiceTransaction} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={`${routes.adminRoutes.submissionApplicationView}/:id`} exact component={SubmissionApplications} />
                <PrivateRoutes isAdmin={user.isAdmin} authToken={token} path={`${routes.adminRoutes.shippingFactor}`} exact component={AdminShippingFactors} />

            </Switch>

        </>
    )
}

const mapState = (state: IStoreReducers) => ({
    token: state.auth.access_token,
    spaces: state.spaces,
    misc: state.misc,
    spaceEnabled: state.user.activeSpace && state.user.activeSpace._id,
    user: state.user
})

export default connect(mapState, { logoutAction, saveUser })(AdminUsersTabsComponent);
