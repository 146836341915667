import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AxiosResponse } from 'axios'
import { RouteComponentProps, Link, useLocation, useHistory } from 'react-router-dom'
import { IFormates, ITransaction, IUser } from '../../interfaces'
import { transactionService } from '../../services/Transaction.service'
import LoadingComponent from '../../components/common/Loading.component'
import { TransactionLayout } from '../../components/common/TransactionLayout'
import { ETransactionStatus } from '../../enums'
import { userSelector } from '../../store/selectors'
import { UniversalModal } from '../../components/common/Modals/Universal.modal'
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial'
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon'
import { EScreens } from '../../enums/screens.enum'

interface IPageProps {
    routeUser?: IUser
    hideBackButton?: boolean
}
interface IProps {
    onClick: Function
}

const DisputePage = ({ routeUser, hideBackButton }: IPageProps) => {
    const history = useHistory()
    const storeUser = useSelector(userSelector)
    const user = routeUser && routeUser._id ? routeUser : storeUser
    const [showBuyerDetails, setShowBuyerDetails] = useState<boolean>()
    const [showSellerDetails, setShowSellerDetails] = useState<boolean>()
    const [disputedTransactions, setDisputedTransaction] = useState<Array<ITransaction>>()
    const [loding, setLoading] = useState<boolean>(false)
    const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false)


    useEffect(() => {
        setLoading(true)
        transactionService.getUserDisputes(user?._id).then((response: AxiosResponse<any>) => {
            let newTransaction = [...response.data.response]
            setDisputedTransaction(newTransaction.reverse())
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }, [])

    const getButtonStatus = (item: ITransaction) => {
        if (item.disputed && item.disputed.status) {
            if (item.disputed.labelStatus === ETransactionStatus.PENDING) {
                return { status: 'Pending', color: 'orange' }
            } else {
                return { status: 'Resolved', color: 'grey' }
            }
        }
        return { status: 'Resolved', color: 'grey' }
    }

    const getItemType = (item: ITransaction) => {
        if (item.formatesId) {
            let format: Array<IFormates> = item?.itemID?.formates.filter((value: IFormates) => value._id === item?.formatesId)
            if (format && format.length <= 0) {
                return ` `
            }
            if (format && format[0]) {
                return format[0].size
            }
            return ` `
        }
        return `Original`
    }


    const openTutorialBox = () => {
        setShowTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setShowTutorialBox(false)
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two ">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>Disputes <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.DISPUTES} onClick={openTutorialBox} /></span></h2>
                </div>
                <div className="at-transaction-main pt-0">
                    {disputedTransactions && disputedTransactions?.length > 0 ? (
                        <>
                            {disputedTransactions?.map((item: ITransaction) => {
                                return (
                                    <div>
                                        <div className="at-transactionhead">
                                            <div className="at-transact-line">
                                                <h2>Title:<span className="pl-2 at-texttransf-none">{`${item.disputed.reason}`}</span></h2>
                                            </div>
                                            <div className="at-transact-line">
                                                <h2>Description:<span className="pl-2 at-texttransf-none">{`${item.disputed.details}`}</span></h2>
                                            </div>
                                        </div>
                                        <TransactionLayout item={item} handleDetailPopup={() => { }} getButtonStatus={getButtonStatus} getItemType={getItemType} />
                                    </div>
                                )
                            })}
                        </>) : (<>{loding && <LoadingComponent className="at-transaction-loader" />}</>)
                    }
                    {/* showSellerDetails popup ends */}
                </div>
                {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={closeTutorialBox} position='bottom-0'>
                    <ScreenTutorial screen={EScreens.DISPUTES} />
                    {/* at-categorybox ends */}
                </UniversalModal>
                }
            </div>
        </main>
    )
}

export default DisputePage
