import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { images } from '../../assets/image'
import LoadingComponent from '../../components/common/Loading.component'
import { IEventDocumentResponse, IUser } from '../../interfaces'
import { eventService, toastService } from '../../services'
import { eventImages } from '../../shared'
import { templateSelector } from '../../store/selectors'
import { routes } from './routes'
import { CustomImage } from '../components/CustomImage'
import moment from 'moment'
import { IResponse } from '../../interfaces/db.interface'

const ExhibitionsPage = () => {
    const history = useHistory()
    const template = useSelector(templateSelector)
    const user = template.user
    const [loading, setLoading] = useState<boolean>(false)
    const [events, setEvents] = useState<Array<IEventDocumentResponse>>([])
    const [upcomingEvents, setUpcomingEvents] = useState<Array<IEventDocumentResponse>>([])
    const [happeningEvents, setHappeningEvents] = useState<Array<IEventDocumentResponse>>([])
    const [pastEvents, setPastEvents] = useState<Array<IEventDocumentResponse>>([])

    const sortingEvent = (e: Array<IEventDocumentResponse>) => {
        let futureEvents = e.filter((e: IEventDocumentResponse) => moment(e.startDate).valueOf() > moment().valueOf() && moment(e.endDate).valueOf() > moment().valueOf())
        // .sort((a: IEventDocumentResponse, b: IEventDocumentResponse) => new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf())
        let currEvents = e.filter((e: IEventDocumentResponse) => moment(e.startDate).valueOf() < moment().valueOf() && moment(e.endDate).valueOf() > moment().valueOf())
        let pastEvents = e.filter((e: IEventDocumentResponse) => moment(e.startDate).valueOf() < moment().valueOf() && moment(e.endDate).valueOf() < moment().valueOf())

        setUpcomingEvents([...futureEvents])
        setHappeningEvents([...currEvents])
        setPastEvents([...pastEvents])
    }

    const fetchEvents = useCallback(() => {
        setLoading(true)
        eventService.getUserWithEvent(user?._id).then((res: AxiosResponse<IResponse<IUser>>) => {
            if (res.data.success) {
                let resUser = res.data.data
                let data = (resUser.events as Array<IEventDocumentResponse>).filter((e: IEventDocumentResponse) => !e.isHide)
                setEvents([...data])
                sortingEvent(data)
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])
    useEffect(() => {
        if (user) {
            fetchEvents()
        }
    }, [user])

    const renderEvents = (e: IEventDocumentResponse) => {
        return (
            <div key={e._id} className="col-sm-4" onClick={() => history.push(`${routes(template.url).exhibitions.viewSingleExhibition}/${e._id}`)}>
                <div className="At-EventBox">
                    <figure className="At-FigureFull">
                        <img src={eventImages({}, `${e.poster}`, true)} className="img-fluid" alt="" />
                    </figure>
                    <p>
                        {e.name}
                    </p>
                </div>
            </div>
        )
    }
    return <div >
        <section className="At-SectionExhibitions At-Padding-t10b5 At-Section-Mh800">
            {events.length > 0 ? <div className="container">
                {happeningEvents && happeningEvents.length > 0 && <>  <div>
                    <h4 className="at-eventheading">Current Events</h4>
                </div>
                    <div className="row">
                        {happeningEvents.map((e: IEventDocumentResponse) => renderEvents(e))}
                    </div>
                </>}
                {upcomingEvents && upcomingEvents.length > 0 && <><div>
                    <h4 className="at-eventheading">Future Events</h4>
                </div>
                    <div className="row">

                        {upcomingEvents.map((e: IEventDocumentResponse) => renderEvents(e))}

                    </div>
                </>
                }
                {pastEvents && pastEvents.length > 0 && <>  <div>
                    <h4 className="at-eventheading">Past Events</h4>
                </div>
                    <div className="row">
                        {pastEvents.map((e: IEventDocumentResponse) => renderEvents(e))}
                    </div>
                </>}
            </div> : <div className="container"><p className="At-Scheduled-Event">No scheduled events.</p></div>}

            {loading && <LoadingComponent className='at-transaction-loader' />}
        </section>
    </div>
}

export default ExhibitionsPage