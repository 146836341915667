import React, { useState, useEffect, FormEvent } from 'react'
import Select from 'react-select'
import { userService, toastService } from '../../../services'
import { AxiosResponse, AxiosError } from 'axios'
import { IUser } from '../../../interfaces'
import { collectionService } from '../../../services/collection.service'
import { validationErrors } from '../../../shared'
import { BAD_REQUEST, CONFLICT } from 'http-status'
import { ErrorComponent } from '../../common/Error.component'
import { CustomTooltip } from '../../common/CustomTooltip'
import { typesOfAssignment } from '../../../constants'

interface IAssignmentForm {
    user: string
    period: string
    commission: string,
    type: string,
    formatPeriod?: string,
    formatCommission?: string,
    originalResponsibility: string
    formatResponsibility: string
}
interface IProps {
    items: Array<string>
    onComplete: Function
}
export const AssignmentFormComponent = ({ items, onComplete }: IProps) => {
    const [agentLoading, setAgentLoading] = useState<boolean>()
    const [assigning, setAssigning] = useState<boolean>()
    const [users, setUsers] = useState<Array<any>>([])
    const [form, setForm] = useState<IAssignmentForm>(
        {
            user: '',
            period: '',
            commission: '',
            type: '',
            formatCommission: '',
            formatPeriod: '',
            originalResponsibility: 'artist',
            formatResponsibility: 'artist'
        }
    )
    const [errors, setErrors] = useState<{ [key: string]: any }>({})
    const [showBox, setShowBox] = useState<boolean>(false)
    useEffect(() => {
        setAgentLoading(true)
        userService.agents().then((response: AxiosResponse<Array<IUser>>) => {
            const users = response.data.map((user: IUser) => ({
                value: user._id,
                label: user.gallery_name || `${user.firstName} ${user.lastName}`
            }))
            setUsers(users)
            setAgentLoading(false)
        })
    }, [])


    const handleChange = ({ target: { name, value } }: any) => {
        if ((name === 'commission' || name === 'formatCommission') && (value < 0 || value > 100)) return;
        if ((name === 'period' || name === "formatPeriod") && (value < 0 || value > 120)) return;
        setForm({ ...form, [name]: value })
    }

    const submit = (e: FormEvent) => {
        e.preventDefault();
        if (assigning) return;
        collectionService.assignItems({ ...form, items }).then((res: AxiosResponse) => {
            if (res.data.isExist) {
                toastService.error(res.data.message)
                return
            }
            setAssigning(true)
            toastService.success(res.data.message)
            onComplete();
        }, errorHandler)
    }


    const errorHandler = ({ response }: any) => {
        let _errors = null;
        setAssigning(false)
        switch (response.status) {
            case BAD_REQUEST:
                _errors = validationErrors(response.data);
                break;
            case CONFLICT:
                toastService.error(response.data.message)
                break;
            default:
                break;
        }
        setErrors({ ..._errors })
    }

    const errorElement = (key: string) => (
        errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    return (
        <>
            <form onSubmit={submit} className="at-assignment-form">
                <div className='form-group at-inputwithicon at-assignselect'>
                    {/* <i className="icon-search"></i>
                <input
                    type='text'
                    name='name'
                    className='form-control text-capitalize'
                    placeholder={'Art Gallery Name'}
                /> */}
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isLoading={agentLoading}
                        isSearchable={true}
                        name="user"
                        options={users}
                        onChange={(e: any) => setForm({ ...form, user: e.value })}
                    />
                    {errorElement('user')}
                </div>

                <div className="form-group at-floatlabel-h40">
                    <div className="d-flex align-items-center ">
                        <span className="at-select">
                            <select className="text-capitalize floating-select" value={form.type} onChange={handleChange} name="type" required >
                                <option value="" disabled></option>
                                <option>exclusive</option>
                                <option>non-exclusive</option>
                            </select>
                            <label className="at-fs11">Type of Assignment</label>
                        </span>
                        <i className="icon-info at-infoassignment" onClick={() => setShowBox(!showBox)} ></i>
                    </div>
                    {errorElement('type')}
                </div>
                <div className='form-group at-floatlabel-h40'>
                    <div className="d-flex align-items-center">
                        <input
                            type='number'
                            name='period'
                            value={form.period}
                            onWheel={(e: any) => e.target.blur()}
                            className='form-control text-capitalize'
                            placeholder={' '}
                            onChange={handleChange}
                        />
                        <label className="at-fs11">ORIGINAL length of Assignment in Months</label>
                    </div>
                    {errorElement('formatPeriod')}
                </div>
                <div className='form-group at-floatlabel-h40'>
                    <input
                        type='number'
                        name='commission'
                        value={form.commission}
                        className='form-control text-capitalize'
                        placeholder={' '}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={handleChange}
                    />
                    <label className="at-fs11">ORIGINAL percent (%) commission paid to Gallery</label>
                    {errorElement('formatCommission')}
                </div>

                <div className="form-group at-floatlabel-h40">
                    <div className="d-flex align-items-center ">
                        <span className="at-select">
                            <select className="text-capitalize floating-select" value={form.originalResponsibility} name="originalResponsibility" required onChange={handleChange} >
                                <option value="" disabled></option>
                                <option>artist</option>
                                <option>seller</option>
                            </select>
                            <label className="at-fs11">ORIGINAL Shipping Responsibility</label>
                        </span>
                    </div>
                    {errorElement('type')}
                </div>

                <div className='form-group at-floatlabel-h40'>
                    <div className="d-flex align-items-center ">
                        <input
                            type='number'
                            name='formatPeriod'
                            value={form.formatPeriod}
                            className='form-control text-capitalize'
                            placeholder={' '}
                            onWheel={(e: any) => e.target.blur()}
                            
                            onChange={handleChange}
                        />
                        <label className="at-fs11">OTHER FORMATS length of Assignment in Months</label>
                    </div>
                    {errorElement('period')}
                </div>
                <div className='form-group at-floatlabel-h40'>
                    <input
                        type='number'
                        name='formatCommission'
                        value={form.formatCommission}
                        className='form-control text-capitalize'
                        placeholder={' '}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={handleChange}
                    />
                    <label className="at-fs11">OTHER FORMATS percent (%) commission paid to Gallery</label>
                    {errorElement('commission')}
                </div>
                <div className="form-group at-floatlabel-h40">
                    <div className="d-flex align-items-center ">
                        <span className="at-select">
                            <select className="text-capitalize floating-select" value={form.formatResponsibility} name="formatResponsibility" required onChange={handleChange}>
                                <option disabled></option>
                                <option>artist</option>
                                <option>seller</option>
                            </select>
                            <label className="at-fs11">OTHER FORMAT Shipping Responsibility</label>
                        </span>
                    </div>
                    {errorElement('type')}
                </div>
                <div className='form-group'>
                    <button
                        type='submit'
                        className='at-btn at-assignbtn-from'
                        disabled={assigning}
                    >
                        ASSIGN
                </button>
                </div>
            </form>
            {
                showBox && <CustomTooltip message={typesOfAssignment} platform="Type of Assignment" />
            }
        </>
    )
}
