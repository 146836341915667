export enum EInvitationStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED'
}

export enum ECuratorialOfferStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED'
}

export enum EMileStoneStatus {
    PENDING = 'PENDING',
    REQUESTED = 'REQUESTED',
    PAID = 'PAID',
}