import React, { useEffect, useState, useRef } from 'react'
import { AxiosResponse } from 'axios'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { UserNameComponent, UserPicComponent } from '../../../components/User/index.component'
import { ICollectionItem, ICommissionResponse, ICommissionworkPROPOSAL, IMilestone, IStoreReducers, IUser } from '../../../interfaces'
import { collectionService } from '../../../services/collection.service'
import { images } from '../../../assets/image'
import { commissionWorkService, toastService } from '../../../services'
import { CurrencyField } from '../../../components/common/CurrencyInput'
import { collectionImage, proposalImage } from '../../../shared'
import { CurrencyFormat } from '../../../components/common/CurrencyFormat'
import { ErrorComponent } from '../../../components/common/Error.component'
import { bytesToMegaBytes, dataURLtoFile, _logger } from '../../../helper'
import { SliderComponent } from '../../../components/common/Slider.component'
import { ConfirmAlertComponent } from '../../../components/common/ConfirmAlert.component'
import { IResponse } from '../../../interfaces/db.interface'

interface IProps extends RouteComponentProps<{ id: string }> {
    user: IUser
}

const ProposalCommissionwork = (props: IProps) => {

    const [art, setArt] = useState<ICollectionItem>()
    const [request, setRequest] = useState<ICommissionResponse>()
    const [errors, setErrors] = useState<any>({})
    const [requestMilestone, setRequestMilestone] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [msIndex, setMsIndex] = useState<number>(NaN)
    const [showConfirmBix, setConfirmBox] = useState<boolean>(false)
    const imageRef = useRef<any>(null)

    const [imageState, setImageState] = useState<any>({
        preview: [],
        previewsCopy: [],
        selectedFiles: [],
        selectedFile: 0,
    })
    const [milestone, setMilestone] = useState<IMilestone>({
        name: '',
        payment: '',
    })
    const [proposal, setProposal] = useState<ICommissionworkPROPOSAL>({
        artname: '',
        budget: '',
        shipping: '',
        milestone: [],
        description: '',
        images: []
    })

    const params = () => {
        return props.match.params
    }
    const getRouteState = () => {
        return (props.location.state as any)
    }

    useEffect(() => {
        fecthItem()
    }, [])

    const fecthItem = () => {
        setLoading(true)
        commissionWorkService.getRequest(params().id).then((res: AxiosResponse<IResponse<ICommissionResponse>>) => {
            setArt({ ...res.data.data.itemId })
            setRequest({ ...res.data.data })
            if (res.data.data.proposalID) {
                setProposal({ ...res.data.data.proposalID })
                const preview = ((res.data.data && res.data.data.proposalID.images) || []).map((a: any) => ({ url: proposalImage(res.data.data.proposalID, a.url), isVideo: a.isVideo }))
                setImageState({ ...imageState, preview, previewsCopy: preview })
            } else {
                setProposal({
                    ...proposal,
                    artname: res.data.data.itemId.name,
                    budget: res.data.data.budget
                })
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }

    const onChangeText = (e: any) => {
        let newValue = e.target.value
        if (newValue && (e.target.name === "price" || e.target.name === "shipping")) newValue = newValue.split("$")[1].replace(/,/gi, '')
        setProposal({
            ...proposal,
            [e.target.name]: newValue
        })
    }

    const onMilestonePriceChange = (e: any) => {
        let newValue = e.target.value
        if (newValue && e.target.name === "payment") newValue = newValue.split("$")[1].replace(/,/gi, '')
        setMilestone({ ...milestone, [e.target.name]: newValue })
    }

    const addMilestone = () => {
        let newMilestone = proposal.milestone
        if (!milestone.name || !milestone.payment) return;
        let checkBudget = 0;
        newMilestone.forEach((item: IMilestone) => {
            checkBudget = Number(item.payment) + checkBudget
        })
        if (((checkBudget + Number(milestone.payment)) > Number(proposal.budget)) && isNaN(msIndex)) {
            let err = errors
            err['maxBudget'] = ['Budger limit exceeded']
            setErrors({ ...err })
            return
        }
        setErrors({})
        if (!isNaN(msIndex)) {
            newMilestone[msIndex] = milestone
            console.log("run")
        } else {
            newMilestone.push(milestone)
        }
        setProposal({
            ...proposal,
            milestone: newMilestone
        })
        setMilestone({
            name: '',
            payment: '',
        })
        setMsIndex(NaN)
    }

    const onSubmitForm = (e: any) => {
        e.preventDefault()
        if (!validateForm()) return
        if (getRouteState() && getRouteState().update) {
            updateProposal()
            return
        }
        let data = new FormData()
        imageState.selectedFiles.forEach((i: any) => data.append('files', i))
        data.append('proposal', JSON.stringify((proposal as any)))
        data.append('commissionID', (request as ICommissionResponse)._id)
        setLoading(true)
        commissionWorkService.createProposal(data).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success("Successfully send the proposal to the buyer.")
                props.history.goBack()
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }

    const updateProposal = () => {
        let data = new FormData()
        let newProposalData: any = proposal
        imageState.selectedFiles.forEach((i: any) => data.append('files', i))
        if (proposal.status && proposal.status === 'rejected') {
            newProposalData['status'] = 'pending'
        }
        data.append('proposal', JSON.stringify((newProposalData as any)))
        setLoading(true)
        commissionWorkService.updateProposal(request?.proposalID.id, data).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success(res.data.message)
                props.history.goBack()
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const handleSelect = (e: any) => {
        setRequestMilestone(e.target.value)
    }

    const sendPaymentRequest = () => {
        setLoading(true)
        commissionWorkService.requestPayment({ proposalID: proposal.id, milestone: requestMilestone }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success(res.data.message)
                fecthItem()
                props.history.goBack()
            }
            if (res.data.status === 'Fail') {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const EditMileStone = (index: any) => {
        if (!checkPaidMilestone(index)) return
        setMsIndex(index)
        setMilestone({
            name: proposal.milestone[index].name,
            payment: proposal.milestone[index].payment,
        })
    }

    const validateForm = () => {
        if (proposal.milestone?.length === 0) {
            let err = errors
            err['maxBudget'] = ['Please add at least one milestone']
            setErrors({ ...err })
            return false
        }
        let checkBudget = 0;
        proposal.milestone?.forEach((item: IMilestone) => {
            checkBudget = Number(item.payment) + checkBudget
        })
        if (checkBudget < Number(proposal.budget)) {
            let err = errors
            err['maxBudget'] = [`Addition of all Milestone's payment must be equal to Budget`]
            setErrors({ ...err })
            return false
        }

        setErrors({})
        return true
    }

    /* ===================================== Images Handling =========================================================== */
    const onChangeFiles = (e: any): void => {
        const { preview, selectedFile, selectedFiles } = imageState;
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    setImageState({
                        ...imageState,
                        preview: [...preview, ...images],
                        previewsCopy: [...preview, ...images],
                        selectedFiles: [...selectedFiles, ...files],
                        selectedFile: selectedFile ? selectedFile : 0
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onCrop = (image: any) => {
        let fileName
        let index = image.index
        if (params().id) {
            index = imageState.preview.length - image.index
            fileName = imageState.selectedFiles[index - 1].name
        } else {
            fileName = imageState.selectedFiles[index].name
        }
        const file = dataURLtoFile(image.cropped, fileName)
        const updatedFiles = [...imageState.selectedFiles]
        updatedFiles[index] = file
        const updatedPreviews = [...imageState.preview]
        updatedPreviews[image.index] = image.cropped
        setImageState({
            ...imageState,
            selectedFiles: updatedFiles,
            preview: updatedPreviews
        })
    }

    const deleteImage = (): void => {

        let { preview, selectedFiles, selectedFile: i } = imageState;


        if (i > -1 && preview[i]) preview.splice(i, 1)
        let oldPlusNew = [...proposal.images, ...selectedFiles];
        oldPlusNew.splice(i, 1);
        proposal.images = oldPlusNew.filter((v: any) => v.proposal)
        const newAssets = oldPlusNew.filter((v: any) => !v.proposal)
        const selectedFile = i > 0 ? i - 1 : 0;

        setImageState({ ...imageState, preview, previewsCopy: preview, selectedFiles: newAssets, selectedFile })
        setProposal({ ...proposal })
    }

    const checkPaidMilestone = (index: any) => {
        if (proposal.milestone[index].status === 'paid') {
            toastService.error("Sorry you cant edit or delete milestone which is already paid")
            return false
        }
        return true
    }
    /* =============================== Confirm box handlers ===================================*/

    const openConfirmBox = (index: any) => {
        if (!checkPaidMilestone(index)) return
        setMsIndex(index)
        setConfirmBox(true)
    }
    const closeConfirmBox = () => {
        setMsIndex(NaN)
        setConfirmBox(false)
    }

    const onSubmitConfirmBox = () => {
        let newMilestone = proposal.milestone.filter((m: IMilestone, index: any) => msIndex !== index)
        setProposal({
            ...proposal,
            milestone: [...newMilestone]
        })
        closeConfirmBox()
    }
    let dropDownMilestone = proposal.milestone.filter((m: IMilestone) => m.status === 'pending')

    const selectImages = () => {

        imageRef.current.click();
    }
    return (
        <div className="at-collectiondetailholder scrollable at-contentarea">
            <div className="at-orangeeconhead">
                <a onClick={() => props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                {/* <UserPicComponent user={props.user} />
                <div className="at-orangeeconcontent">
                    {props.user.isDeaf && <span className="at-postcontenticon"><i className="icon-hand"></i></span>}
                    <h3 className="text-left">
                        {art && art.art_collection && art.art_collection.title}
                        <UserNameComponent user={props.user} />
                    </h3>
                </div> */}
            </div>
            <form className="at-formtheme at-formcreatepost border-bottom-0 text-left" onSubmit={onSubmitForm}>
                <fieldset>
                    <div className="row">
                        <div className="col-12">
                            {/* <ImageViewComponent items={[]} propoasl={proposal} /> */}
                            <div className="form-group">
                                <div className="at-themefileupload">
                                    <input type="file" name="files[name]" multiple ref={imageRef} accept="image/*,video/*" onChange={onChangeFiles} />
                                    <label onClick={selectImages} className="mb-0">
                                        <span className="text-center"><i className="icon-youtube"></i>Upload Images/Videos</span>
                                    </label>
                                </div>
                                {/* {
									this.errorElement('files')
								} */}
                            </div>
                            <div className={`form-group`}>
                                <SliderComponent items={imageState.preview} originalItems={imageState.previewsCopy} onCrop={onCrop} currentIndex={imageState.selectedFile} onChangeIndex={(selectedFile: number) => setImageState({ ...imageState, selectedFile: selectedFile })} />
                                {
                                    imageState.preview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={() => deleteImage()}></i>)
                                }
                                {/* <CropImageComponent image={preview[selectedFile]} /> */}
                            </div>
                        </div>
                        {/* <div className="col-12">
                            {art && <figure className='at-figure-round'>
                                <img src={collectionImage(art, `high_${art?.url}`, true)} className="img-fluid" alt="" />
                            </figure>}
                        </div> */}
                    </div>
                    <div className="row row-margin-lr7">
                        <div className="col-12 pad-lr-7">
                            <div className="form-group at-floatlabel-h40">
                                <input
                                    type='text'
                                    name='art-name'
                                    className='form-control at-input-bold'
                                    placeholder=""
                                    value={art?.name}
                                    disabled
                                />
                                <label>Art Name</label>
                            </div>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <div className="form-group at-floatlabel-h40">
                                <CurrencyField
                                    id="input-example-price"
                                    className="form-control"
                                    name="price"
                                    placeholder=" "
                                    prefix="$"
                                    value={proposal.budget ? proposal.budget : request?.budget}
                                    disabled
                                    onChange={() => { }}
                                />
                                <label>Project Price</label>
                            </div>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <div className="form-group at-floatlabel-h40">
                                <CurrencyField
                                    id="input-example-price"
                                    className="form-control"
                                    name="shipping"
                                    placeholder=" "
                                    prefix="$"
                                    value={proposal.shipping}
                                    onChange={onChangeText}
                                    disabled={proposal.status === 'approved'}
                                />
                                <label>Shipping Cost</label>
                            </div>
                        </div>
                        <div className="col-12 pad-lr-7">
                            <div className="form-group at-floatlabel-h40">
                                <textarea name="description" disabled={proposal.status === 'approved'} placeholder=" " className="form-control at-textarea-proposal" value={proposal.description} onChange={onChangeText} ></textarea>
                                <label>Description</label>
                            </div>
                        </div>
                    </div>
                    <div className="row row-form-three-cols align-items-start at-row-ml-5px">
                        <div className="col-5 pad-lr5">
                            <div className="form-group at-floatlabel-h40">
                                <input
                                    type='text'
                                    name='name'
                                    className='form-control'
                                    placeholder=" "
                                    value={milestone.name}
                                    onChange={(e: any) => { setMilestone({ ...milestone, [e.target.name]: e.target.value }) }}
                                    disabled={proposal.status === 'approved'}
                                />
                                <label>Milestone Name</label>
                            </div>
                        </div>
                        <div className="col-5 pad-lr5">
                            <div className="form-group at-floatlabel-h40">
                                <CurrencyField
                                    id="input-example-price"
                                    className="form-control"
                                    name="payment"
                                    placeholder=" "
                                    prefix="$"
                                    value={milestone.payment}
                                    onChange={onMilestonePriceChange}
                                    disabled={proposal.status === 'approved'}
                                />
                                <label>Payment</label>
                            </div>
                        </div>
                        <div className="col-12">
                            {errors && errors['maxBudget'] && <ErrorComponent errors={errors['maxBudget']} multiple={false} />}
                        </div>
                        <span className="at-icon-addformat">
                            <i className="icon-add-bold" onClick={() => addMilestone()} ></i>
                        </span>

                    </div>
                    {proposal.milestone.map((item: IMilestone, index: any) => {
                        return (
                            <div className="row px-3 At-row-milestone At-row-milestone-mr30">
                                <div className="col-6">
                                    <h4>{item.name}</h4>
                                </div>
                                <div className="col-6 text-right">
                                    <h5><CurrencyFormat amount={item.payment} /></h5>
                                </div>
                                <span className="at-icon-editformat">
                                    <i className="icon-edit" onClick={() => EditMileStone(index)} />
                                    <i className="icon-delete" onClick={() => openConfirmBox(index)} />
                                </span>
                            </div>


                        )
                    })}
                    {/* <div className="row px-3 At-row-milestone">
                        <div className="col-md-6">
                            <h4>Half way point</h4>
                        </div>
                        <div className="col-md-6 text-right">
                            <h5>$125</h5>
                        </div>
                    </div>
                    <div className="row px-3 At-row-milestone">
                        <div className="col-md-6">
                            <h4>Finish ART</h4>
                        </div>
                        <div className="col-md-6 text-right">
                            <h5>$75</h5>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <button type="submit" disabled={(proposal.status === 'approved' && imageState.selectedFiles && imageState.selectedFiles.length === 0) || loading} className="at-btn at-minwidth-100imp" >{loading ? 'Please wait...' : getRouteState() && getRouteState().update ? "Update" : 'Send Proposal'}</button>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>
            <div className="at-formtheme at-formcreatepost border-bottom-0 text-left">
                <fieldset>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="at-f-semibold mt-1 mb-3">Request to Release Milestone</h4>
                            <div className="form-group">
                                <span className="at-select">
                                    <select name="art_collection" value={requestMilestone} onChange={handleSelect}>
                                        <option value=''>Milestone Name</option>
                                        {dropDownMilestone.map((item: IMilestone) => (<option value={item._id}>{item.name}</option>))}
                                    </select>
                                </span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <button className="at-btn at-minwidth-100imp" disabled={!proposal.id || requestMilestone.length <= 0 || loading} onClick={() => sendPaymentRequest()}>{loading ? 'Please wait...' : 'Payment Request'}</button>
                            </div>
                        </div>
                    </div>
                </fieldset>

            </div>
            {showConfirmBix && <ConfirmAlertComponent
                open={showConfirmBix}
                message="Are you sure you want to delete this Milestone!"
                onCancel={closeConfirmBox}
                onClose={closeConfirmBox}
                onConfirm={onSubmitConfirmBox}
                closeable={true}
            />}
        </div>

    )
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(ProposalCommissionwork)