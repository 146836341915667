import React, { Component } from 'react';
import LoadingComponent from '../../../components/common/Loading.component';
import { ICollectionItem, ICollection, IStoreReducers, IUser } from '../../../interfaces';
import { userImage, postImage, collectionImage } from '../../../shared';
import { images } from '../../../assets/image';
import HeartIconComponent from '../../../components/ArtCentral/feed/HeartIcon.component';
import { collectionService } from '../../../services/collection.service';
import { RouteComponentProps, Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { connect } from 'react-redux';
import { routes } from '../../../router';
import { UserNameComponent, UserPicComponent } from '../../../components/User/index.component';
import { LongPressAableComponent } from '../../../components/common/LongPress.component';
import { ConfirmAlertComponent } from '../../../components/common/ConfirmAlert.component';
import { locale, lang } from '../../../locale';
import { assignmentService, toastService } from '../../../services';
import { NoDataComponent } from '../../../components/common/NoData.component';
import { ArtItemImageComponent } from '../../../components/ArtCentral/collection/ArtItemImage.component';
import { AssignmentFormComponent } from '../../../components/ArtCentral/collection/AssignmentForm.component';
import { toast } from 'react-toastify';
import ReactCursorPosition from 'react-cursor-position';
import { ScrollTo, ScrollArea } from 'react-scroll-to'
import { ERole } from '../../../enums';
import { IResponse } from '../../../interfaces/db.interface';

interface IPageProps extends RouteComponentProps<{ id: string }> {
    user: IUser
}

interface IPageState {
    loading: boolean
    deleting: boolean
    collection: ICollection | null
    openAction: boolean
    openDeleting: boolean
    confirmBox: boolean
    openAssignBox: boolean
    selectedForAssignment: any
    selectedItems: {
        [key: string]: boolean
    }
    isDragging: boolean
}

let buttonPressTimer: any;
class CollectionViewPage extends Component<IPageProps> {
    state: IPageState = {
        loading: false,
        deleting: false,
        confirmBox: false,
        openDeleting: false,
        openAction: false,
        openAssignBox: false,
        collection: null,
        selectedItems: {},
        selectedForAssignment: null,
        isDragging: false
    }

    componentDidMount() {
        this.fetch()
    }

    get params() {
        return this.props.match.params;
    }

    toggleLoading = (key: 'loading' | 'deleting' | 'confirmBox') => {
        this.setState({ [key]: !this.state[key] })
    }

    fetch = () => {
        this.toggleLoading('loading');
        collectionService.show(this.params.id).then((response: AxiosResponse<IResponse<ICollection>>) => {
            this.toggleLoading('loading');
            this.setState({ collection: response.data.data, selectedItems: {} })
        })
    }

    selectItem = (id: any) => {
        let { selectedItems } = this.state;
        if (selectedItems[id]) {
            delete selectedItems[id]
        } else {
            selectedItems[id] = true;
        }
        this.setState({ selectedItems })
    }

    doFavorit = (favorit: boolean) => {
        if (!this.state.collection) return;
        let collection: any = this.state.collection;
        if (favorit) {
            collection.favoritedBy = [...collection.favoritedBy, this.props.user._id];
        } else {
            collection.favoritedBy = collection.favoritedBy.filter((f: string) => f !== this.props.user._id);
        }

        this.setState({ collection })
        collectionService.likeUnlike(collection.id, favorit);

    }

    isSelected = (id: any) => this.state.selectedItems[id]

    onClick = ({ id, assignment }: ICollectionItem) => {
        const selectionIsOn = (Object.keys(this.state.selectedItems)).length > 0;
        if (selectionIsOn) {
            this.selectItem(id)
            return;
        }
        let user = this.state.collection?.user;
        this.props.history.push(`${!user?.isAgent ? routes.artCentral.collection.item : routes.agent.showAssigment}/${user?.isAgent ? assignment : id}`);
    }

    onLongPress = ({ id }: any) => {
        const { selectedItems } = this.state;
        if (selectedItems[id]) {
            delete selectedItems[id];
        } else {
            selectedItems[id] = true
        }
        this.setState({ selectedItems })
    }

    selectUnselectAll = (): void => {
        const { collection } = this.state;
        if (!collection) return;
        let selectedItems: any = {};

        if (this.totalSelected !== collection.itemsCount) {
            collection.items.forEach((item: ICollectionItem) => {
                if (item.id) selectedItems[item.id] = true
            })
        }
        this.setState({ selectedItems })
    }

    get totalSelected() {
        return (Object.keys(this.state.selectedItems)).length
    }

    deleteItems = () => {
        this.toggleLoading('deleting');
        const items = Object.keys(this.state.selectedItems)
        collectionService.deleteItems(this.params.id, { items }).then((response: AxiosResponse<any>) => {
            toastService.info(response.data.message)
            this.fetch();
            this.closeConfirmBox()
        }, error => {
            this.closeConfirmBox()
        })
    }

    closeConfirmBox = () => {
        this.setState({ deleting: false, confirmBox: false });
    }

    openAction = () => {
        this.setState({ openAction: true })
    }

    openDeleteBox = () => {
        this.setState({ openAction: false, openDeleting: true })
    }

    close = () => {
        this.setState({ openAction: false, openDeleting: false })
    }

    deletCollection = (): void => {
        this.toggleLoading('deleting');
        collectionService.deleteCollection(this.params.id).then((response: AxiosResponse<any>) => {
            this.toggleLoading('deleting');
            toastService.success(response.data.message)
            this.props.history.goBack();
        }, (error: any) => {
            this.toggleLoading('deleting');
            // toastService.error(lang.common.httpError);
        })
    }

    checkAuctionArt = () => {
        let { collection, selectedItems } = this.state
        let count = collection?.items.filter((i: ICollectionItem) => {
            if (i.isAuctioned && selectedItems[i.id as string]) {
                delete selectedItems[i.id as string]
                return i
            }
        }).length
        this.setState({ selectedItems })
        if (count && count > 0) return false;
        return true
    }
    openAssignmentBox = (id?: any) => {
        if (!this.checkAuctionArt()) {
            return
        }
        this.setState({ openAssignBox: true, selectedForAssignment: id })
    }

    closeAssignmentBox = () => {
        this.setState({ openAssignBox: false, selectedForAssignment: null })
    }

    selectedItemsForAssignment = () => {
        return this.totalSelected > 0 ? Object.keys(this.state.selectedItems) : [this.state.selectedForAssignment]
    }

    onAssignmentComplete = () => {
        this.closeAssignmentBox();
        this.fetch();
    }

    scrollWithDrag = (position: any, scroll: any) => {
        if (this.state.isDragging) {
            scroll({ x: position.position.x, y: position.position.y })
        }
    }

    onMoveUpward = (index: any) => {
        let newCollection: any = this.state.collection
        if (newCollection) {
            let items = newCollection.items
            if (index !== 0) {
                let temp = items[index];
                items[index] = items[index - 1];
                items[index - 1] = temp;
                newCollection.items = items
                this.setState({
                    collection: newCollection
                });
                let data = items.map((item: any) => item.id)
                this.UpdatingCollectionSequence(data)
            } else {

                toastService.error("Unable to move the collection Upword")
            }
        }
    }

    onMoveDownward = (index: any) => {
        let newCollection: any = this.state.collection
        if (newCollection) {
            let items = newCollection.items
            if (index !== items.length - 1) {
                let temp = items[index];
                items[index] = items[index + 1];
                items[index + 1] = temp;
                newCollection.items = items
                this.setState({
                    collection: newCollection
                });
                let data = items.map((item: any) => item.id)
                this.UpdatingCollectionSequence(data)
            } else {
                toastService.error("Unable to move the collection Downward")
            }
        }
    }

    UpdatingCollectionSequence = (data: Array<any>) => {
        let { collection } = this.state
        if (!collection) return
        if (!collection.user.isAgent && !collection.user.roles.includes(ERole.AGENT)) {
            collectionService.updateArtSeq(collection?.id, { items: data, agent: false }).then((res: AxiosResponse<any>) => {
            }).catch(err => console.log(err))
        } else {
            collectionService.updateArtSeq(collection?.id, { items: data, agent: true }).then((res: AxiosResponse<any>) => {
            }).catch(err => console.log(err))
        }
    }

    render() {
        const { loading, collection, confirmBox, deleting, openDeleting, openAction, openAssignBox } = this.state;
        const isFavorit = ((collection && collection.favoritedBy) || []).some((f: string) => f === this.props.user._id);
        const { user } = this.props;
        const isCreator = user._id === collection?.user._id;

        return (
            <div id="scroll-dnd" className="at-artcollections scrollable at-contentarea">
                {
                    loading && <LoadingComponent />
                }
                {
                    collection && (
                        <div className="at-height100">
                            <div className="at-artcollectionsholder">
                                <div className="at-orangeeconhead">

                                    <div className="at-orangeeconcontent">
                                        <UserPicComponent user={collection.user} />
                                        {collection.user.isDeaf && <span className="at-postcontenticon"><i className="icon-hand"></i></span>}
                                        <h3 className="text-left">
                                            {collection.title}
                                            <UserNameComponent user={collection.user} />
                                        </h3>
                                        <div className="at-btniconholder">
                                            {/* <a id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a>
                                            <a className="at-btnhearttwo at-postbtn cursor-pointer" onClick={() => this.doFavorit(!isFavorit)}>
                                                <i className={`icon-favorite${isFavorit ? '-2': ''}`}></i>
                                            </a>
                                            { collection.user._id === user._id &&  <a className="at-btnmore at-postbtn cursor-pointer" onClick={this.openAction}><i className="icon-more"></i></a>} */}
                                            {/* {(isCreator && !collection.user.isAgent) && <Link to={{ pathname: `${routes.artCentral.collection.edit}/${this.params.id}/` }}>
                                                            <i className="icon-edit text-muted" title="Edit"></i>
                                                        </Link>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="at-artcollectionsarea">
                                <div className="at-formtheme at-formartcollection">
                                    <fieldset className="at-artcollectioncontent">
                                        <div className="form-group">
                                            <h3>
                                                <a className="at-left-arrowicon cursor-pointer" onClick={this.props.history.goBack}>
                                                    <i className="icon-left-arrow"></i>
                                                </a>
                                                {this.totalSelected > 0 ? `Selected ${this.totalSelected}/${collection.itemsCount} Images` : ''}
                                            </h3>
                                            {
                                                (isCreator && !collection.user.isAgent) && <div className="at-btniconholder">
                                                    {!collection.user.roles.includes(ERole.AGENT) && <div className="at-checkbox">
                                                        <button disabled={this.totalSelected < 1} className="at-assingn-btn" onClick={this.openAssignmentBox}><i className="icon-assign"></i></button>
                                                    </div>}
                                                    <div className="at-checkbox">
                                                        <input type="checkbox" name="remember" checked={this.totalSelected === collection.itemsCount} id="artcollectionselectall" onClick={this.selectUnselectAll} />
                                                        <label htmlFor="artcollectionselectall"></label>
                                                    </div>
                                                    <button disabled={this.totalSelected < 1} className="at-btndelete text-danger" onClick={() => this.toggleLoading('confirmBox')}><i className="icon-delete"></i></button>
                                                </div>
                                                // (this.totalSelected > 0 && !confirmBox) && (
                                                // )
                                            }
                                        </div>
                                    </fieldset>
                                    <fieldset className="at-artgallerycollections at-themescrollbar">
                                        <ul className="at-artcollectiongrid at-artgallerycollection" >
                                            {
                                                collection.itemsCount < 1 && <NoDataComponent icon="fa fa-images" />
                                            }
                                            {
                                                collection.items.map((item: ICollectionItem, index: any) => (
                                                    <ArtItemImageComponent key={item.id} collection={collection} item={item} isSelected={this.isSelected(item.id)} onClick={() => this.onClick(item)} onLongPress={this.onLongPress} openAssignmentBox={this.openAssignmentBox} onMoveUpward={this.onMoveUpward} onMoveDownward={this.onMoveDownward} index={index} {...this.props} />
                                                ))
                                            }
                                        </ul>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    )
                }

                {confirmBox && <ConfirmAlertComponent open={confirmBox} onCancel={this.closeConfirmBox} onConfirm={this.deleteItems} message={lang.deleteSelectedItems} confirmTitle={deleting ? 'Deleting...' : 'Delete'} />}

                {openAction && <div id="at-themeaddactionpopup" className={`at-themepopupholder ${openAction && 'at-showthemesignuppopup'}`}>
                    <div className="at-themepopupbox">
                        <a className="at-close cursor-pointer" onClick={this.close} id="at-close"><i className="icon-cancel"></i></a>
                        <div className="at-addactionholder">
                            <div className="at-formthemehead">
                                <h2>Action</h2>
                            </div>
                            <ul>
                                <li>
                                    <Link to={{ pathname: `${routes.artCentral.collection.edit}/${this.params.id}/` }}>Edit</Link>
                                </li>
                                <li className="at-active">
                                    <a className="cursor-pointer" onClick={this.openDeleteBox}>Delete</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>}

                {openAssignBox && <div id="at-themeaddactionpopup" className={`at-themepopupholder ${openAssignBox && 'at-showthemesignuppopup'}`}>
                    <div className="at-themepopupbox at-themepopup-assigngallery">
                        <a className="at-close cursor-pointer" onClick={this.closeAssignmentBox} id="at-close"><i className="icon-cancel"></i></a>
                        <div className="at-addactionholder">
                            <div className="at-formthemehead">
                                <h2>Assign to Gallery</h2>
                            </div>
                            <AssignmentFormComponent items={this.selectedItemsForAssignment()} onComplete={this.onAssignmentComplete} />
                        </div>
                    </div>
                </div>}

                {openDeleting && <ConfirmAlertComponent open={openDeleting} onCancel={this.close} onConfirm={this.deletCollection} confirmTitle={deleting ? 'Deleing...' : 'Delete'} />}

            </div>
        );
    }
}

const mapState = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapState, {})(CollectionViewPage);