import { AxiosResponse } from 'axios'
import { format } from 'path'
import React, { useEffect, useState } from 'react'
import { EArtistPrintType } from '../../../enums'
import { IFormates } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { IArtistServicesState, initArtistServiceState, IArtistSelectedService } from '../../../interfaces/services.interface'
import { consultantService } from '../../../services/consultantServices.service'
import { BuyerSeePrinterAndFrame } from '../../User/Services/PrintingServices/Modal/BuyerSeePrinterAndFrame.modal'
import { BuyerSeePrinterDetails } from '../../User/Services/PrintingServices/Modal/BuyerSeePrinterDetails.modal'
import { ArtistOwnPrinter } from '../../User/Services/PrintingServices/Modal/UserOwnPrinter.modal'

interface IProps {
    format: IFormates
    onSubmitShowService: Function
}
export const ShowArtServiceComponent = ({ format, onSubmitShowService }: IProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [state, setState] = useState<IArtistServicesState>({ ...initArtistServiceState })
    const fetchCurrentSelectedService = () => {
        if (!format) return
        setLoading(true)

        consultantService.getSelectedServiceById((format.selectedService as IArtistSelectedService)._id).then((res: AxiosResponse<IResponse<IArtistSelectedService>>) => {
            setState({
                ...state,
                ...res.data.data as any
            })
            // if (res.data.data.printType === EArtistPrintType.OWN) {
            //     onClickingSelectPrinter()
            // }
        })
    }

    useEffect(() => {
        fetchCurrentSelectedService()
    }, [])

    const renderPage = () => {
        switch ((format.selectedService as IArtistSelectedService).printType) {
            case EArtistPrintType.FRAMED:

                return <BuyerSeePrinterAndFrame onSubmitShowService={onSubmitShowService} format={format} selectedServices={state.selectedServices} />;

            case EArtistPrintType.UNFRAMED:

                return <BuyerSeePrinterDetails format={format} selectedPrint={state.selectedServices[0]} />

            case EArtistPrintType.OWN:

                return <ArtistOwnPrinter parentState={state} onlyView={true} onClose={() => { }} onUpdateData={() => { }} loading={false} />

            default:
                return null;
        }
    }

    return renderPage()

}