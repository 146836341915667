import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleActiveMemberSidebar } from '../../store/actions'
import { IStoreReducers, ISpace } from '../../interfaces'
import { getInitials, _logger } from '../../helper'
import { userImage } from '../../shared'

interface IActiveSpaceMembersProps extends RouteComponentProps {
	toggleActiveMemberSidebar?: any
	activeSpace?: ISpace
}

class ActiveSpaceMembersComponent extends Component<IActiveSpaceMembersProps> {
	render() {
		const { activeSpace } = this.props

		const contacts = activeSpace?.contacts

		_logger('Contacts', contacts)

		/* -------------------------------------------------------------------------- */
		/*                      start - component to be rendered                      */
		/* -------------------------------------------------------------------------- */

		const contactsList = contacts?.map((contact) => (
			<li
				className={contact.activeSpace === activeSpace?._id ? 'at-active' : ''}
				key={contact._id}
			>
				<a  className='at-btncontactdetail'>
					<figure className='at-userimg'>
						<img
							src={
								contact.profile
									? userImage(contact.profile)
									: getInitials(contact.firstName, contact.lastName)
							}
							alt=''
						/>
					</figure>
					<div className='at-usersidebarcontent'>
						<h3>
							{contact.firstName} {contact.lastName}
							{contact.isDeaf && <i className='icon-hand'></i>}
						</h3>
					</div>
				</a>
			</li>
		))

		/* -------------------------------------------------------------------------- */
		/*                      end - component to be rendered                      */
		/* -------------------------------------------------------------------------- */

		return (
			<aside id='at-themeusersidebar' className='at-themeusersidebar' style={{display:'none'}}>
				<a
					onClick={() => this.props.toggleActiveMemberSidebar()}
					className='at-btnopenapplicants'
				></a>
				<div className='at-usersidebarcontentholder'>
					<ul>
						{contactsList}
						<li>
							<a  className='at-btncontactdetail'>
								<figure className='at-userimg'>
									<img
										src={
											activeSpace?.userId?.profile &&
											userImage(activeSpace?.userId?.profile)
										}
										alt=''
									/>
								</figure>
								<div className='at-usersidebarcontent'>
									<h3>Nouman Ghani</h3>
								</div>
							</a>
						</li>
					</ul>
				</div>
			</aside>
		)
	}
}

const mapStateToProps = (state: IStoreReducers) => ({
	activeSpace: state.user.activeSpace,
})

export default connect(mapStateToProps, { toggleActiveMemberSidebar })(
	ActiveSpaceMembersComponent
)
