import React from "react";
import { useHistory } from "react-router-dom";

interface IProps {}
export const DashboardComponent = (props: IProps) => {
  const history = useHistory();
  return (
    <div className="at-createaccount at-contentarea-two scrollable scrollbar-hidden">
      <div className="at-themehead">
        <a onClick={() => history.goBack()} className="at-left-arrowicon">
          <i className="icon-left-arrow"></i>
        </a>
        <h2>Dashboard</h2>
      </div>
      <div className="at_theme_innercontent dashboard-component-parent">
        <div className="row">
          <div className="col-6">
            <div className="">
              <div className="text-center">
                <h5>Audit Fail</h5>
              </div>
              <span>
                Transactions: <u>2</u>
              </span>
              <span>
                Service Trans: <u>0</u>
              </span>
            </div>
          </div>
          <div className="col-6">
            <div className="">
              <div className="text-center">
                <h5>Fulfillment Issues</h5>
              </div>
              <span>
                Shipping Open: <u>2</u>
              </span>
              <span>
                Return Open: <u>2</u>
              </span>
            </div>
          </div>
          <div className="col-6">
            <div className="">
              <div className="text-center">
                <h5>Expired Offers</h5>
              </div>
              <span>
                Disregard Lavel: <u>2</u>
              </span>
              <span>
                Interest Lavel: <u>0</u>
              </span>
              <span>
                Education Lavel: <u>2</u>
              </span>
            </div>
          </div>
          <div className="col-6">
            <div className="">
              <div className="text-center">
                <h5>Stale Comm. Work</h5>
              </div>
              <span>
                Stale Requests: <u>2</u>
              </span>
              <span>
                Stale Proposals: <u>0</u>
              </span>
            </div>
          </div>
          <div className="col-6">
            <div className="">
              <div className="text-center">
                <h5>Cash Report</h5>
              </div>
              <span>
                Stripe Balance: <u>$34,879</u>
              </span>
              <span>
                Outstanding Payments: <u>$32,678</u>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
