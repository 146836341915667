import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { images } from '../../assets/image'
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';
import { templateSelector } from '../../store/selectors';
import { ICollection, ICollectionItem, ITemplateReducerState, IUser } from '../../interfaces';
import { AxiosResponse } from 'axios';
import { collectionService } from '../../services/collection.service';
import { collectionImage, getUserTemplateRoute, templatesImages } from '../../shared';
import { CustomImage } from '../../templates/components/CustomImage';
import { ERole } from '../../enums';
import { APP_URL } from '../../environment';
import { setInPlatformWebState } from '../../store/actions';
import { routes } from '../../router';
import { IResponse } from '../../interfaces/db.interface';

interface IProps {
    userId: string
    onGoBack: Function
    onSelectCollection: Function
    onClickOnItem: Function
}
const BuyerGalleryPage = ({ userId, onGoBack, onSelectCollection, onClickOnItem }: IProps) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [collections, setCollections] = useState<Array<ICollection>>([])
    const [cUser, setCUser] = useState<IUser>()
    const [loading, setLoading] = useState<boolean>(false)

    const fetchCollections = () => {
        setLoading(true)
        collectionService.listWithItems(userId).then((response: AxiosResponse<IResponse<Array<ICollection>>>) => {
            setCUser(response.data.data[0].user)
            setCollections([...response.data.data])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchCollections()
    }, [])

    const onClikcOnTemplate = () => {
        if (!cUser) return
        if (window.innerWidth > 770) {
            if (cUser.template && (cUser.template as ITemplateReducerState).url) {
                // window.location.replace(`${APP_URL}/${(cUser.template as ITemplateReducerState)?.url}/${getUserTemplateRoute(cUser.template as ITemplateReducerState)}`)
                // history.replace(`${(cUser.template as ITemplateReducerState)?.url}/${getUserTemplateRoute(cUser.template as ITemplateReducerState)}`)
                dispatch(setInPlatformWebState({
                    nextPath: `${(cUser.template as ITemplateReducerState)?.url}`,
                    isActive: true,
                    previousPath: routes.home,
                    activeSplitScreen: true
                }))
                history.push(routes.loadingRoutes)
                return
            }
        }
    }
    const renderCollection = (i: ICollectionItem) => {

        return (
            <div key={i.id} className="col-md-4 col-lg-4">
                <div className="At-BorderFigureHolder">
                    <div className="At-BorderFigure">
                        <CustomImage src={collectionImage(i, `${i.url}`)} thumbnail={collectionImage(i, `blur_${i.url}`)} />

                    </div>
                    <div className="At-ArtDetailBox">
                        {i.name && <h5>
                            {i.name}
                        </h5>}
                        {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>}
                        <i className="icon-dotted-circle" onClick={() => onClickOnItem(i)}></i>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <section className="At-SectionGallery At-Padding-tb8  At-Section-Mh800">
                <div className="container">
                    <div className="row ">
                        <div className="col-12 at-panelcollection-heading">
                            <a onClick={() => onGoBack()} className="at-left-arrowicon"><i className="icon-arrow-bold"></i></a>
                            <div className="at-panelcollection-text">
                                {cUser && <h2>Art Studio</h2>}
                                {cUser && <h3 className="at-color-grey"> {cUser?.roles.includes(ERole.AGENT) ? cUser?.gallery_name : `${cUser?.firstName} ${cUser?.lastName}`} </h3>}
                            </div>
                            {(cUser?.template && (cUser?.template as ITemplateReducerState).url) && <button className="at-btn" onClick={() => onClikcOnTemplate()}>VISIT ARTIST WEBSITE</button>}

                        </div>
                    </div>
                    {collections.length > 0 && collections.map((c: ICollection) => {
                        if (c.isHide) {
                            return null
                        }
                        let items = c.items.length > 5 ? c.items.slice(0, 5) : c.items;
                        return (
                            items.length > 0 ? <div key={c.id} className="row">
                                <div className="At-GalleryTitlePanel col-12 cursor-pointer">
                                    <h2>{c.title}</h2>
                                </div>
                                {items.length > 0 && items.map((i: ICollectionItem, index: number) => {
                                    return <>
                                        {renderCollection(i)}
                                        {c.items.length > 5 && index === 4 && <a className="col-md-4 col-lg-4 At-SixthCol" onClick={() => onSelectCollection(c)}>
                                            <div key={i.id} >
                                                <div className="At-BorderFigureHolder">
                                                    <div className="At-BorderFigure">
                                                        <figure key={i.id}>
                                                            <img src={collectionImage(i, `${i.url}`)} alt="" />

                                                        </figure>
                                                        <figcaption>
                                                            <span><i className="icon-add"></i>{c.items.length - 5}</span>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>}
                                    </>
                                })}
                            </div> : null
                        )
                    })}
                </div>
            </section>
        </div>
    )
}

export default BuyerGalleryPage