

export interface IVideo {
    url: string;
    isVideo: boolean;
    thumbnail: string
}
export interface ITutorailsCategory {
    _id: string,
    title: string,
    tutorials: Array<ITutorials>,
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly deletedAt: Date,
}

export interface ITutorials {
    _id: string,
    name: string,
    roles: Array<string>,
    category: ITutorailsCategory | string,
    tutorial: IVideo,
    screen: string,
    cover: string,
    pdf: string,
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly deletedAt: Date,
}

export interface ITutorialState {
    _id?: string,
    name: string,
    roles: Array<string>,
    category?: ITutorailsCategory | string,
    tutorial: any,
    screen: string,
    cover: string,
    pdf: string,
    coverPreview: Array<any>
    tutorialPreview: Array<any>
}

export const tutorialInitailState = {
    name: '',
    roles: [],
    tutorial: null,
    screen: '',
    cover: '',
    pdf: '',
    coverPreview: [],
    tutorialPreview: []
}