import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { images } from '../../assets/image'
import { userTemplateService } from '../../services/userTemplates.service'
import { templatesImages } from '../../shared'
import { setInPlatformWebState, setTemplateData } from '../../store/actions'
import { authSelector, templateSelector, userSelector } from '../../store/selectors'

import { routes } from './routes'
import { routes as mainRoutes } from '../../router'
import { ZoomActions, EZoom } from '../../services/zoom.service'

const Header = () => {
    let template = useSelector(templateSelector)
    const user = useSelector(userSelector)
    const token = useSelector(authSelector).access_token
    let isIframe = window.self !== window.top
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const [isEditArtist, setIsEditArtist] = useState<boolean>(false)
    const [isEditEevnt, setIsEditEevnt] = useState<boolean>(false)
    const [nameArtist, setNameArtist] = useState<string>('Artists')
    const [nameEvent, setNameEvent] = useState<string>('Exihibitions')

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const onClickEdit = (name: string) => {
        setNameArtist(name)
        setIsEditArtist(true)
    }

    const onUpdateArtistMenuName = () => {
        if (!template) return
        //     eventPageName?: string,
        // artistNamePage?: string
        userTemplateService.updateTemplate(template?._id as string, { artistNamePage: nameArtist }).then((res: AxiosResponse<any>) => {
            dispatch(setTemplateData(res.data.data))
            setIsEditArtist(false)

        }).catch((err: any) => console.log(err))
    }
    const onUpdateEventMenuName = () => {
        userTemplateService.updateTemplate(template?._id as string, { eventPageName: nameEvent }).then((res: AxiosResponse<any>) => {
            dispatch(setTemplateData(res.data.data))
            setIsEditEevnt(false)
        }).catch((err: any) => console.log(err))
    }

    useEffect(() => {
        if (template) {

            template.eventPageName ? setNameEvent(template.eventPageName) : setNameEvent('Artists')
            template.artistNamePage ? setNameArtist(template.artistNamePage) : setNameArtist('Exihibitions')
        }
    }, [template])

    const getOmniRoutes = () => {
        let path = location.pathname
        if (path.includes(routes(template.url).exhibitions.index)) {
            return `${mainRoutes.artCentral.events.list}/${template.user._id}`
        }
        if (path.includes(routes(template.url).artists.index)) {
            return `${mainRoutes.artCentral.collection.list}/${template.user._id}`
        }

        return `${mainRoutes.artCentral.collection.list}/${template.user._id}`
    }

    const onClickLogo = () => {
        if (isIframe) return
        dispatch(setInPlatformWebState({
            nextPath: `${getOmniRoutes()}`,
            activeSplitScreen: false,
            isActive: false
        }))
        if (window.innerWidth > 767) {
            ZoomActions.triggerAction({ type: EZoom.ART_COLLECTION, value: null })
        }
        history.push(mainRoutes.loadingRoutes)
    }

    const onClickLink = (r: string) => {
        if (!isNavCollapsed) {
            setIsNavCollapsed(true)
        }
        dispatch(setInPlatformWebState({
            previousPath: r
        }))
    }

    return (
        <div>
            <section className="At-SectionHeader">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-dark ">
                        {template?.logoImage && <Link className="At-LinkLogo" to={routes(template.url).home}>
                            <figure className="At-FigureLogo mb-0">
                                <img src={templatesImages(`high_${template.logoImage}`, true)} className="img-fluid" alt="" />
                                {/* <img src={images.template.logoTemplate2} className="img-fluid At-Template2" alt="" /> */}
                            </figure>
                        </Link>}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded={isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
                            <ul className="navbar-nav ml-auto">
                                <li onClick={() => onClickLink(routes(template.url).home)} className="nav-item">
                                    <Link to={routes(template.url).home}>Home</Link>
                                </li>
                                <li onClick={() => onClickLink(routes(template.url).exhibitions.index)} className="nav-item active">
                                    {!isEditEevnt ?
                                        <>
                                            <Link to={routes(template.url).exhibitions.index}>{nameEvent} </Link>
                                            {token && user._id === template.user._id && <i className="icon-edit2 cursor-pointer"
                                                onClick={() => {
                                                    setIsEditEevnt(true)
                                                }}
                                            />}
                                        </>
                                        :
                                        <>
                                            <input className='form-control'
                                                value={nameEvent}
                                                // onBlur={() => {
                                                //     setIsEditEevnt(false)
                                                //     setNameEvent('')
                                                // }}
                                                onChange={(e: any) => setNameEvent(e.target.value)}
                                            />
                                            <i className="icon-tick cursor-pointer" onClick={() => onUpdateEventMenuName()} />
                                        </>
                                    }
                                </li>
                                <li onClick={() => onClickLink(routes(template.url).artists.index)} className="nav-item">
                                    {!isEditArtist ?
                                        <>
                                            <Link to={routes(template.url).artists.index}>{nameArtist}</Link>
                                            {token && user._id === template.user._id && <i className="icon-edit2 cursor-pointer"
                                                onClick={() => {
                                                    setIsEditArtist(true)
                                                }}
                                            />}
                                        </>
                                        :
                                        <>
                                            <input
                                                className='form-control'
                                                value={nameArtist}
                                                // onBlur={() => {
                                                //     setIsEditArtist(false)
                                                //     setNameArtist('')
                                                // }}
                                                onChange={(e: any) => setNameArtist(e.target.value)}
                                            />
                                            <i className="icon-tick cursor-pointer" onClick={() => onUpdateArtistMenuName()} />
                                        </>

                                    }
                                </li>
                                <li onClick={() => onClickLink(routes(template.url).about)} className="nav-item">

                                    <Link to={routes(template.url).about}>About</Link>
                                </li>
                                <li onClick={() => onClickLink(routes(template.url).Contact)} className="nav-item">
                                    <Link to={routes(template.url).Contact}>Contact</Link>
                                </li>
                                <li onClick={() => onClickLink('')} className="nav-item">
                                    <Link to="#">
                                        <figure className="At-OmniLogo">
                                            <img src={images.logoOmniNew2} className="img-fluid" alt="" onClick={() => onClickLogo()} />
                                        </figure>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </section>
        </div>
    )
}

export default Header