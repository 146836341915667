import { HttpService } from './base.service'
import { data } from 'jquery';

class AdminService extends HttpService {
    private readonly prefix: string = 'admin';

    /**
     *  All Users List
     */
    userList = (): Promise<any> => this.get(`${this.prefix}/users`);

    /**
     *  Block & unBlock User
     */
    blockUser = (id: any, data: any): Promise<any> => this.post(`${this.prefix}/users/block/${id}`, data);

    /**
     *  Search User by Phone Number
     */
    userWithParams = (data: any): Promise<any> => this.get(`${this.prefix}/user/${data}`);

    /**
     *  Create Policy
     */
    createPolicy = (data: any): Promise<any> => this.post(`${this.prefix}/policy/create`, data)

    /**
     *  get Policy
     */
    getPolicy = (): Promise<any> => this.get(`${this.prefix}/policy`)

    /**
     *  Update Policy
     */
    updatePolicy = (id: any, data: any): Promise<any> => this.post(`${this.prefix}/policy/update/${id}`, data)

    /**
    *  Search User by Phone Number
    */
    pendingUserWithParams = (data: any): Promise<any> => this.get(`${this.prefix}/user/pending/${data}`);

    approveUser = (id: any, data: any): Promise<any> => this.post(`${this.prefix}/users/approve/${id}`, data);

    /****************************************** Admin Shipping Factors ****************************************** */
    /**
     * Create Weight Factors
     * @param data  
     */
    createWeightFactors = (data: any): Promise<any> => this.post(`${this.prefix}/weight-factor/create`, data)

    /**
     * Update Weight Factors
     * @param data  
     */
    updateWeightFactors = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/weight-factor/update/${id}`, data);

    /**
     * fetch Shipping factors
     */

    getShippingFactor = (): Promise<any> => this.get(`${this.prefix}/shipping-factor`)


    /**
     * Update Sequence
     * @param data  
     */
    udpateFactorSequence = (data: any): Promise<any> => this.put(`${this.prefix}/shipping-factor/update-sequence`, data);

    /**
     * delete Weight Factors
     * @param id  
     */
    deleteWeightFactors = (id: any): Promise<any> => this.put(`${this.prefix}/weight-factor/delete/${id}`);


    /**
     * Create Cost Factors
     * @param data  
     */
    createCostFactors = (data: any): Promise<any> => this.post(`${this.prefix}/cost-factor/create`, data)
}

export const adminService = new AdminService();