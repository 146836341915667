import React, { Component } from 'react'
import { Subscription } from 'rxjs';
import { IZoom, EZoom, ZoomActions } from '../../services/zoom.service';
import FeedComponent from '../ArtCentral/feed/Feed.component';
import CollectionItemViewComponent from '../ArtCentral/collection/ItemView.component';
import LoadingComponent from './Loading.component';
import ImageGallery from 'react-image-gallery';
import { IPost, IPostImage, ICollectionItem, IArtAttachment } from '../../interfaces';
import { postImage, collectionImage } from '../../shared';
import LazyLoadImageComponent from '../common/Lazy-load-image.component';
import { IFrameComponent } from './IFrame.component';
import { connect } from 'react-redux';
import { setInPlatformWebState } from '../../store/actions'
import Art_Collections from '../../pages/ArtCentral/Art_Collections';
import BuyerArtCentral from '../../pages/BuyerArtCentral';
interface IState {
	zoom: IZoom,
	loading: boolean
}

class ZoomItemComponent extends Component<{ setInPlatformWebState: Function }> {

	zoomSubscription = new Subscription();
	zoomEventValue: IZoom = {
		type: EZoom.NONE,
		value: null
	}
	state: IState = {
		loading: false,
		zoom: this.zoomEventValue
	}

	componentDidMount() {
		const sub$ = ZoomActions.listen.subscribe(this.zoomListener);
		this.zoomSubscription.add(sub$)
	}

	zoomListener = (data: IZoom) => {
		this.zoomEventValue = data;
		const closeZoom = data.type === EZoom.NONE
		if (!closeZoom) this.setState({ loading: true })

		setTimeout(() => {
			this.setState({ zoom: { ...data }, loading: false })
		}, closeZoom ? 0 : 1000);
	}

	componentWillUnmount() {
		this.zoomSubscription.unsubscribe();
	}

	assetsList = () => {
		const { zoom } = this.state;
		let images: any[] = [];

		if (zoom.type === EZoom.FEED) {
			const value: IPost = zoom.value;
			images = (value.assets || []).map((asset: IPostImage) => ({
				thumbnail: postImage(value._id, asset.isVideo ? asset.thumbnail : asset.url, true),
				original: postImage(value._id, asset.url),
				midResolution: postImage(value._id, `high_${asset.url}`, true),
				isVideo: asset.isVideo
			}))
		} else if (zoom.type === EZoom.COLLECTION_ITEM) {
			const item: ICollectionItem = zoom.value;
			const assets: Array<IArtAttachment> = [{ url: item.url || '', id: item.id || '' }, ...(item.items || [])]
			images = assets.map((asset: IArtAttachment) => ({
				thumbnail: collectionImage(item, asset.url || '', true),
				original: collectionImage(item, asset.url || ''),
				isVideo: false
			}))

		} else if (zoom.type === EZoom.TEMPLATES) {
			const items = zoom.value
			images = items
		}

		return images;
	}

	onCloseZoom = () => {
		if (this.state.zoom.type === EZoom.TEMPLATES_IFRAME) {
			this.props.setInPlatformWebState({
				activeIFrame: false,
				nextPath: ``,
				iFrameUrl: ``,
			})
		}
		ZoomActions.triggerAction({ type: EZoom.NONE, value: null })
	}

	closeButton = () => <i className="icon-cancel at-closezoom" onClick={() => this.onCloseZoom()}></i>

	render() {
		const { zoom, loading } = this.state;
		return (
			<>
				{
					loading ? <LoadingComponent /> : (
						<>
							{
								zoom.type === EZoom.FEED && (
									<div className="zoom-container">
										{this.closeButton()}
										<div className="zoom-container-inner scroll-style-3">
											<ImageGallery showPlayButton={this.assetsList().length > 1} items={this.assetsList()} renderItem={(item: any, index: number) => <LazyLoadImageComponent id={item._id} index={index} isVideo={item.isVideo} videoUrl={item.original} midResolution={item.original} thumbnail={item.thumbnail} />} />
										</div>
									</div>
								)
							}

							{
								zoom.type === EZoom.COLLECTION_ITEM && (
									<div className="zoom-container">
										{this.closeButton()}
										<div className="zoom-container-inner scroll-style-3">
											<ImageGallery thumbnailClass='abcd' showPlayButton={this.assetsList().length > 1} items={this.assetsList()} renderItem={(item: any, index: number) => <LazyLoadImageComponent id={item._id} index={index} isVideo={item.isVideo} videoUrl={item.original} midResolution={item.original} thumbnail={item.thumbnail} />} />
										</div>
									</div>
								)
							}
							{
								zoom.type === EZoom.TEMPLATES && (
									<div className="zoom-container">
										{this.closeButton()}
										<div className="zoom-container-inner scroll-style-3">
											<ImageGallery thumbnailClass='abcd' showPlayButton={this.assetsList().length > 1} items={this.assetsList()} renderItem={(item: any, index: any) => <LazyLoadImageComponent id={index} index={index} isVideo={item.isVideo} videoUrl={item.original} midResolution={item.original} thumbnail={item.thumbnail} />} />
										</div>
									</div>
								)
							}
							{
								zoom.type === EZoom.TEMPLATES_IFRAME && (
									<div className="At-FullScreenPanel">
										{this.closeButton()}
										<div className="At-FullScreenInner scrollable scrollbar-hidden">
											<IFrameComponent url={zoom.value} />
										</div>
									</div>
								)
							}
							{
								zoom.type === EZoom.ART_COLLECTION && (
									<div id="zoom-collection" className="At-FullScreenPanel">
										{/* {this.closeButton()} */}
										<div className="At-FullScreenInner scrollable scrollbar-hidden">
											<BuyerArtCentral />
										</div>
									</div>
								)
							}
						</>
					)
				}
			</>
		)
	}
}

export default connect(null, { setInPlatformWebState })(ZoomItemComponent)
