import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { RETURN_OF_ART_NOTICE } from '../../constants'
import { INotification, IUser } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { notificationService } from '../../services'
import LoadingComponent from '../common/Loading.component'
import { UserPicComponent } from './index.component'
import { NotitcationLayOut } from './Noitification.component'

interface IPorps {
    user: IUser
    hideBackButton?: boolean
}
const AgentNotification = ({user, hideBackButton} : IPorps) => {

    const history = useHistory()
    const [notifications, setNotifications] = useState<Array<INotification>>([])
    const [loading, setLoading] = useState<boolean>(false)

    const handleReadCounts = () => {
        let count = notifications?.filter((item: INotification) => !item.read)
        return count.length
    }

    const fetch = () => {
        setLoading(true)
        notificationService.getNotifications(user._id).then((res: AxiosResponse<IResponse<Array<INotification>>>) => {
            if (res.data && res.data.data.length === 0) {
                setLoading(false)
                return
            }
            let data = res.data.data.reverse()
            setNotifications([...data])
            setLoading(false)

        }).catch((err) => {
            setLoading(false)

            console.log(err)
        })
    }

    useEffect(() => {
        if (!user){
            history.goBack()
        }
        fetch()
    }, [])

    const handleAdminNotification = () => {
        const canvas = document.createElement('canvas')
        canvas.style.display = 'none'
        canvas.width = 200
        canvas.height = 200
        document.body.appendChild(canvas)
        const context = canvas.getContext('2d')
        if (context) {
            context.fillStyle = '#92A8B5'
            context.fillRect(0, 0, canvas.width, canvas.height)
            context.font = '50px Helvetica'
            context.fillStyle = '#FBFAF9'
        }
        let name = 'Admin'

        context?.fillText(
            name,
            canvas.width / 6,
            canvas.height / 1.6
        )

        const data = canvas.toDataURL()
        document.body.removeChild(canvas)
        return data
    }
    return (
        <div className="at-createcollection at-contentarea-two">
            <div className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>Notifications <span>{`(${handleReadCounts()})`}</span>  </h2>
                </div>
                <div className="at-notifyholder">
                    {notifications && notifications.length > 0 ? (
                        <>
                            {
                                notifications.map((n: INotification) => (
                                    <div className="at-notifybox" key={n.id}>
                                        {!n.fromAdmin ? <UserPicComponent className="at-notifyfigure" user={n.from} /> :
                                            <figure className="at-notifyfigure">
                                                <img src={handleAdminNotification()} alt="" />
                                            </figure>}
                                        <div className="at-notifytext">
                                            <p>{n.message}</p>
                                            {n.type === RETURN_OF_ART_NOTICE && n.transaction && n.transaction.return_art &&
                                                <>
                                                    <div className="at-transact-line">
                                                        <p>Reason for Return: <span className="pl-2">{n.transaction?.return_art.reason}</span></p>
                                                    </div>
                                                    <div className="at-transact-line">
                                                        <p>Additional Information: <span className="pl-2">{n.transaction?.return_art.details}</span></p>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="at-transaction-main">
                                            <NotitcationLayOut notificationType={n.type} notification={n} />
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    ) :
                        <>{loading && <LoadingComponent />}</>
                    }

                </div>
                {/* at-notifyholder ends */}

            </div>
        </div>
    )
}

export default AgentNotification