import { ICollectionItem, IUser } from ".";



export interface IAuctionDocument {
    _id: string,
    id: string
    name: string
    startDate: Date
    endDate: Date
    startTime: Date
    endTime: Date
    description: string
    images: Array<any>
    pdf: string
    user: IUser
    isActive: boolean
    isHide: boolean
    poster: string
    timeZone: string
    arts: Array<ICollectionItem>
    attendees: Array<IAuctionAttendessDocument>
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

export interface IAuctionAttendessDocument {
    user: IUser
    auction: IAuctionDocument
    paddleNumber: number
}

export interface IAuctionState {
    _id?: string,
    id?: string
    name: string,
    startDate: any | Date,
    endDate: any | Date,
    startTime: any | Date,
    endTime: any | Date,
    description: string,
    images: Array<any>,
    arts: Array<ICollectionItem>
    pdf: any
    isHide: boolean,
    isActive: boolean,
    poster: any,
    timeZone: string
}

export const initAuctionState: IAuctionState = {

    name: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    arts: [],
    description: '',
    images: [],
    pdf: null,
    poster: null,
    isHide: false,
    isActive: true,
    timeZone: 'US/EST'
}

export interface IAuctionImageState {
    preview: any,
    image: any,
    previewsCopy: any,
    uploadartImage: boolean,
    artImageIndex: number
}


/****************************************************** Auction Attendees ********************************************* */

export interface IAuctionAttendessDocument {
    _id: string,
    id: string
    user: IUser
    auction: IAuctionDocument
    paddleNumber: number
    isActive: boolean
    isHide: boolean
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

/*********************************************************** Auction Art *********************************************** */

export interface IAuctionArtDocument {
    _id: string,
    id: string
    art: ICollectionItem,
    auction: IAuctionDocument,
    biddings: Array<IAuctionBiddingDocument>
    finalBid: IAuctionBiddingDocument
    activeForBidding: boolean
    purchasingTime: Date
    isPurchased: boolean
    isActive: boolean
    isHide: boolean
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}


/*********************************************************** Auction Bidding *********************************************** */

export interface IAuctionBiddingDocument extends Document {
    _id: string,
    id: string
    auctionArt: IAuctionArtDocument,
    auction: IAuctionDocument,
    attendee: IAuctionAttendessDocument
    amount: number
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}