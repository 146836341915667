import React, { useState, useEffect } from 'react'
import { images } from '../../assets/image'
import { RouteComponentProps, Link } from 'react-router-dom'
import { IFormates, ITransaction, IUser } from '../../interfaces'
import moment from 'moment'
import LoadingComponent from '../common/Loading.component'
import { collectionImage } from '../../shared'
import { CurrencyFormat } from '../common/CurrencyFormat'
import { paymentService, toastService, transactionService } from '../../services'
import { AxiosResponse } from 'axios'
import { ConfirmAlertComponent } from '../common/ConfirmAlert.component'
import { ERole, ETransactionStatus, EtransactionType } from '../../enums'
import { TransferLayout } from '../common/TransferLayout'
import { transactionStatus } from '../../helper'
import { AdminCuratorialofferComponent } from './Curatorial/AdminCuratorialOffer.component'

interface IPageProps extends RouteComponentProps {
    [key: string]: any
}
export const AdminCuratorialTransactionsComponent = ({ history }: IPageProps) => {
    const [transactions, setTransaction] = useState<Array<ITransaction>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showConfirmBox, setConfirmBox] = useState<boolean>(false)
    const [selectedTransaction, setSelectedTransaction] = useState<ITransaction>()

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        transactionService.getAllCuratorialTransactions().then((response: AxiosResponse<any>) => {
            let newData = [...response.data.response]
            setTransaction(newData.reverse())
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const getItemType = (item: ITransaction) => {
        if (item.formatesId) {
            let format: Array<IFormates> = item?.itemID?.formates.filter((value: IFormates) => value._id === item?.formatesId)
            if (format && format.length <= 0) {
                return ` `
            }
            if (format && format[0]) {
                return format[0].size
            }
            return ` `
        }
        return `Original`
    }

    const onOpenConfirmBox = (item: ITransaction) => {
        setSelectedTransaction(item)
        setConfirmBox(true)
    }

    const onCloseConfirmBox = () => {
        let object: any = {}
        setSelectedTransaction(object)
        setConfirmBox(false)
    }



    const checkStatus = (item: ITransaction) => {
        if (item.transactionState === ETransactionStatus.PAID) {
            return { color: 'grey', text: 'Paid', disabled: true }
        }

        if (item.transactionState === 'refunded') {
            return { color: 'grey', text: 'Paid', disabled: true }
        }
        if (item.transactionState === ETransactionStatus.RETURN_COMPLETED) {
            return { color: 'grey', text: ETransactionStatus.RETURN_COMPLETED.replace('_', ' ').toLowerCase(), disabled: true }
        }
        return { color: 'orange', text: 'Release funds', disabled: false }
    }



    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Curatorial Transactions</h2>
                </div>
                <div className="at-transaction-main">
                    {transactions?.length > 0 ? (
                        <>
                            {transactions?.map((item: ITransaction, index: number) => {
                                return (item.transactionType === EtransactionType.CURATORIAL_OFFER ? <AdminCuratorialofferComponent transaction={item} fetch={fetch} key={index} /> : null)
                            })}
                        </>
                    ) : (<>{loading && <LoadingComponent className='at-transaction-loader' />}</>)}

                </div>
            </div>
            {showConfirmBox && <ConfirmAlertComponent loading={loading} open={showConfirmBox} onClose={onCloseConfirmBox} onCancel={onCloseConfirmBox} onConfirm={() => { }} message="Are you sure you want to release the fund." />}
        </main>
    )
}
