export enum ETransactionStatus{
    PENDING = 'PENDING',
    SHIPPED = 'SHIPPED',
    RETURN_PENDING = 'RETURN_PENDING',
    COMPLETED = 'COMPLETED',
    DISPUTED = 'DISPUTED',
    RETURN_ALLOWED = "RETURN_ALLOWED",
    RETURN_NOT_ALLOWED =    'RETURN_NOT_ALLOWED',
    RETURN_SHIPPED = 'RETURN_SHIPPED',
    RETURN_COMPLETED = 'RETURN_COMPLETED',
    PAID = 'PAID',
    RESOLVED = 'RESOLVED'
}

export enum EtransactionType {
    ART = 'ART',
    PROPOSAL_COMPLETE = 'PROPOSAL_COMPLETE',
    PROPOSAL_INCOMPLETE = 'PROPOSAL_INCOMPLETE',
    CURATORIAL_OFFER = 'CURATORIAL_OFFER'
}