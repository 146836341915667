import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { connect, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory, useLocation, useParams } from 'react-router-dom';
import { _logger } from '../../helper';
import { IStoreReducers, IUser } from '../../interfaces';
import { RequestReceived, RequestMade } from '../../components/User/CommissionWork'
import { userSelector } from '../../store/selectors';
import { UniversalModal } from '../../components/common/Modals/Universal.modal';
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial';
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon';
import { EScreens } from '../../enums/screens.enum';

interface IProps {
    routeUser?: IUser
    hideBackButton?: boolean
}

const CommissionWork = ({ routeUser, hideBackButton }: IProps) => {

    const storeUser = useSelector(userSelector)
    const history = useHistory()
    const params = (useParams() as any).id
    const user = routeUser && routeUser._id ? routeUser : storeUser

    const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false)

    const openTutorialBox = () => {
        setShowTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setShowTutorialBox(false)
    }
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>Commission Work <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.COMMISSION_WORK} onClick={openTutorialBox} /></span></h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')} selected={params ? Number(params) : 0}>
                        <Tab label="Request Received">
                            <RequestReceived history={history} user={(user as IUser)} />
                        </Tab>
                        <Tab label="Request Made">
                            <RequestMade history={history} user={(user as IUser)} />
                        </Tab>
                    </Tabs>
                </div>
                {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={closeTutorialBox} position='bottom-0'>
                    <ScreenTutorial screen={EScreens.COMMISSION_WORK} />
                    {/* at-categorybox ends */}
                </UniversalModal>
                }
            </div>
        </main>
    )
}


export default CommissionWork