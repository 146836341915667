import React from 'react'
interface IProps{
    icon?: string
    text?: string
    className?: string
}
export const NoDataComponent = ({ icon, text, className }: IProps) => {
    return (
        <div className={`text-center text-muted ${className}`}>
            <h2><i className={ `text-muted fs-30 ${icon ? icon : 'fa fa-table'}`}></i></h2>
            <h3 className="text-muted">{ text ? text : 'No Data Available'}</h3>
        </div>
    )
}
