import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { images } from '../../../../assets/image'
import { EServicesFrequencyType, EServicesPaymentType } from '../../../../enums'
import { PLATFORM_FEE } from '../../../../environment'
import { IResponse } from '../../../../interfaces/db.interface'
import { IConsultantServicesDocument } from '../../../../interfaces/services.interface'
import { toastService } from '../../../../services'
import { consultantService } from '../../../../services/consultantServices.service'
import { userSelector } from '../../../../store/selectors'
import HeartComponent from '../../../ArtCentral/feed/HeartIcon.component'
import { CurrencyFormat } from '../../../common/CurrencyFormat'
import { UniversalModal } from '../../../common/Modals/Universal.modal'
import { ProposalImageView } from '../../../common/ProposalImageView'
import { TmceEditor } from '../../../common/TMCEditor.comopnent'
import { CustomEditior } from '../../../Editor'
import { UpdateCardForm } from '../../../Stirpe'
import { UserPicComponent } from '../../index.component'

interface IProps {
    service: IConsultantServicesDocument
    onSetServiceProvider: Function
    showUserService: string
    openMessageBox: Function
}
export const ServiceComponent = ({ service, onSetServiceProvider, showUserService, openMessageBox }: IProps) => {

    const [more, setMore] = useState<boolean>(false)
    const [showPayment, setShowPayment] = useState<boolean>(false)
    const [showCard, setShowCard] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const user = useSelector(userSelector)

    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')

    const openPaymentModal = () => {
        setShowPayment(true)
    }
    const closePaymentModal = () => {
        setShowPayment(false)
    }

    const onConfirmPaymentModal = () => {
        openCardModal()
        // closePaymentModal()
    }
    const openCardModal = () => {
        setShowCard(true)
    }
    const closeCardModal = () => {
        setShowCard(false)
    }

    const onBuy = (source: any) => {
        let data = {
            serviceId: service._id,
            sourceToken: source.id,
            userId: user._id
        }
        setLoading(true)
        consultantService.buyService(data).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            } else {
                toastService.error(res.data.message)
            }
            closeCardModal()
            closePaymentModal()
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const getDescription = () => {
        let text = service.overView
        const showDesc = text.substring(0, 110);
        if (text.length < 111) {
            return showDesc
        }
        return <span className="at-span-moreless">{more ? text : showDesc}<a className="cursor-pointer secondary-color" onClick={() => setMore(!more)}>{more ? 'Less' : '... More'}</a></span>
    }

    const priceText = () => {
        if (service.paymentMethod === EServicesPaymentType.RECURRING_PAYMENTS) {
            return `${service.numberOfPayments && service.numberOfPayments > 0 ? `${service.numberOfPayments} ` : ''}${service.frequency === EServicesFrequencyType.YEAR ? "Annual" : `${service.frequency?.toLowerCase()}ly`} payments of $${Number(Number(service.amount) + Number(PLATFORM_FEE)).toFixed(2)}`
        } else {
            return `upfront payment of $${Number(Number(service.amount) + Number(PLATFORM_FEE)).toFixed(2)}`
        }
    }

    const onUpdateDescription = () => {
        setLoading(true)
        consultantService.updateSingleService(service._id, { description: description }).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            } else {
                toastService.error(res.data.message)
            }

            setShowInfo(false)
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <>
            <div className={`at-post`}>
                <div className="at-orangeeconhead">
                    <UserPicComponent user={service.user} />
                    <div className="at-orangeeconcontent">
                        {service.user && service.user.businessName && <h3 className="at-color-grey-imp">{service.user.businessName}</h3>}
                    </div>
                    <div className="at-orangeeconcontent">
                        {
                            service?.user?.isDeaf && <span className="at-postcontenticon"><i className="icon-hand"></i></span>
                        }
                        <h3>{service.name}</h3>
                        <div className="at-btniconholder">
                            {/* {feed.user?.template && <a className="at-btnomniicon at-postbtn at-icon-intemplate" title={`${this.getUserTypeTooltips(feed.user)} Website`}><i className="icon-intemplate" onClick={() => this.onClickTemplateIcon((feed.user.template as ITemplateReducerState))}></i></a>} */}
                            {/* Remove from SprintONE */}

                            {/* <a onClick={() => this.openArtCentralSpacePopup(`${routes.artCentral.events.list}/${feed.user._id}`)} title="Events" className="at-btnmenu at-postbtn">
										<i className="icon-event-view"></i>
										{!token && <p>Events</p>}
									</a> */}
                            <a className={`at-btnmenu at-btnomniicon at-postbtn at-postbtn-big at-textcenter ${showUserService === service._id && 'text-orange'}`} title="Message"><i className="icon-services-2" onClick={() => onSetServiceProvider(service)}></i> <p>Service</p></a>

                            <a className="at-btnomniicon at-postbtn" title="Message"><i className="icon-send" onClick={() => openMessageBox(service)}></i></a>

                            <HeartComponent postId={{} as any} userId={service.user._id} />


                            {/* {token && user && <>
									<a id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a>
									{!hideZoomButton && <a id="at-btnomniicon" title="Full Screen" className="at-btnomniicon at-postbtn at-fullmode-btn" onClick={() => {
										ZoomActions.triggerAction({ type: EZoom.FEED, value: this.props.feed })
									}}><i className='icon-switch'></i></a>}
								</>
								} */}

                            {/* {
                                (!hideMoreButton && token && feed.user._id === user?._id) && <a className="at-btnmore at-postbtn cursor-pointer" title="Edit" onClick={() => openAction(feed._id)}><i className="icon-more"></i></a>
                            } */}
                        </div>
                    </div>
                </div>
                <figure className="at-postimg">
                    <ProposalImageView isServicImage={true} item={service} />
                </figure>

                <div className="at-description">
                    <p><strong>Overview:</strong> {getDescription()}</p>
                    {/* <span>{moment()}</span> */}
                </div>
                <div className="at-description-professional">
                    <div className="row row-margin-lr7 align-items-center">
                        <div className="col-8 pad-lr-7">
                            <p className="at-pricing-professional">Pricing: {priceText()}.</p>
                        </div>
                        <div className="col-4 pad-lr-7">
                            <button className="at-btn at-medbtn-h40-mauto" disabled={(user?.susbcribedServices as any)?.includes(service._id)} onClick={() => openPaymentModal()}>BUY</button>
                        </div>
                        <div className="col-12 pad-lr-7">
                            <p className="at-parabottom">
                                {/* By buying this service you agree to give access to the consultant to edit and make changes to your art collections and transaction information. */}
                                (Note: A $10 platform fee will be added to the service price).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {showPayment &&
                <UniversalModal className="at-thankyou-popup" open={showPayment} onClose={closePaymentModal}>
                    <div className="at-addactionholder scrollable text-left ">
                        <div className="at-formthemehead">
                            <h2>Thank you for your Purchase</h2>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Price</p>
                            </div>
                            <div className="col-6 text-right">
                                <p className="at-padding-right-40"> <span><CurrencyFormat amount={(Number(service.amount) + 10)} /></span></p>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-6">
                                <p>Platform Fee</p>
                            </div>
                            <div className="col-6 text-right">
                                <p ><span><CurrencyFormat amount={10} /><i className="icon-info at-icon-infomessage d-inline" onClick={() => { }} ></i></span></p>
                            </div>
                        </div> */}

                        <span className="border-top d-block pb-4"></span>
                        <div className="row">
                            <div className="col-6">
                                <p className="at-padding-right-40">Total</p>
                            </div>
                            <div className="col-6 text-right">
                                <p><strong><CurrencyFormat amount={(Number(service.amount) + 10)} /></strong></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <button type="button" className="cursor-pointer btn at-btn-cancel btn-block" onClick={() => closePaymentModal()}>CANCEL</button>
                            </div>
                            {!showCard && <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 at-mt-sm-10px">
                                <button type="button" className="cursor-pointer at-btn-fullblock" disabled={loading} onClick={() => onConfirmPaymentModal()}  >{loading ? 'Wait...' : 'Pay'}</button>
                            </div>}

                            {showCard &&
                                <div className="col-12 mt-4">
                                    <UpdateCardForm text='Buy' loading={loading} onClick={(source: any) => onBuy(source)} />
                                </div>
                            }
                        </div>
                    </div>
                </UniversalModal>
            }

            {showInfo && <UniversalModal open={showInfo} onClose={() => setShowInfo(false)}>
                <div className="at-addactionholder">
                    <div className="at-formthemehead text-center pb-2">
                        <h2>Service description</h2>

                    </div>
                    {service?.user?._id === user._id ? <>
                        <div className="row description-modal-editor">
                            <div className="col-12">
                                {/* <CustomEditior text={description} onChange={(e: any) => setDescription(e)} /> */}
                                <TmceEditor text={description} onChange={(e: any) => setDescription(e)} />
                            </div>
                        </div>
                        <div className="pt-2">
                            <button className="at-btn at-btnupdate at-btn-fullwidth" disabled={loading} onClick={() => onUpdateDescription()}>{loading ? `Please wait...` : `Update`}</button>
                        </div>
                    </>
                        :
                        <div className=" ">
                            {description && <div className="text-justify" dangerouslySetInnerHTML={{ __html: description }}>
                            </div>}
                        </div>
                    }
                </div>
            </UniversalModal>}

        </>
    )
}