import React, { Component } from 'react'
import {
	RouteComponentProps,
	Switch,
	Route,
	withRouter,
} from 'react-router-dom'
import { connect } from 'react-redux'
import HomePage from '../pages/Home.page'
import ArtCentralRoute from './art-central.route'
import { routes } from './index'
import AccountPage from '../pages/User/Account.page'
import PostDetailPage from '../pages/ArtCentral/PostDetail.page'
import MyCentralRoute from './my-central.route'
import ContactsPage from '../pages/MyCentral/Contacts.page'
import {
	IStoreActionsStates,
	IStoreReducers,
} from '../interfaces/store.interface'
import { HttpService } from '../services/base.service'
import { saveUser, zoomImageAction, logoutAction, setInPlatformWebState } from '../store/actions'
import { userService, toastService, NotificationAction, NotificationCountAction, oneSignalServices, UserAlertAction, UserAlertCountAction } from '../services'
import { AxiosError, AxiosResponse } from 'axios'
import { IUser } from '../interfaces'
import PrismaZoom from 'react-prismazoom'
import StreamingHeaderComponent from '../components/common/StreamingHeader.component'
import { _logger } from '../helper'
import ZoomItemComponent from '../components/common/ZoomItem.component'
import { EZoom, IZoom, ZoomActions } from '../services/zoom.service'
import { Subscription } from 'rxjs'
import EditProfilePage from '../pages/User/EditProfile.page'
import FundsPage from '../pages/User/Funds.page'
import OfferPage from '../pages/User/Offers.page'
import TransactionPage from '../pages/User/Transaction.page'
import DisputesPage from '../pages/User/Disputes.page'
import ReturnArtPage from '../pages/User/ReturnArt.page'
import SalesLogPage from '../pages/User/SalesLog.page'
// import ConnectedWithStripePage from '../pages/User/ConnectedWithStripe.page'
import CommissionWork from '../pages/User/CommissonWork.page'
import MetricsPage from '../pages/User/Metrics.page'
import TermsPage from '../pages/User/Terms.page'
import { ERole } from '../enums'
import { socketNode } from '../services/socket.service'
import { BLOCKED_USER_BY_ADMIN } from '../constants'
import NotificationPage from "../pages/Notifications";
import * as type from '../constants'
import Tunes from '../assets/tunes'
import ManageFunds from '../pages/User/Funds.page'
import UserAlertPage from '../pages/UserAlert.page'
import NotFound404Page from '../pages/NotFound404.page'
import { notificationActions } from '../actions'

import SubscriptionPage from '../pages/LazyloadingComponents/Subscription.lazyloading'
import AgentAssignedAccountPage from '../pages/LazyloadingComponents/AgentAssignedAccountLazyloading'
import ArtistAssignAccountPage from '../pages/LazyloadingComponents/ArtistAssignAccountLazyLoading'
import AgentNotification from '../components/User/AgentNotification'
import InPlatformWebsiteLazyloading from '../pages/LazyloadingComponents/InPlatformWebsiteLazyloading'
import { APP_URL } from '../environment'
import { ImageLoading } from '../components/common/ImageLoading'
import ProfessionalSerives from '../pages/User/ProfessionalServices.page'
import AccessProfessionServices from '../pages/User/AccessProfessionalServices'
import { ServicesForm } from '../components/User/Services/ProfessionalServices/ServiceForm'
import ServicesTransaction from '../pages/User/ServiceTransaction.page'
import Art_Collections from '../pages/ArtCentral/Art_Collections'
import SubmissionProcessForm from '../components/SubmissionProcess/SubmissionProcessForm.component'
import PrintingAndFramingServicesPageLazyloading from '../pages/LazyloadingComponents/PrintingAndFramingServicesPage.lazyloading'
import { PrintingServicesForm } from '../components/User/Services/PrintingServices/PrintingServiceForm'
import PrintingAndFramingTransactionsPage from '../pages/User/PrintingAndFramingTransaction.Page'
import ShowTutorialsPage from '../components/Tutorials/ShowTutorials'
import ScheduledServicesLazyLoading from '../pages/LazyloadingComponents/ScheduledServicesPaymentLazyLoading'
import CuratorialServicePageLazyLoading from '../pages/LazyloadingComponents/CuratorialServiceLazyLoading'
import CreateCuratorialProposal from '../components/User/Services/CuratorialServices/CreateCuratorialProposal.component'
import ParticipantCuratorialProjectPage from '../pages/User/ParticipantCuratorial.page'
import ViewCuratorialProposalComponent from '../components/User/Services/CuratorialServices/proposal/ViewCuratorialProposal.component'
import { IResponse } from '../interfaces/db.interface'
import { CheckCache } from '../components/common/CheckCache.component'
import CreateCollection from '../pages/ArtCentral/collection/CreateCollection'
import AddAuctionComponent from '../components/User/Services/Auction/Form/AddAuction.component'
import AuctionFormComponent from '../components/User/Services/Auction/Form/AuctionForm.component'
import ArtAuctionsPage from '../components/User/Services/Auction/ArtAuctions.component'
import { ArtAuctionShowArts } from '../components/User/Services/Auction/ArtAuctionShowArts.component'
import { LiveAuction } from '../components/User/Services/Auction/LiveAuction/LiveAuction.component'



interface IPageProps extends IStoreActionsStates, RouteComponentProps {
	[key: string]: any
	setInPlatformWebState: Function
}

class PrivateRoutes extends Component<IPageProps> {

	zoomSubscription = new Subscription();
	notificationSubscription = new Subscription();
	userAlertSubscription = new Subscription();
	zoomEventValue: IZoom = {
		type: EZoom.NONE,
		value: null
	}
	state: { zoom: IZoom, count: number, userAlertCount: number, showCacheClear: boolean, showInvalidUser: boolean } = {
		zoom: this.zoomEventValue,
		count: 0,
		userAlertCount: 0,
		showCacheClear: false,
		showInvalidUser: false
	}
	componentWillMount() {
		this.setToken()
		const { pathname } = this.props.location
		const isDetailPage = pathname.includes('post/details')
		// if (!this.props.token && !isDetailPage) {
		// 	console.log("private route")
		// 	this.props.history.push('/')
		// }
		const count = NotificationCountAction.listen.subscribe((status: number) => this.setState({ count: status }))
		const countUser = UserAlertCountAction.listen.subscribe((status: number) => this.setState({ userAlertCount: status }))
		this.notificationSubscription.add(count)
		this.userAlertSubscription.add(countUser)
	}

	// componentWillReceiveProps(props: IPageProps) {
	// 	if (!props.location.pathname.includes(routes.home) && !props.misc.isSpaceActivated) {
	// 		if (this.state.zoom.type !== EZoom.NONE) {
	// 			ZoomActions.triggerAction({ type: EZoom.NONE, value: null })
	// 		}
	// 	}
	// }

	componentDidMount() {
		const sub$ = ZoomActions.listen.subscribe(this.zoomListener);
		this.zoomSubscription.add(sub$)
	}

	shouldComponentUpdate(nextProps: any, nextState: any) {
		if (nextState.count !== this.state.count || nextState.userAlertCount !== this.state.userAlertCount) {
			return false
		}
		return true
	}

	zoomListener = (data: IZoom) => {
		this.zoomEventValue = data;
		this.setState({ zoom: data })
	}

	componentWillUnmount() {
		this.zoomSubscription.unsubscribe();
		this.notificationSubscription.unsubscribe();
		this.userAlertSubscription.unsubscribe()
	}


	socketEventHandlers = (event: any) => {
		let { count, userAlertCount } = this.state
		notificationActions(event, count, userAlertCount, this.props.logoutAction, this.props.saveUser, this.manaulLogoutAndCacheClear)
	}

	openCasheClearPopup = () => {
		this.setState({ showCacheClear: true })
	}

	closeCasheClearPopup = () => {
		this.setState({ showCacheClear: false })
	}

	manaulLogoutAndCacheClear = () => {

		userService.userUpdateManualLogout().then((res: AxiosResponse<IResponse<any>>) => {
			this.props.logoutAction()
			this.props.saveUser({})
			this.closeCasheClearPopup()
		}).catch((err: AxiosError) => {
			console.log("error logout:-=-=", err)
		})
	}
	onSubmitCasheClearPopup = () => {
		this.manaulLogoutAndCacheClear()

	}

	openInavalidPopup = () => {
		this.setState({ showInvalidUser: true })
	}

	closeInavalidPopup = () => {
		this.setState({ showInvalidUser: false })
	}

	onSubmitInavalidPopup = () => {

		this.props.logoutAction()
		this.props.saveUser({})
		this.closeInavalidPopup()
	}

	getSocketEvent = (user: IUser) => {

		socketNode.removeEventListener(user._id)
		socketNode.on(user._id, this.socketEventHandlers)
	}
	setToken = async (): Promise<void> => {
		await HttpService.setToken(this.props.token)
		userService.account().then(async (response: AxiosResponse<IUser>) => {
			if (response.data.status && response.data.status === 404) {
				this.onSubmitInavalidPopup()
				return
			}
			if (response.data.status && response.data.status === 503) {
				this.onSubmitCasheClearPopup()
				return
			}
			this.props.saveUser(response.data)
			this.getSocketEvent(response.data);
			// (window as any).OneSignal.push(function () {
			// 	/* These examples are all valid */
			// 	(window as any).OneSignal.sendTag(`user`, `${response.data._id}`);
			// });
		}).catch((err) => {
			console.log("eror:-=-=-=", err)
		})
	}

	heightOfZoom = () => {
		const mainDoc: any = document.getElementById('at-main')
		const { height, width } = mainDoc.getBoundingClientRect()
		return { height: height || '740px', width: width || '375px' }
	}

	render() {
		const { pathname } = this.props.location
		const { misc, spaceEnabled, isAdmin } = this.props
		const isDetailPage = pathname.includes('post/details')
		if (!this.props.token && !isDetailPage) {
			console.log("private route")
			this.props.history.push('/')
		}
		// if(isAdmin) {
		// 	this.props.history.push(routes.adminRoutes.index)
		// }
		const { zoom: { type } } = this.state;
		const zoomClass = (type === EZoom.FEED || type === EZoom.COLLECTION_ITEM || type === EZoom.TEMPLATES_IFRAME || type === EZoom.ART_COLLECTION) ? 'zoom-enabled' : ''
		return (
			// <div
			// 	id='at-wrapper'
			// 	className={`at-wrapper ${spaceEnabled ? 'at-wrapper-withvideo' : ''} ${this.props.spaces &&
			// 		this.props.spaces.openActiveMembersSideBar &&
			// 		'at-openthemeusersidbar'
			// 		} ${zoomClass}  ${(type === EZoom.TEMPLATES_IFRAME || type === EZoom.ART_COLLECTION) ? 'at-wrapperfullpanel' : ''} `}
			// >
			// 	{zoomClass && <ZoomItemComponent />}
			// 	<div className="at-themeholder">
			<div className="at-height100">
				<div className="at-height100">
					{/* <StreamingHeaderComponent {...this.props} /> */}

					<Switch>
						<Route path={routes.home} component={ArtCentralRoute} />
						<Route path={routes.account.index} exact component={AccountPage} />
						<Route path={routes.account.editProfile} exact component={EditProfilePage} />
						<Route path={routes.account.funds} exact component={FundsPage} />
						<Route path={routes.account.offers} exact component={OfferPage} />
						<Route path={routes.account.transactions} exact component={TransactionPage} />
						<Route path={routes.account.salelogs} exact component={SalesLogPage} />
						<Route path={routes.account.terms} exact component={TermsPage} />
						<Route path={routes.account.funds} exact component={ManageFunds} />
						<Route path={routes.account.metrics} exact component={MetricsPage} />
						<Route path={routes.account.diputes} exact component={DisputesPage} />
						<Route path={routes.account.returnArt} exact component={ReturnArtPage} />
						<Route path={routes.account.commissionWork} exact component={CommissionWork} />
						<Route path={routes.account.agentAssignAccount} exact component={AgentAssignedAccountPage} />
						<Route path={routes.account.artistAssignAccount} exact component={ArtistAssignAccountPage} />
						<Route path={routes.account.subscription} exact component={SubscriptionPage} />
						<Route path={routes.account.inPlatformWebsite} exact component={InPlatformWebsiteLazyloading} />
						{/* Services Routes */}
						<Route path={routes.account.services.professional.index} exact component={ProfessionalSerives} />
						<Route path={routes.account.services.professional.scheduledServices} exact component={ScheduledServicesLazyLoading} />
						<Route path={routes.account.services.professional.access} exact component={AccessProfessionServices} />
						<Route path={routes.account.services.professional.transactions} exact component={ServicesTransaction} />
						<Route path={routes.account.services.printingAndFraming.index} exact component={PrintingAndFramingServicesPageLazyloading} />
						<Route path={`${routes.account.services.professional.form}/:id`} exact component={ServicesForm} />
						<Route path={`${routes.account.services.printingAndFraming.form}/:id`} exact component={PrintingServicesForm} />
						<Route path={`${routes.submissionProcess.form}`} exact component={(props: any) => (<SubmissionProcessForm timestamp={new Date().toString()} {...props} />)} />
						<Route path={`${routes.submissionProcess.serviceForm}`} exact component={(props: any) => (<SubmissionProcessForm timestamp={new Date().toString()} {...props} />)} />
						<Route path={routes.account.services.printingAndFraming.transactions} exact component={PrintingAndFramingTransactionsPage} />

						<Route path={routes.account.services.curatorialServices.index} exact component={(props: any) => <CuratorialServicePageLazyLoading timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.curatorialServices.form}/:id`} exact component={(props: any) => <CuratorialServicePageLazyLoading timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.curatorialServices.createProposal}/:invitation`} exact component={(props: any) => <CreateCuratorialProposal timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.curatorialServices.createProposal}/:id/:invitation`} exact component={(props: any) => <CreateCuratorialProposal timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.curatorialServices.seeProposal}/:id`} exact component={(props: any) => <ViewCuratorialProposalComponent timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.curatorialServices.participantCuratorialProject}`} exact component={(props: any) => <ParticipantCuratorialProjectPage timestamp={new Date().toString()} {...props} />} />


						<Route path={`${routes.account.services.auction.index}`} exact component={(props: any) => <ArtAuctionsPage timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.auction.add}`} exact component={(props: any) => <AddAuctionComponent timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.auction.form}`} exact component={(props: any) => <AuctionFormComponent timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.auction.form}/:id`} exact component={(props: any) => <AuctionFormComponent timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.auction.showArts}/:id`} exact component={(props: any) => <ArtAuctionShowArts timestamp={new Date().toString()} {...props} />} />
						<Route path={`${routes.account.services.auction.joinAuction}/:id`} exact component={(props: any) => <LiveAuction timestamp={new Date().toString()} {...props} />} />

						<Route path={routes.appTutorials} component={ShowTutorialsPage} />
						{/* Services Routes */}
						<Route path={routes.account.agentCreateArt} exact component={(props: any) => (
							<CreateCollection timestamp={new Date().toString()} {...props} />)
						} />

						<Route path={`${routes.account.agentCreateArtEdit}/:id/:item?`} exact component={CreateCollection} />

						<Route path={`${routes.account.commissionWork}/:id`} exact component={CommissionWork} />
						<Route path={routes.notifications} exact component={NotificationPage} />
						<Route path={routes.userAlert} exact component={UserAlertPage} />
						<Route
							path={`${routes.artCentral.post.details}/:postId`}
							component={PostDetailPage}
						/>
						<Route
							path={routes.myCentral.contacts}
							exact
							component={ContactsPage}
						/>

						<Route path={routes.artCentral.index} component={ArtCentralRoute} />

						<Route path={routes.myCentral.index} component={MyCentralRoute} />
						<Route path="*" component={NotFound404Page} />
					</Switch>
				</div>


				{misc.zoomImage.original && (
					<div
						className='zoomed-image'
						style={{
							height: this.heightOfZoom().height,
							width: this.heightOfZoom().width,
						}}
					>
						<p
							className='closeZoom cursor-pointer'
							onClick={() => this.props.zoomImageAction({ original: '', thumbnail: '' })}
						>
							<i className='icon-cancel'></i>
						</p>
						<PrismaZoom>
							<ImageLoading src={misc.zoomImage.original} thumbnail={misc.zoomImage.thumbnail} className='zoom-post-image' />
							{/* <img src={misc.zoomImage.original || ''} className='zoom-post-image' /> */}
						</PrismaZoom>
					</div>
				)}
				{this.state.showCacheClear && <CheckCache onSubmitCasheClearPopup={this.onSubmitCasheClearPopup} />}
				{this.state.showInvalidUser && <CheckCache onSubmitCasheClearPopup={this.onSubmitInavalidPopup} />}
			</div>
		)
	}
}
const mapState = (state: IStoreReducers) => ({
	token: state.auth.access_token,
	spaces: state.spaces,
	misc: state.misc,
	spaceEnabled: state.user.activeSpace && state.user.activeSpace._id,
	isAdmin: state.auth.isAdmin,
})

export default withRouter(
	connect(mapState, { saveUser, zoomImageAction, logoutAction, setInPlatformWebState })(PrivateRoutes)
)
