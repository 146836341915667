import { AxiosResponse } from 'axios'
import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import LoadingComponent from '../components/common/Loading.component'
import { UserPicComponent } from '../components/User/index.component'
import { INotification, ISpace, IStoreReducers, IUser } from '../interfaces'
import { notificationService, spaceService, toastService, UserAlertAction, UserAlertCountAction, userService } from '../services'
import * as type from '../constants'
import { UserAlertMesageInfo, UserMessageWithStudio, UserTemplateAlert } from '../components/User/UserAlerts'
import { activateSpaceAction, changeActiveSpace } from '../store/actions'
import { socketNode } from '../services/socket.service'
import { ADD_NEW_MEMBER, USER_ALERT_SPACE_ENTER } from '../constants'
import { routes } from '../router'
import { userSelector } from '../store/selectors'
import { images } from '../assets/image'
import { IResponse } from '../interfaces/db.interface'

interface IProps {
    changeActiveSpace?: any
    activateSpaceAction?: any
    routeUser?: IUser
    hideBackButton?: boolean
}

const UserAlertPage = ({ routeUser, hideBackButton }: IProps) => {
    const storeUser = useSelector(userSelector)
    const user = routeUser && routeUser._id ? routeUser : storeUser
    const history = useHistory()
    const [alerts, setAlerts] = useState<Array<INotification>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch();
    // const user = useSelector(userSelector)
    const subscribe = new Subscription()

    const onClikNotification = (n: INotification) => {

    }
    const handleReadCounts = () => {
        let count = alerts?.filter((a: INotification) => !a.read)
        return count.length
    }

    useEffect(() => {
        fetchAlerts()
        const sub = UserAlertAction.listen.subscribe((status: Object) => {
            fetchAlerts()
            // let newData: any = [status].concat(alerts)
            // console.log("Alerts after:-=-=-=-=-=", newData)
            // setAlerts([...newData])
        })
        subscribe.add(sub)

        return () => {
            subscribe.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (alerts && alerts.length > 0) {
            UserAlertCountAction.triggerAction(0)
        }
    }, [alerts])

    const fetchAlerts = () => {
        setLoading(true)
        notificationService.getUserAlerts(user._id).then((res: AxiosResponse<IResponse<any>>) => {
            let data = [...res.data.data]
            setAlerts(data.reverse())
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const handleAdminNotification = (n: string = '') => {
        const canvas = document.createElement('canvas')
        canvas.style.display = 'none'
        canvas.width = 200
        canvas.height = 200
        document.body.appendChild(canvas)
        const context = canvas.getContext('2d')
        if (context) {
            context.fillStyle = '#92A8B5'
            context.fillRect(0, 0, canvas.width, canvas.height)
            context.font = '50px Helvetica'
            context.fillStyle = '#FBFAF9'
        }
        let name = n ? n : 'Admin'

        context?.fillText(
            name,
            canvas.width / 6,
            canvas.height / 1.6
        )

        const data = canvas.toDataURL()
        document.body.removeChild(canvas)
        return data
    }

    const joinUserStudio = async (id: any, item: INotification) => {
        const space: ISpace = (await spaceService.userArtCentralSpace(id)).data;
        console.log(space)
        changeActiveSpace && dispatch(changeActiveSpace(space))
        socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
        dispatch(activateSpaceAction(true))
        sendUserAlert(item, space._id)
        history.push(`${routes.artCentral.collection.list}/${id}`)
    }

    const sendUserAlert = (item: INotification, spaceId: any) => {
        let data = {
            message: `${user?.firstName} ${user?.lastName} has just entered your space.`,
            to: item.from._id,
            from: item.to._id,
            type: USER_ALERT_SPACE_ENTER,
            spaceID: spaceId
        }
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                // toastService.success("Successfully send the message.")
            }

        }).catch((err: any) => {
            console.log(err)
        })
    }

    const getLayout = (a: INotification) => {
        switch (a.type) {
            case type.USER_ALERT_SPACE_ENTER:
                return <UserAlertMesageInfo alert={a} user={user} />
            case type.USER_ALERT_SPACE_MESSAGE:
                return <UserAlertMesageInfo alert={a} user={user} />
            case type.USER_ALERT_SPACE_MESSAGE_REPLY:
                return <UserMessageWithStudio alert={a} user={user} joinUserStudio={joinUserStudio} />
            case type.USER_ALERT_TEMPLATE_CONTACT:
                return null
            default:
                break;
        }
    }
    return (
        <div className="at-createcollection at-contentarea-two">
            <div className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>Messages {alerts && alerts.length > 0 && <span>{`(${handleReadCounts()})`}</span>}  </h2>
                </div>
                <div className="at-notifyholder">
                    {alerts && alerts.length > 0 ? (
                        <>
                            {
                                alerts.map((n: INotification) => (
                                    <div className="at-notifybox" key={n.id} onClick={() => onClikNotification(n)}>
                                        {!n.fromAdmin ? (n.from && n.from.profile ? <UserPicComponent className="at-notifyfigure" user={n.from} /> : null) :
                                            <figure className="at-notifyfigure">
                                                <img src={handleAdminNotification(n.type === type.USER_ALERT_TEMPLATE_CONTACT ? n.firstName : '')} alt="" />
                                            </figure>}
                                        <div className="at-notifytext">
                                            <p>{n.type !== type.USER_ALERT_SPACE_MESSAGE_REPLY ?
                                                (n.type === type.USER_ALERT_TEMPLATE_CONTACT ? `${n.message} ${n.fromPhoneNumber ? `sent from ${formatPhoneNumberIntl(n.fromPhoneNumber)}` : ''} ` : n.message)
                                                :
                                                `from: ${n.from.firstName} ${n.from.lastName}`}</p>
                                        </div>
                                        <div className="at-transaction-main pt-0">
                                            {getLayout(n)}
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    ) :
                        <>{loading && <LoadingComponent />}
                            {!loading && (!alerts || alerts.length === 0) &&
                                <div className="at-emptybox">
                                    <figure className="at-figure">
                                        <img src={images.emptyMessage} className="img-fluid" alt="" />
                                    </figure>
                                    <h4>No Messages</h4>
                                </div>
                            }</>
                    }

                </div>
                {/* at-notifyholder ends */}

            </div>
        </div>
    )
}


export default UserAlertPage