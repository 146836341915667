import React, { Component, Fragment, FormEvent } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom'
import FeedComponent from "../../components/ArtCentral/feed/Feed.component";
import { IFeed, IPost, IStoreReducers, IPaginate, IPaginateResponse, IPostComment } from "../../interfaces";
import { routes } from "../../router";
import { postService } from '../../services/post.service';
import { AxiosResponse, AxiosError } from 'axios';
import { PullToRefresh, PullDownContent, ReleaseContent, RefreshContent } from "react-js-pull-to-refresh";
import LoadingComponent from '../../components/common/Loading.component';
import { connect } from 'react-redux';
import { saveFeeds } from '../../store/actions';
import CommentsComponent from '../../components/common/Comments.component';
import { ConfirmAlertComponent } from '../../components/common/ConfirmAlert.component';
import { toastService } from '../../services';
import { locale, lang } from '../../locale';
import debounce from 'lodash/debounce';
import PostShimmeringComponent from '../../components/common/PostShimmering.component';
import { socketNode } from '../../services/socket.service';
import { ADD_NEW_COMMENT } from '../../constants';
import { ZoomActions, IZoom, EZoom } from '../../services/zoom.service';
import { Subscription } from 'rxjs';
import { _logger } from '../../helper';


interface IPageProps extends RouteComponentProps {
    saveFeeds: any
    feeds: Array<IPost>
    token: any
}

interface IPageState extends IPaginate {
    feeds: Array<IPost>
    loading: boolean
    showComments: boolean
    selectedId: string
    openAction: boolean
    openDeleting: boolean
    actionId: string
    deleting: boolean
    hasMore: boolean
    error: any
    showSearch: boolean
    filtered: boolean
    isZoomed: boolean

}

class NewsFeedsPage extends Component<IPageProps> {
    state: IPageState = {
        feeds: [],
        loading: false,
        showComments: false,
        selectedId: '',
        openAction: false,
        actionId: '',
        openDeleting: false,
        deleting: false,
        hasMore: false,
        error: null,
        showSearch: false,

        page: 1,
        limit: 10,
        query: '',
        count: 0,
        next: false,
        filtered: false,
        isZoomed: false
    }

    zoomSubscription = new Subscription();

    searchInput: any;
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        if (this.searchQuery) {
            this.setState({ showSearch: true, query: this.searchQuery }, () => this.fetch())
        }
        else {
            this.fetch();
        }
        this.scrollPosition()
        const sub$ = ZoomActions.listen.subscribe(this.zoomListener);
        this.zoomSubscription.add(sub$)
    }

    componentWillUnmount() {
        this.zoomSubscription.unsubscribe();
    }


    zoomListener = (data: IZoom) => {
        this.setState({ isZoomed: data.type !== EZoom.NONE })
    }

    scrollPosition = (): void => {
        const item = document.querySelector(
            ".restore-" + (typeof (this.props.location.state) === 'object' ? '' : this.props.location.state)
        );
        if (item) {
            item.scrollIntoView();
        }
    }

    toggleLoading = (loading: boolean): void => {
        this.setState({ loading })
    }

    get searchQuery() {
        return new URLSearchParams(this.props.location.search).get("name")
    }

    // fetchWithoutLogin = async (page = 1): Promise<any> => {
    //     const { limit, query, feeds, loading, filtered } = this.state;

    //     if (loading) return;

    //     this.toggleLoading(true);
    //     this.setState({ page })
    //     return postService.listWithoutLogin({ limit, page, query, filtered }).then((response: AxiosResponse<IPaginateResponse<IPost>>) => {
    //         const { count, documents, next } = response.data;
    //         const newFeeds = page === 1 ? documents : [...this.props.feeds, ...documents];
    //         // const newFeeds = page === 1 ? documents : [...feeds, ...documents];
    //         this.close();
    //         // this.setState({ next, feeds: newFeeds, count })
    //         this.setState({ next, count })
    //         this.props.saveFeeds(newFeeds.filter(item => item.user))
    //         this.toggleLoading(false)
    //     }, (error: AxiosError) => {
    //         _logger('Errors', error.response);
    //         this.toggleLoading(false)
    //     })
    // }

    fetch = async (page = 1): Promise<any> => {
        const { limit, query, feeds, loading, filtered } = this.state;
        const { token } = this.props

        if (loading) return;

        this.toggleLoading(true);
        this.setState({ page })
        if (!token) {
            return postService.listWithoutLogin({ limit, page, query, filtered }).then((response: AxiosResponse<IPaginateResponse<IPost>>) => {
                const { count, documents, next } = response.data;
                const newFeeds = page === 1 ? documents : [...this.props.feeds, ...documents];
                // const newFeeds = page === 1 ? documents : [...feeds, ...documents];
                this.close();
                // this.setState({ next, feeds: newFeeds, count })
                this.setState({ next, count })
                this.props.saveFeeds(newFeeds.filter(item => item.user))
                this.toggleLoading(false)
            }, (error: AxiosError) => {
                _logger('Errors', error.response);
                this.toggleLoading(false)
            })
        }
        return postService.list({ limit, page, query, filtered }).then((response: AxiosResponse<IPaginateResponse<IPost>>) => {
            const { count, documents, next } = response.data;
            const newFeeds = page === 1 ? documents : [...this.props.feeds, ...documents];
            // const newFeeds = page === 1 ? documents : [...feeds, ...documents];
            this.close();
            // this.setState({ next, feeds: newFeeds, count })
            this.setState({ next, count })
            this.props.saveFeeds(newFeeds.filter(item => item.user))
            this.toggleLoading(false)
        }, (error: AxiosError) => {
            _logger('Errors', error.response);
            this.toggleLoading(false)
        })
    }
    searchDebounce = debounce(this.fetch, 500);

    search = (e: any): any => {
        this.setState({ query: e.target.value });
        this.searchDebounce();
    }

    openCommentBox = (selectedId: string): void => {
        let atMain: any = document.getElementById('at-main');
        atMain.classList.add('at-noscroll');
        this.setState({ openDeleting: false, openAction: false, showComments: false, selectedId: null })
        this.setState({ showComments: true, selectedId })
    }

    openActions = (id: any) => {
        this.setState({ openDeleting: false, showComments: false })
        this.setState({ openAction: true, actionId: id })
    }

    openDeleteBox = () => {
        this.setState({ openAction: false, showComments: false })
        this.setState({ openDeleting: true })
    }

    close = () => {
        let atMain: any = document.getElementById('at-main');
        atMain?.classList.remove('at-noscroll');
        this.setState({ openAction: false, actionId: null, selectedId: null, openDeleting: false, showComments: false })
    }

    deleteItems = () => {
        this.setState({ deleting: true })
        postService.deletePost(this.state.actionId).then((response) => {
            this.setState({ deleting: false, openDeleting: false, openAction: false })
            toastService.success(response.data.message)
            this.fetch();
        }, (error) => {
            this.setState({ deleting: false, openDeleting: false, openAction: false })
            // toastService.error(lang.common.httpError)
        })
    }


    backClick = () => {
        let { token } = this.props
        if (this.state.showSearch) {
            this.closeSearch()
            return
        }
        if (token) {
            this.props.history.push(routes.home)
        }
        // this.state.showSearch ? this.setState({ showSearch: false, query:'' }) : this.props.history.push(routes.home)
    }

    openSearch = () => {

        this.setState({ showSearch: true });

        setTimeout(() => {
            this.searchInput.focus();
        }, 100);

    }
    loadMoreInstance: any;
    scroll = (event: any) => {

        // const { page, next } = this.state;
        // if (next) {
        //     let offsetTop  = this.loadMoreInstance.getBoundingClientRect().top;
        //     if (offsetTop < 1000) {
        //         this.fetch(page+1);
        //     }
        // }

    }

    toggleFilter = () => {
        this.setState({ filtered: !this.state.filtered })
        setTimeout(this.fetch, 200);
    }

    closeSearch = () => {
        this.setState({ showSearch: false, query: '' })
        this.searchDebounce();

    }

    render() {
        let { showSearch, query, filtered, loading, page, showComments, selectedId, openAction, openDeleting, deleting, actionId, error, next, count, isZoomed } = this.state;
        const { feeds: allFeeds, token } = this.props;
        let feeds = allFeeds.filter((c: IPost) => c.assets.length > 0);
        // const { feeds } = this.props;
        const rowHeight = 559;
        const listHeight = rowHeight * (feeds.length - 1);
        const rowWidth = 375;
        let checkRoute = this.props.location.pathname === routes.artCentral.newsFeeds || this.props.location.pathname === routes.newsFeed
        // let isHomePage = this.props.location.pathname.includes(routes.home)

        return (
            <div className="at-heightinherit">
                <PullToRefresh
                    pullDownContent={<PullDownContent />}
                    releaseContent={<ReleaseContent />}
                    refreshContent={<LoadingComponent className="mt-10" />}
                    pullDownThreshold={200}
                    onRefresh={() => this.fetch(1)}
                    triggerHeight={50}
                    backgroundColor='white'
                    startInvisible={true}
                >
                    <div className="at-orangeeconpost at-contentarea at-themescrollbar scrollable scrollbar-hidden text-center" onMouseDown={this.close} >
                        <div id="feed-scroll" className={`at-posts at-themescrollbar scrollable scrollbar-hidden ${isZoomed ? 'disableThumbnailScroll' : ''}`} onScroll={this.scroll}>

                            <div className="at-themehead">
                                {showSearch && <a className="at-left-arrowicon" onClick={this.backClick}><i className="icon-left-arrow"></i></a>}
                                {!showSearch && <a className="at-filtericon cursor-pointer" onClick={this.openSearch}><i title="Search" className="icon-search"></i></a>}

                                {token && <a className={`at-filtericon cursor-pointer mr-2 ${filtered && 'text-orange'}`} onClick={this.toggleFilter}><i title="Filter Preferred" className="icon-filter"></i></a>}
                                <h2>
                                    <div className={`from-group At-MarginTopSearchMinus dn ${showSearch && 'display'}`}>
                                        <div className="input-group">
                                            {loading && <div className="input-group-prepend">
                                                <span className="input-group-text search-field" id="basic-addon1"><i className="fa fa-spinner"></i></span>
                                            </div>}
                                            <input type="search" ref={input => this.searchInput = input} value={query} className="form-control" placeholder="Search" onChange={this.search} />
                                        </div>
                                    </div>
                                    {!showSearch && checkRoute ? 'News Feed' : ''}
                                </h2>
                            </div>

                            {
                                (loading && feeds.length < 1) && [1, 1, 1].map((v, i) => <PostShimmeringComponent key={i} />)
                            }
                            {
                                feeds.map((feed: IPost) => (
                                    <Fragment key={`post_${feed._id}`} >
                                        <FeedComponent {...this.props} openAction={this.openActions} feed={feed} openCommentBox={this.openCommentBox} />
                                    </Fragment>
                                ))
                            }
                            {/* <List
                        width={rowWidth}
                        height={listHeight}
                        rowHeight={rowHeight}
                        rowRenderer={({ index, key, style }: any) => {
                            const feed = feeds[index];
                            return <FeedComponent key={`post_${feed._id}`} {...this.props} openAction={this.openActions} feed={feed} openCommentBox={this.openCommentBox}/>
                        }}
                        rowCount={feeds.length} /> */}
                            <br />
                            {
                                loading && <LoadingComponent />
                            }
                            {error &&
                                <div style={{ color: '#900' }}>
                                    {error}
                                </div>
                            }
                            {
                                (next && !loading) && <button id="loadMore" ref={(el) => this.loadMoreInstance = el} className="at-btn at-loadmore" onClick={() => this.fetch(page + 1)}>{lang.common.loadMore}</button>
                            }
                            {(!loading && !next && count > 0) &&
                                <div className="row text-center">
                                    <p className="reachedText">
                                        {lang.feeds.reachedEnd}
                                    </p>
                                    <br />
                                </div>
                            }
                            {
                                (!loading && count < 1) && <div className="text-center  mt-5 mb-5">
                                    <h4>{!query ? lang.feeds.notFound : lang.feeds.changeSearch}</h4>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        (showComments && selectedId) && (
                            <div id="at-themesignuppopup" className={`at-themepopupholder at-showthemesignuppopup`}>
                                <div className="at-themepopupbox">
                                    <a className="at-close at-btnclosesignup cursor-pinter" id="at-close" onClick={this.close}><i className="icon-cancel"></i></a>
                                    <CommentsComponent id={selectedId} />
                                </div>
                            </div>
                        )


                    }

                    {openAction && <div id="at-themeaddactionpopup" className={`at-themepopupholder ${openAction && 'at-showthemesignuppopup'}`}>
                        <div className="at-themepopupbox">
                            <a className="at-close cursor-pointer" onClick={this.close} id="at-close"><i className="icon-cancel"></i></a>
                            <div className="at-addactionholder">
                                <div className="at-formthemehead pr-0">
                                    <h2 className="text-center">Action</h2>
                                </div>
                                <ul>
                                    <li className="text-center">
                                        <Link to={{ pathname: `${routes.artCentral.post.edit}/${actionId}`, state: actionId }}>Edit</Link>
                                    </li>
                                    <li className="at-active text-center">
                                        <a className="cursor-pointer" onClick={this.openDeleteBox}>Delete</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>}

                    {openDeleting && <ConfirmAlertComponent
                        open={openDeleting}
                        onCancel={this.close}
                        onConfirm={this.deleteItems}
                        confirmTitle={deleting ? 'Deleing...' : 'Delete'}
                        message="Are you sure you want to delete this post?"
                    />}
                </PullToRefresh>
            </div>

        );
    }
}

const mapState = (state: IStoreReducers) => ({
    feeds: state.feed,
    token: state.auth.access_token
})
export default connect(mapState, { saveFeeds })(NewsFeedsPage);