import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { INotification, IUser } from '../../../interfaces'
import { contactService, toastService, userService } from '../../../services'
import { USER_ALERT_SPACE_MESSAGE_REPLY } from '../../../constants'
import { AxiosResponse } from 'axios'
import { MessageModal } from '../../common/Modals/MessagePopup'
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component'
import { refreshUser } from '../../../store/actions'
import { getUserType } from '../../../shared'

interface IProps {
    alert: INotification,
    user?: IUser
}

export const UserAlertMesageInfo = ({ alert, user }: IProps) => {
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [showContactIcon, setShowContactIcon] = useState<boolean>(true)
    const [showConfirmBox, setConfirmBox] = useState<boolean>(false)
    const [showLevelBox, setShowLevelBox] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (alert && user?.contacts && user.contacts.length > 0) {
            let check = user.contacts.find((c: string) => alert.from?._id === c)
            if (check && check?.length > 0) {
                setShowContactIcon(false)
            }
        }
    }, [])

    const openMessageBox = () => {
        setShowMessageBox(true)
    }

    const closeMessageBox = () => {
        setShowMessageBox(false)
    }

    const submitMessageBox = (message: string) => {
        let data = {
            message: message,
            to: alert.from._id,
            from: alert.to._id,
            type: USER_ALERT_SPACE_MESSAGE_REPLY
        }
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
            if (res.data.status === 'Success') {
                toastService.success("Message sent successfully")
            }
            closeMessageBox()
        }).catch((err: any) => {
            console.log(err)
            closeMessageBox()
        })
    }

    const openConfirmBox = () => {
        setConfirmBox(true)
    }

    const closeConfirmBox = () => {
        setConfirmBox(false)
    }

    const submitConfirmBox = () => {
        let data = {
            firstName: alert.from.firstName,
            lastName: alert.from.lastName,
            code: '1',
            number: '',
            _id: alert.from._id,
            phoneNumber: alert.from.phoneNumber,
        }
        setLoading(true)
        contactService.add({ ...data }).then((res: AxiosResponse<any>) => {
            toastService.success("User added to contacts!")
            dispatch(refreshUser())
            closeConfirmBox()
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            closeConfirmBox()
            setLoading(false)
        })
    }

    const userLevel = () => {
        if (alert.purchases >= 10) {
            return 'greenlight'
        }
        if (alert.purchases >= 5) {
            return 'blue2'
        }
        if (alert.purchases >= 3) {
            return 'yellow'
        }
        if (alert.purchases >= 1) {
            return 'lightgrey2'
        }
    }

    return (
        <div className="at-star-boxouter">
            <ul className="at-star-topbtns d-flex justify-content-end">
                <li><button className="at-btnicon" disabled={!showContactIcon} onClick={() => openConfirmBox()}><i className="icon-add-user at-color-grey mr-2"></i></button></li>
                <li><i className="icon-send at-color-grey" onClick={() => openMessageBox()} ></i></li>
            </ul>
            <div className="at-star-box">
                <div className="at-transact-line">
                    <p>{moment(alert.createdAt).format("L")}</p>
                    <p className="pl-5">{moment(alert.createdAt).format("hh:mm:ss a")}</p>
                </div>
                {alert.from && <h5><strong>{alert.from.firstName} {alert.from.lastName} </strong>{`(${getUserType(alert.from)})`}</h5>}
                <h6>Star Rating: {alert.purchases > 0 ? <i className={`icon-star-grey fs-20 mr-1 at-color-${userLevel()}`}></i> : <i className={`icon-star-outline fs-20 mr-1 at-color-${userLevel()}`}></i>}
                    <i className="icon-info fs-20 at-color-nightrider" onClick={() => setShowLevelBox(!showLevelBox)}></i></h6>
                <div className="at-level-box">
                    <h5 className="at-text-underline">OMNI LEVEL</h5>
                    <p>Number of Purchases: <span>{alert.purchases && alert.purchases > 0 ? alert.purchases : 0}</span></p>
                    <p>Number of Offers: <span>{alert.offers && alert.offers > 0 ? alert.offers : 0}</span></p>
                    <p>Number of Disputes Filed: <span>{alert.disputes && alert.disputes > 0 ? alert.disputes : 0}</span></p>
                </div>
                <div className="at-level-box">
                    <h5 className="at-text-underline">SPACE LEVEL</h5>
                    <p>Number of Space Visits (1 week): <span>{alert.lastWeek && alert.lastWeek > 0 ? alert.lastWeek : 0}</span></p>
                    <p>Number of Space Visits (1 month): <span>{alert.lastMonth && alert.lastMonth > 0 ? alert.lastMonth : 0}</span></p>
                    <p>Number of Space Visits (6 months): <span>{alert.lastSixMonths && alert.lastSixMonths > 0 ? alert.lastSixMonths : 0}</span></p>
                </div>
            </div>
            {showLevelBox && <div className="at-stardefinition-box" style={{ display: (showLevelBox ? 'block' : 'none') }}>
                <h6>Star Rating Definition</h6>
                <li className="d-flex"><i className="icon-star-grey at-color-greenlight"></i><p>User has purchased 10 or more works of ART.</p></li>
                <li className="d-flex"><i className="icon-star-grey at-color-blue2"></i><p>User has purchased 5 or more works of ART.</p></li>
                <li className="d-flex"><i className="icon-star-grey at-color-yellow"></i><p>User has purchased 3 or more works of ART.</p></li>
                <li className="d-flex"><i className="icon-star-grey at-color-lightgrey2"></i><p>User has purchased 1 or more works of ART.</p></li>
                <li className="d-flex"><i className="icon-star-outline "></i><p>User has purchased 0 works of ART.</p></li>

            </div>}
            {showMessageBox && <MessageModal open={showMessageBox} onClose={closeMessageBox} onSubmit={submitMessageBox} message='' title='Send Message' />}
            {showConfirmBox && <ConfirmAlertComponent
                open={showConfirmBox}
                onCancel={closeConfirmBox}
                onClose={closeConfirmBox}
                onConfirm={submitConfirmBox}
                title='Confirm!'
                message="Are you sure you want to add this user to your Contact list."
                loading={loading}
                closeable={true}
            />}
        </div>
    )
}