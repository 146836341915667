import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EArtistPrintType } from '../../../../../enums'
import { ICuratorialProposalArtsDocument, IFormates, IProposalArtFormats } from '../../../../../interfaces'
import { routes } from '../../../../../router'
import { collectionImage } from '../../../../../shared'
import { ShowArtServiceComponent } from '../../../../ArtCentral/ArtServices/ShowArtService.component'
import { CurrencyFormat } from '../../../../common/CurrencyFormat'
import { UniversalModal } from '../../../../common/Modals/Universal.modal'


interface IProps {
    proposalArt: ICuratorialProposalArtsDocument
    onSelectArts: Function
}

export const ViewCuratorialProposalArtComponent = ({ proposalArt, onSelectArts }: IProps) => {
    const [selectedFormat, setSelectedFormat] = useState<IFormates>()
    const [selectedFormatIndex, setSelectedFormatIndex] = useState<number>(NaN)
    const [showService, setShowService] = useState<boolean>(false)

    const onOpenShowService = (format: IProposalArtFormats, fIndex: number) => {
        if (!format.selectedService) return
        setSelectedFormat(format)
        setSelectedFormatIndex(fIndex)
        setShowService(true)
        // this.setState({ formatSelected: format, showService: true })
    }

    const onCloseShowService = () => {
        setSelectedFormat(undefined)
        setSelectedFormatIndex(NaN)
        setShowService(false)
        // this.setState({ formatSelected: undefined, showService: false })
    }
    const history = useHistory()
    return (
        <>
            <div className="row at-row-mlr-3 at-curatorial-format">
                <div className="col-12">
                    <h6 className="at-fontmedium"><span className="at-color-grey">Proposed Art and Art Formats: </span></h6>
                    <div className="at-figurelandscape">
                        <figure className="at-figure-h130">
                            {proposalArt && proposalArt.art && proposalArt.art.url && <img src={collectionImage({} as any, `high_${proposalArt.art.url}`, true)} className="img-fluid" alt="" />}
                        </figure>
                    </div>
                </div>
                <div className="col-12">
                    {proposalArt && proposalArt.art && proposalArt.art.name && <h6> {proposalArt.art.name}</h6>}
                </div>
            </div>
            <div className="row at-rowprinting at-row-mlr-3">
                <div className="col-sm-6 offset-sm-3 my-4">
                    <button className="at-btn-linkorange at-btn-wauto at-fs16" onClick={() => history.push(`${routes.artCentral.collection.item}/${proposalArt.art.id}`)}>See Art Detail</button>
                </div>
            </div>
            {/* row ends */}

            <div className="row at-row-mlr-3">
                <div className="col-7">
                    <h5 className="at-semibold">Proposed Formats</h5>
                </div>
                <div className="col-5 at-textcenter">
                    <h5 className="at-semibold">Proposed Pricing</h5>
                </div>
            </div>
            {/* row ends */}
            {proposalArt.proposalFormats && proposalArt.proposalFormats.length > 0 && proposalArt.proposalFormats.map((f: IProposalArtFormats, index: number) => {
                return (f.isSelected ? (
                    <div className="row at-rowprinting at-row-mlr-3 align-items-center">

                        <div className="col-6 at-colprinting-label align-items-center">
                            <div>
                                <h6>{f.size}</h6>
                                {f.printType === EArtistPrintType.FRAMED && <p className="at-paralabelgrey">Framed <a className="at-btn-linkorange" onClick={() => onOpenShowService(f, index)}>See Details</a></p>}
                                {f.printType === EArtistPrintType.UNFRAMED && <p className="at-paralabelgrey">Unframed <a className="at-btn-linkorange" onClick={() => onOpenShowService(f, index)}>See Details</a></p>}
                            </div>
                        </div>
                        <div className="col-3 at-textcenter">
                            <h6><CurrencyFormat amount={Number(f.price)} /></h6>
                        </div>
                        <div className="col-3 at-textcenter">
                            <button className={`at-btn ${f.isSelectedForOffer ? 'at-btn-grey' : 'at-btn-lightgreen'} at-extrasmallbtn px-2 w-100`} onClick={() => onSelectArts(index)}>{f.isSelectedForOffer ? 'Selected' : 'Select'}</button>
                        </div>
                    </div>
                ) : null
                )
            })}

            {showService && selectedFormat && <UniversalModal open={showService} onClose={onCloseShowService} >
                <ShowArtServiceComponent format={selectedFormat} onSubmitShowService={() => { }} />
            </UniversalModal>}
        </>
    )
}