import React, { useEffect, useRef, useState } from 'react'
import { dataURLtoFile } from '../../../../../helper'
import { IArtInitialImageState, artInitialImageState } from '../../../../../interfaces'
import { IArtistServicesState } from '../../../../../interfaces/services.interface'
import { collectionImage } from '../../../../../shared'
import { SliderComponent } from '../../../../common/Slider.component'

interface IProps {
    onUpdateData: Function
    loading?: boolean
    state: IArtistServicesState
}
export const AddArtFileWithoutFrame = ({ onUpdateData, loading, state }: IProps) => {
    const imageRef = useRef<any>(null)
    const [imageState, setImageState] = useState<IArtInitialImageState>({ ...artInitialImageState })
    const selectImages = () => {

        imageRef.current.click();
    }
    const onChangeFiles = (e: any): void => {
        if (e.target.files) {

            /* Get files in array form */
            const files = Array.from(e.target.files);
            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then((base64Strings: any) => {
                    setImageState({
                        ...imageState,
                        preview: base64Strings,
                        previewsCopy: base64Strings,
                        image: files[0],
                        uploadItemImage: true
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onCrop = (image: any) => {
        const fileName = imageState.image.name
        const file = dataURLtoFile(image.cropped, fileName)
        // const updatedFiles = this.state.selectedFiles
        const updatedFiles = file
        // const updatedPreviews = [...this.state.preview]
        const updatedPreviews = [image.cropped]
        setImageState({
            ...imageState,
            image: updatedFiles,
            preview: updatedPreviews
        })
    }

    const deleteImage = (): void => {
        setImageState({
            ...imageState,
            preview: [],
            previewsCopy: [],
            image: null,
            uploadItemImage: false
        })
    }
    useEffect(() => {
        if (state.artImage && state.artImage.url) {
            setImageState({
                ...imageState,
                preview: [collectionImage({} as any, state.artImage.url || '')],
                previewsCopy: [collectionImage({} as any, state.artImage.url || '')],
                image: collectionImage({} as any, state.artImage.url || '')
            })
        }
    }, [state.artImage])

    return (
        <div className="at-addactionholder">
            <div className="at-formthemehead at-headselectprinter pb-2 ">
                <h2 className="at-textcenter">Select Print Medium and Size</h2>
                <p>Small Print Unframed</p>
            </div>
            <div className="at-selectprinter">
                <div className="at-greybgbox">
                    <div className="form-group form-w-100 mb-0">
                    {state.artImage && state.artImage.url && <p className="at-heading-greybgbox"><span>Current File:</span>{state.artImage.url}</p>}
                        <div className="at-themefileupload">
                            <input type="file" name="files[name]" multiple={false} ref={imageRef} accept="image/*" onChange={onChangeFiles} id="at-uploadfile" />
                            <label onClick={selectImages}>
                                <span><i className="icon-gallery"></i>Upload Picture</span>
                            </label>
                        </div>
                        <button className="at-btn mt-2" onClick={selectImages}>
                            Upload Artist Approved File
                    </button>
                    </div>
                </div>
                <div className="form-group form-w-100 at-positionrelative">
 
                    {/* <SliderComponent items={preview} currentIndex={selectedFile} onChangeIndex={(selectedFile: number) => this.setState({ selectedFile })}/> */}
                    <SliderComponent items={imageState.preview} originalItems={imageState.previewsCopy} onCrop={onCrop} currentIndex={0} onChangeIndex={() => { }} />
                    {
                        imageState.preview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={deleteImage}></i>)
                    }

                </div>
                <div className="row">
                    <div className="col-6 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-btn-cancel at-longbtn-h40" onClick={() => { }}>Cancel</button>
                        </div>
                    </div>
                    <div className="col-6 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-longbtn-h40" disabled={!imageState.image || loading} onClick={() => onUpdateData(imageState.image)}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}