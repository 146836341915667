import React from 'react'
import moment from 'moment'
import { INotification } from '../../interfaces'
import { collectionImage } from '../../shared'
import { CurrencyFormat } from '../common/CurrencyFormat'
import { ERole } from '../../enums'

interface IProps {
    notification: INotification
}

export const AcceptOfferLayout = ({ notification }: IProps) => {
    let isAgent = notification && notification?.to && notification?.to?.roles?.includes(ERole.AGENT)
    let artistName = isAgent ? `${notification.itemId?.artistName}` || `${notification?.to?.firstName} ${notification?.to?.lastName}` : `${notification?.to?.firstName} ${notification?.to?.lastName}`
    return (<div className="at-transaction-wrap">
        <div className="at-transaction-box">
            <div className="at-transact-left">
                <div className="at-transact-line">
                    <p>{moment(notification.createdAt).format("L")}</p>
                    <p className="pl-5">{moment(notification.createdAt).format("hh:mm:ss a")}</p>
                </div>
                <div className="at-transact-line">
                    <p>Seller:</p>
                    <p className="pl-2 text-capitalize"><span>{notification.from.firstName} {notification.from.lastName}</span></p>
                </div>
                <div className="at-transact-line">
                    <p>Artist:</p>
                    <p className="pl-2 text-capitalize"><span>{artistName}</span></p>
                </div>
                <div className="at-transact-line" >
                    <p>Buyer:</p>
                    <p className="pl-3 text-capitalize"><span>{`${notification.to.firstName} ${notification.to.lastName}`}</span></p>
                    {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                </div>
                <div className="at-transact-line pt-2">
                    {/* <p>Art:<span> {getItemType(item)}</span></p> */}
                </div>
                <div className="at-transact-line pt-2">
                    <p>Offer:<span><CurrencyFormat amount={notification.offerID?.offeredPrice} /></span></p>
                    <p className="pl-2">Shipping: <span><CurrencyFormat amount={notification.itemId?.shipping} /></span></p>
                </div>
            </div>
            {notification.itemId && <div className="at-transact-right">
                {notification.itemId && notification.itemId.name && <p className=""><span>{notification.itemId.name}</span></p>}
                <figure>
                    <img src={collectionImage(notification.itemId, `high_${notification.itemId.url}`, true)} alt="" />
                </figure>
                <button className={`at-btn at-btn-sm at-btn-orange`}>Next</button>
            </div>}
        </div>
    </div>
    )
}
