import React from 'react'
import { userImage } from '../../shared'
import { IUser } from '../../interfaces'
import { getInitials } from '../../helper'
import { ERole } from '../../enums'
import { isAgent } from '../../services'

interface IProps {
    user: IUser,
    className?: any
}
export const UserPicComponent = ({ user, ...props }: IProps) => (
    <figure className={props.className}>
        <img src={user.profile ? userImage(user.profile) : getInitials(user?.firstName, user?.lastName)} alt="icon"/>
    </figure>
)

export const UserNameComponent = ({ user }: IProps) => <span>{(isAgent(user) || user.isAgent) ? user.gallery_name : `${user.firstName} ${user.lastName}`}</span>
