import React, { lazy, Suspense } from 'react'

const SubscriptionPage = lazy(() => import('../User/Subscription.page'))

 const SubscriptionPageLazyloading = (props: any) => {
    return (
        <Suspense fallback={null}>
            <SubscriptionPage {...props} />
        </Suspense>
    )
}

export default SubscriptionPageLazyloading