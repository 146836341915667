export enum ERole {
    ADMIN = 'ADMIN',
    ARTIST = 'ARTIST',
    AGENT = 'AGENT',
    COLLECTOR = 'COLLECTOR',
    CONSULTANT = 'CONSULTANT',
    SERVICE_PROVIDER = 'SERVICE_PROVIDER',
    PRIVATE_ART_BUYER = 'PRIVATE_ART_BUYER',
    PROFESSIONAL_ART_BUYER = 'PROFESSIONAL_ART_BUYER',
    INSTITUTIONAL_BUYER = 'INSTITUTIONAL_BUYER',
    TESTER = 'TESTER',
    PROMOTER = 'PROMOTER',
    AUCTIONEER = 'AUCTIONEER',
}


