import React, { useState, useEffect, FormEvent } from 'react'
import ContactComponent from '../MyCentral/Contact/Contact.component'
import { IContact, ISpace } from '../../interfaces'
import { contactService, rtcService, toastService, userService } from '../../services';
import { AxiosResponse } from 'axios';
import { socketNode } from '../../services/socket.service';
import { ADD_NEW_MEMBER, USER_ALERT_SPACE_MESSAGE_REPLY } from '../../constants';
import LoadingComponent, { BSLoading } from './Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors';
import { isPlural, _logger } from '../../helper';
import { ManageContacts } from './ManageContacts';
import { MessageModal } from './Modals/MessagePopup';
import { ConfirmAlertComponent } from './ConfirmAlert.component';
import { fetchContacts } from '../../store/actions';

interface IProps {
	onClose: any
	isOpen: boolean
	contacts: Array<IContact>
}

export const InviteUserComponent = ({ onClose, isOpen, contacts }: IProps) => {

	const contactsInSpaceIds = useSelector(userSelector).activeSpace?.contacts?.map(({ _id: id }: IContact) => id);
	const user = useSelector(userSelector)
	const [selectedContactsState, setSelectedContacts] = useState<Array<any>>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [adding, setAdding] = useState(false);
	const [addContact, setAddContact] = useState<boolean>(false)
	const [editContact, setEditContact] = useState<boolean>(false)
	const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
	const [singleContact, setSingleContact] = useState<IContact>()
	const [openDelConfirmation, setOpenDelConfirmation] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useDispatch()

	const searchHandler = (value: string) => {
		const parsed = parseInt(value)
		const isName = isNaN(parsed)
		return (contact: IContact) => {
			if (isName) {
				const fullName =
					contact.firstName.toLowerCase() + ' ' + contact.lastName.toLowerCase()
				return (
					fullName.includes(value.toLocaleLowerCase()) ||
					// contact.firstName.toLowerCase().includes(value.toLowerCase()) ||
					// contact.lastName.toLowerCase().includes(value.toLowerCase()) ||
					false
				)
			} else {
				return contact.phoneNumber.includes(parsed + '') || false
			}
		}
	}
	const isSelected = (id: string) =>
		selectedContactsState.some((v: string) => v === id)

	const toggleSelect = (contactId: string) => {
		if (contacts.length === selectedContactsState.length) {
			setSelectedContacts([])
		}
		const isExisted = isSelected(contactId)
		let selectedContacts: Array<string> = selectedContactsState
		if (isExisted) {
			selectedContacts = selectedContacts.filter((v) => v !== contactId)
		} else {
			selectedContacts = [...selectedContacts, contactId]
		}
		setSelectedContacts(selectedContacts)
	}

	const isAlreadyAdded = (id: any) => contactsInSpaceIds?.includes(id)


	const onDone = async (): Promise<void> => {
		try {
			setAdding(true)
			const { data, status }: AxiosResponse<{ success: boolean, message: string, space: ISpace }> = await rtcService.inviteUser(selectedContactsState);
			if (data.success) {
				toastService.success(data.message)
				selectedContactsState.forEach((id: string) => {
					const user = contacts.find(
						(user: IContact) => id === user._id
					)
					if (user) socketNode.emit(ADD_NEW_MEMBER, { channel: data.space._id, user })
					setSelectedContacts([])
				})
				onClose()
			}
			setAdding(false)
		} catch (error) {
			_logger('', error);
			setAdding(false)

		}
	}

	const openContactForm = () => {
		setAddContact(true)
	}

	const closeAll = () => {
		let obj: any = {}
		setAddContact(false)
		setEditContact(false)
		setSingleContact({ ...obj })
		setOpenDelConfirmation(false)
	}

	const isAllSelected = () => {
		return selectedContactsState.length === (contactsInSpaceIds && contactsInSpaceIds.length > 0 ? (contacts.length - (contactsInSpaceIds.length - 1)) : contacts.length)
	}
	const selectAllContacts = () => {
		if (isAllSelected()) {
			setSelectedContacts([])
			return
		}
		let newSelected = contacts.map((c: IContact) => c._id)
		let isAlreadySelected = newSelected.filter((c: any) => !isAlreadyAdded(c))
		setSelectedContacts([...isAlreadySelected])
	}


	const openMessageBox = () => {
		setShowMessageBox(true)
	}

	const closeMessageBox = () => {
		setShowMessageBox(false)
	}

	const onEditContact = (c: IContact) => {
		setSingleContact(c)
		setEditContact(true)
	}

	const onSubmitMessage = (message: string) => {
		let data = {
			message: message,
			to: selectedContactsState,
			from: user._id,
			type: USER_ALERT_SPACE_MESSAGE_REPLY
		}
		userService.multiUserMessage({ ...data }).then((res: AxiosResponse<any>) => {
			if (res.data.status === 'Success') {
				toastService.success("Message sent successfully")
			}
			if (res.data.status === 'Fail') {
				toastService.error("Message not sent")
			}
			closeMessageBox()
		}).catch((err: any) => {
			console.log(err)
			closeMessageBox()
		})
	}

	const deleteContacts = (e: FormEvent) => {
		e.preventDefault()
		if (!selectedContactsState.length) return
		setLoading(true)
		contactService.remove(selectedContactsState).then((res: AxiosResponse<any>) => {
			dispatch(fetchContacts())
			closeAll()
			toastService.success(
				`${selectedContactsState.length} contact${isPlural(selectedContactsState.length) ? 's' : ''
				} deleted`
			)
			setSelectedContacts([])
			setLoading(false)
		}).catch((err: any) => {
			console.log(err)
			setLoading(false)
		})
	}

	const contactsList = contacts
		.filter(searchHandler(searchTerm))
		.map((contact) => {
			return (
				<ContactComponent
					key={contact._id}
					contact={contact}
					toggleSelect={toggleSelect}
					alreadyAdded={isAlreadyAdded(contact._id)}
					disabled={isAlreadyAdded(contact._id)}
					isSelected={isSelected}
					isEditable={true}
					editContact={onEditContact}
				/>
			)
		})
	return (
		<>
			<div id="at-themerecoverycodepopup" className={`at-themepopupholder ${isOpen && 'at-showthemerecoverycodepopup'}`}>
				<div className="at-themepopupbox at-themepopupbox-addcontact">
					<a className="at-close" id="at-close" onClick={onClose}><i className="icon-cancel"></i></a>

					<main id='at-main' className='at-main at-haslayout'>
						<form className=''>
							<fieldset>
								<div className='at-contacthead'>
									<h3>
										Contact<span>{contacts.length} Online</span>
									</h3>
								</div>
								<div className='form-group'>
									<input
										type='text'
										name='searchTerm'
										className='form-control'
										placeholder='Search by names & numbers'
										value={searchTerm}
										onChange={(v: any) => setSearchTerm(v.target.value)}
									/>
								</div>
								{contacts.length > 0 && <div className='form-group'>
									<div className={`at-selectall-contacts`}>
										<div
											className='at-checkbox'
											onClick={() => selectAllContacts()}
										>
											<input type='checkbox' checked={isAllSelected()} onChange={() => { }} />
											<label></label>
										</div>
									</div>
									<div className="at-contactright-actions cursor-pointer position-relative">
										<div className="d-flex align-items-center">

											{selectedContactsState.length > 0 && <><a className=" at-done" id="at-done" onClick={onDone}>
												{
													!adding ? <i className="icon-tick btn btn-secondary"></i> : <BSLoading />
												}
											</a>
												<a
													className='mx-3'
												onClick={() => setOpenDelConfirmation(true)}
												>
													<i className='icon-delete at-color-nightrider fs-20'></i>
												</a></>}
											<i className="icon-send at-color-nightrider fs-20" onClick={() => openMessageBox()} ></i>
										</div>
									</div>
								</div>}
								<div className='at-usereditcontactholder at-popupinner-h-400px scrollable'>
									<div className='form-group'>
										<div className='at-uservideobox'>{contactsList}</div>
									</div>
								</div>
							</fieldset>
						</form>
						<a className='at-btnadduser' onClick={openContactForm}>
							<i className='icon-add text-white'></i>
						</a>
					</main>

				</div>
			</div>
			<ManageContacts addContact={addContact} editContact={editContact} close={closeAll} contact={singleContact} />
			{showMessageBox && <MessageModal open={showMessageBox} message='' onClose={closeMessageBox} onSubmit={onSubmitMessage} />}
			{openDelConfirmation && (
				<ConfirmAlertComponent
					open={openDelConfirmation}
					onCancel={closeAll}
					message={`Delete ${selectedContactsState.length} contact${isPlural(selectedContactsState.length) ? 's' : ''
						}?`}
					onConfirm={deleteContacts}
					confirmTitle={'Delete'}
					onClose={closeAll}
				/>
			)}
			{loading && <LoadingComponent className="at-transaction-loader" />}
		</>
	)
}
