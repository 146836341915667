import { HttpService } from './base.service'
import { IContact, IResponse } from '../interfaces'
import { AxiosResponse } from 'axios'

class ContactService extends HttpService {
	private readonly prefix: string = 'contact'

	/**
	 * Contact List
	 */
	list = (): Promise<AxiosResponse<IContact[]>> => this.get(this.prefix)

	/**
	 * Create Contact
	 * @param  {IContact} contact
	 */
	add = (contact: IContact): Promise<AxiosResponse<IContact | IResponse>> =>
		this.post(this.prefix, contact)

	/**
	 * Update Contact
	 * @param  {IContact} contact
	 */
	udpate = (contact: IContact): Promise<AxiosResponse<IContact>> =>
		this.put(this.prefix, contact)

	/**
	 * Remove Contact form contact list
	 * @param  {IContact} contact
	 */
	remove = (contactIds: string[]): Promise<any> =>
		this.delete(this.prefix, undefined, { contactIds })
}

export const contactService = new ContactService()
