import React, {Component} from 'react';
import { RouteComponentProps, Link, useLocation } from 'react-router-dom';
import { IPost, ICollectionItem, ICollection, IStoreReducers, IUser, IOffer } from '../../../../interfaces';
import { AxiosResponse, AxiosError } from 'axios';
import LoadingComponent from '../../../../components/common/Loading.component';
import { collectionService } from '../../../../services/collection.service';
import { toastService, offerService } from '../../../../services';
import CollectionItemViewComponent from '../../../../components/ArtCentral/collection/ItemView.component';
import { IResponse } from '../../../../interfaces/db.interface';

interface IPageProps extends RouteComponentProps {
    user: IUser
    id?: string
    onBack: Function;
    onEditItem: Function
}

interface IPageState {
    item?: ICollectionItem
    loading: boolean
    offer?: IOffer
}
class AssignedCollectionItemView extends Component<IPageProps> {
    state: IPageState = {
        loading: false
    }

    componentDidMount() {
        if(this.query){
          this.fetchOffer()
        }
        this.fetch();
    }

    toggleLoading = (): void => {
		this.setState({ loading: !this.state.loading })
    }


    get query (){
        return this.props.location.search
    }

    fetch = (): void => {
        if (!this.props.id) return
        this.toggleLoading();
        collectionService.showItem(this.props.id).then((response: AxiosResponse<IResponse<ICollectionItem>>) => {
            this.setState({ item: response.data.data })
            this.toggleLoading();
        }, (error: AxiosError) => {
            this.toggleLoading();
        })
    }

    fetchOffer = () => {
    //   let query = new URLSearchParams(this.props.location.search).get("offer")
    //   offerService.getSingleOffer(query).then((res: AxiosResponse<Array<IOffer>>) => {
    //       this.setState({offer: res.data[0]})
    //   })
    }
    

    render() {
        const { loading, item, offer } = this.state;
        const { _id: userId } = this.props.user;
        
         
        return (
            <div className="at-collectiondetail at-collectiondetailview at-contentarea scrollable"> 
                {
                    loading && <LoadingComponent />
                }
                {
                    item && (
                        <CollectionItemViewComponent item={item} actionOffer={offer} {...this.props} refresh={this.fetch} refreshOffer={this.fetchOffer}/>
                    )
                }
            </div>
        );
    }
}


export default AssignedCollectionItemView;