import { string } from "prop-types"
import { IUser } from "./user.interface"

interface IHomeInformation {
    heading: string,
    description: string
}
interface TemplateContactDocument {
    email: string,
    instagramId: string,
    facebookId: string,
    youtubeId: string,
    information: string
}

interface IFonts {
    fontFamily: string,
    fontCategory: string,
    fontSize: string
}
export interface ITemplateState {
    _id?: string,
    user?: IUser | string,
    url: string,
    template: string,
    homeImage: any,
    galleryImage: any,
    logoImage: any,
    aboutImage: any,
    homeInformation: IHomeInformation,
    contactInformation: TemplateContactDocument,
    locationImage: any,
    textColor: string,
    backgroundColor: string,
    eventPageName?: string,
    artistNamePage?: string
    lineColor: string,
    aboutDescription: string,
    font: IFonts
}
export interface ITemplateReducerState {
    _id?: string,
    user: IUser,
    url: string,
    template: string,
    homeImage: any,
    galleryImage: any,
    logoImage: any,
    aboutImage: any,
    homeInformation: IHomeInformation,
    contactInformation: TemplateContactDocument,
    locationImage: any
    eventPageName?: string,
    artistNamePage?: string,
    textColor: string,
    backgroundColor: string,
    lineColor: string,
    aboutDescription: string,
    font: IFonts
}

export interface IinitTemplateDataState {

    textColor: string,
    backgroundColor: string,
    _id?: string,
    user?: IUser | string,
    url: string,
    template: string,
    homeImage: any,
    galleryImage: any,
    logoImage: any,
    aboutImage: any,
    galleryImagePreview: Array<any>,
    homeImagePreview: Array<any>,
    logoImagePreview: Array<any>,
    locationImagePreview: Array<any>,
    aboutImagePreview: Array<any>,
    locationImage: any,
    homeInformation: IHomeInformation,
    contactInformation: TemplateContactDocument,
    eventPageName?: string,
    artistNamePage?: string,
    lineColor: string,
    aboutDescription: string,
    font: IFonts
}
export const initTemplateState: ITemplateState = {
    url: '',
    template: '',
    homeImage: null,
    galleryImage: null,
    logoImage: null,
    aboutImage: null,
    homeInformation: {
        heading: '',
        description: ''
    },
    contactInformation: {
        email: '',
        instagramId: '',
        facebookId: '',
        youtubeId: '',
        information: ''
    },
    locationImage: null,
    textColor: '',
    backgroundColor: '',
    lineColor: '',
    aboutDescription: '',
    font: {
        fontCategory: '',
        fontFamily: '',
        fontSize: '18'
    }
}

export const initTemplateDataState = {

    galleryImagePreview: [],
    homeImagePreview: [],
    logoImagePreview: [],
    locationImagePreview: [],
    aboutImagePreview: [],
    ...initTemplateState
}
