import React, { FormEvent } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { IArtDetailImageState, IArtInitialImageState, ICollection, ICollectionItemState, IUser } from '../../../../interfaces'
import { lang } from '../../../../locale'
import { CurrencyField } from '../../../common/CurrencyInput'
import { SliderComponent } from '../../../common/Slider.component'

interface IProps extends StepWizardChildProps {
    item: ICollectionItemState
    errorElement: Function
    onChangeText: Function
    imageRef: any
    onChangeFiles: Function
    selectImages: Function
    imageState: IArtInitialImageState
    onCrop: Function
    onChangePrice: Function
    artDetailImages: IArtDetailImageState
    deleteImage: Function
    artDetailRef: any
    onChangeArtImages: Function
    selectArtImages: Function
    onCropArtImages: Function
    onChangeIndex: Function
    onDeleteArtImage: Function
    setErrorData: Function
    errors: any
    user: IUser
}

export const CreateCollectionStepThree = ({ item, errors, errorElement, onChangeText, imageRef, onChangeFiles, selectImages, imageState, onCrop, onChangePrice, artDetailImages, deleteImage, artDetailRef, onChangeArtImages, selectArtImages, onCropArtImages, onChangeIndex, onDeleteArtImage, setErrorData, previousStep, nextStep, user }: IProps) => {

    const onClickNext = () => {
        let newErrors = errors
        if (!item.name) {
            newErrors['name'] = [lang.errors.required.art_collection.name]
            setErrorData({ ...newErrors })
            return
        }
        if (!item.price) {
            newErrors['price'] = [lang.errors.required.art_collection.price]
            setErrorData({ ...newErrors })
            return
        }
        if (!item.height) {
            newErrors['height'] = [lang.errors.required.art_collection.height]
            setErrorData({ ...newErrors })
            return
        }
        if (!item.width) {
            newErrors['width'] = [lang.errors.required.art_collection.width]
            setErrorData({ ...newErrors })
            return
        }
        if (!item.depth) {
            newErrors['depth'] = [lang.errors.required.art_collection.depth]
            setErrorData({ ...newErrors })
            return
        }

        setErrorData({})
        nextStep()
    }

    let { image } = imageState
    return (
        <>
            <div className="at-orangeheading">
                <h5>Add Original Art Information</h5>
            </div>
            <div className="at-greyborderbox pt-4">

                <div className="form-group form-w-100 mb-0">
                    <div className="at-themefileupload">
                        <input
                            type="file"
                            name="files[name]"
                            multiple={false}
                            ref={imageRef}
                            accept="image/*"
                            onChange={(e: any) => onChangeFiles(e)}
                            id="at-uploadfile"
                        />
                        <label onClick={() => selectImages()}>
                            <span>
                                <i className="icon-gallery"></i>UPLOAD YOUR ART
                            </span>
                        </label>
                    </div>
                </div>

                <div className="form-group form-w-100 mb-4">
                    {/* <SliderComponent items={preview} currentIndex={selectedFile} onChangeIndex={(selectedFile: number) => this.setState({ selectedFile })}/> */}
                    <SliderComponent
                        items={imageState.preview}
                        originalItems={imageState.previewsCopy}
                        onCrop={(e: any) => onCrop(e)}
                        currentIndex={0}
                        onChangeIndex={() => { }}
                    />
                    {imageState.preview.length > 0 && (
                        <i
                            className="icon-delete2 at-icon-delete"
                            onClick={() => deleteImage()}
                        ></i>
                    )}
                </div>

                <div className="form-group form-w-100 mb-0">
                    <div className="newCollectionDescLabel">
                        <small className="">
                            Please upload additional picture of your art to help the buyer
                            better appereciate the beauty and significance of your art.
                        </small>
                    </div>
                </div>

                <div className="form-group form-w-100 mb-0">
                    <div className="at-themefileupload">
                        <input
                            type="file"
                            name="artFiles[name]"
                            multiple
                            ref={artDetailRef}
                            accept="image/*"
                            onChange={(e: any) => onChangeArtImages(e)}
                            id="at-uploadfile"
                        />
                        <label onClick={() => selectArtImages()}>
                            <span>
                                <i className="icon-gallery"></i>UPLOAD ART Detail
                            </span>
                        </label>
                    </div>
                </div>

                <div className="form-group form-w-100 mb-4">
                    {/* <SliderComponent items={preview} currentIndex={selectedFile} onChangeIndex={(selectedFile: number) => setState({ selectedFile })}/> */}
                    <SliderComponent
                        items={artDetailImages.preview}
                        originalItems={artDetailImages.previewsCopy}
                        onCrop={(e: any) => onCropArtImages(e)}
                        currentIndex={artDetailImages.artImageIndex}
                        onChangeIndex={(selectedFile: number) => onChangeIndex(selectedFile)}
                    />
                    {artDetailImages.preview.length > 0 && (
                        <i
                            className="icon-delete2 at-icon-delete"
                            onClick={() => onDeleteArtImage()}
                        ></i>
                    )}
                </div>

                {user.isAgent && <div className="form-group form-w-100 at-floatlabel">
                    <input
                        disabled={!image}
                        autoComplete="off"
                        type="text"
                        name="artistName"
                        className="form-control at-input-semibold"
                        placeholder=" "
                        value={item.artistName}
                        onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                    />
                    <label>Artist Name</label>
                    {errorElement("name")}
                </div>}

                <div className="form-group form-w-100 at-floatlabel">
                    <input
                        disabled={!image}
                        autoComplete="off"
                        type="text"
                        name="name"
                        className="form-control at-input-semibold"
                        placeholder=" "
                        value={item.name}
                        onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                    />
                    <label>Art Name</label>
                    {errorElement("name")}
                </div>

                <div className="form-group form-w-100 at-floatlabel">
                    <input
                        disabled={!image}
                        autoComplete="off"
                        type="text"
                        name="artTechnique"
                        className="form-control at-input-semibold"
                        placeholder=" "
                        value={item.artTechnique}
                        onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                    />
                    <label>Art Technique and Medium</label>
                    {/* <span className={`${(item.artTechnique || '').length > 30 && 'text-danger'}`}>You have {30 - (item.artTechnique || '').length} characters left</span> */}
                    {errorElement("artTechnique")}
                </div>

                <div className="form-group form-w-100 mb-0">
                    <div className="newCollectionDescLabel">
                        <small className="">
                            Please write a short description to help the buyer better understand the inspiration, story, or technique behind your art.
                        </small>
                    </div>
                </div>
                <div className="form-group at-floatlabel form-w-100">
                    <textarea
                        disabled={!image}
                        name="description"
                        placeholder=" "
                        className="form-control "
                        onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                        value={item.description}
                    >
                        {item.description}
                    </textarea>
                    <label>Art Description</label>
                    {errorElement("description")}
                    <span
                        className={`${(item.description || "").length > 2000 && "text-danger"
                            }`}
                    >
                        You have {2000 - (item.description || "").length} characters
                        left
                    </span>
                </div>
                <div className="clearfix"></div>
                {/* <div className="row row-form-three-cols align-items-center mt-1"> */}
                {/* <div className="form-group form-w-100 m-0">
                        <div className="d-flex justify-content-end mb-4">
                            <a className="at-orangelink-borderbottom" onClick={() => openShowShippingCalculation()}>Calculate Shipping</a>
                        </div>
                    </div> */}

                {/* 
                                <div className="form-group form-w-50 at-floatlabel">
                                    <CurrencyField
                                        id="input-example-shipping"
                                        className="form-control at-input-semibold"
                                        name="shipping"
                                        placeholder=" "
                                        prefix="$"
                                        disabled={!image}
                                        value={item.shipping}
                                        onChange={(e: FormEvent<HTMLInputElement> | any) => onChangePrice(e)}
                                    />
                                    <label>Shipping Cost</label>
                                    {errorElement("shipping")}
                                </div>
                            */}

                {/* </div> */}
                <div className="row">
                    <div className="col">
                        <div className="form-group form-w-50 at-floatlabel">
                            <CurrencyField
                                id="input-example-price"
                                className="form-control at-input-semibold"
                                name="price"
                                placeholder=" "
                                prefix="$"
                                disabled={!image}
                                value={item.price}
                                onChange={(e: FormEvent<HTMLInputElement> | any) => onChangePrice(e)}
                            />
                            <label>Original Price</label>
                            {errorElement("price")}
                            {/* <input type="text" name="price" className="form-control cust-input-padding-5px" placeholder="Price" /> */}
                        </div>
                        <div className="form-group form-w-50 at-floatlabel">
                            <input
                                disabled={!image}
                                autoComplete="off"
                                type="number"
                                step="any"
                                min="0.0001"
                                name="height"
                                className="form-control at-input-semibold"
                                placeholder=" "
                                value={item.height}
                                onWheel={(e: any) => e.target.blur()}
                                onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                            />
                            <label>Height (inches)</label>
                            {errorElement("height")}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group form-w-50 at-floatlabel">
                            <input
                                disabled={!image}
                                autoComplete="off"
                                type="number"
                                step="any"
                                min="0.0001"
                                name="width"
                                className="form-control at-input-semibold"
                                placeholder=" "
                                value={item.width}
                                onWheel={(e: any) => e.target.blur()}
                                onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                            />
                            <label>Width (inches)</label>
                            {errorElement("width")}
                        </div>
                        {/* <div className="form-group form-w-100 m-0">
                    <div className="d-flex justify-content-end">
                        <a className="at-orangelink-borderbottom" onClick={() => openShowWeightCalculation()}>Calculate Weight</a>
                    </div>
                </div> */}
                        <div className="form-group form-w-50 at-floatlabel">
                            <input
                                disabled={!image}
                                autoComplete="off"
                                type="number"
                                step="any"
                                min="0.0001"
                                name="depth"
                                className="form-control at-input-semibold"
                                placeholder=" "
                                value={item.depth}
                                onWheel={(e: any) => e.target.blur()}
                                onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                            />
                            <label>Depth (inches)</label>
                            {errorElement("depth")}
                        </div>
                    </div>
                </div>
                <div className="at-clearfix">

                </div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => previousStep()}
                        className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                    >
                        BACK
                    </button>
                </div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => onClickNext()}
                        disabled={!image}
                        className="at-btn at-medbtn-h40-mauto"
                    >
                        NEXT
                    </button>
                </div>

            </div>
            {/* at-greyborderbox ends */}

        </>
    )

}