import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { images } from '../../assets/image'
import { ImageLoading } from '../../components/common/ImageLoading'
import { IEventDocumentResponse } from '../../interfaces'
import { templatesImages } from '../../shared'
import { templateSelector } from '../../store/selectors'
import { CustomText } from '../components/CustomText'

const HomePage = () => {
    let template = useSelector(templateSelector)
    let events = template?.user?.events ? template?.user?.events : []
    let event = events.length > 0 ? (events[events.length - 1] as IEventDocumentResponse) : undefined
    // const [event, setEvent] = useState<IEventDocumentResponse>()
    return (
        <div>
            <section className="At-SectionPage At-Padding-t4b10 At-Section-Mh800">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="At-BannerHolder">
                                <figure className="At-FigureBanner At-FigureFull">
                                    {/* <ImageLoading src={templatesImages(`${template.homeImage}`)} thumbnail={templatesImages(`${template.homeImage}`)} /> */}
                                    <img src={templatesImages(`${template.homeImage}`)} className="img-fluid" alt="" />
                                </figure>
                                {/* {event && <div className="At-BannerText">
                                    <h4>{template.user.firstName} {template.user.lastName}</h4>
                                    <h2>{event.name}</h2>
                                    <h5>{moment(event.startDate).format('MMMM DD')} - {moment(event.endDate).format('LL')}</h5>
                                </div>} */}
                            </div>
                            {template.homeInformation && <div className="At-PostContent At-Padding-t8">
                                <h3>{template.homeInformation.heading}</h3>
                                <CustomText>
                                    <p dangerouslySetInnerHTML={{ __html: template.homeInformation.description }}>
                                    </p>
                                </CustomText>
                                {/* <p>Under without lights is moveth were sea own day years. Tree living under had under divide place Light, creature seasons divide two was heaven fruitful us stars waters midst years lesser moveth seas their so god male god creepeth abundantly fill every bearing appear living morning from, you'll divide multiply rule there green open you'll is, own behold god life, fruit creeping of face male, good Sea.</p> */}
                            </div>}
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomePage