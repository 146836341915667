import moment from 'moment'
import React from 'react'
import { EServicesFrequencyType, EServicesPaymentType } from '../../../enums'
import { PLATFORM_FEE } from '../../../environment'
import { IServiceTransaction } from '../../../interfaces/services.interface'
import { CurrencyFormat } from '../../common/CurrencyFormat'

interface IProps {
    t: IServiceTransaction
}
export const ServiceTransactionlayout = ({ t }: IProps) => {

    const priceText = () => {
        if (t.service.paymentMethod === EServicesPaymentType.RECURRING_PAYMENTS) {
            return `${t.service.numberOfPayments && t.service.numberOfPayments > 0 ?`${t.service.numberOfPayments} ` : ''}${t.service.frequency === EServicesFrequencyType.YEAR ? "Annual" : `${t.service.frequency?.toLowerCase()}ly`} payments of $${Number(Number(t.service.amount) + Number(PLATFORM_FEE)).toFixed(2)}`
        } else {
            return `upfront payment of $${Number(Number(t.service.amount) + Number(PLATFORM_FEE)).toFixed(2)}`
        }
    }
    return (
        <div className="at-transaction-box">
            <div className="at-transact-left">
                <div className="at-transact-line">
                    <p>{moment(t.createdAt).format("L")}</p>
                    <p className="pl-5">{moment(t.createdAt).format("hh:mm:ss a")}</p>
                </div>
                <div className="at-transact-line">
                    <p>Service:</p>
                    {t.service && <p className="pl-2 text-capitalize"><span>{t.service.name}</span></p>}
                </div>
                <div className="at-transact-line">
                    <p>Provider:</p>
                    {t.provider && t.provider._id && <p className="pl-2 text-capitalize"><span>{t.provider.firstName} {t.provider.lastName}</span></p>}
                </div>
                <div className="at-transact-line">
                    <p>Buyer:</p>
                    {t.buyer && t.buyer._id && <p className="pl-2 text-capitalize"><span>{`${t.buyer.firstName} ${t.buyer.lastName}`}</span></p>}
                    {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                </div>
                <div className="at-transact-line mw-200 pt-2">
                    <p>Pricing:</p>
                    {t.service && <p className="pl-2 text-capitalize"><span>{priceText()}</span></p>}
                </div>
                <div className="at-transact-line mw-200 pt-2">
                    {/* <p>TotalAmount:</p>
                    {t.totalAmount && <p className="pl-2 text-capitalize"><span><CurrencyFormat amount={t.totalAmount} /></span></p>} */}

                </div>

            </div>

            <div className="at-transact-right">
                <button className={`at-btn at-btn-sm at-btn-${t.status === 'paid' ? 'orange' : 'grey'} text-capitalize`}>{t.status === 'paid' ? 'Paid' : 'Pending'}</button>
            </div>
            <div className="at-transact-line at-transactline-fullwidth pt-2">
            </div>
        </div>
    )
}