import { HttpService } from "./base.service";
import { IUser } from "../interfaces";

class AuctionService extends HttpService {
    private readonly prefix: string = 'auction';

    /**
     * 
     *  Create Auction
     */
    createAuction = (data: any): Promise<any> => this.post(`${this.prefix}/create`, data)

    /**
     * Update Auction
     * @param id 
     * @param data 
     * @returns 
     */

    updateAuction = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/update/${id}`, data)

    /**
     * Get Auctions list
     */

    getAuctionsList = (data: any): Promise<any> => this.get(`${this.prefix}/auctions`, data)

    /**
     * Get User Auctions list
     */

    getUserAuctions = (): Promise<any> => this.get(`${this.prefix}/user-auctions`)

    /**
     * Get AuctionBy Id
     * @param id 
     * @returns 
     */

    getAuctionById = (id: any): Promise<any> => this.get(`${this.prefix}/auction-by-id/${id}`)

    /**
     *  Delete Auctions
     * @param id 
     * @returns 
     */

    deteAuction = (id: any): Promise<any> => this.put(`${this.prefix}/delete-auction/${id}`)

    /**
     * Update Single Auction
     * @param id 
     * @param data 
     * @returns 
     */

    updateSingleAuction = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/delete-auction/${id}`, data)

    /***************************************** Auction Attendees API'S ************************************************ */

    /**
     * Register User for auction
     * @param data 
     * @returns 
     */
    registerUserToAuction = (data: any): Promise<any> => this.post(`${this.prefix}/user-registration`, data)

    /**
     * get User as an Attendee in Auctions
     * @returns 
     */
    getUserAuctionReg = (): Promise<any> => this.get(`${this.prefix}/user-attendees`)


    deleteAttendee = (id: any): Promise<any> => this.put(`${this.prefix}/delete-attende/${id}`)

    /***************************************** Auction Arts API'S ************************************************ */


    /**
     *  Add Art to Start Auction
     * @param data 
     * @returns 
     */
    startAuctionOnArt = (data: any): Promise<any> => this.post(`${this.prefix}/add-art-auction`, data)

    /**
     * Get Current Art in Auction
     * @param auctionId 
     * @returns 
     */
    getCurrentArtInAuction = (auctionId: any): Promise<any> => this.get(`${this.prefix}/current-auction-art/${auctionId}`)

    /**
     * Get Current Art By Id
     * @param auctionId 
     * @returns 
     */
    getAuctionArtById = (id: any): Promise<any> => this.get(`${this.prefix}/get-auction-art/${id}`)

    /**
     *  Stop Auction Art
     * @param data 
     * @returns 
     */
    stopAuctionOnArt = (data: any): Promise<any> => this.put(`${this.prefix}/close-auction-art`, data)

    /**
     *  Stop Auction Art
     * @param id
     * @returns 
     */
    restartAuctionOnArt = (id: any): Promise<any> => this.put(`${this.prefix}/restart-auction-art-bidding/${id}`)

    /***************************************** Auction Bidding API'S ************************************************ */

    /**
     *  Get Latest Bid
     * @param auctionArtId 
     * @returns 
     */
    getLatestBid = (auctionArtId: any): Promise<any> => this.get(`${this.prefix}/latest-bid/${auctionArtId}`)

    /**
     * Create New Bid
     * @param data 
     * @returns 
     */
    createBid = (data: any): Promise<any> => this.post(`${this.prefix}/add-new-bid`, data)


    /**
     * Payment
     * @param data 
     * @returns 
     */
    auctionCheckout = (data: any): Promise<any> => this.post(`${this.prefix}/checkout`, data);

}

export const auctionService = new AuctionService()