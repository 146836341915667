import React from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { IAuctionState } from '../../../../../interfaces/auction.interface'
import { ErrorComponent } from '../../../../common/Error.component'
import { SliderComponent } from '../../../../common/Slider.component'
import { TmceEditor } from '../../../../common/TMCEditor.comopnent'


interface IProps extends StepWizardChildProps {
    eventState: IAuctionState
    onChangeText: Function
    errors: any
    imageState: any
    previewPoster: Array<any>
    imageRef: any
    pdfRef: any
    posterRef: any
    onChangeFiles: Function
    onCrop: Function
    deleteImage: Function
    onChangePoster: Function
    onDeletePoster: Function
    selectImages: Function
    selectPdf: Function
    selectPoster: Function
    onCropPoster: Function
    onChangeIndex: Function
    setErrorData: Function
}

export const AuctionformStepTwo = ({
    eventState,
    errors,
    onChangeText,
    imageState,
    posterRef,
    selectPoster,
    onChangePoster,
    previewPoster,
    onCropPoster,
    onDeletePoster,
    imageRef,
    onChangeFiles,
    selectImages,
    selectPdf,
    onCrop,
    pdfRef,
    deleteImage,
    onChangeIndex,
    setErrorData,
    nextStep, previousStep
}: IProps) => {

    const onClickNext = () => {
        let { name, startDate, endDate, startTime, endTime } = eventState
        let newErr = errors
        if (!imageState.preview ) {
            newErr['images'] = ['Images are required']
            setErrorData({ ...newErr })
            return
        }

        if (imageState.preview && imageState.preview.length === 0 ) {
            newErr['images'] = ['Images are required']
            setErrorData({ ...newErr })
            return
        }

        if (!eventState.pdf ) {
            newErr['pdf'] = ['Pdf is required']
            setErrorData({ ...newErr })
            return
        }

        if (!eventState.description ) {
            newErr['description'] = ['Description is required']
            setErrorData({ ...newErr })
            return
        }

        newErr = {}
        setErrorData({ ...newErr })
        nextStep()
    }

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    return (
        <>
            <div className="at-orangeheading">
                <h5>Add Live Auction Marketing Information</h5>
                {/* <h6>(skip Step 1 if adding art to existing collection)</h6> */}
            </div>
            <div className="at-greyborderbox pt-4">
                <div className="form-group form-w-100">
                    <div className="at-themefileupload">
                        <input type="file" name="poster" multiple accept="image/*" ref={posterRef} onChange={(e: any) => onChangePoster(e, 'poster')} id="at-uploadfile-3" />
                        <label onClick={() => selectPoster()}>
                            <span><i className="icon-gallery"></i>UPLOAD AUCTION POSTER</span>
                        </label>
                    </div>
                </div>

                {previewPoster && previewPoster.length > 0 && <div className={`form-group form-w-100`}>
                    <SliderComponent items={previewPoster} originalItems={previewPoster} onCrop={onCropPoster} currentIndex={0} onChangeIndex={() => { }} />
                    {
                        previewPoster && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeletePoster()}></i>)
                    }
                    {/* <CropImageComponent image={preview[selectedFile]} /> */}
                </div>}

                <div className="form-group form-w-50">
                    <div className="at-themefileupload">
                        <input type="file" name="files[name]" multiple accept="image/*" ref={imageRef} onChange={(e) => onChangeFiles(e)} id="at-uploadfile" />
                        <label onClick={() => selectImages()}>
                            <span><i className="icon-gallery"></i>UPLOAD AUCTION PICTURES</span>
                        </label>
                        
                    </div>
                    {errorElement('images')}
                </div>
                <div className="form-group form-w-50">
                    <div className="at-themefileupload">
                        <input type="file" name="pdf" accept="application/pdf" ref={pdfRef} onChange={(e: any) => onChangePoster(e, 'pdf')} id="at-uploadfile-2" />
                        <label onClick={() => selectPdf()}>
                            {eventState.pdf && eventState.pdf !== "null" ? <span><i className="icon-tick  at-color-greenlight"></i>pdf uploaded</span> : <span><i className="icon-pdf-big"></i>Upload Auction pdf</span>}
                        </label>
                    </div>
                    {errorElement('pdf')}
                </div>
                <div className={`form-group form-w-100`}>
                    <SliderComponent items={imageState.preview} originalItems={imageState.previewsCopy} onCrop={onCrop} currentIndex={imageState.selectedFile} onChangeIndex={(selectedFile: number) => onChangeIndex(selectedFile)} />
                    {
                        imageState.preview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={() => deleteImage()}></i>)
                    }
                    {/* <CropImageComponent image={preview[selectedFile]} /> */}
                </div>

                <div className="form-group form-w-100 ">
                    <label className="text-left at-color-grey">Auction Description</label>
                    {/* <textarea name="description" placeholder=" " value={eventState.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}

                    <TmceEditor text={eventState.description} onChange={(e: any) => onChangeText({ target: { name: "description", value: e } })} />
                    {errorElement('description')}
                </div>

                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => previousStep()}
                        className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                    >
                        BACK
                    </button>
                </div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => {
                            onClickNext()
                        }}
                        className="at-btn at-medbtn-h40-mauto"
                        disabled={!eventState.poster}
                    >
                        NEXT
                    </button>
                </div>
            </div>
        </>
    )
}