import { IPost, IMiscState } from "../../interfaces";
import { IActionType, SET_IMAGE_TO_ZOOM, ACTIVATE_SPACE } from "../types";

const initialState: IMiscState = {
    zoomImage: {
        original: '',
        thumbnail: ''
    },
    isSpaceActivated: false
}

export const miscReducer = (state = initialState, action: IActionType): IMiscState => {
    switch (action.type) {
        case SET_IMAGE_TO_ZOOM:
            return { ...state, zoomImage: action.payload };
        case ACTIVATE_SPACE:
            return { ...state, isSpaceActivated: action.payload };
        default:
            return state;
    }
}
