import { ERole, ESelling } from "../enums";
import { EScreens } from "../enums/screens.enum";
import { ESubPakcges } from "../enums/subsPackage.enum";
import { PLATFORM_FEE } from "../environment";


/*  User Selling types */
export const userSelling = [
    {
        label: 'Works of art',
        value: ESelling.ART
    },
    {
        label: 'Services to the art community',
        value: ESelling.ART_SERVICES
    },
]

/*  User Roles */

export const userArtRoles = [
    {
        label: 'I am an artist',
        value: ERole.ARTIST
    },
    {
        label: 'I am an agent and/or Gallery',
        value: ERole.AGENT
    },
]

export const userArtServiceRoles = [
    {
        label: 'I provide consulting services',
        value: ERole.CONSULTANT
    },
]

export const getUserRoles = (type: string) => {
    switch (type) {
        case ESelling.ART:
            return userArtRoles
        case ESelling.ART_SERVICES:
            return userArtServiceRoles

        default:
            return []
    }
}

/* Tutorials variables */

export const userTypeArray = [
    { label: 'Private Buyer', value: ERole.PRIVATE_ART_BUYER },
    { label: 'Printing/Framing Service Provider', value: ERole.SERVICE_PROVIDER },
    { label: 'Collector', value: ERole.COLLECTOR },
    { label: 'Consulting Services Provider', value: ERole.CONSULTANT },
    { label: 'Institutional Buyer', value: ERole.INSTITUTIONAL_BUYER },
    { label: 'Artist', value: ERole.ARTIST },
    { label: 'Agent/Gallery', value: ERole.AGENT }
]

export const screenTypeArray = [
    { label: 'Home Screen', value: EScreens.HOME },
    { label: 'Create Post', value: EScreens.CREATE_POST },
    { label: 'Create Collection', value: EScreens.CREATE_COLLECTION },
    { label: 'Create Event', value: EScreens.CREATE_EVENT },
    { label: 'Edit Profile', value: EScreens.EDIT_PROFILE },
    { label: 'Subscription', value: EScreens.SUBSCRIPTION },
    { label: 'In-Platform Website', value: EScreens.IN_PLATFORM_WEBSITE },
    { label: 'Assign Account Management', value: EScreens.ASSIGN_ACCOUNT_MANAGEMENT },
    { label: 'Manage Assigned Accounts', value: EScreens.MANAGE_ASSIGNED_ACCOUNT },
    { label: 'Offers', value: EScreens.OFFERS },
    { label: 'Transactions', value: EScreens.TRANSACTIONS },
    { label: 'Commission Work', value: EScreens.COMMISSION_WORK },
    { label: 'Disputes', value: EScreens.DISPUTES },
    { label: 'Returns', value: EScreens.RETURNS },
    { label: 'Metrics', value: EScreens.METRICS },
    { label: 'Manage Funds', value: EScreens.MANAGE_FUNDS },
    { label: 'Create Cloud-Walk', value: EScreens.CREATE_CLOUD_WALK },
    { label: 'Manage Space', value: EScreens.MANGE_SPACE },
    { label: 'Select Cloud-Walk', value: EScreens.SELECT_CLOUD_WALK },
    { label: 'Add Space to Cloud-Walk', value: EScreens.ADD_SPACE_TO_CLOUD_WALK },
]

export const defaultConsultantServices = {
    title: 'OMNI Platform Services',
    type: 'DEFAULT',
    services: [
        {
            name: 'OMNI Onboarding'

        },
        {
            name: 'In -Platform Website Development'

        },
        {
            name: 'Portfolio Review, Analysis and Update'

        },
        {
            name: 'Platform Marketing Program Management'

        },
        {
            name: 'Complete  Account  Management'

        },
    ],
}