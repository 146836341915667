export enum EServicesType {
    DEFAULT = 'DEFAULT',
    USER_CREATED = 'USER_CREATED',
}

export enum EServicesPaymentType {
    UPFRONT_PAYMENT = 'UPFRONT_PAYMENT',
    RECURRING_PAYMENTS = 'RECURRING_PAYMENTS',
}

export enum EServicesFrequencyType {
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum EDefaulServices {
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum EPrintingServiceType {
    PRINTING_OPTION = 'PRINTING_OPTION',
    FRAMING_OPTION = 'FRAMING_OPTION'
}

export enum EPrintType {
    FRAMED = 'FRAMED',
    UNFRAMED = 'UNFRAMED'
}
export enum EArtistPrintType {
    FRAMED = 'FRAMED',
    UNFRAMED = 'UNFRAMED',
    OWN = "OWN"
}

export enum EProfessionalServiceType {
    CONTENT_CREATION_MARKETING = 'CONTENT_CREATION_MARKETING',
    BUSINESS_MANAGEMENT_FOR_ARTISTS = 'BUSINESS_MANAGEMENT_FOR_ARTISTS',
    ART_ADVISORY_FOR_BUYERS = 'ART_ADVISORY_FOR_BUYERS',

}