import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, RouteComponentProps } from "react-router-dom";
import { routes } from "./index";
import NewsFeedsPage from "../pages/ArtCentral/NewsFeeds.page";
import BottomTabsComponent from "../components/ArtCentral/BottomTabs.component";
import StreamingHeaderComponent from "../components/common/StreamingHeader.component";
import CreateEditPostPage from "../pages/ArtCentral/CreateEditPost.page";
import NotFound404Page from "../pages/NotFound404.page";
import CreateEditCollectionPage from "../pages/ArtCentral/collection/CreateEditCollection.page";
import CollectionListPage from "../pages/ArtCentral/collection/CollectionList.page";
import CollectionViewPage from "../pages/ArtCentral/collection/CollectionView.page";
import CollectionItemViewPage from '../pages/ArtCentral/collection/CollectionItemView.page';
import { AgentCollectionsPage } from '../pages/ArtCentral/collection/AgentCollections.page';
import { AgentAssignmentCollectionViewPage } from '../pages/ArtCentral/collection/AssignmentCollectionView.page';
import AgentAssignmentDetailsViewPage from '../pages/ArtCentral/collection/AgentAssignmentDetailsView.page';
import RequestCommissionwork from '../pages/ArtCentral/CommissionWork/RequestCommissionWork'
import ProposalCommissionwork from '../pages/ArtCentral/CommissionWork/CommissionWorkProposal'
import RequestCommisionWork from '../pages/ArtCentral/CommissionWork/RequestCommissionWork';
import CreatEditEvent from '../pages/LazyloadingComponents/CreateEditEventLazyloading'
import EventsListViewPage from '../pages/LazyloadingComponents/EventsListViewLazyloading'
import EventView from '../components/ArtCentral/events/EventView'
import HomePage from '../pages/Home.page';
import CreateCollection from '../pages/ArtCentral/collection/CreateCollection';
import Art_Collections from '../pages/ArtCentral/Art_Collections';
import GalleryWalkPage from '../pages/GalleryWalk.page';
import CreateGalleryWalkPage from '../components/ArtCentral/GalleryWalk/CreateGalleryWalk.component';
import { SingleGalleryView } from '../pages/BuyerArtCentral/SingleGalleryWalkView.component';
import { GalleryWalkFeatureArtsComponent } from '../components/ArtCentral/GalleryWalk/GalleryWalkFeatureArts.component';

class ArtCentralRoute extends Component {
    render() {
        return (
            <main id="at-main" className="at-main at-haslayout">

                {/*Switch Routes*/}
                <Switch>
                    <Route path={routes.home} exact component={HomePage} />

                    <Route path={routes.artCentral.newsFeeds} exact component={NewsFeedsPage} />
                    <Route path={routes.artCentral.post.create} exact component={(props: any) => (
                        <CreateEditPostPage timestamp={new Date().toString()} {...props} />
                    )} />
                    <Route path={`${routes.artCentral.post.edit}/:id`} component={(props: any) => (
                        <CreateEditPostPage timestamp={new Date().toString()} {...props} />
                    )} />
                    <Route path={routes.artCentral.artCollection} exact component={(props: any) => (
                        <Art_Collections isShowMobile={true} {...props} />)
                    } />
                    <Route path={routes.artCentral.gelleryWalk.index} exact component={(props: any) => (
                        <GalleryWalkPage {...props} />)
                    } />

                    <Route path={routes.artCentral.gelleryWalk.create} exact component={(props: any) => (
                        <CreateGalleryWalkPage timestamp={new Date().toString()} {...props} />)
                    } />

                    <Route path={`${routes.artCentral.gelleryWalk.view}/:id`} exact component={(props: any) => (
                        <SingleGalleryView {...props} />)
                    } />
                    <Route path={`${routes.artCentral.gelleryWalk.featureArts}/:id`} exact component={(props: any) => (
                        <GalleryWalkFeatureArtsComponent {...props} />)
                    } />

                    <Route path={`${routes.artCentral.gelleryWalk.create}/:id`} exact component={(props: any) => (
                        <CreateGalleryWalkPage timestamp={new Date().toString()} {...props} />)
                    } />
                    <Route path={`${routes.artCentral.collection.list}/:id`} exact component={CollectionListPage} />

                    <Route path={routes.artCentral.collection.create} exact component={(props: any) => (
                        <CreateCollection timestamp={new Date().toString()} {...props} />
                    )} />
                    <Route path={`${routes.artCentral.collection.edit}/:id/:item?`} component={(props: any) => (
                        <CreateCollection timestamp={new Date().toString()} {...props} />
                    )} />
                    <Route path={`${routes.artCentral.collection.view}/:id`} component={CollectionViewPage} />
                    <Route path={`${routes.artCentral.collection.item}/:id`} component={CollectionItemViewPage} />

                    {/* Agent Routes */}
                    <Route path={routes.artCentral.agent.assignments} exact component={(props: any) => (
                        <AgentCollectionsPage timestamp={new Date().toString()} {...props} />
                    )} />

                    <Route path={routes.agent.assignments} exact component={(props: any) => (
                        <AgentCollectionsPage timestamp={new Date().toString()} {...props} />
                    )} />
                    <Route path={routes.agent.collection + '/:id'} component={AgentAssignmentCollectionViewPage} />
                    <Route path={routes.agent.showAssigment + '/:id'} component={AgentAssignmentDetailsViewPage} />


                    <Route path={`${routes.artCentral.events.edit}/:id`} component={(props: any) => (
                        <CreatEditEvent timestamp={new Date().toString()} {...props} />
                    )} />
                    <Route path={routes.artCentral.events.create} component={(props: any) => (
                        <CreatEditEvent timestamp={new Date().toString()} {...props} />
                    )} />
                    <Route path={routes.artCentral.events.list + '/:id'} component={EventsListViewPage} />
                    <Route path={routes.artCentral.events.view + '/:id'} component={EventView} />

                    <Route path={`${routes.artCentral.commissionWork.requestCommissionwork}/:id`} component={RequestCommisionWork} />
                    <Route path={`${routes.artCentral.commissionWork.proposalCommissionwork}/:id`} component={ProposalCommissionwork} />

                    <Route path="*" component={NotFound404Page} />

                </Switch>

                {/*Bottom Tabs*/}
                <BottomTabsComponent {...this.props as RouteComponentProps} />
            </main>
        );
    }
}

export default ArtCentralRoute;