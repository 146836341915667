
import { HttpService } from "./base.service";
import { IUser } from "../interfaces";

class GalleryWalkService extends HttpService {
    private readonly prefix: string = 'gallery-walk';

    /**
     * 
     *  Create Gallery Walk
     * @param data
     */
    createGalleryWalk = (data: any): Promise<any> => this.post(`${this.prefix}/create`, data)


    /**
     * 
     *  List of User Gallery Walks
     * 
     */
    getAllUserGalleryWalks = (): Promise<any> => this.get(`${this.prefix}/all-user-gallery`)

    /**
     * 
     *  List of Gallery Walks
     * 
     */
    getGalleryWalks = (data: any): Promise<any> => this.get(`${this.prefix}/all`, data)

    /**
     * 
     *  List of Gallery Walks
     * @param id
     */
    getAllGalleryWalkById = (id: string): Promise<any> => this.get(`${this.prefix}/single/${id}`)

    /**
     * 
     *  Create Gallery Walk
     * @param data
     */

    updateGalleryWalk = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/update/${id}`, data)

    /**
    * 
    * Delete Gallery Walk
    * @param id
    */

    deleteGalleryWalkById = (id: any): Promise<any> => this.put(`${this.prefix}/delete/${id}`)

    /**
    * 
    * Delete Gallery Walk
    * @param id
    */

    updateSingleGalleryWalkById = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/update-single-gallery-walk/${id}`, data)


    /**
     * 
     *  Single Gallery Walks Space
     * @param id
     */
    getSingleGallerySpace = (id: string): Promise<any> => this.get(`${this.prefix}/gallery-single-space/${id}`)


}

export const galleryWalkService = new GalleryWalkService()