import moment from 'moment'
import React from 'react'
import { ERole } from '../../enums'
import { ITransaction } from '../../interfaces'
import { collectionImage } from '../../shared'
import { CurrencyFormat } from './CurrencyFormat'

interface IProps {
    item: ITransaction,
    handleDetailPopup: Function
    getItemType: Function
    getButtonStatus: Function
}

export const TransactionLayout = ({ handleDetailPopup, item, getItemType, getButtonStatus }: IProps) => {
    let artist = item.artistID && item.artistID._id ? item.artistID : item.sellerID
    let isAgent = artist && artist.roles.includes(ERole.AGENT)
    let artistName = artist && isAgent ? item.itemID?.artistName || `${artist.firstName} ${artist.lastName}` : `${artist.firstName} ${artist.lastName}`
    return (
        <div className="at-transaction-box">
            <div className="at-transact-left">
                <div className="at-transact-line">
                    <p>{moment(item.createdAt).format("L")}</p>
                    <p className="pl-5">{moment(item.createdAt).format("hh:mm:ss a")}</p>
                </div>
                <div className="at-transact-line" onClick={() => handleDetailPopup(item.sellerID, 'Seller', item)}>
                    <p>Seller:</p>
                    {item.sellerID && <p className="pl-2 text-capitalize"><span>{item.sellerID.firstName} {item.sellerID.lastName}</span></p>}
                </div>
                <div className="at-transact-line" onClick={() => handleDetailPopup(item.sellerID, 'Seller', item)}>
                    <p>Artist:</p>
                    {artist && <p className="pl-2 text-capitalize"><span>{artistName}</span></p>}
                </div>
                <div className="at-transact-line" onClick={() => handleDetailPopup(item.buyerID, 'Buyer', item)}>
                    <p>Buyer:</p>
                    {item.buyerID && <p className="pl-3 text-capitalize"><span>{`${item.buyerID.firstName} ${item.buyerID.lastName}`}</span></p>}
                    {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                </div>
                <div className="at-transact-line d-block" onClick={() => handleDetailPopup(item.buyerID, 'Buyer', item)}>
                    <p>Shipping Address:</p>
                    {item.zip && <p className="text-capitalize"><span>{item.shippingAddress}, {item.city}, {item.state}, {item.zip}</span></p>}
                    {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                </div>
                <div className="at-transact-line mw-200 pt-2">
                    <p>Art:<span> {item.itemName ? item.itemName : getItemType(item)}</span></p>
                </div>
                {item?.shipment?.shipment_company && <><div className="at-transact-line">
                    <p className=" text-capitalize">Shipping Company: <span>{item.shipment.shipment_company}</span></p>
                </div>
                    <div className="at-transact-line pt-2">
                        <p className="">Shipping Date: <span>{moment(item.shipment.shipment_date).format("L")}</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p className="">Est. Delivery Date: <span>{moment(item.shipment.delivery_date).format("L")}</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p className="">Tracking Number: <span>{item.shipment.tracking_number}</span></p>
                    </div></>}

            </div>

            <div className="at-transact-right">
                {item.itemID && item.itemID.name && <p className=""><span>{item.itemID.name}</span></p>}
                <figure>
                    {item.itemID && item.itemID.url && <img src={collectionImage(item.itemID, `high_${item.itemID.url}`, true)} alt="" />}
                </figure>
                <button className={`at-btn at-btn-sm at-btn-${getButtonStatus(item).color} text-capitalize`}>{getButtonStatus(item).status}</button>
            </div>
            <div className="at-transact-line at-transactline-fullwidth pt-2">
                {/* <p>Price:<span><CurrencyFormat amount={item.price} /></span></p>
                <p className="pl-2">Shipping: <span><CurrencyFormat amount={item.shippingPrice} /></span></p> */}
                <p>Total: <span><CurrencyFormat amount={item.totalPrice} /></span></p>
            </div>
            
        </div>
    )
}