import React, { Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import RSelect from 'react-select'
import { IQuestion, IStoreActionsStates, ISignupState, IUser, IStoreReducers } from '../../interfaces';
import { ErrorComponent } from '../../components/common/Error.component';
import { toastService, questionService, authService } from '../../services';
import { validationErrors, userImage } from '../../shared';
import { BAD_REQUEST, CONFLICT } from 'http-status'
import { accountService } from '../../services/account.service';
import { connect } from 'react-redux';
import { AxiosResponse, AxiosError } from 'axios';
import PhoneInput from 'react-phone-number-input'
import { ERole } from '../../enums';
import { refreshUser } from '../../store/actions';
import { ConfirmAlertComponent } from '../../components/common/ConfirmAlert.component';
import { _logger } from '../../helper';
import { getUserRoles, userSelling } from '../../constants';
import { UniversalModal } from '../../components/common/Modals/Universal.modal';
import { SubscriptionComponent } from '../../components/UserSubscription/Subscription.component';
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon';
import { EScreens } from '../../enums/screens.enum';
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial';


interface IPageProps extends IStoreActionsStates, RouteComponentProps {
    refreshUser: Function
}

interface IPageState {
    errors: any
    loading: boolean
    questions: IQuestion[]
    questionTab: number
    image: any
    imagePath: string
    roleChangedMessage: string
    isOpenRoleChangePopup: boolean
    confirmAccountRole?: Function
    user: IUser | any
    changePassword: boolean
    isValidZip: boolean,
    showSubscriptionPopup: boolean,
    prevRole: Array<string>
    prevSelling: string,
    isValidated: boolean
    showTutorialBox: boolean
}

const initialState: IPageState = {
    errors: null,
    loading: false,
    questions: [],
    questionTab: 1,
    image: null,
    imagePath: '',
    changePassword: false,
    isOpenRoleChangePopup: false,
    roleChangedMessage: '',
    user: {
        _id: '',
        firstName: '',
        profile: '',
        lastName: '',
        isActive: false,
        phoneNumber: '+1',
        isDeaf: false,
        question1: '',
        answer1: '',
        question2: '',
        answer2: '',
        gallery_name: '',
        roles: [],
        address: '',
        city: '',
        state: '',
        zipcode: '',
        selling: '',
        businessName: ''
    },
    isValidZip: false,
    showSubscriptionPopup: false,
    prevRole: [],
    prevSelling: '',
    isValidated: false,
    showTutorialBox: false
};

class EditAccountPage extends Component<IPageProps> {

    state: IPageState = { ...initialState }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.fetchQuestions()
        this.fetchUser()
    }

    fetchUser = (): void => {
        this.toggleLoading(true)
        accountService.me().then((response: AxiosResponse<IUser>) => {
            let isValidZip = false
            if (response.data.zipcode) {
                isValidZip = true
            }
            this.setState({ user: response.data, isValidZip, prevSelling: response.data.selling, prevRole: response.data.roles });
            this.toggleLoading(false);
        }, (error: AxiosError) => {
            this.toggleLoading(false);
        })


    }

    fetchQuestions = () => {
        questionService.list().then((response: any) => {
            this.setState({ questions: response.data })
        })
    }
    get questions1() {
        const { questions, user } = this.state;
        return questions.filter((q: IQuestion) => q._id !== user.question2)
    }

    get questions2() {
        const { questions, user } = this.state;
        return questions.filter((q: IQuestion) => q._id !== user.question1)
    }

    onChangeText = (e: FormEvent<HTMLInputElement> | any): void => {
        const { name, value } = e.target;
        const { user } = this.state;
        const _user = { ...user, [name]: value };
        this.setState({ user: _user })
    }

    selectImage = (e: any): void => {
        const [first] = e.target.files;
        if (first) {
            const url = URL.createObjectURL(first)
            this.setState({ image: first, imagePath: url })
        }
    }

    toggleLoading = (loading: boolean): void => {
        this.setState({ loading })
    }

    // Submit registeration Form
    submit = (e: FormEvent): void => {
        e.preventDefault()
        this.onUpdating()
    }

    checkRolePackages = () => {
        if (this.state.user.roles.includes(ERole.ARTIST) && this.state.user.roles.includes(ERole.AGENT) && this.state.user.roles.includes(ERole.CONSULTANT)) {
            return true
        }
        return false
    }

    onUpdating = () => {
        const { prevRole, prevSelling, isValidated } = this.state
        const { firstName, lastName, phoneNumber, password, confirmPassword, question1, question2, answer1, answer2, isDeaf, roles, gallery_name, zipcode, city, address, state, selling, businessName } = this.state.user;
        if (!this.validateAddress()) return
        this.toggleLoading(true);
        this.setState({ errors: null })
        let data: any = {
            firstName, lastName, phoneNumber, question1, question2, answer1, answer2, isDeaf, roles, gallery_name, zipcode, city, state, address, selling, businessName
        }
        if (this.state.changePassword) {
            data = { ...data, password: password || '', confirmPassword: confirmPassword || '', changePassword: true }
        }
        const formData = new FormData()
        for (let key in data) {
            formData.append(key, data[key]);
        }
        if (this.state.imagePath) formData.append('file', this.state.image);
        if (!isValidated && (this.checkRolePackages()) && (selling !== prevSelling || !prevRole.includes(roles[0]))) {
            formData.append('validate', 'validate')
        }
        accountService.update(formData).then(this.successHandler, this.errorHandler)
    }

    successHandler = (response: any) => {
        console.log("resp:", response)
        if (response.data.validated) {
            this.openShowSubscriptionPopup()
            this.setState({ isValidated: true })
            return
        }
        toastService.success('Profile updated successfully.');
        this.toggleLoading(false);
        this.setState({ isValidated: false })
        this.props.refreshUser();
    }


    errorHandler = ({ response }: any) => {
        let errors = null;
        this.toggleLoading(false);
        switch (response.status) {
            case BAD_REQUEST:
                errors = validationErrors(response.data);
                toastService.error('Please fix errors.')
                break;
            case CONFLICT:
                errors = response.data
                toastService.error('Please fix errors.')
                break;
            default:
                break;
        }
        this.setState({ errors, isValidated: false })
    }

    errorElement = (key: string) => (
        this.state.errors && this.state.errors[key] && <ErrorComponent errors={this.state.errors[key]} multiple={false} />
    )

    questionElement = (type: number) => (
        <div className={`at-tabpane tab-pane fade show ${this.state.questionTab === type ? 'active' : ''}`} id={`questionTab${type}`} role="tabpanel" aria-labelledby="questionone-tab">
            <div className="form-group">
                <span className="at-select">
                    <select id={`question${type}`} name={`question${type}`} value={this.state.user[`question${type}`]} onChange={this.onChangeText}>
                        <option>Select Question {type}</option>
                        {
                            (type === 1 ? this.questions1 : this.questions2).map((q: IQuestion) => <option key={q._id} value={q._id}>{q.title}</option>)
                        }
                    </select>
                </span>
                {this.errorElement(`question${type}`)}
            </div>
            <div className="form-group">
                <input type="text" name={`answer${type}`} className="form-control" value={this.state.user[`answer${type}`]} placeholder="Security Answer" onChange={this.onChangeText} />
                {this.errorElement(`answer${type}`)}
            </div>
        </div>
    )

    get profileImage() {
        const { imagePath, user: { profile } } = this.state;
        if (imagePath) {
            return imagePath
        } else if (profile) {
            return userImage(profile);
        }
        return 'default-image.png'
    }

    confirmAccountRole = (role: any) => {

        return () => {
            let { user } = this.state
            if (user.roles.includes(role.value)) {
                user.roles = []
            } else {
                user.roles = [role.value]
            }
            this.setState({ user: { ...user }, isOpenRoleChangePopup: false })
        }
    }

    setRole = (role: any): void => {
        let { user } = this.state
        const [first] = user.roles.includes(role.value) ? [] : [role.value]

        let msg = '';
        // msg = !first ? 'Assigned arts and Created Collections will be removed from account' : msg;
        if (!user.roles?.length || !(user.roles[0]?.length > 0)) {
            let func = this.confirmAccountRole(role)
            func()
            return
        }
        msg = !first ? 'All of your previous Collections and Services will be removed from account' : msg;
        msg = first === ERole.AGENT ? 'Created Collections will be removed from account' : msg;
        msg = first === ERole.ARTIST ? 'Assigned arts will be removed from account' : msg;

        this.setState({ roleChangedMessage: msg, confirmAccountRole: this.confirmAccountRole(role), isOpenRoleChangePopup: true })
    }

    setSellingType = (role: any): void => {
        let { user } = this.state
        if (role.value !== user.selling) {
            user.roles = []
        }
        user.selling = role.value
        this.setState({ user: { ...user } })
    }

    onChangeZip = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        _logger('', {
            name, value
        });

        const { user } = this.state;
        const _user = { ...user, [name]: value };
        this.setState({ user: _user }, () => {
            if (value.length === 5) {
                this.checkZip(value);
            }
            else {
                this.setState({ isValidZip: false })
            }
        })
    }

    checkZip = (value: any) => {
        const { user, errors } = this.state;
        this.setState({ zipLoading: true })
        let url = `https://zip.getziptastic.com/v2/US/${value}`
        fetch(url, { method: 'GET' }).then((res) => res.json()).then((res: any) => {
            if (res.city && res.state && res.country) {
                let newErrors: any = errors
                if (newErrors && newErrors['zipcode']) {
                    delete newErrors['zipcode']
                }
                this.setState({
                    user: {
                        ...user,
                        city: res.city,
                        state: res.state,
                    },
                    isValidZip: true,
                    errors: newErrors,
                    zipLoading: false
                })
            } else {
                let newErrors: any = {}
                newErrors['zipcode'] = ['Please enter valid zip code']
                this.setState({ errors: newErrors, zipLoading: false })
            }
        }).catch(err => {
            let newErrors: any = errors
            newErrors['zipcode'] = ['Please enter valid zip code']
            this.setState({ errors: newErrors, zipLoading: false })
        })
    }

    validateAddress = () => {
        let { errors, user } = this.state
        if (errors && errors['zipcode']) {
            return false
        }
        if (!user.zipcode) {
            let newErrors: any = {}
            newErrors['zipcode'] = ['Please enter zip code']
            this.setState({ errors: newErrors })
            return false
        }
        if (!user.address) {
            let newErrors: any = {}
            newErrors['address'] = ['Please enter address']
            this.setState({ errors: newErrors })
            return false
        }
        if (user.roles.includes(ERole.CONSULTANT) && !user.businessName) {
            let newErrors: any = {}
            newErrors['businessName'] = ['Business Name is Required']
            this.setState({ errors: newErrors })
            return false
        }
        if (user.zipcode.length < 5) {
            let newErrors: any = {}
            newErrors['zipcode'] = ['Please enter valid zip code']
            this.setState({ errors: newErrors })
            return false
        }
        this.setState({ errors: null })
        return true
    }

    openShowSubscriptionPopup = () => {
        this.setState({ showSubscriptionPopup: true })
    }

    closeSubscriptionPopup = (subsLevel: string) => {
        let { user, prevRole, prevSelling } = this.state
        if (!subsLevel && !this.props.user.subscriptionID) {
            this.toggleLoading(false)
            user.roles = prevRole
            user.selling = prevSelling
            this.setState({ user })
        } else {
            // toastService.success(`Successfully buy the ${subsLevel} Subscription`)
            this.onUpdating()
        }
        this.setState({ showSubscriptionPopup: false })
    }

    openTutorialBox = () => {
        this.setState({
            showTutorialBox: true
        })
    }

    closeTutorialBox = () => {
        this.setState({
            showTutorialBox: false
        })
    }

    getAddressLabel = (u: IUser) => {
        if (u.roles.includes(ERole.ARTIST)) {
            return 'Art Studio Address'
        }
        if (u.roles.includes(ERole.AGENT)) {
            return 'Gallery Address'
        }
        if (u.roles.includes(ERole.CONSULTANT)) {
            return 'Business Address'
        }

        return 'Address'
    }

    render() {
        const { loading, errors, user, questionTab, changePassword, roleChangedMessage, isOpenRoleChangePopup, confirmAccountRole, isValidZip, showSubscriptionPopup } = this.state;

        const tab1 = errors && (errors.question1 || errors.answer1) ? 'text-danger' : questionTab === 1 && 'active';
        const tab2 = errors && (errors.question2 || errors.answer2) ? 'text-danger' : questionTab === 2 && 'active';
        let userRoles = getUserRoles(user.selling)

        return (
            <main id="at-main" className="at-main at-haslayout">
                <div className="at-createaccount scroll-style-3 at-contentarea-two">
                    <div className="at-themehead">
                        <a onClick={() => this.props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                        <h2>Edit Profile <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.EDIT_PROFILE} onClick={this.openTutorialBox} /></span></h2>
                    </div>
                    <form className="at-formtheme at-createaccountform " onSubmit={this.submit}>
                        <fieldset>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <div className="at-dpuploadholder">
                                            <span><img src={this.profileImage} alt="" /></span>
                                            <label className="at-dpupload">
                                                <input id="at-udpupload" type="file" accept="image/*" name="dpupload" onChange={this.selectImage} />
                                            </label>
                                        </div>
                                        <h2>
                                            {user.firstName} {user.lastName}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group at-floatlabel-h40">
                                        <input type="text" name="firstName" value={user.firstName} className="form-control" placeholder=" " onChange={this.onChangeText} />
                                        <label>First name</label>
                                        {this.errorElement('firstName')}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group at-floatlabel-h40">
                                        <input type="text" name="lastName" value={user.lastName} className="form-control" placeholder=" " onChange={this.onChangeText} />
                                        <label>Last name</label>
                                        {this.errorElement('lastName')}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group mb-3">
                                        <div className="at-deafhardholder" onClick={e => this.setState({ user: { ...user, isDeaf: !user.isDeaf } })}>
                                            <em className="at-deafhardicon"><i className="icon-hand"></i></em>
                                            <span>Deaf or Hard of Hearing</span>
                                            <div className="onoffswitch">
                                                <input type="checkbox" name="isDeaf" className="onoffswitch-checkbox" id="myonoffswitch4" checked={user.isDeaf} />
                                                <label className="onoffswitch-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {!this.props.user.roles.includes(ERole.CONSULTANT) &&
                                    <>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group mb-3">
                                                <div className="at-deafhardholder" onClick={e => this.setRole({ value: ERole.PRIVATE_ART_BUYER })}>
                                                    <em className="at-deafhardicon"><i className="icon-gallery"></i></em>
                                                    <span>Private Art Buyer</span>
                                                    <div className="onoffswitch">
                                                        <input type="checkbox" name="agent" className="onoffswitch-checkbox" value={ERole.PRIVATE_ART_BUYER} id="myonoffswitch5" checked={user.roles.includes(ERole.PRIVATE_ART_BUYER)} />
                                                        <label className="onoffswitch-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <div className="at-deafhardholder" onClick={e => this.setRole({ value: ERole.PROFESSIONAL_ART_BUYER })}>
                                                    <em className="at-deafhardicon"><i className="icon-color-palette"></i></em>
                                                    <span>Professional/institutional Art Buyer</span>
                                                    <div className="onoffswitch">
                                                        <input type="checkbox" name="artist" className="onoffswitch-checkbox" value={ERole.PROFESSIONAL_ART_BUYER} id="myonoffswitch6" checked={user.roles.includes(ERole.PROFESSIONAL_ART_BUYER)} />
                                                        <label className="onoffswitch-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                } */}
                                {/* {!isBuyer && !this.props.user.roles.includes(ERole.CONSULTANT) && <>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group at-FormInputHAuto at-zindex10 mb-3">
                                            <RSelect name='selling' value={userSelling.find(s => s.value === user.selling)} options={userSelling} onChange={this.setSellingType} placeholder='What are you selling?' />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group at-FormInputHAuto at-zindex9">
                                            <RSelect name='role' disable={userRoles.length === 0} value={userRoles?.find(s => s.value === user.roles[0])} options={userRoles} onChange={this.setRole} placeholder='What is your role?' />
                                            {this.errorElement('role')}
                                        </div>
                                    </div>
                                </>} */}
                                <div className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 ${!user.roles.includes(ERole.AGENT) ? 'd-none' : ''}`}>
                                    <div className="form-group at-floatlabel-h40">
                                        <input autoComplete="off" type="text" name="gallery_name" value={user.gallery_name} className="form-control" placeholder=" " onChange={this.onChangeText} />
                                        <label>Gallery Name</label>
                                        {this.errorElement('gallery_name')}
                                    </div>
                                </div>
                                {this.state.user.roles.includes(ERole.CONSULTANT) && <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group at-floatlabel-h40">
                                        <input type="text" name="businessName" className="form-control" placeholder=" " value={user.businessName} onChange={this.onChangeText} />
                                        <label>Business Name</label>
                                        {this.errorElement('businessName')}
                                    </div>
                                </div>}
                                {isValidZip &&
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group at-floatlabel-h40">
                                            <input type="text" name="address" className="form-control" placeholder=" " value={user.address} onChange={this.onChangeText} />
                                            <label>{this.getAddressLabel(user)}</label>
                                            {this.errorElement('address')}
                                        </div>
                                    </div>}
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                    <div className="row">
                                        {isValidZip && <><div className="col-4 col-sm-4 col-md-4 col-lg-4 at-colpadding-right-0">
                                            <div className="form-group at-floatlabel-h40">
                                                <input type="text" disabled name="city" className="form-control" placeholder=" " value={user.city} />
                                                <label>City</label>
                                            </div>
                                        </div>
                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 at-colpadding-right-0">
                                                <div className="form-group at-floatlabel-h40">
                                                    <input type="text" disabled name="state" className="form-control" placeholder=" " value={user.state} />
                                                    <label>State</label>
                                                </div>
                                            </div> </>}
                                        <div className={!isValidZip ? 'col-xs-12 col-sm-12 col-md-12 col-lg-12' : 'col-4 col-sm-4 col-md-4 col-lg-4'}>
                                            <div className="form-group at-floatlabel-h40">
                                                <input type="text" name="zipcode" className="form-control" placeholder=" " value={user.zipcode} onChange={this.onChangeZip} />
                                                <label>Zipcode</label>
                                                {this.errorElement('zipcode')}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <PhoneInput
                                            disabled={true}
                                            placeholder="Phone (e.g., +1 412 877 4312)"
                                            value={user.phoneNumber}
                                            defaultCountry="US"
                                            onChange={(phoneNumber: any) => this.setState({ user: { ...user, phoneNumber } })} />
                                        {this.errorElement('phoneNumber')}

                                    </div>
                                </div>
                                {
                                    !user.provider && (
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <div className="at-deafhardholder" onClick={e => this.setState({ changePassword: !changePassword })}>
                                                    <em className="at-deafhardicon"><i className="icon-lock"></i></em>
                                                    <span>Change Password</span>
                                                    <div className="onoffswitch">
                                                        <input type="checkbox" name="changePassword" className="onoffswitch-checkbox" id="myonoffswitch5" checked={changePassword} />
                                                        <label className="onoffswitch-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    changePassword && (
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group at-floatlabel-h40">
                                                <input type="password" disabled={!changePassword} name="password" className="form-control" placeholder=" " onChange={this.onChangeText} />
                                                <label>Password</label>
                                                {changePassword && this.errorElement('password')}

                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    changePassword && (
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group at-floatlabel-h40">
                                                <input type="password" disabled={!changePassword} name="confirmPassword" className="form-control" placeholder=" " onChange={this.onChangeText} />
                                                <label>Confirm Password</label>
                                                {changePassword && this.errorElement('confirmPassword')}
                                            </div>
                                        </div>
                                    )
                                }
                                {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <ul className="nav nav-tabs at-themenavtabs at-questiontab" id="at-questiontab" role="tablist">
                                                <li className="nav-item">
                                                    <a className={`nav-link ${tab1}`} id="questionone-tab" data-toggle="tab" onClick={() => this.setState({ questionTab: 1 })} role="tab" aria-controls="questionone" aria-selected="true"><span>Question 1</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${tab2}`} id="questiontwo-tab" data-toggle="tab" onClick={() => this.setState({ questionTab: 2 })} role="tab" aria-controls="questiontwo" aria-selected="false"><span>Question 2</span></a>
                                                </li>
                                            </ul>
                                            <div className="at-tabcontent tab-content" id="myTabContent">
                                                { this.questionElement(1) }
                                                { this.questionElement(2) }
                                            </div>
                                        </div>
                                    </div> */}
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group mb-0">
                                        <button className="at-btn at-btnrecoveycode at-btn-fullwidth" disabled={loading} type="submit">{loading ? 'Please wait...' : 'UPDATE'}</button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <ConfirmAlertComponent title="Warning!" message={roleChangedMessage} open={isOpenRoleChangePopup} onCancel={() => this.setState({ isOpenRoleChangePopup: false })} onConfirm={confirmAccountRole} />
                    {showSubscriptionPopup && user && <UniversalModal open={showSubscriptionPopup} onClose={() => this.closeSubscriptionPopup('')} position='bottom'>
                        <SubscriptionComponent user={user} onCLose={this.closeSubscriptionPopup} />
                    </UniversalModal>
                    }
                    {this.state.showTutorialBox && <UniversalModal open={this.state.showTutorialBox} onClose={this.closeTutorialBox} position='bottom-0'>
                        <ScreenTutorial screen={EScreens.EDIT_PROFILE} />
                        {/* at-categorybox ends */}
                    </UniversalModal>
                    }
                </div>
            </main>
        );
    }
}

const mapState = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapState, { refreshUser })(EditAccountPage);