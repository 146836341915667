import React, { Component, FormEvent } from 'react'
import { images } from '../../assets/image'
import { IStoreReducers, IPostComment, IUser } from '../../interfaces';
import { connect } from 'react-redux';
import { postService } from '../../services/post.service';
import { AxiosResponse } from 'axios';
import { userImage } from '../../shared';
import moment from 'moment';
import { UserPicComponent } from '../User/index.component';
import { socketNode } from '../../services/socket.service';
import { ADD_NEW_COMMENT } from '../../constants';

interface IComponentProps {
    id: string
    user: IUser
}

interface IComponentState {
    comment: string
    comments: Array<IPostComment>
    loading: boolean
    sending: boolean
}

class CommentsComponent extends Component<IComponentProps> {

    state: IComponentState = {
        comment: '',
        comments: [],
        loading: false,
        sending: false
    }

    componentDidMount() {
        this.fetch();
        this.receiveCommentSocket();
    }
    
    toggleLoading = (key: 'sending' | 'loading') => {
        this.setState({ [key]: !this.state[key] })
    }

    receiveCommentSocket = () => {
        socketNode.on(ADD_NEW_COMMENT, (comment: IPostComment) => {
            if (comment.post === this.props.id) this.setState({ comments: [...this.state.comments, comment ] });
        })
    }

    fetch = (): void => {
        this.toggleLoading('loading');
        postService.comments(this.props.id).then((response: AxiosResponse<IPostComment>) => {
            this.toggleLoading('loading');
            this.setState({ comments: response.data })          
        })
    }

    submit = (e: FormEvent): void => {
		e.preventDefault();
        const { comment, comments } = this.state;
        if (comment.trim() === '') return;
		this.toggleLoading('sending');
        const { user, id } = this.props;
		postService.addComment(id, { comment }).then((response: AxiosResponse<IPostComment>) => {
			this.toggleLoading('sending');
            this.setState({ comment: '' })
            // this.setState({ comment: '', comments: [...comments, { ...response.data, user }] })
            socketNode.emit(ADD_NEW_COMMENT, {...response.data, user});
		})
	}

    render() {
        const { user } = this.props;
        
        const { sending, loading, comment, comments } = this.state;
        
        return (
            <div>
                <div className="comments-list scroll-style-3">
                    {
                        comments.map((c: IPostComment) => (
                            <div className="commentbox" key={c._id}>
                                <fieldset>
                                    <div className="form-group bb-silder">
                                        <UserPicComponent user={c.user} className='at-addcommentimg'/>
                                        <div className="text-left">
                                            <b>{c.user.firstName} {c.user.lastName}</b>
                                            <p className="m-0 overflow-hidden">{c.comment}</p>
                                            <p className="fs-10 text-date">{moment(c.createdAt).fromNow()}</p>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        ))
                    }
                </div>
                <form className="at-formtheme at-formpostcomment at-formcomment p-0" onSubmit={this.submit}>
                    <fieldset>
                        <div className="form-group">
                            {/* <figure > */}
                            {
                                user && <UserPicComponent user={user} className="at-addcommentimg"/>
                            }
                            {/* </figure> */}
                            <input type="text" name="addcomment" autoFocus={true} disabled={sending} value={comment} className="form-control" placeholder="Add Comment..." onChange={({ target: { value: comment }}) => this.setState({ comment })} autoComplete="off"/>
                            { comment.length > 0 && <a onClick={this.submit} className="at-btnsendmessage cursor-pointer"><i className="fa fa-paper-plane"></i></a>}
                        </div>
                    </fieldset>
                </form>
            </div>
        )
    }
}


const mapState = (state: IStoreReducers) => ({
  user: state.user  
})
export default connect(mapState, {})(CommentsComponent);