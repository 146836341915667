import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { ITransaction, IUser } from '../../../interfaces'
import { transactionService } from '../../../services'
import { AxiosResponse } from 'axios'

interface IProps {
    userType: string
    user?: IUser
    showSellerDetails: boolean
    onClose: Function
    transaction?: Array<ITransaction>
    item?: ITransaction
}
export const UserDetailPopup = ({ userType, user, showSellerDetails, transaction, onClose, item }: IProps) => {

    const [userTransaction, setUserTransaction] = useState<Array<ITransaction>>()

    useEffect(() => {
        transactionService.getUserTransactions(user?._id).then((res: AxiosResponse<any>) => {
            let data = [...res.data.response]
            setUserTransaction(data.reverse())
        })
    }, [])

    const getSaleTransaction = () => {
        let completed = userTransaction?.filter((item: ITransaction) => item.sellerID._id === user?._id)
        return completed?.length || 0
    }

    const getPurchaseTransaction = () => {
        let completed = userTransaction?.filter((item: ITransaction) => item.buyerID._id === user?._id)
        return completed?.length || 0
    }

    const getDisputedTransactions = () => {
        let completed = userTransaction?.filter((item: ITransaction) => item.disputed.status)
        return completed?.length || 0
    }

    const getUnresolveDisputedTransactions = () => {
        let completed = transaction?.filter((item: ITransaction) => !item.disputed.resolve)
        return completed?.length || 0
    }

    return (<div id="at-themeaddactionpopup" className={`at-themepopupholder at-buyerdetail-popup ${showSellerDetails && 'at-showthemesignuppopup'}`}>
        <div className="at-themepopupbox  text-left">
            <a className="at-close cursor-pointer" id="at-close" onClick={() => onClose()}><i className="icon-cancel"></i></a>
            <div className="at-addactionholder">
                <div className="at-formthemehead">
                    <h2>{userType} Details</h2>
                </div>
                <div className="at-buyer-greybox">
                    <p>Name</p>
                    <h6 className="mb-3">{`${user?.firstName} ${user?.lastName}`}</h6>
                    {user?.zipcode && <><p>Shipping Address</p>
                        <h6>{`${user.address} ${user.city}, ${user.state} ${user.zipcode}`}</h6></>}
                </div>
                <div className="at-buyer-bottombox">
                    <p className="para-fontlight"> Transaction Metrics</p>
                    <ul>
                        <li>
                            <p>Number of Sales</p>
                            <h3>{getSaleTransaction()}</h3>
                        </li>
                        <li>
                            <p>Number of Purchase</p>
                            <h3>{getPurchaseTransaction()}</h3>
                        </li>
                        <li>
                            <p>Number of Disputes Filed</p>
                            <h3>{getDisputedTransactions()}</h3>
                        </li>
                        {/* <li>
                            <p>Number of unresolved Disputed filed</p>
                            <h3>{getUnresolveDisputedTransactions()}</h3>
                        </li> */}
                    </ul>
                </div>

            </div>
        </div>
    </div>)
}