import React, {Component} from 'react';
import {images} from "../../assets/image";
import { RouteComponentProps } from "react-router-dom";
import {routes} from "../../router";

interface IHomeMyCentralProps extends RouteComponentProps {

}
class HomePage extends Component<IHomeMyCentralProps> {
    render() {
        return (
            <div className="at-sectionspace at-haslayout at-homeone at-contentarea">
					<div className="at-themehead">
						<a onClick={() => this.props.history.push(routes.home)} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
						<h2>Home Screen</h2>
					</div>
					<div className="at-themecontentbox">
						<figure className="at-globeicon">
							<a  onClick={() => this.props.history.push(routes.myCentral.spaces)} >
								<img src={images.hubsAndSpaces.globeImg} alt=""/>
								<map name="image-map">
									<area target="" alt="" title="" href="home.html" coords="101,99,98" shape="circle" />
								</map>
							</a>
						</figure>
					</div>
				</div>
        );
    }
}

export default HomePage;