import React, { Component } from 'react';
import { connect } from 'react-redux';
import { likeAction, unLikeAction, likeUserAction, unLikeUserAction } from '../../../store/actions';
import { postService } from '../../../services/post.service';
import { IUser, IStoreReducers } from '../../../interfaces';
import { userService } from '../../../services';

interface IComponentProps {
    postId: string
    userId: string
    user?: IUser
    likeAction?: any
    unLikeAction?: any
    likeUserAction?: any
    unLikeUserAction?: any
}

const HeartComponent = (props: IComponentProps) => {

    const isFavorit = (props.user && props.user.favoriteArtist || []).some((f: any) => f === props.userId);

    const likeUnlike = async () => {
        isFavorit ? props.unLikeUserAction(props.userId) : props.likeUserAction(props.userId)
        userService.likeUnlike(props.userId, !isFavorit).then()

    }

    return (
        (props.user && props.user._id) ? <a onClick={likeUnlike} title="Preferred" className="at-btnheart at-postbtn cursor-pointer">
            <i className={`icon-favorite${isFavorit ? '-2' : ''}`}></i>
        </a> : <span></span>
    )
}


const mapState = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapState, { likeAction, unLikeAction, likeUserAction, unLikeUserAction })(HeartComponent)