import { AxiosResponse } from 'axios'
import React, { Component, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingComponent from '../../components/common/Loading.component'
import { PrintingTransactionLayout } from '../../components/User/Transactions/PrintingTransactionLayout'
import { ITransaction } from '../../interfaces'
import { transactionService } from '../../services'
import { userSelector } from '../../store/selectors'
const PrintingAndFramingTransactionsPage = () => {
    const history = useHistory()
    const user = useSelector(userSelector)
    const [transactions, setTransactions] = useState<Array<ITransaction>>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        transactionService.getProviderTransactions(user._id).then((res: AxiosResponse<any>) => {
            let data = [...res.data.response]
            setLoading(false)
            setTransactions(data.reverse())
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Printing {'&'} Framing Transactions</h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    {transactions && transactions.length > 0 && transactions.map((t: ITransaction) => <PrintingTransactionLayout transaction={t} />)}
                </div>
                {loading && <LoadingComponent className='at-transaction-loader' />}
            </div>
        </main>
    )
}

export default PrintingAndFramingTransactionsPage