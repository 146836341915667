import React, { Component } from 'react'
import { images } from '../../../../assets/image'
import { routes } from '../../../../router'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { ICollection, ICollectionItem, IStoreReducers, IUser, IArtAssignment } from '../../../../interfaces'
import { collectionService } from '../../../../services/collection.service'
import { AxiosResponse } from 'axios'
import { collectionImage } from '../../../../shared'
import LoadingComponent from '../../../common/Loading.component'
import { connect } from 'react-redux'
import { ConfirmAlertComponent } from '../../../common/ConfirmAlert.component'
import { toastService, assignmentService } from '../../../../services'
import { lang } from '../../../../locale'
import AssignedCollectionComponent from './AssignedCollection.Componend'
import { refreshUser } from '../../../../store/actions'
import { NoDataComponent } from "../../../common/NoData.component"
import { ArtItemImageComponent } from '../../../ArtCentral/collection/ArtItemImage.component'
import { IResponse } from '../../../../interfaces/db.interface'

interface IProps extends RouteComponentProps {
    event: any
    title?: any
    user?: IUser
    onClickCollection: Function
    onEditCollection: Function
    refreshUser: Function
    onClickOnItem: Function
}

interface IComponentState {
    loading: boolean
    collections: Array<ICollection>,
    assignments: Array<IArtAssignment>
}
class AssignedCollectionGalleryComponent extends Component<IProps> {

    state: IComponentState = {
        collections: [],
        loading: false,
        assignments: []
    }

    componentDidMount() {
        this.fetch();
        // if (this.props.user?.isAgent) {
        //     this.fetchAssignedArts()
        // }
    }

    toggleLoading = (): void => {
        this.setState({ loading: !this.state.loading, openConfirmBox: false })
    }

    fetchAssignedArts = () => {
        return assignmentService.list().then((response: AxiosResponse<IUser>) => {
            this.setState({ assignments: response.data.assignments })
            // setAssignments(response.data.assignments || [])
            // setCollections(response.data.assignment_collections || [])
            // dispatch(refreshUser())
        })
    }

    fetch = (): void => {
        let { user } = this.props
        this.toggleLoading();
        this.props.event(user?._id).then((response: AxiosResponse<Array<ICollection>>) => {
            this.setState({ collections: [...response.data], loading: false })
            this.props.refreshUser();
        }, (error: any) => {
            this.toggleLoading();
        })
    }

    onClick = ({ id, art }: IArtAssignment): any => {
        this.props.history.push(`${routes.agent.showAssigment}/${id}`)
    }

    onMoveUp = (index: any) => {
        if (index !== 0) {
            let items = this.state.collections;
            let temp = items[index];
            items[index] = items[index - 1];
            items[index - 1] = temp;
            this.setState({
                collections: items
            });
            let data = items.map((item: any) => item.id)
            this.UpdatingCollectionSequence(data)
        } else {

            toastService.error("Unable to move the collection Upword")
        }
    }
    onMoveDown = (index: any) => {
        let items = this.state.collections;
        if (index !== items.length - 1) {
            let temp = items[index];
            items[index] = items[index + 1];
            items[index + 1] = temp;
            this.setState({
                collections: items
            });
            let data = items.map((item: any) => item.id)
            this.UpdatingCollectionSequence(data)
        } else {
            toastService.error("Unable to move the collection Downward")
        }
    };

    UpdatingCollectionSequence = (data: Array<any>) => {
        let user = (this.props.user as IUser)
        collectionService.updateUserCollectionSequence(user._id, { collections: data }).then((res: AxiosResponse<IResponse<any>>) => {
        }).catch(err => console.log(err))
    }



    render() {
        const { loading, collections, assignments } = this.state;
        const { title, user } = this.props;
        return (
            <div className="at-collectiongallery">
                {user?.isAgent && assignments.length > 0 && <div className="at-formtheme at-formartcollection pt-4">
                    <fieldset className="at-artcollectioncontent">
                        <div className="form-group">
                            <h3>
                                {/* {selectionOn()  ? `Selected ${selectionOn().length}/${assignments.length} Images` : ''} */}
                                Assigned Art
                            </h3>
                        </div>
                    </fieldset>
                    <fieldset className="at-artgallerycollections at-themescrollbar">
                        <ul className="at-gallery">
                            {this.state.assignments.map((item: any) =>
                            (<li key={item.id} onClick={() => this.onClick(item)}>
                                <img src={collectionImage(item.art, `high_${item.art.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(item.art, item.art.url || '', true)})` }} />
                            </li>)
                            )}
                        </ul>
                    </fieldset>
                </div>}
                <div className="col-md-12">
                    {
                        title && (
                            <div className="at-manageposttitle">
                                <h2>{title}</h2>
                            </div>
                        )
                    }
                    {
                        loading && <LoadingComponent />
                    }
                </div>
                {
                    collections.map((c: ICollection, index: any) => {
                        
                            return <AssignedCollectionComponent key={c.id} {...this.props} collection={c} refreshList={this.fetch} onMoveDown={this.onMoveDown} onMoveUp={this.onMoveUp} index={index} />
                        
                    })
                }
            </div>
        )
    }
}



export default connect(null, {refreshUser})(withRouter(AssignedCollectionGalleryComponent));