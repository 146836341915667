import { HttpService } from "./base.service";

class PostService extends HttpService {
    private readonly prefix: string = 'post';

    /**
     * Posts List
     * @param data
     */
    list = (params: any): Promise<any> => this.get(this.prefix, params);

    listWithoutLogin = (params: any): Promise<any> => this.get(`${this.prefix}/without-login`, params);

    /**
     * My Posts
     * @param data
     */
    myPosts = (id?:string): Promise<any> => this.get(`${this.prefix}/personal`,{id});
    /**
     * My Posts
     * @param data
     */
    userPosts = (id:string): Promise<any> => this.get(`${this.prefix}/user-posts/${id}`);

    /**
     * Post Comments
     * @param data
     */
    comments = (id: string): Promise<any> => this.get(`${this.prefix}/comments/${id}`);

    /**
     * View Post
     */
    show = (id: string): Promise<any> => this.get(`post-detail/${id}`);

    /**
     * Delete Post
     */
    deletePost = (id: string): Promise<any> => this.delete(`${this.prefix}/${id}`);

    /**
     * Create Post
     * @param data
     */
    add = (data: any): Promise<any> => this.post(this.prefix, data);
    
    /**
     * Update Post
     * @param data
     */
    update = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/${id}`, data);

    /**
     * Attach Images
     * @param data
     */
    attachImages = (id: string, data: any, options = {}): Promise<any> => this.post(`${this.prefix}/attach-post-images/${id}`, data, options);

    /**
     * Like and Unlike Post
     */
    likeUnlike = (id: string, status: boolean): Promise<any> => this.put(`${this.prefix}/like-unlike/${id}/${status ? 1 : 0}`)

    /**
     * Post Comment
     */
    addComment = (postId: string, data: { comment: string}): Promise<any> => this.post(`${this.prefix}/comment/${postId}`, data);

}

export const postService = new PostService();
