import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { EArtistPrintType, EPrintType } from '../../../enums'
import { ICollectionItem, ICollectionItemState, IFormates, IUser } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { IArtistSelectedService, IArtistServicesState, initArtistServiceState, ISelectedService, ISelectedServiceState } from '../../../interfaces/services.interface'
import { consultantService } from '../../../services/consultantServices.service'
import { AddArtFileWithFrame } from '../../User/Services/PrintingServices/Modal/AddArtFileWithFrame'
import { AddArtFileWithoutFrame } from '../../User/Services/PrintingServices/Modal/AddArtFileWithoutFrame'
import { SeeServicePricing } from '../../User/Services/PrintingServices/Modal/SeeServicePricing.modal'
import { SelectPrintingModal } from '../../User/Services/PrintingServices/Modal/SelectPrinting.modal'
import { SelectPrintMedium } from '../../User/Services/PrintingServices/Modal/SelectPrintMedium.modal'
import { SelectPrintMediumWithFrame } from '../../User/Services/PrintingServices/Modal/SelectPrintMediumWithFrame.modal'
import { ArtistOwnPrinter } from '../../User/Services/PrintingServices/Modal/UserOwnPrinter.modal'

interface IProps {
    format: IFormates
    art: ICollectionItemState
    onSubmitAddService: Function
    onClose: Function
}
export const ArtServices = ({ format, art, onSubmitAddService, onClose }: IProps) => {

    const [selectedPrinter, setSelectedPrinter] = useState<IUser>()
    const [component, setComponent] = useState<string>('SEE_PRINTERS')
    const [state, setState] = useState<IArtistServicesState>({ ...initArtistServiceState })
    const [loading, setLoading] = useState<boolean>(false)
    const [own, setOwn] = useState<boolean>(false)

    const onSeePrinterDetails = (p: IUser) => {
        setComponent('SEE_PRINTER_DETAIL')
        setSelectedPrinter(p)
        setOwn(false)
    }

    const onSelectPrinter = (p: IUser) => {
        if (selectedPrinter && selectedPrinter._id === p._id) {
            setSelectedPrinter(undefined)
            setOwn(false)
            return
        }
        setSelectedPrinter(p)
        setOwn(false)
    }

    const onClickingSelectPrinter = () => {
        if (own) {
            onChoseYoureOwnService()
            return
        }
        setComponent('SELECTED_PRINTER_DETAIL')
    }
    const onClickChoseOwn = () => {
        setOwn(!own)
        setSelectedPrinter(undefined)
    }
    const onChoseYoureOwnService = () => {
        setComponent('SEE_OWN_SERVICE_PAGE')
    }

    const onAdd = (data: IArtistServicesState) => {
        setComponent('SEE_ART_UPLOAD_PAGE')
        setState({
            ...state,
            ...data
        })
    }

    const onUpdateData = (image?: any, artistOwn?: any) => {
        setLoading(true)
        let data = new FormData()
        if (image) data.append('file', image)
        if (state.selectedServices && state.selectedServices.length > 0) {
            let selectedServices = state.selectedServices.map((s: ISelectedServiceState) => {
                return {
                    _id: s._id,
                    printingService: s.printingService._id,
                    framingService: s.framingService && s.framingService._id ? s.framingService._id : null,
                    selectedSize: s.selectedSize._id,
                    selectedPrintrSevicePrice: s.selectedPrintrSevicePrice._id,
                    selectedFrameSevicePrice: s.selectedFrameSevicePrice && s.selectedFrameSevicePrice._id ? s.selectedFrameSevicePrice._id : null,
                    totalCost: s.totalCost,
                    artistChoice: s.artistChoice
                }
            })
            data.append('selectedServices', JSON.stringify(selectedServices))
            data.append('printType', state.printType)
        }

        if (artistOwn && artistOwn.price && own) {
            setState({
                ...state,
                artistOwnService: artistOwn
            })
            data.append('artistOwnService', JSON.stringify(artistOwn))
            data.append('printType', EArtistPrintType.OWN)
        }

        if (state.serviceProvider) data.append('serviceProvider', state.serviceProvider)
        if (format && format._id) data.append('format', format._id)
        if (art && art.id) data.append('art', art.id)
        if (state._id) data.append('id', state._id)

        consultantService.addSelectedServiceData(data).then((res: AxiosResponse<IResponse<any>>) => {
            onSubmitAddService(res.data.data)
            setState({
                ...state,
                ...res.data.data
            })
            setLoading(false)
        })

    }

    const fetchCurrentSelectedService = () => {
        if (!format) return
        setLoading(true)

        consultantService.getSelectedServiceById((format.selectedService as IArtistSelectedService)._id).then((res: AxiosResponse<IResponse<IArtistSelectedService>>) => {
            if (res.data.data) {
                setState({
                    ...state,
                    ...res.data.data as any
                })

                setSelectedPrinter({ ...res.data.data.serviceProvider })
                if (res.data.data.printType === EArtistPrintType.FRAMED || res.data.data.printType === EArtistPrintType.FRAMED) {
                    onClickingSelectPrinter()
                }
                if (res.data.data.printType === EArtistPrintType.OWN) {
                    onClickChoseOwn()
                    onChoseYoureOwnService()
                }
            }
            setLoading(false)
            // if (res.data.data.printType === EArtistPrintType.OWN) {
            //     onClickingSelectPrinter()
            // }
        })
    }

    useEffect(() => {
        console.log("run:outer")
        if (format && format.selectedService) {
            console.log("run:smkmck")
            fetchCurrentSelectedService()
        }
    }, [format.selectedService])

    const renderComponent = () => {
        switch (component) {
            case 'SEE_PRINTERS':
                return <SelectPrintingModal onClickChoseOwn={onClickChoseOwn} own={own} onSeePrinterDetails={onSeePrinterDetails} onChoseYoureOwnService={onChoseYoureOwnService} selectedPrinter={selectedPrinter} onSelectPrinter={onSelectPrinter} format={format} onClickingSelectPrinter={onClickingSelectPrinter} />
            case 'SEE_PRINTER_DETAIL':
                return format.printType && format.printType === EPrintType.FRAMED ? <SeeServicePricing selectedPrinter={selectedPrinter} format={format} onClickingSelectPrinter={onClickingSelectPrinter} /> : <SeeServicePricing onlyPrinter={true} selectedPrinter={selectedPrinter} format={format} onClickingSelectPrinter={onClickingSelectPrinter} />
            case 'SELECTED_PRINTER_DETAIL':
                return format.printType && format.printType === EPrintType.FRAMED ? <SelectPrintMediumWithFrame parentState={state} selectedPrinter={selectedPrinter} format={format} onAdd={onAdd} onUpdateData={onUpdateData} /> : <SelectPrintMedium selectedPrinter={selectedPrinter} format={format} onAdd={onAdd} parentState={state} />
            case 'SEE_ART_UPLOAD_PAGE':
                return format.printType && format.printType === EPrintType.FRAMED ? <AddArtFileWithFrame state={state} onUpdateData={onUpdateData} loading={loading} /> : <AddArtFileWithoutFrame state={state} onUpdateData={onUpdateData} loading={loading} />
            case 'SEE_OWN_SERVICE_PAGE':
                return <ArtistOwnPrinter parentState={state} onUpdateData={onUpdateData} onClose={onClose} loading={loading} />
            default:
                return null
        }
    }

    return renderComponent()
}