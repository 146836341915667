import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AddTemplateData } from '../../components/InPlatformWebsiteComponent/AddTemplateData.component'
import { SelectTemplateComponent } from '../../components/InPlatformWebsiteComponent/SelectTemplateComponent'
import { SelectUrl } from '../../components/InPlatformWebsiteComponent/SelectUrl.component'
import { initTemplateState, ITemplateState } from '../../interfaces/user-template.interface'
import { toastService } from '../../services'
import { userTemplateService } from '../../services/userTemplates.service'
import { FetchTemplateData, refreshUser, setInPlatformWebState } from '../../store/actions'
import { userSelector } from '../../store/selectors'
import { getCompleteUrlWithDomain } from '../../shared';
import { IUser } from '../../interfaces';
import { ETemplate } from '../../enums';
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon';
import { EScreens } from '../../enums/screens.enum';
import { UniversalModal } from '../../components/common/Modals/Universal.modal';
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial';
import { routes } from '../../router';

interface IProps {
    routeUser?: IUser
    hideBackButton?: boolean
}
const InPlatformWebsite = ({ routeUser, hideBackButton }: IProps) => {
    const [comp, setComp] = useState<number>(0)
    const [routeArray, setRouteArray] = useState<Array<number>>([])
    const [didUpated, setDidUpdate] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [state, setState] = useState<ITemplateState>({ ...initTemplateState })
    const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false)
    const storeUser = useSelector(userSelector)
    const user = routeUser && routeUser._id ? routeUser : storeUser
    const dispatch = useDispatch()


    const fetchTemplate = useCallback(async () => {
        setLoading(true)
        let res: any = await dispatch(FetchTemplateData({ user: user._id }))
        setState({
            ...state,
            ...res
        })
        setLoading(false)
        // userTemplateService.getUserTemplate(user._id).then((res: AxiosResponse<any>) => {
        //     if (res.data.success) {
        //         setState({
        //             ...state,
        //             ...res.data.data
        //         })
        //     }
        //     setLoading(false)

        // }).catch((err: any) => {
        //     console.log(err)
        //     setLoading(false)

        // })
    }, [])
    const onSelectComponent = (page: number) => {
        if (loading) return
        setComp(page)
    }

    const setRoute = () => {
        let array = routeArray
        array.push(comp)
        setRouteArray([...array])
    }


    const onGoBack = () => {
        // let currPage = routeArray[routeArray.length - 1]
        setComp(0)
        let array = routeArray
        array.pop()
        setRouteArray([...array])
    }

    const setData = (data: any) => {
        setState({
            ...state,
            ...data
        })
        onGoBack()
    }

    useEffect(() => {
        if (comp) {
            setDidUpdate(!didUpated)
        }
    }, [comp])

    useEffect(() => {
        fetchTemplate()
    }, [])

    const onSubmit = () => {
        if (!validateData()) return
        let data = new FormData()
        let { homeImage, galleryImage, logoImage, locationImage, homeInformation, contactInformation, template, url, backgroundColor, textColor, aboutImage, lineColor, aboutDescription, font } = state
        if (state._id) data.append('id', state._id)
        data.append('homeImage', homeImage)
        data.append('galleryImage', galleryImage)
        data.append('logoImage', logoImage)
        data.append('locationImage', locationImage)
        data.append('aboutImage', aboutImage)
        data.append('homeInformation', JSON.stringify(homeInformation))
        data.append('contactInformation', JSON.stringify(contactInformation))
        data.append('template', template)
        data.append('url', url)
        data.append('backgroundColor', backgroundColor)
        data.append('textColor', textColor)
        data.append('lineColor', lineColor)
        data.append('aboutDescription', aboutDescription)
        data.append('user', user._id)
        data.append('font', JSON.stringify(font))

        setLoading(true)
        userTemplateService.add(data).then((res: AxiosResponse<any>) => {
            toastService.success(res.data.message)
            fetchTemplate()
            dispatch(refreshUser())
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const validateData = () => {
        let { homeImage, galleryImage, logoImage, homeInformation, contactInformation, locationImage, url, template } = state

        if (!url) {
            toastService.error('Template Url is required')
            return false
        }

        if (!state.template) {
            toastService.error('Template is required')
            return false
        }

        if (!homeImage) {
            toastService.error('Template Information is required')
            return false
        }

        if (state.template === ETemplate.GALLERY_FORMAT && !galleryImage) {
            toastService.error('Template Information is required')
            return false
        }
        if (!logoImage) {
            toastService.error('Template Information is required')
            return false
        }
        if (!locationImage) {
            toastService.error('Template Information is required')
            return false
        }


        return true

    }


    const openTutorialBox = () => {
        setShowTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setShowTutorialBox(false)
    }

    const openViewWeb = () => {
        if (!state._id) return
        dispatch(setInPlatformWebState({
            nextPath: `${state.url}`,
            isActive: true,
            previousPath: routes.home
        }))
        history.push(routes.loadingRoutes)
    }



    const renderMain = () => (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>In-Platform Website <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.IN_PLATFORM_WEBSITE} onClick={openTutorialBox} /></span></h2>
                </div>
                <div className="at-inPlatform-main">
                    <div className="at-inPlatform-tabs">
                        <h4>
                            STEP 1:
                        </h4>
                        <h5>
                            Select URL
                        </h5>
                        <i className="icon-edit2 ml-auto" onClick={() => onSelectComponent(1)}></i>
                    </div>
                    <div className="at-inPlatform-tabs">
                        <h4>
                            STEP 2:
                        </h4>
                        <h5>
                            Select Template
                        </h5>
                        <i className="icon-edit2 ml-auto" onClick={() => onSelectComponent(2)}></i>
                    </div>
                    <div className="at-inPlatform-tabs">
                        <h4>
                            STEP 3:
                        </h4>
                        <h5>
                            Add Template Information
                        </h5>
                        <i className="icon-edit2  ml-auto" onClick={() => onSelectComponent(3)}></i>
                    </div>
                </div>

                <div className="form-group">
                    <button type="button" className="at-btn at-longbtn-h40" disabled={loading} onClick={onSubmit} >{loading ? 'Please wait...' : 'UPDATE WEBSITE'}</button>
                </div>
                <div className="at-roundbox-floatleft at-template-roundbox">
                    <h5 className="at-template-head">URL:</h5>
                    <div className="at-template-copy">
                        {state.url &&
                            <CopyToClipboard text={getCompleteUrlWithDomain(state.url, true)} onCopy={() => toastService.info('Copied to clipboard')}>

                                <p id='url'><span>{getCompleteUrlWithDomain(state.url, true)}</span><i className="icon-copy at-icon-copy"></i></p>

                            </CopyToClipboard>
                        }
                        {/* <p>test url</p> */}
                    </div>
                    <h5 className="at-template-head">Active Template:</h5>
                    {state.template && <p>{state.template.replace(/_/g, ' ')}</p>}
                </div>
                <div className="at-link-ownwebsite">
                    <a className="at-orangelink at-orangelink-lg" onClick={() => openViewWeb()}>View Website</a>
                </div>
                {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={closeTutorialBox} position='bottom-0'>
                    <ScreenTutorial screen={EScreens.IN_PLATFORM_WEBSITE} />
                    {/* at-categorybox ends */}
                </UniversalModal>
                }
            </div>
        </main>
    )

    const renderComponent = () => {
        switch (comp) {
            case 1:
                return <SelectUrl data={state.url} setData={setData} goBack={onGoBack} />
            case 2:
                return <SelectTemplateComponent user={user} templateState={state} data={state.template} setData={setData} goBack={onGoBack} />
            case 3:
                return <AddTemplateData
                    data={state}
                    setData={setData}
                    goBack={onGoBack} />
            default:
                return renderMain();
        }
    }

    const history = useHistory()
    return (
        renderComponent()
    )
}

export default InPlatformWebsite