import { AxiosResponse } from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { IUser } from '../../interfaces'
import { ISubscriptionBilling, ISubscriptionInvoice } from '../../interfaces/subscription.interface'
import { subscriptionService, toastService } from '../../services'
import { CurrencyFormat } from '../common/CurrencyFormat'
import LoadingComponent from '../common/Loading.component'
import { UniversalModal } from '../common/Modals/Universal.modal'
import { UpdateCardForm } from '../Stirpe'

interface IProps {
    user: IUser
}
export const Billing = ({ user }: IProps) => {

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
    const [billing, setBilling] = useState<ISubscriptionBilling>()
    const [loading, setLoading] = useState<boolean>(false)
    const openUpdateModal = () => setShowUpdateModal(true)
    const closeUpdateModal = () => setShowUpdateModal(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        subscriptionService.getSubscriptionInvoice(user._id).then((res: AxiosResponse<ISubscriptionBilling>) => {
            setBilling({ ...res.data })
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onUpdateCard = (source: any) => {
        let data = {
            userId: user._id,
            sourceToken: source.id
        }
        setLoading(true)
        subscriptionService.updateUserCard(data).then((res: AxiosResponse<any>) => {
            if (!res.data.success) {
                toastService.error(res.data.message)
                setLoading(false)
                return
            }
            toastService.success(res.data.message)
            closeUpdateModal()
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    return (
        <div className="at-roundcontainer clearfix">
            {billing?.success && <div>
                <h6 className="at-color-grey">Next Billing</h6>
                <div className="row">
                    <div className="col-6">
                        <p className="mb-0">Amount</p>
                    </div>
                    <div className="col-6">
                        <p className="mb-0">Date</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-6">
                        <p><CurrencyFormat amount={billing?.nextInvoiceData.amount} /></p>
                    </div>
                    <div className="col-6">
                        <p>{moment.unix(billing?.nextInvoiceData.date).format('L')}</p>
                    </div>
                    <div className="col-12 mb-4">
                        <button className="at-btn at-longbtn-h40" onClick={openUpdateModal}>Update Credit Card Information</button>
                    </div>
                </div>
                <h6 className="at-color-grey">Billing History</h6>
                <div className="row">
                    <div className="col-6">
                        <p className="mb-0">Amount</p>
                    </div>
                    <div className="col-6">
                        <p className="mb-0">Date</p>
                    </div>
                </div>
                <hr />
                {billing?.invoiceData.length > 0 && billing?.invoiceData.map((i: ISubscriptionInvoice, index: any) => {
                    return (
                        <div className="row" key={index}>
                            <div className="col-6">
                                <p><CurrencyFormat amount={i.amount} /></p>
                            </div>
                            <div className="col-6">
                                <p>{moment.unix(i.date).format('L')}</p>
                            </div>
                        </div>
                    )
                })}
                {/* <div className="row">
                    <div className="col-6">
                        <p>$29.00</p>
                    </div>
                    <div className="col-6">
                        <p>11/20/2020</p>
                    </div>
                </div> */}
            </div>}
            {showUpdateModal && <UniversalModal open={showUpdateModal} onClose={closeUpdateModal} position='bottom'>
                <h5 className="at-popupheading">Update Credit Card Information</h5>
                <UpdateCardForm text='Update' onClick={onUpdateCard} />
            </UniversalModal>}
            {loading && <LoadingComponent className='at-transaction-loader' />}
        </div>
    )
}