import React, { Component, ReducerState } from 'react'
import { connect } from 'react-redux'
import { AxiosResponse } from 'axios'
import { images } from '../assets/image'
import { NotificationAction, NotificationCountAction, notificationService } from '../services'
import { IStoreReducers, IUser, INotification } from '../interfaces'
import { RouteComponentProps } from 'react-router-dom'
import { UserPicComponent } from '../components/User/index.component'
import moment from 'moment'
import { NotitcationLayOut } from '../components/User/Noitification.component'
import { Subscription } from 'rxjs'
import LoadingComponent from '../components/common/Loading.component'
import { collectionImage } from '../shared'
import { STRIPE_ACCOUNT } from '../constants'
import { routes } from '../router'
import { RETURN_OF_ART_NOTICE } from '../constants/index'
import { IResponse } from '../interfaces/db.interface'

interface IProps extends RouteComponentProps {
    user: IUser
}

interface IState {
    notifications: Array<INotification>,
    unreadCount: number
    loading: boolean
}
class NotificationPage extends Component<IProps> {

    state: IState = {
        notifications: [],
        unreadCount: 0,
        loading: false
    }
    subcription = new Subscription()

    componentDidMount() {
        let { user } = this.props
        this.setState({ loading: true })
        notificationService.getNotifications(user._id).then((res: AxiosResponse<IResponse<Array<INotification>>>) => {
            this.setState({ notifications: res.data.data.reverse(), loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
            console.log(err)
        })
        NotificationCountAction.triggerAction(0)
    }

    onClikNotification = (n: INotification): void => {
        if (n.url) {
            let url = n.url.split(".com")[1]
            this.props.history.push(url)
        }
        if (n.type === STRIPE_ACCOUNT) {
            this.props.history.push(routes.account.connectedWithStripe)
        }
    }

    componentWillMount() {
        const sub = NotificationAction.listen.subscribe((status: Object) => {
            let newState = [status].concat(this.state.notifications)
            this.setState({ notifications: newState })
        })
        this.subcription.add(sub)
    }

    componentWillUnmount() {
        this.subcription.unsubscribe()
    }

    handleReadCounts = () => {
        let { notifications } = this.state
        let count = notifications?.filter((item: INotification) => !item.read)
        return count.length
    }

    handleAdminNotification = () => {
        const canvas = document.createElement('canvas')
        canvas.style.display = 'none'
        canvas.width = 200
        canvas.height = 200
        document.body.appendChild(canvas)
        const context = canvas.getContext('2d')
        if (context) {
            context.fillStyle = '#92A8B5'
            context.fillRect(0, 0, canvas.width, canvas.height)
            context.font = '50px Helvetica'
            context.fillStyle = '#FBFAF9'
        }
        let name = 'Admin'

        context?.fillText(
            name,
            canvas.width / 6,
            canvas.height / 1.6
        )

        const data = canvas.toDataURL()
        document.body.removeChild(canvas)
        return data
    }

    layout = (n: INotification) => {
        return (
            <div className="at-transaction-wrap">
                <div className="at-transaction-box">
                    <div className="at-transact-left">
                        <div className="at-transact-line">
                            <p>{moment(n.createdAt).format("L")}</p>
                            <p className="pl-5">{moment(n.createdAt).format("hh:mm:ss a")}</p>
                        </div>
                        <div className="at-transact-line">
                            <p>Seller:</p>
                            <p className="pl-2 text-capitalize"><span>{n.from.firstName} {n.from.lastName}</span></p>
                        </div>
                        <div className="at-transact-line" >
                            <p>Buyer:</p>
                            <p className="pl-3 text-capitalize"><span>{`${n.to.firstName} ${n.to.lastName}`}</span></p>
                            {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                        </div>
                        <div className="at-transact-line pt-2">
                            {/* <p>Art:<span> {getItemType(item)}</span></p> */}
                        </div>
                        <div className="at-transact-line pt-2">
                            {/* <p>Price:<span><CurrencyFormat amount={item.price} /></span></p>
                                        <p className="pl-2">Shipping: <span><CurrencyFormat amount={item.shippingPrice} /></span></p>
                                        <p className="pl-2">Total: <span><CurrencyFormat amount={item.totalPrice} /></span></p> */}
                        </div>
                    </div>
                    {n.itemId && <div className="at-transact-right">
                        <figure>
                            <img src={collectionImage(n.itemId, `high_${n.itemId.url}`, true)} alt="" />
                        </figure>
                        <button className={`at-btn at-btn-sm at-btn-orange`}></button>
                    </div>}
                </div>
            </div>
        )
    }
    render() {
        const { notifications, loading } = this.state
        let adminPic = {
            firstName: 'Adm'
        }
        return (
            <div className="at-createcollection at-contentarea-two">
                <div className="at-createcollectionholder at-themescrollbar scrollable">
                    <div className="at-themehead">
                        <a onClick={() => this.props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                        <h2>Notifications <span>{`(${this.handleReadCounts()})`}</span>  </h2>
                    </div>
                    <div className="at-notifyholder">
                        {notifications && notifications.length > 0 ? (
                            <>
                                {
                                    notifications.map((n: INotification) => (
                                        n.from && n.from._id ? <div className="at-notifybox" key={n.id} onClick={() => this.onClikNotification(n)}>
                                            {!n.fromAdmin ? <UserPicComponent className="at-notifyfigure" user={n.from} /> :
                                                <figure className="at-notifyfigure">
                                                    <img src={this.handleAdminNotification()} alt="" />
                                                </figure>}
                                            <div className="at-notifytext">
                                                <p>{n.message}</p>
                                                {n.type === RETURN_OF_ART_NOTICE && n.transaction && n.transaction.return_art &&
                                                    <>
                                                        <div className="at-transact-line">
                                                            <p>Reason for Return: <span className="pl-2">{n.transaction?.return_art.reason}</span></p>
                                                        </div>
                                                        <div className="at-transact-line">
                                                            <p>Additional Information: <span className="pl-2">{n.transaction?.return_art.details}</span></p>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="at-transaction-main">
                                                <NotitcationLayOut notificationType={n.type} notification={n} />
                                            </div>
                                        </div> : null
                                    ))
                                }
                            </>
                        ) :
                            <>
                                {loading && <LoadingComponent />}
                                {!loading && (!notifications || notifications.length === 0) &&
                                    <div className="at-emptybox">
                                        <figure className="at-figure">
                                            <img src={images.emptyNotification} className="img-fluid" alt="" />
                                        </figure>
                                        <h4>No Notifications</h4>
                                    </div>
                                }
                            </>
                        }

                    </div>
                    {/* at-notifyholder ends */}

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(NotificationPage)
