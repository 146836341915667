import { IEnvironment } from "../enums"

export const NODE_ENV = IEnvironment.STAGING
export const APP_DOMAIN = 'https://staging-be.omnipresenti.com'
export const APP_URL = `https://staging.omnipresenti.com`
export const API_ENDPOINT = `${APP_DOMAIN}/api/v1`
export const CURRENT_VERSION = '3.beta.6.9'
export const PLATFORM_FEE = '10'
export const ADMIN_PHONE = "+14128774313"
export const CLOUDURL = 'https://dunapub38hyil.cloudfront.net'
export const AWS_ENDPOINT = 'https://omni-testing.s3.us-east-2.amazonaws.com'
export const AWS_IMAGE_FOLDER = 'images'
export const STRIPE_KEY = 'pk_test_51HZVbpCvhhHpDxMLaGsuUCpb5NuTYInn7goRGIk3Cy8drmIhQSJqvKEy9ETh2zcyo1PYTVT8ONOCRidoyEk2uQAb00pKtyAjDU'
export const THIRD_PARTY = {
	facebook: {
		appID: '588919188707106',
	},
	instagram: {
		appID: '1142995602709632',
		appSecret: '0b1c123482e6ca0f274a7f0ef770c199',
	},
	agora: {
		appID: '03093b1d7f0b45d0b1dd6f6114ea7b9b',
		appCertificate: 'c53b0c51c1e44966963a72df37b1b112',
	},
}
