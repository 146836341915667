import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../assets/image'
import ImageGallery from 'react-image-gallery';

const GalleryDetailPage = () => {
    const items = [{
        original: images.template.bannerTemplate1,
        thumbnail: images.template.bannerTemplate1,
    },
    {
        original: images.template.artDetailImg,
        thumbnail: images.template.artDetailImg,
    }]
    return (
        <div>
            <section className="At-SectionPage">
                <div className="At-ContainerFull">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <div className="At-BannerHolder">
                                <figure className="At-FigureBanner-h600 At-FigureFull">
                                    <img src={images.template.bannerTemplate3} className="img-fluid" alt="" />
                                </figure>
                                <div className="At-BannerTextFraming">
                                    <h2>Our Gallery</h2>
                                    <nav aria-label="breadcrumb ">
                                        <ol className="breadcrumb At-Breadcrumb">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item " ><a href="#">Gallery</a></li>
                                            <li className="breadcrumb-item active" >Street Wall Paint</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="At-SectionGalleryDetail At-Padding-tb8 At-Section-Mh800">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-md-6">
                            <figure>
                                <img src={images.template.consulting} className="img-fluid" alt="" />
                            </figure>
                        </div>
                        <div className="col-sm-8 col-md-6 At-FramingMainCol pl-5">
                            <div className="At-FramingTopContent">
                                <h3>Curate Collection</h3>
                                <p>Website analysis and strategic proposal /outline for creation or updating of website.
                                     Creative website design and development packages also available at very reasonable prices.</p>
                                <h3>Detail Information</h3>
                                <p>Website analysis and strategic proposal /outline for creation or updating of website. Creative website
                                    design and development packages also available at very reasonable prices.  </p>
                                <h3>Account Access Required</h3>
                                <ul>
                                    <li className="At-CollectLi">Collection</li>
                                    <li className="At-OffersLi">Offers</li>
                                </ul>
                                <p className="At-ColorOrangeImp">
                                    If you purchase this service, the consultant will have full access to edit, make changes and manage the functions listed above.
                                 </p>
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5>Pricing</h5>
                                    </div>
                                    <div className="col-6 text-right">
                                        <h4 className="mb-0 At-FontBold">$500.00</h4>
                                        <h6 className="At-ConsultingGreyText">Project Rate</h6>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <button className="At-Btn At-Btn-lg">Buy</button>
                                        <p className="At-ColorGrey text-center">A $10.00 platform fee will be added to your price.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default GalleryDetailPage