import React from 'react'
interface IProps {
    open: boolean
    title?: string
    message?: string
    onCancel: any
    onConfirm?: any
    cancelTitle?: string
    confirmTitle?: any
    onClose?: any
    closeable?: boolean
    loading?: boolean
}

export const ConfirmAlertComponent = (props: IProps) => {
    const messages = {
        title: 'Confirm!',
        message: 'Are you sure you want to perform this action?'
    }
    const { open, title, message, onCancel, onConfirm, cancelTitle, confirmTitle } = props;
    const cancelText = cancelTitle ? cancelTitle : 'Cancel';
    const confirmText = confirmTitle ? confirmTitle : 'Confirm';
    return (
        open ? <div id="at-themerecoverycodepopup" className={`at-themepopupholder at-popupfixedbottom at-showthemerecoverycodepopup`}>
            <div className="at-themepopupbox">
                <div className="row confirmbox">
                    {props.closeable && <a className="at-close cursor-pointer" id="at-close" onClick={props.onClose}><i className="icon-cancel"></i></a>}
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h3>{title ? title : messages.title}</h3>
                        <p>{message ? message : messages.message}</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <button type="button" className="btn btn-secondary btn-block" onClick={onCancel}>{cancelText}</button>
                        </div>
                    </div>
                    {
                        onConfirm && <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <button type="button" disabled={props.loading} className="at-btn at-btn-orange at-btn-buyoriginal btn-block" onClick={onConfirm}>{props.loading ? 'Please wait...' : confirmText}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div> : <div></div>
    )
}
