import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Subscription } from 'rxjs'
import { UserLoginAction } from '../../services'
import ForgotPasswordComponent from '../Authentication/ForgotPassword.component'
import ResetPasswordComponent from '../Authentication/ResetPassword.component'
import SigninComponent from '../Authentication/Signin.component'
import SignupComponent from '../Authentication/Signup.component'
import VerificationCodeComponent from '../Authentication/VerificationCode.component'

interface IProps extends RouteComponentProps {

}
interface IState {
    showSignup: boolean,
    showSignin: boolean,
    showForgotPassword: boolean,
    showVerificationCode: boolean,
    showResetPassword: boolean,
    phoneNumber: string,
    pin: string,
}

const initialtState = {
    showSignup: false,
    showSignin: false,
    showForgotPassword: false,
    showVerificationCode: false,
    showResetPassword: false,
    phoneNumber: '',
    pin: '',
}
const AuthModals = (props: IProps) => {
    const [state, setState] = useState({ ...initialtState })
    const loginSubscription = new Subscription();
    const close = (): void => {
        console.log("close all subs")

        setState({
            ...state,

            showSignup: false,
            showSignin: false,
            showForgotPassword: false,
            showVerificationCode: false,
            showResetPassword: false,
        })
        UserLoginAction.triggerAction(false)
    }

    const openSignup = (): void => {
        close()
        setState({
            ...state,
            showSignin: false,
            showSignup: true
        })
    }

    const openSignin = (): void => {
        close()
        setState({
            ...state,
            showSignin: true,
            showSignup: false,
            showForgotPassword: false,
        })
    }

    const openForgotPassword = (): void => {
        close()
        setState({
            ...state,
            showForgotPassword: true,
            showSignin: false,
        })
    }

    const openVerificationCode = (phoneNumber: string): void => {
        close()
        setState({
            ...state,
            phoneNumber, showVerificationCode: true
        })
    }

    const openResetPassword = (pin: string): void => {
        close()
        setState({
            ...state,
            showResetPassword: true, pin
        })
    }

    useEffect(() => {

        const userLogin = UserLoginAction.listen.subscribe((status: boolean) => setState({ ...state, showSignin: status }))
        loginSubscription.add(userLogin)

        return () => {
            loginSubscription.unsubscribe()
        }
    }, [])

    // openSubscriptionPopup = (res: any) => {
    //     let u: IUser = res.user
    //     this.close()
    //     if (u.roles.length === 0 || u.roles[0].length === 0) {
    //         toastService.info("Please wait for account approval")
    //         return
    //     }
    //     if (u.phoneNumber === ADMIN_PHONE) {
    //         return
    //     }
    //     this.setState({ response: res, showSubscriptionPopup: true })
    // }

    // closeSubscriptionPopup = (subsLevel: string) => {
    //     let { response } = this.state
    //     if (!subsLevel) {
    //         toastService.info("Please wait for account approval")
    //     } else {
    //         let user: IUser = response?.user
    //         toastService.info(`Welcome, ${user.firstName} ${user.lastName} and enjoy your ${subsLevel} subscription.`)
    //         this.props.loginAction(response);
    //         this.props.saveUser(response?.user);
    //         this.props.history.push(routes.home)
    //     }
    //     this.close()
    // }

    let { showSignup, showSignin, showForgotPassword, showResetPassword, showVerificationCode, pin, phoneNumber } = state
    return <>
        {
            showSignup && (
                <div id="at-themesignuppopup" className={`at-themepopupholder ${showSignup && 'at-showthemesignuppopup'}`}>
                    <SignupComponent {...props} onClose={close} openSignin={openSignin} openSubscriptionPopup={() => { }} />
                </div>
            )
        }

        {/*Sign In Component*/}
        {showSignin && <div id="at-themeloginpopup" className={`at-themepopupholder ${showSignin && 'at-showthemeloginpopup'}`}>
            <SigninComponent {...props} onClose={close} openSignup={openSignup} openForgotPassword={openForgotPassword} />
        </div>}

        {/*Forgot Password Component*/}
        {showForgotPassword && <div id="at-themeforgetpasswordpopup" className={`at-themepopupholder ${showForgotPassword && 'at-showthemeforgetpasswordpopup'}`}>
            <ForgotPasswordComponent onClose={close} openSignin={openSignin} openVerificationCode={openVerificationCode} />
        </div>}

        {/*Verification Code Component*/}
        {showVerificationCode && <div id="at-themerecoverycodepopup" className={`at-themepopupholder ${showVerificationCode && 'at-showthemerecoverycodepopup'}`}>
            <VerificationCodeComponent onClose={close} openSignin={openSignin} openResetPassword={openResetPassword} phoneNumber={phoneNumber} />
        </div>}

        {/*Reset Password Component*/}
        {showResetPassword && <div id="at-themecreatenewpasswordpopup" className={`at-themepopupholder ${showResetPassword && 'at-showthemecreatenewpasswordpopup'}`}>
            <ResetPasswordComponent onClose={close} openSignin={openSignin} phoneNumber={phoneNumber} pin={pin} />
        </div>}
    </>
}

export const AuthenticationComponent = withRouter(AuthModals)