import React, { Component } from 'react'

interface IComponentProps {
    errors: Array<string>,
    multiple?: boolean
}

export const ErrorComponent = (props: IComponentProps) => (
    <ul className="validation-error">
        {
            !props.multiple ?
                <li className="text-danger" key={`error-first`}>{props.errors[0]}</li> :
                props.errors.map((error: string, index: number) => <li className="text-danger" key={`error-${index}`}>{error}</li>)
        }
    </ul>
)