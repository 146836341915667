import React, { Fragment, PureComponent } from 'react'
import { IHub, ISpace } from '../../../interfaces'
import { getHubColorPath, getUserStats } from '../../../helper'

interface IProps {
	hubs: IHub[]
	activeSpace: ISpace | undefined
	selectItem: Function
}

export default class HubsAndSpacesListViewComponent extends React.Component<
	IProps
> {
	render() {
		const { hubs, activeSpace: mySpace, selectItem } = this.props
		let activeSpace = mySpace;
		const localCopy = [...hubs]

		const activeHub: IHub = localCopy.filter((hub, index) => {
			if (hub._id === activeSpace?.hubId) {
				localCopy.splice(index, 1)
				activeSpace = hub.spaces?.find((space) => space._id === mySpace?._id)
				return true
			}
			return false
		})[0]
		
		return (
			<Fragment>
				{/* <div className={`at-list-holder ${this.props.className}`}> */}
				{activeHub && (
					<div className='at-space-block'>
						<div className='at-topline'>
							<div>
								<span>
									<i
										className='icon-hub'
										onClick={() => {
											selectItem({ type: 'hub', ...activeHub })
										}}
									></i>{' '}
									<h4>{activeHub?.name}</h4>
								</span>
							</div>
							<div>
								<h5>
									{' '}
									<span className='badge at-badge-success'>Active Space</span>
									{activeSpace?.activeUsers?.length} /{' '}
									{activeSpace?.contacts?.length}
									{/* {getUserStats(activeHub?.spaces).activeUsers} /{' '}
									{getUserStats(activeHub?.spaces).totalUsers} */}
								</h5>
							</div>
						</div>
						<div className='at-bottomline'>
							<ul>
								{activeHub?.spaces?.map((space) => (
									<li
										key={space._id}
										className={
											space._id === activeSpace?._id
												? 'list-view-active-space'
												: ''
										}
										onClick={() => {
											selectItem({
												type: 'space',
												...{ ...space, parent: { ...activeHub } },
											})
										}}
									>
										<img src={getHubColorPath(space.color)} alt='Blue space' />
										<span>{space.name}</span>
									</li>
								))}
							</ul>
						</div>
					</div>
				)}

				{localCopy.map((hub) => (
					<div className='at-space-block' key={hub._id}>
						<div className='at-topline'>
							<div>
								<span>
									<i
										className='icon-hub'
										onClick={() => {
											selectItem({ type: 'hub', ...hub })
										}}
									></i>{' '}
									<h4>{hub.name}</h4>
								</span>
							</div>
							{/* <div>
								<h5>
									{getUserStats(hub.spaces).activeUsers} /{' '}
									{getUserStats(hub.spaces).totalUsers}
								</h5>
							</div> */}
						</div>
						<div className='at-bottomline'>
							<ul>
								{hub.spaces?.map((space) => (
									<li
										key={space._id}
										onClick={() => {
											selectItem({
												type: 'space',
												...{ ...space, parent: { ...hub } },
											})
										}}
									>
										<img src={getHubColorPath(space.color)} alt='Blue space' />
										<span>{space.name}</span>
									</li>
								))}
							</ul>
						</div>
					</div>
				))}

				{/* </div> */}
			</Fragment>
		)
	}
}
