export enum EScreens {
    HOME = "HOME",
    CREATE_COLLECTION = "CREATE_COLLECTION",
    CREATE_POST = "CREATE_POST",
    CREATE_EVENT = "CREATE_EVENT",
    EDIT_PROFILE = "EDIT_PROFILE",
    SUBSCRIPTION = "SUBSCRIPTION",
    IN_PLATFORM_WEBSITE = "IN_PLATFORM_WEBSITE",
    ASSIGN_ACCOUNT_MANAGEMENT = "ASSIGN_ACCOUNT_MANAGEMENT",
    MANAGE_ASSIGNED_ACCOUNT = "MANAGE_ASSIGNED_ACCOUNT",
    OFFERS = "OFFERS",
    TRANSACTIONS = "TRANSACTIONS",
    COMMISSION_WORK = "COMMISSION_WORK",
    DISPUTES = "DISPUTES",
    RETURNS = "RETURNS",
    METRICS = "METRICS",
    MANAGE_FUNDS = "MANAGE_FUNDS",
    CREATE_CLOUD_WALK = "CREATE_CLOUD_WALK",
    SELECT_CLOUD_WALK = "SELECT_CLOUD_WALK",
    ADD_SPACE_TO_CLOUD_WALK = "ADD_SPACE_TO_CLOUD_WALK",
    MANGE_SPACE = "MANGE_SPACE",
}