export const routes = {
    home: '/consulting-services',
    viewGallery: '/consulting-services/gallery',
    viewGalleryDetail: '/consulting-services/gallery-detail-page',
    // exhibitions: {
    //     index: '/artShow/exhibitions',
    //     viewSingleExhibition: '/artShow/view-single-exhibition',
    // },
    // about: '/artShow/about',
    // artists: {
    //     index: '/artShow/artists/collection',
    //     collectionView: '/artShow/artists/collection/collection-view',
    //     collectionItemView: '/artShow/artists/collection/item-view'
    // },
    // Contact: '/artShow/contact',
    // ArtDetail: '/artShow/art-detail',
    // Collection: '/artShow/collection',
 
} 