import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { userSelector } from '../../store/selectors'
import { _logger } from '../../helper';
import { SellerServiceTransaction } from '../../components/User/Account/SellerServiceTransaction';
import { BuyerServiceTransaction } from '../../components/User/Account/BuyerServiceTransaction';

interface IProps {
    hideBackButton: boolean
}
const ServicesTransaction = ({ hideBackButton }: IProps) => {
    const user = useSelector(userSelector)
    const history = useHistory()
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>Service Transactions<span className="at-tutorialplaybtn"></span></h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                        <Tab label="Seller Transactions">
                            <SellerServiceTransaction user={user} />
                        </Tab>
                        <Tab label="Buyer Transactions">
                            <BuyerServiceTransaction user={user} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </main>
    )
}

export default ServicesTransaction