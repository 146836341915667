import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { useHistory, useParams } from 'react-router-dom';
import { images } from '../../../../../assets/image';
import { ICuratorialProposalArtsDocument, ICuratorialProposalDocument, IProposalArtFormats, IVideo } from '../../../../../interfaces'
import { IResponse } from '../../../../../interfaces/db.interface';
import { routes } from '../../../../../router';
import { curatorialService, toastService } from '../../../../../services';
import { collectionImage, getCuratorialProposalProjectImages } from '../../../../../shared'
import { ImageLoading } from '../../../../common/ImageLoading'
import { ViewCuratorialProposalArtComponent } from './ViewCuratorialProposalArt.component';


interface IProps {

}

const ViewCuratorialProposalComponent = ({ }: IProps) => {

    const history = useHistory()
    const params = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [proposal, setProposal] = useState<ICuratorialProposalDocument>()
    const [proposalArts, setProposalArts] = useState<Array<ICuratorialProposalArtsDocument>>([])

    const fetchProposal = () => {

        setLoading(true)
        if (!params || !((params as any).id)) return
        curatorialService.getProposalWithCuratorial((params as any).id).then((res: AxiosResponse<IResponse<ICuratorialProposalDocument>>) => {
            if (res.data.success) {
                setProposal(res.data.data)
                setProposalArts([...res.data.data.proposalArts])
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }


    const onSelectArts = (artIndex: number, formatIndex: number) => {
        let array = proposalArts
        let art = array[artIndex]
        if (art.proposalFormats[formatIndex].isSelectedForOffer) {
            art.proposalFormats[formatIndex].isSelectedForOffer = false
        } else {
            art.proposalFormats[formatIndex].isSelectedForOffer = true
        }

        let format = art.proposalFormats.filter((i: IProposalArtFormats) => i.isSelectedForOffer)

        if (format.length > 0) {
            art.isSelectedForOffer = true
        } else {
            art.isSelectedForOffer = false
        }

        array[artIndex] = art
        setProposalArts([...array])
    }

    const onSubmitOffer = () => {
        if (!validateArts() || !proposal) return
        let arts: Array<any> = proposalArts.filter((i: ICuratorialProposalArtsDocument) => {
            return i.isSelectedForOffer
        })

        arts = arts.map((i: ICuratorialProposalArtsDocument) => {
            return {
                ...i,
                user: i.user._id,
                art: i.art.id
            }
        })
        setLoading(true)
        curatorialService.createCuratorialOffer({ proposalId: proposal._id, selectedProposalArts: arts }).then((res: AxiosResponse<IResponse<ICuratorialProposalDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                // setProposal({ ...res.data.data })
                history.push({ pathname: `${routes.account.services.curatorialServices.index}`, state: { page: 'offer' } })
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }


    const onUpdate = () => {
        if (!validateArts() || !proposal) return
        let arts: Array<any> = proposalArts.filter((i: ICuratorialProposalArtsDocument) => {
            return i.isSelectedForOffer
        })

        arts = arts.map((i: ICuratorialProposalArtsDocument) => {
            return {
                ...i,
                user: i.user._id,
                art: i.art.id
            }
        })

        setLoading(true)
        curatorialService.updateCuratorialOffer(proposal.curatorialOffer, { selectedProposalArts: arts }).then((res: AxiosResponse<IResponse<ICuratorialProposalDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                // setProposal({ ...res.data.data })
                history.push({ pathname: `${routes.account.services.curatorialServices.index}`, state: { page: 'offer' } })
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const validateArts = () => {
        let arts = proposalArts.filter((i: ICuratorialProposalArtsDocument) => {
            return i.isSelectedForOffer
        })
        if (arts.length > 0) {
            return true
        } else {
            toastService.warning("Please select atleast one art.")
            return false
        }
    }

    useEffect(() => {
        fetchProposal()
    }, [])

    return (
        <div className="at-section-wrap at-curatorial-wrap at-contentarea-two">
            <div className="at-themehead">
                <a className="at-left-arrowicon" onClick={() => history.goBack()}><i className="icon-left-arrow"></i></a>
                <h2>{proposal && proposal.curatorialInvitation && proposal.curatorialInvitation.participant && proposal.curatorialInvitation.participant.firstName ? `${proposal.curatorialInvitation.participant.firstName} ${proposal.curatorialInvitation.participant.lastName} ` : 'Curatorial Proposal'}</h2>
            </div>
            <div className="at-transaction-main">
                <div className="at-transaction-wrap">
                    <div className="at-transaction-box">
                        <div className="w-100">

                            {proposal && proposal._id &&
                                <>
                                    <div className="at-rejectaccept-proposal">
                                        <h6 className=" text-left"><span className="at-color-grey">Project Name: </span>{proposal?.curatorialService.name} </h6>
                                        <h6 className="text-left"><span className="at-color-grey">Proposal Images </span> </h6>

                                        <div className="at-new-thumbslider">

                                            <ImageGallery
                                                items={getCuratorialProposalProjectImages(proposal)}
                                                showFullscreenButton={true}
                                                showPlayButton={false}
                                                showNav={false}
                                                // slideOnThumbnailOver={true}
                                                renderItem={(item: any) => (
                                                    <div className="at-imagebox">
                                                        <figure className="at-figure">
                                                            <ImageLoading className="img-fluid" src={item.original} thumbnail={item.blurImage} />
                                                        </figure>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* <ul className="d-flex mt-2 ">
                                            <li className="mr-3 w-50">
                                                <h6><span className="at-color-grey">Start Date: </span> <br />
                                                    <span>{moment(proposal?.curatorialService.startDate).format('MM/DD/YYYY')}</span></h6>
                                            </li>
                                            <li className="w-50">
                                                <h6><span className="at-color-grey">End Date: </span>
                                                    <br />
                                                    <span>{moment(proposal?.curatorialService.endDate).format('MM/DD/YYYY')}</span></h6>
                                            </li>
                                        </ul> */}
                                        <h6 className=""><span className="at-color-grey">Proposal Description: </span><p dangerouslySetInnerHTML={{ __html: proposal.description }}></p></h6>

                                    </div>
                                    {proposalArts && proposalArts.length > 0 && proposalArts.map((pA: ICuratorialProposalArtsDocument, index: number) => <div className={`${index === 0  ? '':"at-bordertop"}`}>    <ViewCuratorialProposalArtComponent proposalArt={pA} onSelectArts={(fIndex: number) => onSelectArts(index, fIndex)} /></div>)}
                                    <div className="row">
                                        <div className="col-12 at-textcenter">
                                            {proposal.curatorialOffer ? <button className={`at-btn at-longbtn-h40`} onClick={() => onUpdate()}>See Offer</button> : <button className={`at-btn at-longbtn-h40`} onClick={() => onSubmitOffer()}>Create Offer</button>}
                                        </div>
                                    </div>
                                </>
                            }
                            {/* row checkbox ends */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewCuratorialProposalComponent