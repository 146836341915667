import React from 'react'
import { useEffect } from 'react'
import { CURRENT_VERSION } from '../../environment'

interface IProps {
    onSubmitCasheClearPopup: Function
}
export const CheckCache = ({ onSubmitCasheClearPopup }: IProps) => {

    return <div className="at-message-popup-overlay">
        <div className="at-message-popup">
            <h3>Release Update {CURRENT_VERSION}</h3>
            <p>
                We are excited to announce an updated website. By clicking OK, you'll be logged off so you can log into the updated website. Thank you
            </p>
            <button className="at-btn" onClick={() => onSubmitCasheClearPopup()}>
                OK
            </button>
        </div>
    </div>

}