import React from 'react'

interface IProps {
    onFinish: Function
}
export const StepFive = (props: IProps) => {
    return <div>
        <h6 className="at-stepheading">We appreciate your submission. You will be notified within 2 weeks if you are accepted onto the Omni platform.</h6>
        <div className="row row-margin-lr7 align-items-center">
            <div className="col-6 pad-lr-7 m-auto">
                <button className={`at-btn at-btn-sm at-btn-orange at-step-finishbtn`} onClick={() => props.onFinish()}>FINISH</button>
            </div>

        </div>
    </div>
}