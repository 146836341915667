import moment from 'moment';
import React from 'react'
import ImageGallery from 'react-image-gallery';
import { AWS_ENDPOINT } from '../../environment';
import { ICuratorialServiceDocument, IVideo } from '../../interfaces'
import { collectionImage, getCuratorialProjectImages } from '../../shared'
import { ImageLoading } from '../common/ImageLoading';

interface IPorps {
    service: ICuratorialServiceDocument
}
export const CuratorialInvitationResponseBox = ({ service }: IPorps) => {

    // const onClickPdf = () => {
    //     if (!service.pdf) return
    //     let link = `${AWS_ENDPOINT}/pdf/${service.pdf}`
    //     window.open(link, "_blank");
    // }

    // const onSentInvitations = () => {

    // }
    return (
        <div className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-rejectaccept-proposal">
                    <div className="at-new-thumbslider">

                        <ImageGallery
                            items={getCuratorialProjectImages(service)}
                            showFullscreenButton={true}
                            showPlayButton={false}
                            showNav={false}
                            // slideOnThumbnailOver={true}
                            renderItem={(item: any) => (
                                <div className="at-imagebox">
                                    <figure className="at-figure">
                                        <ImageLoading className="image-gallery-image" src={item.original} thumbnail={item.blurImage} />
                                    </figure>
                                </div>
                            )}
                        />
                    </div>
                    <h6 className="mb-0 text-left"><span className="at-color-grey">Project Name: </span>{service.name} </h6>
                </div>
            </div>
        </div>
    )

}