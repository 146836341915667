import axios, { AxiosResponse } from 'axios'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import RTSelect from 'react-select'
import { ETemplate } from '../../enums'
import { bytesToMegaBytes, dataURLtoFile, _logger } from '../../helper'
import { IinitTemplateDataState, initTemplateDataState, ITemplateState } from '../../interfaces/user-template.interface'
import { toastService } from '../../services'
import { templatesImages } from '../../shared'
import { ErrorComponent } from '../common/Error.component'
import { UniversalModal } from '../common/Modals/Universal.modal'
import { SliderComponent } from '../common/Slider.component'
import { TmceEditor } from '../common/TMCEditor.comopnent'

interface IProps {
    goBack: Function
    setData: Function
    data: ITemplateState
}


export const AddTemplateData = ({ goBack, setData, data }: IProps) => {

    const imageLogoRef = useRef<any>(null)
    const imageLocationRef = useRef<any>(null)
    const imageHomeRef = useRef<any>(null)
    const imageGalleryRef = useRef<any>(null)
    const imageAboutRef = useRef<any>(null)
    const [state, setState] = useState<IinitTemplateDataState>({ ...initTemplateDataState })
    const [errors, setErrors] = useState<any>({})
    const [showColorModal, setColorModal] = useState<boolean>(false)
    const [fieldName, setFieldName] = useState<string>('')
    const [color, setColor] = useState<string>('')
    const [fonts, setFonts] = useState<Array<any>>([])

    // const [state]



    useEffect(() => {
        if (data) {
            setDataState(data)
        }
        fetchFonts()
    }, [])

    const fetchFonts = async () => {
        await axios.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBvUwk8WBfe_ZhIAJkwcdep8u-UBisr4m4').then((res: AxiosResponse<any>) => {
            let array = res.data.items?.map((f: any) => {
                return {
                    label: f.family,
                    value: f.family,
                    category: f.category
                }
            })
            setFonts([...array])
        })
    }

    const setDataState = async (data: ITemplateState) => {
        setState({
            ...state,
            ...data,
            homeImagePreview: data.homeImage && data.homeImage !== "null" ? [{ url: templatesImages(`${data.homeImage}`) }] : [],
            galleryImagePreview: data.galleryImage && data.galleryImage !== "null" ? [{ url: templatesImages(`${data.galleryImage}`) }] : [],
            logoImagePreview: data.logoImage && data.logoImage !== "null" ? [{ url: templatesImages(`${data.logoImage}`) }] : [],
            locationImagePreview: data.locationImage && data.locationImage !== "null" ? [{ url: templatesImages(`${data.locationImage}`) }] : [],
            aboutImagePreview: data.aboutImage && data.aboutImage !== "null" ? [{ url: templatesImages(`${data.aboutImage}`) }] : [],
        })
        // console.log(fetchImage(templatesImages(`high_${data.logoImage}`)))
    }
    const onChangeText = (e: FormEvent<HTMLInputElement> | any, objectName: string) => {
        const { name, value } = e.target;
        switch (objectName) {
            case 'homeInformation':
                setState({
                    ...state,
                    homeInformation: {
                        ...state.homeInformation,
                        [name]: value
                    }
                })
                return
            case 'contactInformation':
                setState({
                    ...state,
                    contactInformation: {
                        ...state.contactInformation,
                        [name]: value
                    }
                })
                return

            default:
                setState({
                    ...state,
                    [name]: value
                })
                return;
        }
    }


    //                                Image handling
    const selectLogoImage = () => {

        imageLogoRef.current.click();
    }
    const selectHomeImage = () => {

        imageHomeRef.current.click();
    }
    const selectGalleryImage = () => {

        imageGalleryRef.current.click();
    }
    const selectLocationImage = () => {

        imageLocationRef.current.click();
    }
    const selectAboutImage = () => {

        imageAboutRef.current.click();
    }

    const onChangePoster = (e: any, name: string) => {
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            if (filesArray?.length === 0) return

            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {

                    setState({
                        ...state,
                        [name]: files[0],
                        [`${name}Preview`]: images
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    function convertImgToBase64(url: any, callback: any, outputFormat: any = 'image/png') {
        let newUrl
        var canvas = document.createElement('canvas');
        document.body.appendChild(canvas)
        var ctx = canvas.getContext('2d');
        var img = new Image;
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            canvas.height = img.height;
            canvas.width = img.width;
            ctx?.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
            // Clean up
            document.body.removeChild(canvas)
            newUrl = dataURL
        };
        img.src = url;
    }
    const fetchImage = async (url: string) => {

        // let get = await axios.get(url, { headers: { Accept: '*/*' }, responseType: 'blob' })
        // console.log("bfjdhbfhd", get)
        // let img = await fetch(url)
        // let blob = await img.blob()
        // fetch(url).then(function (response) {
        //     return response.blob();
        // })
        // .then(function (blob) {
        //     console.log(blob)
        // });
        // console.log("fetch await ===", blob)
        // const name = `${new Date().getTime()}`;

        // let split = url.split('.')
        // let extension = split[split.length - 1]

        // let file = new File([blob], name, { type: `image/${extension}` })
        // // console.log("file:-=-=-=", file)
        // let base64 = await new Promise((resolve, reject) => {
        //     const reader = new FileReader();
        //     reader.addEventListener('load', (ev: any) => {
        //         resolve(ev.target.result);
        //     });
        //     reader.addEventListener('error', reject);
        //     reader.readAsDataURL(file);
        // })
        // console.log(base64)
        // return base64
        // let imageUrl
        // return await convertImgToBase64(url, async (res: any) => {
        //  return imageUrl = await [res] 
        // })
        // console.log("image wait:-=-=", imageUrl)
    }

    const onCropImage = (image: any, fileName: string, varName: string) => {
        const file = dataURLtoFile(image.cropped, fileName)
        setState({
            ...state,
            [varName]: file,
            [`${varName}Preview`]: [image.cropped]
        })

        // const file = dataURLtoFile(image.cropped, fileName)

    }

    const onDeleteImage = (name: string) => {
        setState({
            ...state,
            [name]: null,
            [`${name}Preview`]: []
        })
    }

    const openColorModal = (name: string) => {
        let data: any = state
        setColorModal(true)
        setFieldName(name)
        setColor(data[name])
    }

    const closeColorModal = () => {
        setColorModal(false)
        setFieldName('')
    }

    const onChangeColor = (color: any) => {
        setColor(color.hex)
    }

    const onAddColor = () => {
        setState({
            ...state,
            [fieldName]: color
        })
        setColor('')
        closeColorModal()
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (!validateData()) return
        let data = {
            homeImage: state.homeImage,
            galleryImage: state.galleryImage,
            logoImage: state.logoImage,
            homeInformation: state.homeInformation,
            contactInformation: state.contactInformation,
            locationImage: state.locationImage,
            backgroundColor: state.backgroundColor,
            textColor: state.textColor,
            aboutImage: state.aboutImage,
            aboutDescription: state.aboutDescription,
            lineColor: state.lineColor,
            font: state.font
        }

        setData(data)
    }

    const validateData = () => {
        let { homeImage, galleryImage, logoImage, homeInformation, contactInformation, locationImage } = state
        let newErr: any = {}
        if (!homeImage) {
            newErr['homeImage'] = ['Home image is required']
        }

        if (state.template === ETemplate.GALLERY_FORMAT && !galleryImage) {
            newErr['galleryImage'] = ['Gallery image is required']
        }
        if (!logoImage) {
            newErr['logoImage'] = ['Logo image is required']
        }
        if (!locationImage) {
            newErr['locationImage'] = ['Location image is required']
        }
        if (!homeInformation.heading) {
            newErr['heading'] = ['Heading is required']
        }
        if (!homeInformation.description) {
            newErr['description'] = ['Description is required']
        }


        if (Object.keys(newErr).length > 0) {
            setErrors({ ...newErr })
            return false
        }
        setErrors({})
        return true
    }

    const fontSizeArray = () => {
        let array = []
        for (let i = 11; i < 24; i++) {
            let obj = {
                label: `${i + 1}`,
                value: `${i + 1}`
            }
            array.push(obj)
        }
        return array
    }

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2><span className="at-color-orange">STEP 3:</span> Add Template Information</h2>
                    {/* <h2>STEP 1: Select URL</h2> */}
                </div>
                <div className="at_theme_innercontent">
                    <form className="at-formtheme" onSubmit={onSubmit}>
                        <fieldset>
                            <div className="at-assign-roundbox">
                                <div className="form-group at-formgroup-themecolor">
                                    <h4 onClick={() => openColorModal('backgroundColor')}> <i className="icon-paint-bucket"></i>Background Color <div style={{ backgroundColor: `${state.backgroundColor}` }} className="at-bg-box"></div></h4>
                                    <h4 onClick={() => openColorModal('textColor')} className="mt-4"><i className="icon-text-color"></i>Text Color <div style={{ backgroundColor: `${state.textColor}` }} className="at-bg-box"></div></h4>
                                    <h4 onClick={() => openColorModal('lineColor')} className="mt-4"><i className="icon-text-color"></i>Line Color <div style={{ backgroundColor: `${state.lineColor}` }} className="at-bg-box"></div></h4>
                                    <div className="At-ThemeFontPicker">
                                        <div className="at-customfont-holder">
                                            <label htmlFor=""> Font Family</label>
                                            <RTSelect
                                                value={fonts.find((i: any) => i.value === state.font.fontFamily)}
                                                options={fonts}
                                                onChange={(data: any) => setState({ ...state, font: { ...state.font, fontFamily: data.value, fontCategory: data.category } })}
                                            />
                                        </div>
                                        <div className="at-customfont-holder">
                                            <label htmlFor=""> Font Size</label>
                                            <RTSelect
                                                value={fontSizeArray().find((i: any) => i.value === state.font.fontSize)}
                                                options={fontSizeArray()}
                                                onChange={(data: any) => setState({ ...state, font: { ...state.font, fontSize: data.value } })}
                                            />
                                        </div>
                                    </div>
                                    {/* {errorElement('heading')} */}
                                    {/* {errorElement('name')} */}
                                </div>
                            </div>

                            {state.homeImagePreview?.length > 0 && <div className={`form-group form-w-100 mb-2 position-relative`}>
                                <SliderComponent items={state.homeImagePreview} originalItems={state.homeImagePreview} onCrop={(image: any) => onCropImage(image, (state.homeImage as any).name, 'homeImage')} currentIndex={0} onChangeIndex={() => { }} />
                                {
                                    state.homeImagePreview && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeleteImage('homeImage')}></i>)
                                }
                                {/* <CropImageComponent image={preview[selectedFile]} /> */}
                            </div>}
                            <div className="form-group form-w-100 mb-3">
                                <div className="at-themefileupload at-upload-templates">
                                    <input type="file" name="poster" multiple accept="image/*" ref={imageHomeRef} onChange={(e: any) => onChangePoster(e, 'homeImage')} id="at-uploadfile-1" />
                                    <label onClick={selectHomeImage}>
                                        <span><i className="icon-gallery"></i>UPLOAD HOME PAGE IMAGE</span>
                                    </label>
                                    {errorElement('homeImage')}
                                </div>
                            </div>
                            {state.template !== ETemplate.ART_SHOW_FORMAT && <>
                                {state.galleryImagePreview?.length > 0 && <div className={`form-group form-w-100 mb-2 position-relative`}>
                                    <SliderComponent items={state.galleryImagePreview} originalItems={state.galleryImagePreview} onCrop={(image: any) => onCropImage(image, (state.galleryImage as any).name, 'galleryImage')} currentIndex={0} onChangeIndex={() => { }} />
                                    {
                                        state.galleryImagePreview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeleteImage('galleryImage')}></i>)
                                    }
                                    {/* <CropImageComponent image={preview[selectedFile]} /> */}
                                </div>}
                                <div className="form-group form-w-100 mb-3">
                                    <div className="at-themefileupload at-upload-templates">
                                        <input type="file" name="poster" multiple accept="image/*" ref={imageGalleryRef} onChange={(e: any) => onChangePoster(e, 'galleryImage')} id="at-uploadfile-2" />
                                        <label onClick={selectGalleryImage}>
                                            <span><i className="icon-gallery"></i>UPLOAD GALLERY PAGE IMAGE</span>
                                        </label>
                                        {errorElement('galleryImage')}
                                    </div>
                                </div>
                            </>}
                            {state.logoImagePreview?.length > 0 && <div className={`form-group form-w-100 mb-2 position-relative`}>
                                <SliderComponent items={state.logoImagePreview} originalItems={state.logoImagePreview} onCrop={(image: any) => onCropImage(image, (state.logoImage as any).name, 'logoImage')} currentIndex={0} onChangeIndex={() => { }} />
                                {
                                    state.logoImagePreview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeleteImage('logoImage')}></i>)
                                }
                                {/* <CropImageComponent image={preview[selectedFile]} /> */}
                            </div>}
                            <div className="form-group form-w-100 mb-3">
                                <div className="at-themefileupload at-upload-templates">
                                    <input type="file" name="poster" multiple accept="image/*" ref={imageLogoRef} onChange={(e: any) => onChangePoster(e, 'logoImage')} id="at-uploadfile-3" />
                                    <label onClick={selectLogoImage}>
                                        <span><i className="icon-gallery"></i>UPLOAD WEBSITE LOGO</span>
                                    </label>
                                    {errorElement('logoImage')}
                                </div>
                                <p className="at-fs12 at-color-orange">Important: Logo must be loaded with transparent background.</p>
                            </div>

                            {state.aboutImagePreview?.length > 0 && <div className={`form-group form-w-100 mb-2 position-relative`}>
                                <SliderComponent items={state.aboutImagePreview} originalItems={state.aboutImagePreview} onCrop={(image: any) => onCropImage(image, (state.aboutImage as any).name, 'logoImage')} currentIndex={0} onChangeIndex={() => { }} />
                                {
                                    state.aboutImagePreview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeleteImage('logoImage')}></i>)
                                }
                                {/* <CropImageComponent image={preview[selectedFile]} /> */}
                            </div>}
                            <div className="form-group form-w-100 mb-3">
                                <div className="at-themefileupload at-upload-templates">
                                    <input type="file" name="poster" multiple accept="image/*" ref={imageAboutRef} onChange={(e: any) => onChangePoster(e, 'aboutImage')} id="at-uploadfile-3" />
                                    <label onClick={selectAboutImage}>
                                        <span><i className="icon-gallery"></i>UPLOAD ABOUT PAGE IMAGE</span>
                                    </label>
                                    {errorElement('aboutImage')}
                                </div>
                                {/* <p className="at-fs12 at-color-orange">Important: Logo must be loaded with transparent background.</p> */}
                            </div>

                            {state.locationImagePreview?.length > 0 && <div className={`form-group form-w-100 mb-2 position-relative`}>
                                <SliderComponent items={state.locationImagePreview} originalItems={state.locationImagePreview} onCrop={(image: any) => onCropImage(image, (state.locationImage as any).name, 'locationImage')} currentIndex={0} onChangeIndex={() => { }} />
                                {
                                    state.locationImagePreview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={() => onDeleteImage('locationImage')}></i>)
                                }
                                {/* <CropImageComponent image={preview[selectedFile]} /> */}
                            </div>}
                            <div className="form-group form-w-100 mb-3">
                                <div className="at-themefileupload at-upload-templates">
                                    <input type="file" name="poster" multiple accept="image/*" ref={imageLocationRef} onChange={(e: any) => onChangePoster(e, 'locationImage')} id="at-uploadfile-4" />
                                    <label onClick={selectLocationImage}>
                                        <span><i className="icon-gallery"></i>UPLOAD CONTACT PAGE IMAGE</span>
                                    </label>
                                    {errorElement('locationImage')}
                                </div>
                            </div>

                            <h2 className="mb-4">Home Page Text</h2>
                            <div className="form-group form-w-100 at-floatlabel-h40">
                                <input autoComplete="off" type="text" value={state.homeInformation.heading} name="heading" className="form-control" placeholder=" " onChange={(e: any) => onChangeText(e, 'homeInformation')} />
                                <label>Update Heading</label>
                                {errorElement('heading')}
                                {/* {errorElement('name')} */}
                            </div>
                            <div className="form-group form-w-100">
                                {/* <textarea name="description" placeholder=" " value={eventState.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}
                                <label className="at-color-grey">Home Page Information</label>

                                <TmceEditor text={state.homeInformation.description} onChange={(e: any) => onChangeText({ target: { name: 'description', value: e } }, 'homeInformation')} />
                                {errorElement('description')}
                            </div>
                            <div className="form-group form-w-100">
                                {/* <textarea name="description" placeholder=" " value={eventState.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}
                                <label className="at-color-grey">About Page Information</label>

                                <TmceEditor text={state.aboutDescription} onChange={(e: any) => onChangeText({ target: { name: 'aboutDescription', value: e } }, '')} />

                                {errorElement('description')}
                            </div>
                            {/* <h2 className="mb-4">Contact Information</h2> */}
                            <div className="form-group form-w-100">
                                {/* <textarea name="description" placeholder=" " value={eventState.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}
                                <label className="at-color-grey">Contact Page Information</label>

                                <TmceEditor text={state.contactInformation.information} onChange={(e: any) => onChangeText({ target: { name: 'information', value: e } }, 'contactInformation')} />

                            </div>
                            {/* <div className="form-group form-w-100 at-floatlabel-h40 at-inputiconleft">
                                <input autoComplete="off" type="text" value={state.contactInformation.facebookId} name="facebookId" className="form-control" placeholder=" " onChange={(e: any) => onChangeText(e, 'contactInformation')} />
                                <label>Facebook User ID</label>
                                <i className="icon-fb"></i>
                            </div>
                            <div className="form-group form-w-100 at-floatlabel-h40 at-inputiconleft">
                                <input autoComplete="off" type="text" value={state.contactInformation.instagramId} name="instagramId" className="form-control" placeholder=" " onChange={(e: any) => onChangeText(e, 'contactInformation')} />
                                <label>Instagram User ID</label>
                                <i className="icon-instagram2"></i>
                            </div>
                            <div className="form-group form-w-100 at-floatlabel-h40 at-inputiconleft">
                                <input autoComplete="off" type="text" value={state.contactInformation.youtubeId} name="youtubeId" className="form-control" placeholder=" " onChange={(e: any) => onChangeText(e, 'contactInformation')} />
                                <label>Youtube User ID</label>
                                <i className="icon-youtube1 fs-30"></i>
                            </div>
                            <div className="form-group form-w-100 at-floatlabel-h40 at-inputiconleft">
                                <input autoComplete="off" type='email' value={state.contactInformation.email} name="email" className="form-control" placeholder=" " onChange={(e: any) => onChangeText(e, 'contactInformation')} />
                                <label>Contact Email</label>
                                <i className="icon-email2 "></i>
                            </div> */}

                            <div className="row row-margin-lr7">
                                <div className="col-6 pad-lr-7">
                                    <div className="form-group">
                                        <button className="at-btn at-btn-cancel at-longbtn-h40" >Cancel</button>
                                    </div>
                                </div>
                                <div className="col-6 pad-lr-7">
                                    <div className="form-group">
                                        <button type="submit" className="at-btn at-longbtn-h40" >Update</button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                {showColorModal && <UniversalModal open={showColorModal} onClose={closeColorModal}>
                    <SketchPicker className="at-sketch-picker-popup" disableAlpha color={color} onChange={onChangeColor} />
                    <div className="row row-margin-lr7">
                        <div className="col-6 pad-lr-7">
                            <div className="form-group">
                                <button className="at-btn at-btn-cancel at-longbtn-h40" onClick={closeColorModal}>Cancel</button>
                            </div>
                        </div>
                        <div className="col-6 pad-lr-7">
                            <div className="form-group">
                                <button type="button" className="at-btn at-longbtn-h40" onClick={onAddColor} >Update</button>
                            </div>
                        </div>
                    </div>
                </UniversalModal>
                }
            </div>
        </main>
    )
}