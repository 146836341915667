import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { authSelector, userSelector } from '../../store/selectors';
import StreamingHeaderComponent from './StreamingHeader.component';
import Draggable from 'react-draggable';

interface IProps {
    children?: any
}
const PortalComponent = (props: IProps) => {
    const user = useSelector(userSelector)
    const auth = useSelector(authSelector).access_token
    const spaceEnabled = user.activeSpace && user.activeSpace._id
    const renderJsx = () => {
        return (
            <div className="At-AgoraTemplate">
                {/* <Draggable>
                    <div>
                        <div id='at-wrapper' className={` ${spaceEnabled ? 'at-wrapper-withvideo' : ''}`} >
                            {auth && <StreamingHeaderComponent {...props} hideHeader={true} />}
                        </div>
                    </div>
                </Draggable> */}
                <div id='portal-child-root'></div>
            </div>
        )
    }
    return renderJsx()
}

export default withRouter(PortalComponent)