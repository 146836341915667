import { HttpService } from './base.service'
import { data } from 'jquery';

class OfferService extends HttpService {
    private readonly prefix: string = 'offer';

    /**
     *  User make offer
     */
    makeOffer = (data:any): Promise<any> => this.post(`${this.prefix}`, data);

    /**
     *  User make offer
     */
    getAllOffers = (): Promise<any> => this.get(`${this.prefix}`);

    /**
     *  Seller offers
     */
    getSellerOffersOffer = (id: any): Promise<any> => this.get(`${this.prefix}/recieved/${id}`);

    /**
     *  Buyer offers
     */
    getBuyerOffersOffer = (id: any): Promise<any> => this.get(`${this.prefix}/sent/${id}`);

    /**
     *  get single offers
     */
    getSingleOffer = (id: any): Promise<any> => this.get(`${this.prefix}/${id}`);

}

export const offerService = new OfferService();