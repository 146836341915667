import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { templateSelector } from '../../store/selectors'
import { CustomStyledText } from '../assets/_global-template-style'
import Webfont from 'webfontloader'

interface IProps {
    className?: string,
    children: any
}
export const CustomText = ({ className, children }: IProps) => {
    const template = useSelector(templateSelector)

    useEffect(() => {
        if (template.font && template.font.fontFamily && template.font.fontCategory) {
            Webfont.load({
                google: {
                    families: [template.font.fontFamily, template.font.fontCategory]
                }
            });
        }

    }, [template])
    return (
        <CustomStyledText
            className={`${className ? className : ''}`}
            fontFamily={template.font?.fontFamily || ""}
            fontSize={template.font?.fontSize || '16'}
        >
            {children}
        </CustomStyledText>
    )
}