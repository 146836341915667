import { IUser } from "./user.interface";
import { IDynamic, IWeihgtFactorsDocument } from ".";
import { BlockLike } from "typescript";
import { IArtistSelectedService, ISelectedService } from "./services.interface";



export interface IPackageDimension {
    height: number
    width: number
    depth: number
    weight: number
}

export interface IFormates {
    _id?: string,
    size: string,
    price: string,
    shipping: string
    active?: boolean
    printType: string
    selectedService: any | IArtistSelectedService
    buyerSelectedService?: ISelectedService
    weight: number
    shippingInsuranceAmount: number
}

export interface IFormatesResponse {

    size: string,
    price: string,
    shipping: string
}

export interface ICollectionItem {
    readonly id?: string
    name: string
    price: string
    shipping: string
    totalShipping: string
    art_collection?: ICollection | any
    description: string
    favoritedBy: Array<string>
    url?: string
    assignment?: IArtAssignment
    items?: Array<IArtAttachment>
    sold: boolean
    reserved: boolean
    readonly createdAt?: Date
    readonly updatedAt?: Date
    formates: Array<IFormates>
    offerState: boolean,
    changeCollection?: boolean
    artTechnique: string,
    height: number,
    width: number,
    depth: number,
    weight: number,
    artistName?: string
    artFactor: IWeihgtFactorsDocument
    frameFactor: IWeihgtFactorsDocument
    packageFactor: IWeihgtFactorsDocument
    packageDimension: IPackageDimension
    allowRolled: boolean
    shippingInsuranceAmount: number
    isAuctioned: boolean
    auctionCommission: number
    auctionMinPrice: number
    omniShipping: boolean,
    ownShipping: boolean,
}

export interface ICollectionItemState {
    readonly id?: string
    name: string
    price: string
    shipping: string
    totalShipping: string
    art_collection?: ICollection | any
    description?: string
    favoritedBy?: Array<string>
    url?: string
    assignment?: IArtAssignment
    items?: Array<IArtAttachment>
    sold: boolean
    reserved: boolean
    readonly createdAt?: Date
    readonly updatedAt?: Date
    formates: Array<IFormates>
    offerState: boolean,
    changeCollection?: boolean
    artTechnique: string,
    height: number,
    width: number,
    depth: number,
    weight: number
    artistName?: string
    allowRolled: boolean
    packageDimension: IPackageDimension
    artFactor?: any
    frameFactor?: any
    packageFactor?: any
    shippingInsuranceAmount: number
    isAuctioned: boolean
    auctionCommission: number
    auctionMinPrice: number
    omniShipping: boolean
    ownShipping: boolean
    
}

export interface ICreateCollectionItem extends ICollectionItem {
    name: string
    price: string
    art_collection: string
    description: string
}

export interface ICollection {
    readonly id: string
    readonly title: string
    readonly isHide: boolean
    readonly description: string
    readonly keywords: string
    readonly isActive: boolean
    readonly itemsCount: number
    readonly items: Array<ICollectionItem>
    readonly favoritedBy: Array<string>
    readonly user: IUser
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

export interface ICreateCollection {
    title: string
    description?: string,
    keywords?: string
}

export interface IArtAttachment {
    id: string
    url: string
}
export interface IArtAssignment {
    readonly id: string
    readonly type: string
    readonly art: ICollectionItem
    readonly user: IUser | string
    readonly assigned: boolean
    readonly accepted: boolean
    readonly period: number
    readonly commission: number
    readonly formatPeriod?: number
    readonly formatCommission?: number
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
    readonly originalResponsibility: string
    readonly formatResponsibility: string
}

export const initialItemState: ICollectionItemState = {
    name: '',
    price: '',
    shipping: '',
    totalShipping: '',
    sold: false,
    reserved: false,
    formates: [],
    offerState: false,
    allowRolled: false,
    artTechnique: '',
    height: NaN,
    width: NaN,
    depth: NaN,
    weight: NaN,
    art_collection: '',
    packageDimension: {
        height: NaN,
        width: NaN,
        depth: NaN,
        weight: NaN,
    },
    shippingInsuranceAmount: NaN,
    isAuctioned: false,
    auctionCommission: NaN,
    auctionMinPrice: NaN,
    omniShipping: true,
    ownShipping: false,
}

export const initialCollectionState: ICreateCollection = {
    title: '',
    description: '',
    keywords: ''
}

export interface IArtInitialImageState {
    preview: any,
    image: any,
    previewsCopy: any,
    uploadItemImage: boolean
}

export interface IArtDetailImageState {
    preview: any,
    image: any,
    previewsCopy: any,
    uploadartImage: boolean,
    artImageIndex: number
}

export const artInitialImageState: IArtInitialImageState = {
    preview: [],
    image: null,
    previewsCopy: [],
    uploadItemImage: false
}

export const artDetailImageState: IArtDetailImageState = {
    preview: [],
    image: [],
    previewsCopy: [],
    uploadartImage: false,
    artImageIndex: 0
}

export const initialFormatState: IFormates = {
    size: '',
    price: '',
    shipping: '',
    active: true,
    printType: '',
    selectedService: '',
    buyerSelectedService: undefined,
    weight: NaN,
    shippingInsuranceAmount: NaN
}