import momentTimeZone from 'moment-timezone';
import { APP_DOMAIN, APP_URL, AWS_ENDPOINT, AWS_IMAGE_FOLDER, CLOUDURL } from "../environment";
import { ICollectionItem, ICommissionworkPROPOSAL, ICuratorialProposalDocument, ICuratorialServiceDocument, ITemplateReducerState, IUser, IVideo } from "../interfaces";
import $, { type } from "jquery";
import { ERole, ETemplate } from "../enums";
import timezones, { Timezone } from 'timezones.json';
enum EImageThumbPercentage {
  POINT_ONE = 0.1,
  POINT_FIVE = 0.5,
}

export const validationErrors = ({ message }: any) => {
  return message.reduce((acc: any, val: any) => {
    acc[val.property] = Object.values(val.constraints);
    return acc;
  }, {});
}

const getCompressedImage = (thumbnail: boolean, path: string) => {
  let high = path && path.includes('high_')
  // let newPath = high ? path.split('_')[1] : path
  if (thumbnail) {
    if (path && path.includes('high_')) {
      let newPath = path.split('high_')[1]
      return `${CLOUDURL}/fit-in/375x250/filters:quality(100)/${AWS_IMAGE_FOLDER}/${newPath}`
    }
    else {
      return `${CLOUDURL}/fit-in/375x250/filters:quality(100)/${AWS_IMAGE_FOLDER}/${path}`
    }
    // return high ? `${CLOUDURL}/fit-in/375x250/filters:quality(100)/${AWS_IMAGE_FOLDER}/${newPath}` : `${CLOUDURL}/fit-in/375x250/filters:quality(100)/${AWS_IMAGE_FOLDER}/${newPath}`
  }

  if (path && path.includes('blur_')) {
    let newPath = path.split('blur_')[1]
    return `${CLOUDURL}/fit-in/375x250/filters:quality(100)/${AWS_IMAGE_FOLDER}/${newPath}`
  }
  return `${AWS_ENDPOINT}/${AWS_IMAGE_FOLDER}/${path}`
}

export const userImage = (path: string): string => path.includes('https://') ? path : `${CLOUDURL}/fit-in/375x250/${AWS_IMAGE_FOLDER}/${path}`

export const postImage = (postId: string, path: string, thumbnail = false): string => {
  if (path && path.includes('.mp4')) {
    return `${AWS_ENDPOINT}/${AWS_IMAGE_FOLDER}/${path}`
  }
  return `${getCompressedImage(thumbnail, path)}`
}

export const collectionImage = (item: ICollectionItem, path: string, thumbnail = false): string => {
  // let collectionId = ''
  // if (typeof item === 'string') collectionId = item;
  // if (typeof item.art_collection === 'object') collectionId = item.art_collection.id;
  // if (typeof item.art_collection === 'string') collectionId = item.art_collection;
  if (path && path.includes('.mp4')) {
    return `${AWS_ENDPOINT}/${AWS_IMAGE_FOLDER}/${path}`
  }
  return `${getCompressedImage(thumbnail, path)}`;
}

export const proposalImage = (item: ICommissionworkPROPOSAL, path: string, thumbnail = false): string => {
  // let proposalId = ''
  // if (typeof item === 'string') proposalId = item;
  // if (typeof item === 'object') proposalId = item.id as string;
  if (path && path.includes('.mp4')) {
    return `${AWS_ENDPOINT}/${AWS_IMAGE_FOLDER}/${path}`
  }
  return `${getCompressedImage(thumbnail, path)}`;
}

export const eventImages = (item: any, path: string, thumbnail = false) => {
  if (path && path.includes('.mp4')) {
    return `${AWS_ENDPOINT}/${AWS_IMAGE_FOLDER}/${path}`
  }
  return `${getCompressedImage(thumbnail, path)}`
}

export const templatesImages = (path: string, thumbnail = false) => {
  return `${getCompressedImage(thumbnail, path)}`
}

export const validateForm = (keys: Array<string>, data: any = {}, errorMessages: any = {}) => {
  let errors: any = {}
  keys.forEach((key: string) => {
    if (data && !data[key] && key !=="auctionCommission" && key !=="auctionMinPrice"  && key !== "shippingInsuranceAmount"  && typeof data[key] !== 'boolean') errors[key] = [errorMessages[key]]
  })

  const valid = (Object.keys(errors)).length === 0;
  return {
    valid,
    errors
  }

}

export const secondsToTime = (secs: any) => {
  var hours = Math.floor(secs / (60 * 60));

  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);
  return `${hours}:${minutes}:${seconds}`
}


export const getBase64 = async (url: any) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result)
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  })
}

export const currencyInputField = () => {
  // Jquery Dependency

  $("input[data-type='currency']").on({
    keyup: function () {
      formatCurrency($(this));
    },
    blur: function () {
      formatCurrency($(this), "blur");
    }
  });


}
function formatNumber(n: any) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


function formatCurrency(input: any, blur?: any) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  var input_val = input.val();

  // don't validate empty input
  if (input_val === "") { return; }

  // original length
  var original_len = input_val.length;

  // initial caret position 
  var caret_pos = input.prop("selectionStart");

  // check for decimal
  if (input_val.indexOf(".") >= 0) {

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = input_val.indexOf(".");

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // On blur make sure 2 numbers after decimal
    if (blur === "blur") {
      right_side += "00";
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    input_val = "$" + left_side + "." + right_side;

  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = "$" + input_val;

    // final formatting
    if (blur === "blur") {
      input_val += ".00";
    }
  }

  // send updated string to input
  input.val(input_val);

  // put caret back in the right position
  var updated_len = input_val.length;
  caret_pos = updated_len - original_len + caret_pos;
  input[0].setSelectionRange(caret_pos, caret_pos);
}

export const getCompleteUrlWithDomain = (url: string, withHttps = false) => {
  return withHttps ? `${APP_URL}/${url}` : `${APP_URL}/${url}`
}

export const getDomainUrl = (url: string) => {
  let split = url.split('/')
  return split[1]
}

export const getUserTemplateRoute = (template: ITemplateReducerState) => {

  if (template.template === ETemplate.ART_SHOW_FORMAT) { return 'artists/collections' }

  if (template.template === ETemplate.GALLERY_FORMAT) { return 'artists/collections' }

  if (template.template === ETemplate.STUDIO_FORMAT) { return 'gallery/collections' }

  if (template.template === ETemplate.CATALOGUE_FORMAT) { return 'gallery/collections' }

  return ''


}

// export const countryZone = () => {
//   var abbrs = {
//     EST: 'Eastern Standard Time',
//     EDT: 'Eastern Daylight Time',
//     CST: 'Central Standard Time',
//     CDT: 'Central Daylight Time',
//     MST: 'Mountain Standard Time',
//     MDT: 'Mountain Daylight Time',
//     PST: 'Pacific Standard Time',
//     PDT: 'Pacific Daylight Time',
//   };
//   let momentTimeZoneList = momentTimeZone.tz.countries()
//   let momentZoneName = momentTimeZone.tz.names()

//   let array: Array<any> = []

//   momentTimeZoneList.map((t: string) => {
//     Object.keys(abbrs).map((k: string) => {
//       array.push({
//         label: `${t}/${k}`,
//         value: `${t}/${k}`
//       })
//       return k
//     })
//     return t
//   })

//   return array
// }

export const countryZone = () => {
  let timezoneArray: Array<Timezone> = timezones

  let array = timezoneArray.map((t: Timezone) => {
    return {
      label: `${t.text}`,
      value: `${t.abbr}`
    }
  })

  return array
}

export const getUserType = (u: IUser) => {
  if (u.roles.includes(ERole.ARTIST)) {
    return "Artist"
  }
  if (u.roles.includes(ERole.AGENT)) {
    return "Gallery"
  }
  if (u.roles.includes(ERole.CONSULTANT)) {
    return "Service Provider"
  }

  return 'Buyer'
}

export const getCuratorialProjectImages = (service: ICuratorialServiceDocument) => {

  let poesterImage = [
    {
      original: collectionImage({} as any, `${service.poster}`),
      blurImage: collectionImage({} as any, `blur_${service.poster}`),
      thumbnail: collectionImage({} as any, `${service.poster}`, true),
    },
  ];
  let attachImages = service.images.map((i: IVideo) => {
    return {
      original: collectionImage({} as any, `${i.url}`),
      blurImage: collectionImage({} as any, `blur_${i.url}`),
      thumbnail: collectionImage({} as any, `${i.url}`, true),
    };
  });
  return [...poesterImage, ...attachImages];
}
export const getCuratorialProposalProjectImages = (proposal: ICuratorialProposalDocument) => {

  let attachImages = proposal.images.map((i: IVideo) => {
    return {
      original: collectionImage({} as any, `${i.url}`),
      blurImage: collectionImage({} as any, `blur_${i.url}`),
      thumbnail: collectionImage({} as any, `${i.url}`, true),
    };
  });
  return [...attachImages];
}