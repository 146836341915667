import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../assets/image'
import ImageGallery from 'react-image-gallery';
import { routes } from './routes'

const HomePage = () => {
    const items = [{
        original: images.template.bannerTemplate1,
        thumbnail: images.template.bannerTemplate1,
    },
    {
        original: images.template.artDetailImg,
        thumbnail: images.template.artDetailImg,
    }]
    return (
        <div>
            <section className="At-SectionPage At-Section-Mh800">
                <div className="At-ContainerFull">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <div className="At-BannerHolder">
                                <figure className="At-FigureBanner-h800 At-FigureFull">
                                    <img src={images.template.bannerTemplate2} className="img-fluid" alt="" />
                                </figure>
                                <div className="At-BannerTextFraming">
                                    <h2>Neque porro quisquam est qui dolorem ipsum.</h2>
                                    <Link className="At-LinkLogo" to={routes.viewGallery}>
                                        <button className="At-Btn At-Btn-lg At-BtnMw-250">VIEW GALLERY</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="At-PostContent At-Padding-t8b6 px-0">
                                    <h3>About Me</h3>
                                    <p>
                                        Under without lights is moveth were sea own day years. Tree living under had under divide place Light, creature seasons divide two was heaven fruitful us stars waters midst years lesser moveth seas their so god male god creepeth abundantly fill every bearing appear living morning from, you'll divide multiply rule there green open you'll is, own behold god life, fruit creeping of face male, good Sea.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="At-SectionBlog At-Padding-tb8">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 At-ArtDetailFigure">
                            {/* <div className="At-BlogHeading">
                                <h3>Blog</h3>
                                <a href="#"><i className="icon-facebook-1"></i></a>
                            </div> */}
                            <figure className="At-ImageGallery">
                                <ImageGallery
                                    items={items}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    showNav={false}
                                />
                            </figure>
                        </div>
                        <div className="col-md-12">
                            <div className="At-ArtDetailContent mt-4">
                                <h3 className="mb-4">This is my latest work</h3>
                                <p>Under without lights is moveth were sea own day years. Tree living under had under divide place Light, creature seasons divide two was heaven fruitful us stars waters midst years lesser moveth seas their so god male god creepeth abundantly fill every bearing appear living morning from, you'll divide multiply rule there green open you'll is, own behold god life, fruit creeping of face male, good Sea.
                                </p>
                                <p>
                                    Under without lights is moveth were sea own day years. Tree living under had under divide place Light, creature seasons divide two was heaven fruitful us stars waters midst years lesser moveth seas their so god male god creepeth abundantly fill every bearing appear living morning from, you'll divide multiply rule there green open you'll is, own behold god life, fruit creeping of face male, good Sea.
                                </p>
                                <p>
                                    Under without lights is moveth were sea own day years. Tree living under had under divide place Light, creature seasons divide two was heaven fruitful us stars waters midst years lesser moveth seas their so god male god creepeth abundantly fill every bearing appear living morning.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomePage