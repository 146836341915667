import React from 'react'
import { PLATFORM_FEE } from '../../../../../environment'
import { ICollectionItem, IUser } from '../../../../../interfaces'
import { IAuctionArtDocument, IAuctionDocument } from '../../../../../interfaces/auction.interface'
import { collectionImage } from '../../../../../shared'
import { CustomImage } from '../../../../../templates/components/CustomImage'
import { CurrencyFormat } from '../../../../common/CurrencyFormat'

interface IProps {
    auction: IAuctionDocument
    selectedArt?: ICollectionItem
    onSelecteArt: Function
    onAuctionArt: Function
    user: IUser
    auctionArt?: IAuctionArtDocument
    isRight?: boolean
}
const BiddingArtListBox = ({ auction, selectedArt, onSelecteArt, onAuctionArt, user, auctionArt, isRight }: IProps) => {

    const filterComingArts = () => {
        let filter = auction.arts.filter((a: ICollectionItem) => !a.sold && a.id !== auctionArt?.art.id)
        if (filter && filter.length > 0) {
            return true
        }
        return false
    }

    const filterSoldArts = () => {
        let filter = auction.arts.filter((a: ICollectionItem) => a.sold)
        if (filter && filter.length > 0) {
            return true
        }
        return false
    }
    return (
        <div className={`${isRight ? "at-greyborderbox mt-4 mt-sm-0" : "mh-100"} at-auction-comingup scrollable scrollbar-hidden`}>
            <div className="row at-row-mlr-12">
                <div className="col-md-12">
                    {isRight && filterComingArts() && <h4 className="at-color-grey at-textleft">Coming Up</h4>}
                    {!isRight && filterComingArts() && <h3 className="at-color-grey at-textleft at-fs30 mb-4">Coming Up</h3>}
                    {/* {auction.user._id === user._id && <button type="button" disabled={!selectedArt} className={`at-btn at-longbtn-h40 mb-3`} onClick={() => onAuctionArt()}>Add to auction</button>} */}
                </div>
            </div>
            <div className="row at-row-mlr-12">

                {auction && auction.arts && auction.arts.length > 0 && auction.arts.map((a: ICollectionItem, index: number) => {
                    let artUser = a.art_collection?.user
                    return (
                        !a.sold && a.id !== auctionArt?.art.id ? <div className={`${isRight ? "col-12" : "col-md-12 col-lg-6 col-xl-4"}`}>
                            <div key={a.id} className="At-BorderFigureHolder at-artgallerycollectionbox">
                                <div className="At-BorderFigure At-BorderFigure-h170">
                                    <CustomImage src={collectionImage({} as any, `${a.url}`)} thumbnail={collectionImage({} as any, `blur_${a.url}`)} />
                                    <div className="at-art-infobox">
                                        <h5 className="at-black-title">
                                            {a.name}
                                        </h5>
                                        {/* {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>} */}
                                        <p>{a.height}" X {a.width}" X {a.depth}" {a.artTechnique ? a.artTechnique : ''}</p>
                                        <p>Minimum Price: <CurrencyFormat amount={Number((Number(a.auctionMinPrice) + Number(PLATFORM_FEE) + Number(a.shipping)).toFixed(2))} /></p>
                                        {artUser && <p>Artist: {artUser.firstName} {artUser.lastName}</p>}
                                        {/* <i className="icon-dotted-circle" onClick={() => onClickOnItem(i)}></i> */}
                                    </div>
                                    {/* {user._id === auction.user._id && !(a.sold) && (a.id !== auctionArt?.art.id || (a.id === auctionArt?.art.id && !(auctionArt?.activeForBidding))) && <span className="at-checkbox at-checkbox-width40" onClick={() => onSelecteArt(a)}>
                                        <input type="checkbox" name="select" id="artcollectionselectall" checked={a.id === selectedArt?.id} />
                                        <label htmlFor="artcollectionone" ></label>
                                    </span>} */}
                                    {/* <span className="at-round-count">
                                        {index + 1}
                                    </span> */}
                                    {user._id === auction.user._id && !(a.sold) && (a.id !== auctionArt?.art.id || (a.id === auctionArt?.art.id && !(auctionArt?.activeForBidding))) && <div className="at-abs-btnholder">
                                        <button className="at-btn at-smallbtn text-capitalize" onClick={() => onAuctionArt(a.id)}>Add To Auction</button>
                                    </div>}
                                </div>

                            </div>
                        </div> : null

                    )
                })}
            </div>

            <div className="row at-row-mlr-12">
                <div className="col-md-12">
                    {isRight && filterSoldArts() && <h4 className="at-color-grey at-textleft">Sold</h4>}
                    {!isRight && filterSoldArts() && <h3 className="at-color-grey at-textleft at-fs30 mb-4">Sold</h3>}
                    {/* {auction.user._id === user._id && <button type="button" disabled={!selectedArt} className={`at-btn at-longbtn-h40 mb-3`} onClick={() => onAuctionArt()}>Add to auction</button>} */}
                </div>
            </div>
            <div className="row at-row-mlr-12">

                {auction && auction.arts && auction.arts.length > 0 && auction.arts.map((a: ICollectionItem, index: number) => {
                    let artUser = a.art_collection?.user
                    return (
                        a.sold ? <div className={`${isRight ? "col-12" : "col-md-12 col-lg-6 col-xl-4"}`}>
                            <div key={a.id} className="At-BorderFigureHolder at-artgallerycollectionbox">
                                <div className="At-BorderFigure At-BorderFigure-h170">
                                    <CustomImage src={collectionImage({} as any, `${a.url}`)} thumbnail={collectionImage({} as any, `blur_${a.url}`)} />
                                    <div className="at-art-infobox">
                                        <h5 className="at-black-title">
                                            {a.name}
                                        </h5>
                                        {/* {a.sold && <h6 className="at-soldlabel at-soldlabel-top15neg">SOLD</h6>} */}
                                        {/* {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>} */}
                                        <p>{a.height}" X {a.width}" X {a.depth}" {a.artTechnique ? a.artTechnique : ''}</p>
                                        <p>Minimum Price: <CurrencyFormat amount={Number((Number(a.auctionMinPrice) + Number(PLATFORM_FEE) + Number(a.shipping)).toFixed(2))} /></p>
                                        {artUser && <p>Artist: {artUser.firstName} {artUser.lastName}</p>}
                                        {/* <i className="icon-dotted-circle" onClick={() => onClickOnItem(i)}></i> */}
                                    </div>
                                    {/* {user._id === auction.user._id && !(a.sold) && (a.id !== auctionArt?.art.id || (a.id === auctionArt?.art.id && !(auctionArt?.activeForBidding))) && <span className="at-checkbox at-checkbox-width40" onClick={() => onSelecteArt(a)}>
                                        <input type="checkbox" name="select" id="artcollectionselectall" checked={a.id === selectedArt?.id} />
                                        <label htmlFor="artcollectionone" ></label>
                                    </span>} */}
                                    {/* <span className="at-round-count">
                                        {index + 1}
                                    </span> */}
                                    <div className="at-abs-btnholder">
                                        <button className="at-btn at-btn-red at-smallbtn text-capitalize">Sold</button>
                                    </div>
                                </div>

                            </div>
                        </div> : null

                    )
                })}
            </div>

        </div>
    )
}

export default React.memo(BiddingArtListBox)