import { routes } from "../router";
import { routes as artShowRoutes } from '../templates/Art_Show_Format/routes'
import { routes as catalogueRoutes } from '../templates/Catalogue_Format/routes'
import { routes as galleryRoutes } from '../templates/Gallery_Format/routes'
import { routes as studioRoutes } from '../templates/Studio_Format/routes'
import { store } from '../store'
import { ITemplateReducerState } from "../interfaces";

class RouterService {
    template: ITemplateReducerState = store.getState().template
    artCentralSpaceRoutes: Array<string> = [routes.artCentral.collection.list,
    routes.artCentral.collection.view,
    routes.artCentral.collection.item,
    routes.agent.showAssigment,
    routes.userAlert,
    routes.notifications,
    routes.artCentral.events.view,
    routes.artCentral.events.list,
    routes.loadingRoutes,
    routes.artCentral.gelleryWalk.index,
    routes.artCentral.gelleryWalk.view,
    routes.account.services.auction.index

    ];

    isArtCentralSpace = (route: string): boolean => {
        let templatedata: ITemplateReducerState = store.getState().template
        let templateRoutes: Array<string> = [
            artShowRoutes(templatedata.url).home,
            artShowRoutes(templatedata.url).Contact,
            artShowRoutes(templatedata.url).about,
            studioRoutes(templatedata.url).home,
            studioRoutes(templatedata.url).Contact,
            studioRoutes(templatedata.url).about,
            artShowRoutes(templatedata.url).exhibitions.index,
            galleryRoutes(templatedata.url).exhibitions.index,
            artShowRoutes(templatedata.url).artists.index,
            galleryRoutes(templatedata.url).artists.index,
            catalogueRoutes(templatedata.url).gallery.index,
            catalogueRoutes(templatedata.url).exhibitions.index,
            studioRoutes(templatedata.url).gallery.index,
            studioRoutes(templatedata.url).exhibitions.index,
        ]

        let combineArray = this.artCentralSpaceRoutes.concat(templateRoutes)
        return combineArray.some((r: string) => route.includes(r));
    }

}

export const routerService = new RouterService();