import { AxiosResponse } from 'axios'
import React, { useState, useEffect, FormEvent } from 'react'
import { IShippingFactorDocument } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { adminService, toastService } from '../../../services'
import { ErrorComponent } from '../../common/Error.component'

interface IProps {
    shipping?: IShippingFactorDocument
    fetch: Function
}
export const AdminCostFactor = ({ shipping, fetch }: IProps) => {

    const [errors, setErrors] = useState<any>({})
    const [businessProtectionFactor, setBusinessProtectionFactor] = useState<number>(NaN)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (shipping && shipping.businessProtectionFactor) {
            setBusinessProtectionFactor(shipping.businessProtectionFactor)
        }
    }, [shipping])

    const onValidate = () => {

        let newErr: any = {}
        if (!businessProtectionFactor ) {
            newErr['businessProtectionFactor'] = ['Business Protection Factor is required']
            setErrors({ ...newErr })
            return false
        }

        if (isNaN(businessProtectionFactor) || Number(businessProtectionFactor) < 1) {
            newErr['businessProtectionFactor'] = ['Value must be greater than or equal to 1']
            setErrors({ ...newErr })
            
            return false
        }

        setErrors({})
        return true
    }

    const onAddFactor = (e: FormEvent) => {
        e.preventDefault()
        if (!onValidate()) return
        setLoading(true)
        adminService.createCostFactors({ businessProtectionFactor }).then((res: AxiosResponse<IResponse<IShippingFactorDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetch()
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }

    return (

        <div className="">
            <form onSubmit={onAddFactor} className="at-formtheme at-formcreatecollection px-0 pt-3">
                <div className="row row-margin-lr7 align-items-center">
                    <div className="col-12 pad-lr-7 pt-3">
                        <div className="form-group at-floatlabel-h40">
                            <input type="number" name="businessProtectionFactor" className="form-control" value={businessProtectionFactor} placeholder=" " onChange={(e: any) => setBusinessProtectionFactor(e.target.value)} />
                            <label>Business Protection Factor</label>
                            {errors && errors['businessProtectionFactor'] && <ErrorComponent errors={errors['businessProtectionFactor']} multiple={false} />}
                        </div>
                    </div>
                    <div className="col-6">

                    </div>
                    <div className="col-6 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-longbtn-h40" type="submit" disabled={loading}>{loading ? 'Wait...' : 'Save'}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}