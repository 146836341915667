import React, { lazy, Suspense } from 'react'

const AdminSubscriptionPage = lazy(() => import('../../components/Admin/Subscription.page'))

 const AdminSubscriptionPageLazyloading = (props: any) => {
    return (
        <Suspense fallback={null}>
            <AdminSubscriptionPage {...props} />
        </Suspense>
    )
}

export default AdminSubscriptionPageLazyloading