import React, { Component } from "react";

  class PostShimmerComponent extends Component<any> {
        render() {
            return (
                <div className={`at-post`}>
                    <div className="at-orangeeconhead">
                    <figure>
                        <img src={' '} className="ph" alt=""/>
                    </figure>
                        <div className="at-orangeeconcontent">
                            <span className="at-postcontenticon"><i className="icon-hand"></i></span>
                            <h3 className="ph" style={{height: '25px', width: '100px'}}></h3>
                            <div className="at-btniconholder">
                                <a className="at-btnheart at-postbtn cursor-pointer">
                                    <i className={`icon-favorite`}></i>
                                </a>
                                <a className="at-btnmenu at-postbtn"><i className="icon-menu"></i></a>
                                <a id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a>
                                <a className="at-btnmore at-postbtn cursor-pointer"><i className="icon-more"></i></a>
                            </div>
                        </div>
                    </div>
                    <figure className="at-postimg">
                        <div className="ph" style={{width: '100%', height: '300px'}}></div>
                    </figure>
                    <div className="at-description">
                        <p className="ph" style={{height: '20px', width: '100%'}}></p>
                        <p className="ph" style={{height: '20px', width: '50%', marginTop: '5px'}}></p>
                        <span className="ph" style={{height: '18px', width: '200px', marginTop: '10px'}}></span>
                        <div className="at-post-socialicons">
                        <span>Post to</span>
                        <ul className="at-socialicons">
                            {/* <li><a href="#"><i className="icon-facebook-1"></i></a></li>
                            <li><a href="#"><i className="icon-instagram-1"></i></a></li> */}
                            
                            <li>
                                <a>
                                    <i className="icon-facebook-1"></i>
                                </a>
                            </li>
                            {/* <li><a href="#"><i className="icon-instagram-1"></i></a></li> */}
                            
                        </ul>
                    </div>
                    </div>
                    <form className="at-formtheme at-formpostcomment">
                        <fieldset>
                            <div className="form-group">
                                <figure className="at-addcommentimg">
                                    <img src={''} className="ph" alt=""/>
                                </figure>
                                <span className="left text-date cursor-pointer">Comments </span>
                                {/* <input type="text" name="addcomment" disabled={true} onClick={openCommentBox} value={comment} className="form-control" placeholder="Add Comment..." onChange={this.onChangeText} autoComplete="off"/> */}
                            </div>
                        </fieldset>
                    </form>
                </div>
            );
        }
  }
   
  export default PostShimmerComponent;