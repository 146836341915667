import React from 'react'
import ImageGallery from 'react-image-gallery';
import LazyLoadImageComponent from '../../components/common/Lazy-load-image.component';
import { IPost, IPostImage } from '../../interfaces';
import { postImage } from '../../shared';
import { CustomText } from './CustomText';

interface IProps {
    post: IPost
}
export const PostComponent = ({ post }: IProps) => {
    let assets = post.assets.map((a: IPostImage) => {
        return {
            original: postImage(post._id, a.url),
            thumbnail: postImage(post._id, a.isVideo ? a.thumbnail : a.url, true),
            isVideo: a.isVideo
        }
    })

    const onSlideBefore = (index: number) => {

    }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="At-ArtDetailContent At-TextJustify mb-4">
                    <CustomText>
                        <p>{post.description}</p>
                    </CustomText>
                </div>
            </div>
            <div className="col-md-12 At-ArtDetailFigure">
                <figure className="At-ImageGallery">
                    <ImageGallery onBeforeSlide={onSlideBefore} showPlayButton={false} showFullscreenButton={false} showNav={false} items={assets} renderItem={(item: any, index: number) => <LazyLoadImageComponent id={item._id} index={index} isVideo={item.isVideo} videoUrl={item.original} midResolution={item.original} thumbnail={item.thumbnail} />} />
                </figure>
            </div>

        </div>
    )
}