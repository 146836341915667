import { HttpService } from './base.service'
import { data } from 'jquery';

class ConsultantService extends HttpService {
  private readonly prefix: string = 'services'
  /**
   * 
   * Create Caregory
   */
  addCategories = (data: any): Promise<any> => this.post(`${this.prefix}/category`, data)



  /**
   * 
   * Add Tutorials
   */
  addServices = (data: any): Promise<any> => this.post(`${this.prefix}/add-service`, data)

  /**
   * Update Services
   * @param id
   * @return Promise
   */

   updateCategoryName = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/edit-category-name/${id}`, data)

  /**
  * 
  * Get Categories
  */
  getCategories = (): Promise<any> => this.get(`${this.prefix}/categories`)

  /**
   * 
   * Get Categories by Id
   */
  getCategoryById = (id: string): Promise<any> => this.get(`${this.prefix}/category/${id}`)

  /**
  * 
  * Get All Service
  */
  getAllService = (data: any): Promise<any> => this.get(`${this.prefix}/all-Services`, { ...data })

  /**
   * 
   * Get Tutorials by Id
   */
  getServiceById = (id: string): Promise<any> => this.get(`${this.prefix}/service/${id}`)

  /**
   * 
   * Update Service
   */

  addServiceData = (data: any): Promise<any> => this.post(`${this.prefix}/add-service-data`, data)

  /**
   * Send Sms
   * @param data
   * @return Promise
   */

  sendSms = (data: any): Promise<any> => this.post(`${this.prefix}/send-sms`, data)

  /**
   * Get Service by Name
   * @param name
   * @return Promise
   */

  getServiceByName = (name: string): Promise<any> => this.get(`${this.prefix}/service-by-name/${name}`)

  /**
   * Buy Services
   * @param data
   * @return Promise
   */
  buyService = (data: any): Promise<any> => this.post(`${this.prefix}/buy-service`, data)

  /**
   * Delete Services
   * @param id
   * @return Promise
   */

  deleteService = (id: any): Promise<any> => this.put(`${this.prefix}/delete-service/${id}`)


  /**
   * Updateing Service
   * @param data
   * @return Promise
   */
  updateSingleService = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/update-signle-service/${id}`, data)

  /**
   * Buyer Services Transaction
   * @param id
   * @return Promise
   */
  getBuyerTransactions = (id: any): Promise<any> => this.get(`${this.prefix}/buyer-transaction/${id}`)

  /**
   * Scheduled Services billing
   * @param id
   * @return Promise
   */
  getScheduledBilling = (id: any): Promise<any> => this.get(`${this.prefix}/scheduled-payments/${id}`)

  /**
   * Seller Services Transaction
   * @param id
   * @return Promise
   */
  getSellerTransactions = (id: any): Promise<any> => this.get(`${this.prefix}/seller-transaction/${id}`)

  /**
   * Admin Services Transaction
   * @return Promise
   */
  getAdminTransactions = (): Promise<any> => this.get(`${this.prefix}/all-admin-transaction`)

  /**
    * Release Services Funds
    * @param data
    * @return Promise
    */
  releaseServiceFunds = (data: any): Promise<any> => this.post(`${this.prefix}/payout/release`, data)


  /************************************************* Printing Serives *****************************************************/

  /**
   * Get Printing And Framing Services
   *
   * @return Promise
   */

  retrievePrintingService = (): Promise<any> => this.get(`${this.prefix}/retrieve-printing-service`)

  /**
   * Add Printing And Framing Services
   * @param data
   * @return Promise
   */

  addPrintingService = (data: any): Promise<any> => this.post(`${this.prefix}/add-printing-service`, data)

  /**
   * Delete Printing And Framing Services
   * @param id
   * @return Promise
   */
  deletePrintingService = (id: any): Promise<any> => this.put(`${this.prefix}/delete-printing-service/${id}`)


  /**
  * Get Printing And Framing Sizes
  *
  * @return Promise
  */

  retrievePrintingSizes = (): Promise<any> => this.get(`${this.prefix}/retrieve-printing-sizes`)

  /**
   * Add Printing And Framing Sizes
   * @param data
   * @return Promise
   */

  addPrintingSizes = (data: any): Promise<any> => this.post(`${this.prefix}/add-printing-sizes`, data)

  /**
   * Delete Printing And Framing Services
   * @param id
   * @return Promise
   */
  deletePrintingSize = (id: any): Promise<any> => this.put(`${this.prefix}/delete-printing-sizes/${id}`)


  /**
     * Get Printing And Framing Services by Id
     * @param id
     * @return Promise
     */
  getPrintingServiceById = (id: string): Promise<any> => this.get(`${this.prefix}/printing-service/${id}`)

  /**
       * Add Printing And Framing Services data printing-services-by-user
       * @param data
       * @return Promise
       */
  addPrintingServiceData = (data: any): Promise<any> => this.post(`${this.prefix}/add-printing-service-data`, data)

  getAllPrintingServices = (data: any): Promise<any> => this.get(`${this.prefix}/all-printing-service-provider`, data)

  getPrintingServicesByUserId = (userId: string): Promise<any> => this.get(`${this.prefix}/printing-services-by-user/${userId}`)


  /**    SelectedService */

  addSelectedServiceData = (data: any): Promise<any> => this.post(`${this.prefix}/add-selected-service`, data)

  getSelectedServiceById = (id: string): Promise<any> => this.get(`${this.prefix}/get-selected-service/${id}`)

   /**
   * Swap Printing And Framing Services
   * @param data
   * @return Promise
   */

    swapPrintingService = (data: any): Promise<any> => this.post(`${this.prefix}/swap-service`, data)
}

export const consultantService = new ConsultantService();