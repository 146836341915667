import React, {Component} from 'react';
import {images} from "../../assets/image";
import {routes} from "../../router";
import { Link, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors';
import { activatePost } from '../../services';
import { ERole, ESelling } from '../../enums';

const BottomTabsComponent = ( props: RouteComponentProps ) =>  {
	const { location: {pathname, state} } = props
	const user = useSelector(userSelector)
	const isArtist = user && user.isArtist;
	const isAgent = user && user.isAgent;
	const showBottomIcon = user && user._id && (((user.isGuest) ||  (user.roles && (user.roles.length === 0 || user.roles[0].length === 0))) || (user.roles.includes(ERole.CONSULTANT)))
	const activePostClass = !showBottomIcon ? '' : 'at-btnnav-disabled';
	return (
		<nav className="at-nav at-orangeeconnav">
				<ul>
					<li>
						<Link className={pathname === routes.home ? 'text-secondary' : ''} 
							to={{
							pathname: routes.home,
							state
							}}><img src={images.homeGlobe} alt="Home" /><span>Home</span></Link></li>
					<li>
						<Link className={pathname === routes.artCentral.post.create && !showBottomIcon ? 'text-secondary' : activePostClass} to={!showBottomIcon ? routes.artCentral.post.create : pathname}><i className="icon-add-post"></i><span>Post</span></Link>
					</li>
					
					{
						!isAgent ? 
						<li><Link className={pathname === routes.artCentral.collection.create && !showBottomIcon ? 'text-secondary' : activePostClass} to={!showBottomIcon ? routes.artCentral.collection.create : pathname}><i className="icon-add-menu"></i><span>Collection</span></Link></li> :
						<li><Link className={pathname === routes.artCentral.agent.assignments ? 'text-secondary' : ''} to={routes.artCentral.agent.assignments}><i className="icon-menu"></i><span>Collection</span></Link></li>

					}
				 	<li>
						<Link className={pathname === routes.artCentral.events.create && !showBottomIcon ? 'text-secondary' : activePostClass} to={!showBottomIcon ? routes.artCentral.events.create : pathname}><i className="icon-event"></i><span>Event</span></Link>
					</li>
					<li><Link className={pathname === routes.account.index ? 'text-secondary' : ''} to={routes.account.index}><i className="icon-user-2"></i><span>Account</span></Link></li>
				</ul>
			</nav>
	);
}

export default BottomTabsComponent;