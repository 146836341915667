import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import { connect } from 'react-redux';
import { IStoreReducers } from '../interfaces';
import MainRoute from '../router/main.route';
import { Subscription } from 'rxjs';
import { _logger } from '../helper';
import { AgoraLeaveActions } from '../services';
 
class IdleTimeComopnent extends Component<any> {

    idleTimer: any;
    sub$: Subscription = new Subscription();
    state = {
        connected: false,
        counter: 0
    }
    constructor(props: any) {
        super(props)
        this.idleTimer = null
    }

    handleOnAction = (event: any) => {
        // console.log('user did something', event)
    }
    
    handleOnActive = (event: any) => {
        // console.log('user is active', event)
        // console.log('time remaining', this.idleTimer.getRemainingTime())
    }
    
    handleOnIdle = (event: any) => {
        // console.log('user is idle', event)
        // console.log('last active', this.idleTimer.getLastActiveTime())
        AgoraLeaveActions.triggerAction(true)
    }

    render() {
        return (
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 480}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <MainRoute />
            </>
        )
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(IdleTimeComopnent);