import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import SigninComponent from '../../../components/Authentication/Signin.component'
import PortalChild from '../../../components/common/PortalChild'
import { setInPlatformWebState } from '../../../store/actions'
import { authSelector, templateSelector } from '../../../store/selectors'
import { TemplateSignup } from './TemplateSignup.component'

interface IProps extends RouteComponentProps {
    closeAuth: Function
}
const TemplateAuthComponent = (props: IProps) => {

    const dispatch = useDispatch()
    const auth = useSelector(authSelector).access_token
    const template = useSelector(templateSelector)
    const [showSignin, setShowSignin] = useState<boolean>(true)
    const [showSignup, setShowSignup] = useState<boolean>(false)

    const openSignIn = () => {
        setShowSignin(true)
    }

    const closeSignIn = () => {
        if (!auth) {
            dispatch(setInPlatformWebState({
                activeIFrame: false,
                nextPath: ``,
                iFrameUrl: ``,
                activeSplitScreen: false
            }))
            props.closeAuth()
            props.history.push(`/${template.url}`)
        }
        setShowSignin(false)
    }

    const openSignUp = () => {
        setShowSignup(true)
    }

    const closeSignUp = () => {
        setShowSignup(false)
    }

    return (
        <>
            <PortalChild>
                {showSignin && <div id="at-themeloginpopup" className={`at-themepopupholder ${showSignin && 'at-showthemeloginpopup'}`}>
                    <SigninComponent onSigninSucees={() => { }} {...props} onClose={closeSignIn} openSignup={openSignUp} openForgotPassword={() => { }} />
                </div>}

                {showSignup && <div id="at-themeloginpopup" className={`at-themepopupholder ${showSignup && 'at-showthemeloginpopup'}`}>
                    <TemplateSignup onClose={closeSignUp} openSignin={openSignIn} />
                </div>}
            </PortalChild>
            <section className="At-SectionPage At-Padding-t4b10 At-Section-Mh800">

            </section>
        </>
    )
}

export default withRouter(TemplateAuthComponent)