import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { ERole } from '../../../enums'
import { ICollectionItem } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { IGalleryWalkSpacesDocument } from '../../../interfaces/Gallery-walk.interface'
import { routes } from '../../../router'
import { galleryWalkService } from '../../../services'
import { collectionImage } from '../../../shared'
import { NoDataComponent } from '../../common/NoData.component'
import { UserNameComponent, UserPicComponent } from '../../User/index.component'

export const GalleryWalkFeatureArtsComponent = () => {

    const params = (useParams() as any).id
    const [galleryWalkSpace, setGalleryWalkSpace] = useState<IGalleryWalkSpacesDocument>()
    const [loading, setLoading] = useState<boolean>(false)
    const history = useHistory()
    const fetchGalleryWalk = () => {
        if (!params) return
        setLoading(true)
        galleryWalkService.getSingleGallerySpace(params).then((res: AxiosResponse<IResponse<IGalleryWalkSpacesDocument>>) => {
            setGalleryWalkSpace(res.data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchGalleryWalk()
    }, [])
    return (
        <div className='at-postcollections scrollable at-contentarea'>
            {galleryWalkSpace && galleryWalkSpace.user && <div className='at-collections'>
                <div className='at-orangeeconhead'>
                    <a
                        onClick={() => history.goBack()}
                        className='at-left-arrowicon'
                    >
                        <i className='icon-left-arrow'></i>
                    </a>
                    {galleryWalkSpace.user && <UserPicComponent user={galleryWalkSpace.user} />}
                    <div className='at-orangeeconcontent'>
                        {galleryWalkSpace.user?.isDeaf && (
                            <span className='at-postcontenticon'>
                                <i className='icon-hand'></i>
                            </span>
                        )}
                        <fieldset className="at-artcollectioncontent w-100">
                            <h3>
                                {`Featured Art`}
                                {galleryWalkSpace.user && <UserNameComponent user={galleryWalkSpace.user} />}
                            </h3>

                        </fieldset>
                    </div>
                </div>
                <div className="at-gallerytitle at-paddinglr-15">
                    {/* <h3 className="d-block mb-2">
                        <Link to="#">
                            {event.name}
                        </Link>
                        <span>{event.items.length} Images</span>
                    </h3> */}

                </div>
                <fieldset className="at-artgallerycollections at-themescrollbar">
                    <ul className="at-artcollectiongrid at-artgallerycollection" id="dnd" >
                        {
                            galleryWalkSpace.featuredArts.length < 1 && !loading && <NoDataComponent icon="fa fa-images" />
                        }
                        {
                            galleryWalkSpace.featuredArts.map((item: ICollectionItem, index: any) => (
                                <li key={item.id} className="itemImage" id={`${item.id}`} onDoubleClick={() => { history.push(`${routes.artCentral.collection.item}/${item.id}`) }}>
                                    <div className="at-artgallerycollectionbox atv2-collectionbox">
                                        <span className="at-checkbox" title="Select">
                                            <img src={collectionImage(item, `high_${item.url}`, true)} alt={collectionImage(item, `high_${item.url}`, true)} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />

                                        </span>
                                        <div className="at-clearfix"></div>
                                        <div className="at-artdetail-box">
                                            {item.name && <h5>
                                                {item.name}
                                            </h5>}
                                            {item.artTechnique && item.height && item.width && <p> {item.height}" X {item.width}" {item.depth ? `X ${item.depth}"` : null}   {item.artTechnique}</p>}
                                            <i className="icon-dotted-circle" title="Art Detail" onClick={() => { history.push(`${routes.artCentral.collection.item}/${item.id}`) }} ></i>
                                        </div>
                                    </div>

                                </li>
                            ))
                        }
                    </ul>
                </fieldset>
            </div>}
        </div>
    )
}