import React, { Component, FormEvent } from 'react'
import axios, { AxiosResponse } from 'axios';
import { connect } from 'react-redux'
import { FacebookShareButton, TumblrIcon } from 'react-share'
import { collectionService } from '../../../services/collection.service';
import { ICollectionItem, IUser, ICollection, IArtAttachment, IFormates, IOffer, IOFFerResponse, IArtAssignment, IStoreReducers } from '../../../interfaces';
import { toastService, isArtist, offerService, assignmentService, paymentService, userService, transactionService } from '../../../services';
import { RouteComponentProps, Link } from 'react-router-dom';
import { UserPicComponent, UserNameComponent } from '../../User/index.component';
import MultiClickComponent from '../../common/MultiClick.component';
import { collectionImage } from '../../../shared';
import { routes } from '../../../router';
import { lang } from '../../../locale';
import PropTypes from 'prop-types';
import { EZoom, ZoomActions } from '../../../services/zoom.service';
import { currencyFormatter } from '../../../helper';
import { UploaderComponent } from '../../common/Upload.component';
import { API_ENDPOINT } from '../../../environment';
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component';
import { ThanyouPopup } from '../../common/Modals/ThankyouPopup'
import { PayPopup } from '../../common/Modals/PayPopup'
import { AddressPopup } from '../../common/AddressPopup'
import { CurrencyFormat } from '../../common/CurrencyFormat'
import { ErrorComponent } from '../../common/Error.component'
import { CurrencyField } from '../../common/CurrencyInput'
import { EArtistPrintType, EPrintType, ERole } from '../../../enums';
import { PLATFORM_FEE } from '../../../environment'
import { ImageLoading } from '../../common/ImageLoading';
import { UniversalModal } from '../../common/Modals/Universal.modal';
import { ShowArtServiceComponent } from '../ArtServices/ShowArtService.component';
import { IArtistSelectedService, ISelectedService } from '../../../interfaces/services.interface';
import { IResponse } from '../../../interfaces/db.interface';



interface IProps extends RouteComponentProps {
    user: IUser
    item?: ICollectionItem
    hideBackButton?: boolean
    hideMoreButton?: boolean
    hideZoomButton?: boolean
    refresh: Function
    isAgentView?: boolean
    agent?: IUser,
    actionOffer?: IOffer,
    studioUser?: IUser
    refreshOffer?: any
    onEditItem?: any
    onBack?: any
}

interface userAddress {
    shippingAddress: string
    city: string
    zipcode: string
    state: string
    loading: boolean
}

const adressState = {
    shippingAddress: '',
    city: '',
    zipcode: '',
    state: '',
    loading: false
}

interface IPageState {
    item?: ICollectionItem
    openAction: boolean
    deleting: boolean
    heart: boolean
    showMoreItemPopup: boolean
    thankYouPopup: boolean
    showMessageBox: boolean
    imageUrl: string,
    offer: string,
    collection?: ICollection
    payPopup: boolean,
    offerStatus?: IOFFerResponse,
    userPage: boolean,
    formatSelected?: IFormates,
    errors: any
    isApiCall: boolean,
    showAdrressPopup: boolean
    userAddress: userAddress,
    tax: number,
    loading: boolean,
    instance: any,
    itemType: string,
    showPaymentForm: boolean,
    paymentMessage: string,
    studioUser: any
    inValidAddress: boolean
    stripeData: any
    showService: boolean
}

class CollectionItemViewComponent extends Component<IProps> {
    state: IPageState = {
        openAction: false,
        deleting: false,
        heart: false,
        showMoreItemPopup: false,
        thankYouPopup: false,
        imageUrl: '',
        showMessageBox: false,
        offer: '',
        payPopup: false,
        userPage: false,
        errors: {},
        isApiCall: false,
        showAdrressPopup: false,
        userAddress: adressState,
        tax: 0,
        loading: false,
        instance: null,
        itemType: '',
        showPaymentForm: false,
        paymentMessage: '',
        studioUser: {},
        inValidAddress: false,
        stripeData: {},
        showService: false,
    }

    componentWillReceiveProps(props: IProps) {
        let checkItem = this.checkItemFormats(this.props.item as ICollectionItem)
        this.setState({ imageUrl: checkItem?.url, item: checkItem }, () => {
            this.fetchCollection()
        })
    }

    componentDidMount() {
        const { item, user, actionOffer } = this.props
        let checkItem = this.checkItemFormats(this.props.item as ICollectionItem)
        this.setState({
            item: {
                ...checkItem,
            },
            imageUrl: checkItem?.url,
            userPage: this.checkUserProfile()
        }, () => {
            this.validateDataOffer()
            this.fetchCollection()
        })

    }

    checkItemFormats = (item: ICollectionItem) => {
        if (!item) return item
        let formats = item.formates

        if (formats && formats.length > 0) {
            let newFormats = formats.map((f: IFormates) => {
                if (f.printType === EArtistPrintType.UNFRAMED) {
                    f.buyerSelectedService = (f.selectedService as IArtistSelectedService)?.selectedServices[0]
                }
                return f
            })
            item.formates = newFormats
        }
        return item
    }


    validateDataOffer = () => {
        const { item, user, actionOffer } = this.props
        // if (actionOffer?.offeredBy !== user._id){
        //   toastService.error("You are not autorized for this link")
        //   this.props.history.goBack()
        // }
        if (actionOffer?.approved && !item?.sold) {
            this.setState({ showAdrressPopup: true, itemType: 'offered' })
        }
        if (actionOffer?.approved && item?.sold) {
            toastService.info("Art is already sold")
        }
    }
    checkUserProfile = () => {
        let { user, item } = this.props
        let _user = item?.assignment?.user as IUser;
        if (user.roles.includes(ERole.AGENT) && user._id === _user?._id) {
            return true
        }
        if (user.roles.includes(ERole.ARTIST) && user._id === item?.art_collection.user._id) {
            return true
        }

        return false
    }

    fetchCollection = () => {
        const { item } = this.state
        const { user } = this.props
        if (!item) return
        collectionService.show(item.art_collection.id).then((response: AxiosResponse<IResponse<ICollection>>) => {
            this.setState({ collection: response.data.data })
        }).catch((error) => {
            console.log("Unable to get Collection", error)
        })
    }

    doFavorit = (favorit: boolean) => {
        if (!this.state.item) return;
        let { item } = this.state;
        if (favorit) {
            item.favoritedBy = [...item.favoritedBy, this.props.user._id];
        } else {
            item.favoritedBy = item.favoritedBy.filter((f: string) => f !== this.props.user._id);
        }

        this.setState({ item })
        if (item) {
            collectionService.likeUnlikeItem(item.id || '', favorit);
        }

    }

    favorit = (item: ICollectionItem) => {
        return item.favoritedBy.some((v: string) => v === this.props.user._id);
    }

    openAction = () => {
        this.setState({ openAction: true })
    }

    openDeleteBox = () => {
        this.setState({ openAction: false, openDeleting: true })
    }

    close = () => {
        this.setState({ openAction: false, openDeleting: false })
    }

    deleteItems = () => {
        const { item } = this.state;
        if (!item) return;

        collectionService.deleteItems(item.art_collection.id, { items: [item.id] }).then((response: any) => {
            toastService.success(response.data.message)
            this.close();
            this.props.history.goBack()
        }, error => {
            // toastService.error(lang.common.httpError)
        })
    }

    doubleClick = (status: boolean) => {
        this.setState({ heart: true })
        this.doFavorit(status)
        setTimeout(() => {
            this.setState({ heart: false })
        }, 1000);
    }

    onUploadComplete = () => {
        toastService.success('Items uploaded successfully.')
        this.setState({ showMoreItemPopup: false })
        this.props.refresh()
    }

    onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        let offer = e.target.value
        if (offer && e.target.name === "offer") {
            offer = offer.split("$")[1].replace(/,/gi, '')
        }
        this.setState({ [e.target.name]: offer, formatSelected: null, itemType: 'item' })
    }

    setSellerId = () => {
        let { item, collection } = this.state
        let { studioUser } = this.props
        let agent = (item?.assignment?.user as IUser)

        if (!agent) {
            return { sellerID: collection?.user._id, agentID: null, artistID: collection?.user._id }
        }
        if (item?.assignment?.type === 'non-exclusive' && studioUser && !studioUser?.roles.includes(ERole.AGENT)) {
            return { sellerID: collection?.user._id, agentID: null, artistID: collection?.user._id }
        }
        if (item?.assignment?.type === 'non-exclusive' && studioUser?._id === agent._id) {
            return { sellerID: agent._id, agentID: agent._id, artistID: collection?.user._id }
        }
        if (!item?.assignment) {
            return { sellerID: collection?.user._id, agentID: null, artistID: collection?.user._id }
        }

        return { sellerID: agent._id, agentID: agent._id, artistID: collection?.user._id }
    }

    onMakingOffer = () => {
        const { item, offer, collection, errors } = this.state
        if (!item) return
        const { user } = this.props
        let price = item?.price ? item?.price : ''
        if (this.validateData('offer', 'Offer is required')) return;
        if (Number(offer) >= Number(this.artOriginalTotalCost(item))) {
            this.setState({ itemType: 'item' })
            this.onOpenAddressPopup()
            return
        }
        if (item && collection) {
            this.setState({ isApiCall: true, loading: true })
            let offeredUser = this.setSellerId()
            offerService.makeOffer({
                offeredPrice: offer,
                offeredBy: user._id,
                offeredTo: offeredUser.artistID,
                itemId: item?.id,
                agentId: offeredUser.agentID,
                approved: Number(offer) >= Number(this.artOriginalTotalCost(item)) ? true : false,
                pending: Number(offer) >= Number(this.artOriginalTotalCost(item)) ? false : true
            }).then((response: AxiosResponse<IOFFerResponse>) => {
                this.setState({ offerStatus: response.data, isApiCall: false, loading: false, offer: '' }, () => this.openThankPopup())
            }).catch((error) => {
                console.log("Unable to Make offer", error)
            })
        }
    }

    onItemBuy = () => {
        this.setState({ itemType: 'item', showAdrressPopup: true, })
    }

    validateData = (name: string, message: string): boolean => {
        let { errors } = this.state
        let value: any = this.state
        if (!value[name]) {
            let newError = errors
            newError[name] = [message]
            this.setState({
                errors: newError
            })
            return true
        } else if (value['offer'] === '0') {
            let newError = errors
            newError[name] = ['Please enter valid price.']
            this.setState({
                errors: newError
            })
            return true
        }
        else {
            this.setState({ errors: {} })
            return false
        }
    }

    onBuyFormats = (format: IFormates) => {
        let service = format.selectedService as IArtistSelectedService
        if (service && service._id && (service.printType === EArtistPrintType.FRAMED || service.printType === EArtistPrintType.UNFRAMED) && !format.buyerSelectedService) {
            toastService.warning("Please select framing option before buying")
            return
        }
        this.setState({ formatSelected: format, showAdrressPopup: true, itemType: 'format' })
        // const { item, offer, collection } = this.state
        // const { user } = this.props
        // this.setState({isApiCall: true})
        // if (item && collection) {
        //     let offeredUser = item?.assignment?.type === "exclusive" ? item?.assignment?.user : collection?.user._id
        //     offerService.makeOffer({
        //         offeredPrice: format.price,
        //         offeredBy: user._id,
        //         offeredTo: offeredUser,
        //         itemId: item?.id,
        //         formatesId: format?._id,
        //         approved: true,
        //         pending: false,
        //     }).then((response: AxiosResponse<IOFFerResponse>) => {
        //         this.setState({
        //             offerStatus: response.data,
        //             formatSelected: format,
        //             isApiCall: false
        //         }, () => {
        //             this.openThankPopup()
        //         })
        //     }).catch((error) => {
        //         console.log("Unable to Make offer", error)
        //     })
        // }
    }

    onOpenShowService = (format: IFormates) => {
        if (!format.selectedService) return
        this.setState({ formatSelected: format, showService: true })
    }

    onCloseShowService = () => {
        this.setState({ formatSelected: undefined, showService: false })
    }

    onSubmitShowService = (service: ISelectedService) => {
        if (!this.state.formatSelected) return
        let formats = this.state.item?.formates
        let newItem = this.state.item
        if (newItem && formats && formats.length > 0) {
            let newFormats = formats.map((f: IFormates) => {
                if (f._id === this.state.formatSelected?._id) {
                    f.buyerSelectedService = service
                }
                return f
            })
            newItem.formates = newFormats
            this.setState({ item: newItem })
        }
        this.onCloseShowService()
    }


    handlePayment = () => {
        let { item, userAddress, offerStatus, tax, formatSelected, offer, itemType } = this.state
        let { actionOffer, user } = this.props
        let offerData = actionOffer ? actionOffer : offerStatus
        let data
        this.setState({ loading: true })
        let sellerData: any = this.setSellerId()

        if (itemType === 'format' && formatSelected) {
            let totalPrice = `${parseFloat(formatSelected?.price) + parseFloat(formatSelected.shipping) + 10 + Number(tax)}`
            let serviceData = {}
            if (formatSelected.selectedService && (formatSelected.selectedService as IArtistSelectedService)._id) {
                let service = formatSelected.selectedService as IArtistSelectedService
                if (service.printType === EArtistPrintType.OWN) {
                    serviceData = {
                        service: service._id,
                    }
                } else {
                    serviceData = {
                        service: service._id,
                        buyerService: formatSelected.buyerSelectedService?._id
                    }
                }
            }
            data = {
                name: item?.name,
                price: formatSelected?.price,
                tax: tax,
                shippingPrice: formatSelected?.shipping,
                totalPrice: totalPrice,
                shippingAddress: userAddress.shippingAddress,
                zip: userAddress.zipcode,
                city: userAddress.city,
                state: userAddress.state,
                exclusive: item?.assignment?.type === "exclusive" ? true : false,
                ...sellerData,
                buyerID: user._id,
                itemID: item?.id,
                itemDescription: item?.description,
                quantity: 1,
                formatesId: formatSelected._id,
                itemName: formatSelected.size,
                ...serviceData
            }
        }

        if ((actionOffer) && itemType === 'offered') {
            let totalPrice = ''
            if (item && offerData) {
                totalPrice = `${parseFloat(actionOffer?.offeredPrice) + parseFloat(item?.shipping) + 10 + Number(tax)}`
            }
            data = {
                name: item?.name,
                price: actionOffer?.offeredPrice,
                tax: tax,
                shippingPrice: item?.shipping,
                totalPrice: totalPrice,
                shippingAddress: userAddress.shippingAddress,
                zip: userAddress.zipcode,
                city: userAddress.city,
                state: userAddress.state,
                exclusive: item?.assignment?.type === "exclusive" ? true : false,
                ...sellerData,
                buyerID: actionOffer?.offeredBy,
                itemID: item?.id,
                itemDescription: item?.description,
                quantity: 1,
                itemName: actionOffer.itemId.name
            }
        }

        if (item && itemType === 'item') {
            let totalPrice = offer ? offer : item?.price
            data = {
                name: item?.name,
                price: totalPrice,
                tax: tax,
                shippingPrice: item?.shipping,
                totalPrice: totalPrice,
                shippingAddress: userAddress.shippingAddress,
                zip: userAddress.zipcode,
                city: userAddress.city,
                state: userAddress.state,
                exclusive: item?.assignment?.type === "exclusive" ? true : false,
                ...sellerData,
                buyerID: user._id,
                itemID: item?.id,
                itemDescription: item?.description,
                quantity: 1,
                itemName: item.name
            }
        }
        this.handleAPICall(data)
    }
    handleAPICall = (data: any) => {
        this.setState({ loading: true })
        paymentService.checkout({
            ...data
        }).then(resp => {
            if (resp.data.success) {
                this.setState({
                    loading: false, showPaymentForm: true, stripeData: {
                        id: resp.data.id,
                        clientSecret: resp.data.client_secret
                    }
                })
            }
            if (!resp.data.success) {
                this.setState({ loading: false, showPaymentForm: false })
            }

        }).catch(error => {
            this.setState({ loading: false, showPaymentForm: false })
            console.log(error)
        })

    }

    // Pupups handlers
    openThankPopup = () => this.setState({ thankYouPopup: true })
    closeThankPop = () => this.setState({ thankYouPopup: false })
    onSubmitThankPopup = () => {
        this.closeThankPop()
        if (!this.state.offerStatus?.approved) return;
        this.onOpenAddressPopup()
    }

    openPayPopup = () => this.setState({ payPopup: true })
    closePayPopup = () => {

        this.setState({ payPopup: false, loading: false, showPaymentForm: false })
    }
    onConfirmPay = () => {
        this.handlePayment()
    }
    onSubmitPayPopup = () => {
        let { item, itemType } = this.state
        let data = (item as ICollectionItem)
        if (itemType !== 'format') {
            data.sold = true
        }

        this.setState({ payPopup: false, loading: false, showPaymentForm: false, stripeData: {}, item: data, offer: '' })
    }

    onOpenAddressPopup = () => this.setState({ showAdrressPopup: true })
    onAddressClose = () => this.setState({ showAdrressPopup: false })
    onSubmitAddressPopup = (address: userAddress) => {
        this.setState({ userAddress: address })
        this.getSaleTax(address)
    }

    getSaleTax = (address: userAddress) => {
        this.setState({ loading: true })
        let { formatSelected, item, itemType, collection, offer } = this.state
        let { actionOffer } = this.props

        let price = itemType === 'offered' ? actionOffer?.offeredPrice : offer ? offer : item?.price
        let shipping: any = item?.shipping
        if (itemType === 'format' && formatSelected) {
            price = Number(formatSelected.price).toFixed(2)
            let service = formatSelected.selectedService as IArtistSelectedService
            if (service && (service.printType === EArtistPrintType.UNFRAMED || service.printType === EArtistPrintType.FRAMED)) {
                console.log((formatSelected.selectedService as IArtistSelectedService)?.selectedServices[0]?.totalCost)
                price = Number(Number(price) + Number(formatSelected.buyerSelectedService?.totalCost || 0) + Number(PLATFORM_FEE)).toFixed(2)
            }

            if (service && service.printType === EArtistPrintType.OWN && service.artistOwnService && service.artistOwnService.price) {
                price = Number(Number(price) + Number(service.artistOwnService.price) + Number(service.artistOwnService.shipping)).toFixed(2)
            }
            shipping = formatSelected.shipping
        }
        if (price) {
            let totalPrice = `${parseFloat(price) + parseFloat(shipping) + parseFloat(PLATFORM_FEE)}`
            paymentService.taxPayment({
                state: address.state,
                address: address.shippingAddress,
                price: totalPrice,
                sellerID: this.setSellerId().sellerID,
                zip: address.zipcode,
                city: address.city
            }).then((res: AxiosResponse<any>) => {
                if (res.data.status === 'success') {
                    if (!res.data.total_tax || isNaN(res.data.total_tax)) {
                        this.setState({ inValidAddress: true, loading: false })
                        return
                    }
                    this.setState({ tax: `${res.data.total_tax}`, loading: false }, () => {
                        this.openPayPopup()
                        this.onAddressClose()
                    })
                }
                if (res.data.status === 'Fail') {
                    this.setState({ inValidAddress: true, loading: false })
                    return
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    setDropinInstance = (instance: any) => {
        this.setState({ instance: instance })
    }

    getActiveFormats = (item: ICollectionItem) => {
        let { formates } = item
        if (formates && formates.length > 0) {
            let check = formates.filter((format: IFormates) => format.active)
            if (check && check.length > 0) {
                return true
            }
            return false
        }
        return false
    }
    editItem = () => {
        let { onEditItem, history } = this.props
        let { item } = this.state
        let artCollection: ICollection = item && item.art_collection;
        if (onEditItem) {
            onEditItem(item)
            return
        }

        if (artCollection?.user.roles.includes(ERole.AGENT)) {
            history.push({ pathname: `${routes.account.agentCreateArtEdit}/${item && item.art_collection.id}/${item && item.id}` })
            return
        }
        history.push({ pathname: `${routes.artCentral.collection.edit}/${item && item.art_collection.id}/${item && item.id}` })
    }

    artOriginalTotalCost = (art: ICollectionItem) => {
        let total = Number(art.price) + Number(art.shipping) + Number(PLATFORM_FEE)
        return `${total}`
    }

    artFormatesTotalCost = (f: IFormates) => {
        let total = Number(f.price) + Number(f.shipping) + Number(PLATFORM_FEE)
        let service = f.selectedService as IArtistSelectedService
        if (service && (service.printType === EArtistPrintType.UNFRAMED || service.printType === EArtistPrintType.FRAMED)) {
            console.log((f.selectedService as IArtistSelectedService)?.selectedServices[0]?.totalCost)
            total = total + Number(f.buyerSelectedService?.totalCost || 0) + Number(PLATFORM_FEE)
        }

        if (service && service.printType === EArtistPrintType.OWN && service.artistOwnService && service.artistOwnService.price) {
            total = total + Number(service.artistOwnService.price) + Number(service.artistOwnService.shipping)
        }
        return Number(total).toFixed(2)
    }

    getButtonColor = (f: IFormates) => {
        if (f.selectedService && ((f.selectedService as IArtistSelectedService).printType === EArtistPrintType.FRAMED || (f.selectedService as IArtistSelectedService).printType === EArtistPrintType.UNFRAMED) && !f.buyerSelectedService) {
            return 'at-btn-grey'
        }

        if (f.selectedService && (f.selectedService as IArtistSelectedService).printType === EArtistPrintType.OWN) {
            return ''
        }

        return ''
    }

    getPrinterSizes = (f: IFormates) => {
        if (!f.selectedService) return
        let services = f.selectedService as IArtistSelectedService
        if (services && (services.printType === EArtistPrintType.FRAMED || services.printType === EArtistPrintType.UNFRAMED)) {
            if (!f.buyerSelectedService) return null
            return `${f.buyerSelectedService.selectedSize.height}x${f.buyerSelectedService.selectedSize.width}`
        }

        if (services && services.printType === EArtistPrintType.OWN) {
            if (!services.artistOwnService || !services.artistOwnService.price) return null
            return `${services.artistOwnService.height}x${services.artistOwnService.width}`
        }
    }

    checkFramedOption = () => {
        let { item } = this.state
        if (!item) return false

        if (item.formates.filter((f: IFormates) => (f.printType === EPrintType.FRAMED && f.active === true)).length > 0) {
            return true
        }

        return false
    }


    render() {
        const { item, heart, loading, inValidAddress, openAction, showAdrressPopup, showMoreItemPopup, paymentMessage, tax, thankYouPopup, imageUrl, payPopup, userPage, formatSelected, errors, offer, isApiCall, offerStatus, itemType, showPaymentForm } = this.state;
        const { user: { _id: userId }, hideBackButton, hideMoreButton, hideZoomButton, agent, isAgentView, actionOffer, studioUser } = this.props;
        let price = offer ? offer : (itemType === 'item' ? this.artOriginalTotalCost(item as ICollectionItem) : actionOffer?.offeredPrice)
        let artCollection: ICollection = item && item.art_collection;
        const attachments: Array<IArtAttachment> = !item ? [] : [{ url: item.url || '', id: item.id || '' }, ...(item.items || [])]
        let user = isAgentView ? ({ ...agent, isAgent: true } as IUser) : artCollection?.user;
        let shareUrl = `${API_ENDPOINT}/post-detail/item/${item?.id}`;
        return (
            <>
                {
                    (item) && (
                        <div className="at-collectiondetailholder">
                            <div className="at-orangeeconhead">
                                {!hideBackButton && <a onClick={() => { this.props.onBack ? this.props.onBack() : this.props.history.goBack() }} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                                <UserPicComponent user={user} />
                                <div className="at-orangeeconcontent">
                                    {user.isDeaf && <span className="at-postcontenticon"><i className="icon-hand"></i></span>}
                                    <h3 className="text-left">
                                        {isAgentView ? user.gallery_name : artCollection.title}
                                        {!isAgentView && <UserNameComponent user={user} />}
                                    </h3>
                                    <div className="at-btniconholder ">
                                        {/* Remove from SprintONE */}
                                        <a className="at-btnomniicon at-postbtn" title="Post">
                                            <FacebookShareButton quote={item.description} url={shareUrl} onShareWindowClose={() => { }}>
                                                {
                                                    (shareCount: any) => <span className="myShareCountWrapper">{shareCount}</span>
                                                }
                                                <i className="icon-facebook-1"></i>
                                            </FacebookShareButton>
                                        </a>
                                        {/* <a id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a> */}
                                        {!userPage && <a className="at-btnheart at-postbtn cursor-pointer" onClick={() => this.doFavorit(!this.favorit(item))}>
                                            <i className={`icon-favorite${this.favorit(item) ? '-2' : ''}`} title="Preferred"></i>
                                        </a>}
                                        {/* {!hideZoomButton && <a id="at-btnomniicon" title="Full Screen" className="at-btnomniicon at-postbtn at-hide-after767" onClick={() => {
                                            ZoomActions.triggerAction({ type: EZoom.COLLECTION_ITEM, value: item })
                                        }}><i className='icon-switch'></i></a>
                                        } */}
                                        {
                                            (!hideMoreButton && artCollection.user && artCollection.user._id === userId) && (
                                                <a className="at-btnmore at-postbtn" title="Edit" onClick={this.editItem}><i className="icon-edit2 fs-20 cursor-pointer right" title="Edit"></i></a>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="at-collectiondetailbox">
                                <figure className="at-roundbox-figure">
                                    <MultiClickComponent onDoubleClick={!userPage ? () => this.doubleClick(!this.favorit(item)) : () => { }}>
                                        {/* <img src={collectionImage(item, imageUrl || '')} style={{ backgroundImage: `url(${collectionImage(item, imageUrl || '', true)})` }} alt="No Data Exist" /> */}
                                        <ImageLoading src={collectionImage(item, imageUrl || '')} thumbnail={collectionImage(item, `blur_${imageUrl}` || '')} alt="No Data Exist" />
                                    </MultiClickComponent>
                                    {
                                        heart && <svg className={this.favorit(item) ? 'floatingheart' : 'floatingheart-o'} viewBox="0 0 32 29.6">
                                            <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                                            c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"/>
                                        </svg>
                                    }
                                </figure>
                                <div className="custom-gallery">
                                    {
                                        attachments.map((t: IArtAttachment, i: number) => <img key={`item-${i}`} src={collectionImage(item, `high_${t.url}` || '', true)} onClick={() => this.setState({ imageUrl: t.url })} />)
                                    }
                                </div>
                                <div className="at-collectiondetailcontent">
                                    <h3>{item.name}</h3>
                                    <p>Artist:
                                        <strong className="text-capitalize">
                                            {artCollection && artCollection?.user?.roles?.includes(ERole.AGENT) ? ` ${item.artistName}` || ` ${artCollection.user.firstName} ${artCollection.user.lastName}` : ` ${artCollection.user.firstName} ${artCollection.user.lastName}`}
                                        </strong>
                                    </p>

                                    <div className="at-description">
                                        {item.height && item.width && item.artTechnique && <p className=" mt-3">{item.height}" X {item.width}" {item.depth ? `X ${item.depth}"` : null} {item.artTechnique} </p>}
                                        <p>{item.description} </p>
                                    </div>
                                    {
                                        userPage && <div className="row my-3 d-flex align-items-start at-row-original">
                                            <div className="col-6 pad-lr-7">
                                                <label className="at-pricelabel" htmlFor="">ORIGINAL</label>
                                                <span className="at-pricedetail at-pricedetail-two">Price: <em>{currencyFormatter.format(Number(this.artOriginalTotalCost(item)) || 0)}</em></span>
                                            </div>
                                        </div>
                                    }
                                    {!userPage && <>
                                        <div className="clearfix"></div>
                                        {!item.sold && !item.reserved && <div className="row my-3 d-flex align-items-start at-row-original">
                                            <div className="col-6 pad-lr-7">
                                                <label className="at-pricelabel" htmlFor="">ORIGINAL</label>
                                                <span className="at-pricedetail at-pricedetail-two">Price: <em>{currencyFormatter.format(Number(this.artOriginalTotalCost(item)) || 0)}</em></span>
                                            </div>
                                            {/* Remove from SprintONE */}
                                            {item.offerState ? <div className="col-6 pad-lr-7">
                                                <label className="at-pricelabel" htmlFor="">Offer</label>
                                                <div className='form-group position-relative'>
                                                    <CurrencyField
                                                        type="text"
                                                        name='offer'
                                                        className="form-control text-capitalize at-input-bgwhite"
                                                        onChange={this.onChangeText}
                                                        placeholder={`${currencyFormatter.format(Number(this.artOriginalTotalCost(item)) || 0)}`}
                                                        value={offer} />
                                                    {/* <input
                                                        type='text'
                                                        name='offer'
                                                        className='form-control text-capitalize at-input-bgwhite'
                                                        placeholder={`${currencyFormatter.format(Number(item.price)|| 0)}`}
                                                        onChange={this.onChangeText}
                                                    /> */}
                                                    <button type="button" className="at-sendicon" disabled={isApiCall} onClick={this.onMakingOffer}><i className="icon-send"></i></button>
                                                    {errors && errors['offer'] && <ErrorComponent errors={errors['offer']} multiple={false} />}
                                                </div>
                                            </div> : (
                                                <div className="col-6 pad-lr-7">
                                                    <div className='form-group position-relative'>
                                                        <label className="at-pricelabel invisible">.</label>

                                                        <button className="at-btn at-btn-buyoriginal" onClick={this.onItemBuy}>BUY</button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>}
                                    </>
                                    }

                                    <div className="clearfix"></div>
                                    {item.formates?.length > 0 && this.getActiveFormats(item) && <div className="row ">
                                        <div className="col-6">
                                            <p className="at-multipleprice-heading">Other Formats</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="at-multipleprice-heading">Price</p>
                                        </div>
                                    </div>}
                                    {item.formates?.map((format: IFormates) => (
                                        format.active && <div key={format._id} className="at-multipleprice-outerbox">
                                            <div className="clearfix"></div>
                                            <div className="row at-multipleprice-row d-flex align-items-center mb-2">
                                                <div className="col-6">
                                                    <h6 className="mb-0">{format.size} {this.getPrinterSizes(format)}</h6>
                                                    {format.printType === EArtistPrintType.FRAMED && <p className="mb-0">Framed <a href="#" className="at-orangelinkspan" onClick={() => this.onOpenShowService(format)}><span>Select Option</span> *</a></p>}
                                                    {format.printType === EArtistPrintType.UNFRAMED && <p className="mb-0">Unframed <a href="#" className="at-orangelink" onClick={() => this.onOpenShowService(format)}>See Details</a></p>}
                                                </div>
                                                <div className="col-6 at-formatprice-col ">
                                                    <CurrencyFormat amount={this.artFormatesTotalCost(format)} /> {!userPage && <button className={`at-btn at-smallbtn ${this.getButtonColor(format)}`} onClick={() => this.onBuyFormats(format)}>BUY</button>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {/* Remove from SprintONE */}

                                    <p className="at-countryshippingtext mt-3 mb-0">In-country shipping included in the price. Sales tax not included in the price.</p>
                                    {this.checkFramedOption() && <p className="at-countryshippingtext mb-3">* The cost of the print medium &amp; framing option you select will be added to the shown print price.</p>}

                                    {/* ================================ Request button */}
                                    {userId !== user._id && (item.sold || item.reserved) && <div className="form-group">
                                        <button className="at-btn at-minwidth-100" onClick={() => this.props.history.push(`${routes.artCentral.commissionWork.requestCommissionwork}/${item.id}`, { studioUser })}>Request Commission work</button>
                                    </div>}
                                    {item.sold && <h6 className="at-soldlabel">SOLD</h6>}
                                    {item.reserved && <h6 className="at-soldlabel">Reserved</h6>}
                                </div>
                            </div>
                        </div>
                    )
                }
                {openAction && <div id="at-themeaddactionpopup" className={`at-themepopupholder ${openAction && 'at-showthemesignuppopup'}`}>
                    <div className="at-themepopupbox">
                        <a className="at-close cursor-pointer" onClick={this.close} id="at-close"><i className="icon-cancel"></i></a>
                        <div className="at-addactionholder">
                            <div className="at-formthemehead">
                                <h2 className="text-center">Action</h2>
                            </div>
                            <ul>
                                <li className="text-center">
                                    <a onClick={() => this.editItem()}>Edit</a>
                                </li>
                                <li className="text-center">
                                    <a className="cursor-pointer" onClick={() => this.setState({ showMoreItemPopup: true, openAction: false })}>Add More Details</a>
                                </li>
                                <li className="text-center">
                                    <a className="cursor-pointer text-danger" onClick={this.openDeleteBox}>Delete</a>
                                </li>
                                {/* Remove from SprintONE */}
                                {/* <li className="at-active">
                                    <a className="cursor-pointer" onClick={() => this.setState({ thankYouPopup: true, openAction: false })}>Thank you popup</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>}
                {/* openAction popup ends */}

                {showMoreItemPopup && <div className={`at-add-detailpopup at-themepopupholder ${showMoreItemPopup && 'at-showthemesignuppopup'}`}>
                    <div className="at-themepopupbox">
                        <a className="at-close cursor-pointer" onClick={() => this.setState({ showMoreItemPopup: false })} id="at-close"><i className="icon-cancel"></i></a>
                        <div className="at-addactionholder">
                            <div className="at-formthemehead">
                                <h2>Action</h2>
                                <span className="mt-2 d-block">Add Details</span>
                            </div>
                            <div className="form-group">
                                <div className="at-themefileupload">
                                    {/* <input type="file" name="files[name]" multiple ref="files" />
                                <label>
                                    <span><i className="icon-youtube"></i>Upload Images/Videos</span>
                                </label> */}
                                    <UploaderComponent url={`${API_ENDPOINT}/collection/item/attachments/${item?.art_collection.id}/${item?.id}`} onComplete={this.onUploadComplete} />
                                </div>
                            </div>
                            {/* <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> <button type="button" className="cursor-pointer btn btn-secondary btn-block" >DELETE</button></div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> <button type="button" className="cursor-pointer btn btn-orange btn-block"  >EDIT</button></div>
                        </div> */}
                        </div>
                    </div>
                </div>}
                {/* add more details popup ends */}

                {thankYouPopup && <div className={`at-thankyou-popup at-themepopupholder ${thankYouPopup && 'at-showthemesignuppopup'}`}>
                    <ThanyouPopup header={offerStatus?.status === 'fail' ? 'Your has been failed' : "Thank you for your offer"} message={offerStatus?.message || ''} onClose={this.closeThankPop} onSubmit={this.onSubmitThankPopup} buttonText={offerStatus?.approved ? "Next" : 'Ok'} />
                    {/* <AddressPopup message={message} onClose={this.closeThankPop} onSubmit={this.onSubmitThankPopup} buttonText={buttonText} /> */}
                </div>}
                {showAdrressPopup && <div className={`at-thankyou-popup at-themepopupholder ${showAdrressPopup && 'at-showthemesignuppopup'}`}>
                    <AddressPopup onClose={this.onAddressClose} onSubmit={this.onSubmitAddressPopup} user={this.props.user} inValidAddress={inValidAddress} showLoading={loading} />
                </div>}
                {payPopup && <div className={`at-thankyou-popup at-themepopupholder ${payPopup && 'at-showthemesignuppopup'}`}>
                    <PayPopup message={paymentMessage} loading={loading} tax={tax} platformFee={10} offerPrice={itemType === 'format' ? this.artFormatesTotalCost(formatSelected as IFormates) : price} item={itemType === 'format' ? formatSelected : item} onClose={this.closePayPopup} onConfirmPay={this.onConfirmPay} showPaymentForm={showPaymentForm} onSubmit={this.onSubmitPayPopup} setDropinInstance={this.setDropinInstance} stripeData={this.state.stripeData} />
                </div>
                }
                {this.state.showService && formatSelected && <UniversalModal open={this.state.showService} onClose={this.onCloseShowService} >
                    <ShowArtServiceComponent format={formatSelected} onSubmitShowService={this.onSubmitShowService} />
                </UniversalModal>}
            </>
        )
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    studioUser: state.studio.user
})

export default connect(mapStateToProps, null)(CollectionItemViewComponent)
