import React from 'react'

interface IProps {
    open: boolean
    onClose: Function
    children: any
    position?: string
    className?: string
    hideCross?: boolean
}
export const UniversalModal = ({ open, onClose, children, position, className, hideCross }: IProps) => {
    return (
        <div className={`at-themepopupholder ${open && `at-universalPop-${position ? position : 'bottom'}`} ${className ? className : ''}`}>
            <div className="at-themepopupbox">
                {!hideCross && <a className="at-close cursor-pointer" onClick={() => onClose()} id="at-close"><i className="icon-cancel"></i></a>}
                <div className="at-popupcontent">
                    {children}
                </div>
            </div>
        </div>
    )
}