import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { _logger } from '../../helper';
import { SalesSummary } from '../../components/User/Sales/SaleLogSummary'
import { SalesDetails } from '../../components/User/Sales/SaleLogsDetail'
import { IStoreReducers, IUser } from '../../interfaces';

interface IProps extends RouteComponentProps {
    user: IUser
}

class SaleLogs extends Component<IProps> {



    render() {
        const { user } = this.props
        return (
            <main id="at-main" className="at-main at-haslayout">
                <div className="at-createaccount scroll-style-3 at-contentarea-two">
                    <div className="at-themehead">
                        <a onClick={() => this.props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                        <h2>Sales Tax Log</h2>
                    </div>
                    <div className="at_theme_innercontent at_tabs_styling">
                        <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                            <Tab label="Detail">
                                <SalesDetails user={user} />
                            </Tab>
                            <Tab label="Summary">
                                <SalesSummary user={user} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(SaleLogs)
