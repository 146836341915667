import { BehaviorSubject, Subject } from "rxjs"

export enum EZoom {
    NONE = 'NONE',
    FEED = 'FEED',
    COLLECTION = 'COLLECTION',
    COLLECTION_ITEM = 'COLLECTION_ITEM',
    VIDEO_BAND = 'VIDEO_BAND',
    TEMPLATES = 'TEMPLATES',
    TEMPLATES_IFRAME = 'TEMPLATES_IFRAME',
    ART_COLLECTION = 'ART_COLLECTION'
}

export interface IZoom {
    type: EZoom,
    value: any
}

export const ZoomActions = (() => {
    const event$ = new BehaviorSubject<any>({})
    return {
        triggerAction: (data: IZoom) => event$.next(data),
        listen: event$,
    }
})()