import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { images } from '../../assets/image'
import ImageGallery from 'react-image-gallery';
import { routes } from './routes'
import { useSelector } from 'react-redux';
import { templateSelector } from '../../store/selectors';
import { ICollection, ICollectionItem } from '../../interfaces';
import { AxiosResponse } from 'axios';
import { collectionService } from '../../services/collection.service';
import { collectionImage, templatesImages } from '../../shared';
import { CustomImage } from '../components/CustomImage';
import { BannerComponent } from '../components/BannerComponent';
import { IResponse } from '../../interfaces/db.interface';

const GalleryPage = () => {
    let template = useSelector(templateSelector)
    const history = useHistory()
    const user = template.user
    const [collections, setCollections] = useState<Array<ICollection>>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchCollections = () => {
        setLoading(true)
        collectionService.listWithItems(user?._id).then((response: AxiosResponse<IResponse<Array<ICollection>>>) => {
            setCollections([...response.data.data])
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchCollections()
    }, [])

    const renderCollection = (i: ICollectionItem) => {

        return (
            <div key={i.id} className="col-md-4 col-lg-4">
                <div className="At-BorderFigureHolder">
                    <div className="At-BorderFigure">
                        <CustomImage src={collectionImage(i, `${i.url}`)} thumbnail={collectionImage(i, `blur_${i.url}`)} />

                    </div>
                    <div className="At-ArtDetailBox">
                        {i.name && <h5>
                            {i.name}
                        </h5>}
                        {i.artTechnique && i.height && i.width && <p> {i.height}" X {i.width}" {i.depth ? `X ${i.depth}"` : null}   {i.artTechnique}</p>}
                        <i className="icon-dotted-circle" onClick={() => { history.push(`${routes(template.url).gallery.collectionItemView}/${i.id}`) }}></i>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <section className="At-SectionPage">
                <div className="At-ContainerFull">
                    <BannerComponent title="" imgSrc={templatesImages(`${template.galleryImage}`) || images.template.bannerTemplate3}>
                        <nav aria-label="breadcrumb ">
                            <ol className="breadcrumb At-Breadcrumb">
                                <li className="breadcrumb-item"><Link to={routes(template.url).home}>Home</Link></li>
                                <li className="breadcrumb-item active">Gallery</li>
                            </ol>
                        </nav>
                    </BannerComponent>
                </div>
            </section>
            <section className="At-SectionGallery At-Padding-tb8  At-Section-Mh800">
                <div className="container">
                    {collections.length > 0 && collections.map((c: ICollection) => {
                        if (c.isHide) {
                            return null
                        }
                        let items = c.items.length > 5 ? c.items.slice(0, 5) : c.items;
                        return (
                            items.length > 0 ? <div key={c.id} className="row">
                                <div className="At-GalleryTitle col-12 cursor-pointer">
                                    <h2 onClick={() => history.push(`${routes(template.url).gallery.collectionView}/${c.id}`)}>{c.title}</h2>
                                </div>
                                {items.length > 0 && items.map((i: ICollectionItem, index: number) => {
                                    return <>
                                        {renderCollection(i)}
                                        {c.items.length > 5 && index === 4 && <Link className="col-md-4 col-lg-4 At-SixthCol" to={`${routes(template.url).gallery.collectionView}/${c.id}`}>
                                            <div key={i.id} >
                                                <div className="At-BorderFigureHolder">
                                                    <div className="At-BorderFigure">
                                                        <figure key={i.id}>
                                                            <img src={collectionImage(i, `${i.url}`)} alt="" />

                                                        </figure>
                                                        <figcaption>
                                                            <span><i className="icon-add"></i>{c.items.length - 5}</span>
                                                        </figcaption>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>}
                                    </>
                                })}
                            </div> : null
                        )
                    })}
                </div>
            </section>
        </div>
    )
}

export default GalleryPage