import React, { Component, FormEvent, Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Select from 'react-select'
import $ from 'jquery'
 
import ActiveSpaceMembersComponent from '../../components/common/ActiveSpaceMembers.component'
import {
	hubService,
	spaceService,
	toastService,
	communicationService,
	ECommunicationMessages,
	AgoraLeaveActions,
} from '../../services'
import { ConfirmAlertComponent } from '../../components/common/ConfirmAlert.component'
import {
	IHub,
	ISpaceAdd,
	ISpaceUpdate,
	IStoreActionsStates,
	IStoreReducers,
	ISpace,
	IContact,
	IUser,
} from '../../interfaces'
import { isPlural } from '../../helper'
import {
	changeActiveSpace,
	fetchContacts,
	loadHubsWithSpaces,
	refreshUser,
} from '../../store/actions'
import { connect } from 'react-redux'
import { ErrorComponent } from '../../components/common/Error.component'
import HubsAndSpaces from '../../components/MyCentral/HubsAndSpaces/HubsAndSpaces.component'
import { routes } from '../../router'
import { Subscription } from 'rxjs'
import { EHubsAndSpaces, ADD_NEW_MEMBER } from '../../constants'
import { socketNode } from '../../services/socket.service'
import HubsAndSpacesListViewComponent from '../../components/MyCentral/HubsAndSpacesListView/HubsAndSpacesListView.component'

interface ISpacesPageProps extends RouteComponentProps, IStoreActionsStates {
	changeActiveSpace?: any
	fetchContacts: Function
	loadHubsWithSpaces: Function
	refreshUser: Function
	activeSpace: ISpace | undefined
	user: IUser
}

interface IMultiSelectOption {
	value: string
	label: string
}

enum FormFields {
	name = 'name',
	type = 'type',
	selectedContacts = 'selectedContacts',
	toHub = 'toHub',
}

interface IFormError {
	touched: boolean
	error: string
}

interface ISpacesPageState {
	hubs: IHub[]
	actions?: IAction
	selectedItem?: ISelectedItem
	itemForm: IAddItemForm
	contactsForSelect: IMultiSelectOption[]
	initialContactsForSelect: IMultiSelectOption[]
	errors: {
		[FormFields.name]: IFormError
		[FormFields.type]: IFormError
		[FormFields.selectedContacts]: IFormError
		[FormFields.toHub]: IFormError
	}
	invalidForm: boolean
	isAuthorized: boolean
	loading: boolean
	showListView: boolean
}

interface IAction {
	edit: boolean
	delete: boolean
	select: boolean
	add: boolean
}

interface IAddItemForm {
	_id?: string
	type: EItem
	name: string
	fromHub?: string
	toHub?: string
	color?: EHubsAndSpaces
	hubId?: string
	selectedContacts: IMultiSelectOption[]
	contactIds: string[]
}

interface ISelectedItem {
	type: EItem
	data: IHub & ISpace
	userId: string
}

interface ILongPressItem extends IHub, ISpace {
	type: EItem
}

enum EItem {
	hub = 'hub',
	space = 'space',
}

const initialItemForm: IAddItemForm = {
	name: '',
	type: EItem.hub,
	fromHub: '',
	toHub: '',
	selectedContacts: [],
	contactIds: [],
	_id: '',
	hubId: '',
	color: EHubsAndSpaces.spaceBlue,
}

const initialErrorsState: any = {
	[FormFields.name]: {
		touched: false,
		error: '',
	},
	[FormFields.type]: {
		touched: false,
		error: '',
	},
	[FormFields.selectedContacts]: {
		touched: false,
		error: '',
	},
	[FormFields.toHub]: {
		touched: false,
		error: '',
	},
}

const initialState: ISpacesPageState = {
	hubs: [],
	itemForm: { ...initialItemForm },
	contactsForSelect: [],
	errors: { ...initialErrorsState },
	invalidForm: true,
	initialContactsForSelect: [],
	isAuthorized: false,
	loading: false,
	showListView: true,
}

class SpacesPage extends Component<ISpacesPageProps> {
	state = initialState
	subscription = new Subscription()

	async componentDidMount() {
		await this.fetchData()
		this.listenToMessages()
	}

	componentWillUnmount() {
		this.subscription.unsubscribe()
	}

	listenToMessages = () => {
		this.subscription.add(
			communicationService.getData().subscribe(async (message) => {
				switch (message.type) {
					case ECommunicationMessages.OPEN_SPACE_ADD_FORM:
						this.openAddForm(EItem.space, message.payload.color)
						break
					case ECommunicationMessages.OPEN_HUB_ADD_FORM:
						this.openAddForm(EItem.hub)
						break
					default:
						break
				}
			})
		)
	}

	componentWillUpdate() {
		if (
			this.props.contacts.isLoaded &&
			this.props.contacts.data.length &&
			!this.state.contactsForSelect.length
		) {
			const {
				contacts: { data },
			} = this.props

			const contacts = this.mapContactsForSelect(data)

			this.setState({
				contactsForSelect: contacts,
				initialContactsForSelect: contacts,
			})
		}
	}

	shouldComponentUpdate(nextProps: ISpacesPageProps, nextState: any) {
		if (!this.state.selectedItem) {
			if (nextProps.activeSpace !== this.props.activeSpace) {
				this.setState({
					selectedItem: { type: EItem.space, data: nextProps.activeSpace },
				})
			}
		}

		return true
	}

	mapContactsForSelect = (contacts: IContact[] = []) => {
		return contacts.map((contact) => {
			return {
				label: `${contact.firstName} ${contact.lastName}`,
				value: contact._id,
			}
		})
	}

	fetchData = async () => {
		const hubs = await (await hubService.list()).data
		// if (!this.props.user.hubsWithSpaces) this.props.loadHubsWithSpaces()

		await this.setState({
			hubs,
			// selectedItem: { type: 'hub', data: hubs[0] },
		})
		if (!this.props.contacts.isLoaded) {
			this.props.fetchContacts()
		}
	}

	closeAllModals = (): void => {
		// reset multiple select dropdown list
		// this.setState({
		// 	contactsForSelect: this.state.initialContactsForSelect,
		// })
		this.setState({
			contactsForSelect: this.state.initialContactsForSelect,
			actions: undefined,
			itemForm: { ...initialItemForm },
			errors: { ...initialErrorsState },
			invalidForm: true,
		})
	}

	/* -------------------------------------------------------------------------- */
	/*              start - handlers open up the necessary modals                 */
	/* -------------------------------------------------------------------------- */

	addHandler = (type: EItem, color?: EHubsAndSpaces) => {
		if (!this.state.isAuthorized) {
			return toastService.warning(`Sorry, you can not add sapce to this hub`)
		}

		if (type === EItem.space) {
			this.setState({
				itemForm: {
					...this.state.itemForm,
					fromHub: color ? undefined : this.state.selectedItem?.data._id,
					toHub: color ? undefined : this.state.selectedItem?.data._id,
					type,
					color,
				},
				actions: { add: true },
			})
		}
		if (type === EItem.hub) {
			this.setState({
				actions: { add: true },
			})
		}
	}

	editHandler = async () => {
		if (!this.state.isAuthorized) {
			return toastService.warning(
				`Sorry, you can not edit this ${this.state.selectedItem?.type}`
			)
		}

		const { selectedItem } = this.state

		if (selectedItem?.type === EItem.hub) {
			const itemForm = {
				type: EItem.hub,
				name: selectedItem.data.name,
				_id: selectedItem.data._id,
				selectedContacts: [],
				contactIds: [],
			}
			this.setState({
				actions: { edit: true },
				itemForm,
			})
		}

		if (selectedItem?.type === EItem.space) {
			const mapToMultiSelect = this.mapContactsForSelect(
				selectedItem?.data.contacts
			)

			const itemForm = {
				type: EItem.space,
				name: selectedItem.data.name,
				_id: selectedItem?.data._id,
				selectedContacts: mapToMultiSelect,
				fromHub: selectedItem.data.parent?._id,
				toHub: selectedItem.data.parent?._id,
			}

			this.setState({
				actions: { edit: true },
				itemForm,
				// contactsForSelect: this.state.initialContactsForSelect,
				selectedContacts: mapToMultiSelect,
			})
		}
	}

	deleteHandler = () => {
		// this.setState({
		// 	selectedItem: {
		// 		type,
		// 		data: { ...item, hubId: parent },
		// 	},
		// })

		if (!this.state.isAuthorized) {
			return toastService.warning(
				`Sorry, you can not delete this ${this.state.selectedItem?.type}`
			)
		}

		if (this) this.setState({ actions: { delete: true } })
	}

	selectHandler = (item?: ILongPressItem) => {
		if (item) {
			const userId =
				item.type === EItem.hub ? item.spaces?.[0]?.userId : item?.userId

			const isAuthorized = userId ? this.isAuthorized(userId._id) : true

			if (item.type === EItem.hub && userId && !isAuthorized) {
				return toastService.warning(`Sorry, you are not author of this hub.`)
			}

			this.setState({
				actions: { select: true },
				selectedItem: { type: item.type, data: item, userId },
				isAuthorized: isAuthorized,
			})
		} else {
			this.openAddForm(EItem.hub)
		}
	}

	openAddForm = async (
		type: EItem,
		color: EHubsAndSpaces = EHubsAndSpaces.spaceBlue
	) => {
		await this.setState({
			isAuthorized: true,
		})
		this.addHandler(type, color)
	}

	dragDropHandler = async (space: ISpace, hub: IHub) => {
		if (!this.isAuthorized(space.userId?._id)) {
			return toastService.warning('Sorry, you can not move this space.')
		}

		if (space.userId?._id !== hub.spaces?.[0]?.userId?._id)
			return toastService.warning(`Sorry, you are not author of this hub.`)

		if (space.parent?._id && hub._id) {
			const payload: ISpaceUpdate = {
				fromHub: space.parent._id,
				toHub: hub._id,
				name: space.name,
				_id: space._id,
			}
			await spaceService.udpate(payload)
			toastService.success(`${payload.name} is moved to ${hub.name}`)

			this.fetchData()
		}
	}

	changeActiveSpace = () => {
		this.props.changeActiveSpace(this.state.selectedItem?.data)
		toastService.success(
			`You have entered ${this.state.selectedItem?.data.name}`
		)
		this.closeAllModals()
	}

	/* -------------------------------------------------------------------------- */
	/*                 end - handlers open up the necessary modals                */
	/* -------------------------------------------------------------------------- */

	private isAuthorized = (userId: string = '') => {
		return userId && userId === this.props.user._id
	}

	deleteItems = async () => {
		const { selectedItem: payload } = this.state

		if (payload?.type === EItem.hub) {
			await hubService.remove({ hubIds: [`${payload?.data._id}`] })
		} else if (payload?.type === EItem.space) {
			if (payload.data.parent?._id) {
				await spaceService.remove({
					spaceIds: [`${payload.data._id}`],
					hubId: payload.data.parent._id,
				})
			}
		}
		toastService.success(`${payload?.data.name} deleted`)
		this.fetchData()
		this.closeAllModals()
	}

	onChangeText = async (e: FormEvent) => {
		let { name, value } = e.target as HTMLInputElement
		await this.setState({ itemForm: { ...this.state.itemForm, [name]: value } })
		this.validateForm()
	}

	onFocus = async (e: FormEvent) => {
		const { name } = e.target as HTMLInputElement
		const { errors } = this.state as any
		this.setState({
			errors: {
				...errors,
				[name]: { ...errors[name], touched: true },
			},
		})
	}

	validateForm = async () => {
		const { errors, itemForm } = this.state
		let valid = false

		for (const [key] of Object.entries(errors)) {
			// if (value.touched) {
			if (key === FormFields.name) {
				if (itemForm.name && itemForm.name.trim().length) {
					errors.name.error = ''
					valid = true
				} else {
					valid = false
					errors.name.error = `${
						itemForm.type[0].toUpperCase() + itemForm.type.slice(1)
					} name is required`
				}
			}

			if (itemForm.type === EItem.space) {
				if (key === FormFields.toHub) {
					if (itemForm.toHub?.length) {
						errors.toHub.error = ''
						valid = valid && true
					} else {
						valid = valid && false
						errors.toHub.error = 'Please select a hub'
					}
				}
			}
			// }
		}

		await this.setState({ errors, invalidForm: !valid })
	}

	handleChange = (selectedOptions: any) => {
		// const { errors } = this.state
		// errors.selectedContacts.error = selectedOptions?.length
		// 	? ''
		// 	: 'Please select at least one contact'

		this.setState({
			// errors,
			itemForm: { ...this.state.itemForm, selectedContacts: selectedOptions },
			errors: { ...initialErrorsState },
			invalidForm: false,
		})

		this.validateForm()
	}

	addItem = async (e: FormEvent) => {
		e.preventDefault()
		this.toggleLoading(true)
		const { itemForm: payload } = this.state
		payload.contactIds = payload.selectedContacts?.map(
			(contact) => contact.value
		)
		delete payload.selectedContacts
		if (payload.type === EItem.hub) {
			await hubService.add({ name: payload.name })
		} else if (payload.type === EItem.space) {
			payload.hubId = payload.toHub
			delete payload._id
			await spaceService.add(payload as ISpaceAdd)
		}
		toastService.success(`${payload.name} is added`)
		this.fetchData()
		this.closeAllModals()
		this.toggleLoading(false)
	}

	editItem = async (e: FormEvent) => {
		e.preventDefault()
		this.toggleLoading(true)
		const { itemForm: payload } = this.state

		payload.contactIds = payload.selectedContacts?.map(
			(contact) => contact.value
		)
		if (!payload.contactIds) payload.contactIds = []
		delete payload.selectedContacts
		if (payload.type === EItem.hub) {
			await hubService.udpate(payload)
		} else if (payload.type === EItem.space) {
			await spaceService.udpate(payload as ISpaceUpdate)
			// this.addMemberThroughSocket(payload._id, payload.contactIds)
			this.props.refreshUser();
		}
		toastService.success(`${payload.name} is updated`)
		this.fetchData()
		this.closeAllModals()
		this.toggleLoading(false)
	}

	addMemberThroughSocket = (spaceID: any, members: string[]) => {
		members.forEach((id: string) => {
			const user = this.contactsWithDetails().find(
				(user: IContact) => id === user._id
			)
			if (user) socketNode.emit(ADD_NEW_MEMBER, { channel: spaceID, user })
		})
	}

	toggleLoading = (loading: boolean): void => {
		this.setState({ loading })
	}

	toggleListView = () => {
		this.setState({ showListView: !this.state.showListView }, () => {
			this.state.showListView
				? $('#hubs-and-spaces-canvas').parent().hide()
				: $('#hubs-and-spaces-canvas').parent().show()
		})
	}

	contactsWithDetails = () => {
		const { selectedItem } = this.state
		return (
			(selectedItem && selectedItem.data && selectedItem.data.contacts) || []
		)
	}

	render() {
		let {
			hubs,
			actions,
			selectedItem,
			itemForm,
			contactsForSelect,
			errors,
			invalidForm,
			loading,
			showListView,
		} = this.state

		const { activeSpace } = this.props

		// temp ui for spaces and hubs
		// temp ui for spaces and hubs
		hubs = hubs.filter((h: any) => h._id);

		return (
			<div className='at-sectionspace at-haslayout at-homeone at-hometwo at-contentarea'>
				<ActiveSpaceMembersComponent {...this.props} />

				<div className='at-themehead'>
					<a
						onClick={() => this.props.history.push(routes.home)}
						className='at-left-arrowicon'
					>
						<i className='icon-left-arrow'></i>
					</a>
					<a className='at-right-listicon d-none' onClick={this.toggleListView}>
						{showListView ? (
							<i className={`icon-menu c-sunshade`}></i>
						) : (
							<i className={`icon-list c-sunshade`}></i>
						)}
					</a>
					<h2>Home Screen</h2>
				</div>

				<div className={`at-list-holder scroll-style-3 ${showListView ? '' : 'd-none'}`}>
					<HubsAndSpacesListViewComponent
						hubs={hubs}
						activeSpace={activeSpace}
						selectItem={this.selectHandler}
					/>
				</div>
				<div className={`at-list-holder scroll-style-3 ${!showListView ? '' : 'd-none'}`}>
				<HubsAndSpaces
					hubs={hubs}
					activeSpace={activeSpace}
					selectItem={this.selectHandler}
					dragDropHandler={this.dragDropHandler}
					user={this.props.user}
					// deleteItem={this.deleteHandler}
				/>
				</div>

				{actions?.delete && (
					<ConfirmAlertComponent
						open={actions.delete}
						onCancel={this.closeAllModals}
						message={`Delete ${selectedItem?.type} ${
							selectedItem?.data.name
						}? ${
							selectedItem?.type === EItem.hub
								? selectedItem.data.spaces?.length
									? `Note: ${
											isPlural(selectedItem.data.spaces.length) ? 'All' : ''
									  } ${selectedItem.data.spaces.length} space${
											isPlural(selectedItem.data.spaces.length) ? 's' : ''
									  } will be deleted.`
									: ''
								: ''
						}`}
						onConfirm={this.deleteItems}
						confirmTitle={'Delete'}
					/>
				)}

				{/* Options Menu for hubs, spaces and globe */}
				<div
					id='at-themeaddusercontactpopup'
					className={`at-themepopupholder at-themeaddusercontactpopup ${
						actions?.select && 'at-showthemecreatecontactpopup'
					}`}
				>
					<div className='at-themepopupbox'>
						<a className='at-close' id='at-close' onClick={this.closeAllModals}>
							<i className='icon-cancel'></i>
						</a>
						<fieldset>
							<div className='at-formthemehead'>
								<h2>{selectedItem?.data?.name}</h2>
							</div>
							<div className='at-formthemeholder at-formtheme-pb20'>
								{selectedItem?.type === EItem.hub && (
									<ul>
										<li
											className='list-group-item text-capitalize border-top-0 border-left-0 border-right-0'
											onClick={() => this.addHandler(EItem.space)}
										>
											add space
										</li>
										<li
											className='list-group-item text-capitalize border-left-0 border-right-0'
											onClick={this.editHandler}
										>
											edit hub
										</li>
										<li
											className='list-group-item text-capitalize border-bottom-0 border-left-0 border-right-0'
											onClick={this.deleteHandler}
										>
											delete hub
										</li>
									</ul>
								)}
								{selectedItem?.type === EItem.space && (
									<ul>
										{selectedItem.data?._id === activeSpace?._id ? (
											<li className='list-group-item text-capitalize  border-left-0 border-right-0'>
												activated
											</li>
										) : (
											<li
												className='list-group-item text-capitalize border-top-0 border-left-0 border-right-0'
												onClick={this.changeActiveSpace}
											>
												active space
											</li>
										)}
										<li
											className='list-group-item text-capitalize border-left-0 border-right-0'
											onClick={this.editHandler}
										>
											edit space
										</li>
										<li
											className='list-group-item text-capitalize border-bottom-0 border-left-0 border-right-0'
											onClick={this.deleteHandler}
										>
											delete space
										</li>
									</ul>
								)}
							</div>
						</fieldset>
					</div>
				</div>
				{/* Options Menu for hubs, spaces and globe */}

				{/*Add Hub or Space*/}
				<div
					id='at-themeaddusercontactpopup'
					className={`at-themepopupholder at-themeaddusercontactpopup ${
						(actions?.edit || actions?.add) && 'at-showthemecreatecontactpopup'
					}`}
				>
					<div className='at-themepopupbox'>
						<a className='at-close' id='at-close' onClick={this.closeAllModals}>
							<i className='icon-cancel'></i>
						</a>
						<form
							onSubmit={actions?.add ? this.addItem : this.editItem}
							className='at-formtheme'
						>
							<fieldset>
								<div className='at-formthemehead'>
									{actions?.add && <h2>Add {itemForm?.type}</h2>}
									{actions?.edit && <h2>Edit {itemForm?.type}</h2>}
								</div>
								<div className='at-formthemeholder'>
									{/* <div className='form-group'>
										<span className='at-select'>
											<select
												name='type'
												defaultValue={itemForm?.type || EItem.hub}
												onChange={this.onChangeText}
												disabled={actions?.edit}
											>
												<option value={EItem.hub}>Hub</option>
												<option value={EItem.space}>Space</option>
											</select>
										</span>
									</div> */}
									{itemForm.type === EItem.space && (
										<Fragment>
											<div className='form-group'>
												<span className='at-select at-floatlabel-h40'>
													<select
														name='toHub'
														onChange={this.onChangeText}
														defaultValue={itemForm.toHub || 'default'}
														className="floating-select"
														disabled={
															actions?.add && selectedItem?.type === EItem.hub
														}
													>
														<option disabled value='default'>
															
														</option>
														{hubs
															?.filter(
																(hub) =>
																	!hub.spaces?.[0] ||
																	hub.spaces?.[0]?.userId?._id ===
																		this.props.user._id
															)
															.map((hub) => (
																<option key={hub?._id} value={hub?._id}>
																	{hub.name}
																</option>
															))}
													</select>
													<label>Choose hub</label>
												</span>
												<ErrorComponent
													errors={[errors.toHub.error]}
													multiple={false}
												/>
											</div>
											<div className='form-group'>
												<span className='at-select at-select-noafter'>
													<Select
														isMulti
														value={itemForm.selectedContacts}
														onChange={this.handleChange}
														options={contactsForSelect}
														placeholder='Select contacts'
													/>
												</span>
												<ErrorComponent
													errors={[errors.selectedContacts.error]}
													multiple={false}
												/>
											</div>
										</Fragment>
									)}
									<div className='form-group'>
										<input
											type='text'
											name='name'
											className='form-control text-capitalize'
											placeholder={(itemForm?.type || 'hub') + ' name'}
											value={itemForm?.name}
											onChange={this.onChangeText}
											// onFocus={this.onFocus}
										/>
										<ErrorComponent
											errors={[errors.name.error]}
											multiple={false}
										/>
									</div>
									<div className='form-group'>
										<button
											type='submit'
											className='at-btn'
											disabled={invalidForm || loading}
										>
											{actions?.add && 'Add'}
											{actions?.edit && 'Edit'}
										</button>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>
		)
	}
}
 
const mapStateToProps = (state: IStoreReducers) => ({
	contacts: state.contacts,
	activeSpace: state.user.activeSpace,
	user: state.user,
})

const mapAction = {
	changeActiveSpace,
	fetchContacts,
	loadHubsWithSpaces,
	refreshUser
}

export default connect(mapStateToProps, mapAction)(SpacesPage)
