import {IAccount, ISocialSignup} from "../../interfaces";
import {IActionType, SOCIAL_SIGNUP, RESET_SOCIAL_SIGNUP} from "../types";

const initialState: ISocialSignup | any = null;

export const socialAuthReducer = (state = initialState, action: IActionType ): ISocialSignup | any => {

    switch (action.type) {
        case SOCIAL_SIGNUP:
            return { ...action.payload };
        case RESET_SOCIAL_SIGNUP:
            return null;
        default:
            return state;
    }
}
