import { AxiosError, AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AWS_ENDPOINT, AWS_IMAGE_FOLDER } from '../../../environment'
import { IResponse } from '../../../interfaces/db.interface'
import { ISubmissionProcess } from '../../../interfaces/submission-process.interface'
import { submissionProcessService } from '../../../services'

export const ApplicationInformation = () => {

    const [loading, setLoading] = useState<boolean>(false)
    const [application, setApplication] = useState<ISubmissionProcess>()
    const appId = (useParams() as any).id
    const fetch = useCallback(() => {
        setLoading(true)
        submissionProcessService.singleApplicationById(appId).then((res: AxiosResponse<IResponse<ISubmissionProcess>>) => {
            setApplication(res.data.data)
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    const openPdf = (l: string) => {
        let link = `${AWS_ENDPOINT}/pdf/${l}`
        window.open(link, "_blank");
    }
    useEffect(() => {
        fetch()
    }, [])
    return (
        application && application._id ? <div className="at-roundcontainer">
            <h6 className="at-color-grey">Studio Address:</h6>
            <p>{application.address} {application.city}, {application.state} {application.zipcode} </p>

            <div className="at-applicationinfobox">
                <h6 className="at-color-grey">Website Link: </h6>
                <a className="at-btnlink" href={`${application.webUrl}`} target="_blank">{application.webUrl}</a>
            </div>

            <div className="at-applicationinfobox">
                <h6 className="at-color-grey">Instagram Account Link: </h6>
                <a className="at-btnlink" href={`${application.instagramUrl}`} target="_blank">{application.instagramUrl}</a>
            </div>
            {application.bio && application.bio.url && <div className="at-applicationinfobox">
                <h6 className="at-color-grey">Bio/Artist Statement PDF<i className="icon-pdf-big at-iconpdf cursor-pointer" onClick={() => openPdf(application.bio.url)}></i></h6>

            </div>}
            {application.cv && application.cv.url && <div className="at-applicationinfobox">
                <h6 className="at-color-grey">CV PDF File<i className="icon-pdf-big at-iconpdf cursor-pointer" onClick={() => openPdf(application.cv.url)}></i></h6>
            </div>}
        </div>
            :
            null
    )
}