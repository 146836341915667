import { IStoreAction } from '../../interfaces/store.interface'
import {
	TOGGLE_MEMBER_SIDEBAR,
	CHANGE_ACTIVE_SPACE_SUCCESS,
	CHANGE_ACTIVE_SPACE_REQUEST,
	CHANGE_ACTIVE_SPACE_FAILURE,
} from '../types'
import { IActiveSpaceDetails } from '../../interfaces'
import { userService } from '../../services'
import { refreshUser } from '.'

export const toggleActiveMemberSidebar = (): IStoreAction => ({
	type: TOGGLE_MEMBER_SIDEBAR,
})

export const changeActiveSpaceRequest = (): IStoreAction => ({
	type: CHANGE_ACTIVE_SPACE_REQUEST,
})

export const changeActiveSpaceSuccess = (
	payload: IActiveSpaceDetails
): IStoreAction => ({
	type: CHANGE_ACTIVE_SPACE_SUCCESS,
	payload,
})

export const changeActiveSpaceFailure = (payload: any): IStoreAction => ({
	type: CHANGE_ACTIVE_SPACE_FAILURE,
	payload,
})

export const changeActiveSpace = (space: IActiveSpaceDetails): any => {
	return function (dispatch: Function) {
		if (space._id) {
			dispatch(changeActiveSpaceRequest())
			userService.changeActiveSpace(space._id).then(
				(res) => {
					dispatch(refreshUser())
				},
				(rejection) => {
					dispatch(changeActiveSpaceFailure(JSON.stringify(rejection)))
				}
			)
		}
	}
}
