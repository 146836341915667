import React, { useEffect, useRef, useState } from 'react'
import Video from 'react-video-renderer';
import ReactPlayer from 'react-player'
import { ITutorials } from '../../interfaces'
import { eventImages, secondsToTime } from '../../shared'
import { AWS_ENDPOINT } from '../../environment';

interface IProps {
    tutorial: ITutorials
    seletecedVideo: string
    onSelectVideo: Function
    onShareLink: Function
    hideShare?: boolean
    autoPlay?: boolean
}

export const TutorailPlayComponent = ({ tutorial, onSelectVideo, seletecedVideo, onShareLink, hideShare, autoPlay }: IProps) => {

    const [play, setPlay] = useState<boolean>(false)
    const [showVideo, setSHowVideo] = useState<boolean>(false)
    const [muted, setMuted] = useState<boolean>(false)
    const videoRef = useRef<any>(null)

    const onClickPlay = () => {
        setPlay(true)
        setSHowVideo(true)
        onSelectVideo(tutorial)
    }

    const onFullScreen = (actions: any, s: any) => {
        console.log(s)
        actions.requestFullscreen()
    }

    const onClickPdf = () => {
        if (!tutorial.pdf) return
        let link = `${AWS_ENDPOINT}/pdf/${tutorial.pdf}`
        window.open(link, "_blank");
    }

    useEffect(() => {
        if (seletecedVideo && seletecedVideo !== tutorial._id) {
            setPlay(false)
            setSHowVideo(false)
        }

        if (autoPlay) {
            setPlay(true)
            setMuted(true)
            setSHowVideo(true)
        }
    }, [seletecedVideo, autoPlay])

    return (
        <div>
            <div className="at-categoryitem">
                <figure onClick={() => onClickPlay()}>
                    <img src={eventImages({}, tutorial.cover, true)} className="" alt="" />
                    <i className="icon-play-button"></i>
                    <div className="at-overlay"></div>
                </figure>
                <div className="at-rightcateg">
                    <h6>{tutorial.name}</h6>
                    {!hideShare && <ul className="at-categaction">
                        {tutorial.pdf && <li>
                            <button className="at-btndelete" onClick={() => onClickPdf()} >
                                <i className="icon-pdf-big"></i>
                            </button>
                        </li>}
                        <li>
                            <button className="at-btndelete" onClick={() => onShareLink(tutorial)} >
                                <i className="icon-share"></i>
                            </button>

                        </li>
                    </ul>}
                </div>
            </div>
            {showVideo &&
                <ReactPlayer
                    url={eventImages({}, tutorial.tutorial.url)}
                    playing={play}
                    autoplay={true}
                    muted={muted}
                    onPause={() => setPlay(false)}
                    onPlay={() => setPlay(true)}
                    controls
                    playsinline
                    height="auto"
                    width="100%"
                    ref={videoRef}
                />
                // <Video src={eventImages({}, tutorial.tutorial.url)} autoPlay={play} ref={videoRef} >
                //     {(video, state, actions) => (
                //         <div onClick={() => onClickPlayPause(actions)} className="position-relative">
                //             {video}
                //             {
                //                 !play && <button type="button" className="videoButton text-white d-flex fs-20" onClick={() => onClickPlayPause(actions)}><i className='icon-play-button'></i></button>
                //             }
                //             <div className={state.isLoading ? 'blink_me' : ''}>{secondsToTime(Math.round(state.currentTime))} / {secondsToTime(Math.round(state.duration))}</div>

                //         </div>
                //     )}
                // </Video>
            }
        </div>
    )
}