import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ERole } from '../../enums'
import { ADMIN_PHONE } from '../../environment'
import { routes } from '../../router'
import { UserLoginAction } from '../../services'
import { authSelector, userSelector } from '../../store/selectors'

interface IProps {
    onClose: Function
}
export const OmniHeaderDropdown = ({ onClose }: IProps) => {
    const user = useSelector(userSelector)
    const token = useSelector(authSelector).access_token
    const history = useHistory()
    const [show, setShow] = useState<boolean>(false)

    const toggleDropdown = () => {
        setShow(!show)
    }

    const onClickList = (route: string) => {
        history.push(route)
        toggleDropdown()
    }

    let acessService = user && user._id && (user.roles.includes(ERole.ARTIST) || user.roles.includes(ERole.AGENT) || user.roles.includes(ERole.CONSULTANT))
    return (
        <div className="at-header-dropdown">
            <div className="dropdown">
                <div
                    onClick={() => toggleDropdown()}
                    className="dropdown__toggle dropdown__list-item"
                >

                    <i className="icon-menu2" aria-hidden="true"></i>
                </div>
                <ul className={`dropdown__list ${show ? 'dropdown__list--active' : ''}`}>
                    <li>
                        <h6 className="at-dropdownheading">OMNI art central</h6>
                    </li>
                    <li
                        onClick={() => onClickList(token ? routes.artCentral.artCollection : routes.artCollection)}
                        className={"dropdown__list-item "}
                    >
                        {'Art Collection'}
                    </li>
                    {token && <li
                        onClick={() => onClickList(routes.artCentral.gelleryWalk.index)}
                        className={"dropdown__list-item "}
                    >
                        {'Gallery Walks & Events'}
                    </li>}
                    <li
                        onClick={() => onClickList(token ? routes.artCentral.newsFeeds : routes.newsFeed)}
                        className={"dropdown__list-item "}
                    >
                        {'News Feed'}
                    </li>
                    {acessService && token && <li
                        onClick={() => onClickList(routes.account.services.professional.access)}
                        className={"dropdown__list-item "}
                    >
                        {'Professional Services'}
                    </li>}

                    <li
                        onClick={() => {
                            if (!token) {
                                UserLoginAction.triggerAction(true)
                                toggleDropdown()
                                return
                            }
                            onClickList(routes.account.services.auction.index)
                        }}
                        className={"dropdown__list-item "}
                    >
                        {'Live Art Auctions'}
                        {/* <span className="at-color-orange">(coming soon!)</span> */}
                    </li>
                    {user?.phoneNumber === ADMIN_PHONE && <li
                        onClick={() => onClickList(routes.adminRoutes.index)}
                        className={"dropdown__list-item "}
                    >
                        <i className='icon-user-setting'></i>
                    </li>}
                    <li>
                        <h6 className="at-dropdownheading">TOOLS</h6>
                    </li>
                    {token && user?.roles?.length === 0 && <li
                        onClick={() => onClickList(routes.submissionProcess.form)}
                        className={"dropdown__list-item "}
                    >
                        {'Apply to be an Artist or Gallery'}
                    </li>}
                    {token && (user?.roles?.includes(ERole.ARTIST) || user?.roles?.includes(ERole.AGENT)) && <li
                        onClick={() => onClickList(user?.roles?.includes(ERole.ARTIST) ? routes.artCentral.collection.create : routes.artCentral.agent.assignments)}
                        className={"dropdown__list-item "}
                    >
                        {'Add Art to OMNI'}
                    </li>}
                    {token && (user?.roles?.includes(ERole.ARTIST) || user?.roles?.includes(ERole.AGENT) || user?.roles?.includes(ERole.CONSULTANT)) && <li
                        onClick={() => onClickList(routes.artCentral.post.create)}
                        className={"dropdown__list-item "}
                    >
                        {'Add Post to News Feed'}
                    </li>}
                    {token && <li
                        onClick={() => onClickList(routes.artCentral.gelleryWalk.create)}
                        className={"dropdown__list-item "}
                    >
                        {'Add Gallery Walk Event'}
                    </li>}
                    {token && <li
                        onClick={() => onClickList(routes.account.services.auction.add)}
                        className={"dropdown__list-item "}
                    >
                        {'Add Art Auction Event'}
                    </li>}

                    {token && user?.roles?.includes(ERole.CONSULTANT) && <li
                        onClick={() => onClickList(routes.account.services.professional.index)}
                        className={"dropdown__list-item "}
                    >
                        {'Add Professional Services'}
                    </li>}
                    <li>
                        <h6 className="at-dropdownheading">ACCOUNT</h6>
                    </li>
                    {token && <li
                        onClick={() => onClickList(routes.account.editProfile)}
                        className={"dropdown__list-item "}
                    >
                        {'Edit Profile'}
                    </li>}
                    {token && <li
                        onClick={() => onClickList(routes.account.index)}
                        className={"dropdown__list-item "}
                    >
                        {'Other Account Services'}
                    </li>}
                </ul>
            </div>
            {/* { (
                <button className='at-btnadmin at-btn' onClick={() => { history.push(routes.adminRoutes.index) }}>
                    <i className='icon-user-setting'></i>
                </button>
            )} */}
        </div>
    )
}