import React, { useState } from 'react'

import { ErrorComponent } from '../Error.component'

interface IProps {
    open: boolean,
    onClose: Function,
    onSubmit: Function
    message: any,
    title?: any
}

export const MessageModal = ({ open, onClose, onSubmit, message, title }: IProps) => {

    const [newMessage, setMessage] = useState<string>('')
    const [errors, setErrors] = useState<any>({})

    const onSend = () => {
        if (!newMessage){
            let newErrors = errors
            newErrors['message'] = [`Please write message you want to send!`]
            setErrors({...newErrors})
            return
        }
        setErrors({})
        onSubmit(newMessage)
    }

    return (
        <div className={`at-thankyou-popup at-themepopupholder at-popupfixedbottom at-showthemerecoverycodepopup ${open && 'at-showthemesignuppopup'}`}>
            <div className="at-themepopupbox text-left">
                <a className="at-close cursor-pointer" onClick={() => onClose()} id="at-close"><i className="icon-cancel"></i></a>
                <div className="at-addactionholder">
                    <div className="at-formthemehead">
                        <h2>{title ? title : 'Message'}</h2>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="form-group at-textarea-height100 at-textarea-message mt-4">
                                <textarea name="details" className="form-control at-border-radius10" placeholder="Type Message" value={newMessage} onChange={(e: any) => setMessage(e.target.value)} />
                                {errors && errors['message'] && <ErrorComponent errors={errors['message']} multiple={false} />}
                                <button type="button" className="at-sendicon-message" onClick={onSend}><i className="icon-send"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}