import React from 'react'

export default function LoadingComponent (props: any) {
    return (
        <div className={`form-group ${props.className}`}>
            <div className="at-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export const BSLoading = () => <div className="spinner-border" role="status">
<span className="sr-only">Loading...</span>
</div>