import React, { useEffect, useState } from 'react'
import { assignmentService, toastService } from '../../../services'
import { AxiosResponse } from 'axios'
import { IUser, IArtAssignment, ICollection, IDynamic, IAssignmentCollection, ICollectionItem } from '../../../interfaces'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../store/selectors'
import { AgentAssignmentComponent } from '../../../components/ArtCentral/collection/AgentAssignment.component'
import { useHistory, Link, useParams } from 'react-router-dom'
import { routes } from '../../../router'
import { trim } from 'lodash'
import { ErrorComponent } from '../../../components/common/Error.component'
import { collectionImage } from '../../../shared'
import { images } from '../../../assets/image'
import { NoDataComponent } from '../../../components/common/NoData.component'
import { AgentCollectionComponent } from '../../../components/ArtCentral/collection/AgentCollection.component'
import { ConfirmAlertComponent } from '../../../components/common/ConfirmAlert.component'
import { BSLoading } from '../../../components/common/Loading.component'

export const AgentAssignmentCollectionViewPage = () => {
    const router = useHistory();
    const params = useParams<{ id: string }>();

    const user = useSelector(userSelector);
    const [collection, setCollection] = useState<IAssignmentCollection | null>(null)
    const [selectedAssignments, setSelectedAssignments] = useState<IDynamic>({})
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)

    const fetch = () => {
        if (!params.id) return;
        assignmentService.collection(params.id).then((response: AxiosResponse<IAssignmentCollection>) => {
            setCollection(response.data)
        })
    }
    useEffect(() => {
        fetch()
    }, [])

    const isSelected = (id: string) => {
        return selectedAssignments[id] === true
    };
    const onLongPress = ({ id }: any) => selectUnselect(id)

    const selectUnselect = (id: string) => {

        if (selectedAssignments[id]) {
            delete selectedAssignments[id]
        } else {
            selectedAssignments[id] = true;
        }
        setSelectedAssignments({ ...selectedAssignments })
    }

    const selectionOn = () => Object.keys(selectedAssignments)

    const onClick = ({ id, art }: IArtAssignment) => {

        if (selectionOn().length > 0) return selectUnselect(id)
        router.push(`${routes.agent.showAssigment}/${art.assignment}`)
    }

    const deleteItems = async () => {
        setDeleting(true)
        await assignmentService.deleteCollectionItems(collection?.id, selectionOn())
        fetch();
        setDeleting(false)
        setDeletePopup(false)
    }

    const onMoveUpward = (index: any) => {
        let newCollection: any = collection
        if (newCollection) {
            let items = newCollection.assignments
            if (index !== 0) {
                let temp = items[index];
                items[index] = items[index - 1];
                items[index - 1] = temp;
                newCollection.assignments = items

                let data = items.map((item: any) => item.id)
                UpdatingCollectionSequence(data)
            } else {

                toastService.error("Unable to move the collection Upword")
            }
        }
    }

    const onMoveDownward = (index: any) => {
        let newCollection: any = collection
        if (newCollection) {
            let items = newCollection.assignments
            if (index !== items.length - 1) {
                let temp = items[index];
                items[index] = items[index + 1];
                items[index + 1] = temp;
                newCollection.assignments = items
                let data = items.map((item: any) => item.id)
                UpdatingCollectionSequence(data)
            } else {
                toastService.error("Unable to move the collection Downward")
            }
        }
    }

    const UpdatingCollectionSequence = (data: Array<any>) => {
        if (!collection) return
        assignmentService.updateArtSeq(collection?.id, { assignments: data }).then((res: AxiosResponse<any>) => {
        }).catch(err => console.log(err))
    }

    return (
        <>
            <div className="at-artcollections scrollable at-contentarea">
                {/* { selectionOn() && <button onClick={() => setAddToCollection(true)}>Add</button>} */}
                <div className="at-artcollectionsarea">
                    <div className="at-formtheme at-formartcollection pt-4">
                        <fieldset className="at-artcollectioncontent">
                            <div className="form-group">
                                <h3>
                                    <a className="at-left-arrowicon cursor-pointer" onClick={router.goBack}>
                                        <i className="icon-left-arrow"></i>
                                    </a>
                                    {/* {selectionOn()  ? `Selected ${selectionOn().length}/${assignments.length} Images` : ''} */}
                                    {collection?.title}
                                </h3>
                                {
                                    (selectionOn().length > 0) && (
                                        <div className="at-btniconholder">
                                            <div className="at-checkbox">
                                                <button className="at-assingn-btn" onClick={() => setDeletePopup(true)}><i className="icon-delete"></i></button>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </fieldset>
                        <fieldset className="at-artgallerycollections at-themescrollbar">
                            <ul className="at-artcollectiongrid at-artgallerycollection">
                                {
                                    collection?.assignments?.map((assignment: IArtAssignment, index: any) => <AgentAssignmentComponent key={assignment.id} user={user} assignment={assignment} onClick={onClick} onLongPress={onLongPress} isSelected={isSelected(assignment.id)} onMoveUpward={onMoveUpward} onMoveDownward={onMoveDownward} index={index} c={collection} />)
                                }
                            </ul>
                        </fieldset>
                    </div>
                </div>
            </div>
            <ConfirmAlertComponent open={deletePopup} title="Deleting!" message="Are you sure you want to remove assignment?" onCancel={() => setDeletePopup(false)} onConfirm={deleteItems} confirmTitle={!deleting ? 'Delete' : <BSLoading />} />
        </>
    )
}
