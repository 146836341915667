import React from 'react';
import { Provider } from 'react-redux';
import './App.scss';
import './assets/css/vendor/bootstrap.min.css'
import './assets/css/fontawesome/fontawesome-all.css'
import './assets/css/scrollbar.css'
import './assets/css/icomoon.css'
import './assets/style.scss'
import './assets/css/responsive.scss'
import 'react-phone-number-input/style.css'
import 'react-circular-progressbar/dist/styles.css';



import { persistor, store} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import IdleTimeComponent from './components/IdleTime.component';

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                  <IdleTimeComponent />
            </PersistGate>
        </Provider>
    )
}

export default App;
