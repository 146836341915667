import { IPlatformWebReducer } from '../../interfaces'
import { IActionType, IN_PLATFORM_ACTIVE, IN_PLATFORM_ACTIVE_SPLITSCREEN, IN_PLATFORM_SET_PATH, IN_PLATFORM_SET_STATE } from '../types'

const initialState: IPlatformWebReducer = {
    activeSplitScreen: false,
    previousPath: '',
    nextPath: '',
    isActive: false
}

export const InPlatformWebReducer = (state = initialState, action: IActionType): IPlatformWebReducer => {

    switch (action.type) {
        case IN_PLATFORM_ACTIVE:

            return { ...state, isActive: action.payload };
        case IN_PLATFORM_ACTIVE_SPLITSCREEN:

            return { ...state, activeSplitScreen: action.payload };
        case IN_PLATFORM_SET_PATH:

            return { ...state, ...action.payload };
        case IN_PLATFORM_SET_STATE:

            return { ...state, ...action.payload };

        default:
            return state
    }
}