import openSocket from 'socket.io-client';
import { APP_DOMAIN, API_ENDPOINT } from '../environment';

class SocketIO extends openSocket {

    constructor () {
        super(APP_DOMAIN)
    }
}

export const socketNode = new SocketIO();