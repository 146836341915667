import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { adminService } from '../../services'

interface IState {
    terms: string
}

export default class TermsPage extends Component<RouteComponentProps> {
    state: IState = {
        terms: `We are a SOCIAL AND BUSINESS RELATIONSHIP PLATFORM to facilitate the building of 
        trusting relationships between ARTISTS and their AGENTS in their common and join efforts to engage BUYERS 
        to develop knowledge, appreciation and love for the creative works of the ARTIST…`
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = (): void => {
        adminService.getPolicy().then((response: any) => {
            let text = response.data.length > 0 ? response.data[0].terms : this.state.terms
            this.setState({ terms: text })
        })
    }
    render() {
        return (
            <main id="at-main" className="at-main at-haslayout">
                <div className="at-createaccount scroll-style-3 at-contentarea-two">
                    <div className="at-themehead">
                        <a onClick={() => this.props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                        <h2>Terms</h2>
                    </div>
                    <div className="at_theme_innercontent">
                        <div className="at-privacy-textholder at-popupinner-mh-55vh">
                            <p dangerouslySetInnerHTML={{ __html: this.state.terms }}></p>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}
