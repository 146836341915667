import { AxiosResponse } from 'axios'
import { ITemplateReducerState } from '../../interfaces'
import { userTemplateService } from '../../services/userTemplates.service'
import { TEMPLATE_DATA } from '../types'

export const setTemplateData = (payload: ITemplateReducerState) => ({
    type: TEMPLATE_DATA,
    payload
})


export const FetchTemplateData = (data: any) => async (dispatch: any) => {
    try {
        const res: AxiosResponse<any> = await userTemplateService.getTemplate(data)
        dispatch(setTemplateData(res.data.data))
        return res.data.data
    } catch (error) {
        console.log(error)
    }
}