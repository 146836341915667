export * from './feed.interface'
export * from './store.interface'
export * from './auth.interface'
export * from './user.interface'
export * from './post.interface'
export * from './question.interface'
export * from './contact.interface'
export * from './collection.interface'
export * from './collection.interface'
export * from './http.interface'
export * from './hub.interface'
export * from './space.interface'
export * from './misc.interface'
export * from './rtcEvents.interface'
export * from './common.interface'
export * from './assignment.interface'
export * from './offer.interface'
export * from './notification.interface'
export * from './transaction.interface'
export * from './currentStudio.interface'
export * from './commission.interface'
export * from './assign-account.interface'
export * from './event.interface'
export * from './user-template.interface'
export * from './InPlatformWeb.interface'
export * from './tutorials.interface'
export * from './Curatorial.interface'
export * from './curatorial-proposal.interface'
export * from './Shipping-factor.interface'
export interface IDynamic {
	[key: string]: any
}
