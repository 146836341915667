import React from 'react'
import { useSelector } from 'react-redux'
import { images } from '../../assets/image'
import { templatesImages } from '../../shared'
import { templateSelector } from '../../store/selectors'
import { CustomText } from './CustomText'

const AboutPage = () => {
    let template = useSelector(templateSelector)
    return <div >
        <section className="At-AboutPage At-Section-Mh800">
            <div className="container">
                <div className="row mx-lg-n6">
                    <div className="col-sm-6 px-lg-6 At-AboutContent">
                        <h3>{`About`}</h3>
                        <CustomText>
                            <p className="" dangerouslySetInnerHTML={{ __html: template.aboutDescription }}>

                            </p>
                        </CustomText>
                    </div>
                    <div className="col-sm-6 px-lg-6">
                        <figure className="At-AboutImage">
                            <img src={templatesImages(template.aboutImage) || images.template.aboutImg} className="img-fluid" alt="" />
                        </figure>
                    </div>

                </div>
            </div>
        </section>
    </div>
}

export default AboutPage