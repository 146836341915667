import { AxiosResponse } from 'axios'
import { ICollection } from 'fabric/fabric-impl'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import { TemplateServiceComponent } from '../components/TemplateService.component'
import LoadingComponent from '../../components/common/Loading.component'
import { DescriptionModal } from '../../components/common/Modals/Description.modal'
import { MessageModal } from '../../components/common/Modals/MessagePopup'
import PostShimmeringComponent from '../../components/common/PostShimmering.component'
import { ServiceComponent } from '../../components/User/Services/ProfessionalServices/Service.component'
import { USER_ALERT_SPACE_MESSAGE } from '../../constants'
import { ERole } from '../../enums'
import { IPaginateResponse, IUser } from '../../interfaces'
import { IConsultantServicesDocument } from '../../interfaces/services.interface'
import { lang } from '../../locale'
import { routes } from './routes'
import { userService, toastService, assignmentService } from '../../services'
import { collectionService } from '../../services/collection.service'
import { consultantService } from '../../services/consultantServices.service'
import { IZoom, EZoom } from '../../services/zoom.service'
import { setLeftPanel } from '../../store/actions'
import { userSelector, authSelector, leftPanelSelector, templateSelector } from '../../store/selectors'
import { BannerComponent } from '../components/BannerComponent'
import { templatesImages } from '../../shared'
import { images } from '../../assets/image'

interface IProps {

}
interface IPageState {
    loading: boolean
    error: any
    isZoomed: boolean
}

const initialState = {
    isZoomed: false,
    error: null,
    loading: false,
}
const AccessProfessionalServiceTepmlate = ({ }: IProps) => {
    const history = useHistory()
    const user: IUser = useSelector(userSelector)
    const token = useSelector(authSelector).access_token
    const location = useLocation()
    const dispatch = useDispatch()
    const activeRightPanel = useSelector(leftPanelSelector).activeRigthPanel

    const template = useSelector(templateSelector)
    const [state, setState] = useState<IPageState>({ ...initialState })
    const [page, setPage] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [filtered, setFiltered] = useState<boolean>(false)
    const [query, setQuery] = useState<string>('')
    const [next, setNext] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [services, setServices] = useState<Array<IConsultantServicesDocument>>([])
    const [showMessageBox, setMessageBox] = useState<boolean>(false)
    const [selectedService, setSelectedService] = useState<IConsultantServicesDocument>()
    const [zoom, setZoom] = useState<IZoom>({ type: EZoom.NONE, value: null })
    const [serviceName, setServiceName] = useState<string>('')
    const [showUserService, setShowUserService] = useState<string>('')
    const [selectedConsultand, setSelectedConsultant] = useState<string>('')

    const fetchServices = async (page = 1, query = "", filtered = false, serviceName = '', provider = ''): Promise<any> => {
        toggleLoading(true)
        let userId = ''

        if (filtered && user) {
            userId = user._id
        }
        setPage(page)
        consultantService.getAllService({ page, limit: 10, filtered, query, userId, serviceName, provider: template.user._id }).then((res: AxiosResponse<IPaginateResponse<IConsultantServicesDocument>>) => {
            const { count, documents, next } = res.data;
            const newFeeds = page === 1 ? [...documents] : [...services, ...documents];
            setNext(next)
            setCount(count)
            setServices([...newFeeds])
            toggleLoading(false)
        }, (err: any) => {
            console.log(err)
            toggleLoading(false)
        })
    }

    const toggleLoading = (l: boolean) => {
        setState({
            ...state,
            loading: l
        })
    }
    const openSearch = () => {
        setShowSearch(true)
    }

    const closeSearch = () => {
        if (showSearch) {
            setQuery('')
            setShowSearch(false)
            return
        }
        // history.goBack()
        // fetchServices(1, '', filtered)
    }

    const search = (e: any) => {
        setQuery(e.target.value)
        fetchServices(1, e.target.value, filtered, selectedConsultand)
    }

    const getSearchQuery = () => {
        return new URLSearchParams(location.search).get("name")
    }

    const onToggleFilter = (value: boolean) => {
        if (showUserService) {
            setShowUserService('')
            setSelectedConsultant('')
        }
        if (value) {
            setFiltered(true)
        } else {
            setFiltered(false)
        }
        fetchServices(1, query, value)
    }
    const openMessageBox = (s: IConsultantServicesDocument) => {
        setMessageBox(true)
        setSelectedService(s)
    }

    const closeMessageBox = () => {
        setMessageBox(false)
        setSelectedService(undefined)
    }

    const onSumbitMessageBox = (message: string) => {
        sendUserAlert(selectedService as IConsultantServicesDocument, message, USER_ALERT_SPACE_MESSAGE, true)
    }

    const onChangeRole = (e: any) => {
        setServiceName(e.target.value)
        fetchServices(1, query, filtered, e.target.value, selectedConsultand)
    }

    const onSetServiceProvider = (s: IConsultantServicesDocument) => {
        if (showUserService === s._id) {
            setShowUserService('')
            setSelectedConsultant('')
            fetchServices(1, query, filtered, serviceName, '')
            return
        }
        setShowUserService(s._id)
        setSelectedConsultant(s.user._id)
        fetchServices(1, query, filtered, serviceName, s.user._id)
    }

    const sendUserAlert = (s: IConsultantServicesDocument, message: string, type: string, showToast: boolean = false, spaceId: any = null) => {
        let cUser = s.user

        if (user?._id === cUser._id) {
            return
        }
        let data = {
            message: message,
            to: cUser._id,
            from: user?._id,
            type: type,
            spaceID: spaceId
        }
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
            if (showToast && res.data.status === 'Success') {
                toastService.success("Message sent successfully")
            }
            closeMessageBox()
        }).catch((err: any) => {
            console.log(err)
            closeMessageBox()
        })
    }


    useEffect(() => {
        fetchServices(1)
    }, [])

    return (
        <div>
            <section className="At-SectionPage At-Padding-tb8">
                {/* <div className="At-ContainerFull">
                    <BannerComponent title="" imgSrc={templatesImages(`${template.galleryImage}`) || images.template.bannerTemplate3}>
                        <nav aria-label="breadcrumb ">
                            <ol className="breadcrumb At-Breadcrumb">
                                <li className="breadcrumb-item"><Link to={routes(template.url).home}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Services</li>
                            </ol>
                        </nav>
                    </BannerComponent>
                </div> */}
            </section>
            <section className="At-SectionGallery At-Padding-tb8 At-Section-Mh800 clearfix">
                <div className="container">
                    <div id="feed-scroll" className={`at-posts at-posts-threecol at-themescrollbar scrollable scrollbar-hidden`}>
                        <div className="at-themeheader-outer at-themeheader-fullpage">
                            <div className="at-themehead">
                                <h3>Professional Services</h3>
                                {/* <div className="form-group at-artcollectionselect">
                        <span className="at-select at-floatlabel-h40">
                            <select className="form-control floating-select" value={serviceName} name="" onChange={(e: any) => onChangeRole(e)} >
                                <option value="">Select Service</option>
                                <option >OMNI Onboarding</option>
                                <option >In-Platform  Website  Development</option>
                                <option >Portfolio Review, Analysis and Update</option>
                                <option >Platform Marketing Program Management</option>
                                <option >Complete  Account  Management</option>
                            </select>
                        </span>
                    </div> */}
                                {/* <div className="at-selectfilterwrap">
                        {showSearch && <a className="at-left-arrowicon" onClick={() => closeSearch()}><i className="icon-left-arrow"></i></a>}
                        <a className="at-filtericon cursor-pointer" onClick={() => openSearch()}><i title="Search" className="icon-search"></i></a>

                        {token && <a className={`at-filtericon cursor-pointer mr-2 ${filtered && 'text-orange'}`} onClick={() => onToggleFilter(!filtered)}><i title="Filter Preferred" className="icon-filter"></i></a>}
                        <h2>
                            <div className={`from-group At-MarginTopSearchMinus dn ${showSearch && 'display'}`}>
                                <div className="input-group">
                                    {state.loading && <div className="input-group-prepend">
                                        <span className="input-group-text search-field" id="basic-addon1"><i className="fa fa-spinner"></i></span>
                                    </div>}
                                    <input type="search" value={query} className="form-control" placeholder="Search" onChange={(e: any) => search(e)} />
                                </div>
                            </div>
                            {!showSearch ? 'Home Screen' : ''}
                        </h2>
                    </div> */}

                            </div>
                        </div>
                        {
                            (state.loading && services.length < 1) && [1, 1, 1].map((v, i) => <PostShimmeringComponent key={i} />)
                        }

                        {
                            services.length > 0 && services?.map((s: IConsultantServicesDocument) => (
                                <Fragment key={`post_${s._id}`} >
                                    <TemplateServiceComponent showUserService={showUserService} openMessageBox={openMessageBox} onSetServiceProvider={onSetServiceProvider} service={s} />
                                </Fragment>
                            ))
                        }
                        {
                            state.loading && <LoadingComponent />
                        }
                        {
                            (next && !state.loading) && <div className="at-haslayout">
                                <button id="loadMore" className="at-btn at-loadmore" onClick={() => fetchServices(page + 1, query, filtered, selectedConsultand)}>{lang.common.loadMore}</button>
                            </div>
                        }
                        {(!state.loading && !next && count > 0) &&
                            <div className="row text-center">
                                <p className="reachedText">
                                    {lang.feeds.reachedEnd}
                                </p>
                                <br />
                            </div>
                        }
                        {
                            (!state.loading && count < 1) && <div className="text-center  mt-5 mb-5">
                                <h4>{!query ? lang.feeds.notFound : lang.feeds.changeSearch}</h4>
                            </div>
                        }
                        {showMessageBox && <MessageModal open={showMessageBox} onClose={closeMessageBox} onSubmit={onSumbitMessageBox} message='' title="Send Message" />}

                    </div>
                </div>
            </section>
        </div>

    )
}

export default AccessProfessionalServiceTepmlate