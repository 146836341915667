import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { images } from '../../../assets/image'
import { ITransaction, IUser } from '../../../interfaces'
import { transactionService } from '../../../services'
import LoadingComponent from '../../common/Loading.component'
import { CurrencyFormat } from '../../common/CurrencyFormat'
import { collectionImage } from '../../../shared'

interface IProps {
    user: IUser
}
export const SalesDetails = ({ user }: IProps) => {

    const [userTransaction, setUserTransaction] = useState<Array<ITransaction>>()
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        transactionService.getUserTransactions(user?._id).then((res: AxiosResponse<any>) => {
            let data = [...res.data.response]
            setUserTransaction(data.reverse())
        })
    }, [])
    

    const transactionRender = () => {
        return (
            userTransaction?.map((item: ITransaction) => (
                <div className="at-transaction-box">
                    <div className="at-transact-left">
                        <div className="at-transact-line">
                            <p>{moment(item.createdAt).format("L")}</p>
                            <p className="pl-5">{moment(item.createdAt).format("hh:mm:ss a")}</p>
                        </div>
                       {item.buyerID && <div className="at-transact-line">
                            <p>Buyer:</p>
                            <p className="pl-3"><span>{`${item.buyerID.firstName} ${item.buyerID.lastName}`}</span></p>
                        </div>}
                        <div className="at-transact-line pt-2">
                            <p>Shipping Address:<span>{`${item.shippingAddress} ${item.city}, ${item.state} ${item.zip}`}</span></p>
                        </div>
                        <div className="at-transact-line pt-2">
                            <p>Sales Tax/User Tax Charged:<span> <CurrencyFormat amount={item.tax} /></span></p>
                        </div>
                    </div>
                    <div className="at-transact-right">
                        <figure>
                            {item.itemID && item.itemID.url && <img src={collectionImage(item.itemID, `high_${item.itemID.url}`, true)} alt="" />}
                        </figure>
                    </div>
                </div>
            ))
        )
    }
    return (
        <div className="at-transaction-main">
            {userTransaction && userTransaction.length > 0 ? transactionRender() : (loading && <LoadingComponent className='at-transaction-loader' />)}

            {/* <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div>

            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>11/27/2020</p>
                        <p className="pl-5">12:13:23</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Buyer:</p>
                        <p className="pl-3"><span>Mary Adams</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Shipping Address:<span>319 Woodland Road Sewickley,PA 15143</span></p>
                    </div>
                    <div className="at-transact-line pt-2">
                        <p>Sales Tax/User Tax Charged:<span> $35.23</span></p>
                    </div>
                </div>
                <div className="at-transact-right">
                    <figure>
                        <img src={images.admin.img02} alt="" />
                    </figure>

                </div>
            </div> */}
        </div>
    )
}