export const MapToIndices = (obj: any, keyPath: string) => {
	let mappedObj: any = {}

	let idx = 'entry'

	// navigate upto the property whose value will be used as indices
	keyPath.split('.').forEach(step => {
		idx += `["${step}"]`
	})

	for (const entry of obj) {
		// eslint-disable-next-line no-eval
		mappedObj[`${eval(idx)}`] = entry
	}
	return mappedObj
}
