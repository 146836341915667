import React, { useEffect } from 'react'
import { useState } from 'react';
import CircleLoadingComponent from './CircleLoader.component';
import LoadingComponent from './Loading.component';
interface IProps {
    src: string,
    thumbnail: string
    className?: string
    alt?: string
}
export const ImageLoading = ({ src, thumbnail, className, alt }: IProps) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [isError, setError] = useState<boolean>(false)
    const [image, setImage] = useState(thumbnail);
    const onLoadingImage = (e: any) => {
        if (!isError) {

            setImage(src)
        }
        if (image === src && loading) {
            setLoading(false)
        }
    }

    const onError = () => {
        if (image === thumbnail) {
            setImage(src)
        } else {

            setImage(thumbnail)
        }
        setLoading(false)
        setError(true)
    }

    useEffect(() => {
        if (src || thumbnail) {
            setImage(thumbnail)
            setLoading(true)
        }
    }, [src, thumbnail])
    return <>
        <img
            src={image}
            alt={alt || ''}
            className={`${loading && 'blue-affect'} ${className ? className : ''}`}
            onError={onError}
            onLoad={onLoadingImage}
        />
        {loading && <CircleLoadingComponent className='at-transaction-loader' />}
    </>
    // return <LazyLoadImage
    //     alt=""
    //     src={image}
    //     effect="blur"
    //     className={className ? className : ''}
    //     afterLoad={() => setImage(src)}
    // />
}