import { AxiosResponse, AxiosError } from 'axios'
import React, { FormEvent, useEffect, useState } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { images } from '../../../../../assets/image'
import { EArtistPrintType, EPrintingServiceType, EPrintType } from '../../../../../enums'
import { PLATFORM_FEE } from '../../../../../environment'
import { IFormates, IUser } from '../../../../../interfaces'
import { IResponse } from '../../../../../interfaces/db.interface'
import { IArtistServicesState, initialSelectedServices, IPrintingServices, IPrintingServiesPrices, IPrintingServiesSizes, ISelectedServiceState } from '../../../../../interfaces/services.interface'
import { consultantService } from '../../../../../services/consultantServices.service'
import { CurrencyFormat } from '../../../../common/CurrencyFormat'
import { ProposalImageView } from '../../../../common/ProposalImageView'

interface IProps {
    onAdd: Function
    format: IFormates
    selectedPrinter?: IUser
    parentState: IArtistServicesState
}
export const SelectPrintMedium = ({ onAdd, selectedPrinter, format, parentState }: IProps) => {

    const [printingServices, setPrintingServices] = useState<Array<IPrintingServices>>([])
    // const [selectedPrint, setSelectedPrint] = useState<IPrintingServices>()
    // const [selectedPrice, setSelectedPrice] = useState<IPrintingServiesPrices>()
    const [sizeOptions, setSizeOptions] = useState<Array<IPrintingServiesPrices>>([])
    const [selectedState, setSelectedState] = useState<ISelectedServiceState>({ ...initialSelectedServices })
    const [loading, setLoading] = useState<boolean>(false)

    const fetchServices = () => {
        if (!selectedPrinter) return
        setLoading(true)
        consultantService.getPrintingServicesByUserId(selectedPrinter._id).then((res: AxiosResponse<IResponse<Array<IPrintingServices>>>) => {
            if (res.data.success) {
                let prints = res.data.data.filter((p: IPrintingServices) => p.serviceType === EPrintingServiceType.PRINTING_OPTION)
                setPrintingServices([...prints])
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            setLoading(false)
            console.log(err)
        })
    }

    useEffect(() => {
        fetchServices()
    }, [])

    const onChangeService = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target
        let print = printingServices.find((p: IPrintingServices) => p._id === value)
        if (print) {
            setSelectedState({
                ...selectedState,
                printingService: print,
                selectedPrintrSevicePrice: undefined,
                selectedSize: undefined,
                totalCost: ''
            })
            setSizeOptions(print?.printingPrices)
        }
    }

    const onChangeServicePrice = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target
        let print = sizeOptions.find((s: IPrintingServiesPrices) => s._id === value)
        if (print) {
            setSelectedState({
                ...selectedState,
                selectedPrintrSevicePrice: print,
                selectedSize: print.printingSizes,
                totalCost: `${Number(print.price) + Number(print.shippingPrice)}`
            })
        }
    }

    const getTotalPrice = () => {
        let total = 0
        if (selectedState.printingService && selectedState.selectedPrintrSevicePrice) {
            total = total + Number(selectedState.selectedPrintrSevicePrice.price + selectedState.selectedPrintrSevicePrice.shippingPrice) + Number(PLATFORM_FEE)
            return total
        }
        return total
    }

    const onAddServiceData = () => {
        if (!selectedPrinter) return
        let data = {
            selectedServices: [{ ...selectedState }],
            serviceProvider: selectedPrinter._id,
            printType: EArtistPrintType.UNFRAMED
        }

        onAdd(data)
    }

    useEffect(() => {
        if (parentState && parentState.selectedServices && parentState.selectedServices.length > 0) {
            setSelectedState({
                ...selectedState,
                ...parentState.selectedServices[0]
            })
        }
    }, [parentState])

    useEffect(() => {
        if (printingServices && printingServices.length > 0 && selectedState.printingService) {
            printingServices.map((p: IPrintingServices) => {
                if (p._id === selectedState.printingService._id) {
                    setSizeOptions([...p.printingPrices])
                }
            })
        }
    }, [printingServices, selectedState.printingService])

    return <div className="at-addactionholder">
        <div className="at-formthemehead at-headselectprinter pb-2 ">
            <h2 className="at-textcenter">Select Print Medium and Size</h2>
            <p>Small Print Unframed</p>
        </div>
        <div className="at-selectprinter">
            {selectedPrinter && <div className="at-printerbox">
                <h6>{selectedPrinter.businessName ? `${selectedPrinter.businessName}` : `${selectedPrinter.firstName} ${selectedPrinter.lastName}`}</h6>
                <p>{selectedPrinter.address ? selectedPrinter.address : ''} {selectedPrinter.city ? selectedPrinter.city : ''} {selectedPrinter.state ? selectedPrinter.state : ''} {selectedPrinter.zipcode ? selectedPrinter.zipcode : ''}</p>
                <p>{formatPhoneNumberIntl(selectedPrinter.phoneNumber)}</p>
            </div>}
            <div className="form-group mt-3">
                <span className="at-select at-floatlabel-h40 ">
                    <select className="floating-select" name="art_collection" value={selectedState.printingService?._id ? selectedState.printingService._id : ''} disabled={printingServices.length === 0} onChange={onChangeService} >
                        <option value="" disabled></option>
                        {printingServices && printingServices.length > 0 && printingServices.map((p: IPrintingServices) => {
                            return (
                                <option value={p._id}>{p.name}</option>
                            )
                        })}
                    </select>
                    <label className="at-lineheight-27">Select Print Medium Option</label>
                </span>
            </div>
            <div className="form-group mt-3">
                <span className="at-select at-floatlabel-h40 ">
                    <select className="floating-select" name="art_collection" disabled={sizeOptions.length === 0} value={selectedState.selectedPrintrSevicePrice?._id ? selectedState.selectedPrintrSevicePrice._id : ''} onChange={onChangeServicePrice} >
                        <option value="" disabled></option>
                        {sizeOptions && sizeOptions.length > 0 && sizeOptions.map((s: IPrintingServiesPrices) => {
                            return (
                                <option value={s._id}>{s.printingSizes.height} X {s.printingSizes.width}</option>
                            )
                        })}
                        {/* <option value="">Select Print Medium Option</option> */}
                    </select>
                    <label className="at-lineheight-27">Select Print Size (inches)</label>
                </span>
            </div>
            <div className="at-printerbox">
                <h5><span className="at-color-orange">Total Cost:</span> <CurrencyFormat amount={getTotalPrice()} /></h5>
                <p className="at-notered">NOTE: This total cost will be added to your edition price in setting the total price shown to the buyer.</p>
            </div>
            {selectedState.printingService && <>  <div className="at-printerbox">
                <h6 className="at-printer-greyheading">PRINT MEDIUM</h6>
                <h6>{selectedState.printingService.name}</h6>
                <p dangerouslySetInnerHTML={{ __html: selectedState.printingService.description }}></p>
            </div>
                {selectedState.selectedSize && <div className="at-printerbox">
                    <h6>Size: <span className="at-span-printerbox" >{selectedState.selectedSize.height} inches X {selectedState.selectedSize.width} inches</span></h6>
                </div>}
                <div className="at-printerbox">
                    <h6>Shipping: <span className="at-span-printerbox" dangerouslySetInnerHTML={{ __html: selectedState.printingService.shipmentInformation }}></span></h6>
                </div>
                <figure className="mb-4">
                    <ProposalImageView isServicImage={true} item={selectedState.printingService} />
                </figure> </>}
            <div className="row row-margin-lr7">
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button className="at-btn at-btn-cancel at-longbtn-h40" >Cancel</button>
                    </div>
                </div>
                <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button type="submit" className="at-btn at-longbtn-h40" onClick={() => onAddServiceData()}>Next</button>
                    </div>
                </div>
            </div>
            {/* <div className="at-greybgbox">
                <div className="form-group form-w-100 mb-0">
                    <p className="at-heading-greybgbox"><span>Current File:</span> Small Nature file for printer.jpg</p>
                    <div className="at-themefileupload">
                        <input type="file" name="files[name]" multiple={false} accept="image/*" id="at-uploadfile" />
                        <label>
                            <span><i className="icon-gallery"></i>Upload Picture</span>
                        </label>
                    </div>
                    <button className="at-btn mt-2">
                        Upload Artist Approved File
                    </button>
                </div>
            </div> */}

        </div>
    </div>
}