import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { templateSelector } from '../../store/selectors'

interface IProps {
    imgSrc: string,
    title: string
    children: any
    hideDownIcon?: boolean
}
export const BannerComponent = ({ imgSrc, title, children, hideDownIcon }: IProps) => {
    const history = useHistory()
    const template = useSelector(templateSelector)
    return (
        <div className="row no-gutters">
            <div className="col-md-12">
                <div className="At-BannerHolder">
                    <figure className="At-FigureBanner-h800 At-FigureFull">
                        <img src={imgSrc} className="img-fluid" alt="" />
                    </figure>
                    <div className="At-Overlay"></div>
                    <div className="At-BannerTextFraming">
                        {title && <h2>{title}</h2>}
                        <div className="At-BannerTextInner">
                            {children}
                            {!hideDownIcon && <i className="icon-arrow-down-thick At-ArrowDown"></i>}
                        </div>
                    </div>
 
                </div>
            </div>
        </div>
    )
}