import { AxiosResponse } from 'axios'
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import DatePicker from "react-datepicker";
import { EventCollectionView } from '../../../../components/ArtCentral/events/EventsCollectionView'
import LoadingComponent from '../../../../components/common/Loading.component'
import { SliderComponent } from '../../../../components/common/Slider.component'
import { bytesToMegaBytes, dataURLtoFile, _logger } from '../../../../helper'
import { ICollection, ICollectionItem, IEventDocument, IUser } from '../../../../interfaces'
import { eventService, toastService } from '../../../../services'
import { collectionService } from '../../../../services/collection.service'
import { collectionImage, eventImages } from '../../../../shared'
import { userSelector } from '../../../../store/selectors'
import moment from 'moment';
import { ErrorComponent } from '../../../../components/common/Error.component';

import { EventAssignedCollectionView } from './AssignedEventCollections';
import { TmceEditor } from '../../../common/TMCEditor.comopnent';
import { IResponse } from '../../../../interfaces/db.interface';


interface IProps {
    id?: string,
    onBack: Function
    user: IUser
    onClickOnCollection: Function
    onClickOnItem: Function
    onClickViewEvents: Function
}
const initState = {
    name: '',
    startDate: null,
    endDate: null,
    items: [],
    description: '',
    images: [],
    pdf: null,
    poster: null
}

const initImageState = {
    preview: [],
    previewsCopy: [],
    selectedFiles: [],
    selectedFile: 0,
}


const AssignedCreateEventPage = ({ user, id, onBack, onClickOnCollection, onClickOnItem, onClickViewEvents }: IProps) => {
    const history = useHistory()
    // const params = (useParams() as any).id
    // const user = useSelector(userSelector)
    const [eventState, setEventState] = useState<IEventDocument>({ ...initState })
    const [collections, setCollections] = useState<Array<ICollection>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const [selectedArts, setSelectedArts] = useState<Array<ICollectionItem>>([])
    const imageRef = useRef<any>(null)
    const pdfRef = useRef<any>(null)
    const posterRef = useRef<any>(null)
    const [errors, setErrors] = useState<any>({})
    const [previewPoster, setPreviewPoster] = useState<Array<any>>([])

    const [imageState, setImageState] = useState<any>({ ...initImageState })


    const fetchCollections = useCallback(() => {
        collectionService.listWithItems(user._id).then((response: AxiosResponse<IResponse<Array<ICollection>>>) => {
            setCollections([...response.data.data])
            let artsData = response.data.data.reduce((n: any, c: ICollection) => {
                return n.concat(c.items)
            }, [])
        }).catch((err: any) => {
            console.log(err)
        })
    }, [])

    const fetchEvent = () => {
        if (!id) return
        eventService.getSingleEvent(id).then((res: AxiosResponse<IResponse<any>>) => {
            let data = res.data.data
            if (!data) {
                onBack()
                return
            }
            setEventState({
                ...eventState,
                name: data.name,
                startDate: new Date(data.startDate),
                endDate: new Date(data.endDate),
                images: data.images,
                items: data.items,
                _id: data._id,
                pdf: data.pdf,
                description: data.description,
                poster: data.poster
            })
            setPreviewPoster([eventImages({}, data.poster)])
            const preview = ((res.data && res.data.data.images) || []).map((a: any) => ({ url: eventImages(res.data.data, a.url), isVideo: a.isVideo }))
            setImageState({ ...imageState, preview, previewsCopy: preview })
        })
    }

    useEffect(() => {
        if (id) {
            fetchEvent()
        }
        fetchCollections()
    }, [fetchCollections])

    // useEffect(() => {
    //     if (eventState.poster) {
    //         if (typeof (eventState.poster) === 'string') {

    //         }
    //     }
    // }, [eventState.poster])


    const onAddEventArts = (array: Array<ICollectionItem>) => {
        let newArts = eventState.items
        newArts = newArts.concat(array)
        setEventState({
            ...eventState,
            items: newArts
        })
    }

    const onSelectEventArts = (item: ICollectionItem) => {
        if (selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1) {
            let newItems = selectedArts.filter((i: ICollectionItem) => i.id !== item.id)
            setSelectedArts([...newItems])
            return
        }
        let newArts = selectedArts
        newArts.push(item)
        setSelectedArts([...newArts])
    }
    const isSelected = (item: ICollectionItem) => {
        return selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1
    }

    const onSelectAllEventArts = () => {
        let { items } = eventState
        if (selectedArts.length === items.length) {
            setSelectedArts([])
            return
        }
        setSelectedArts([...items])
    }
    const isAllEventsArtSelected = () => {
        let { items } = eventState
        return selectedArts.length > 0 && selectedArts.length === items.length
    }

    const onDeleteArts = () => {
        let { items } = eventState
        if (!selectedArts.length) return
        let array = (items as Array<ICollectionItem>).filter((i: ICollectionItem) => {
            return selectedArts.map((s: ICollectionItem) => s.id).indexOf(i.id) === -1 ? true : false
        })
        console.log(array)
        setEventState({
            ...eventState,
            items: array
        })
        setSelectedArts([])
    }

    const onMoveUp = (index: any) => {
        if (index !== 0) {
            let items = eventState.items;
            let temp = items[index];
            items[index] = items[index - 1];
            items[index - 1] = temp;
            setEventState({
                ...eventState,
                items
            })
        } else {
            toastService.error("Unable to move the collection Upword")
        }
    }
    const onMoveDown = (index: any) => {
        let items = eventState.items;
        if (index !== items.length - 1) {
            let temp = items[index];
            items[index] = items[index + 1];
            items[index + 1] = temp;
            setEventState({
                ...eventState,
                items
            })
        } else {
            toastService.error("Unable to move the collection Downward")
        }
    };

    /* ===================================== Images Handling =========================================================== */
    const onChangeFiles = (e: any): void => {
        const { preview, selectedFile, selectedFiles } = imageState;
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    setImageState({
                        ...imageState,
                        preview: [...preview, ...images],
                        previewsCopy: [...preview, ...images],
                        selectedFiles: [...selectedFiles, ...files],
                        selectedFile: selectedFile ? selectedFile : 0
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onCrop = (image: any) => {
        let fileName
        let index = image.index
        if (id) {
            index = imageState.preview.length - image.index
            fileName = imageState.selectedFiles[index - 1].name
        } else {
            fileName = imageState.selectedFiles[index].name
        }
        // const fileName = imageState.selectedFiles[image.index].name
        const file = dataURLtoFile(image.cropped, fileName)
        const updatedFiles = [...imageState.selectedFiles]
        updatedFiles[index] = file
        const updatedPreviews = [...imageState.preview]
        updatedPreviews[image.index] = image.cropped
        setImageState({
            ...imageState,
            selectedFiles: updatedFiles,
            preview: updatedPreviews
        })
    }

    const onCropPoster = (image: any) => {
        const fileName = eventState.poster.name
        const file = dataURLtoFile(image.cropped, fileName)
        setEventState({
            ...eventState,
            poster: file,
        })

        setPreviewPoster([image.cropped])
    }

    const deleteImage = (): void => {

        let { preview, selectedFiles, selectedFile: i } = imageState;


        if (i > -1 && preview[i]) preview.splice(i, 1)
        let oldPlusNew = [...eventState.images, ...selectedFiles];
        oldPlusNew.splice(i, 1);
        eventState.images = oldPlusNew.filter((v: any) => v.proposal)
        const newAssets = oldPlusNew.filter((v: any) => !v.proposal)
        const selectedFile = i > 0 ? i - 1 : 0;

        setImageState({ ...imageState, preview, previewsCopy: preview, selectedFiles: newAssets, selectedFile })
        setEventState({ ...eventState })
    }


    const selectImages = () => {

        imageRef.current.click();
    }
    const selectPdf = () => {

        pdfRef.current.click();
    }
    const selectPoster = () => {

        posterRef.current.click();
    }

    const onChangePoster = (e: any, name: string) => {
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    if (name === 'poster') {
                        setPreviewPoster(images)
                    }
                    setEventState({
                        ...eventState,
                        [name]: files[0]
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onDeletePoster = () => {
        setEventState({
            ...eventState,
            poster: undefined
        })
        setPreviewPoster([])
    }

    const onChangeText = (e: FormEvent<EventTarget>) => {
        const { name, value } = (e.target as HTMLInputElement);
        setEventState({
            ...eventState,
            [name]: value
        })
    }

    const handleDate = (date: any, name: string) => {
        setEventState({
            ...eventState,
            [name]: date
        })
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (!onValidate()) return
        let items = eventState.items.map((i: ICollectionItem) => i.id)
        let data = new FormData()
        imageState.selectedFiles.forEach((i: any) => data.append('files', i))
        data.append('name', eventState.name)
        data.append('startDate', eventState.startDate)
        data.append('endDate', eventState.endDate)
        data.append('description', eventState.description)
        data.append('poster', eventState.poster)
        data.append('pdf', eventState.pdf)
        data.append('items', JSON.stringify(items))
        if (eventState.images && eventState.images.length > 0) data.append('images', JSON.stringify(eventState.images))

        setSubmitLoading(true)
        if (id) {
            eventService.updateEvent(id, data).then((res: AxiosResponse<IResponse<any>>) => {
                if (res.data.success) {
                    toastService.success(res.data.message)
                    setEventState({ ...initState })
                    setImageState({ ...initImageState })
                    setPreviewPoster([])
                } else {
                    toastService.error(res.data.message)
                }
                setSubmitLoading(false)
            }).catch((err: any) => {
                console.log(err)
                setSubmitLoading(false)
            })
            return
        }
        eventService.createEvent(data).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                setEventState({ ...initState })
                setImageState({ ...initImageState })
                setPreviewPoster([])
            } else {
                toastService.error(res.data.message)
            }
            setSubmitLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setSubmitLoading(false)
        })
    }

    const onValidate = () => {
        let { name, items, startDate, endDate } = eventState
        let newErr: any = {}
        if (!name) {
            newErr['name'] = ['Name is required']
        }

        if (!items || (items && items.length === 0)) {
            toastService.warning('You must add to the event at least one work of art from your collections.')
            newErr['items'] = ['Name is required']
        }
        if (!startDate) {
            newErr['startDate'] = ['Start Date is required']
        }
        if (!endDate) {
            newErr['endDate'] = ['End Date is required']
        }

        if (moment(startDate).valueOf() > moment(endDate).valueOf()) {
            newErr['endDate'] = ['End date must be greater than start date']
        }

        if (Object.keys(newErr).length > 0) {
            setErrors({ ...newErr })
            return false
        }
        setErrors({})
        return true

    }

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )



    const renederSelectedItems = () => {
        return (
            <div className="at-collectiongallerybox at-artgallerybox px-0 pb-2">
                <div className="at-gallerytitle">
                    <ul className="at-gallery">
                        {
                            eventState.items.map((item: ICollectionItem, index: any) => (
                                <li key={item.id}>
                                    <div>
                                        <figure>
                                            <img src={collectionImage(item, `high_${item.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                                            <div className="at-artgallerycollectionbox atv2-event-positionabs">
                                                <span className="at-checkbox">
                                                    <input type="checkbox" name="select" id="artcollectionselectall" checked={isSelected(item)} />
                                                    <label htmlFor="artcollectionone" onClick={() => onSelectEventArts(item)}></label>
                                                </span>
                                            </div>
                                            <div className="atv2-event-arrows">
                                                <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowup" title="Up" onClick={() => onMoveUp(index)} ></i>
                                                <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowdw" title="Down" onClick={() => onMoveDown(index)} ></i>
                                            </div>
                                        </figure>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }


    let customInput = <input type="text" autoComplete="off" className="form-control" />
    return (
        <div className="at-createcollection at-contentarea">
            <div className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    <a onClick={() => onBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Create Event</h2>
                </div>
                <form className="at-formtheme at-formcreatecollection" onSubmit={onSubmit}>
                    <fieldset>
                        <div className="form-group form-w-100">
                            <div className="at-themefileupload">
                                <input type="file" name="poster" multiple accept="image/*" ref={posterRef} onChange={(e: any) => onChangePoster(e, 'poster')} id="at-uploadfile-3" />
                                <label onClick={selectPoster}>
                                    <span><i className="icon-gallery"></i>UPLOAD EVENT POSTER</span>
                                </label>
                            </div>
                        </div>

                        {previewPoster && previewPoster.length > 0 && <div className={`form-group form-w-100`}>
                            <SliderComponent items={previewPoster} originalItems={previewPoster} onCrop={onCropPoster} currentIndex={0} onChangeIndex={() => { }} />
                            {
                                previewPoster && previewPoster.length > 0 && (<i className="icon-cancel delete-image cursor-pointer" onClick={() => onDeletePoster()}></i>)
                            }
                            {/* <CropImageComponent image={preview[selectedFile]} /> */}
                        </div>}
                        <div className="form-group form-w-50">
                            <div className="at-themefileupload">
                                <input type="file" name="files[name]" multiple accept="image/*" ref={imageRef} onChange={onChangeFiles} id="at-uploadfile" />
                                <label onClick={selectImages}>
                                    <span><i className="icon-gallery"></i>UPLOAD EVENT PICTURES</span>
                                </label>
                            </div>
                        </div>
                        <div className="form-group form-w-50">
                            <div className="at-themefileupload">
                                <input type="file" name="pdf" accept="application/pdf" ref={pdfRef} onChange={(e: any) => onChangePoster(e, 'pdf')} id="at-uploadfile-2" />
                                <label onClick={selectPdf}>
                                    {eventState.pdf ? <span><i className="icon-tick  at-color-greenlight"></i>pdf uploaded</span> : <span><i className="icon-pdf-big"></i>Upload event pdf</span>}
                                </label>
                            </div>
                        </div>
                        <div className={`form-group form-w-100`}>
                            <SliderComponent items={imageState.preview} originalItems={imageState.previewsCopy} onCrop={onCrop} currentIndex={imageState.selectedFile} onChangeIndex={(selectedFile: number) => setImageState({ ...imageState, selectedFile: selectedFile })} />
                            {
                                imageState.preview.length > 0 && (<i className="icon-cancel delete-image cursor-pointer" onClick={() => deleteImage()}></i>)
                            }
                            {/* <CropImageComponent image={preview[selectedFile]} /> */}
                        </div>
                        <div className="form-group form-w-100 at-formgroup-datepicker at-floatlabel">
                            <input autoComplete="off" type="text" name="name" value={eventState.name} className="form-control" placeholder=" " onChange={onChangeText} />
                            <label>Event Name</label>
                            {errorElement('name')}
                        </div>
                        <div className="form-group form-w-50 at-inputwithicon">
                            <DatePicker name="startDate" autoComplete="off" customInput={customInput} selected={eventState.startDate} placeholderText="Start Date" onChange={(date: any) => handleDate(date, 'startDate')} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('startDate')}
                        </div>
                        <div className="form-group form-w-50 at-inputwithicon">
                            <DatePicker name="endDate" autoComplete="off" popperPlacement="bottom-end" customInput={customInput} selected={eventState.endDate} placeholderText="End Date" onChange={(date: any) => handleDate(date, 'endDate')} />
                            <i className="icon-Calender fs-16"></i>
                            {errorElement('endDate')}
                        </div>

                        <div className="form-group ">
                            <label className="text-left at-color-grey">Event Description</label>
                            {/* <textarea name="description" placeholder=" " value={eventState.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}

                            <TmceEditor text={eventState.description} onChange={(e: any) => setEventState({ ...eventState, description: e })} />

                        </div>
                        <div className="form-group form-w-50">
                            <button type="button" className="at-btn fs-14 at-btn-white at-minwidth-100 at-btnpost" onClick={() => onClickViewEvents()} >{`Existing Events`}</button>
                        </div>
                        <div className="form-group form-w-50">
                            <button type="submit" className="at-btn at-minwidth-100 " disabled={submitLoading || (imageState.selectedFiles && imageState.selectedFiles.length === 0 && !id)}>{submitLoading ? 'Please wait...' : 'UPDATE'}</button>
                        </div>

                    </fieldset>
                </form>

                {eventState.items.length > 0 && <div className="at-collectiongallery">
                    <div className="col-md-12">
                        {
                            eventState.items.length > 0 && <div className="at-manageposttitle mt-3 d-flex justify-content-between">
                                <h2>{`Art Selected for Gallery Walk/Event`}</h2>
                                <div className="at-btndelete d-flex ">
                                    {/* <i className="icon-event fs-20 mr-3 cursor-pointer right" ></i> */}
                                    {selectedArts && selectedArts.length > 0 && <i className="icon-delete2 fs-20 mr-3 cursor-pointer right" title="Delete" onClick={onDeleteArts}></i>}

                                    <div className="at-checkbox">
                                        <input type="checkbox" name="remember" checked={isAllEventsArtSelected()} />
                                        <label htmlFor="artcollectionselectall" className="at-label-bdradius50" onClick={onSelectAllEventArts} ></label>
                                    </div>
                                </div>
                            </div>
                        }
                        {renederSelectedItems()}
                    </div>
                </div>
                }

                {!loading && <div className="at-collectiongallery pt-3">
                    <div className="col-md-12">
                        {
                            <div className="at-manageposttitle">
                                <h2>{`Collections`}</h2>
                            </div>
                        }
                        {
                            loading && <LoadingComponent />
                        }
                        {
                            collections.map((c: ICollection) => <EventAssignedCollectionView key={c.id} c={c} user={user} selectedForEvent={eventState.items} onAddToEvents={onAddEventArts} onClickOnCollection={onClickOnCollection} onClickOnItem={onClickOnItem} />)
                        }
                    </div>
                </div>
                }

            </div>
        </div>
    )

}

export default AssignedCreateEventPage