import { Subject, ReplaySubject } from 'rxjs'

import { HttpService } from './base.service'
import { IRtcAction, IRtcConnectionState } from '../interfaces'
import { spaceService } from './space.service'

interface ISpace {
	spaceId: string
}

class RtcService extends HttpService {
	private readonly prefix: string = 'rtc'

	/**
	 * Get token against space id
	 * @param  {ISpace} space
	 */
	getToken = (space: ISpace): Promise<{ token: string }> =>
		this.get(`${this.prefix}/generate-token?channelId=${space.spaceId}`).then(
			(res) => res.data
		)

	/** 
	 * Invite Users
	 */
	inviteUser = (users: Array<string>) => spaceService.inviteUserToActiveSpace(users)
}

export const RtcStreamActions = (() => {
	const rtcEvents$ = new Subject<any>()
	return {
		triggerAction: (action: any) => rtcEvents$.next(action),
		listen: rtcEvents$,
	}
})()

export const RtcStreamConnectionActions = (() => {
	const rtcEvents$ = new Subject<boolean>()
	return {
		triggerAction: (status: boolean) => rtcEvents$.next(status),
		listen: rtcEvents$,
	}
})()

export const AgoraLeaveActions = (() => {
	const rtcEvents$ = new Subject<boolean>()
	return {
		triggerAction: (status = false) => rtcEvents$.next(status),
		listen: rtcEvents$,
	}
})()

export const NotificationAction = (() => {
	const rtcEvents$ = new Subject<Object>()
	return {
		triggerAction: (status = {}) => rtcEvents$.next(status),
		listen: rtcEvents$,
	}
})()

export const NotificationCountAction = (() => {
	const rtcEvents$ = new Subject<number>()
	return {
		triggerAction: (status = 0) => rtcEvents$.next(status),
		listen: rtcEvents$,
	}
})()

export const UserAlertAction = (() => {
	const rtcEvents$ = new Subject<Object>()
	return {
		triggerAction: (status = {}) => rtcEvents$.next(status),
		listen: rtcEvents$,
	}
})()

export const UserAlertCountAction = (() => {
	const rtcEvents$ = new Subject<number>()
	return {
		triggerAction: (status = 0) => rtcEvents$.next(status),
		listen: rtcEvents$,
	}
})()

export const UserLoginAction = (() => {
	const rtcEvents$ = new Subject<boolean>()
	return {
		triggerAction: (status = false) => rtcEvents$.next(status),
		listen: rtcEvents$
	}
})()

export const AdminRouteAction = (() => {
	const rtcEvents$ = new Subject<boolean>()
	return {
		triggerAction: (status = false) => rtcEvents$.next(status),
		listen: rtcEvents$
	}
})()

export const ShowTutorialPageAction = (() => {
	const rtcEvents$ = new Subject<boolean>()
	return {
		triggerAction: (status = false) => rtcEvents$.next(status),
		listen: rtcEvents$
	}
})()


export const AuctionAddNewArtAction = (() => {
	const rtcEvents$ = new Subject<boolean>()
	return {
		triggerAction: (status = false) => rtcEvents$.next(status),
		listen: rtcEvents$
	}
})()

export const AuctionArtBiddingStopAction = (() => {
	const rtcEvents$ = new Subject<string>()
	return {
		triggerAction: (status = '') => rtcEvents$.next(status),
		listen: rtcEvents$
	}
})()

export const AuctionArtBiddingRestartAction = (() => {
	const rtcEvents$ = new Subject<string>()
	return {
		triggerAction: (status = '') => rtcEvents$.next(status),
		listen: rtcEvents$
	}
})()

export const AuctionArtBiddingUpdateAction = (() => {
	const rtcEvents$ = new Subject<string>()
	return {
		triggerAction: (status = '') => rtcEvents$.next(status),
		listen: rtcEvents$
	}
})()

export const AuctionArtPurchasedAction = (() => {
	const rtcEvents$ = new Subject<string>()
	return {
		triggerAction: (status = '') => rtcEvents$.next(status),
		listen: rtcEvents$
	}
})()


export const rtcService = new RtcService()

export const GSTT = {
	CREATE_MEETING: 'CREATE_MEETING',
	INIT_GOOGLE_CLOUD_STREAM: 'INIT_GOOGLE_CLOUD_STREAM',
	START_GOOGLE_CLOUD_STREAM: 'START_GOOGLE_CLOUD_STREAM',
	END_GOOGLE_CLOUD_STREAM: 'END_GOOGLE_CLOUD_STREAM',
	INTERIM_RESPONSE: 'INTERIM_RESPONSE',
	FINAL_RESPONSE: 'FINAL_RESPONSE',
	SEND_MESSAGE: 'SEND_MESSAGE'
}