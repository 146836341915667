import React, {Component} from 'react';
import { RouteComponentProps, Link, useLocation } from 'react-router-dom';
import StreamingHeaderComponent from "../../../components/common/StreamingHeader.component";
import {images} from "../../../assets/image";
import { IPost, ICollectionItem, ICollection, IStoreReducers, IUser, IOffer } from '../../../interfaces';
import { postService } from '../../../services/post.service';
import { AxiosResponse, AxiosError } from 'axios';
import { APP_DOMAIN } from '../../../environment';
import HeartComponent from '../../../components/ArtCentral/feed/HeartIcon.component'
import { routes } from '../../../router';
import LoadingComponent from '../../../components/common/Loading.component';
import { collectionService } from '../../../services/collection.service';
import { collectionImage, userImage } from '../../../shared';
import { connect } from 'react-redux';
import { ConfirmAlertComponent } from '../../../components/common/ConfirmAlert.component';
import { toastService, offerService } from '../../../services';
import { locale, lang } from '../../../locale';
import { UserPicComponent, UserNameComponent } from '../../../components/User/index.component';
import MultiClickComponent from '../../../components/common/MultiClick.component';
import CollectionItemViewComponent from '../../../components/ArtCentral/collection/ItemView.component';
import { IResponse } from '../../../interfaces/db.interface';

interface IPageProps extends RouteComponentProps<{id: string}> {
    user: IUser
}

interface IPageState {
    item?: ICollectionItem
    loading: boolean
    offer?: IOffer
}
class CollectionItemViewPage extends Component<IPageProps> {
    state: IPageState = {
        loading: false
    }

    componentDidMount() {
        if(this.query){
          this.fetchOffer()
        }
        this.fetch();
    }

    toggleLoading = (): void => {
		this.setState({ loading: !this.state.loading })
    }

    get params () {
        return this.props.match.params;
    }

    get query (){
        return this.props.location.search
    }

    fetch = (): void => {
        this.toggleLoading();
        collectionService.showItem(this.params.id).then((response: AxiosResponse<IResponse<ICollectionItem>>) => {
            this.setState({ item: response.data.data })
            this.toggleLoading();
        }, (error: AxiosError) => {
            this.toggleLoading();
        })
    }

    fetchOffer = () => {
      let query = new URLSearchParams(this.props.location.search).get("offer")
      offerService.getSingleOffer(query).then((res: AxiosResponse<Array<IOffer>>) => {
          this.setState({offer: res.data[0]})
      })
    }
    

    render() {
        const { loading, item, offer } = this.state;
        const { _id: userId } = this.props.user;
        
         
        return (
            <div className="at-collectiondetail at-collectiondetailview at-contentarea scrollable"> 
                {
                    loading && <LoadingComponent />
                }
                {
                    item && (
                        <CollectionItemViewComponent item={item} actionOffer={offer} {...this.props} refresh={this.fetch} refreshOffer={this.fetchOffer}/>
                    )
                }
            </div>
        );
    }
}

const mapState = (state: IStoreReducers) => ({
    user: state.user
})

export default connect(mapState, {})(CollectionItemViewPage);