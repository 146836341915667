import React from 'react'

const Footer = () => {
    return <div>
        <section className="At-SectionFooter">
            <div className="container">
                <div className="At-BorderTop">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p>© Omni 2021. All rights reserved.</p>
                        </div>
                        <div className="col-12 col-sm-6">
                            {/* <ul className="At-Socials">
                                <li>
                                    <a href="#"><i className="icon-fb"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="icon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="icon-instagram2"></i></a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>

            </div>
        </section>

    </div>
}

export default Footer