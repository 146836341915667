import React, { useEffect, useRef } from 'react'

interface IProps {
    onClose: Function
}

const useOutsideAlerter = (ref: any, onClose: Function) => {
    function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
            onClose()
        }
    }
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */    

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    },[ref]);
}

export const HelpBox = ({ onClose }: IProps) => {
    const wrapperRef = useRef<any>('');
    useOutsideAlerter(wrapperRef, onClose);
    return <div className="at-message-popup-overlay at-messagepopup-orange-overlay">
        <div ref={wrapperRef} className="at-message-popup ">
            <i className="icon-arrow-up At-ArrowIcon"></i>
            <i className="icon-cancel At-IconClose" onClick={() => onClose()}></i>
            <p className="mb-0">
                Please click Allow to access important OMNI communication features.
            </p>
            {/* <button className="at-btn at-btn-green" >
                OK
            </button> */}
        </div>
    </div>

}