import React, { Component, FormEvent } from 'react';
import { IPost, IStoreReducers, IUser, IPostComment, ISpace, ITemplateReducerState } from "../../../interfaces";
import { routes } from "../../../router";
import { RouteComponentProps } from 'react-router-dom';
import HeartComponent from './HeartIcon.component';
import { connect } from 'react-redux';
import moment from 'moment';
import FeedImageComponent from './Image.component'
import { postService } from '../../../services/post.service';
import { AxiosResponse, AxiosError } from 'axios';
import { FacebookShareButton } from 'react-share'
import { toastService, spaceService, userService, UserLoginAction } from '../../../services';
import { UserPicComponent } from '../../User/index.component';
import { API_ENDPOINT, APP_URL } from '../../../environment';
import { _logger } from '../../../helper';
import { socketNode } from '../../../services/socket.service';
import { ADD_NEW_COMMENT, ADD_NEW_MEMBER, USER_ALERT_SPACE_ENTER, USER_ALERT_SPACE_MESSAGE } from '../../../constants';
import { ZoomActions, EZoom } from '../../../services/zoom.service';
import CommentsComponent from '../../common/Comments.component';
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component';
import {
	changeActiveSpace, activateSpaceAction, fetchUserStudio, setInPlatformWebState
} from '../../../store/actions'

import $ from 'jquery';
import { MessageModal } from '../../common/Modals/MessagePopup';
import { ERole } from '../../../enums';
import { getUserTemplateRoute } from '../../../shared';

interface IProps extends RouteComponentProps {
	feed: IPost
	user?: IUser
	openCommentBox: any
	openAction?: any
	hideMoreButton?: boolean
	hideZoomButton?: boolean
	showInLineComments?: boolean
	changeActiveSpace?: Function
	activateSpaceAction?: any
	token?: any
	fetchUserStudio: Function
	setInPlatformWebState: Function
}

interface IPageState {
	comment: string
	more: boolean
	loading: boolean
	count: number
	openSpacePopup: boolean
	showMessageBox: boolean,
	studioRoute: string
}
class FeedComponent extends Component<IProps> {

	state: IPageState = {
		loading: false,
		more: false,
		comment: '',
		count: 0,
		openSpacePopup: false,
		showMessageBox: false,
		studioRoute: ''
	}

	get detailLink() {
		return `${routes.artCentral.post.details}/${this.props.feed._id}`;
	}

	toggleMoreLess = () => {
		this.setState({ more: !this.state.more })
	}

	componentDidMount() {
		this.receiveCommentSocket();
		this.setState({ count: this.props.feed.commentsCount })
	}


	getDescription = () => {
		const { feed } = this.props;
		const { more } = this.state;
		const showDesc = feed.description.substring(0, 110);
		if (feed.description.length < 111) {
			return showDesc
		}
		return <div className="pt-0">{more ? feed.description : showDesc}<a className="cursor-pointer secondary-color" onClick={this.toggleMoreLess}>{more ? 'Less' : '... More'}</a></div>
	}


	onChangeText = (e: FormEvent<HTMLInputElement> | any): void => {
		const { value: comment } = e.target;
		this.setState({ comment })
	}

	toggleLoading = (): void => {
		this.setState({ loading: !this.state.loading })
	}

	submit = (e: FormEvent): void => {
		e.preventDefault();
		this.toggleLoading();
		const { comment } = this.state;
		postService.addComment(this.props.feed._id, { comment }).then((response: AxiosResponse<IPostComment>) => {
			this.toggleLoading();
			this.setState({ comment: '' })
		}, ({ response }: AxiosError) => {
			_logger('', response);

			// if (response.status === 401) this.props.history.push('/');
		})
	}

	postShared = (): void => {
		// toastService.info('Post shared successfully.')
	}


	receiveCommentSocket = () => {
		socketNode.on(ADD_NEW_COMMENT, (comment: IPostComment) => {
			if (comment.post === this.props.feed._id) this.setState({ count: this.state.count + 1 });
		})
	}

	openArtCentralSpacePopup = async (route: string = '') => {
		let { token } = this.props
		if (!token) {
			UserLoginAction.triggerAction(true)
			return
		}
		$('#at-wrapper').addClass('no-scroll')
		const { feed, changeActiveSpace, user, history } = this.props

		if (window.innerWidth > 770) {
			if (feed.user && feed.user.template && (feed.user.template as ITemplateReducerState).url) {
				this.props.setInPlatformWebState({
                    nextPath: `${(feed.user.template as ITemplateReducerState)?.url}/${getUserTemplateRoute(feed.user.template as ITemplateReducerState)}`,
                    isActive: true,
                    previousPath: routes.home
                })
                history.push(routes.loadingRoutes)
                return
			}
		}
		const space: ISpace = (await spaceService.userArtCentralSpace(feed.user._id)).data;
		changeActiveSpace && changeActiveSpace(space)
		socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
		this.sendUserAlert(`${user?.firstName} ${user?.lastName} has just entered your space.`, USER_ALERT_SPACE_ENTER, false, space._id)
		this.props.fetchUserStudio(feed.user._id)
		// this.closeArtCentralSpacePopup();
		this.props.activateSpaceAction(true)
		history.push(route, feed._id)
	}

	onClosePopup = () => {

		$('#at-wrapper').removeClass('no-scroll')
		this.setState({ openSpacePopup: false })
	}

	closeArtCentralSpacePopup = () => {
		this.onClosePopup();
		const { feed, history } = this.props
		history.push(this.state.studioRoute, feed._id)
	}

	// confirmArtCentralSpacePopup = async () => {
	// 	const { feed, changeActiveSpace, user } = this.props
	// 	const space: ISpace = (await spaceService.userArtCentralSpace(feed.user._id)).data;
	// 	changeActiveSpace && changeActiveSpace(space)
	// 	socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
	// 	this.sendUserAlert(`${user?.firstName} ${user?.lastName} has just entered your space.`, USER_ALERT_SPACE_ENTER, false, space._id)
	// 	this.closeArtCentralSpacePopup();
	// 	this.props.activateSpaceAction(true)
	// }

	openMessageBox = () => {
		this.setState({ showMessageBox: true })
	}

	closeMessageBox = () => {
		this.setState({ showMessageBox: false })
	}

	onSubmitMessageBox = (message: string) => {
		this.sendUserAlert(message, USER_ALERT_SPACE_MESSAGE, true)
	}

	sendUserAlert = (message: string, type: string, showToast: boolean = false, spaceId: any = null) => {
		const { feed, changeActiveSpace, user } = this.props
		if (user?._id === feed.user._id) {
			return
		}
		let data = {
			message: message,
			to: feed.user._id,
			from: user?._id,
			type: type,
			spaceID: spaceId
		}
		userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
			if (showToast && res.data.status === 'Success') {
				toastService.success("Message sent successfully")
			}
			this.closeMessageBox()
		}).catch((err: any) => {
			console.log(err)
			this.closeMessageBox()
		})
	}

	onClickTemplateIcon = (template: ITemplateReducerState) => {
		if (!template || !template.url) return
		window.location.replace(`${APP_URL}/${template?.url}`)
	}

	get commentText() {
		const { count: cc } = this.state
		if (cc === 1) {
			return 'View 1 comment'
		} else if (cc > 1) {
			return `View all ${cc} comments`
		} else {
			return 'Add comment'
		}
	}

	getUserTypeTooltips = (u: IUser) => {
		if (!u) return ''
		if (u?.roles?.includes(ERole.ARTIST)) {
			return 'Artist'
		}
		if (u?.roles?.includes(ERole.AGENT)) {
			return 'Gallery'
		}
		return ''
	}
	render() {

		const { origin } = window.location
		const { feed, user, openCommentBox, openAction, hideMoreButton, hideZoomButton, showInLineComments, token } = this.props;
		const { loading, comment, openSpacePopup, showMessageBox } = this.state;
		// const shareUrl = origin + this.detailLink
		const shareUrl = `${API_ENDPOINT}/post-detail/show/${this.props.feed._id}`;
		const isAgent = feed.user.roles.includes(ERole.AGENT)
		return (
			<>
				<div className={`at-post restore-${feed._id}`}>
					<div className="at-orangeeconhead">
						<UserPicComponent user={feed.user} />
						<div className="at-orangeeconcontent">
							{
								feed.user.isDeaf && <span className="at-postcontenticon"><i className="icon-hand"></i></span>
							}
							<h3>{isAgent ? feed.user.gallery_name : `${feed.user.firstName} ${feed.user.lastName}`}</h3>
							<div className="at-btniconholder">
								{/* {feed.user?.template && <a className="at-btnomniicon at-postbtn at-icon-intemplate" title={`${this.getUserTypeTooltips(feed.user)} Website`}><i className="icon-intemplate" onClick={() => this.onClickTemplateIcon((feed.user.template as ITemplateReducerState))}></i></a>} */}
								<a onClick={() => this.openArtCentralSpacePopup(`${routes.artCentral.collection.list}/${feed.user._id}`)} title="Art Collection" className="at-btnmenu at-postbtn at-artcollectbtn">
									{/* <i className="icon-menu"></i>
									<p>{isAgent ? 'Gallery' : `Studio`}</p> */}
                                <button className="at-btn at-doubleextrasmallbtn">ART {isAgent ? 'GALLERY' : `STUDIO`}</button>

								</a>
								{/* Remove from SprintONE */}
								{token && user && <>
									{/* <a onClick={() => this.openArtCentralSpacePopup(`${routes.artCentral.events.list}/${feed.user._id}`)} title="Events" className="at-btnmenu at-postbtn">
										<i className="icon-event-view"></i>
										{!token && <p>Events</p>}
									</a> */}
									<a className="at-btnomniicon at-postbtn" title="Message"><i className="icon-send" onClick={() => this.openMessageBox()}></i></a>
									<a className="at-btnomniicon at-postbtn" title="Post">
										<FacebookShareButton quote={feed.description} url={shareUrl} onShareWindowClose={this.postShared}>
											{
												(shareCount: any) => <span className="myShareCountWrapper">{shareCount}</span>
											}
											<i className="icon-facebook-1"></i>
										</FacebookShareButton>
									</a>
									<HeartComponent postId={feed._id} userId={feed.user._id} />
								</>
								}

								{/* {token && user && <>
									<a id="at-btnomniicon" className="at-btnomniicon at-postbtn"><i className="icon-omini-logo"></i></a>
									{!hideZoomButton && <a id="at-btnomniicon" title="Full Screen" className="at-btnomniicon at-postbtn at-fullmode-btn" onClick={() => {
										ZoomActions.triggerAction({ type: EZoom.FEED, value: this.props.feed })
									}}><i className='icon-switch'></i></a>}
								</>
								} */}

								{
									(!hideMoreButton && token && feed.user._id === user?._id) && <a className="at-btnmore at-postbtn cursor-pointer" title="Edit" onClick={() => openAction(feed._id)}><i className="icon-more"></i></a>
								}
							</div>
						</div>
					</div>
					<figure className="at-postimg">
						<FeedImageComponent  {...this.props} feed={feed} redirect={true} />
					</figure>
					<div className="at-description">
						<p>{this.getDescription()}</p>
						<span>{moment(feed.createdAt).fromNow()}</span>
					</div>
					{token && <form className="at-formtheme at-formpostcomment" onSubmit={this.submit}>
						<fieldset>
							<div className="form-group">
								{/* <figure className="at-addcommentimg"> */}
								{
									(!showInLineComments && user && user._id && token) && <UserPicComponent user={user} className="at-addcommentimg" />
								}
								{/* </figure> */}
								{showInLineComments ? <CommentsComponent id={feed._id} /> : <span className="left text-date cursor-pointer" onClick={() => openCommentBox(feed._id)}>{this.commentText}</span>}

								{/* <input type="text" name="addcomment" disabled={true} onClick={openCommentBox} value={comment} className="form-control" placeholder="Add Comment..." onChange={this.onChangeText} autoComplete="off"/> */}
							</div>
						</fieldset>
					</form>}
				</div>
				{/* <ConfirmAlertComponent closeable={true} onClose={this.onClosePopup} open={openSpacePopup} onCancel={this.onClosePopup} title="Confirm!" message={`Confirm to join the conversation at ${feed.user.firstName} ${feed.user.lastName}'s Studio`} onConfirm={this.confirmArtCentralSpacePopup} confirmTitle="Confirm" cancelTitle="Cancel" /> */}
				{showMessageBox && <MessageModal open={showMessageBox} onClose={this.closeMessageBox} onSubmit={this.onSubmitMessageBox} message='' title="Send Message" />}
			</>
		);
	}
}

const mapState = (state: IStoreReducers) => ({
	user: state.user,
	token: state.auth.access_token
})
export default connect(mapState, { changeActiveSpace, activateSpaceAction, fetchUserStudio, setInPlatformWebState })(FeedComponent);