import {toast} from "react-toastify";

class ToastService {
    private readonly  config: any = {
        type: 'default',
        position: 'top-center',
        autoClose: true
    };

    success = (message: string, option: any = {}): void => {
        toast(message, {...this.config, ...option, type: 'success'})
    };

    error = (message: string, option: any = {}): void => {
        toast(message, {...this.config, ...option, type: 'error'})
    };

    info = (message: string, option: any = {}): void => {
        toast(message, {...this.config, ...option, type: 'info'})
    };

    warning = (message: string, option: any = {}): void => {
        toast(message, {...this.config, ...option, type: 'warning'})
    }
}

export const toastService = new ToastService();