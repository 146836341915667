import React, { Component } from 'react';
import { routes } from '../../../../router';
import { ConfirmAlertComponent } from '../../../common/ConfirmAlert.component';
import { RouteComponentProps, Link } from 'react-router-dom';
import { ICollection, ICollectionItem, IUser } from '../../../../interfaces';
import { collectionService } from '../../../../services/collection.service';
import { toastService } from '../../../../services';
import { lang } from '../../../../locale';
import { collectionImage } from '../../../../shared';
import { AxiosResponse } from 'axios';
import { images } from '../../../../assets/image';
import { ERole } from '../../../../enums';
import { DescriptionModal } from '../../../common/Modals/Description.modal'
interface IProps extends RouteComponentProps {
    collection: ICollection
    refreshList: Function
    user?: IUser
    onMoveDown?: any
    index?: any,
    onMoveUp?: any
    isUpdate?: boolean
    onClickCollection: Function
    onEditCollection: Function
    onClickOnItem: Function
}

interface IComponentState {
    submitting: boolean
    collection: ICollection | any
    openConfirmBox: boolean
    deleteId: string
    deletingPost: boolean
    editing: boolean,
    selectedCollection: ICollection | any,
    showDescription: boolean
}
class AssignedCollectionComponent extends Component<IProps> {

    state: IComponentState = {
        submitting: false,
        openConfirmBox: false,
        deleteId: '',
        collection: {},
        deletingPost: false,
        editing: false,
        selectedCollection: {},
        showDescription: false
    }

    componentDidMount() {
        this.setState({ collection: this.props.collection })
    }

    componentWillReceiveProps(props: IProps) {
        this.setState({ collection: this.props.collection })
    }


    collectionImages = (c: ICollection) => {
        let { onClickOnItem } = this.props
        const items = c.items.length > 5 ? c.items.slice(0, 5) : c.items;
        const isAgent = c.user.roles.includes(ERole.AGENT);
        return (
            items.map((item: ICollectionItem) => (
                <li key={item.id}>
                    <a onClick={() => onClickOnItem(item)}>
                        <figure>
                            <img src={collectionImage(item, `high_${item.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                        </figure>
                    </a>
                </li>
            ))
        )
    }

    actions = (c: ICollection) => {
        const user: any = c.user;
        const { user: currentUser, onEditCollection } = this.props;
        if ((currentUser && currentUser._id !== user._id) || currentUser?.isAgent) return;
        return (
            <span>
                <div className="at-btndelete d-flex align-items-center">
                    <i className="icon-info fs-20 mr-3 cursor-pointer left" onClick={() => this.openDescription(c)}></i>
                    <i className={`${c.isHide ? 'icon-view-disable' : 'icon-view'} fs-20 mr-3 cursor-pointer right`} onClick={() => this.hideCollection(c.id, !c.isHide)}></i>
                    <i className="icon-delete2 fs-20 mr-3 cursor-pointer right" title="Delete" onClick={() => this.setState({ deleteId: c.id, openConfirmBox: true })}></i>
                    <i className="icon-edit2 fs-20 cursor-pointer right" title="Edit" onClick={() => onEditCollection(c)}></i>
                    <div className="d-flex at-arrow-wrap">
                        <i className={`icon-Down-Arrow fa-rotate-180 fs-14 cursor-pointer `} onClick={() => this.props.onMoveUp(this.props.index)}></i>
                        <i className={`icon-Down-Arrow fs-14 cursor-pointer `} onClick={() => this.props.onMoveDown(this.props.index)}></i>
                    </div>
                </div>
            </span>
        )
    }

    deletePost = () => {
        this.setState({ deletingPost: true })
        collectionService.deleteCollection(this.state.deleteId).then((response: AxiosResponse<{ message: string }>) => {
            toastService.success(lang.collection.deletedSuccessfully)
            this.props.refreshList();
        })
    }

    updateData = () => {
        const { title: oldTitle } = this.props.collection;
        const { title: newTitle } = this.state.collection;
        this.setState({ editing: false })
        if (oldTitle.trim() === newTitle.trim() || newTitle.trim() === "") return;
        this.setState({ submitting: true })
        collectionService.updateName(this.props.collection.id, { title: this.state.collection.title }).then((response: AxiosResponse<{ message: string }>) => {
            toastService.success(lang.collection.updatedSuccessfully)
            this.setState({ submitting: false, editing: false })
            this.props.refreshList();
        })
    }

    hideCollection = (id: any, status: boolean) => {
        let { collection } = this.state
        collectionService.hideCollection(id, { isHide: status }).then((res: AxiosResponse<any>) => {
            toastService.success(res.data.message)
            this.setState({ collection: { ...collection, isHide: status } })
            this.props.refreshList();
        })
    }

    openDescription = (c: ICollection) => {
        this.setState({ showDescription: true, selectedCollection: c })
    }

    closeDescriptionPop = () => {
        this.setState({ showDescription: false, selectedCollection: {} })
    }

    onSubmitDescription = () => {
        this.setState({ showDescription: false, selectedCollection: {} })
        this.props.refreshList()
    }
    render() {
        const { user, onClickCollection } = this.props;
        const { openConfirmBox, deletingPost, collection: c, editing, submitting, showDescription, selectedCollection } = this.state;
        // console.log("rerender:-=-=-=-=-=-=",c)
        return (
            <>
                {
                    c.itemsCount > 0 && <div className="at-collectiongallerybox at-artgallerybox" id={c.id} key={c.id}>
                        <div className="at-gallerytitle">
                            {/* <h3 className="cursor-pointer" onClick={() => this.props.history.push(`${routes.artCentral.collection.view}/${c.id}`)}>{c.title}<span>{c.itemsCount} Images</span></h3> */}
                            <h3>
                                {
                                    !editing ? <a onClick={() => onClickCollection(c)}>
                                        {c.title}
                                    </a> : <input className='form-control' value={c.title} onBlur={this.updateData} onChange={(v: any) => this.setState({ collection: { ...c, title: v.target.value } })} />
                                }
                                {
                                    (user && user._id && user.roles.includes(ERole.ARTIST)) && (
                                        <>
                                            {
                                                user._id === c.user._id && <i className={`ml-2 cursor-pointer ${editing ? 'icon-tick' : 'icon-edit'}`} title={`${editing ? 'Done' : 'Edit'}`} onClick={() => {
                                                    editing ? this.updateData() : this.setState({ editing: true })
                                                }}></i>
                                            }
                                        </>
                                    )
                                }
                                <span>{c.itemsCount} Images</span>
                            </h3>

                            {
                                this.actions(c)
                            }
                            {/* {
                            } */}

                        </div>
                        <ul className="at-gallery">
                            {this.collectionImages(c)}
                            {
                                (c.items.length > 5) && (
                                    <li>
                                        <Link to={`${routes.artCentral.collection.view}/${c.id}`}>
                                            <figure>
                                                <img src={images.gallery.img01} alt="" />
                                                <figcaption>
                                                    <span><i className="icon-add"></i>{c.items.length - 5}</span>
                                                </figcaption>
                                            </figure>
                                        </Link>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                }
                {showDescription && <DescriptionModal open={showDescription} title={selectedCollection.title} description={selectedCollection.description} keyword={selectedCollection.keywords} onClose={this.closeDescriptionPop} collection={selectedCollection} event={collectionService.updateDescription} onSubmit={this.onSubmitDescription} user={(user as IUser)} />}
                <ConfirmAlertComponent
                    open={openConfirmBox}
                    onCancel={() => this.setState({ openConfirmBox: false })}
                    onConfirm={this.deletePost}
                    confirmTitle={deletingPost ? 'Deleting...' : 'Confirm'}
                    message='Are you sure you want to delete this collection?'
                />
            </>
        );
    }
}

export default AssignedCollectionComponent;