import {IPost} from "../../interfaces";
import {IActionType, SAVE_FEEDS} from "../types";

const initialState: Array<IPost> = []

export const feedReducer = (state = initialState, action: IActionType ): Array<IPost> => {

    switch (action.type) {
        case SAVE_FEEDS:
            return [...action.payload];
        default:
            return state;
    }
}
