import { AxiosResponse } from 'axios'
import { param } from 'jquery'
import React, { FormEvent, useCallback, useRef, useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { images } from '../../../../assets/image'
import { EArtistPrintType } from '../../../../enums'
import { PLATFORM_FEE } from '../../../../environment'
import { dataURLtoFile } from '../../../../helper'
import { curatorialProposalImageState, ICollection, ICollectionItem, ICuratorialInvitationDocument, ICuratorialProposalArtsDocument, ICuratorialProposalArtState, ICuratorialProposalDocument, ICuratorialProposalImageState, ICuratorialProposalState, IFormates, initCuratorialProposalArtState, initProposalState, IProposalArtFormats } from '../../../../interfaces'
import { IResponse } from '../../../../interfaces/db.interface'
import { IArtistSelectedService } from '../../../../interfaces/services.interface'
import { routes } from '../../../../router'
import { curatorialService, toastService } from '../../../../services'
import { collectionService } from '../../../../services/collection.service'
import { collectionImage } from '../../../../shared'
import { userSelector } from '../../../../store/selectors'
import { EventCollectionView } from '../../../ArtCentral/events/EventsCollectionView'
import { CurrencyField } from '../../../common/CurrencyInput'
import { ErrorComponent } from '../../../common/Error.component'
import LoadingComponent from '../../../common/Loading.component'
import { SliderComponent } from '../../../common/Slider.component'
import { TmceEditor } from '../../../common/TMCEditor.comopnent'

import { CuratorialProposalArtsComponent } from './proposal/CuratorialProposalArts.component'

interface IProps {

}
const CreateCuratorialProposal = ({ }: IProps) => {


    const params = useParams()
    const user = useSelector(userSelector)
    const history = useHistory()
    const artDetailRef = useRef<any>(null)
    const [errors, setErrors] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [collections, setCollections] = useState<Array<ICollection>>([])
    const [selectedArts, setSelectedArts] = useState<Array<ICollectionItem>>([])
    const [selectedProposalArts, setSelectedProposalArts] = useState<Array<ICuratorialProposalArtState>>([])
    const [deletedProposalArts, setDeletedProposalArts] = useState<Array<ICuratorialProposalArtState>>([])
    const [state, setState] = useState<ICuratorialProposalState>({ ...initProposalState })
    const [proposalImages, setProposalImage] = useState<ICuratorialProposalImageState>({ ...curatorialProposalImageState })
    const [invitation, setInvitation] = useState<ICuratorialInvitationDocument>()

    const fetchCollections = useCallback(() => {
        collectionService.listWithItems().then((response: AxiosResponse<IResponse<Array<ICollection>>>) => {
            setCollections([...response.data.data])
            let artsData = response.data.data.reduce((n: any, c: ICollection) => {
                return n.concat(c.items)
            }, [])
        }).catch((err: any) => {
            console.log(err)
        })
    }, [])

    const fetchInvitation = () => {
        if (!params || !((params as any).invitation)) return
        curatorialService.getInvitationsById((params as any).invitation).then((res: AxiosResponse<IResponse<any>>) => {
            setInvitation({ ...res.data.data })
        }).catch((err: any) => {
            console.log(err)
        })
    }
    const fetchProposal = () => {
        if (!params || !((params as any).id)) return
        curatorialService.getProposalById((params as any).id).then((res: AxiosResponse<IResponse<ICuratorialProposalDocument>>) => {
            if (res.data.success) {
                setState({
                    ...state,
                    ...res.data.data
                })
                let othrImages = res.data.data?.images?.map((i: any) => collectionImage({} as any, i.url || '')) || ['']
                setProposalImage({
                    ...proposalImages,
                    preview: [...othrImages],
                    previewsCopy: [...othrImages]
                })
                if (res.data.data.proposalArts.length > 0) {
                    let arts = res.data.data.proposalArts.map((i: ICuratorialProposalArtsDocument) => i.art)
                    setSelectedArts([...arts])
                }
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const artFormatesTotalCost = (f: IFormates) => {
        let total = Number(f.price) + Number(f.shipping) + Number(PLATFORM_FEE)
        let service = f.selectedService as IArtistSelectedService
        if (service && (service.printType === EArtistPrintType.UNFRAMED || service.printType === EArtistPrintType.FRAMED)) {
            console.log((f.selectedService as IArtistSelectedService)?.selectedServices[0]?.totalCost)
            total = total + Number(f.buyerSelectedService?.totalCost || 0) + Number(PLATFORM_FEE)
        }

        if (service && service.printType === EArtistPrintType.OWN && service.artistOwnService && service.artistOwnService.price) {
            total = total + Number(service.artistOwnService.price) + Number(service.artistOwnService.shipping)
        }
        return Number(total).toFixed(2)
    }

    const checkItemFormats = (item: ICollectionItem) => {
        if (!item) return item
        let formats = item.formates

        if (formats && formats.length > 0) {
            let newFormats = formats.map((f: IFormates) => {
                if (f.printType === EArtistPrintType.UNFRAMED) {
                    f.buyerSelectedService = (f.selectedService as IArtistSelectedService)?.selectedServices[0]
                }
                f.price = artFormatesTotalCost(f)
                return f
            })
            item.formates = newFormats
        }
        return item
    }

    const onAddProposalArts = (array: Array<ICollectionItem>) => {
        let newArts = selectedArts
        array = array.map((i: ICollectionItem) => checkItemFormats(i))
        let checkAssigned = array.filter((i: ICollectionItem) => {
            if (i.assignment) {
                return i
            }
        })

        if (checkAssigned.length > 0) {
            toastService.warning("Plaese add unassigned arts")
            return
        }
        let mapping = array.map((i: ICollectionItem) => {
            let proposalArt = { ...initCuratorialProposalArtState }
            proposalArt.proposalFormats = []
            proposalArt.price = i.price
            proposalArt.art = { ...i }
            let data: IProposalArtFormats = {
                price: Number(Number(i.price) + (Number(i.shipping) + Number(PLATFORM_FEE))).toFixed(2),
                size: 'original',
                isSelected: false,
                shipping: i.shipping,
                printType: '',
                selectedService: null,
                formatId: null,
                offerPrice: '',
                qauntity: 1,
                proposalPrice: '',
                weight: i.weight,
                shippingInsuranceAmount: i.shippingInsuranceAmount
            }
            proposalArt.proposalFormats.push({ ...data })
            data.size = 'commission work'
            proposalArt.proposalFormats.push({ ...data })
            i.formates = i.formates.filter((f: IFormates) => f.active)
            i.formates.map((f: IFormates) => {
                let newForm: IProposalArtFormats = { ...f, formatId: f._id, offerPrice: '', qauntity: 1, proposalPrice: '' }
                if (newForm._id) delete newForm._id
                newForm.isSelected = false
                proposalArt.proposalFormats.push({ ...newForm })
            })

            return proposalArt
        })
        setState({
            ...state,
            proposalArts: [...state.proposalArts, ...mapping]
        })
        newArts = newArts.concat(array)
        setSelectedArts([...newArts])
    }

    const AddSelectedFormatesToProposal = (pArt: ICuratorialProposalArtState, index: number) => {
        let data = state.proposalArts

        data[index] = pArt
        setState({
            ...state,
            proposalArts: data
        })
    }

    const selectArtImages = () => {
        artDetailRef.current.click()
    }

    // const onDeleteArts = () => {

    //     if (!selectedArts.length) return
    //     let array = (items as Array<ICollectionItem>).filter((i: ICollectionItem) => {
    //         return selectedArts.map((s: ICollectionItem) => s.id).indexOf(i.id) === -1 ? true : false
    //     })
    //     console.log(array)
    //     setEventState({
    //         ...eventState,
    //         items: array
    //     })
    //     setSelectedArts([])
    // }


    const onChangeArtImages = (e: any) => {
        if (e.target.files) {
            let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = proposalImages
            /* Get files in array form */
            const files = Array.from(e.target.files);
            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then((base64Strings: any) => {
                    setProposalImage({
                        ...proposalImages,
                        image: [...image, ...files],
                        preview: [...preview, ...base64Strings],
                        previewsCopy: [...previewsCopy, ...base64Strings],
                        uploadServiceImage: true
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onCropArtImages = (cropedImage: any) => {
        let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = proposalImages
        let currentIndex = (preview.length - 1) - (image.length - 1)
        const fileName = image[imageIndex - currentIndex].name
        const file = dataURLtoFile(cropedImage.cropped, fileName)
        const updatedFiles = image
        updatedFiles[imageIndex - currentIndex] = file
        const updatedPreviews = [...preview]
        updatedPreviews[imageIndex] = cropedImage.cropped
        setProposalImage({
            ...proposalImages,
            image: updatedFiles,
            preview: updatedPreviews,
            previewsCopy: updatedPreviews
        })
    }

    const onDeleteArtImage = () => {
        let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = proposalImages
        let updateImage = []
        if (image && image.length > 0) {
            let currentIndex = (preview.length - 1) - (image.length - 1)
            updateImage = image.filter((a: any, index: any) => index !== (imageIndex - currentIndex))
        }
        let updatePreview = preview?.filter((a: any, index: any) => index !== imageIndex)
        let newIndaex = imageIndex

        if (state && state._id && state.images && (state.images.length - 1) >= imageIndex) {
            let currentIndex = (preview.length - 1) - (state.images.length - 1)
            state.images = state.images.filter((i: any, index: number) => index !== (imageIndex - currentIndex))
        }


        if (newIndaex >= updatePreview.length && newIndaex !== 0) {
            newIndaex = newIndaex - 1
        }


        setProposalImage({
            ...proposalImages,
            image: updateImage,
            preview: updatePreview,
            previewsCopy: updatePreview,
            imageIndex: newIndaex
        })
    }

    const onChangeIndex = (selectedFile: number) => {
        setProposalImage({
            ...proposalImages,
            imageIndex: selectedFile
        })
    }

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    useEffect(() => {
        fetchCollections()
        fetchInvitation()

        if (params && (params as any).id) {
            fetchProposal()
        }
    }, [])

    // const selectedAllProposalArts = () => {
    //     if (state.proposalArts.length === 0) return
    //     if (selectedProposalArts.length === state.proposalArts.length) {
    //         setSelectedProposalArts([])
    //         return
    //     }
    //     setSelectedProposalArts([...state.proposalArts])
    // }

    const selectSingleProposalArts = (pArt: ICuratorialProposalArtState) => {
        let newArts = selectedProposalArts
        if (newArts.map((n: ICuratorialProposalArtState) => n.art.id).includes(pArt.art.id)) {
            newArts = newArts.filter((n: ICuratorialProposalArtState) => n.art.id !== pArt.art.id)
            setSelectedProposalArts([...newArts])
            return
        }

        newArts.push(pArt)
        setSelectedProposalArts([...newArts])
    }

    const checkSelectedProposalArts = (pArt: ICuratorialProposalArtState) => {
        let newArts = selectedProposalArts
        return newArts.map((n: ICuratorialProposalArtState) => n.art.id).includes(pArt.art.id)
    }

    const onDeleteSelectedProposalArts = (index: number) => {

        let pArt = state.proposalArts[index]

        let filterProposalArts = state.proposalArts.filter((pa: ICuratorialProposalArtState, ind: number) => ind !== index)

        setState({
            ...state,
            proposalArts: filterProposalArts
        })
        let arts = filterProposalArts.map((i: ICuratorialProposalArtState) => i.art)
        setSelectedArts([...arts])
        if (pArt._id) {

            let delArts = deletedProposalArts
            delArts.push(pArt)
            setDeletedProposalArts([...delArts])
        }
    }


    const onSave = () => {
        if (!valiateData()) return

        let data = new FormData()

        for (let i of proposalImages.image) {
            data.append('file', i)
        }

        data.append('description', state.description)
        let dupState = { ...state }
        let artsProp = [...dupState.proposalArts]
        artsProp = artsProp.map((p: ICuratorialProposalArtState) => {
            // p.art = p.art.id
            let newArt = { ...initCuratorialProposalArtState }
            newArt = { ...p }
            newArt.art = p.art.id
            return newArt
        })
        data.append('proposalArts', JSON.stringify(artsProp))
        if (state.id) data.append('id', state.id)
        if (deletedProposalArts.length > 0) {
            let newDeleted = deletedProposalArts.map((dA: ICuratorialProposalArtState) => dA._id)
            if (newDeleted && newDeleted.length > 0) data.append('deletedProposalArts', JSON.stringify(newDeleted))
        }
        if (state.images.length > 0) data.append('images', JSON.stringify(state.images))
        if (invitation) {
            data.append('curatorialUser', invitation.curatorialUser._id)
            data.append('curatorialService', invitation.curatorialService._id)
            data.append('curatorialInvitation', invitation._id)

        }

        data.append('user', user._id)

        setLoading(true)
        curatorialService.createCuratorialProposal(data).then((res: AxiosResponse<IResponse<ICuratorialProposalState>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            }
            setState({
                ...state,
                ...res.data.data
            })
            history.push(`${routes.account.services.curatorialServices.createProposal}/${res.data.data._id}/${(params as any).invitation}`)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })

    }

    const onSubmit = () => {
        if (!params || !((params as any).invitation)) return
        setLoading(true)
        curatorialService.sendCuratorialProposal((params as any).invitation).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const valiateData = () => {

        if (state.proposalArts.length === 0) {
            toastService.warning("Select at least one work of art")
            let obj = errors
            obj['paymentMethod'] = ['Payment method is required']
            setErrors({ ...obj })
            return false
        }

        setErrors({} as any)
        return true
    }


    return (
        <div className="at-section-wrap at-curatorial-wrap at-contentarea-two">
            <div className="at-themehead mb-5">
                <a className="at-left-arrowicon" onClick={() => history.goBack()}><i className="icon-left-arrow"></i></a>
                <h2>Create Proposal </h2>
            </div>
            <div className="row at-row-mlr-3">
                <div className="col-12">
                    <div className="form-group form-w-100 mb-0">
                        <div className="at-themefileupload text-center">
                            <input type="file" name="image" multiple ref={artDetailRef} accept="image/*,video/*" onChange={onChangeArtImages} id="at-service-image" />
                            <label onClick={selectArtImages}>
                                <span><i className="icon-gallery"></i>UPLOAD PROPOSAL PICTURES</span>
                            </label>
                            {errorElement('image')}
                        </div>
                    </div>

                    <div className="form-group form-w-100">
                        <SliderComponent items={proposalImages.preview} originalItems={proposalImages.previewsCopy} onCrop={onCropArtImages} currentIndex={proposalImages.imageIndex} onChangeIndex={onChangeIndex} />
                        {
                            proposalImages.preview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={onDeleteArtImage}></i>)
                        }

                    </div>
                </div>
            </div>
            <div className="row at-row-mlr-3">
                <div className="col-12">
                    <div className="form-group ">
                        <label className="text-left at-color-grey">Proposal Description</label>
                        {/* <textarea name="description" placeholder=" " value={state.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}
                        
                            <TmceEditor text={state.description}  onChange={(e: any) => setState({ ...state, description: e })} />
                        
                    </div>
                </div>
            </div>
            {/* row ends */}
            <div className="row at-row-mlr-3">
                <div className="col-10">
                    <h6>Art Selected for Proposal</h6>
                </div>
                {/* <div className="col-2">
                    <ul className="at-categaction justify-content-end">
                        <li>
                            <button className="at-btndelete" >
                                <i className="icon-delete2" onClick={() => onDeleteSelectedProposalArts()}></i>
                            </button>
                        </li>
                        <li>
                            <div className="at-checkbox at-checkbox-round at-top-0" >
                                <input type="checkbox" checked={state.proposalArts.length > 0 && selectedProposalArts.length === state.proposalArts.length} name="remember" id="abc" />
                                <label htmlFor="abc" onClick={() => selectedAllProposalArts()}></label>
                            </div>
                        </li>
                    </ul>
                </div> */}
            </div>


            {state.proposalArts && state.proposalArts.length > 0 && state.proposalArts.map((i: ICuratorialProposalArtState, index: number) => <CuratorialProposalArtsComponent
                proposalArt={i}
                key={index}
                index={index}
                AddSelectedFormatesToProposal={(pArt: ICuratorialProposalArtState) => AddSelectedFormatesToProposal(pArt, index)}
                selectSingleProposalArts={selectSingleProposalArts}
                checkSelectedProposalArts={checkSelectedProposalArts}
                onDeleteSelectedProposalArts={() => onDeleteSelectedProposalArts(index)}
            />)}
            <div className="at-clearfix"></div>
            <div className="row at-row-mlr-6 mt-3">
                <div className="col-6">
                    <div className="form-group form-w-100">
                        {/* <span className={`${(newCollection.description || '').length > 250 && 'text-danger'}`}>You have {250 - (newCollection.description || '').length} characters left</span> */}
                        <button type="button" disabled={loading} className="at-btn at-btn-bermudagrey at-longbtn-h40imp" onClick={() => onSave()}>{loading ? 'Wait...' : 'Save'}</button>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group form-w-100">
                        {/* <span className={`${(newCollection.description || '').length > 250 && 'text-danger'}`}>You have {250 - (newCollection.description || '').length} characters left</span> */}
                        <button type="button" disabled={loading || !state.id} className="at-btn at-longbtn-h40imp" onClick={() => onSubmit()}>{loading ? 'Wait...' : 'Submit'}</button>
                    </div>
                </div>
            </div>
            {/* row checkbox ends */}
            {
                !loading && <div className="at-collectiongallery pt-3">
                    <div>
                        {
                            <div className="at-manageposttitle">
                                <h2>{`Collections`}</h2>
                            </div>
                        }
                        {
                            loading && <LoadingComponent />
                        }
                        {
                            collections.map((c: ICollection) => <EventCollectionView key={c.id} c={c} user={user} selectedForEvent={selectedArts} onAddToEvents={onAddProposalArts} />)
                        }
                    </div>
                </div>
            }
        </div >
    )
}

export default CreateCuratorialProposal