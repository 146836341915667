import React, { FormEvent, useState } from 'react'
import { EArtistPrintType } from '../../../../../enums'
import { PLATFORM_FEE } from '../../../../../environment'
import { currencyFormatter } from '../../../../../helper'
import { ICollectionItem, ICuratorialProposalArtsDocument, ICuratorialProposalArtState, IFormates, IProposalArtFormats } from '../../../../../interfaces'
import { IArtistSelectedService, ISelectedService } from '../../../../../interfaces/services.interface'
import { toastService } from '../../../../../services'
import { collectionImage } from '../../../../../shared'
import { ShowArtServiceComponent } from '../../../../ArtCentral/ArtServices/ShowArtService.component'
import { CurrencyField } from '../../../../common/CurrencyInput'
import { UniversalModal } from '../../../../common/Modals/Universal.modal'

interface IProps {
    proposalArt: ICuratorialProposalArtState
    index: number
    AddSelectedFormatesToProposal: Function
    selectSingleProposalArts: Function
    checkSelectedProposalArts: Function
    onDeleteSelectedProposalArts: Function
}
export const CuratorialProposalArtsComponent = ({ proposalArt, AddSelectedFormatesToProposal, onDeleteSelectedProposalArts }: IProps) => {
    let art: ICollectionItem = proposalArt.art

    const [selectedFormat, setSelectedFormat] = useState<IFormates>()
    const [selectedFormatIndex, setSelectedFormatIndex] = useState<number>(NaN)
    const [showService, setShowService] = useState<boolean>(false)


    const onSelectFormats = (f: IProposalArtFormats, index: number) => {
        let data = { ...proposalArt }
        if (data.proposalFormats[index].size === 'original' && data.art.sold) {
            return
        }
        if (data.proposalFormats[index].selectedService && !data.proposalFormats[index].buyerSelectedService) {
            if ((data.proposalFormats[index].selectedService as IArtistSelectedService).printType === EArtistPrintType.FRAMED || (data.proposalFormats[index].selectedService as IArtistSelectedService).printType === EArtistPrintType.UNFRAMED) {
                toastService.warning('Please select frame option to include this format in the proposal.')
                return
            }
        }

        if (data.proposalFormats[index].isSelected && data.proposalFormats[index].isSelected === true) {
            data.proposalFormats[index].isSelected = false
        } else {

            data.proposalFormats[index].isSelected = true
        }
        AddSelectedFormatesToProposal(data)
    }


    const onOpenShowService = (format: IProposalArtFormats, fIndex: number) => {
        if (!format.selectedService) return
        setSelectedFormat(format)
        setSelectedFormatIndex(fIndex)
        setShowService(true)
        // this.setState({ formatSelected: format, showService: true })
    }

    const onCloseShowService = () => {
        setSelectedFormat(undefined)
        setSelectedFormatIndex(NaN)
        setShowService(false)
        // this.setState({ formatSelected: undefined, showService: false })
    }

    const onSubmitShowService = (service: ISelectedService) => {
        if (!selectedFormat) return
        if (isNaN(selectedFormatIndex)) return
        let formats = proposalArt?.proposalFormats
        let newItem = proposalArt
        if (newItem && formats && formats.length > 0) {
            let newFormats = formats.map((f: IProposalArtFormats, index: number) => {
                if (index === selectedFormatIndex) {
                    f.buyerSelectedService = service
                    f.price = Number(Number(f.price) + Number(service.totalCost)).toFixed(2)
                }
                return f
            })
            newItem.proposalFormats = newFormats
            proposalArt = newItem
            AddSelectedFormatesToProposal(proposalArt)
            // this.setState({ item: newItem })
        }
        onCloseShowService()
    }

    const artFormatesTotalCost = (f: IFormates) => {
        let total = Number(f.price) + Number(f.shipping) + Number(PLATFORM_FEE)
        let service = f.selectedService as IArtistSelectedService
        if (service && (service.printType === EArtistPrintType.UNFRAMED || service.printType === EArtistPrintType.FRAMED)) {
            console.log((f.selectedService as IArtistSelectedService)?.selectedServices[0]?.totalCost)
            total = total + Number(f.buyerSelectedService?.totalCost || 0) + Number(PLATFORM_FEE)
        }

        if (service && service.printType === EArtistPrintType.OWN && service.artistOwnService && service.artistOwnService.price) {
            total = total + Number(service.artistOwnService.price) + Number(service.artistOwnService.shipping)
        }
        return Number(total).toFixed(2)
    }

    const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        proposalArt.description = e.target.value
        AddSelectedFormatesToProposal(proposalArt)
    }

    const onChangePrice = (e: FormEvent<HTMLInputElement> | any, index: number) => {
        let data = { ...proposalArt }
        let offer = e.target.value
        if (offer) {
            offer = offer.split("$")[1].replace(/,/gi, '')
        }
        data.proposalFormats[index].price = offer

        AddSelectedFormatesToProposal(data)
    }


    const validateProposalField = (pF: IProposalArtFormats, index: number) => {
        let service = pF.selectedService as IArtistSelectedService
        if (service) {
            if (service.printType === EArtistPrintType.FRAMED && !pF.buyerSelectedService) {
                return <div className="">
                    <button className="at-btn at-btn-lightorange at-smallbtn-h40-w100" onClick={() => onOpenShowService(pF, index)}>Select Option</button>
                </div>
            }
            if (service.printType === EArtistPrintType.FRAMED && pF.buyerSelectedService) {
                return <CurrencyField
                    type="text"
                    name={`${pF.size}-${art.id}`}
                    id={`${pF.size}-${art.id}`}
                    className="form-control text-capitalize at-input-bgwhite at-inputgrey-center"
                    onChange={(e: FormEvent<HTMLInputElement> | any) => onChangePrice(e, index)}
                    placeholder={`${currencyFormatter.format(Number(pF.price) || 0)}`}
                    value={pF.price} />
            }
            if (service.printType === EArtistPrintType.UNFRAMED) {

                return <CurrencyField
                    type="text"
                    name={`${pF.size}-${art.id}`}
                    id={`${pF.size}-${art.id}`}
                    className="form-control text-capitalize at-input-bgwhite at-inputgrey-center"
                    onChange={(e: FormEvent<HTMLInputElement> | any) => onChangePrice(e, index)}
                    placeholder={`${currencyFormatter.format(Number(pF.price) || 0)}`}
                    value={pF.price} />
            }

            if (service.printType === EArtistPrintType.OWN && service.artistOwnService && service.artistOwnService.price) {
                return <CurrencyField
                    type="text"
                    name={`${pF.size}-${art.id}`}
                    id={`${pF.size}-${art.id}`}
                    className="form-control text-capitalize at-input-bgwhite at-inputgrey-center"
                    onChange={(e: FormEvent<HTMLInputElement> | any) => onChangePrice(e, index)}
                    placeholder={`${currencyFormatter.format(Number(pF.price) || 0)}`}
                    value={pF.price} />
            }
        } else {
            return <CurrencyField
                type="text"
                name={`${pF.size}-${art.id}`}
                id={`${pF.size}-${art.id}`}
                className="form-control text-capitalize at-input-bgwhite at-inputgrey-center"
                onChange={(e: FormEvent<HTMLInputElement> | any) => onChangePrice(e, index)}
                placeholder={`${currencyFormatter.format(Number(pF.price) || 0)}`}
                value={pF.price} />
        }
    }

    return (
        <>
            <div className="at-greyborderbox mb-2">
                <div className="row at-row-mlr-3 at-curatorial-format align-items-center">
                    {/* <div className="col-2">
                    <div className="at-checkbox at-checkbox-round at-top-0" >
                        <input type="checkbox" checked={checkSelectedProposalArts(proposalArt)} name="remember" />
                        <label htmlFor="abc" onClick={() => selectSingleProposalArts(proposalArt)}></label>
                    </div>
                </div> */}
                    <div className="col-10">
                        <h5>{art.name}</h5>
                    </div>
                    <div className="col-2">
                        <ul className="at-categaction justify-content-end">
                            <li>
                                <button className="at-btndelete" >
                                    <i className="icon-delete2" onClick={() => onDeleteSelectedProposalArts()}></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <div className="at-figurelandscape">
                            {art.url && <figure className="at-figure-h170">
                                <img src={collectionImage({} as any, art.url, true)} className="img-fluid" alt="" />
                            </figure>}
                        </div>
                    </div>

                </div>
                {/* row ends */}
                <div className="row at-row-mlr-3 mt-4">
                    <div className="col-12">
                        <div className="at-formgroup mb-4 form-group at-floatlabel">
                            <textarea name="description" placeholder=" " value={proposalArt.description} className="form-control" onChange={onChangeText} ></textarea>
                            <label>Additional Information</label>
                        </div>
                    </div>
                </div>
                {/* row ends */}

                <div className="row at-row-mlr-3">
                    <div className="col-7">
                        <h5 className="at-semibold">Select Proposal Formats</h5>
                    </div>
                    <div className="col-5 at-textcenter">
                        <h5 className="at-semibold">Set Pricing</h5>
                    </div>
                </div>
                {/* row ends */}

                {/* {!art.sold && <div className="row at-rowprinting at-row-mlr-3" >
                <div className="col-7 at-colprinting-label align-items-center">
                    <div className="at-checkbox at-checkbox-round at-top-0">
                        <input type="checkbox" checked={proposalArt.showOriginal} name="remember" id="abc" onClick={onShowOriginal}/>
                        <label htmlFor="abc" ></label>
                    </div>
                    <div>
                        <h6>Original</h6>
                    </div>
                </div>

                <div className="col-5">
                    <div className="at-formgroup at-formgroup-h40 form-group mb-0">
                        <input type="text" name="" className="form-control at-textcenter" autoComplete="off" placeholder="$3,000.00" value="$3,000.00" />
                    </div>
                </div>

            </div>} */}
                {/* row checkbox ends */}

                {/* {art.sold && <div className="row at-rowprinting at-row-mlr-3">
                <div className="col-7 at-colprinting-label align-items-center">
                    <div className="at-checkbox at-checkbox-round at-top-0" >
                        <input type="checkbox" checked={proposalArt.showCommission} name="remember" id="abc" />
                        <label htmlFor="abc" ></label>
                    </div>
                    <div>
                        <h6>Commission Work Inspired by Original</h6>
                    </div>
                </div>

                <div className="col-5">
                    <div className="at-formgroup at-formgroup-h40 form-group mb-0">
                        <input type="text" name="" className="form-control at-textcenter" autoComplete="off" placeholder="$3,000.00" value="$3,000.00" />
                    </div>
                </div>

            </div>} */}
                {/* row checkbox ends */}

                {proposalArt.proposalFormats && proposalArt.proposalFormats.length > 0 && proposalArt.proposalFormats.map((f: IProposalArtFormats, index: number) => {
                    return (

                        <div className="row at-rowprinting at-row-mlr-3" key={index}>
                            <div className="col-7 at-colprinting-label align-items-center">
                                <div className="at-checkbox at-checkbox-round at-top-0" >
                                    <input type="checkbox" checked={f.isSelected} name="remember" id={`${f.size}-${art.id}`} />
                                    <label htmlFor="abc" onClick={() => onSelectFormats(f, index)}></label>
                                </div>
                                <div>
                                    <h6>{f.size}</h6>
                                    {f.printType === EArtistPrintType.FRAMED && <p className="at-paralabelgrey">Framed <a className="at-btn-linkorange" onClick={() => onOpenShowService(f, index)}>See Details</a></p>}
                                    {f.printType === EArtistPrintType.UNFRAMED && <p className="at-paralabelgrey">Unframed <a className="at-btn-linkorange" onClick={() => onOpenShowService(f, index)}>See Details</a></p>}
                                </div>

                            </div>

                            <div className="col-5">
                                <div className="at-formgroup at-formgroup-h40 form-group mb-0">
                                    {f.size === 'original' && (art.sold || art.reserved) ?
                                        <>

                                            {art.sold && <button className="at-btn at-btn-lightorange at-smallbtn-h40-w100 text-center">Sold</button>}
                                            {art.reserved && <button className="at-btn at-btn-lightorange at-smallbtn-h40-w100">Reserved</button>}

                                            {/* {art.sold && <h6 className="">SOLD</h6>}
                                        {art.reserved && <h6 className="">Reserved</h6>} */}
                                        </>
                                        :
                                        (f.size === 'commission work' || f.size === 'original') ?
                                            <CurrencyField
                                                type="text"
                                                name={`${f.size}-${art.id}`}
                                                id={`${f.size}-${art.id}`}
                                                className="form-control text-capitalize at-input-bgwhite at-inputgrey-center"
                                                onChange={(e: FormEvent<HTMLInputElement> | any) => onChangePrice(e, index)}
                                                placeholder={`${currencyFormatter.format(Number(f.price) || 0)}`}
                                                value={f.price} />

                                            :

                                            validateProposalField(f, index)

                                    }

                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>
            {/* at-greyborderbox ends */}

            {/* row checkbox ends */}
            {/* <div className="row at-rowprinting at-row-mlr-3">
                <div className="col-7 at-colprinting-label align-items-center">
                    <div className="at-checkbox at-checkbox-round at-top-0" >
                        <input type="checkbox" checked={true} name="remember" id="abc" />
                        <label htmlFor="abc" ></label>
                    </div>
                    <div>
                        <h6>Large Print 24x36</h6>
                        <p className="at-paralabelgrey">Unframed <a className="at-btn-linkorange">See Details</a></p>
                    </div>
                </div>

                <div className="col-5">
                    <button className="at-btn at-btn-lightorange at-smallbtn-h40-w100">Select Option</button>
                </div>
            </div> */}
            {showService && selectedFormat && <UniversalModal open={showService} onClose={onCloseShowService} >
                <ShowArtServiceComponent format={selectedFormat} onSubmitShowService={onSubmitShowService} />
            </UniversalModal>}
        </>
    )
}