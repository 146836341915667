import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { StepWizardChildProps } from 'react-step-wizard'
import { ICollectionItem } from '../../../../../interfaces'
import { IAuctionState } from '../../../../../interfaces/auction.interface'
import { IResponse } from '../../../../../interfaces/db.interface'
import { routes } from '../../../../../router'
import { collectionService } from '../../../../../services/collection.service'
import { collectionImage } from '../../../../../shared'


interface IProps extends StepWizardChildProps {
    eventState: IAuctionState
    onChangeText: Function
    errors: any
    selectedForEvent: Array<ICollectionItem>
    onAddToEvents: Function
    onSelectEventArts: Function
    isSelectedForEvents: Function
    isAllEventsArtSelected: Function
    onMoveUp: Function
    onMoveDown: Function
    onDeleteArts: Function
    onSelectAllEventArts: Function
    submitLoading: boolean
}

export const AuctionformStepThree = ({ eventState, errors, onChangeText, nextStep, previousStep, selectedForEvent, onAddToEvents, onSelectEventArts, isSelectedForEvents, isAllEventsArtSelected, onMoveUp, onMoveDown, submitLoading, onDeleteArts, onSelectAllEventArts }: IProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [arts, setArts] = useState<Array<ICollectionItem>>([])
    const [selectedArts, setSelectedArts] = useState<Array<ICollectionItem>>([])
    const [filtered, setFiltered] = useState<boolean>(false)
    const [sortBy, setSortBy] = useState<string>('')
    const history = useHistory()

    const fetchArts = useCallback((s: string, filter: boolean = false) => {
        setLoading(true)
        collectionService.auctionArts({ sort: s, filtered: filter }).then((res: AxiosResponse<IResponse<Array<ICollectionItem>>>) => {
            setArts([...res.data.data])
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }, [])

    useEffect(() => {
        fetchArts("")
    }, [fetchArts])

    const onSelectArts = (item: ICollectionItem) => {
        if (selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1) {
            let newItems = selectedArts.filter((i: ICollectionItem) => i.id !== item.id)
            setSelectedArts([...newItems])
            return
        }
        let newArts = selectedArts
        newArts.push(item)
        setSelectedArts([...newArts])
    }

    const isSelected = (item: ICollectionItem) => {
        return selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1
    }

    const onSelectAll = () => {
        let count = arts.filter((i: ICollectionItem) => isAddedToEvents(i))
        if (selectedArts.length === (arts.length - count.length)) {
            setSelectedArts([])
            return
        }
        let array = arts.filter((i: ICollectionItem) => !isAddedToEvents(i))
        setSelectedArts([...array])
    }

    const isAllSelected = () => {
        if (selectedArts.length === arts.length) return true

        return false
    }

    const onAddedToEvents = () => {
        console.log("run")
        onAddToEvents(selectedArts)
        setSelectedArts([])
    }

    const isAddedToEvents = (item: ICollectionItem) => {
        if (!selectedForEvent) return false
        return selectedForEvent.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1
    }

    const onToggleFilter = (value: boolean) => {
        if (value) {
            setFiltered(true)
        } else {
            setFiltered(false)
        }
        fetchArts(sortBy, value)
    }

    const changeSort = (e: any) => {
        setSortBy(e.target.value)
        fetchArts(e.target.value, filtered)
    }

    const onClickArt = (a: ICollectionItem) => {
        // history.push(`${routes.artCentral.collection.item}/${a.id}`)
    }
    
    return (
        <>
            <div className="at-orangeheading">
                <h5>Add Art to Auction</h5>
                {/* <h6>(skip Step 1 if adding art to existing collection)</h6> */}
            </div>
            <div className="at-greyborderbox pt-4">
                <div className="at-titlewithicons mb-3">
                    <h2 className="at-title">Art Selected for Aution Event</h2>
                    <div className="at-btndelete at-titleicons">
                        <i className="icon-delete2 fs-20 mr-2 cursor-pointer " title="delete" onClick={() => onDeleteArts()}></i>
                        <div className="at-checkbox" title='Select Collection'>
                            <input type="checkbox" name="remember" checked={isAllEventsArtSelected()} />
                            <label htmlFor="artcollectionselectall" className="at-label-bdradius50" onClick={() => onSelectAllEventArts()}></label>
                        </div>
                    </div>
                </div>


                <div className="at-collectiongallerybox at-artgallerybox px-0 pb-2">
                    <div className="at-gallerytitle">
                        <ul className="at-gallery">
                            {
                                eventState && eventState.arts?.length > 0 && eventState.arts.map((item: ICollectionItem, index: any) => (
                                    <li key={item.id}>
                                        <div>
                                            <figure>
                                                <div className="at-artpercent">
                                                    {item.auctionCommission && <h5>{item.auctionCommission}%</h5>}
                                                </div>
                                                <div className="at-artprice">
                                                    <h6>{item.auctionMinPrice ? `$${item.auctionMinPrice}` : ''}; {item.height} X {item.width}</h6>
                                                    {item.art_collection?.user && <h6>{item.art_collection?.user.firstName} {item.art_collection?.user.lastName} </h6>}
                                                </div>
                                                <img src={collectionImage(item, `high_${item.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                                                <div className="At-figurebg"></div>
                                                <div className="at-artgallerycollectionbox atv2-event-positionabs">
                                                    <span className="at-checkbox" title="Select">
                                                        <input type="checkbox" name="select" id="artcollectionselectall" checked={isSelectedForEvents(item)} />
                                                        <label htmlFor="artcollectionone" onClick={() => onSelectEventArts(item)}></label>
                                                    </span>
                                                </div>
                                                <div className="atv2-event-arrows">
                                                    <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowup" title="Sequence Arts" onClick={() => onMoveUp(index)} ></i>
                                                    <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowdw" title="Sequence Arts" onClick={() => onMoveDown(index)} ></i>
                                                </div>

                                            </figure>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => previousStep()}
                        className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                    >
                        BACK
                    </button>
                </div>
                <div className="form-group form-w-50">
                    <button
                        type="submit"
                        className="at-btn at-medbtn-h40-mauto"
                        disabled={submitLoading}
                    >
                        {submitLoading ? "WAIT..." : "UPDATE"}
                    </button>
                </div>
            </div>

            <div className="at-collectiongallerybox at-artgallerybox px-0 mt-4" >
                <div className="at-titlewithicons">
                    <h2 className="at-title">Select Art for Auction Event</h2>
                    <div className="at-btndelete at-titleicons">
                        <i className={`icon-filter fs-20 mr-2 cursor-pointer ${filtered && 'text-orange'}`} title="filter" onClick={() => onToggleFilter(!filtered)}></i>
                    </div>
                </div>
                <div className="at-titlewithselect">
                    <h5 className="at-title">Sequence ART by:</h5>
                    <div className="form-group ">
                        <span className="at-select at-select-h40">
                            <select name="sortBy" value={sortBy} onChange={changeSort} required>
                                <option value="" ></option>
                                <option value="auctionCommission">Percent (%) commission paid</option>
                                <option value="auctionMinPrice"> Minimum Art Price accepted</option>
                                <option value="artistName">Artist Name</option>
                                <option value="artSize">Art Size</option>
                            </select>
                            {/* <label className="at-lineheight-27">Percent (%) commission paid</label> */}
                        </span>
                    </div>
                </div>
                <div className="at-gallerytitle">
                    <span>
                        <div className="at-btndelete d-flex mb-2">
                            <i className="icon-add-circle fs-20 mr-2 cursor-pointer right" title="Add Arts" onClick={() => onAddedToEvents()}></i>
                            <div className="at-checkbox" title='Select Collection'>
                                <input type="checkbox" name="remember" checked={isAllSelected()} />
                                <label htmlFor="artcollectionselectall" className="at-label-bdradius50" onClick={() => onSelectAll()}></label>
                            </div>
                        </div>
                    </span>

                    <ul className="at-gallery">
                        {arts && arts.length > 0 && arts.map((a: ICollectionItem) => {
                            let user = a.art_collection?.user
                            return (
                                <li key={a.id} className="position-relative">
                                    <div>
                                        {/* <Link to={`${isAgent ? routes.agent.showAssigment : routes.artCentral.collection.item}/${item[isAgent ? 'assignment' : 'id']}`}> */}
                                        <figure onClick={() => onClickArt(a)}>
                                            <div className="at-artpercent">
                                                {a.auctionCommission && <h5>{a.auctionCommission}%</h5>}
                                            </div>
                                            <div className="at-artprice">
                                                <h6>{a.auctionMinPrice ? `$${a.auctionMinPrice}` : ''}; {a.height} X {a.width}</h6>
                                                {user && <h6>{user.firstName} {user.lastName} </h6>}
                                            </div>
                                            <img src={collectionImage(a, `high_${a.url}`, true)} alt={''} style={{ backgroundImage: `url(${collectionImage(a, a.url || '', true)})` }} />
                                            <div className="At-figurebg"></div>
                                        </figure>
                                        {/* </Link> */}
                                        <div className="at-artgallerycollectionbox atv2-event-positionabs" title="select">
                                            <span className="at-checkbox">
                                                {!isAddedToEvents(a) && <><input type="checkbox" name="select" checked={isSelected(a)} id="artcollectionselectall" />
                                                    <label htmlFor="artcollectionone" onClick={() => onSelectArts(a)}></label> </>}
                                            </span>

                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}