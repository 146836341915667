import React from 'react'
import moment from 'moment'
import { INotification, ITransaction } from '../../interfaces'
import { collectionImage } from '../../shared'
import { CurrencyFormat } from '../common/CurrencyFormat'
import { ERole } from '../../enums'

interface IProps {
    notification: INotification
    getItemType: Function
}

export const PaymentLayout = ({ notification, getItemType }: IProps) => {
    let transaction = (notification.transaction as ITransaction)
    let artist = transaction && transaction.artistID && transaction.artistID._id ? transaction.artistID : transaction.sellerID
    let isAgent = artist && artist.roles.includes(ERole.AGENT)
    let artistName = artist && isAgent ? transaction.itemID?.artistName || `${artist?.firstName} ${artist?.lastName}` : `${artist?.firstName} ${artist?.lastName}`
    return (
        <div className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-transact-left">
                    <div className="at-transact-line">
                        <p>{moment(notification.createdAt).format("L")}</p>
                        <p className="pl-5">{moment(notification.createdAt).format("hh:mm:ss a")}</p>
                    </div>
                    <div className="at-transact-line">
                        <p>Seller:</p>
                        <p className="pl-2 text-capitalize"><span>{notification.to.firstName} {notification.to.lastName}</span></p>
                    </div>
                    <div className="at-transact-line">
                        <p>Artist:</p>
                        <p className="pl-2 text-capitalize"><span>{artistName}</span></p>
                    </div>
                    <div className="at-transact-line" >
                        <p>Buyer:</p>
                        <p className="pl-3 text-capitalize"><span>{`${notification.from.firstName} ${notification.from.lastName}`}</span></p>
                        {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                    </div>

                </div>
                {notification.itemId && <div className="at-transact-right">
                    {notification.itemId && notification.itemId.name && <p className=""><span>{notification.itemId.name}</span></p>}
                    <figure>
                        <img src={collectionImage(notification.itemId, `high_${notification.itemId.url}`, true)} alt="" />
                    </figure>
                </div>}
                <div className="at-transactbox-bottom">
                    {transaction && <div className="at-transact-line pt-2">
                        <p>Art: <span>{transaction.itemName ? transaction.itemName : getItemType(transaction)}</span></p>
                    </div>}
                    {transaction && <div className="at-transact-line pt-2">
                        <p>Price: <span><CurrencyFormat amount={transaction.price} /></span></p>
                        <p className="pl-2">Shipping: <span><CurrencyFormat amount={transaction.shippingPrice} /></span></p>
                        <p className="pl-2">Total: <span><CurrencyFormat amount={transaction.totalPrice} /></span></p>
                    </div>}
                </div>
            </div>
        </div>
    )
}