import React from 'react'
import { IOffer } from '../../../interfaces'
import moment from 'moment'
import { CurrencyFormat } from '../../common/CurrencyFormat'
import { images } from '../../../assets/image'
import { collectionImage } from '../../../shared/functions'
import { ErrorComponent } from '../../common/Error.component'
import LoadingComponent from '../../common/Loading.component'
import Select from 'react-select'
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component'
import { ERole } from '../../../enums'

interface IProps {
    offers: Array<IOffer>
    getOfferStatus: Function,
    loading?: boolean,
    onAccept: (item: IOffer) => any
    showButton: boolean
    onReject: (item: IOffer) => any
    confirmApprove?: boolean
    confirmReject?: boolean
    onConfirmApprove: Function
    onConfirmReject: Function
    onConfirmApproveClose: Function
    onConfirmRejectClose: Function
}

export const OfferView = ({ offers, getOfferStatus, onAccept, showButton, loading, onReject,
    confirmApprove, onConfirmApprove, onConfirmReject, confirmReject, onConfirmApproveClose, onConfirmRejectClose }: IProps) => {
    return (
        <>
            { offers?.length > 0 ?
                (<div className="at-transaction-main">
                    {offers?.map((item: IOffer) => {
                        let isAgent = item && item?.offeredTo && item?.offeredTo?.roles?.includes(ERole.AGENT)
                        let artistName = isAgent ? `${item.itemId?.artistName}` || `${item?.offeredTo?.firstName} ${item?.offeredTo?.lastName}` : `${item?.offeredTo?.firstName} ${item?.offeredTo?.lastName}`
                      return  (
                            <>
                                {showButton && <div className='at-btniconsbox'>
                                    <button title="Accept" disabled={!item.pending} className={`at-btn-green at-btnaccept at-btnthumbs ${item.pending ? '' : 'at-offer-disabled'}`} onClick={() => onAccept(item)}> <i className="icon-like"></i> </button>
                                    <button title="Reject" disabled={!item.pending} className={`at-btnreject at-btnthumbs at-btn-orange ${item.pending ? '' : 'at-offer-disabled'}`} onClick={() => onReject(item)}> <i className="icon-dislike"></i></button>
                                </div>}
                                <div key={item._id} className="at-transaction-wrap">

                                    <div className="at-transaction-box">
                                        <div className="at-transact-left">
                                            <div className="at-transact-line">
                                                <p>{moment(item.createdAt).format("L")}</p>
                                                <p className="pl-5">{moment(item.createdAt).format("hh:mm:ss a")}</p>
                                            </div>
                                            <div className="at-transact-line">
                                                <p>Seller:</p>
                                                <p className="pl-2 text-capitalize"><span>{item.agentId ? `${item.agentId.firstName} ${item.agentId.lastName}` : `${item.offeredTo.firstName} ${item.offeredTo.lastName}`}</span></p>
                                            </div>
                                            <div className="at-transact-line">
                                                <p>Artist:</p>
                                                <p className="pl-2 text-capitalize"><span>{artistName}</span></p>
                                            </div>
                                            <div className="at-transact-line">
                                                <p>Buyer:</p>
                                                <p className="pl-3 text-capitalize"><span>{`${item.offeredBy.firstName} ${item.offeredBy.lastName}`}</span></p>
                                                {/* <p className="pl-3"><span onClick={() => setShowBuyerDetails(!showBuyerDetails)}>Mary Adams</span></p> */}
                                            </div>
                                            <div className="at-transact-line pt-2">
                                                <p>Offer:<span><CurrencyFormat amount={item.offeredPrice} /></span></p>
                                                {item.itemId && item.itemId.shipping && <p className="pl-2">Shipping: <span><CurrencyFormat amount={item.itemId.shipping} /></span></p>}
                                            </div>
                                        </div>
                                        <div className="at-transact-right">
                                            {item.itemId && item.itemId.name && <p className=""><span>{item.itemId.name}</span></p>}
                                            <figure>
                                                {item.itemId && item.itemId.url && <img src={collectionImage(item.itemId, `high_${item.itemId.url}`, true)} alt="" />}
                                            </figure>
                                            <button className={`at-btn at-btn-sm at-btn-${getOfferStatus(item).color}`}>{getOfferStatus(item).status}</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}

                </div>) : <>{loading && <LoadingComponent className='at-transaction-loader' />}</>
            }
            {confirmApprove && <ConfirmAlertComponent
                open={confirmApprove}
                title='Confirm'
                message='Are you sure you want to accept this offer?'
                onConfirm={onConfirmApprove}
                onCancel={onConfirmApproveClose}
                onClose={onConfirmApproveClose}
            />
            }

            {confirmReject && <ConfirmAlertComponent
                open={confirmReject}
                title='Confirm'
                message='Are you sure you want to reject this offer?'
                onConfirm={onConfirmReject}
                onCancel={onConfirmRejectClose}
                onClose={onConfirmRejectClose}
            />
            }
        </>
    )
}