import React, { Component, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import SellerTransaction from '../../components/User/Transactions/SellerTransactions'
import BuyerTransaction from '../../components/User/Transactions/BuyerTransaction'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { _logger } from '../../helper';
import { IUser } from '../../interfaces';
import { TutorialIcon } from '../../components/Tutorials/TutorialIcon';
import { EScreens } from '../../enums/screens.enum';
import { UniversalModal } from '../../components/common/Modals/Universal.modal';
import { ScreenTutorial } from '../../components/Tutorials/ScreenTutorial';

interface IProps {
    routeUser?: IUser
    hideBackButton?: boolean
}

const TransactionsPage = ({ routeUser, hideBackButton }: IProps) => {
    const history = useHistory()

    const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false)

    const openTutorialBox = () => {
        setShowTutorialBox(true)
    }

    const closeTutorialBox = () => {
        setShowTutorialBox(false)
    }

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    {!hideBackButton && <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>}
                    <h2>Transactions <span className="at-tutorialplaybtn"> <TutorialIcon screen={EScreens.TRANSACTIONS} onClick={openTutorialBox} /></span></h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    <Tabs onSelect={(index: any, label: any) => _logger('Selected Tab', label + ' selected')}>
                        <Tab label="Seller Transactions">
                            <SellerTransaction routeUser={routeUser} />
                        </Tab>
                        <Tab label="Buyer Transactions">
                            <BuyerTransaction routeUser={routeUser} />
                        </Tab>
                    </Tabs>
                </div>
                {showTutorialBox && <UniversalModal open={showTutorialBox} onClose={closeTutorialBox} position='bottom-0'>
                    <ScreenTutorial screen={EScreens.TRANSACTIONS} />
                    {/* at-categorybox ends */}
                </UniversalModal>
                }
            </div>
        </main>
    )

}

export default TransactionsPage
