import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ADD_NEW_MEMBER, USER_ALERT_SPACE_ENTER } from '../../constants'
import { ISpace } from '../../interfaces'
import { spaceService, toastService, userService } from '../../services'
import { socketNode } from '../../services/socket.service'
import { EZoom, ZoomActions } from '../../services/zoom.service'
import { activateSpaceAction, changeActiveSpace, fetchUserStudio, setInPlatformWebState } from '../../store/actions'
import { authSelector, inPlatformSelector, isSpaceActivatedSelector, templateSelector, userSelector } from '../../store/selectors'
import TemplateAuthComponent from './TemplateAuth/TemplateAuthComponent'

interface IProps extends RouteComponentProps {
    children: any
}
const PrivateRoutes = (props: IProps) => {
    const auth = useSelector(authSelector).access_token
    // const history = useHistory()
    const dispatch = useDispatch()
    const template = useSelector(templateSelector)
    const inPlatform = useSelector(inPlatformSelector)
    const user = useSelector(userSelector)
    const isSpaceActivated = useSelector(isSpaceActivatedSelector)
    const [showAuth, setShowAuth] = useState<boolean>(false)
    let isIframe = window.self !== window.top
    useEffect(() => {
        if (!auth && inPlatform.activeSplitScreen) {
            openAuth()
        }
        if (auth && inPlatform.activeSplitScreen && !isIframe) {
            onSigninSucees()
        }
        if (!inPlatform.activeSplitScreen) {
            dispatch(setInPlatformWebState({
                nextPath: ``,
                activeSplitScreen: true
            }))
        }
    }, [auth, inPlatform])

    // useEffect(() => {
    //     return () => {
    //         dispatch(setInPlatformWebState({
    //             activeSplitScreen: false
    //         }))
    //     }
    // }, [])

    const openAuth = () => {
        setShowAuth(true)
    }

    const closeAuth = () => {
        setShowAuth(false)
    }

    const onSigninSucees = async () => {
        if (isSpaceActivated) return
        const space: ISpace = (await spaceService.userArtCentralSpace(template.user._id)).data;
        if (user.activeSpace?._id === space._id) {
            return
        }
        changeActiveSpace && dispatch(changeActiveSpace(space))
        toastService.info(`You are now joining the conversation at ${template?.user?.firstName} ${template?.user?.lastName}’s studio`)
        socketNode.emit(ADD_NEW_MEMBER, { channel: space._id, user })
        dispatch(activateSpaceAction(true))
        dispatch(fetchUserStudio(template.user._id))
        sendUserAlert(`${user?.firstName} ${user?.lastName} has just entered your space.`, USER_ALERT_SPACE_ENTER, false, space._id)
        closeAuth()
    }

    const sendUserAlert = (message: string, type: string, showToast: boolean = false, spaceId: any = null) => {

        if (user?._id === template?.user._id) {
            return
        }
        let data = {
            message: message,
            to: template?.user._id,
            from: user?._id,
            type: type,
            spaceID: spaceId
        }
        userService.sendUserAlert({ ...data }).then((res: AxiosResponse<any>) => {
            if (showToast && res.data.status === 'Success') {
                toastService.success("Message sent successfully")
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    return showAuth ?
        <TemplateAuthComponent closeAuth={closeAuth} />
        : <>{props.children}</>

}

export default withRouter(PrivateRoutes)

