import React, { useEffect, useState } from 'react'
import RTE from 'react-rte'

interface IProps {
    text: string,
    onChange: Function,
    hideToolBar?: boolean
    recieve?: boolean
}
export const CustomEditior = ({ text, onChange, hideToolBar, recieve }: IProps) => {
    const [editortext, setEditorText] = useState<any>(RTE.createEmptyValue())

    useEffect(() => {
        setEditorText(RTE.createValueFromString(text, 'html'))
    }, [recieve])

    const toolbarConfig: any = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: hideToolBar ? [''] : ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };

    const onChangeEditorText = (e: any) => {
        setEditorText(e)

        onChange(e.toString('html'))
    }

    return <RTE className={hideToolBar ? 'at-editor-setting' : ''} value={editortext} toolbarConfig={toolbarConfig} onChange={onChangeEditorText} />

}
