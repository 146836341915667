import { HttpService } from './base.service'
import { data } from 'jquery';

class PaymentService extends HttpService {
    private readonly prefix: string = 'payment';

    /**
     *  Make payment
     */
    makePayment = (data:any): Promise<any> => this.post(`${this.prefix}/paypal`, data);

    /**
     *  sales tax api
     */
    taxPayment = (zipCode: any): Promise<any> => this.post(`${this.prefix}/taxCalculation/`, zipCode);

    checkout = (data: any): Promise<any> => this.post(`${this.prefix}/checkout/`, data);


   /**
     *  Release fund
    */

   releasePayment = (data: any): Promise<any> => this.post(`${this.prefix}/payout/release/`, data)
   releaseCommissionPayment = (data: any): Promise<any> => this.post(`${this.prefix}/commission_payout/release`, data)

   /**
     *  Refund Payment
    */

   RefundPayment = (data: any): Promise<any> => this.post(`${this.prefix}/payout/refund/`, data)

   allowReturn = (data: any): Promise<any> => this.post(`${this.prefix}/allowReturn/`, data)
   
   /**
    *  Connect user Account with stripe
    */

   connectWithStripe = (data: any): Promise<any> => this.post(`${this.prefix}/paywithstripe`, data)

   /**
    *  get user stripe Dashboard
    */

   getStripeAccount = (id: any): Promise<any> => this.get(`${this.prefix}/getAccount/${id}`)

   /**
    *  Delete user Omni stripe account
    */

   deleteStripeAccount = (data: any): Promise<any> => this.post(`${this.prefix}/deleteAccount`, data)

   getStripeWebhook = (): Promise<any> => this.get(`${this.prefix}/stripe/webhook`)

   milestoneCheckout = (data: any): Promise<any> => this.post(`${this.prefix}/checkout/milestone`, data);
}

export const paymentService = new PaymentService();