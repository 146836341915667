import React, { FormEvent, useEffect, useState } from 'react'
import { IArtistOwnService, IArtistServicesState } from '../../../../../interfaces/services.interface'
import { CurrencyField } from '../../../../common/CurrencyInput'
import { ErrorComponent } from '../../../../common/Error.component'

interface IProps {
    loading: boolean
    onClose: Function
    onUpdateData: Function
    parentState: IArtistServicesState
    onlyView?: boolean
}

export const initState = {
    height: NaN,
    width: NaN,
    price: '',
    information: '',
    shipping: '',
}
export const ArtistOwnPrinter = ({ loading, onClose, onUpdateData, parentState, onlyView }: IProps) => {
    const [state, setState] = useState<IArtistOwnService>({ ...initState })
    const [errors, setErrors] = useState<any>({})

    const onChangeName = (e: FormEvent<HTMLInputElement> | any) => {
        let { name, value } = e.target

        setState({
            ...state,
            [name]: value
        })
    }

    const onChangePrice = (e: any,) => {
        let price = e.target.value < 0 ? 0 : e.target.value;
        if (price) {
            price = price.split("$")[1].replace(/,/gi, '')
        }
        setState({
            ...state,
            [e.target.name]: price
        })
    }

    const onAddData = () => {
        if (!state.price && !state.shipping) return
        if (!onValidate()) return
        onUpdateData(undefined, state)
    }

    const onValidate = () => {

        let newErr: any = {}

        if (!state.price) {
            newErr['price'] = ['Price is required']
            setErrors({ ...newErr })
            return false
        }
        if (!state.shipping) {
            newErr['shipping'] = ['Shipping is required']
            setErrors({ ...newErr })
            return false
        }
        if (isNaN(Number(state.width)) || Number(state.width) === 0) {
            newErr['width'] = ['Width is required']
            setErrors({ ...newErr })
            return false
        }
        if (isNaN(Number(state.height)) || Number(state.height) === 0) {
            newErr['height'] = ['Height is required']
            setErrors({ ...newErr })
            return false
        }
        setErrors({})
        return true

    }

    useEffect(() => {
        if (parentState.artistOwnService && parentState.artistOwnService.price) {
            setState({
                ...state,
                ...parentState.artistOwnService
            })
        }
    }, [parentState])

    return (
        <div>
            <h5 className="at-popupheading pb-3">Print Details</h5>
            <div className="row row-margin-lr7 align-items-center">
                <div className="col-12">
                    <div className="form-group at-floatlabel form-w-100 ">
                        <textarea name="information" disabled={onlyView} className="form-control at-textarea-radius10" value={state.information} placeholder=" " onChange={onChangeName} />
                        <label>Additional Information</label>
                    </div>
                </div>

                {!onlyView && <div className="col-12 pad-lr-7">
                    <div className="form-group at-floatlabel-h40">
                        <CurrencyField
                            id="input-example-format-shipping"
                            className="form-control "
                            name="price"
                            placeholder=" "
                            prefix="$"
                            value={state.price}
                            disabled={onlyView}
                            onChange={(e: any) => onChangePrice(e)} />
                        <label>Printing Costs</label>
                        {errors && errors['price'] && <ErrorComponent errors={errors['role']} multiple={false} />}
                    </div>
                </div>}
                {!onlyView && <div className="col-12 pad-lr-7">
                    <div className="form-group at-floatlabel-h40">
                        <CurrencyField
                            id="input-example-format-shipping"
                            className="form-control"
                            name="shipping"
                            placeholder=" "
                            prefix="$"
                            value={state.shipping}
                            disabled={onlyView}
                            onChange={(e: any) => onChangePrice(e)} />
                        <label>Additional Shipping Costs</label>
                        {errors && errors['shipping'] && <ErrorComponent errors={errors['shipping']} multiple={false} />}
                    </div>
                </div>}
                <div className="col-md-12">
                    <div className="form-group at-form-group-mb15">
                        <p className="at-notered">NOTE: Printing, framing and additional shipping costs will be added to your edition price in setting
                            the total price shown to the buyer.</p>
                    </div>
                </div>

                <div className="col-6 pad-lr-7">
                    <div className="form-group at-floatlabel-h40">
                        <input type="number" name="height" min="0.0001" step="any" disabled={onlyView} className="form-control" value={state.height} placeholder=" " onWheel={(e: any) => e.target.blur()} onChange={onChangeName} />
                        <label>Height (in inches)</label>
                        {errors && errors['height'] && <ErrorComponent errors={errors['height']} multiple={false} />}
                    </div>
                </div>

                <div className="col-6 pad-lr-7">
                    <div className="form-group at-floatlabel-h40">
                        <input type="number" step="any" min="0.0001" name="width" disabled={onlyView} className="form-control" value={state.width} placeholder=" " onWheel={(e: any) => e.target.blur()} onChange={onChangeName} />
                        <label>Width (in inches)</label>
                        {errors && errors['width'] && <ErrorComponent errors={errors['width']} multiple={false} />}
                    </div>
                </div>
                

                {!onlyView && <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button className="at-btn at-btn-cancel at-longbtn-h40" onClick={() => onClose()}>Cancel</button>
                    </div>
                </div>}
                {!onlyView && <div className="col-6 pad-lr-7">
                    <div className="form-group">
                        <button className="at-btn at-longbtn-h40" disabled={loading || onlyView} onClick={() => onAddData()}>Add</button>
                    </div>
                </div>}
            </div>
        </div>
    )
}
