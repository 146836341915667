import React, { useEffect } from 'react'
import { createPortal } from 'react-dom';

interface IProps {
    children: any
}
const PortalChild = (props: IProps) => {
    const mount: any = document.getElementById("portal-child-root");
    const el = document.createElement("div");

    useEffect(() => {
        mount?.appendChild(el);
        return () => mount?.removeChild(el);
    }, [el, mount]);
    console.log("portal child:")
    const renderJsx = () => {
        return (
            <div>
                {props.children}
            </div>
        )
    }
    return createPortal(renderJsx(), el)
}

export default PortalChild