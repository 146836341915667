import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { APP_URL } from '../../environment'
import { toastService } from '../../services'
import { userTemplateService } from '../../services/userTemplates.service'
import { getCompleteUrlWithDomain } from '../../shared'
import { ErrorComponent } from '../common/Error.component'

interface IProps {
    goBack: Function,
    setData: Function,
    data: string
}
export const SelectUrl = ({ goBack, setData, data }: IProps) => {

    const [url, setUrl] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [isVerified, setVerified] = useState<boolean>(false)
    const [success, setSuccess] = useState<string>("")

    useEffect(() => {
        if (data) {
            setUrl(data)
        }
    }, [])


    const validateUrl = (u: string) => {
        if (!u) return
        let completeUrl = getCompleteUrlWithDomain(url)
        setLoading(true)
        userTemplateService.checkUrl(u).then((res: AxiosResponse<any>) => {
            if (res.data.isExist) {
                // toastService.warning(res.data.message)
                setSuccess("fail")
                setVerified(!res.data.isExist)
            } else {
                setSuccess("success")
                setVerified(!res.data.isExist)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onChangeUrl = (e: any) => {
        setUrl(e.target.value)
        validateUrl(e.target.value)
    }


    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2><span className="at-color-orange">STEP 1:</span> Select URL</h2>
                    {/* <h2>STEP 1: Select URL</h2> */}
                </div>
                <div className="at_theme_innercontent">
                    <h5 className="px-2 mb-4 text-normal">Select assigned URL below or update to match existing website.</h5>
                    <div className="row row-form-three-cols align-items-center">
                        <div className="col-12 pad-lr5 mb-4">
                            <p className="at-color-orange">{APP_URL}/</p>
                        </div>
                        <div className="col-12 pad-lr5 ">
                            <div className="form-group form-w-100 at-floatlabel-h40">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="url"
                                    value={url}
                                    className="form-control"
                                    placeholder=" "
                                    onChange={onChangeUrl}
                                />
                                <label>Your Own URL</label>
                                {loading && <span className="at-validate-url-spinner at-color-orange"><i className="fa fa-spinner"></i></span>}
                                {success === 'fail' && <ErrorComponent errors={['URL is not available']} multiple={false} />}
                                {success === 'success' && <div className="text-success">URL is available</div>}
                            </div>
                        </div>
                        {/* <div className="col-3 pad-lr5">
                            <p className="at-color-orange">.omnipresenti.com</p>
                        </div> */}
                    </div>
                    <div className="form-group mt-4">
                        <button className="at-btn at-medbtn-h40-mauto" disabled={!isVerified || loading} onClick={() => setData({ url })}>UPDATE</button>
                    </div>
                </div>
            </div>
        </main>
    )
}