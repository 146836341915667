import React from 'react'
import { images } from "../../assets/image";

interface IProps {
    onLogout: Function,
}
export const AdminHeaderComponent = ({ onLogout }: IProps) => {
    return (
        <header id="at-header" className="at-header at-headertwo at-haslayout">
            <div className="at-navigationarea">
                <h4 className="at-admin-topheading">
                    Admin <a className="at-right-listicon cursor-pointer" onClick={() => onLogout()}>
                        <i className="icon-logout"></i>
                    </a>
                </h4>
            </div>
        </header>
    )
}
