import React,{useState} from 'react'
import { INotification, IUser } from '../../../interfaces'
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component'

interface IProps {
    alert: INotification,
    user?: IUser
    joinUserStudio: Function
}

export const UserMessageWithStudio = ({ alert, user , joinUserStudio}: IProps) => {

    const [showConfirmBox, setConfirmBox] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const openConfirmBox = () => {
        setConfirmBox(true)
      }
  
      const closeConfirmBox = () => {
          setConfirmBox(false)
      }
  
      const submitConfirmBox = () => {
        setLoading(true)
        joinUserStudio(alert.from._id, alert)
        setLoading(false)
        closeConfirmBox()
      }

    return (
        <div className="at-star-boxouter">
            <ul className="at-star-topbtns d-flex justify-content-end">
                <li><i className="icon-menu at-color-grey" onClick={() => openConfirmBox()}></i></li>
            </ul>
            <div className="at-star-box">
                <div className="at-level-box px-3">
                    <p>{alert.message}</p>
                </div>
            </div>
            {showConfirmBox && <ConfirmAlertComponent
            open={showConfirmBox}
            onCancel={closeConfirmBox}
            onClose={closeConfirmBox}
            onConfirm={submitConfirmBox}
            title='Confirm!'
            message={`Confirm to join the conversation at ${alert.from.firstName} ${alert.from.lastName}'s Studio`}
            loading={loading}
            closeable={true}
            /> }
        </div>
    )
}