export * from './main.route'
export const routes = {
    landingPage: '/',
    account: {
        index: '/account',
        editProfile: '/account/edit-profile',
        funds: '/account/funds',
        transactions: '/account/transactions',
        offers: '/account/offers',
        salelogs: '/account/saleslog',
        terms: '/account/terms',
        connectedWithStripe: '/account/connected-with-stripe',
        metrics: '/account/metrics',
        diputes: '/account/disputes',
        bankDetails: '/account/bank-details',
        commissionWork: '/account/commission-work',
        returnArt: '/account/return-art',
        subscription: '/account/subscription',
        agentAssignAccount: '/account/agent-assign-account',
        artistAssignAccount: '/account/artist-assign-account',
        inPlatformWebsite: '/account/in-platform-webiste',
        agentCreateArt: '/account/agent-create-art',
        agentCreateArtEdit: '/account/agent-create-art/edit',
        services: {
            professional: {
                index: '/account/services/profession',
                access: '/account/services/profession/access',
                form: '/account/services/profession/form',
                transactions: '/account/services/profession/transactions',
                scheduledServices: '/account/services/profession/scheduled-services'
            },
            printingAndFraming: {
                index: '/account/services/printing-framing',
                form: '/account/services/printing-framing/form',
                transactions: '/account/services/printing-framing/transactions',
            },
            curatorialServices: {
                index: '/account/services/curatorial-services',
                form: '/account/services/curatorial-services/form',
                createProposal: '/account/services/curatorial-services/create-proposal',
                seeProposal: '/account/services/curatorial-services/see-proposal',
                participantCuratorialProject: '/account/services/curatorial-services/participant-invitaions',
            },
            auction: {
                index: '/account/services/auctions',
                add: '/account/services/auctions/add',
                form: '/account/services/auctions/form',
                showArts: '/account/services/auctions/showArts',
                joinAuction: '/account/services/auctions/join-auction',
                participantCuratorialProject: '/account/services/auctions/participant-invitaions',
            }
        }
    },
    home: '/home',
    myCentral: {
        index: '/my-central',
        home: '/my-central/home',
        spaces: '/my-central/spaces',
        contacts: '/my-central/contacts',
        chat: {
            video: '/my-central/streaming/video-chat',
            messages: '/my-central/streaming/messages',
            screenSharing: '/my-central/streaming/screen-sharing'
        }
    },
    artCentral: {
        index: '/art-central',
        newsFeeds: '/art-central/news-feeds',
        collection: {
            list: '/art-central/collection/list',
            create: '/art-central/collection/create',
            edit: '/art-central/collection/edit',
            view: '/art-central/collection/view',
            item: '/art-central/collection/item'
        },
        post: {
            create: '/art-central/post/create',
            edit: '/art-central/post/edit',
            details: '/art-central/post/details'
        },
        agent: {
            assignments: '/art-central/agent/assignments',
        },
        commissionWork: {
            requestCommissionwork: '/art-central/request-commission-work',
            proposalCommissionwork: '/art-central/proposal-commission-work'
        },
        events: {
            create: '/art-central/events/create',
            view: '/art-central/events/view',
            list: '/art-central/events/list',
            edit: '/art-central/events/edit'
        },
        artCollection: '/art-central/art-collection',
        gelleryWalk: {
            index: '/art-central/gallery-walk/list',
            create: '/art-central/gallery-walk/create',
            view: '/art-central/gallery-walk/view',
            featureArts: '/art-central/gallery-walk/feature-arts'
        }
    },

    submissionProcess: {
        index: '/submission-process',
        form: '/submission-process/form',
        serviceForm: '/submission-process/services/form',
    },
    agent: {
        assignments: '/art-central/assignments',
        showAssigment: '/art-central/assignment',
        collection: '/art-central/assignment/collection'
    },
    adminRoutes: {
        index: '/admin',
        login: '/admin/login',
        dashboard: '/admin/dashboard',
        users: '/admin/users',
        manageDisputes: '/admin/manage-disputes',
        transactions: '/admin/transactions',
        curatorialtransactions: '/admin/curatorial-transactions',
        serviceTransactions: '/admin/service-transactions',
        editText: '/admin/editText',
        subscription: '/admin/subscription',
        userSubscription: '/admin/user-subscription',
        systemParameters: '/admin/system-parameters',
        tutorials: '/admin/tutorials',
        submissionApplicationView: '/admin/application-view',
        shippingFactor: '/admin/shipping-factor'
    },
    template: {
        artShow: '/art-show',
        galeeryShow: '/gallery-show',
        printingFramingShow: '/printing-framing-services',
        consultingServicesShow: '/consulting-services'
    },
    userAlert: '/user-alert',
    notifications: '/notifications',
    agentNotifications: '/agent-notifications',
    loadingRoutes: '/loading',
    newsFeed: '/news-feed',
    artCollection: '/art-collection',
    appTutorials: '/app-tutorials',
}