export enum ERtcEvent {
	Mute = 'Mute',
	UnMute = 'UnMute',
	PlayVideo = 'PlayVideo',
	PauseVideo = 'PauseVideo',
	KillAll = 'KillAll',
}

export enum EConnectionState {
	DISCONNECTED = 'DISCONNECTED',
	CONNECTING = 'CONNECTING',
	CONNECTED = 'CONNECTED',
	DISCONNECTING = 'DISCONNECTING',
}

export interface IRtcAction {
	type: ERtcEvent
	payload?: any
}

export interface IRtcConnectionState {
	prevState: EConnectionState
	curState: EConnectionState
}
