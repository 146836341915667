import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IPost } from '../../interfaces';
import { routes } from './routes';
import { postService } from '../../services/post.service';
import { templatesImages } from '../../shared';
import { templateSelector } from '../../store/selectors';
import { PostComponent } from '../components/PostComponent';
import { CustomSwiper } from '../components/CustomSwiper'
import { BannerComponent } from '../components/BannerComponent';
import { CustomText } from '../components/CustomText';
import { ERole } from '../../enums';


const Home = () => {

    let template = useSelector(templateSelector)
    const history = useHistory()
    const [posts, setPosts] = useState<Array<IPost>>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchUserPost = useCallback(() => {
        setLoading(true)
        postService.userPosts(template.user._id).then((res: AxiosResponse<Array<IPost>>) => {
            setLoading(false)
            let data = res.data.reverse()

            let filter = data?.filter((i: IPost) => i.assetsCount > 0)
            
            setPosts([...filter])
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        fetchUserPost()
    }, [fetchUserPost])

    const onChangeSwiperIndex = (index: any) => {
        // let video = document.getElementsByTagName('video')
        // if (video[0]) {
        //     video[0].pause()
        // }
    }

    return (
        <div>
            <section className="At-SectionPage At-Section-Mh800">
                <div className="At-ContainerFull">
                    <BannerComponent hideDownIcon={true} title={template.homeInformation.heading} imgSrc={templatesImages(`${template.homeImage}`)}>
                        <button className="At-Btn At-Btn-lg At-BtnMw-250" onClick={() => history.push(routes(template.url).gallery.index)}>VIEW {template.user.roles.includes(ERole.CONSULTANT) ? 'Services' :`GALLERY`}</button>
                    </BannerComponent>

                </div>
            </section>
            <section className="At-SectionBlog At-Padding-tb8">
                <div className="container">
                    {/* <div className="At-BlogHeading">
                        <h3>Blog</h3>
                        <a href="#"><i className="icon-facebook-1"></i></a>
                    </div> */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="At-PostContent  px-0">
                                {/* <h3>{template.homeInformation.heading}</h3> */}
                                <CustomText>
                                    <p dangerouslySetInnerHTML={{ __html: template.homeInformation.description }}>
                                    </p>
                                </CustomText>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {posts && posts.length > 0 && <CustomSwiper onChangeSwiperIndex={onChangeSwiperIndex}>

                                {posts.map((post: IPost) => <div><PostComponent key={post._id} post={post} /></div>)}

                            </CustomSwiper>}
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Home