import {IAccount, ISocialSignup} from "../../interfaces";
import {LOGIN, LOGOUT, SOCIAL_SIGNUP, RESET_SOCIAL_SIGNUP} from "../types";

export const loginAction = (payload: IAccount) => ({
    type: LOGIN,
    payload
})

export const logoutAction = () => ({
    type: LOGOUT
})

export const socialLoginAction = (payload: ISocialSignup) => ({
    type: SOCIAL_SIGNUP,
    payload
})

export const resetSocialLoginAction = () => ({
    type: RESET_SOCIAL_SIGNUP
})