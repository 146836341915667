import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { connect, useSelector } from 'react-redux'
import { IArtAssignment, IFormates, IShippingDetail, IStoreReducers, ITransaction, IUser } from '../../../interfaces'
import { toastService, transactionService } from '../../../services'
import moment from 'moment'
import { CurrencyFormat } from '../../common/CurrencyFormat'
import { collectionImage } from '../../../shared'
import LoadingComponent from '../../common/Loading.component'
import { UserDetailPopup } from '../../common/Modals/UserDetailPopup'
import { ShippingPopup } from '../../common/Modals/ShippingPopup'
import { TransactionLayout } from '../../common/TransactionLayout'
import { socketNode } from '../../../services/socket.service'
import * as type from '../../../constants/transactionTypes'
import { transactionStatus } from '../../../helper'
import { ERole } from '../../../enums'
import { userSelector } from '../../../store/selectors'
import { useLocation } from 'react-router-dom'

interface IProps {
    routeUser?: IUser
}

const SellerTransaction = ({ routeUser }: IProps) => {
    const storeUser = useSelector(userSelector)
    const user = routeUser && routeUser._id ? routeUser : storeUser
    const [transactions, setTransactions] = useState<Array<ITransaction>>([])
    const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<IUser>()
    const [userType, setUserType] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedTransaction, setTransaction] = useState<ITransaction>()
    const [openShipmentPopup, setShipmentPopup] = useState<boolean>(false)
    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        transactionService.getSellerTransactions(user?._id).then((res: AxiosResponse<any>) => {
            let data = [...res.data.response]
            setLoading(false)
            setTransactions(data.reverse())
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const handleDetailPopup = (item: IUser, type: string, data: ITransaction) => {
        setShowDetailPopup(true)
        setUserType(type)
        setSelectedUser(item)
        setTransaction(data)
    }

    const onCloseDetailPopup = () => {
        setShowDetailPopup(false)
        let object: any = {}
        setTransaction(object)
    }

    const onOpenShipmentModal = (item: ITransaction) => {
        setTransaction(item)
        setShipmentPopup(true)
    }

    const onCloseShipmentPopup = () => {
        let object: any = {}
        setTransaction(object)
        setShipmentPopup(false)
    }

    const onSubmitShipment = (shipment: IShippingDetail) => {
        let data = {
            id: selectedTransaction?._id,
            shipment: { ...shipment }
        }
        if (data) {
            transactionService.updateTransactionShipment(data).then((response: AxiosResponse<any>) => {
                // socketNode.emit(type.CONFORMATION_OF_SHIPMENT, {
                //     from: selectedTransaction?.sellerID._id,
                //     to: selectedTransaction?.buyerID._id,
                //     message: message,
                //     itemId: selectedTransaction?.itemID.id,
                //     phone: selectedTransaction?.buyerID.phoneNumber,
                //     offerID: selectedTransaction.,
                //     path
                // })
                onCloseShipmentPopup()
                toastService.success("Shipping information updated")
                fetch()
            }).catch(err => console.log(err))

        }
    }
    const getItemType = (item: ITransaction) => {
        if (item.formatesId) {
            let format: Array<IFormates> = item?.itemID?.formates.filter((value: IFormates) => value._id === item?.formatesId)
            if (format && format.length <= 0) {
                return ` `
            }
            if (format && format[0]) {
                return format[0].size
            }
            return ` `
        }
        return `Original`
    }

    const getButtonStatus = (item: ITransaction) => {
        return transactionStatus(item.transactionState)
        //     if (item.transactionState === "completed") {
        //         return { status: 'Paid', color: 'orange' }
        //     } else if (item.transactionState === "refunded") {
        //         return { status: 'Refunded', color: 'orange' }
        //     } else {
        //         return { status: 'Pending', color: 'grey' }
        //     }

        // return { status: 'Pending', color: 'grey' }
    }

    const getShipmentRes = (t: ITransaction) => {
        let assignment = t.itemID?.assignment ? t.itemID.assignment as IArtAssignment : null
        let isAgentInvolve = t.sellerID?._id !== t.artistID?._id
        let isAgent = user?.roles.includes(ERole.AGENT)
        let artRes = assignment?.originalResponsibility ? assignment?.originalResponsibility : ''
        let formatRes = assignment?.formatResponsibility ? assignment?.formatResponsibility : ''
        if (!assignment) {
            return true
        }
        if (!assignment && !isAgent) {
            return true
        }
        if (assignment && !isAgent && !isAgentInvolve) {
            return true
        }
        if (assignment && !isAgent && isAgentInvolve && !t.formatesId && artRes === 'artist' && !t.milestoneID) {
            return true
        }
        if (assignment && !isAgent && isAgentInvolve && t.formatesId && formatRes === 'artist' && !t.milestoneID) {
            return true
        }
        if (assignment && !isAgent && isAgentInvolve && artRes === 'artist' && t.milestoneID) {
            return true
        }
        if (assignment && isAgent && isAgentInvolve && t.formatesId && (formatRes === 'seller' || formatRes === 'gallery') && !t.milestoneID) {
            return true
        }
        if (assignment && isAgent && isAgentInvolve && !t.formatesId && (artRes === 'seller' || artRes === 'gallery') && !t.milestoneID) {
            return true
        }
        if (assignment && isAgent && isAgentInvolve && (artRes === 'seller' || artRes === 'gallery') && t.milestoneID) {
            return true
        }

        return false
    }

    return (
        <>
            <div className="at-transaction-main">
                {transactions?.length > 0 ? (
                    <>
                        {transactions?.map((item: ITransaction) => (
                            <>
                                {getShipmentRes(item) && <div className="at-btniconsbox">
                                    <button title="Shipping details" className="at-btnshipping at-btnicon" disabled={item.shipment && item.shipment.status} onClick={() => onOpenShipmentModal(item)}><i className="icon-Bus" ></i></button>
                                </div>}
                                <div key={item._id} className="at-transaction-wrap">
                                    <TransactionLayout item={item} handleDetailPopup={handleDetailPopup} getButtonStatus={getButtonStatus} getItemType={getItemType} />
                                </div>
                            </>
                        ))}
                    </>
                ) : (<>{loading && <LoadingComponent className='at-transaction-loader' />}</>)}


            </div>
            {showDetailPopup && <UserDetailPopup userType={userType} user={selectedUser} showSellerDetails={showDetailPopup} onClose={onCloseDetailPopup} transaction={transactions} item={selectedTransaction} />}
            {openShipmentPopup && <ShippingPopup open={openShipmentPopup} onClose={onCloseShipmentPopup} onSubmit={onSubmitShipment} transaction={selectedTransaction} />}
        </>
    )
}



export default SellerTransaction