import { AxiosResponse, AxiosError } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { images } from '../../../assets/image'
import { IResponse } from '../../../interfaces/db.interface'
import { ISubmissionProcess } from '../../../interfaces/submission-process.interface'
import { submissionProcessService } from '../../../services'
import { eventImages } from '../../../shared'
import { CustomImage } from '../../../templates/components/CustomImage'
import { ImageLoading } from '../../common/ImageLoading'

export const ApplicationArts = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [application, setApplication] = useState<ISubmissionProcess>()
    const appId = (useParams() as any).id
    const fetch = useCallback(() => {
        setLoading(true)
        submissionProcessService.singleApplicationById(appId).then((res: AxiosResponse<IResponse<ISubmissionProcess>>) => {
            setApplication(res.data.data)
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }, [])
    useEffect(() => {
        fetch()
    }, [])
    return (
        <div className="at-artgallerycollections at-themescrollbar at-applicationart">
            <ul className="at-artcollectiongrid at-artgallerycollection">
                {
                    application && application.images && application.images.length > 0 && application.images.map((i: any, index: any) => {
                        return (
                            <li className="itemImage" key={index}>
                                <div className="at-artgallerycollectionbox atv2-collectionbox">
                                    {/* <img src={eventImages({}, i.url)} className="img-fluid" alt="" /> */}
                                    <CustomImage src={eventImages({}, i.url)} className="img-fluid" thumbnail={eventImages({}, `blur_${i.url}`)} />
                                    <div className="at-clearfix"></div>
                                    <div className="at-artdetail-box">
                                        {/* <h5>
                                            Smith_Ocean Blue_Oil on
                                    </h5> */}
                                        <p>{i.url.split(/\_(?=[^\_]+$)/)[0]}</p>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }

                {/* <li className="itemImage">
                    <div className="at-artgallerycollectionbox atv2-collectionbox">
                        <img src={images.gallery.img05} className="img-fluid" alt="" />
                        <div className="at-clearfix"></div>
                        <div className="at-artdetail-box">
                            <h5>
                                Smith_Ocean Blue_Oil on
                            </h5>
                            <p> Canvas_10x20x30_5000.jpg</p>
                        </div>
                    </div>
                </li> */}
            </ul>
        </div>
    )
}