import React, { FormEvent } from 'react'
import { StepWizardChildProps } from 'react-step-wizard';
import { ICollectionItemState, ICreateCollection } from '../../../../interfaces';
import { toastService } from '../../../../services';

interface IProps extends StepWizardChildProps {
    item: ICollectionItemState
    handleOfferState: Function
    onChangeText: Function
    setErrorData: Function
    errors: any
    errorElement: Function
}

export const CreateCollectionStepFour = ({ item, onChangeText, handleOfferState, nextStep, previousStep, errors, errorElement, setErrorData }: IProps) => {

    const onClickAuction = () => {
        if (item.assignment) {
            toastService.warning('You have assigned this work of art to a gallery and therefore is not available for auction services.')
            return
        }
        onChangeText({
            target: { name: "isAuctioned", value: !item.isAuctioned },
        })
    }
    return (
        <>
            <div className="at-orangeheading mb-1">
                <h5>Set Conditions for Sale of Original</h5>
            </div>
            <div className="at-greyborderbox pt-4">
                <div className="form-group form-w-100 at-form-custmargin">
                    <div className="at-deafhardholder">
                        <span className="at-onoffToggle">Accept Offers</span>
                        <div
                            className="onoffswitch at-accept-offer"
                            onClick={() => handleOfferState()}
                        >
                            <input
                                type="checkbox"
                                name="artist"
                                className="onoffswitch-checkbox"
                                id="myonoffswitch6"
                                checked={item.offerState}
                            />
                            <label className="onoffswitch-label"></label>
                        </div>
                    </div>
                </div>
                <div
                    className="form-group form-w-100 at-form-custmargin"
                    onClick={
                        () =>
                            onChangeText({
                                target: { name: "sold", value: !item.sold },
                            })
                    }
                >
                    <div className="at-deafhardholder">
                        <span>ART has already been sold</span>
                        <div className="onoffswitch">
                            <input
                                autoComplete="off"
                                type="checkbox"
                                name="isDeaf"
                                className="onoffswitch-checkbox"
                                id="myonoffswitch4"
                                checked={item.sold}
                            />
                            <label className="onoffswitch-label"></label>
                        </div>
                    </div>
                </div>
                <div
                    className="form-group form-w-100 at-form-custmargin"
                    onClick={
                        () =>
                            onChangeText({
                                target: { name: "reserved", value: !item.reserved },
                            })
                    }
                >
                    <div className="at-deafhardholder">
                        <span>ART is not for sale</span>
                        <div className="onoffswitch">
                            <input
                                autoComplete="off"
                                type="checkbox"
                                name="isDeaf"
                                className="onoffswitch-checkbox"
                                id="myonoffswitch4"
                                checked={item.reserved}
                            />
                            <label className="onoffswitch-label"></label>
                        </div>
                    </div>
                </div>
                <div
                    className="form-group form-w-100 at-form-custmargin"
                    onClick={() => onClickAuction()}
                >
                    <div className="at-deafhardholder">
                        <span>ART available for auction services</span>
                        <div className="onoffswitch">
                            <input
                                autoComplete="off"
                                type="checkbox"
                                name="isAuctioned"
                                className="onoffswitch-checkbox"
                                id="myonoffswitch4"
                                checked={item.isAuctioned}
                            />
                            <label className="onoffswitch-label"></label>
                        </div>
                    </div>
                </div>
                {item.isAuctioned && <>
                    <div className="form-group form-w-100 at-floatlabel">
                        <input
                            autoComplete="off"
                            type="number"
                            step="any"
                            min="0.0001"
                            name="auctionCommission"
                            className="form-control at-input-semibold"
                            placeholder=" "
                            value={item.auctionCommission}
                            onWheel={(e: any) => e.target.blur()}
                            onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                        />
                        <label>Percent (%) commission paid to auctioneer</label>
                        {errorElement("auctionCommission")}
                    </div>

                    <div className="form-group form-w-100 at-floatlabel">
                        <input
                            autoComplete="off"
                            type="number"
                            step="any"
                            min="0.0001"
                            name="auctionMinPrice"
                            className="form-control at-input-semibold"
                            placeholder=" "
                            value={item.auctionMinPrice}
                            onWheel={(e: any) => e.target.blur()}
                            onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                        />
                        <label>Minimum Art Price accepted</label>
                        {errorElement("auctionMinPrice")}
                    </div>
                </>}
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => previousStep()}
                        className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                    >
                        BACK
                    </button>
                </div>
                <div className="form-group form-w-50">
                    <button
                        type="button"
                        onClick={() => nextStep()}
                        className="at-btn at-medbtn-h40-mauto"
                    >
                        NEXT
                    </button>
                </div>
            </div>
            {/* at-greyborderbox ends */}

        </>
    )
}