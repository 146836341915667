export const en = {
    collection: {
        createdSuccessfully: 'Collection created successfully',
        updatedSuccessfully: 'Collection updated successfully',
        deletedSuccessfully: 'Collection deleted successfully',
    },
    common: {
        httpError: 'Some error occurred.',
        loadMore: 'Load more',
    },
    errors: {
        required: {
            art_collection: {
                name: 'Art name is required',
                price: 'Art price is required',
                art_collection: 'Art collection not selected',
                description: 'Art description is required',
                artTechnique: 'Art technique & medium is required',
                height: 'Height is required',
                width: 'Width is required',
                shipping: 'Shipping Cost is required',
                depth: 'Depth is required',
            }
        }
    },
    feeds: {
        reachedEnd: 'You did it! You reached the end!',
        notFound: 'No post found!',
        changeSearch: 'No search found!'
    },
    deleteSelectedItems: 'Are you sure you want to delete selected items?',
    assignment: {
        acceptAssignment: 'Are you sure you want to accept this assignment?',
        rejectAssignment: 'Are you sure you want to reject this assignment?',
    }
}