import React, { useEffect, useState } from 'react'
import { useElements, useStripe, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import { ErrorComponent } from './Error.component';
import LoadingComponent from './Loading.component';
import { toastService } from '../../services';

interface IProps {
    setDropinInstance: Function,
    onConfirmPay: Function
    loading?: boolean,
    message?: string
    stripeData: any
}
export const PaymentForm = ({ loading, onConfirmPay, setDropinInstance, message, stripeData }: IProps) => {
    const [isLoad, setLoad] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>([])

    const stripe = useStripe()
    const elements = useElements()

    const CARD_OPTIONS: any = {
        hidePostalCode: true,
        showIcon: true,
        iconStyle: 'default',
        style: {
            base: {
                color: '#000000',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': { color: '#fce883' },
                '::placeholder': { color: '#87bbfd' },
                padding: '10px 10px'
            },
            invalid: {
                iconColor: '#dc3545',
                color: '#dc3545',
            },
        },
    };
    const OTHER_OPTIONS: any = {
        style: {
            base: {
                color: '#000000',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': { color: '#fce883' },
                '::placeholder': { color: '#87bbfd' },
                padding: '10px 10px'
            },
            invalid: {
                color: '#dc3545',
            },
        },
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setLoad(true)
        if (!stripe || !elements) {
            return;
        }
        const cardElement: any = elements.getElement(CardNumberElement);

        const {paymentIntent, error} = await stripe.confirmCardPayment(stripeData.clientSecret,{
            payment_method: {
                card: cardElement,
              }
        });
        setLoad(false)
        if (error){
         console.log(error)
         let newError = [error.message]
         setErrors(newError)
        }else {
            toastService.success('Payment has been received successfully.')
          onConfirmPay()
          setErrors([])
        }
    }

    return (
        <>
            <form className="at-formtheme" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="pl-2" htmlFor="cardNumber">Card Number</label>
                    <CardNumberElement
                        id="cardNumber"
                        options={CARD_OPTIONS}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label className="pl-2" htmlFor="expiry">Card Expiration</label>
                    <CardExpiryElement
                        id="expiry"
                        options={OTHER_OPTIONS}
                    />
                </div>
                <div className="form-group">
                    <label className="pl-2" htmlFor="cvc">CVC</label>
                    <CardCvcElement
                        id="cvc"
                        options={OTHER_OPTIONS}
                    />
                </div>
                {errors && errors.length>0 && <ErrorComponent errors={errors} multiple={false} />}
                <div className="pb-2">
                    <button type="submit" className="at-btn at-longbtn-h40" disabled={isLoad} >{isLoad ? 'Please wait ...' : 'Pay'}</button>
                </div>
            </form>

            {/* <CardElement options={CARD_OPTIONS} className='StripeElement' />
            <button type="submit" className="cursor-pointer btn btn-orange btn-block pt-2" onClick={handleSubmit} >Pay</button> */}
        </>
    )
}