import React, { useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { AdminWeightFactor } from './WeightFactor.component'
import { AdminCostFactor } from './CostFactor.component';
import { IShippingFactorDocument } from '../../../interfaces';
import { adminService } from '../../../services';
import { AxiosResponse } from 'axios';
import { IResponse } from '../../../interfaces/db.interface';

interface IProps {

}

const AdminShippingFactors = ({ }: IProps) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [shipping, setShipping] = useState<IShippingFactorDocument>()
    const fetch = useCallback(() => {
        setLoading(true)
        adminService.getShippingFactor().then((res: AxiosResponse<IResponse<IShippingFactorDocument>>) => {
            if (res.data.data) {
                setShipping(res.data.data)
            }
            setLoading(false)
        }).catch((err: any) => {
            setLoading(false)
            console.log(err)
        })
    }, [])

    const onSwap = (s: IShippingFactorDocument) => {
setShipping({...s})
    }

    useEffect(() => {
        fetch()
    }, [])

    const history = useHistory()
    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 scrollable at-contentarea-two">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>Shipping Factors</h2>
                </div>
                <div className="at_theme_innercontent at_tabs_styling">
                    <Tabs >
                        <Tab label="Weight Factors">
                            <AdminWeightFactor shipping={shipping} fetch={fetch} onSwap={onSwap} />
                        </Tab>
                        <Tab label="Cost Factors">
                            <AdminCostFactor shipping={shipping} fetch={fetch} />
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </main>
    )
}

export default AdminShippingFactors