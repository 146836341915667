import { IStoreAction } from '../../interfaces/store.interface'
import { LOAD_HUBS_WITH_SPACES } from '../types'
import { hubService } from '../../services'
import { udpateUserDetails } from './user.action'

export const loadHubsWithSpacesRequest = (): IStoreAction => ({
	type: LOAD_HUBS_WITH_SPACES,
})

export const loadHubsWithSpaces = () =>
	function (dispatch: Function) {
		hubService
			.list()
			.then((res) => dispatch(udpateUserDetails({ hubsWithSpaces: res.data })))
	}
