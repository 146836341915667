import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { ITutorials } from '../../interfaces'
import { IResponse } from '../../interfaces/db.interface'
import { tutorialsService } from '../../services'
import LoadingComponent from '../common/Loading.component'
import { TutorailPlayComponent } from './TuturialPlay.component'

interface IProps {
    id: string
}
export const SingleTutorialView = ({ id }: IProps) => {

    const [tutorial, setTutorial] = useState<ITutorials>()
    const [loading, setLoading] = useState<boolean>(false)

    const fetchTutorial = () => {
        setLoading(true)
        tutorialsService.getTutorialById(id).then((res: AxiosResponse<IResponse<ITutorials>>) => {
            let { data } = res

            setTutorial(data.data)
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchTutorial()
    }, [])
    return (
        <div>
            <h5 className="at-popupheading">Select Tutorial</h5>
            {tutorial && !loading && <div className="at-categorybox at-tutorialplaybox">
                {/* <div className="at-categoryhead">
                                <h4 className="at-additional-heading">Getting Started</h4>
                            </div> */}
                <TutorailPlayComponent seletecedVideo={''} onSelectVideo={() => { }} tutorial={tutorial} onShareLink={() => { }} hideShare={true} autoPlay={true} />

            </div>}
            {loading && <LoadingComponent className='at-transaction-loader' />}
        </div>
    )
}