import React from 'react'

interface IProps {
    platform: string,
    message: string
}
export const CustomTooltip = ({ platform, message }: IProps) => {
    return (
        <div className="at-message-box" style={{ display: 'block' }}>
            <h6>{platform}</h6>
            <p dangerouslySetInnerHTML={{__html: message}}></p>
        </div>
    )
}