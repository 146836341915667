import { HttpService } from './base.service'
import { data } from 'jquery';

class NotificationService extends HttpService {
    private readonly prefix: string = 'notification';

    /**
     *  Get All Notifications
     */
    getNotifications = (id:any): Promise<any> => this.get(`${this.prefix}/user/${id}`);

    getUserAlerts = (id: any): Promise<any> => this.get(`${this.prefix}/useralert/${id}`);

}

export const notificationService = new NotificationService();