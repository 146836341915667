import React, { FormEvent } from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import { ICollection, ICollectionItemState } from '../../../../interfaces'


interface IProps extends StepWizardChildProps {
    item: ICollectionItemState
    errorElement: Function
    params: any
    new_art_collection: string
    onChangeText: Function
    collections: Array<ICollection>
}

export const CreateCollectionStepTwo = ({ item, errorElement, params, new_art_collection, onChangeText, collections, nextStep, previousStep }: IProps) => {
    return (
        <>
            <div className="at-orangeheading">
                <h5>Select Collection</h5>
            </div>
            <div className="at-greyborderbox pt-4">
                <div className="form-group form-w-100">
                    <span className="at-select at-floatlabel mb-3">
                        <select
                            className="floating-select"
                            value={
                                params && new_art_collection
                                    ? new_art_collection
                                    : item.art_collection._id ? item.art_collection._id : item.art_collection
                            }
                            name="art_collection"
                            onChange={(e: FormEvent<HTMLInputElement> | any) => onChangeText(e)}
                            required
                        >
                            <option value="" disabled></option>
                            {collections.map((c: ICollection) => (
                                <option value={c.id} key={c.id}>
                                    {c.title}
                                </option>
                            ))}
                        </select>
                        <label className="at-lineheight-27">Select Collection</label>
                    </span>
                    {errorElement("art_collection")}

                    <div className="form-group form-w-50">
                        <button
                            type="button"
                            onClick={() => previousStep()}
                            className="at-btn at-btn-bermudagrey at-medbtn-h40-mauto"
                        >
                            BACK
                        </button>
                    </div>
                    <div className="form-group form-w-50">
                        <button
                            type="button"
                            onClick={() => nextStep()}
                            disabled={!new_art_collection && !item.art_collection}
                            className="at-btn at-medbtn-h40-mauto"
                        >
                            NEXT
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}