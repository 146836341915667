import { HttpService } from './base.service'
import { data } from 'jquery';

class SubscriptionService extends HttpService {
    private readonly prefix: string = 'subscription'
    getPackages = (role: string): Promise<any> => this.get(`${this.prefix}/packages/${role}`)

    onBuyBasic = (data: any): Promise<any> => this.post(`${this.prefix}/package/buy-basic`, data)
    onBuyPaid = (data: any): Promise<any> => this.post(`${this.prefix}/package/buy`, data)

    getSubscriptionInvoice = (id: string): Promise<any> => this.get(`${this.prefix}/user_subscription/${id}`)

    updateUserCard = (data: any): Promise<any> => this.post(`${this.prefix}/update-card`, data)

    adminRefund = (data: any): Promise<any> => this.post(`${this.prefix}/admin/user-refund`, data)

    pauseSubscription = (id: any, data: any): Promise<any> => this.put(`${this.prefix}/pause/${id}`, data)
}

export const subscriptionService = new SubscriptionService();