import React from 'react'

interface IProps {
    open: boolean
    message: string
    title: string
    onClose: Function
    onNotAllow: Function
    onAllow: Function
    loading: boolean
}

export const AdminResolveModal = ({ open, message, title, onClose, onNotAllow, onAllow, loading }: IProps) => {
    return (
        open ? <div id="at-themerecoverycodepopup" className={`at-themepopupholder at-popupfixedbottom at-showthemerecoverycodepopup`}>
            <div className="at-themepopupbox">
                <div className="row confirmbox">
                    <a className="at-close cursor-pointer" id="at-close" onClick={() => onClose()}><i className="icon-cancel"></i></a>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h3>{title}</h3>
                        <p>{message}</p>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group">
                            <button type="button" disabled={loading} className="btn btn-secondary btn-block" onClick={() => onAllow()}>{loading ? 'Please wait...' : `Allow Buyer to Return Item`}</button>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group">
                            <button type="button" disabled={loading} className="btn btn-orange btn-block" onClick={() => onNotAllow()}>{loading ? 'Please wait...' : `Item may not be Returned`}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div> : <div></div>
    )
}