import React, { Component, FormEvent } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom'
import { BAD_REQUEST, CONFLICT } from 'http-status'
import { postService } from '../../../../services/post.service';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { IPost, IPostImage, IStoreReducers, IUser } from '../../../../interfaces';
import { validationErrors, postImage } from '../../../../shared';
import { toastService, activatePost } from '../../../../services';
import { ErrorComponent } from '../../../../components/common/Error.component';
import { routes } from '../../../../router';
import moment from 'moment';
import { ConfirmAlertComponent } from '../../../../components/common/ConfirmAlert.component';
import LoadingComponent from '../../../../components/common/Loading.component';
import { SliderComponent } from '../../../../components/common/Slider.component';
import { connect } from 'react-redux';
import { dataURLtoFile, bytesToMegaBytes, _logger } from '../../../../helper';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { OVERLAY_CLASS } from '../../../../constants';



interface IProps extends RouteComponentProps {
    user: IUser,
    id?: string
    onSelecPost: Function
    onSubmitPost: Function
}

interface IPageState {
    preview: Array<string>
    previewsCopy: Array<string>
    selectedFiles: Array<any>
    post: IPost | {
        description: string
        assets: Array<IPostImage>
    }
    selectedFile: number
    loadPost: boolean
    submitting: boolean
    errors: any
    posts: Array<IPost>
    loadingPosts: boolean
    openConfirmBox: boolean
    deletePostId: string
    deletingPost: boolean
    noOfUploadedPhotos: number
    uploadingProgress: number
}


class AssignedCreateEditPostPage extends Component<IProps> {

    state: IPageState = {
        preview: [],
        previewsCopy: [],
        selectedFiles: [],
        post: {
            description: '',
            assets: []
        },
        selectedFile: 0,
        loadPost: false,
        submitting: false,
        errors: null,
        posts: [],
        loadingPosts: false,
        openConfirmBox: false,
        deletePostId: '',
        deletingPost: false,
        noOfUploadedPhotos: 0,
        uploadingProgress: 0
    }

    componentDidMount() {
        
        this.checkAuthentication();
        this.fetchMyPosts();

    }
    
    componentWillReceiveProps(props: IProps) {
		if (props.id) {
			this.fetch();
		}
	}

    checkAuthentication = () => {
        if (!activatePost(this.props.user)) {
            toastService.info('To use the post feature you have to create at least one collection.')
            this.props.history.goBack();
        }
    }

    componentWillUnmount() {
        postService.cancel()
    }

    get params() {
        return this.props.match.params
    }

    fetchMyPosts = (): void => {
        this.toggleLoading('loadingPosts')
        let { user } = this.props
        postService.myPosts(user._id).then((response: AxiosResponse<Array<IPost>>) => {
            this.setState({ posts: [...response.data] }, () => console.log(`posts:-=-=-=-=`, this.state.posts))
            this.toggleLoading('loadingPosts')

            if (response.data.length === 0 && this.props.location.pathname !== routes.artCentral.post.create) {
                this.props.history.push(routes.artCentral.post.create)
            }
        }, (error: AxiosError) => {
            this.toggleLoading('loadingPosts')
        })
    }

    fetch = (): void => {
        if (!this.props.id) return
        this.toggleLoading('loadPost')
        postService.show(this.props.id).then(this.fetchSuccessHandler, (error: AxiosError) => {
            this.toggleLoading('loadPost')
        })
    }

    fetchSuccessHandler = async (response: AxiosResponse<IPost>) => {
        if (response.data.user._id !== this.props.user._id) {
            this.props.history.goBack();
        }
        // const preview = await Promise.all((response.data && response.data.assets).map(async (a: IPostImage) => await getBase64(postImage(response.data._id, a.url))))
        const preview = ((response.data && response.data.assets) || []).map((a: IPostImage) => ({ url: postImage(response.data._id, a.url), isVideo: a.isVideo }))
        this.setState({ post: response.data, preview, previewsCopy: preview })

        this.toggleLoading('loadPost')
    }

    onChangeText = (e: FormEvent<HTMLInputElement> | any): void => {
        const { name, value } = e.target;
        const { post } = this.state;
        const _post = { ...post, [name]: value };
        this.setState({ post: _post })
    }

    toggleLoading = (key: 'loadPost' | 'submitting' | 'loadingPosts' | 'deletingPost'): void => {
        this.setState({ [key]: !this.state[key] })
    }

    submit = (e: FormEvent): void => {
        e.preventDefault()
        const { post, preview } = this.state;
        let { user } = this.props

        if (preview.length < 1) {
            const errors = { files: ['Atleast one picture is required.'] }
            this.setState({ errors })
            return;
        }
        this.toggleLoading('submitting');
        const { id } = this.props;
        const request = !id ? postService.add({ ...post, userId: user._id }) : postService.update(id, { description: post.description, assets: post.assets });
        request.then((response: AxiosResponse<IPost>) => {
            this.attachImages(id ? id : response.data._id)
        }, this.errorHandler)
    }

    errorHandler = ({ response }: any) => {
        this.toggleLoading('submitting');
        let errors = null;
        switch (response.status) {
            case BAD_REQUEST:
                errors = validationErrors(response.data);
                break;
            case CONFLICT:
                toastService.error(response.data.message)
                break;
            default:
                break;
        }
        this.setState({ errors })
    }

    upaloadProgress = ({ loaded, total }: any) => {
        const uploadingProgress = (loaded / total) * 100;

        this.setState({ uploadingProgress })
    }

    attachImages = (postID: string): void => {
        const data = new FormData();
        this.state.selectedFiles.forEach((v: any) => data.append('files', v));
        postService.attachImages(postID, data, { onUploadProgress: this.upaloadProgress }).then((response: any) => {
            this.toggleLoading('submitting');
            this.props.history.push(routes.artCentral.newsFeeds)
        });
    }

    errorElement = (key: string) => (
        this.state.errors && this.state.errors[key] && <ErrorComponent errors={this.state.errors[key]} multiple={false} />
    )

    onChangeFiles = (e: any): void => {
        const { preview, selectedFile, selectedFiles } = this.state;
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    this.setState({
                        preview: [...preview, ...images],
                        previewsCopy: [...preview, ...images],
                        selectedFiles: [...selectedFiles, ...files],
                        selectedFile: selectedFile ? selectedFile : 0
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    deleteImage = (): void => {

        let { post, preview, selectedFiles, selectedFile: i } = this.state;

        if (i > -1 && preview[i]) preview.splice(i, 1)
        let oldPlusNew = [...post.assets, ...selectedFiles];
        oldPlusNew.splice(i, 1);
        post.assets = oldPlusNew.filter((v: any) => v._id)
        const newAssets = oldPlusNew.filter((v: any) => !v._id)
        const selectedFile = i > 0 ? i - 1 : 0;

        this.setState({ preview, previewsCopy: preview, selectedFiles: newAssets, post, selectedFile })
    }

    deletePost = (): void => {
        this.toggleLoading('deletingPost')
        postService.deletePost(this.state.deletePostId).then((response: any) => {
            if (this.props.id === this.state.deletePostId) {
                this.props.history.push(routes.artCentral.post.create)
            }
            this.closeConfirmBox()
            this.toggleLoading('deletingPost')
            this.fetchMyPosts();
            toastService.info(response.data.message)
        }, error => {
            this.toggleLoading('deletingPost')
            this.closeConfirmBox()
        })
    }

    closeConfirmBox = () => {
        this.setState({ openConfirmBox: false })
    }

    onCrop = (image: any) => {
        const fileName = this.state.selectedFiles[image.index].name
        const file = dataURLtoFile(image.cropped, fileName)
        const updatedFiles = [...this.state.selectedFiles]
        updatedFiles[image.index] = file
        const updatedPreviews = [...this.state.preview]
        updatedPreviews[image.index] = image.cropped
        this.setState({
            ...this.state,
            selectedFiles: updatedFiles,
            preview: updatedPreviews
        })
    }

    render() {
        const { pathname, state } = this.props.location;
        let { submitting, post, posts, preview, previewsCopy, selectedFile, openConfirmBox, deletingPost, loadingPosts, uploadingProgress } = this.state;
        posts = posts.filter((c: IPost) => c.assets.length > 0);
        const selectImages = () => {
            const { files }: any = this.refs
            files.click();
        }
        return (
            <div className="at-createpost at-contentarea">
                <div className="at-createpostholder at-themescrollbar scrollable">
                    <div className="at-themehead">
                        <h2>{this.props.id ? 'Edit' : 'Create'} Post</h2>
                    </div>
                    <form className={`at-formtheme at-formcreatepost ${submitting && OVERLAY_CLASS}`} onSubmit={this.submit}>
                        <fieldset>
                            <div className="form-group mb-2">
                                <div className="at-themefileupload">
                                    <input type="file" name="files[name]" multiple ref="files" accept="image/*,video/*" onChange={this.onChangeFiles} />
                                    <label onClick={selectImages}>
                                        <span><i className="icon-youtube"></i>Upload Images/Videos</span>
                                    </label>
                                </div>
                                {
                                    this.errorElement('files')
                                }
                            </div>
                            <div className={`form-group ${uploadingProgress}`}>
                                <SliderComponent items={preview} originalItems={previewsCopy} onCrop={this.onCrop} currentIndex={selectedFile} onChangeIndex={(selectedFile: number) => this.setState({ selectedFile })} />
                                {
                                    preview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={this.deleteImage}></i>)
                                }
                                {/* <CropImageComponent image={preview[selectedFile]} /> */}
                            </div>
                            {preview.length > 1 && (
                                <p>
                                    <span className="text-bold">{selectedFile + 1}</span> / {preview.length}
                                </p>
                            )}
                            <div className="form-group">
                                <textarea name="description" placeholder="Description" value={post.description} className="form-control" onChange={this.onChangeText}>{post.description}</textarea>
                                {
                                    this.errorElement('description')
                                }
                            </div>
                            {uploadingProgress > 0 && <CircularProgressbar className="w40px mb-5 mt-5" value={uploadingProgress} text={`${Math.round(uploadingProgress)}%`} />}
                            <div className="form-group">

                                <span className={`${post.description.length > 250 && 'text-danger'}`}>You have {250 - post.description.length} characters left</span>
                                <button type="submit" className="at-btn at-btnpost" disabled={submitting || preview.length < 1}>{submitting ? 'POSTING...' : (this.props.id ? 'UPDATE' : 'POST')}</button>
                            </div>
                        </fieldset>
                    </form>
                    <div className={`at-managepostholder ${submitting && OVERLAY_CLASS}`}>
                        <div className="at-manageposttitle">
                            <h2>Manage Posts</h2>
                        </div>
                        <div className="row">
                            {
                                loadingPosts && <LoadingComponent />
                            }
                        </div>
                        <ul className="at-manageposts">
                            {/* <li>
								<figure><img src="images/post-img/img-03.jpg" alt=""/></figure>
								<div className="at-managepostcontent">
									<span>11/27/2019<em>5:30:17</em></span>
									<div className="at-btniconholder">
										<a  className="at-btnpause at-btnmanagepost"><i className="icon-pause"></i></a>
										<a  className="at-btncancel at-btnmanagepost"><i className="icon-cancel"></i></a>
									</div>
									<div id="at-ourskill" className="at-ourskill at-ourskillgroup">
										<div className="at-skill">
											<div className="at-skillholder" data-percent="50%">
												<div className="at-skillbar"></div>
											</div>
										</div>
									</div>
								</div>
							</li> */}
                            {/* {
								posts.map((c: IPost) => {
									const [first] = c.assets;
									return (
										<li key={c._id}>
											<figure><img src={postImage(c._id, first.url)} alt=""/></figure>
											<div className="at-managepostcontent">
												<span>{ moment(c.createdAt).format('MM/DD/YYYY')}<em>{moment(c.createdAt).format('hh:mm:ss')}</em></span>
												<div className="at-btniconholder">
													<button className="at-btndelete">
														<Link to={`${routes.artCentral.post.edit}/${c._id}`}>
															<i className="icon-edit mr-2" title="Edit"></i>
														</Link>
														<i className="icon-delete text-danger" title="Delete" onClick={() => this.setState({ deletePostId: c._id, openConfirmBox: true })}></i>
													</button>
												</div>
											</div>
										</li>
									)
								})
							} */}
                            {
                                posts.map((c: IPost) => {
                                    const [first] = c.assets;

                                    return (
                                        <li key={c._id}>
                                            <figure><img src={postImage(c._id, first.isVideo ? first.thumbnail : first.url, first.isVideo)} alt="" /></figure>
                                            <div className="at-managepostcontent">
                                                <span>{moment(c.createdAt).format('MM/DD/YYYY')}<em>{moment(c.createdAt).format('hh:mm:ss')}</em></span>
                                                <div className="at-btniconholder">
                                                    <button className="at-btndelete">
                                                        {
                                                            pathname != `${routes.artCentral.post.edit}/${c._id}` && <a onClick={() => this.props.onSelecPost(c)}>
                                                                <i className="icon-edit mr-2" title="Edit"></i>
                                                            </a>
                                                        }
                                                        <i className="icon-delete text-danger" title="Delete" onClick={() => this.setState({ deletePostId: c._id, openConfirmBox: true })}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <ConfirmAlertComponent
                    open={openConfirmBox}
                    onCancel={this.closeConfirmBox}
                    onConfirm={this.deletePost}
                    confirmTitle={deletingPost ? 'Deleting...' : 'Confirm'}
                    message='Are you sure you want to delete this post?'
                />
            </div>
        );
    }
}


export default AssignedCreateEditPostPage;