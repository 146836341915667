import { HttpService } from "./base.service";
import { IUser } from "../interfaces";

class EventService extends HttpService {
    private readonly prefix: string = 'event';

    /**
     * 
     *  Create Event
     */
    createEvent = (data: any): Promise<any> => this.post(`${this.prefix}/create`, data)

    /**
     *  Get Event User
     * @param id 
     * @returns 
     */
    getSingleUserEvent = (id: string): Promise<any> => this.get(`${this.prefix}/single-event-user/${id}`)

    /**
     * 
     * @param id 
     * @param data 
     * @returns 
     */
    updateEvent = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/event_update/${id}`, data)

    /**
     * 
     * @param id 
     * @param data 
     * @returns 
     */
    updateSingleEvent = (id: string, data: any): Promise<any> => this.put(`${this.prefix}/update-single/${id}`, data)

    /**
     * 
     * @param data 
     * @returns 
     */
    deleteEvents = (data: any): Promise<any> => this.put(`${this.prefix}/delete`, data)

    /**
     * 
     * @param id 
     * @returns 
     */
    getUserWithEvent = (id: string): Promise<any> => this.get(`${this.prefix}/user-with-event/${id}`)

    /**
     * 
     * @param id 
     * @returns 
     */
    getSingleEvent = (id: string): Promise<any> => this.get(`${this.prefix}/single-event/${id}`)

    /**
     * 
     * @param id 
     * @returns 
     */
    getAllUserEvents = (id: string): Promise<any> => this.get(`${this.prefix}/list/${id}`)

    /**
     * 
     * @param userId 
     * @param events 
     * @returns 
     */
    updateEventSequence = (userId: string, events: Array<any>): Promise<any> => this.put(`${this.prefix}/sort-events/${userId}`, { events })

}

export const eventService = new EventService()