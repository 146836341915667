import React, { useState } from "react";
import moment from "moment";
import ImageGallery from "react-image-gallery";
import { AWS_ENDPOINT } from "../../../../../environment";
import {
  ICuratorialInvitationDocument,
  IVideo,
} from "../../../../../interfaces";
import {
  collectionImage,
  getCuratorialProjectImages,
} from "../../../../../shared";
import { ImageLoading } from "../../../../common/ImageLoading";
import { curatorialService, toastService } from "../../../../../services";
import { IResponse } from "../../../../../interfaces/db.interface";
import { AxiosError, AxiosResponse } from "axios";
import { EInvitationStatus } from "../../../../../enums";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../router";

interface IPorps {
  invitation: ICuratorialInvitationDocument;
}
export const ParticipantInvitationBox = ({ invitation }: IPorps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const onClickPdf = () => {
    if (!invitation.curatorialService.pdf) return;
    let link = `${AWS_ENDPOINT}/pdf/${invitation.curatorialService.pdf}`;
    window.open(link, "_blank");
  };

  const onAccept = () => {
    setLoading(true)

    curatorialService.acceptInvitaion(invitation._id).then((res: AxiosResponse<IResponse<ICuratorialInvitationDocument>>) => {
      if (res.data.success) {
        toastService.success(res.data.message)
        invitation = res.data.data
      }
      setLoading(false)
    }).catch((err: AxiosError) => {
      console.log(err)
      setLoading(false)
    })
  }

  const onDecline = () => {
    setLoading(true)

    curatorialService.rejectInvitaions(invitation._id).then((res: AxiosResponse<IResponse<ICuratorialInvitationDocument>>) => {
      if (res.data.success) {
        toastService.success(res.data.message)
        invitation = res.data.data
      }
      setLoading(false)
    }).catch((err: AxiosError) => {
      console.log(err)
      setLoading(false)
    })
  }

  return (
    <div className="at-transaction-wrap">
      <div className="at-transaction-box">
        <div className="at-rejectaccept-proposal">
          <div className="at-new-thumbslider">
            <ImageGallery
              items={getCuratorialProjectImages(invitation.curatorialService)}
              showFullscreenButton={true}
              showPlayButton={false}
              showNav={false}
              // slideOnThumbnailOver={true}
              renderItem={(item: any) => (
                <div className="at-imagebox">
                  <figure className="at-figure">
                    <ImageLoading
                      className="img-fluid"
                      src={item.original}
                      thumbnail={item.blurImage}
                    />
                  </figure>
                </div>
              )}
            />
          </div>
          <h6 className="mb-0 text-left">
            <span className="at-color-grey">Project Name: </span>
            {invitation.curatorialService.name}{" "}
          </h6>
          <ul className="d-flex mt-2 ">
            <li className="mr-3 w-50">
              <h6>
                <span className="at-color-grey">Start Date: </span> <br />
                <span>
                  {moment(invitation.curatorialService.startDate).format(
                    "MM/DD/YYYY"
                  )}
                </span>
              </h6>
            </li>
            <li className="w-50">
              <h6>
                <span className="at-color-grey">End Date: </span>
                <br />
                <span>
                  {moment(invitation.curatorialService.endDate).format(
                    "MM/DD/YYYY"
                  )}
                </span>
              </h6>
            </li>
          </ul>
          <h6 className="">
            <span className="at-color-grey">Project Description: </span>
            <p
              dangerouslySetInnerHTML={{
                __html: invitation.curatorialService.description,
              }}
            ></p>
          </h6>
          <h6>
            <span className="at-color-grey">Project PDF: </span>
            <a className="at-orangelink-withicon" onClick={() => onClickPdf()}>
              <i className="icon-download"></i>
            </a>
          </h6>
          <h6>
            <span className="at-color-grey">Estimated Budget Range: </span>
            {invitation.curatorialService.estBudget}
          </h6>
          <h6>
            <span className="at-color-grey">Submission Deadline: </span>{" "}
            {moment(invitation.curatorialService.submissionDeadline).format(
              "MM/DD/YYYY"
            )}
          </h6>
          <h6>
            <span className="at-color-grey">
              Number of Participants Invited:{" "}
            </span>
            {invitation.curatorialService.participants.length}
          </h6>



          {invitation.invitationStatus === EInvitationStatus.ACCEPTED && (
            <>
              <h6 className="text-center">
                <span className="at-color-greenlight">Accepted Invitation</span>
              </h6>
              <>
                {invitation && !invitation.curatorialProposal && (
                  <div className="at-transfer-upper text-center mt-2">
                    <button
                      className="at-btn at-smallbtn-h40"
                      onClick={() =>
                        history.push(
                          `${routes.account.services.curatorialServices.createProposal}/${invitation._id}`
                        )
                      }
                    >
                      Prepare Proposal
                    </button>
                  </div>
                )}

                {invitation && invitation.curatorialProposal && (
                  <div className="at-transfer-upper text-center mt-2">
                    <button
                      className="at-btn at-smallbtn-h40"
                      onClick={() =>
                        history.push(
                          `${routes.account.services.curatorialServices.createProposal}/${invitation.curatorialProposal._id}/${invitation._id}`
                        )
                      }
                    >
                      Update Proposal
                    </button>
                  </div>
                )}
              </>
            </>
          )}

          {invitation.invitationStatus === EInvitationStatus.PENDING &&
            <>
              <div className="at-transfer-upper text-center mt-2">
                <button className="at-btn at-smallbtn-h40 mr-2 at-btn-white" disabled={loading} onClick={() => onDecline()} >DECLINE</button>
              </div>
              <div className="at-transfer-upper text-center mt-2">
                <button className="at-btn at-smallbtn-h40" disabled={loading} onClick={() => onAccept()} >ACCEPT INVITATION</button>
              </div>
            </>
          }

          {invitation.invitationStatus === EInvitationStatus.DECLINED && (
            <div className="at-transfer-upper text-center mt-2">
              <button
                className="at-btn at-smallbtn-h40 at-btn-red"
                onClick={() => { }}
                disabled
              >
                Declined
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
