import React, { useState, useCallback, useEffect, FormEvent } from 'react'
import { ADMIN_PHONE } from '../../../environment'
import { AxiosResponse } from 'axios'
import { IUser } from '../../../interfaces'
import { adminService, toastService, userService } from '../../../services'
import { userImage } from '../../../shared'
import { getInitials } from '../../../helper'
import { ERole } from '../../../enums'
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component'
import LoadingComponent from '../../common/Loading.component'
import { IResponse } from '../../../interfaces/db.interface'

interface IPageProps {
}

interface IblockUser {
    _id: string,
    status: boolean
}

export const AdminUsersListComponent = ({ }: IPageProps) => {
    const [agents, setAgents] = useState<Array<IUser>>()
    const [artists, setArtists] = useState<Array<IUser>>()
    const [guest, setGuest] = useState<Array<IUser>>()
    const [serviceProvider, setServiceProvider] = useState<Array<IUser>>()
    const [consultant, setConsultant] = useState<Array<IUser>>()
    const [searchContent, setSearchContent] = useState<string>("")
    const [openBlockBox, setOpenBlockBox] = useState<boolean>(false)
    const [currentUser, setCurrentUser] = useState<IblockUser>()
    const [selectedUser, setSelectedUser] = useState<IUser>()
    const [rejectConfirm, setRejectConfirm] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    // Component Did mount
    useEffect(() => {
        fetch('')
    }, [])

    // Fecth
    const setDataInState = (data: any) => {
        let artistList = data.filter((item: IUser) => item.roles.includes(ERole.ARTIST))
        let agentList = data.filter((item: IUser) => item.roles.includes(ERole.AGENT))
        let guesList = data.filter((item: IUser) => item.roles.length === 0 || item.roles[0].length === 0 || (item.roles?.includes(ERole.PROMOTER) || item.roles?.includes(ERole.TESTER) || item.roles?.includes(ERole.AUCTIONEER)) && !item?.roles?.includes(ERole.PROFESSIONAL_ART_BUYER) && (!item?.roles?.includes(ERole.ARTIST) && !item?.roles?.includes(ERole.AGENT) && !item?.roles?.includes(ERole.CONSULTANT)))
        let service = data.filter((item: IUser) => item.roles.includes(ERole.SERVICE_PROVIDER))
        let consultdata = data.filter((item: IUser) => item.roles.includes(ERole.CONSULTANT))
        setConsultant([...consultdata])
        setServiceProvider([...service])
        setArtists([...artistList])
        setAgents([...agentList])
        setGuest([...guesList])
    }

    const fetch = useCallback((value: string) => {
        adminService.userWithParams(value).then((response: AxiosResponse<[IUser]>) => {
            let data = response.data.filter((item: IUser) => item.phoneNumber !== ADMIN_PHONE)
            setDataInState(data)
        }).catch(error => {
            console.log("Error in getting users list: ", error)
        })
    }, [])


    // Handlers
    const handleSearch = (e: FormEvent<EventTarget>) => {
        const { value } = (e.target as HTMLInputElement)
        setSearchContent(value)
        fetch(value)
    }


    const handleBlockUser = (id: string, isBlocked: boolean) => {
        setCurrentUser({
            _id: id,
            status: isBlocked
        })
        setOpenBlockBox(true)
        setRejectConfirm(false)
    }


    // Confirmational Box handlers
    const onCancelConfirmation = () => setOpenBlockBox(false)

    const onConfirm = () => {
        setOpenBlockBox(false)
        adminService.blockUser(currentUser?._id, { isBlocked: !currentUser?.status }).then((response: AxiosResponse<any>) => {
            fetch(searchContent)
        }).catch(error => {
            console.log("Error in blocking the user:", error)
        })
    }

    const onConfirmReject = () => {
        let id = selectedUser?._id
        let data = selectedUser
        if (data) {
            data.approve_status = 'approved'
            data.isBlocked = false
            adminService.approveUser(id, { ...data }).then((res: AxiosResponse<any>) => {
                toastService.success("Successfully rejected the user")
                fetch(searchContent)
                setRejectConfirm(false)
            }).catch(err => {
                console.log(err)
                setRejectConfirm(false)
            })
        }

    }

    const onReject = (user: IUser) => {
        setSelectedUser(user)
        setRejectConfirm(true)
        setOpenBlockBox(false)
    }

    const onCloseRejectConfirmation = () => {
        let obj: any = {}
        setRejectConfirm(false)
        setSelectedUser(obj)
    }

    const onChangeSubRole = (r: string, u: IUser) => {
        setLoading(true)
        if (u.roles.includes(r as ERole)) {
            userService.removeUserSUbRole(u._id, { subRole: r }).then((res: AxiosResponse<IResponse<IUser>>) => {
                if (res.data.success) {
                    toastService.success(res.data.message)
                    fetch(searchContent)
                }
                setLoading(false)
            }).catch((err: any) => {
                setLoading(false)
                console.log(err)
            })
            return
        }
        userService.addUserSUbRole(u._id, { subRole: r }).then((res: AxiosResponse<IResponse<IUser>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                fetch(searchContent)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
        return
    }


    // rendering func
    const renderUser = (user: IUser) => (
        <div key={user._id} className="form-group pr-0">
            <div className="at-uservideobox">
                <div className="at-uservideocontent border-0">
                    <a className="at-adminaction">
                        <figure><img src={user.profile ? userImage(user.profile) : getInitials(user?.firstName, user?.lastName)} alt="User Image" /></figure>
                        <h3>{`${user.firstName} ${user.lastName}`}<span>{user.phoneNumber}</span></h3>
                        <div className="at-onoffholder-outer">
                            <div className="at-onoffholder" onClick={() => onChangeSubRole(ERole.TESTER, user)}>
                                <span>Tester</span>
                                <div className="onoffswitch">
                                    <input autoComplete="off" type="checkbox" name="isDeaf" className="onoffswitch-checkbox" id="myonoffswitch4" checked={user.roles.includes(ERole.TESTER)} />
                                    <label className="onoffswitch-label"></label>
                                </div>
                            </div>
                            <div className="at-onoffholder" onClick={() => onChangeSubRole(ERole.PROMOTER, user)}>
                                <span>Promoter</span>
                                <div className="onoffswitch">
                                    <input autoComplete="off" type="checkbox" name="isDeaf" className="onoffswitch-checkbox" id="myonoffswitch4" checked={user.roles.includes(ERole.PROMOTER)} />
                                    <label className="onoffswitch-label"></label>
                                </div>
                            </div>
                            <div className="at-onoffholder" onClick={() => onChangeSubRole(ERole.AUCTIONEER, user)}>
                                <span>Auctioneer</span>
                                <div className="onoffswitch">
                                    <input autoComplete="off" type="checkbox" name="isDeaf" className="onoffswitch-checkbox" id="myonoffswitch4" checked={user.roles.includes(ERole.AUCTIONEER)} />
                                    <label className="onoffswitch-label"></label>
                                </div>
                            </div>
                        </div>
                        <span className="at-adminicon" onClick={() => handleBlockUser(user._id, user.isBlocked)}><i className={`icon-current-user ${user.isBlocked ? `at-color-danger` : `at-color-success`}`}></i></span>
                        {user.approve_status === 'rejected' && <button className="at-adminicon at-cancelicon" onClick={() => onReject(user)}><i className={`icon-cancel1 ${user.approve_status === 'rejected' ? `at-color-danger` : `at-color-success`}`}></i></button>}
                    </a>
                </div>
            </div>
        </div>
    )


    return (
        <>

            <div className="at-formusercontacts  p-0 pt-3">
                <fieldset>
                    <div className="form-group at-paddinglr-15">
                        <input type="text" name="searchnamenumber" value={searchContent} className="form-control" placeholder="Search..." onChange={handleSearch} />
                        {/* <a  className="at-btnsearch"><i className="icon-search"></i></a> */}
                    </div>
                    <div className="at-usereditcontactholder at-usereditcontactholder-two at-themescrollbar scrollable">
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Agent / Gallery</h6>
                            <div className="at-paddinglr-15">
                                {agents?.map((user: IUser) => (
                                    renderUser(user)
                                ))}
                            </div>
                        </div>
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Artists</h6>
                            <div className="at-paddinglr-15">
                                {artists?.map((user: IUser) => (
                                    renderUser(user)
                                ))}
                            </div>
                        </div>
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Guests</h6>
                            <div className="at-paddinglr-15">
                                {guest?.map((user: IUser) => (
                                    renderUser(user)
                                ))}
                            </div>
                        </div>
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Service Provider</h6>
                            <div className="at-paddinglr-15">
                                {serviceProvider?.map((user: IUser) => (
                                    renderUser(user)
                                ))}
                            </div>
                        </div>
                        <div className="at-userbox">
                            <h6 className="at-userbox-heading">Consultant</h6>
                            <div className="at-paddinglr-15">
                                {consultant?.map((user: IUser) => (
                                    renderUser(user)
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            {loading && <LoadingComponent className='at-transaction-loader' />}
            <ConfirmAlertComponent
                open={openBlockBox}
                message={`Are you sure you want to ${currentUser?.status ? 'unblock' : "block"} this user?`}
                title="Confirmation!"
                onCancel={onCancelConfirmation}
                onConfirm={onConfirm}
                confirmTitle={currentUser?.status ? 'Unblock' : "Block"} />

            <ConfirmAlertComponent
                open={rejectConfirm}
                message={`Are you sure you want to unreject this user.`}
                title="Confirmation!"
                onCancel={onCloseRejectConfirmation}
                onConfirm={onConfirmReject}
                confirmTitle={"Confirm"} />
        </>
    )
}
