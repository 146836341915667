import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import HeaderSimpleComponent from "../components/HeaderSimple.component";

class NotFound404Page extends Component<RouteComponentProps> {
    render() {
        return (
            <div>
                {/*                
             <div id="at-wrapper" className="at-wrapper">
        	 	<div className="at-themeholder at-loginsignuparea"> */}
                {/* <HeaderSimpleComponent /> */}

                {/* </div>
            </div> */}
                <div className="at-createcollection at-contentarea">
                    <div className="at-createcollectionholder at-themescrollbar scrollable">
                        <div className="at-themehead">
                            <a onClick={() => this.props.history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                            <h2>Page Not Found</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound404Page;