import React, { Component, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingComponent from '../components/common/Loading.component'
import { inPlatformSelector } from '../store/selectors'

const PrivacyPolicyPage = () => {

    const history = useHistory()
    const inPlatform = useSelector(inPlatformSelector)

    useEffect(() => {
        if (inPlatform && inPlatform.nextPath) {
            history.push(inPlatform.nextPath)
        }
        else if (inPlatform.previousPath) {
            history.push(inPlatform.previousPath)
        } else {
            history.goBack()
        }
    }, [inPlatform])
    return (
        <LoadingComponent />
    )
}


export default PrivacyPolicyPage