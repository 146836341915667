import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { ECuratorialOfferStatus, EMileStoneStatus, ETransactionStatus } from '../../../enums'
import { ICuratorialProposalArtsDocument, IMilestone, ITransaction } from '../../../interfaces'
import { IResponse } from '../../../interfaces/db.interface'
import { curatorialService, toastService } from '../../../services'
import { ConfirmAlertComponent } from '../../common/ConfirmAlert.component'
import { CurrencyFormat } from '../../common/CurrencyFormat'
import { SeeOfferProposalArtsComponent } from '../../User/Services/CuratorialServices/Offer/SeeOfferProposalArts.component'


interface IProps {
    transaction: ITransaction
    fetch: Function
}
export const AdminCuratorialofferComponent = ({ transaction, fetch }: IProps) => {
    let { curatorialOffer, curatorialMilestone } = transaction
    const [showConfirmBox, setConfirmBox] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const onOpenConfirmBox = () => {
        setConfirmBox(true)
    }

    const onCloseConfirmBox = () => {

        setConfirmBox(false)
    }

    const onSubmitConfirmBox = () => {
        setLoading(true)
        curatorialService.releaseOfferPayment({ transactionId: transaction._id }).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success("“Payment released successfully")
                fetch()
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
            onCloseConfirmBox()
        }).catch(err => {
            console.log(err)
            onCloseConfirmBox()
            setLoading(false)
        })
    }

    const checkStatus = () => {
        if (transaction.transactionState === ETransactionStatus.PAID) {
            return { color: 'grey', text: 'Paid', disabled: true }
        }

        if (transaction.transactionState === 'refunded') {
            return { color: 'grey', text: 'Paid', disabled: true }
        }
        if (transaction.transactionState === ETransactionStatus.RETURN_COMPLETED) {
            return { color: 'grey', text: ETransactionStatus.RETURN_COMPLETED.replace('_', ' ').toLowerCase(), disabled: true }
        }
        return { color: 'orange', text: 'Release funds', disabled: false }
    }

    return (
        <div className="at-transaction-wrap">
            <div className="at-transaction-box">
                <div className="at-curatorial-wrap">
                    <div className="row at-row-mlr-3 at-curatorial-format ">
                        <div className="col-12 mb-3">
                            {curatorialOffer.curatorialService && curatorialOffer.curatorialService._id && <h5 ><span className="at-color-grey">Project Name:</span> {curatorialOffer.curatorialService.name}</h5>}
                            {/* {curatorialOffer && curatorialOffer._id && <div className="mt-3">
                                <p dangerouslySetInnerHTML={{ __html: curatorialOffer.information }}>
                                </p>
                            </div>} */}
                        </div>

                    </div>
                    {/* row ends */}

                    {/* <div className="row at-row-mlr-3 at-curatorial-format at-row-fs12">
                        <div className="col-2">
                            <h6 className="at-color-grey">Sky in the Night</h6>
                        </div>
                        <div className="col-10">
                            <div className="row at-row-mlr-3 at-row-underline">
                                <div className="col-4">
                                    <h6 className="at-color-grey">Format</h6>
                                </div>
                                <div className="col-2 at-textcenter">
                                    <h6 className="at-color-grey">Qty</h6>
                                </div>
                                <div className="col-3 at-textcenter">
                                    <h6 className="at-color-grey">Your Proposal Price</h6>
                                </div>
                                <div className="col-3 at-textcenter">
                                    <h6 className="at-color-grey">Our Offer</h6>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {curatorialOffer.proposalArts && curatorialOffer.proposalArts.length > 0 && curatorialOffer.proposalArts.map((pA: ICuratorialProposalArtsDocument, index: number) => {
                        return (<SeeOfferProposalArtsComponent proposalArt={pA} key={index} />)
                    })
                    }

                    {/* outer row ends */}

                    <div className="row at-row-mlr-3 at-curatorial-format at-row-fs12">
                        <div className="col-12">
                            <div className="row at-row-mlr-3 mt-2">
                                <div className="col-4">
                                </div>
                                <div className="col-2 at-textcenter">

                                </div>
                                <div className="col-3 at-textcenter">
                                    <hr className="At-Hr5 at-opacity-0" />
                                    <h5 className="at-fontbold">Total Offer</h5>
                                </div>
                                <div className="col-3 at-textcenter">
                                    <hr className="At-Hr5" />
                                    <h5><CurrencyFormat amount={Number(curatorialOffer.totalOfferPrice)} /></h5>
                                </div>
                            </div>
                            {/* inner row ends */}
                        </div>
                    </div>
                    {/* outer row ends */}
                    {curatorialOffer.milestone && curatorialOffer.milestone.length > 0 && curatorialOffer.milestone.map((m: IMilestone, index: number) => {
                        return (m._id === curatorialMilestone ?
                            <div className="row at-row-mlr-3 at-curatorial-format mt-4 align-items-center">
                                <div className="col-6">
                                    <h6 className="at-color-grey">{m.name}</h6>
                                </div>
                                <div className="col-3 at-textcenter">
                                    <h6><CurrencyFormat amount={Number(m.payment)} /></h6>
                                </div>
                                {curatorialOffer.offerStatus === ECuratorialOfferStatus.ACCEPTED && <div className="col-3 at-textcenter">
                                    {m.status === EMileStoneStatus.PAID && <h6 className="at-color-orange at-fs12">Paid</h6>}
                                </div>}
                            </div>
                            : null
                        )
                    })}
                    <div className="row at-rowprinting at-row-mlr-3">
                        <div className="col-sm-6 offset-sm-3 mt-4">
                            <button disabled={checkStatus().disabled} className={`at-btn at-btn-${checkStatus().color} at-smallbtn-h40-w100 `} onClick={() => onOpenConfirmBox()}>{`${checkStatus().text}`}</button>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmBox && <ConfirmAlertComponent loading={loading} open={showConfirmBox} onClose={onCloseConfirmBox} onCancel={onCloseConfirmBox} onConfirm={onSubmitConfirmBox} message="Are you sure you want to release the fund." />}
        </div>
    )
}