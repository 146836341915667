

export interface IShippingFactorDocument {
    readonly _id: string
    readonly id: string
    arts: Array<IWeihgtFactorsDocument>
    frames: Array<IWeihgtFactorsDocument>
    packages: Array<IWeihgtFactorsDocument>
    businessProtectionFactor: number
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

export interface IWeihgtFactorsDocument {
    readonly _id: string
    readonly id: string
    name: string
    sizingFactor: number
    businessWeightFactor: number
    weightFactor: number
    weightFactorUnit: string
    factorType: string
    costFactor: number
    costFactorUnit: string
    rolled: boolean
    isActive: boolean
    isHide: boolean
    readonly createdAt: Date
    readonly updatedAt: Date
    readonly deletedAt: Date
}

export interface IWeihgtFactorsState {
    _id?: string
    id?: string
    name: string
    sizingFactor: number
    businessWeightFactor: number
    weightFactor: number
    weightFactorUnit: string
    factorType: string
    costFactor?: number
    costFactorUnit?: string
    rolled: boolean
    isActive?: boolean
    isHide?: boolean
}

export const initWeightFactorState: IWeihgtFactorsState = {
    name: "",
    sizingFactor: NaN,
    businessWeightFactor: NaN,
    weightFactor: NaN,
    weightFactorUnit: "",
    rolled: false,
    factorType: "",
}