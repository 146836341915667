import { combineReducers } from 'redux'
import { spaceReducer } from './active-space.reducer'
import { authReducer } from './auth.reducer'
import { userReducer } from './user.reducer'
import { feedReducer } from './feed.reducer'
import { socialAuthReducer } from './social-auth.reducer'
import { contactReducer } from './contact.reducer'
import { miscReducer } from './misc.reducer'
import { currentStudioReducer } from './currentStudio.reducer'
import { templateReducer } from './template.reducer'
import { InPlatformWebReducer } from './InPlatformWeb.reducer'
import { LeftPanelReducer } from './LeftPanel.reducer'

export const rootReducers = combineReducers({
	spaces: spaceReducer,
	auth: authReducer,
	user: userReducer,
	feed: feedReducer,
	misc: miscReducer,
	socialAuth: socialAuthReducer,
	contacts: contactReducer,
	studio: currentStudioReducer,
	template: templateReducer,
	inPlatform: InPlatformWebReducer,
	leftPanel:  LeftPanelReducer
})
