import React, { useState } from 'react'
// import { LongPressAableComponent } from '../../common/LongPress.component'
import { LongPressDoubleClickComponent } from '../../common/LongPressDoubleClick'
import { ICollectionItem, ICollection, IUser } from '../../../interfaces'
import { collectionImage } from '../../../shared'
import { RouteComponentProps } from 'react-router-dom'
import { routes } from '../../../router'

interface IProps extends RouteComponentProps {
    user: IUser
    item: ICollectionItem
    collection: ICollection
    isSelected: boolean
    onLongPress: Function
    onClick: Function
    openAssignmentBox: Function,
    onMoveDownward: any,
    onMoveUpward: any,
    index?: any
}
export const ArtItemImageComponent = ({ user, collection, item, onClick, onLongPress, isSelected, openAssignmentBox, index, onMoveUpward, onMoveDownward, history }: IProps) => {
    const [showInfo, setShowInfo] = useState<boolean>()
    const isAdmin = user._id === collection.user._id;
    const onSelect = () => {
        console.log("selected")
    }

    const onClickButton = () => {
        let user = collection?.user;
        history.push(`${!user?.isAgent ? routes.artCentral.collection.item : routes.agent.showAssigment}/${user?.isAgent ? item.assignment : item.id}`);
    }
    return (
        <li key={item.id} className="itemImage" id={`${item.id}`}>
            <LongPressDoubleClickComponent
                id={item.id}
                click={onClick}
                {...((isAdmin && !collection.user.isAgent) ? {} : {})}
            >
                <div className="at-artgallerycollectionbox atv2-collectionbox">
                    <span className="at-checkbox">
                        <img src={collectionImage(item, `high_${item.url}`, true)} alt={collectionImage(item, item.url || '', true)} style={{ backgroundImage: `url(${collectionImage(item, item.url || '', true)})` }} />
                        {user._id === collection.user._id && <><input type="checkbox" name="select" checked={isSelected} id="artcollectionselectall" />
                            <label htmlFor="artcollectionone" onClick={() => onLongPress({ id: item.id })}></label> </>}

                    </span>
                    {
                        (item.assignment && showInfo) && (
                            <div className="info-box text-capitalize">
                                <div>
                                    AGENT: {(item.assignment.user) && ((item.assignment.user as IUser).gallery_name || `${(item.assignment.user as IUser).firstName} ${(item.assignment.user as IUser).lastName}`)}
                                </div>
                                {item.assignment.type && <div>TYPE OF ASSIGNMENT: {item.assignment.type}</div>}
                                <div>ORIGINAL TERM: {item.assignment.period} months</div>
                                <div>
                                    ORIGINAL PAYOUT: {item.assignment.commission}%
                                </div>
                                <div>OTHER FORMAT TERM: {item.assignment.formatPeriod} months</div>
                                <div>
                                    OTHER FORMAT PAYOUT: {item.assignment.formatCommission}%
                                </div>
                                {item.assignment.originalResponsibility && <div>
                                    ORIGINAL SHIPPING: {item.assignment.originalResponsibility}
                                </div>}
                                {item.assignment.formatResponsibility && <div>
                                    OTHER FORMAT SHIPPING: {item.assignment.formatResponsibility}
                                </div>}
                            </div>
                        )
                    }
                    <div className="at-clearfix"></div>
                    <div className="at-artdetail-box">
                        {item.name && <h5>
                            {item.name}
                        </h5>}
                        {item.artTechnique && item.height && item.width && <p> {item.height}" X {item.width}" {item.depth ? `X ${item.depth}"` : null}  {item.artTechnique}</p>}
                        <i className="icon-dotted-circle" onClick={() => onClickButton()}></i>
                    </div>
                    {/* <div className="info-box">
                        <div>
                            AGENT: Agent Name
                        </div>
                        <div>TERM: 12 months</div>
                        <div>
                            PAYOUT: 10%
                        </div>
                    </div> */}
                </div>
            </LongPressDoubleClickComponent>

            {
                (isAdmin && !collection.user.isAgent) && (
                    <>
                        {/* <i className={`icon icon-assign at-assign-btn ${item.assignment ? 'at-assign-success': ''}`} title="Assign" onClick={() => !item.assignment && openAssignmentBox(item.id)}></i> */}
                        {item.assignment && <i className={`icon icon-assign at-assign-btn ${item.assignment.accepted ? 'at-assign-success' : ''}`} title={item.assignment.accepted ? 'Assignment accepted' : 'Assignment'}></i>}
                        {item.assignment && <i className="icon icon-info at-assign-btn at-btn-info" title="Show Info" onClick={() => setShowInfo(!showInfo)}></i>}
                        {item.isAuctioned && <i className="icon icon-auction at-auction-btn at-assign-btn" title="Auction"></i>}

                    </>
                )
            }
            {user._id === collection.user._id && <>
                <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowup" title="Show Info" onClick={() => onMoveUpward(index)}></i>
                <i className="icon-Down-Arrow at-assign-arrow at-assign-arrowdw" title="Show Info" onClick={() => onMoveDownward(index)} ></i>
            </>
            }

        </li>
    )
}


// collection.items.map((item: ICollectionItem) => <ArtItemImageComponent user={user} collection={collection} item={item} isSelected={this.isSelected(item.id)} onClick={this.onClick} onLongPress={this.onLongPress}/>)