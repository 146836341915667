import { AxiosResponse } from "axios";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import StepWizard from "react-step-wizard";
import { ArtServices } from "../../../components/ArtCentral/ArtServices/ArtServices.component";
import CollectionGalleryComponent from "../../../components/ArtCentral/collection/CollectionGallery.component";
import { CreateCollectionStepFive } from "../../../components/ArtCentral/collection/create-collection/CCStepFive.component";
import { CreateCollectionStepFour } from "../../../components/ArtCentral/collection/create-collection/CCStepFour.component";
import { CreateCollectionStepOne } from "../../../components/ArtCentral/collection/create-collection/CCStepOne.component";
import { CreateCollectionStepSix } from "../../../components/ArtCentral/collection/create-collection/CCStepSix.component";
import { CreateCollectionStepThree } from "../../../components/ArtCentral/collection/create-collection/CCStepThree.component";
import { CreateCollectionStepTwo } from "../../../components/ArtCentral/collection/create-collection/CCStepTwo.component";
import { ConfirmAlertComponent } from "../../../components/common/ConfirmAlert.component";
import { CurrencyField } from "../../../components/common/CurrencyInput";
import { ErrorComponent } from "../../../components/common/Error.component";
import { UniversalModal } from "../../../components/common/Modals/Universal.modal";
import { SapmleKeywords } from "../../../components/common/SampleKeyword.component";
import { ScreenTutorial } from "../../../components/Tutorials/ScreenTutorial";
import { TutorialIcon } from "../../../components/Tutorials/TutorialIcon";
import { EPrintType, ERole } from "../../../enums";
import { EScreens } from "../../../enums/screens.enum";
import { dataURLtoFile } from "../../../helper";
import {
  artDetailImageState,
  artInitialImageState,
  IArtDetailImageState,
  IArtInitialImageState,
  ICollection,
  ICollectionItem,
  ICollectionItemState,
  ICreateCollection,
  IFormates,
  initialCollectionState,
  initialFormatState,
  initialItemState,
  IPackageDimension,
  IStoreReducers,
  IUser,
} from "../../../interfaces";
import { IResponse } from "../../../interfaces/db.interface";
import { IArtistSelectedService } from "../../../interfaces/services.interface";
import { lang } from "../../../locale";
import { routes } from "../../../router";
import { toastService } from "../../../services";
import { collectionService } from "../../../services/collection.service";
import { collectionImage, validateForm } from "../../../shared";

interface IProps {
  user: IUser;
}
const CreateCollection = (props: IProps) => {
  const history = useHistory();
  let params: any = useParams();
  const stepRef = useRef<any>(null);
  const imageRef = useRef<any>(null);
  const artDetailRef = useRef<any>(null);
  const [showAddArt, setShowAddArt] = useState<boolean>(false);
  const [collection, setCollection] = useState<ICollection>();
  const [collections, setCollections] = useState<Array<ICollection>>([]);
  const [newCollection, setNewCollection] = useState<ICreateCollection>({
    ...initialCollectionState,
  });
  const [new_art_collection, setNew_art_collection] = useState("");
  const [item, setItem] = useState<ICollectionItemState>({
    ...initialItemState,
  });
  const [imageState, setImageState] = useState<IArtInitialImageState>({
    ...artInitialImageState,
  });
  const [formates, setFormates] = useState<IFormates>({
    ...initialFormatState,
  });

  const [artDetailImages, setArtDetailImage] = useState<IArtDetailImageState>({
    ...artDetailImageState,
  });
  const [loadingCollections, setLoadingCollections] = useState<boolean>(false);
  const [submittingCollection, setSubmittingCollection] =
    useState<boolean>(false);
  const [showTutorialBox, setShowTutorialBox] = useState<boolean>(false);
  const [showSampleKeywordBox, setShowSampleKeywordBox] = useState<boolean>(false);
  const [submittingItem, setSubmittingItem] = useState<boolean>(false);
  const [formateModal, setShowFormateModal] = useState<{
    showAddFormatBox: boolean;
    showDeleteFormatBox: boolean;
  }>({
    showAddFormatBox: false,
    showDeleteFormatBox: false,
  });
  const [formatIndex, setFormatIndex] = useState<number>(NaN);
  const [errors, setErrors] = useState<any>({});

  const [showServices, setShowServices] = useState<boolean>(false);
  const [selectedFormates, setSelectedFormates] = useState<IFormates>();
  const [selectedFormatIndex, setSelectedFormatIndex] = useState<number>(NaN);
  const [currentStep, setCurrentStep] = useState<number>(1);


  useEffect(() => {
    // checkAuthentication()
    fetchCollections();
    showCollection();
    fetchItem();
  }, []);

  const onAddArt = () => {
    setShowAddArt(true)
  }

  const hideAddArt = () => {
    setShowAddArt(false)
  }

  const onClickBack = () => {

    if (params && params.id) {
      initialItemState.formates = [];
      setItem(initialItemState);
      setImageState({ ...artInitialImageState });
      setArtDetailImage({ ...artDetailImageState });
    }
    if (showAddArt) {
      setShowAddArt(false)
      setCurrentStep(1)
      return
    }
    initialItemState.formates = [];
    setItem(initialItemState);
    setImageState({ ...artInitialImageState });
    setArtDetailImage({ ...artDetailImageState });
    history.goBack()
  }

  const onSetInstance = (sw: any) => {
    stepRef.current = sw
  }

  const checkAuthentication = () => {
    if (props.user.isAgent) {
      toastService.info("Only Artist can access this functionality.");
      history.goBack();
    }
  };

  const fetchCollections = (): void => {
    setLoadingCollections(true);
    collectionService
      .list()
      .then((response: AxiosResponse<IResponse<Array<ICollection>>>) => {
        setCollections(response.data.data);
        setLoadingCollections(false);
      });
  };

  const showCollection = (): void => {
    if (!params.id) return;
    collectionService
      .show(params.id)
      .then((response: AxiosResponse<IResponse<ICollection>>) => {
        if (response.data.data.user._id !== props.user._id) {
          history.goBack();
        }
        setCollection({ ...response.data.data });
      });
  };

  const fetchItem = () => {
    if (!params.item) return;
    collectionService
      .showItem(params.item)
      .then((res: AxiosResponse<IResponse<ICollectionItem>>) => {
        let { data } = res.data;
        if (data?.id) {
          setItem({ ...data });
          setImageState({
            ...imageState,
            preview: [collectionImage(data, data.url || "")],
            previewsCopy: [collectionImage(data, data.url || "")],
            image: collectionImage(data, data.url || ""),
          });

          let othrImages = data?.items?.map((i: any) =>
            collectionImage(data, i.url || "")
          ) || [""];
          setArtDetailImage({
            ...artDetailImageState,
            preview: [...othrImages],
            previewsCopy: [...othrImages],
          });
        }
        setShowAddArt(true)
      });
    // if (response.data.user._id !== this.props.user._id) {
    //     this.props.history.goBack();
    // }
    // const { items } = response.data;
    // const preview = (response.data && items).find((a: ICollectionItem) => a.id === this.params.item)
    // const previewImage = preview && collectionImage(preview, preview.url || '')
    // let currItem = items.find((v: ICollectionItem) => v.id === this.params.item) || {};
    // let artImage = (currItem as ICollectionItem)?.items?.map((i: any) => collectionImage(currItem as any, i.url || ''))
    // this.setState({
    //     collection: { ...response.data },
    //     preview: [previewImage],
    //     previewsCopy: previewImage,
    //     items: currItem, selectedFiles: previewImage,
    //     artImagePreview: artImage,
    //     artImageCopy: artImage,
    // })
  };

  /** ================================ Submittinn Functions ================================= */
  const submit = async (e: FormEvent) => {
    e.preventDefault()
    setErrors(null);
    if (!item) return;
    if (!item.shipping || ( item.omniShipping && !item.shippingInsuranceAmount) || !item.totalShipping) {
      toastService.warning("Shipping Price is required")
    }
    if (!validData()) return;

    // let { artImage } = this.state
    setSubmittingItem(true);
    const form = new FormData();
    if (imageState.uploadItemImage) form.append("file", imageState.image);
    if (artDetailImages.image && artDetailImages.image.length > 0) {
      for (let i of artDetailImages.image) {
        form.append("artImages", i);
      }
    }
    item.formates = item.formates.map((f: IFormates) => {
      if (f.selectedService) {
        f.selectedService = (f.selectedService as IArtistSelectedService)._id;
      }
      return f;
    });
    let newItem: any = item;
    for (const key in newItem) {
      if (key === "formates") {
        item[key].forEach((f: any, index: number) => {
          form.append(`${key}-${index}`, JSON.stringify(f));
        });
      } else if (key === "items") {
        if (newItem && newItem[key] && (newItem[key] as any).length > 0) {
          let images = (newItem[key] as any).map((i: any) => i.id);
          form.append(key, JSON.stringify(images));
        }
      } else if (key === "art_collection") {
        if (newItem && newItem[key])
          form.append(
            key,
            typeof newItem[key] === "string" ? newItem[key] : newItem[key].id
          );
      } else if (key === "packageDimension") {
        form.append(key, JSON.stringify(newItem[key]));
      }
      else if (key === "auctionCommission" || key === "auctionMinPrice") {
        if (!isNaN(newItem[key])) form.append(key, newItem[key])
      }
      else {
        form.append(key, newItem[key]);
      }
    }
    if (params.id) {
      form.append("item_url", (item as any).url);
      form.append("new_art_collection", new_art_collection);
    }
    const save = await collectionService.addItem(form);
    toastService.success(lang.collection.updatedSuccessfully);
    initialItemState.formates = [];
    setItem(initialItemState);
    setImageState({ ...artInitialImageState });
    setArtDetailImage({ ...artDetailImageState });
    setSubmittingItem(false);
    history.push(routes.artCentral.collection.create);
    // return save;
  };

  const validData = (): boolean => {
    const keys = Object.keys(initialItemState);
    let valid = true;
    let index = 0;
    let newItem = item;
    const validate = validateForm(
      keys,
      newItem,
      lang.errors.required.art_collection
    );

    if (!validate.valid) {
      valid = false;
      // this.setState({ errors: validate.errors })
      console.log("validateErrors:-=", validate.errors)
      if (validate.errors && Object.keys(validate.errors).length > 0 && (Object.keys(validate.errors).includes('shipping') || Object.keys(validate.errors).includes('shippingInsuranceAmount') || Object.keys(validate.errors).includes('totalShipping'))) {
        toastService.warning(validate.errors['shipping'])
      }
      setErrors(validate.errors);
    }

    return valid;
  };


  const setErrorData = (e: any) => {
    setErrors(e)
  }

  const submitCollection = () => {
    if (newCollection.title.length > 30) return;
    // if (newCollection.description && newCollection.description.length > 250) return
    if (!newCollection.title) {
      let obj: any = {};
      obj["title"] = ["Title is require"];
      setErrors({ ...obj });
      return;
    }
    setSubmittingCollection(true);
    setErrors(null);
    collectionService.add(newCollection)
      .then((response: AxiosResponse<IResponse<ICollection>>) => {
        toastService.success(lang.collection.createdSuccessfully);
        setItem({
          ...item,
          art_collection: response.data.data
        })
        setNewCollection({ ...initialCollectionState });
        fetchCollections();
        setSubmittingCollection(false);
        stepRef.current?.nextStep()
      });
  };
  /** ======================================= Input Handlers =================================== */

  const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
    let selectItem: ICollectionItem | any = item;
    const { name, value } = e.target;
    if (name === "reserved" && value) {
      selectItem["sold"] = false;
      selectItem["isAuctioned"] = false;
    }
    if (name === "sold" && value) {
      selectItem["reserved"] = false;
      selectItem["isAuctioned"] = false;
    }

    if (name === "isAuctioned" && value) {
      selectItem["reserved"] = false;
      selectItem["sold"] = false;
    }


    if (params.id && name === "art_collection") {
      selectItem["changeCollection"] = true;
      // items = selectItem as any
      setNew_art_collection(value);
      setItem({ ...selectItem });
      return;
    }
    selectItem[name] = value;
    setItem({ ...selectItem });
  };

  const onChangePrice = (e: FormEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    let price = value < 0 ? 0 : value;
    if (price && (name === "price" || "shipping")) {
      price = price.split("$")[1].replace(/,/gi, "");
    }
    onChangeText({ target: { name, value: price } });
  };

  const handleOfferState = () => {
    let { offerState } = item;

    let selectItem: ICollectionItem | any = item;

    selectItem["offerState"] = !offerState;
    setItem({ ...selectItem });
  };

  const openTutorialBox = () => setShowTutorialBox(true);

  const closeTutorialBox = () => setShowTutorialBox(false);

  const onSetOmniShipping = () => {
    let check = item.ownShipping

    setItem({
      ...item,
      omniShipping: !item.omniShipping,
      ownShipping: false,
    })
  }

  const onSetOwniShipping = () => {
    setItem({
      ...item,
      omniShipping: false,
      ownShipping: !item.ownShipping,
    })
  }

  /***============================================ Weight and Shipping Calculation ============================ */

  const addPackageDimensionsWithWeight = (data: any) => {
    let form = item.formates

    if (form.length > 0) {
      form = form.map((f: IFormates) => {
        return {
          ...f,
          weight: data.weight
        }
      })
    }
    setItem({
      ...item,
      ...data,
      formates: form
    })
  }

  const addPackageDimensionsWithShipping = (data: IPackageDimension, s: string, insuranceAmount: number, totalShipping: string) => {
    let form = item.formates
    if (form.length > 0) {
      form = form.map((f: IFormates) => {
        return {
          ...f,
          shipping: s,
          shippingInsuranceAmount: insuranceAmount

        }
      })
    }
    setItem({
      ...item,
      packageDimension: data,
      shipping: s,
      shippingInsuranceAmount: insuranceAmount,
      formates: form,
      totalShipping: totalShipping
    })
  }



  /*========================================= Images Functions ======================================*/
  const selectImages = () => {
    imageRef.current.click();
  };

  const selectArtImages = () => {
    artDetailRef.current.click();
  };

  const onChangeFiles = (e: any): void => {
    if (e.target.files) {
      /* Get files in array form */
      const files = Array.from(e.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file: any) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev: any) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (base64Strings: any) => {
          setImageState({
            ...imageState,
            preview: base64Strings,
            previewsCopy: base64Strings,
            image: files[0],
            uploadItemImage: true,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const onCrop = (image: any) => {
    const fileName = imageState.image.name;
    const file = dataURLtoFile(image.cropped, fileName);
    // const updatedFiles = this.state.selectedFiles
    const updatedFiles = file;
    // const updatedPreviews = [...this.state.preview]
    const updatedPreviews = [image.cropped];
    setImageState({
      ...imageState,
      image: updatedFiles,
      preview: updatedPreviews,
    });
  };

  const deleteImage = (): void => {
    setImageState({
      ...imageState,
      preview: [],
      previewsCopy: [],
      image: null,
      uploadItemImage: false,
    });
  };

  const onChangeArtImages = (e: any) => {
    if (e.target.files) {
      let { preview, previewsCopy, artImageIndex, image, uploadartImage } =
        artDetailImages;
      /* Get files in array form */
      const files = Array.from(e.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file: any) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev: any) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (base64Strings: any) => {
          setArtDetailImage({
            ...artDetailImages,
            image: [...image, ...files],
            preview: [...preview, ...base64Strings],
            previewsCopy: [...previewsCopy, ...base64Strings],
            uploadartImage: true,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const onCropArtImages = (cropedImage: any) => {
    let { preview, previewsCopy, artImageIndex, image, uploadartImage } =
      artDetailImages;
    let currentIndex = preview.length - 1 - (image.length - 1);
    const fileName = image[artImageIndex - currentIndex].name;
    const file = dataURLtoFile(cropedImage.cropped, fileName);
    const updatedFiles = image;
    updatedFiles[artImageIndex - currentIndex] = file;
    const updatedPreviews = [...preview];
    updatedPreviews[artImageIndex] = cropedImage.cropped;
    setArtDetailImage({
      ...artDetailImages,
      image: updatedFiles,
      preview: updatedPreviews,
      previewsCopy: updatedPreviews,
    });
  };

  const onDeleteArtImage = () => {
    let { preview, previewsCopy, artImageIndex, image, uploadartImage } =
      artDetailImages;
    let updateImage = [];
    if (image && image.length > 0) {
      let currentIndex = preview.length - 1 - (image.length - 1);
      updateImage = image.filter(
        (a: any, index: any) => index !== artImageIndex - currentIndex
      );
    }
    let updatePreview = preview?.filter(
      (a: any, index: any) => index !== artImageIndex
    );
    let newIndaex = artImageIndex;

    if (
      item &&
      item.id &&
      item.items &&
      item.items.length - 1 >= artImageIndex
    ) {
      let currentIndex = preview.length - 1 - (item.items.length - 1);
      item.items = item.items.filter(
        (i: any, index: number) => index !== artImageIndex - currentIndex
      );
    }

    if (newIndaex >= updatePreview.length && newIndaex !== 0) {
      newIndaex = newIndaex - 1;
    }

    setArtDetailImage({
      ...artDetailImages,
      image: updateImage,
      preview: updatePreview,
      previewsCopy: updatePreview,
      artImageIndex: newIndaex,
    });
  };

  const onChangeIndex = (selectedFile: number) => {
    setArtDetailImage({
      ...artDetailImages,
      artImageIndex: selectedFile,
    });
  };

  /** ==============================================  Format Function */
  const errorElement = (key: string) =>
    errors &&
    errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />;

  const onCloseFormatModal = () => {
    setShowFormateModal({
      ...formateModal,
      showAddFormatBox: false,
    });
    setFormates({ ...initialFormatState });
  };

  const onFormateChanges = (e: FormEvent<HTMLInputElement> | any) => {
    let { name, value } = e.target;
    let newValue = value;
    if (newValue && (name === "price" || name === "shipping"))
      newValue = newValue.split("$")[1].replace(/,/gi, "");
    setFormates({
      ...formates,
      [name]: newValue,
    });
  };

  const onFormatPriceChange = (value: any, name: any) => {
    let price = value < 0 ? 0 : value;
    setFormates({
      ...formates,
      [name]: price,
    });
  };

  const createFormates = () => {
    let validation = checkFormatValidation();
    if (!validation) return;
    let newFormats: Array<IFormates> | any = item.formates || [];
    if (!isNaN(formatIndex)) {
      newFormats[formatIndex] = formates;
    } else {
      newFormats.push(formates);
    }
    item.formates = newFormats;
    setItem({ ...item });
    setFormates({ ...initialFormatState });
    setFormatIndex(NaN);
    setShowFormateModal({
      ...formateModal,
      showAddFormatBox: false,
    });
  };

  const checkFormatValidation = (): boolean => {
    if (!formates.price || !formates.size) {
      return false;
    }
    if (parseFloat(formates.price) < 0) {
      return false;
    }

    return true;
  };

  const editFormats = (index: any) => {
    let newFormats = item.formates;
    setFormates({ ...newFormats[index] });
    setFormatIndex(index);
    setShowFormateModal({
      ...formateModal,
      showAddFormatBox: true,
    });
  };

  const confirmDeleteFormats = (index: any) => {
    setShowFormateModal({
      ...formateModal,
      showDeleteFormatBox: true,
    });

    setFormatIndex(index);
  };

  const deleteFormats = () => {
    let newFormats = item.formates?.filter((i: IFormates, ind: Number) => {
      if (ind === formatIndex) {
        i.active = false;
      }
      return i;
    });
    item.formates = newFormats;
    setShowFormateModal({
      ...formateModal,
      showDeleteFormatBox: false,
    });

    setFormatIndex(NaN);
    setItem({ ...item });
  };




  const openShowServices = (f: IFormates, index: number) => {
    setShowServices(true);
    setSelectedFormatIndex(index);
    setSelectedFormates(f);
  };

  const closeShowServices = () => {
    setShowServices(false);
    setSelectedFormates(undefined);
    setSelectedFormatIndex(NaN);
  };

  const onSubmitAddService = (service: IArtistSelectedService) => {
    if (!selectedFormates) return;
    if (
      !isNaN(selectedFormatIndex) &&
      item.formates &&
      item.formates.length > 0
    ) {
      item.formates[selectedFormatIndex].selectedService = service;
      // let data = item.formates[selectedFormatIndex]
      // data.selectedService = service
      closeShowServices();
    }
  };
  const closeSampleKeywordPopup = () => {
    setShowSampleKeywordBox(false)
  }

  let { image } = imageState;
  let formatArray = item.formates;
  return (
    <div className="at-createcollection at-contentarea">
      <div className="at-createcollectionholder at-themescrollbar scrollable">
        <div className="at-themehead">
          <a onClick={() => onClickBack()} className="at-left-arrowicon">
            <i className="icon-left-arrow"></i>
          </a>
          <h2>
            {/* {params.id ? "Update" : "Create"} */}
            {showAddArt ? `Step ${currentStep}/6` : "Add Art to OMNI"}{" "}
            <span className="at-tutorialplaybtn">
              {" "}
              <TutorialIcon
                screen={EScreens.CREATE_COLLECTION}
                onClick={openTutorialBox}
              />
            </span>
          </h2>
        </div>
        <form
          className="at-formtheme at-formcreatecollection"
          onSubmit={submit}
          noValidate
        >
          <fieldset>
            {!showAddArt && <div className="at-addpost" onClick={() => onAddArt()}>
              <div className="at-addiconbox">
                <i className="icon-add-bold"></i>
              </div>
              <h4 className="at-addpost-title">Add Art</h4>
            </div>}

            {showAddArt && <>  <StepWizard instance={onSetInstance} onStepChange={(e: any) => setCurrentStep(e.activeStep)}>
              <CreateCollectionStepOne
                newCollection={newCollection}
                setNewCollection={setNewCollection}
                errorElement={errorElement}
                setShowSampleKeywordBox={setShowSampleKeywordBox}
                submitCollection={submitCollection}
                {...stepRef.current}
              />

              <CreateCollectionStepTwo
                item={item}
                errorElement={errorElement}
                params={params.id}
                new_art_collection={new_art_collection}
                onChangeText={onChangeText}
                collections={collections}
                {...stepRef.current}
              />

              <CreateCollectionStepThree
                item={item}
                errorElement={errorElement}
                onChangeText={onChangeText}
                imageRef={imageRef}
                onChangeFiles={onChangeFiles}
                selectImages={selectImages}
                imageState={imageState}
                onCrop={onCrop}
                onChangePrice={onChangePrice}
                artDetailImages={artDetailImages}
                deleteImage={deleteImage}
                artDetailRef={artDetailRef}
                onChangeArtImages={onChangeArtImages}
                selectArtImages={selectArtImages}
                onCropArtImages={onCropArtImages}
                onChangeIndex={onChangeIndex}
                onDeleteArtImage={onDeleteArtImage}
                errors={errors}
                setErrorData={setErrorData}
                user={props.user}
                {...stepRef.current}
              />
              {/* <div className="form-group form-w-50 at-floatlabel">
                <input
                  disabled={!image}
                  autoComplete="off"
                  type="number"
                  step="any"
                  min="0.0001"
                  name="weight"
                  className="form-control at-input-semibold"
                  placeholder=" "
                  value={item.weight}
                  onWheel={(e: any) => e.target.blur()}
                  onChange={onChangeText}
                />
                <label>Weight (lbs)</label>
                {errorElement("weight")}
              </div> */}

              {/* <div className="clearfix"></div> */}

              <CreateCollectionStepFour
                item={item}
                handleOfferState={handleOfferState}
                onChangeText={onChangeText}
                errorElement={errorElement}
                errors={errors}
                setErrorData={setErrorData}
                {...stepRef.current}
              />

              <CreateCollectionStepFive
                item={item}
                formates={formates}
                onFormateChanges={onFormateChanges}
                createFormates={createFormates}
                confirmDeleteFormats={confirmDeleteFormats}
                openShowServices={openShowServices}
                editFormats={editFormats}
                {...stepRef.current}
              />

              <CreateCollectionStepSix
                item={item}
                onChangeText={onChangeText}
                addPackageDimensionsWithWeight={addPackageDimensionsWithWeight}
                addPackageDimensionsWithShipping={addPackageDimensionsWithShipping}
                submittingItem={submittingItem}
                onSetOmniShipping={onSetOmniShipping}
                onSetOwniShipping={onSetOwniShipping}
                {...stepRef.current}
              />
            </StepWizard>
            </>
            }
            <div className="form-group form-w-50"></div>
            {/* <div className="form-group form-w-50 mt-0">
              <button
                type="submit"
                disabled={!image || submittingItem}
                className="at-btn at-medbtn-h40-mauto"
              >
                {submittingItem ? "Please wait..." : "UPDATE"}
              </button>
              {errors && Object.keys(errors).length > 0 && <ErrorComponent errors={['Please fix error above']} multiple={false} />}
            </div> */}
          </fieldset>
        </form>


        {!loadingCollections && !showAddArt && (
          <CollectionGalleryComponent
            event={
              props.user.roles.includes(ERole.AGENT)
                ? collectionService.AgentlistWithItems
                : collectionService.listWithItems
            }
            title="Manage Collections"
          />
        )}
      </div>
      <ConfirmAlertComponent
        open={formateModal.showDeleteFormatBox}
        onCancel={() =>
          setShowFormateModal({ ...formateModal, showDeleteFormatBox: false })
        }
        onConfirm={deleteFormats}
        confirmTitle={
          !formateModal.showDeleteFormatBox ? "Deleting..." : "Confirm"
        }
        message="Are you sure you want to delete this Format?"
      />

      {formateModal.showAddFormatBox && (
        <UniversalModal
          open={formateModal.showAddFormatBox}
          onClose={() => onCloseFormatModal()}
          position="bottom"
        >
          <h5 className="at-popupheading pb-3">
            {formates && formates._id
              ? "Edit Open and Limited Editions"
              : "Add Open and Limited Editions"}
          </h5>
          <div className="row row-margin-lr7 align-items-center">
            <div className="col-12 pad-lr-7">
              <div className="form-group at-floatlabel-h40">
                <input
                  type="text"
                  disabled={!image}
                  name="size"
                  className="form-control"
                  value={formates.size}
                  placeholder=" "
                  onChange={onFormateChanges}
                />
                <label>Description</label>
              </div>
            </div>
            <div className="col-6 pad-lr-7">
              <div className="form-group at-floatlabel-h40 at-form-group-mb15">
                <CurrencyField
                  id="input-example-format-price"
                  className="form-control "
                  name="price"
                  placeholder=" "
                  prefix="$"
                  disabled={!image}
                  value={formates.price}
                  onChange={onFormateChanges}
                />
                <label>price</label>
              </div>
            </div>
            <div className="col-6 pad-lr-7">
              <div className="form-group at-floatlabel-h40 at-form-group-mb15">
                <CurrencyField
                  id="input-example-format-shipping"
                  className="form-control "
                  name="shipping"
                  placeholder=" "
                  prefix="$"
                  disabled={!image}
                  value={formates.shipping}
                  onChange={onFormateChanges}
                />
                <label>shipping</label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group at-form-group-mb15">
                <p className="at-notered">
                  NOTE: Printing and framing costs will be added to your edition
                  price in setting the total price shown to the buyer.
                </p>
              </div>
            </div>
            <div className="col-12 pad-lr-7">
              <div className="form-group ">
                <span className="at-select at-floatlabel-h40">
                  <select
                    className="form-control floating-select"
                    name="printType"
                    value={formates.printType}
                    required
                    onChange={onFormateChanges}
                  >
                    <option value=""></option>
                    <option value={EPrintType.FRAMED}>With Frame</option>
                    <option value={EPrintType.UNFRAMED}>Without Frame</option>
                  </select>
                  <label>Select Frame</label>
                </span>
              </div>
            </div>
            <div className="col-6 pad-lr-7">
              <div className="form-group">
                <button
                  className="at-btn at-btn-cancel at-longbtn-h40"
                  onClick={() => onCloseFormatModal()}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-6 pad-lr-7">
              <div className="form-group">
                <button
                  className="at-btn at-longbtn-h40"
                  onClick={createFormates}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </UniversalModal>
      )}

      {/* {showDetailModal && <UniversalModal open={showDetailModal} onClose={() => this.setState({ showDetailModal: false })} position='bottom'>
                <h5 className="at-popupheading mb-0">Details</h5>
                <p className="at-popupsceondaryhead">Small Print (12"x14") Unframed</p>
                <div className="row row-margin-lr7 align-items-center">
                    <div className="col-6 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-btn-cancel at-longbtn-h40">Cancel</button>
                        </div>
                    </div>
                    <div className="col-6 pad-lr-7">
                        <div className="form-group">
                            <button className="at-btn at-longbtn-h40">Add</button>
                        </div>
                    </div>
                </div>
            </UniversalModal>} */}




      {showSampleKeywordBox && (
        <UniversalModal
          open={showSampleKeywordBox}
          onClose={closeSampleKeywordPopup}
        // position="bottom-0"
        >
          <SapmleKeywords />
          {/* at-categorybox ends */}
        </UniversalModal>
      )}

      {showTutorialBox && (
        <UniversalModal
          open={showTutorialBox}
          onClose={closeTutorialBox}
          position="bottom-0"
        >
          <ScreenTutorial screen={EScreens.CREATE_COLLECTION} />
          {/* at-categorybox ends */}
        </UniversalModal>
      )}

      {showServices && selectedFormates && (
        <UniversalModal open={showServices} onClose={closeShowServices}>
          <ArtServices
            onClose={closeShowServices}
            format={selectedFormates}
            art={item}
            onSubmitAddService={onSubmitAddService}
          />
        </UniversalModal>
      )}


    </div>
  );
};

const mapState = (state: IStoreReducers) => ({
  user: state.user,
});

export default connect(mapState, {})(CreateCollection);
