import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import StepWizard from "react-step-wizard";
import { OVERLAY_CLASS } from '../../../../../constants';
import { bytesToMegaBytes, _logger, dataURLtoFile } from '../../../../../helper';
import { ICollectionItem } from '../../../../../interfaces';
import { IAuctionDocument, IAuctionState, initAuctionState } from '../../../../../interfaces/auction.interface';
import { IResponse } from '../../../../../interfaces/db.interface';
import { routes } from '../../../../../router';
import { auctionService, toastService } from '../../../../../services';
import { eventImages, postImage } from '../../../../../shared';
import LoadingComponent from '../../../../common/Loading.component';
import { AuctionformStepOne } from './AuctionformStepOne.component';
import { AuctionformStepThree } from './AuctionformStepThree.component';
import { AuctionformStepTwo } from './AuctionformStepTwo.component';

interface IProps {

}

const initImageState = {
    preview: [],
    previewsCopy: [],
    selectedFiles: [],
    selectedFile: 0,
}

const AuctionFormComponent = ({ }: IProps) => {
    const history = useHistory()
    const params = (useParams() as any).id
    const stepRef = useRef<any>(null)
    const imageRef = useRef<any>(null)
    const pdfRef = useRef<any>(null)
    const posterRef = useRef<any>(null)
    const [previewPoster, setPreviewPoster] = useState<Array<any>>([])
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [state, setState] = useState<IAuctionState>({ ...initAuctionState })
    const [errors, setErrors] = useState<any>({})
    const [selectedArts, setSelectedArts] = useState<Array<ICollectionItem>>([])
    const [imageState, setImageState] = useState<any>({ ...initImageState })
    const [loading, setLoading] = useState<boolean>(false)


    const fetchAuction = useCallback(() => {
        setLoading(true)
        auctionService.getAuctionById(params).then((res: AxiosResponse<IResponse<IAuctionDocument>>) => {
            let { data } = res.data
            setState({
                ...state,
                _id: data._id,
                id: data.id,
                name: data.name,
                startDate: new Date(data.startDate),
                endDate: new Date(data.endDate),
                startTime: new Date(data.startTime),
                endTime: new Date(data.endTime),
                description: data.description,
                images: data.images,
                pdf: data.pdf,
                isHide: data.isHide,
                isActive: data.isActive,
                poster: data.poster,
                timeZone: data.timeZone,
                arts: data.arts
            })
            setPreviewPoster([eventImages({}, res.data.data.poster)])
            const preview = ((res.data && res.data.data.images) || []).map((a: any) => ({ url: eventImages(res.data.data, a.url), isVideo: a.isVideo }))
            setImageState({ ...imageState, preview, previewsCopy: preview })
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }, [])


    useEffect(() => {
        if (params) {
            fetchAuction()
        }
    }, [])

    const onSetInstance = (sw: any) => {
        stepRef.current = sw
    }

    const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }

    /* ===================================== Images Handling =========================================================== */

    const onChangeIndex = (selectedFile: number) => setImageState({ ...imageState, selectedFile: selectedFile })

    const onChangeFiles = (e: any): void => {
        const { preview, selectedFile, selectedFiles } = imageState;
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    setImageState({
                        ...imageState,
                        preview: [...preview, ...images],
                        previewsCopy: [...preview, ...images],
                        selectedFiles: [...selectedFiles, ...files],
                        selectedFile: selectedFile ? selectedFile : 0
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onCrop = (image: any) => {
        let fileName
        let index = image.index
        if (params) {
            index = imageState.preview.length - image.index
            fileName = imageState.selectedFiles[index - 1].name
        } else {
            fileName = imageState.selectedFiles[index].name
        }
        // const fileName = imageState.selectedFiles[image.index].name
        const file = dataURLtoFile(image.cropped, fileName)
        const updatedFiles = [...imageState.selectedFiles]
        updatedFiles[index] = file
        const updatedPreviews = [...imageState.preview]
        updatedPreviews[image.index] = image.cropped
        setImageState({
            ...imageState,
            selectedFiles: updatedFiles,
            preview: updatedPreviews
        })
    }

    const onCropPoster = (image: any) => {
        const fileName = state.poster.name
        const file = dataURLtoFile(image.cropped, fileName)
        setState({
            ...state,
            poster: file,
        })

        setPreviewPoster([image.cropped])
    }

    const deleteImage = (): void => {

        let { preview, selectedFiles, selectedFile: i } = imageState;


        if (i > -1 && preview[i]) preview.splice(i, 1)
        let oldPlusNew = [...state.images, ...selectedFiles];
        oldPlusNew.splice(i, 1);
        state.images = oldPlusNew.filter((v: any) => v.proposal)
        const newAssets = oldPlusNew.filter((v: any) => !v.proposal)
        const selectedFile = i > 0 ? i - 1 : 0;

        setImageState({ ...imageState, preview, previewsCopy: preview, selectedFiles: newAssets, selectedFile })
        setState({ ...state })
    }


    const selectImages = () => {

        imageRef.current.click();
    }
    const selectPdf = () => {

        pdfRef.current.click();
    }
    const selectPoster = () => {

        posterRef.current.click();
    }

    const onChangePoster = (e: any, name: string) => {
        if (e.target.files) {



            /* Get files in array form */
            const filesArray = Array.from(e.target.files);
            let bigFile = false;
            const files = (filesArray as Array<File> || []).filter((f: File) => {
                bigFile = bytesToMegaBytes(f.size) > 100;

                return !bigFile;
            })
            if (bigFile) toastService.info('File greater than 100MB can not be attached.');
            _logger('Files', files);

            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    if (name === 'poster') {
                        setPreviewPoster(images)
                    }
                    setState({
                        ...state,
                        [name]: files[0]
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onDeletePoster = () => {
        setState({
            ...state,
            poster: undefined
        })
        setPreviewPoster([])
    }


    const onAddEventArts = (array: Array<ICollectionItem>) => {
        let newArts = state.arts
        newArts = newArts.concat(array)
        setState({
            ...state,
            arts: newArts
        })
    }

    const onSelectEventArts = (item: ICollectionItem) => {
        if (selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1) {
            let newItems = selectedArts.filter((i: ICollectionItem) => i.id !== item.id)
            setSelectedArts([...newItems])
            return
        }
        let newArts = selectedArts
        newArts.push(item)
        setSelectedArts([...newArts])
    }
    const isSelectedForEvents = (item: ICollectionItem) => {
        return selectedArts.map((i: ICollectionItem) => i.id).indexOf(item.id) !== -1
    }

    const onSelectAllEventArts = () => {
        let { arts } = state
        if (selectedArts.length === arts.length) {
            setSelectedArts([])
            return
        }
        setSelectedArts([...arts])
    }
    const isAllEventsArtSelected = () => {
        let { arts } = state
        if (selectedArts.length === 0) return false
        return selectedArts.length > 0 && selectedArts.length === arts.length
    }

    const onDeleteArts = () => {
        let { arts } = state
        if (!selectedArts.length) return
        let array = (arts as Array<ICollectionItem>).filter((i: ICollectionItem) => {
            return selectedArts.map((s: ICollectionItem) => s.id).indexOf(i.id) === -1 ? true : false
        })
        console.log(array)
        setState({
            ...state,
            arts: array
        })
        setSelectedArts([])
    }

    const onMoveUp = (index: any) => {
        if (index !== 0) {
            let items = state.arts;
            let temp = items[index];
            items[index] = items[index - 1];
            items[index - 1] = temp;
            setState({
                ...state,
                arts: items
            })
        } else {
            toastService.error("Unable to move the collection Upword")
        }
    }
    const onMoveDown = (index: any) => {
        let items = state.arts;
        if (index !== items.length - 1) {
            let temp = items[index];
            items[index] = items[index + 1];
            items[index + 1] = temp;
            setState({
                ...state,
                arts: items
            })
        } else {
            toastService.error("Unable to move the collection Downward")
        }
    };


    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (state.arts.length === 0) {
            toastService.warning("Add at least one art before updating.")
            return
        }
        let arts = state.arts.map((a: ICollectionItem) => a.id)
        let data = new FormData()
        imageState.selectedFiles.forEach((i: any) => data.append('files', i))
        data.append('name', state.name)
        data.append('startDate', JSON.stringify(state.startDate))
        data.append('endDate', JSON.stringify(state.endDate))
        data.append('startTime', JSON.stringify(state.startTime))
        data.append('endTime', JSON.stringify(state.endTime))
        data.append('description', state.description)
        data.append('poster', state.poster)
        data.append('pdf', state.pdf)
        data.append('arts', JSON.stringify(arts))
        data.append('timeZone', state.timeZone)
        if (state.images && state.images.length > 0) data.append('images', JSON.stringify(state.images))

        setLoading(true)

        if (params) {
            auctionService.updateAuction(params, data).then((res: AxiosResponse<IResponse<IAuctionDocument>>) => {
                if (res.data.success) {
                    toastService.success(res.data.message)
                }
                setState({ ...initAuctionState })
                setImageState({ ...initImageState })
                setPreviewPoster([])
                setLoading(false)
                history.push(routes.account.services.auction.add)
            }).catch((err: any) => {
                console.log(err)
                setLoading(false)
            })
            return
        }

        auctionService.createAuction(data).then((res: AxiosResponse<IResponse<IAuctionDocument>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
            }
            setState({ ...initAuctionState })
            setImageState({ ...initImageState })
            setPreviewPoster([])
            setLoading(false)
            history.push(routes.account.services.auction.add)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const setErrorData = (e: any) => {
        setErrors(e)
    }

    // useEffect(() => {
    //     if (stepRef.current) {
    //         window.scrollTo(0, 0)
    //         console.log("scrol-=-=-=-=")
    //     }
    // }, [stepRef.current])


    return (
        <div className="at-createcollection at-contentarea-two">
            <div id={'step-scroll'} className="at-createcollectionholder at-themescrollbar scrollable">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon">
                        <i className="icon-left-arrow"></i>
                    </a>
                    <h2>
                        {/* {params.id ? "Update" : "Create"} */}
                        {`Step ${currentStep}/3`}{" "}

                    </h2>
                </div>
                <form
                    className="at-formtheme at-formcreatecollection"
                    onSubmit={onSubmit}
                    noValidate
                >
                    <fieldset className="at-overflowinherit">
                        <StepWizard instance={onSetInstance} onStepChange={(e: any) => {
                             var elmnt = document.getElementById("step-scroll");
                             if(elmnt){
                                elmnt.scrollTop = 10;
                                console.log("run")
                             }
                            
                            
                            setCurrentStep(e.activeStep)
                            }}>
                            <AuctionformStepOne
                                eventState={state}
                                errors={errors}
                                onChangeText={onChangeText}
                                setErrorData={setErrorData}
                                {...stepRef.current}
                            />
                            <AuctionformStepTwo
                                eventState={state}
                                errors={errors}
                                onChangeText={onChangeText}
                                imageState={imageState}
                                previewPoster={previewPoster}
                                imageRef={imageRef}
                                pdfRef={pdfRef}
                                posterRef={posterRef}
                                onChangeFiles={onChangeFiles}
                                onCrop={onCrop}
                                deleteImage={deleteImage}
                                onChangePoster={onChangePoster}
                                onDeletePoster={onDeletePoster}
                                onCropPoster={onCropPoster}
                                selectImages={selectImages}
                                selectPdf={selectPdf}
                                selectPoster={selectPoster}
                                onChangeIndex={onChangeIndex}
                                setErrorData={setErrorData}
                                {...stepRef.current}
                            />
                            <AuctionformStepThree
                                eventState={state}
                                errors={errors}
                                onChangeText={onChangeText}
                                onAddToEvents={onAddEventArts}
                                selectedForEvent={state.arts}
                                onSelectEventArts={onSelectEventArts}
                                isSelectedForEvents={isSelectedForEvents}
                                isAllEventsArtSelected={isAllEventsArtSelected}
                                onDeleteArts={onDeleteArts}
                                onSelectAllEventArts={onSelectAllEventArts}
                                onMoveUp={onMoveUp}
                                onMoveDown={onMoveDown}
                                submitLoading={loading}
                                setErrorData={setErrorData}
                                {...stepRef.current}
                            />
                        </StepWizard>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default AuctionFormComponent