import styled from 'styled-components'

type TemplateContainerProps = {
  templateBackground_Color?: string,
  textColor?: string
  lineColor?: string
  fontFamily?: string
  fontSize?: string
};
type TemplateTextProps = {
};

export const TemplateContainer = styled.div`
  background-color: ${(props: TemplateContainerProps) => props.templateBackground_Color};
  color: ${(props: TemplateContainerProps) => props.textColor};
 ${(props: TemplateContainerProps) => props.lineColor};
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  .swiper-button-prev, .swiper-button-next {
    color: ${(props: TemplateContainerProps) => props.textColor};
  }


.At-BorderFigure, .At-ArtDetailContent,
.At-ArtDetailFigure .image-gallery-content:not(.fullscreen) .image-gallery-slide-wrapper {
  border-color: ${(props: TemplateContainerProps) => props.lineColor}
}
  `

export const CustomStyledText = styled.div`
    p,p * {
      font-family: ${(props: TemplateContainerProps) => props.fontFamily || 'sf_ui_displayregular, sans-serif'} !important ;
    font-size: ${(props: TemplateContainerProps) => props.fontSize}px ;
    line-height: 1.3
    }
  `