import { AxiosError, AxiosResponse } from 'axios'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { EServicesFrequencyType, EServicesPaymentType } from '../../../../enums'
import { dataURLtoFile } from '../../../../helper'
import { IResponse } from '../../../../interfaces/db.interface'
import { IConsultantServicesDocument, initprintingPricesState, initPrintingServiceState, initServiceState, IPrintingServicesState, IPrintingServiesPrices, IPrintingServiesSizes, IServiceImageState, IServiceState, serviceImageState } from '../../../../interfaces/services.interface'
import { routes } from '../../../../router'
import { toastService } from '../../../../services'
import { consultantService } from '../../../../services/consultantServices.service'
import { collectionImage } from '../../../../shared'
import { CurrencyField } from '../../../common/CurrencyInput'
import { ErrorComponent } from '../../../common/Error.component'
import { SliderComponent } from '../../../common/Slider.component'
import { TmceEditor } from '../../../common/TMCEditor.comopnent'
import { CustomEditior } from '../../../Editor'

export const PrintingServicesForm = () => {
    const history = useHistory()
    const params = (useParams() as any).id
    const artDetailRef = useRef<any>(null)
    const [state, setState] = useState<IPrintingServicesState>({ ...initPrintingServiceState })
    const [serviceImages, setServiceImage] = useState<IServiceImageState>({ ...serviceImageState })
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>({})
    const [sizes, setSizes] = useState<Array<IPrintingServiesSizes>>([])
    const [selectedSizes, setSelectedSizes] = useState<Array<any>>([])


    useEffect(() => {
        fetchService()
    }, [])
    const filteringPrices = () => {
        let data = sizes.map((s: IPrintingServiesSizes) => {
            let index = state.printingPrices?.map((p: IPrintingServiesPrices) => p.printingSizes._id).indexOf(s._id)
            if (index !== undefined && index !== -1 && state.printingPrices) {
                return {
                    _id: state.printingPrices[index]._id,
                    printingSizes: state.printingPrices[index].printingSizes,
                    printingServices: state.printingPrices[index].printingServices,
                    price: state.printingPrices[index].price,
                    shippingPrice: state.printingPrices[index].shippingPrice,
                    isSelected: true
                }
            }
            return {
                printingSizes: s,
                price: '',
                shippingPrice: '',
                isSelected: false
            }
        })
        setSelectedSizes([...data])
    }

    const fetchService = () => {
        if (!params) return
        setLoading(true)
        consultantService.getPrintingServiceById(params).then((res: AxiosResponse<IResponse<IPrintingServicesState>>) => {
            if (res.data.success) {
                setState({
                    ...state,
                    ...res.data.data
                })
            }
            let othrImages = res.data.data?.images?.map((i: any) => collectionImage({} as any, i.url || '')) || ['']
            setServiceImage({
                ...serviceImages,
                preview: [...othrImages],
                previewsCopy: [...othrImages]
            })
            fetchSizes()
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    const fetchSizes = () => {
        consultantService.retrievePrintingSizes().then((res: AxiosResponse<IResponse<Array<IPrintingServiesSizes>>>) => {
            let services = res.data.data
            setSizes([...services])
            setLoading(false)
        }).catch((err: AxiosError) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (sizes && sizes.length > 0 && state.printingPrices) {
            filteringPrices()
        }
    }, [sizes, state.printingPrices])

    const onChangeText = (e: FormEvent<HTMLInputElement> | any) => {
        let { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }


    const onSubmit = (e: FormEvent) => {
        e.preventDefault()

        if (!valiateData()) return

        let data = new FormData()
        for (let i of serviceImages.image) {
            data.append('file', i)
        }

        let newPrintingPrices = selectedSizes.filter((s: any) => !s._id && s.isSelected)
        let existingPrintingPrices = selectedSizes.filter((s: any) => s._id && s.isSelected)
        let deletedPrintingPrices = selectedSizes.filter((s: any) => s._id && !s.isSelected)

        data.append('description', state.description)
        data.append('name', state.name)
        data.append('shipmentInformation', state.shipmentInformation)
        if (newPrintingPrices && newPrintingPrices.length > 0) data.append('newPrintingPrices', JSON.stringify(newPrintingPrices))
        if (existingPrintingPrices && existingPrintingPrices.length > 0) data.append('existingPrintingPrices', JSON.stringify(existingPrintingPrices))
        if (deletedPrintingPrices && deletedPrintingPrices.length > 0) data.append('deletedPrintingPrices', JSON.stringify(deletedPrintingPrices))
        if (state._id) data.append('serviceId', state._id)

        if (state.images.length > 0) data.append('images', JSON.stringify(state.images))
        setLoading(true)
        consultantService.addPrintingServiceData(data).then((res: AxiosResponse<IResponse<any>>) => {
            if (res.data.success) {
                toastService.success(res.data.message)
                history.goBack()
            } else {
                toastService.error(res.data.message)
            }
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    const valiateData = () => {
        if (serviceImages?.preview?.length === 0) {
            let obj = errors
            obj['image'] = ['Media files reqiured']
            setErrors({ ...obj })
            return false
        }


        setErrors({} as any)
        return true
    }

    const selectArtImages = () => {
        artDetailRef.current.click()
    }

    const onSelectSizes = (index: number) => {
        let newArray = selectedSizes
        let data = { ...newArray[index] }
        data.isSelected = !data.isSelected
        newArray[index] = data
        setSelectedSizes([...newArray])
        // let data = [...selectedSizes]
        // let checkingString = data.map((e: any) => e.printingSizes)
        // if (checkingString.includes(s._id)) {
        //     data = data.filter((e: any) => e.printingSizes !== s._id)
        // } else {
        //     let newData = { ...initprintingPricesState }
        //     newData.printingSizes = s._id
        //     data.push(newData)
        // }
        // setSelectedSizes([...data])
    }

    const checkSelectedSizes = (index: number) => {
        return selectedSizes[index].isSelected
    }

    const onChangePrice = (e: any, index: number) => {
        let price = e.target.value < 0 ? 0 : e.target.value;
        if (price) {
            price = price.split("$")[1].replace(/,/gi, '')
        }
        let newdata = selectedSizes.map((f: any, ind: number) => {
            if (index === ind) {
                f[e.target.name] = price
            }
            return f
        })

        setSelectedSizes([...newdata])
    }


    const onChangeArtImages = (e: any) => {
        if (e.target.files) {
            let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = serviceImages
            /* Get files in array form */
            const files = Array.from(e.target.files);
            /* Map each file to a promise that resolves to an array of image URI's */
            Promise.all(files.map((file: any) => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev: any) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then((base64Strings: any) => {
                    setServiceImage({
                        ...serviceImages,
                        image: [...image, ...files],
                        preview: [...preview, ...base64Strings],
                        previewsCopy: [...previewsCopy, ...base64Strings],
                        uploadServiceImage: true
                    })
                }, error => {
                    console.error(error);
                });
        }
    }

    const onCropArtImages = (cropedImage: any) => {
        let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = serviceImages
        let currentIndex = (preview.length - 1) - (image.length - 1)
        const fileName = image[imageIndex - currentIndex].name
        const file = dataURLtoFile(cropedImage.cropped, fileName)
        const updatedFiles = image
        updatedFiles[imageIndex - currentIndex] = file
        const updatedPreviews = [...preview]
        updatedPreviews[imageIndex] = cropedImage.cropped
        setServiceImage({
            ...serviceImages,
            image: updatedFiles,
            preview: updatedPreviews,
            previewsCopy: updatedPreviews
        })
    }

    const onDeleteArtImage = () => {
        let { preview, previewsCopy, imageIndex, image, uploadServiceImage } = serviceImages
        let updateImage = []
        if (image && image.length > 0) {
            let currentIndex = (preview.length - 1) - (image.length - 1)
            updateImage = image.filter((a: any, index: any) => index !== (imageIndex - currentIndex))
        }
        let updatePreview = preview?.filter((a: any, index: any) => index !== imageIndex)
        let newIndaex = imageIndex

        if (state && state._id && state.images && (state.images.length - 1) >= imageIndex) {
            let currentIndex = (preview.length - 1) - (state.images.length - 1)
            state.images = state.images.filter((i: any, index: number) => index !== (imageIndex - currentIndex))
        }


        if (newIndaex >= updatePreview.length && newIndaex !== 0) {
            newIndaex = newIndaex - 1
        }


        setServiceImage({
            ...serviceImages,
            image: updateImage,
            preview: updatePreview,
            previewsCopy: updatePreview,
            imageIndex: newIndaex
        })
    }

    const onChangeIndex = (selectedFile: number) => {
        setServiceImage({
            ...serviceImages,
            imageIndex: selectedFile
        })
    }

    const errorElement = (key: string) => (
        errors && errors[key] && <ErrorComponent errors={errors[key]} multiple={false} />
    )

    return (
        <main id="at-main" className="at-main at-haslayout">
            <div className="at-createaccount scroll-style-3 at-contentarea-two at_pad_lr_0">
                <div className="at-themehead">
                    <a onClick={() => history.goBack()} className="at-left-arrowicon"><i className="icon-left-arrow"></i></a>
                    <h2>{state.name ? state.name : 'Services form'}</h2>
                </div>
                <div className="at_theme_innercontent">
                    <form className="at-formtheme at-formcreatecollection" onSubmit={onSubmit}>
                        <fieldset>
                            <div className="form-group form-w-100 at-floatlabel mt-4">
                                <input type="text" name="name" value={state.name} className="form-control" onChange={onChangeText} />
                                <label>Name</label>
                            </div>
                            <div className="form-group form-w-100 mb-0">
                                <div className="at-themefileupload text-center">
                                    <input type="file" name="image" multiple ref={artDetailRef} accept="image/*,video/*" onChange={onChangeArtImages} id="at-service-image" />
                                    <label onClick={selectArtImages}>
                                        <span><i className="icon-gallery"></i>Upload Service Pictures</span>
                                    </label>
                                    {errorElement('image')}
                                </div>
                            </div>

                            <div className="form-group form-w-100">
                                <SliderComponent items={serviceImages.preview} originalItems={serviceImages.previewsCopy} onCrop={onCropArtImages} currentIndex={serviceImages.imageIndex} onChangeIndex={onChangeIndex} />
                                {
                                    serviceImages.preview.length > 0 && (<i className="icon-delete2 at-icon-delete" onClick={onDeleteArtImage}></i>)
                                }

                            </div>

                            <div className="form-group form-w-100">
                                <label className="text-left at-color-grey">Service Description</label>
                                {/* <textarea name="description" placeholder=" " value={state.description} className="form-control floating-input" onChange={onChangeText} ></textarea> */}
                                {/* {state.description ? <CustomEditior hideToolBar={false} recieve={true} text={state.description} onChange={(e: any) => onChangeText({ target: { name: 'description', value: e } })} />
                                    :
                                    <CustomEditior hideToolBar={false} recieve={false} text={state.description} onChange={(e: any) => onChangeText({ target: { name: 'description', value: e } })} />
                                } */}

                                <TmceEditor text={state.description} onChange={(e: any) => onChangeText({ target: { name: 'description', value: e } })} />
                            </div>

                            <div className="form-group at-floatlabel form-w-100">
                                <textarea name="shipmentInformation" placeholder=" " className="form-control " onChange={onChangeText} value={state.shipmentInformation}>{state.shipmentInformation}</textarea>
                                <label>Shipping Information</label>
                                {errorElement('shipmentInformation')}
                            </div>
                            {/* {sizes && sizes.length > 0 && <div className="row mt-4">
                                <div className="col-5">
                                    <p className="">Available Sizes</p>
                                </div>
                                <div className="col-2 text-center">
                                    <p className="">Price</p>
                                </div>
                                <div className="col-2">Shipping</div>
                            </div>} */}
                            {/* {state.printingPrices && state.printingPrices.length > 0 && state.printingPrices.map((f: IPrintingServiesPrices) => {
                                return (
                                    <div className="row At-RowPricing align-items-center">
                                        <div className="col-5">
                                            <h6>{f.printingSizes?.width} X {f.printingSizes?.height}</h6>
                                        </div>
                                        <div className="col-2 text-center">
                                            <p>{f.price}</p>
                                        </div>
                                        <div className="col-2 text-center">
                                            <p>{f.shippingPrice}</p>
                                        </div>
                                        <div className="col-3">
                                            <div className="at-editformat-new">
                                                <i className="icon-delete2" />
                                                <i className="icon-edit2" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })} */}
                            {selectedSizes && selectedSizes.length > 0 && <div className="row at-row-mlr-5plus at-printerheading">
                                <div className="col-4 pad-lr5 text-center">
                                    <h6>Available Sizes</h6>
                                    <p>(H x W)</p>
                                </div>
                                <div className="col-4 pad-lr5 text-center">
                                    <h6>Price</h6>
                                    {/* <p>(USD)</p> */}
                                </div>
                                <div className="col-4 pad-lr5 text-center">
                                    <h6>Shipping</h6>
                                    {/* <p>Unframed (USD)</p> */}
                                </div>
                            </div>}
                            {selectedSizes && selectedSizes.length > 0 && selectedSizes.map((f: any, index: number) => {
                                return (
                                    <div className="row at-rowprinting align-items-center at-row-mlr-5plus">
                                        <div className="col-4 pad-lr5 at-colprinting-label align-items-center">
                                            <div className="at-checkbox at-checkbox-round" >
                                                <input type="checkbox" checked={checkSelectedSizes(index)} name="remember" id="abc" />
                                                <label htmlFor="abc" onClick={() => onSelectSizes(index)}></label>
                                            </div>
                                            <h6>{f.printingSizes.height} X {f.printingSizes.width}</h6>
                                        </div>
                                        <div className="col-4 pad-lr5 text-center">
                                            <CurrencyField
                                                id="input-example-format-shipping"
                                                className="form-control cust-input-padding-5px text-center"
                                                name="price"
                                                placeholder="Price"
                                                prefix="$"
                                                disabled={!checkSelectedSizes(index)}
                                                value={f.price}
                                                onChange={(e: any) => onChangePrice(e, index)} />
                                        </div>
                                        <div className="col-4 pad-lr5 text-center">
                                            <CurrencyField
                                                id="input-example-format-shipping"
                                                className="form-control cust-input-padding-5px text-center"
                                                name="shippingPrice"
                                                placeholder="shipping"
                                                prefix="$"
                                                disabled={!checkSelectedSizes(index)}
                                                value={f.shippingPrice}
                                                onChange={(e: any) => onChangePrice(e, index)} />
                                        </div>

                                    </div>
                                )
                            })}

                            <div className="row">
                                <div className="col">
                                    <div className="form-group form-w-100 mt-2">
                                        {/* <span className={`${(newCollection.description || '').length > 250 && 'text-danger'}`}>You have {250 - (newCollection.description || '').length} characters left</span> */}
                                        <button type="submit" disabled={loading} className="at-btn at-longbtn-h40imp">{loading ? 'Wait...' : 'Update'}</button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </main>
    )
}