import { AxiosError, AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { IFormates, IUser } from '../../../../../interfaces'
import { IResponse } from '../../../../../interfaces/db.interface'
import { consultantService } from '../../../../../services/consultantServices.service'
import { UniversalModal } from '../../../../common/Modals/Universal.modal'
import { SeeServicePricing } from './SeeServicePricing.modal'

interface IProps {
    format: IFormates
    onSeePrinterDetails: Function
    onSelectPrinter: Function
    selectedPrinter?: IUser
    onClickingSelectPrinter: Function
    onChoseYoureOwnService: Function
    onClickChoseOwn: Function
    own: boolean
}
export const SelectPrintingModal = ({ format, onSeePrinterDetails, onSelectPrinter, selectedPrinter, onClickingSelectPrinter, onChoseYoureOwnService, onClickChoseOwn, own }: IProps) => {

    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [printers, setPrinters] = useState<Array<IUser>>([])
    const [title, setTitle] = useState<string>('')
    const [search, setSearch] = useState<string>('')


    const fetchPrinter = (search = '') => {
        setLoading(true)
        consultantService.getAllPrintingServices({ search }).then((res: AxiosResponse<IResponse<Array<IUser>>>) => {
            if (res.data.success) {
                setPrinters([...res.data.data])
            }
            setLoading(false)
        }).catch((err: AxiosError) => {
            setLoading(false)
            console.log(err)
        })
    }

    useEffect(() => {
        fetchPrinter()
    }, [])

    const onSearchChange = (e: any) => {
        setSearch(e.target.value)
        fetchPrinter(e.target.value)
    }

    return <div className="at-addactionholder">
        <div className="at-formthemehead pb-2">
            <h2 className="text-center">Select a Printer</h2>
            <div className="from-group at-searchinput">
                <input type="search" className="form-control" placeholder="Search" value={search} onChange={onSearchChange} />
            </div>
        </div>
        <div className="at-selectprinter">
            <div className="at-formgroup-outer">
                <div className="at-checkbox at-checkbox-orangeleft" onClick={() => onClickChoseOwn()}>
                    <input type="checkbox" name="" checked={own} />
                    <label><span className="at-labelbold">I want to use my own printing service</span></label>
                </div>
                {/* <div className="at-checkbox-bottom">
                    <p>1622 Liberty Blvd</p>
                    <p>Scattic CA 90567</p>
                    <button type="button" className="at-btn-linkorange" onClick={() => setShow(true)}>See Services and Pricing</button>
                </div> */}
            </div>
            {printers && printers.length > 0 && printers.map((p: IUser) => {
                return (
                    <div className="at-formgroup-outer">
                        <div className="at-checkbox at-checkbox-orangeleft" onClick={() => onSelectPrinter(p)} >
                            <input type="checkbox" name="" checked={selectedPrinter && selectedPrinter._id === p._id} />
                            <label><span className="at-labelbold">{p.businessName ? `${p.businessName}` : `${p.firstName} ${p.lastName}`}</span></label>
                        </div>
                        <div className="at-checkbox-bottom">
                            <p>{p.address ? p.address : ''}</p>
                            <p>{p.city ? p.city : ''} {p.state ? p.state : ''} {p.zipcode ? p.zipcode : ''}</p>
                            <button type="button" className="at-btn-linkorange" onClick={() => onSeePrinterDetails(p)}>See Services and Pricing</button>
                        </div>
                    </div>
                )
            })}
            {/* <div className="at-formgroup-outer">
                <div className="at-checkbox at-checkbox-orangeleft" >
                    <input type="checkbox" name="" />
                    <label><span className="at-labelbold">The Color Group</span></label>
                </div>
                <div className="at-checkbox-bottom">
                    <p>1622 Liberty Blvd</p>
                    <p>Scattic CA 90567</p>
                    <button type="button" className="at-btn-linkorange" onClick={() => setShow(true)}>See Services and Pricing</button>
                </div>
            </div>
            <div className="at-formgroup-outer">
                <div className="at-checkbox at-checkbox-orangeleft" >
                    <input type="checkbox" name="" />
                    <label><span className="at-labelbold">Redbubble</span></label>
                </div>
                <div className="at-checkbox-bottom">
                    <p>1622 Liberty Blvd</p>
                    <p>Scattic CA 90567</p>
                    <button type="button" className="at-btn-linkorange" onClick={() => setShow(true)}>See Services and Pricing</button>
                </div>
            </div>
            <div className="at-formgroup-outer">
                <div className="at-checkbox at-checkbox-orangeleft" >
                    <input type="checkbox" name="" />
                    <label><span className="at-labelbold" >Giclee Today</span></label>
                </div>
                <div className="at-checkbox-bottom">
                    <p>1622 Liberty Blvd</p>
                    <p>Scattic CA 90567</p>
                    <button type="button" className="at-btn-linkorange" onClick={() => setShow(true)}>See Services and Pricing</button>
                </div>
            </div> */}
        </div>
        {/* {show && <UniversalModal open={show} onClose={() => setShow(false)} position='bottom-0'>
            <SeeServicePricing />
        </UniversalModal>} */}
        <button className="at-btn" disabled={!selectedPrinter && !own} onClick={() => onClickingSelectPrinter()}>
            Select this printer
        </button>
    </div>
}